import { ActionTypes, IAssetsState } from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IAssetsState = {
  assets: []
};

export const assetsReducer = Utils.Redux.createReducer<IAssetsState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_ASSETS]: (state, action) => ({
      ...state,
      assets: action.devices
    })
  }
);