import { ExpandMore } from '@mui/icons-material';
import { TextField, Select, MenuItem, Box } from '@mui/material';
import React, { useState } from 'react'

interface IDurationInputProps {
  onChange: (duration: number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: number;
}

const DurationInput: React.FC<IDurationInputProps> = ({ onChange, value, onBlur, onFocus }) => {
  const [timeUnit, setTimeUnit] = useState<"min" | "sec">("sec");
  const [time, setTime] = useState(value ? value / 1000 : 1);

  return (<Box display="grid" gridTemplateColumns={"2fr 1fr"} gap="0.5rem">
    <TextField
      style={{ minWidth: '5rem', marginTop: 0 }}
      type="number"
      size="small"
      variant="outlined"
      value={time.toString()}
      onChange={(e) => {
        if (e.currentTarget.value.length > 10 || Number(e.currentTarget.value) < 0)
          return;
        setTime(parseInt(e.currentTarget.value)); onChange(timeUnit === "sec" ? parseInt(e.currentTarget.value) * 1000 : parseInt(e.currentTarget.value) * 1000 * 60);
      }}
      margin="none"
      data-cy="timeline-events-time-input"
      onFocus={onFocus}
      onBlur={onBlur}
    />
    <Select
      value={timeUnit}
      style={{ minWidth: '5rem' }}
      margin="none"
      variant="outlined"
      IconComponent={ExpandMore}
      onChange={(e) => { setTimeUnit(e.target.value as "min" | "sec"); onChange(e.target.value === "sec" ? time * 1000 : time * 1000 * 60); }}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <MenuItem
        value={"sec"}
      >
        sec
      </MenuItem>
      <MenuItem
        value={"min"}
      >
        min
      </MenuItem>
    </Select>
  </Box>
  )
}

export default DurationInput