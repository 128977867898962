import { API } from 'aws-amplify';

export async function getStreamUptime(stream: VideoStream): Promise<UptimeEvent[]> {
  try {
    const path: string = `/streams/${stream.siteId}/${stream.kvsName}/status-changes`;

    const data = await API.get(
      'api-service',
      path,
      {}
    )

    return data.data as UptimeEvent[]
  } catch (error) {
    throw error;
  }
}