import EventsFilter from '@classes/StreamEvents/EventsFilter';
import { Box, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import React from 'react';
import TimeRangeSelector from '../../../common/Input/TimeRangeSelector';
import EventsGroupList from './EventsGroupList';
import FilterPanel from './FilterPanel';
import TagFilter from './TagFilter';
import { useMobileQuery } from '@hooks/useMobileQuery';
import API from '@API/index';

export type EventsTabProps = {
  timeRange: { start: Date, end: Date };
  selectedSite?: Site;
  onTimeRangeChange: (start?: Date, end?: Date) => void;
  events: VideoStreamEvent[];
  onApplyFilters?: (filter?: EventsFilter) => void;
  eventsFilter?: EventsFilter;
  onEventClick?: (event: VideoStreamEvent, animateTimeline?: boolean) => void;
  isLoadingEvents: boolean;
  areaSearchActive: boolean;
  onAreaSearchClick: () => void;
  areaSelection?: Rectangle;
  onRemoveAreaSearch?: () => void;
  stream?: VideoStream;
  features?: StreamFeatures;
  timezone?: string;
  onMouseEnterEvent?: (event: VideoStreamEvent) => void;
  onMouseLeaveEvent?: () => void;
  onNextTimeRange: () => void;
  onPreviousTimeRange: () => void;
  zones: Zone[];
  onMarkAsReadClick?: (data: VideoStreamEvent) => void;
  onInformationClick?: (data: VideoStreamEvent) => void;
}

const EventsTab: React.FC<EventsTabProps> = (props) => {
  const {
    timeRange,
    selectedSite,
    onTimeRangeChange,
    events,
    onApplyFilters,
    eventsFilter,
    onEventClick,
    isLoadingEvents,
    areaSearchActive,
    onAreaSearchClick,
    areaSelection,
    onRemoveAreaSearch,
    stream,
    features,
    timezone,
    onMouseEnterEvent,
    onMouseLeaveEvent,
    onNextTimeRange,
    onPreviousTimeRange,
    zones,
    onMarkAsReadClick,
    onInformationClick
  } = props;

  const isMobile = useMobileQuery();

  const [filteringActive, setFilteringActive] = React.useState<boolean>(false);
  const areaSearchActiveRef = React.useRef(areaSearchActive);
  const onAreaSearchClickRef = React.useRef(onAreaSearchClick);

  const [filterOptions, setFilterOptions] = React.useState<Record<string, string[]>>({});

  React.useEffect(() => { onAreaSearchClickRef.current = onAreaSearchClick }, [onAreaSearchClick])
  React.useEffect(() => { areaSearchActiveRef.current = areaSearchActive }, [areaSearchActive])

  React.useEffect(() => {
    if (filteringActive === false && areaSearchActiveRef.current === true && onAreaSearchClickRef.current) onAreaSearchClickRef.current();
  }, [filteringActive])

  function getMaxHeight(): string {
    if (window.innerWidth < 1110) return 'calc(100vh - 10rem)'
    return 'calc(100vh - 9rem)';
  }

  React.useEffect(() => {
    API.Events.fetchFilterSuggestions()
      .then((suggestions) => {
        setFilterOptions(values => {
          const distinctValues = Object.fromEntries(Object.entries(values).map(([key, value]) => [key, new Set(value)]));

          if (Object.keys(suggestions.vehicles).length) {
            distinctValues["make"] = new Set(Array.from(distinctValues["make"] || []).concat(Object.keys(suggestions.vehicles)));
            distinctValues["model"] = new Set(Array.from(distinctValues["model"] || []).concat(Object.values(suggestions.vehicles).flat()));
          }
          if (suggestions.colors) {
            distinctValues["color"] = new Set(Array.from(distinctValues["color"] || []).concat(suggestions.colors));
          }
          if (suggestions.states) {
            distinctValues["region"] = new Set(Array.from(distinctValues["region"] || []).concat(suggestions.states));
          }
          
          return Object.entries(distinctValues).reduce((acc, [key, value]) => ({ ...acc, [key]: Array.from(value) }), {});
        })
      })
  }, []);

  React.useEffect(() => {
    setFilterOptions(values => {
      const distinctValues = Object.fromEntries(Object.entries(values).map(([key, value]) => [key, new Set(value)]));

      events.forEach(e => {
        if (e.metadata) {
          const { make, model, color, region } = e.metadata;

          if (make) {
            if (!distinctValues["make"]) {
              distinctValues["make"] = new Set();
            }
            distinctValues["make"].add(make);
          }
        if (model) {
            if (!distinctValues["model"]) {
              distinctValues["model"] = new Set();
            }
            distinctValues["model"].add(model);
          }
          if (color) {
            if (!distinctValues["color"]) {
              distinctValues["color"] = new Set();
            }
            distinctValues["color"].add(color);
          }
          if (region) {
            if (!distinctValues["region"]) {
              distinctValues["region"] = new Set();
            }
            distinctValues["region"].add(region);
          }
        }
      });

      return Object.entries(distinctValues).reduce((acc, [key, value]) => ({ ...acc, [key]: Array.from(value) }), {});
    })
  }, [events]);

  if (filteringActive === true) {
    return (
      <Box padding="1rem">
        {features &&
          <FilterPanel
            onReturnClick={() => { setFilteringActive(false) }}
            onApplyFilters={onApplyFilters}
            eventsFilterParams={eventsFilter?.getCurrentParams()}
            features={features}
            areaSelection={areaSelection}
            onRemoveAreaSearch={onRemoveAreaSearch}
            onAreaSearchClick={onAreaSearchClick}
            areaSearchActive={areaSearchActive}
            zones={zones}
            maxContainerHeight={getMaxHeight()}
            disableEventsTutorial={true}
            noPadding
            filterOptions={filterOptions}
            defaultState={true}
          />
        }
      </Box>
    )
  }

  if (isMobile && features) {
    return (
      <Box height="40vh">
        <EventsGroupList
          events={events}
          onEventClick={onEventClick}
          eventsFilter={eventsFilter}
          isLoadingEvents={isLoadingEvents}
          stream={stream}
          features={features}
          timezone={timezone}
          onMouseEnterEvent={onMouseEnterEvent}
          onMouseLeaveEvent={onMouseLeaveEvent}
          zones={zones}
          onMarkAsReadClick={onMarkAsReadClick}
          onInformationClick={onInformationClick}
        />
      </Box>
    )
  }

  return (
    <Box display="grid" gridTemplateRows="min-content min-content minmax(25rem, 1fr)">
      <Box id="time-interval-description" display="grid" gridTemplateColumns="auto min-content min-content" alignItems="center" margin="0.8rem" gap={"0.25rem"}>
        <TimeRangeSelector onTimeRangeChange={onTimeRangeChange} timeRange={timeRange} selectedSite={selectedSite} multiDay maxTimeRangeInHours={24} />
        <Box>
          <Tooltip title="Previous">
            <IconButton onClick={onPreviousTimeRange} size="small">
              <ChevronLeft />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title="Next">
            <IconButton onClick={onNextTimeRange} size="small">
              <ChevronRight />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box marginLeft="0.8rem" marginRight="0.8rem">
        <TagFilter
          eventsFilterParams={eventsFilter?.getCurrentParams()}
          onApplyFilters={onApplyFilters}
          areaSelection={areaSelection}
          onRemoveAreaSearch={onRemoveAreaSearch}
          onFilteringClicked={() => setFilteringActive(true)}
          zones={zones}
        />
      </Box>
      {features !== undefined ? (
        <EventsGroupList
          events={events}
          onEventClick={onEventClick}
          eventsFilter={eventsFilter}
          isLoadingEvents={isLoadingEvents}
          stream={stream}
          features={features}
          timezone={timezone}
          onMouseEnterEvent={onMouseEnterEvent}
          onMouseLeaveEvent={onMouseLeaveEvent}
          zones={zones}
          onMarkAsReadClick={onMarkAsReadClick}
          onInformationClick={onInformationClick}
        />
      ) : (
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Box margin="12px">
            <Typography variant="h6" ><Skeleton style={{ borderRadius: 0 }} width="15rem" /></Typography>
          </Box>
          <Box margin="12px">
            <Typography variant="h6" ><Skeleton style={{ borderRadius: 0 }} width="15rem" /></Typography>
          </Box>
          <Box margin="12px">
            <Typography variant="h6" ><Skeleton style={{ borderRadius: 0 }} width="15rem" /></Typography>
          </Box>
          <Box margin="12px">
            <Typography variant="h6" ><Skeleton style={{ borderRadius: 0 }} width="15rem" /></Typography>
          </Box>
          <Box margin="12px">
            <Typography variant="h6" ><Skeleton style={{ borderRadius: 0 }} width="15rem" /></Typography>
          </Box>
          <Box margin="12px">
            <Typography variant="h6" ><Skeleton style={{ borderRadius: 0 }} width="15rem" /></Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default EventsTab;
