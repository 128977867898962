import VideoPoseEstimations from '@classes/VideoPoseEstimations';
import { API } from 'aws-amplify';

async function getPoseEstimations(kvsName: string, start: number, end: number): Promise<VideoPoseEstimations> {
  try {
    const path: string = `/events/poses/${kvsName}/${start}/${end}`;
    const data = await API.get(
      'api-service',
      path,
      {}
    );

    return new VideoPoseEstimations(data.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default getPoseEstimations;