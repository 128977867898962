function getIdsFromStreamName(name: string): { streamId: string, companyId: string, siteId: string } {
  const fullStreamId = name.split("-");
  return {
    streamId: fullStreamId.slice(2).join("-"),
    companyId: fullStreamId[0],
    siteId: fullStreamId[1]
  }
} 

export default getIdsFromStreamName;
