import { API } from "aws-amplify";

async function createZone(streamId: string, zone: Zone): Promise<Zone[]> {
  try {
    const path = `/geofences/${streamId}`;

    const options = {
      body: {
        name: zone.name,
        objectsOfInterest: zone.objectsOfInterest,
        purpose: zone.purpose,
        coordinates: zone.coordinates,
        description: zone.description,
        type: zone.type,
        color: zone.color,
        texture: zone.texture,
        schedule: zone.schedule,
        activeUntil: zone.activeUntil,
        minimumObjectSize: zone.minimumObjectSize
      }
    }
  
    const data: APIResponse<Zone[]> = await API.post(
      'api-service',
      path,
      options
    );
  
    return data.data as Zone[];
  } catch (e) {
    throw e;
  }
}

export default createZone;
