import Tutorial from "./Tutorial";

class TimelineZonesTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#create-zones-description',
        popover: {
          title: 'Create zones',
          description: 'You can draw shapes right in the stream to create custom zones and geofences. You can then create rules based on these zones for events that are of interest, such as a no-go zone.',
          position: 'bottom'
        }
      },
      {
        element: '#all-zones-list-description',
        popover: {
          title: 'Zones list',
          description: 'List of all Zones created by the user.',
          position: 'bottom'
        }
      }

    ])
  }
}

export default TimelineZonesTutorial;
