import React from 'react';
import API from '@API/index';
import EventsFilter from '@classes/StreamEvents/EventsFilter';

type EventsLoaderProps = {
  stream: VideoStream;
  range: { start: Date, end: Date },
  onLoadedData: (events: VideoStreamEvent[]) => void;
  onError?: (error: any) => void;
  areaSelection?: Rectangle;
  onLoadStart?: () => void;
  eventsFilter?: EventsFilter;
}

const EventsLoader: React.FC<EventsLoaderProps> = (props) => {
  const { stream, range, onError, onLoadedData, areaSelection, onLoadStart, eventsFilter } = props;

  const currentStreamRef = React.useRef<VideoStream>(stream);
  const areaSelectionRef = React.useRef(areaSelection)
  const onLoadStartRef = React.useRef(onLoadStart);
  const onErrorRef = React.useRef(onError);
  const onLoadedDataRef = React.useRef(onLoadedData)
  const rangeRef = React.useRef(range);
  const eventsFilterRef = React.useRef(eventsFilter);

  React.useEffect(() => { onErrorRef.current = onError }, [onError])
  React.useEffect(() => { onLoadedDataRef.current = onLoadedData }, [onLoadedData])
  React.useEffect(() => { areaSelectionRef.current = areaSelection }, [areaSelection])
  React.useEffect(() => { currentStreamRef.current = stream; }, [stream])
  React.useEffect(() => { onLoadStartRef.current = onLoadStart }, [onLoadStart]);
  React.useEffect(() => { rangeRef.current = range }, [range])
  React.useEffect(() => { eventsFilterRef.current = eventsFilter }, [eventsFilter])

  const fetchRegularEvents = React.useCallback(async () => {
    const target = { ...currentStreamRef.current };
    try {
      const data = await API.Events.fetchEvents(currentStreamRef.current, rangeRef.current.start, rangeRef.current.end, eventsFilterRef.current)
      if (areaSelectionRef.current === undefined && target.kvsName === currentStreamRef.current?.kvsName) onLoadedDataRef.current(data.data || []);
    } catch (error: any) {
      if (onErrorRef.current && target.kvsName === currentStreamRef.current?.kvsName) onErrorRef.current(error)
    }
  }, [])

  const fetchEventsWithRoi = React.useCallback(async (areaSelection: Rectangle) => {
    try {
      const normalizedArea = normalizeRect(areaSelection);

      const data = await API.Events.fetchEvents(
        currentStreamRef.current,
        rangeRef.current.start,
        rangeRef.current.end,
        eventsFilterRef.current,
        {
          x: normalizedArea.x,
          y: normalizedArea.y,
          w: normalizedArea.width,
          h: normalizedArea.height,
        }
      )
      if (areaSelectionRef.current !== undefined) onLoadedDataRef.current(data.data || []);
    } catch (error: any) {
      if (onErrorRef.current) onErrorRef.current(error)
    }
  }, [])

  const fetchEvents = React.useCallback(async () => {
    if (onLoadStartRef.current) onLoadStartRef.current();
    if (areaSelectionRef.current) await fetchEventsWithRoi(areaSelectionRef.current);
    else await fetchRegularEvents();
  }, [fetchEventsWithRoi, fetchRegularEvents])

  React.useEffect(loadEvents, [range, areaSelection, stream, eventsFilter, fetchEvents]);
  React.useEffect(saveAreaSelection, [areaSelection])

  function saveAreaSelection(): void { areaSelectionRef.current = areaSelection; }
  function loadEvents() { fetchEvents(); }

  function normalizeRect(area: { x: number, y: number, height: number, width: number }): {
    x: number,
    y: number,
    height: number,
    width: number
  } {
    const { x, y, width, height } = area;
    const normalizedArea = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };

    if (x > 0 && y > 0 && width > 0 && height > 0) {
      // drawing from top-left to bottom-right
      normalizedArea.x = x;
      normalizedArea.y = y;
      normalizedArea.width = width;
      normalizedArea.height = height;
    } else if (x > 0 && y > 0 && width < 0 && height > 0) {
      // drawing from top-right to bottom-left.
      normalizedArea.x = x + width;
      normalizedArea.y = y;
      normalizedArea.width = width * -1;
      normalizedArea.height = height;
    } else if (x > 0 && y > 0 && width > 0 && height < 0) {
      // drawing from bottom-left to top-right
      normalizedArea.x = x;
      normalizedArea.y = y + height;
      normalizedArea.width = width;
      normalizedArea.height = height * -1;
    } else if (x > 0 && y > 0 && width < 0 && height < 0) {
      // drawing from bottom-right to top-left
      normalizedArea.x = x + width;
      normalizedArea.y = y + height;
      normalizedArea.width = width * -1;
      normalizedArea.height = height * -1;
    }

    return normalizedArea;
  }

  return null;
}

export default EventsLoader;
