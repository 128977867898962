import { API } from "aws-amplify";

async function updateUserRole(userId: string, roles: string | Record<string, Record<string, string[]>>): Promise<boolean> {
  try {
    const path: string = `/users/${userId}/roles`;

    const data = await API.put(
      'api-service',
      path,
      {
        body: {
          roles
        }
      }
    );

    return data.data as boolean;
  } catch (error: any) {
    throw error;
  }
}

export default updateUserRole;
