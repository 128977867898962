import { useEffect } from "react";

export const useKeyPress = (key: string, action: () => void, state: any[]) => {
  function execute() {
    function onKeyup(e: any) {
      if (e.key === key) action();
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }

  useEffect(execute, [state, action, key]);
}
