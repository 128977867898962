/**
 * Returns 01-31 to UTC Date of given Date.
 * @param date Date object.
 * @returns string from 01 to 31.
 */
function getTwoDigitsUTCDate(date: Date): string {
  const day = date.getUTCDate();
  if (day < 10) {
    return `0${day}`;
  }

  return day.toString();
}

export default getTwoDigitsUTCDate;