import moment from 'moment';
import React from 'react';
import { Timeline, DataSet } from 'vis-timeline/standalone';
import './timeline.css';
import StreamEvents from '@classes/StreamEvents/StreamEvents';
import EventsVisibility from './EventsVisibility';
import ZoomLevel from '@classes/Timeline/ZoomLevel';
import Utils from '@utils/index';
import { first } from 'lodash';
import { useMobileQuery } from '@hooks/useMobileQuery';
import styled from 'styled-components';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';

const TimelineContainer = styled.div<{ isMobile: boolean, minWidth: string | undefined }>`
  .vis-left {
    width: ${props => props.isMobile ? '0px' : undefined};
  }

  .Time .vis-inner {
    min-width: ${props => props.minWidth};
  }

  .Bottom .vis-inner {
    min-width: ${props => props.minWidth};
  }
`;

export const TIMELINE_GROUPS = [
  {
    id: 'uptime',
    content: "<div style='height: 0' />",
    className: "Uptime",
    visible: true
  },
  {
    id: 'blank-Motion',
    className: "BlankSpace",
    content: "<div style='height: 0' />",
    visible: true
  },
  {
    id: 'Motion',
    className: "Motion",
    visible: true
  },
  {
    id: 'blank-Zones',
    className: "BlankSpace",
    content: "<div style='height: 0' />",
    visible: true
  },
  {
    id: 'Zones',
    className: "Zones",
    visible: true
  },
  {
    id: 'blank-Safety infraction',
    className: "BlankSpace",
    content: "<div style='height: 0' />",
    visible: true
  },
  {
    id: 'Safety infraction',
    className: "ProtectionEquipment",
    visible: true
  },
  {
    id: 'blank-Person',
    className: "BlankSpace",
    content: "<div style='height: 0' />",
    visible: true
  },
  {
    id: 'Person',
    className: "PersonDetected",
    visible: true
  },

  {
    id: 'blank-Vehicle',
    className: "BlankSpace",
    content: "<div style='height: 0' />",
    visible: true
  },
  {
    id: 'Vehicle',
    className: "VehicleDetected",
    visible: true
  },
  // {
  //   id: 'blank-Social distance',
  //   className: "BlankSpace",
  //   content: "<div style='height: 0' />",
  //   visible: true
  // },
  // {
  //   id: 'Social distance',
  //   className: "SocialDistance",
  //   visible: true
  // },
  // {
  //   id: 'blank-Fire',
  //   className: "BlankSpace",
  //   content: "<div style='height: 0' />",
  //   visible: true
  // },
  // {
  //   id: 'Fire',
  //   className: "Fire",
  //   visible: true
  // },
  // {
  //   id: 'blank-Vehicle license plate',
  //   className: "BlankSpace",
  //   content: "<div style='height: 0' />",
  //   visible: true
  // },
  // {
  //   id: 'Vehicle license plate',
  //   className: "LicensePlates",
  //   visible: true
  // },
  {
    id: 'Bottom',
    className: "Bottom",
    content: "<div style='height: 0' />",
    visible: true
  }
]

export function generateTimeItem(start: Date, end: Date) {
  return {
    //group: 'Vehicle License Plate',
    id: 'timebar',
    end: end,
    start: start,
    selectable: true,
    type: 'background',
    style: 'background: rgba(0, 72, 227, 0.05); background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #0048E3; mix-blend-mode: multiply; border: none;',
    editable: {
      updateTime: true,
      remove: false,
      //updateGroup: false
    }
  }
}

export function generateMinItem(end: Date) {
  return {
    //group: 'Vehicle License Plate',
    id: 'minbackground',
    end: end,
    start: new Date(0),
    selectable: true,
    type: 'background',
    style: 'background: rgba(128,128,128, 0.5)',
    editable: {
      updateTime: true,
      remove: false,
      //updateGroup: false
    }
  }
}

const TODAY = new Date(Date.now());
const START_DATE = new Date(Date.now());
const END_DATE = new Date(Date.now());
START_DATE.setDate(TODAY.getDate() - 15);
END_DATE.setDate(TODAY.getDate() + 15);

export type TimelineVisibilityHint = {
  motion?: boolean;
  zones?: boolean;
  safetyInfraction?: boolean;
  personDetected?: boolean;
  vehicleDetected?: boolean;
  // legacy
  socialDistance?: boolean;
  fire?: boolean;
  vehicleLicensePlate?: boolean;
}

type VideoTimelineProps = {
  timezone?: string;
  onClick: (date: Date) => void;
  timeEvents: VideoStreamTimeChunk[];
  events: VideoStreamEvent[];
  onTimeRangeChange?: (start: Date, end: Date) => void;
  timeRange?: { start: Date, end: Date };
  timerangeBackground?: boolean;
  features?: StreamFeatures;
  min?: number;
  highlightEvents?: VideoStreamEvent[];
  mouseMarker?: boolean;
  onEventClick?: (id: string) => void;
  noPadding?: boolean;
  renderEventsTags?: boolean;
  hint?: TimelineVisibilityHint;
  disableZoomSlider?: boolean;
  disableVisibility?: boolean;
}

export interface IVideoTimelineHandles {
  updateTime: (timestamp: number, id?: string) => void;
  animateTo: (timestamp: number) => void;
  markEventTime: (event: VideoStreamEvent) => void;
  clearEventTime: () => void;
  moveTo: (start: Date, end: Date) => void;
}

const customTimeID = 'custom-time';

const VideoTimeline: React.ForwardRefRenderFunction<IVideoTimelineHandles, VideoTimelineProps> = (props, ref) => {
  const {
    timezone,
    onClick,
    events,
    timeRange,
    timerangeBackground,
    onTimeRangeChange,
    timeEvents,
    features,
    min,
    highlightEvents,
    mouseMarker,
    onEventClick,
    noPadding,
    renderEventsTags,
    hint,
    disableVisibility,
    disableZoomSlider
  } = props;

  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();

  const [timeline, setTimeline] = React.useState<any>(null);
  const [timelineZoom, setTimelineZoom] = React.useState<number>(0);
  const [visibleGroups, setVisibleGroups] = React.useState<Map<string, boolean>>();
  const [timerangeBackgroundVisible, setTimerangeBackgroundVisible] = React.useState<boolean>(true);

  const containerRef = React.useRef(null);
  const rangeChanged = React.useRef<boolean>(false);
  const previousZoom = React.useRef<number>(0);
  const zoomByUser = React.useRef<boolean>(false);
  const timerangeMarkerMoved = React.useRef<boolean>(false);
  const timeRangeRef = React.useRef<{ start: Date, end: Date }>();
  const eventsRef = React.useRef<VideoStreamEvent[]>([])
  const timeEventsRef = React.useRef<VideoStreamTimeChunk[]>([]);
  const minRef = React.useRef<number>(min || 0);
  const mouseFeedbackRef = React.useRef<Element | undefined>(undefined);
  const timezoneRef = React.useRef<string | undefined>(timezone);
  const timelineRef = React.useRef(timeline);
  const highlightEventsRef = React.useRef(highlightEvents);
  const renderEventsTagsRef = React.useRef(renderEventsTags);
  const timerangeBackgroundVisibleRef = React.useRef(timerangeBackgroundVisible);
  const mouseMarkerRef = React.useRef(mouseMarker);
  const onClickRef = React.useRef(onClick);
  const onEventClickRef = React.useRef(onEventClick);
  const onTimeRangeChangeRef = React.useRef(onTimeRangeChange);

  React.useImperativeHandle(ref, () => ({
    updateTime,
    animateTo,
    markEventTime,
    clearEventTime,
    moveTo
  }))

  React.useEffect(setBackground, [timerangeBackground]);
  React.useEffect(initTimeline, []);
  React.useEffect(updateTimelineEvents, [events, timeEvents])
  React.useEffect(changeGroupsVisibility, [visibleGroups])
  React.useEffect(handleZoom, [timelineZoom])
  React.useEffect(zoomToTimeRange, [timeRange, timeline]);
  React.useEffect(setTimerangeMarkers, [timeRange, timeline]);
  React.useEffect(setTimeRangeRef, [timeRange]);
  React.useEffect(() => { eventsRef.current = events }, [events])
  React.useEffect(() => {
    if (timeRange) {
      timeEventsRef.current = timeEvents.filter(el => {
        if (el.color) {
          if (el.start.getTime() < timeRange.start.getTime()) {
            return false;
          }
        }

        return true;
      })
    } else {
      timeEventsRef.current = timeEvents 
    }
  }, [timeEvents, timeRange]);
  React.useEffect(() => { minRef.current = min || 0 }, [min]);
  React.useEffect(() => { timezoneRef.current = timezone }, [timezone]);
  React.useEffect(() => { timelineRef.current = timeline }, [timeline])
  React.useEffect(() => { highlightEventsRef.current = highlightEvents }, [highlightEvents])
  React.useEffect(() => { renderEventsTagsRef.current = renderEventsTags }, [renderEventsTags]);
  React.useEffect(() => { timerangeBackgroundVisibleRef.current = timerangeBackgroundVisible }, [timerangeBackgroundVisible]);
  React.useEffect(() => { mouseMarkerRef.current = mouseMarker }, [mouseMarker]);
  React.useEffect(() => { onClickRef.current = onClick }, [onClick])
  React.useEffect(() => { onEventClickRef.current = onEventClick }, [onEventClick]);
  React.useEffect(() => { onTimeRangeChangeRef.current = onTimeRangeChange }, [onTimeRangeChange]);

  function setTimeRangeRef(): void {
    timeRangeRef.current = timeRange;
  }

  function setTimerangeMarkers(): void {
    const parsedTimezone = timezoneRef.current?.split(" ")[0] || "";

    if (timeline && timeRange) {
      try {
        timeline.setCustomTime(timeRange.start, 'start');
        timeline.setCustomTimeTitle(moment.tz(timeRange.start, parsedTimezone).format('MMM D, h:mm:ss A'), 'start');
      } catch (e) {
        timeline.addCustomTime(timeRange.start, 'start');
        timeline.setCustomTimeTitle(moment.tz(timeRange.start, parsedTimezone).format('MMM D, h:mm:ss A'), 'start');
      }

      try {
        timeline.setCustomTime(timeRange.end, 'end')
        timeline.setCustomTimeTitle(moment.tz(timeRange.end, parsedTimezone).format('MMM D, h:mm:ss A'), 'end');
      } catch (e) {
        timeline.addCustomTime(timeRange.end, 'end')
        timeline.setCustomTimeTitle(moment.tz(timeRange.end, parsedTimezone).format('MMM D, h:mm:ss A'), 'end');
      }
    }
  }

  function setBackground(): void {
    if (timerangeBackground !== undefined) setTimerangeBackgroundVisible(timerangeBackground);
  }

  function zoomToTimeRange() {
    if (timerangeMarkerMoved.current === true) {
      timerangeMarkerMoved.current = false;
      return;
    }

    if (timeline && timeRange) {
      const offset = timeRange.end.getTime() - timeRange.start.getTime();
      const buffer = offset * 0.05;
      timeline.setWindow(new Date(timeRange.start.getTime() - buffer), new Date(timeRange.end.getTime() + buffer), () => {
        setTimelineZoom(new ZoomLevel(timeRange.start.getTime(), timeRange.end.getTime()).getZoomLevel());
      });
    }
  }

  function moveTo(start: Date, end: Date) {
    if (timeline) {
      const offset = end.getTime() - start.getTime();
      const buffer = offset * 1;
      timeline.setWindow(new Date(start.getTime() - buffer), new Date(end.getTime() + buffer), () => {
        setTimelineZoom(new ZoomLevel(start.getTime(), end.getTime()).getZoomLevel());
      });
    }
  }

  function handleZoom() {
    if (zoomByUser.current === true) {
      zoomByUser.current = false;
      previousZoom.current = timelineZoom;
      return;
    }

    if (timelineRef.current) {
      const previous = previousZoom.current || 0;
      if (previous < timelineZoom) {
        const offset = timelineZoom - previous;
        //timelineRef.zoomIn(Number(offset.toFixed(2)), { animation: false });
        if (timelineZoom === 11.2) timelineRef.current.zoomIn(1, { animation: false })
        else timelineRef.current.zoomIn(Number(offset.toFixed(2)), { animation: false });
      } else if (previous > timelineZoom) {
        const offset = previous - timelineZoom;
        //timelineRef.zoomOut(Number(offset.toFixed(2)), { animation: false })
        if (timelineZoom === 0) timelineRef.current.zoomOut(1, { animation: false })
        else timelineRef.current.zoomOut(Number(offset.toFixed(2)), { animation: false })
      }

      previousZoom.current = timelineZoom;
    }
  }

  function getWindow(): { start: number, end: number } {
    if (timeRangeRef.current) {
      const offset = timeRangeRef.current.end.getTime() - timeRangeRef.current.start.getTime();
      const buffer = offset * 0.05;

      return {
        start: timeRangeRef.current.start.getTime() - buffer,
        end: timeRangeRef.current.end.getTime() + buffer
      }
    }

    const start = Date.now() - (3600000 * 24)
    const end = Date.now()
    const offset = end - start;
    const buffer = offset * 0.05;

    return {
      start: start - buffer,
      end: end - buffer
    }

  }

  function initTimeline() {
    const options = {
      autoResize: true,
      zoomMin: 60000,
      zoomMax: (3600000 * 24) * 30,
      zoomable: true,
      stack: false,
      minHeight: 40,
      maxMinorChars: 15,
      start: getWindow().start,
      end: getWindow().end,
      snap: null,
      orientation: {
        axis: 'top',
        item: 'top'
      },
      margin: {
        item: {
          vertical: 0
        }
      },
      cluster: false,
      showMajorLabels: false,
      verticalScroll: false,
      showCurrentTime: false,
      format: {
        minorLabels: {
          second: 'ss',
          minute: 'h:mm A',
          hour: 'h:mm A',
          day: 'ddd D',
          week: 'ddd D',
        },
      },
      moment: function (date: Date) {
        if (timezoneRef.current) {
          const parsedTimezone = timezoneRef.current.split(" ")[0];
          return moment.tz(date, parsedTimezone);
        }
        // const offset = utcOffset ? utcOffset : 0;
        return moment(date).utcOffset(0);
      },

      // template: function (data: any, item: HTMLDivElement, editedData: any) {
      //   if (data.content) {
      //     const parent = item.parentElement;
      //     if (parent) {
      //       const grandParent = parent.parentElement;
      //       if (grandParent) {
      //         if (grandParent.offsetWidth > item.offsetWidth) {
      //           console.log(grandParent);
      //           console.log(item);
      //           return `<div>${data.content}</div>`
      //         }
      //       }
      //     }
      //   }
      // },

      locale: 'en_US'
    };

    const data: any[] = [];

    if (timerangeBackgroundVisibleRef.current && timeRangeRef.current) data.push(generateTimeItem(timeRangeRef.current.start, timeRangeRef.current.end))
    //if (min) data.push(generateMinItem(new Date(min)));

    const dataSet = new DataSet(data);

    //@ts-ignore
    const ref = new Timeline(containerRef.current, dataSet, TIMELINE_GROUPS, options);

    ref.on('click', function (properties: any) {
      if (onEventClickRef.current && properties.item) {
        onEventClickRef.current(properties.item.includes('#') ? properties.item.split('#')[0] : properties.item);
      }

      // Prevent clicking on group section triggering goToTime logic.
      if (properties.x < 0) return;
      if (properties.group === 'Time' || properties.item === 'timebar') return;
      if (properties.customTime && properties.customTime === 'start') return;
      if (properties.customTime && properties.customTime === 'end') return;
      if (properties.what === null) return;

      if (rangeChanged.current) {
        rangeChanged.current = false;
      } else {
        onClickRef.current(
          properties.time
        );
      }
    });

    ref.on('timechanged', handleTimeChanged);

    ref.on('rangechanged', (properties: any) => {
      rangeChanged.current = true;

      setTimeout(() => {
        rangeChanged.current = false;
      }, 250);

      const { start, end } = properties;

      if (properties.byUser === true) {
        zoomByUser.current = true;

        setTimelineZoom(new ZoomLevel(start, end).getZoomLevel());
      }

      lateUpdate(ref);
    });

    ref.on('mouseMove', (properties: any) => {
      if (mouseMarkerRef.current === false) return;

      const parsedTimezone = timezoneRef.current?.split(" ")[0] || "";
      if (properties.time && ref) {
        try {
          ref.setCustomTime(properties.time, 'mouse-feedback');
          ref.setCustomTimeTitle(moment.tz(properties.time, parsedTimezone).format('MMM D, h:mm:ss A'), 'mouse-feedback');
        } catch (e) {
          ref.addCustomTime(properties.time, 'mouse-feedback');
          //@ts-ignore          
          ref.customTimes[ref.customTimes.length - 1].hammer.off("panstart panmove panend");
        }

        if (!mouseFeedbackRef.current) {
          mouseFeedbackRef.current = first(document.getElementsByClassName('mouse-feedback'))
        }

        if (mouseFeedbackRef.current) {
          if (properties.time > minRef.current) {
            mouseFeedbackRef.current.classList.remove("unavailable")
          } else {
            mouseFeedbackRef.current.classList.add("unavailable");
          }
        }
      }
    })

    setTimeline(ref);
  }

  /**
   * Handles timechanged events from the Timeline, that means any
   * element from the timeline had its time changed either by the
   * user or automatically by the code.
   */
  function handleTimeChanged(properties: any): void {
    const { id: markerId, time: markerTime } = properties;

    if (timeRangeRef.current && onTimeRangeChangeRef.current) {
      const { start, end } = timeRangeRef.current;

      if (markerId === 'start') {
        timerangeMarkerMoved.current = true;
        if (markerTime > end) onTimeRangeChangeRef.current(end, markerTime);
        else onTimeRangeChangeRef.current(markerTime, end);
      }

      if (markerId === 'end') {
        timerangeMarkerMoved.current = true;
        if (start > markerTime) onTimeRangeChangeRef.current(markerTime, start);
        else onTimeRangeChangeRef.current(start, markerTime);
      }
    }
  }

  function setCurrentTime(time: Date, id?: string) {
    try {
      timeline.getCustomTime(id || customTimeID);
      timeline.setCustomTime(time, id || customTimeID);

      let str = "";

      const item = document.getElementsByClassName('vis-custom-time-marker');
      const el = item.item(0);
      if (el) {
        el.remove()
      }

      timeline.setCustomTimeMarker(str, id || customTimeID);
    } catch {
      timeline.addCustomTime(time, id || customTimeID);
    }
  }

  function updateTimelineEvents() {
    if (timelineRef.current) {
      const range = timelineRef.current.getWindow();
      const e = StreamEvents.extractEvents(events, { start: range.start, end: range.end }, highlightEventsRef.current, renderEventsTagsRef.current);
      const data: any[] = [...e];

      const diff = StreamEvents.getDaysDiference(range.start, range.end)
      if (diff > 100000) data.push(...Utils.Timeline.extractUptimeFromTimeChunks(timeEventsRef.current.filter(el => !el.color)))
      else data.push(...Utils.Timeline.extractUptimeFromTimeChunks(timeEventsRef.current))

      if (timerangeBackgroundVisibleRef.current === true && timeRangeRef.current) data.push(generateTimeItem(timeRangeRef.current.start, timeRangeRef.current.end));
      if (minRef.current) data.push(generateMinItem(new Date(minRef.current)));
      const newData = new DataSet(data);
      timelineRef.current.setItems(newData);
    }
  }

  function lateUpdate(ref: any) {
    if (ref && timeRangeRef.current) {
      const range = ref.getWindow();
      const e = StreamEvents.extractEvents(eventsRef.current, { start: range.start, end: range.end }, highlightEventsRef.current, renderEventsTagsRef.current);
      const data: any[] = [...e];

      const diff = StreamEvents.getDaysDiference(range.start, range.end)
      if (diff > 100000) data.push(...Utils.Timeline.extractUptimeFromTimeChunks(timeEventsRef.current.filter(el => !el.color)))
      else data.push(...Utils.Timeline.extractUptimeFromTimeChunks(timeEventsRef.current))

      if (timerangeBackgroundVisibleRef.current === true) data.push(generateTimeItem(timeRangeRef.current.start, timeRangeRef.current.end));
      if (minRef.current) data.push(generateMinItem(new Date(minRef.current)));
      const newData = new DataSet(data);
      ref.setItems(newData);
    }
  }

  function changeGroupsVisibility() {
    if (timelineRef.current) {
      const range = timelineRef.current.getWindow();
      const e = StreamEvents.extractEvents(eventsRef.current, { start: range.start, end: range.end }, highlightEventsRef.current, renderEventsTagsRef.current);
      const data: any[] = [...e];

      const diff = StreamEvents.getDaysDiference(range.start, range.end)
      if (diff > 100000) data.push(...Utils.Timeline.extractUptimeFromTimeChunks(timeEventsRef.current.filter(el => !el.color)))
      else data.push(...Utils.Timeline.extractUptimeFromTimeChunks(timeEventsRef.current))

      if (timerangeBackgroundVisibleRef.current === true && timeRangeRef.current) data.push(generateTimeItem(timeRangeRef.current.start, timeRangeRef.current.end));
      if (minRef.current) data.push(generateMinItem(new Date(minRef.current)));
      const newData = new DataSet(data);

      const newGroups = [...TIMELINE_GROUPS].filter((el) => {
        if (el.id.includes('blank-')) {
          return visibleGroups?.get(el.id.split('blank-')[1]) || false;
        } else if (el.id.includes('Bottom') || el.id.includes('uptime')) {
          return true;
        } else {
          return visibleGroups?.get(el.id) || false;
        }
      });

      timelineRef.current.setGroups(newGroups);
      timelineRef.current.setItems(newData);
    }
  }

  function updateTime(timestamp: number, id?: string) {
    if (timeline) {
      setCurrentTime(new Date(timestamp));
    }
  }

  function animateTo(timestamp: number) {
    if (timeline) {
      timeline.moveTo(timestamp, { animation: true })
    }
  }

  function removeCursorMarker(): void {
    if (timeline) {
      try {
        timeline.removeCustomTime('mouse-feedback');
        mouseFeedbackRef.current = undefined;
      } catch (e) { }
    }
  }

  function markEventTime(event: VideoStreamEvent) {
    if (timeline) {
      try {
        timeline.setCustomTime(event.startDate, 'event-feedback');
      } catch (e) {
        timeline.addCustomTime(event.startDate, 'event-feedback');
      }
    }
  }

  function clearEventTime() {
    if (timeline) {
      try {
        timeline.removeCustomTime('event-feedback');
      } catch (e) { }
    }
  }

  return (
    <TimelineContainer
      isMobile={isMobile || isSmallScreen}
      id="timeline-container-description"
      className="Container"
      ref={containerRef}
      onMouseLeave={removeCursorMarker}
      minWidth={disableZoomSlider ? undefined : "8.75rem"}
      style={{
        paddingBottom: noPadding ? undefined : '1rem',
        paddingLeft: noPadding ? undefined : '1rem',
        paddingRight: noPadding ? undefined : '1rem',
      }}
    >
      <EventsVisibility
        onZoomChange={(value: number) => { setTimelineZoom(value); }}
        onGroupVisibilityChange={(map) => setVisibleGroups(map)}
        zoomValue={timelineZoom}
        features={features}
        hint={hint}
        style={{ display: isMobile || isSmallScreen ? 'none' : undefined }}
        disableAllEvents={isMobile || isSmallScreen}
        disableZoomSlider={disableZoomSlider}
        disableVisibility={disableVisibility}
      />
    </TimelineContainer>
  )
}

export default React.forwardRef(VideoTimeline);
