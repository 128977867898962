import { Box } from '@mui/material';
import { CompaniesTabs } from '@store/views/overviewView/types';
import React from 'react';
import CompaniesList from './CompaniesList';

type CompaniesContentProps = {
  activeTab: CompaniesTabs;
}

const CompaniesContent: React.FC<CompaniesContentProps> = (props) => {
  const { activeTab } = props;

  return (
    <Box width="100%" height="100%">
      {activeTab === CompaniesTabs.Companies && <CompaniesList />}
    </Box>
  )
}

export default CompaniesContent;
