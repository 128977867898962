import { API } from "aws-amplify";

export async function removeDetectionReview(pictureUrl: string, asCompany?: string): Promise<void> {
  try {
    const path: string = `/workers/images/review` + (asCompany ? `?asCompany=${asCompany}` : "");
    await API.post('api-service', path, { body: { imageURI: pictureUrl, flaggedForReview: false } })
  } catch (error) {
    throw error;
  }
}
