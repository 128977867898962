import ExportsTab from '@components/timeline/CommandPanel/ExportsTab/ExportsTab';
import { Box, Dialog, DialogProps } from '@mui/material';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import Utils from '@utils/index';

type ExportEventDialogProps = {
  eventToExport?: VideoStreamEvent;
} & DialogProps;

const ExportEventDialog: React.FC<ExportEventDialogProps> = (props) => {
  const { eventToExport, ...restOfProps } = props;

  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const sites = useSelector((store: IStoreState) => store.sites.sites);

  function getOptimalTimerange(): { start: number, end: number } {
    const start = Utils.Timestamp.edgeTimestampToMiliseconds(eventToExport?.startDate || 0);
    const end = eventToExport?.endDate || eventToExport?.mergedEndDate || 0;

    const length = end - start;

    if (length >= 10000) return { start, end };

    const diff = 10000 - length;
    const halfDiff = diff / 2;


    return { start: start - halfDiff, end: end + halfDiff };
  }

  function getStart(): number { return getOptimalTimerange().start };
  function getEnd(): number { return getOptimalTimerange().end }

  return (
    <Dialog
      {...restOfProps}
    >
      <Box padding="1rem" width="30vw" minWidth={500}>
        <ExportsTab
          type="video_clip"
          stream={streams.find(el => el.kvsName === eventToExport?.sourceCamera)}
          selectedSite={sites.get(eventToExport?.companyId || "")?.find(el => el.siteId === eventToExport?.siteId)}
          startTimestamp={getStart()}
          endTimestamp={getEnd()}
          disableMinimumVideoLength={true}
        />
      </Box>
    </Dialog>
  )
}

export default ExportEventDialog;
