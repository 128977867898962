import deleteView from "./deleteView";
import getViewsFromCompany from "./getViewsFromCompany";
import getViewsFromSite from "./getViewsFromSite";
import postView from "./postView";
import updateView from "./updateView";

export interface IView {
  postView(view: UserView): Promise<void>;
  getViewsFromSite(companyId: string, siteId: string): Promise<UserView[]>;
  deleteView(view: UserView): Promise<void>;
  getViewsFromCompany(companyId: string): Promise<UserView[]>;
  updateView(view: UserView): Promise<void>
}

const Views: IView = {
  postView,
  getViewsFromCompany,
  deleteView,
  getViewsFromSite,
  updateView
}

export default Views;
