import { PubSub } from "aws-amplify";
import { ZenObservable } from '../../../node_modules/zen-observable-ts'

function subscribeToTopic(topic: string | string[], onMessage: Function, onError?: Function, onComplete?: Function): ZenObservable.Subscription {
  console.log('Subscribing to: ', topic)
  const subscription = PubSub.subscribe(topic).subscribe({
    next: (data) => { onMessage(data.value); },
    error: (error) => { if (onError) onError(error) },
    complete: () => { if (onComplete) onComplete(); }
  })

  return subscription;
}

export default subscribeToTopic;
