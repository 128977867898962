import { API } from 'aws-amplify'

export type PartialVideoDownload = {
  streamName: string;
  status: string;
  location: string;
  thumbnailLocation: string;
  videoDownloadId: string;
}

async function getDownloadStatus(kvsName: string, videoDownloadId: string): Promise<PartialVideoDownload | undefined> {
  try {
    const path: string = `/download/video/status/${kvsName}/${videoDownloadId}`;
    const data = await API.get('api-service', path, {}) as APIResponse<{ status: string, location: string, thumbnailLocation: string }>;

    if (data.data) {
      return {...data.data, streamName: kvsName, videoDownloadId: videoDownloadId};
    }
  } catch (e) {
    throw e;
  }
}

export default getDownloadStatus;
