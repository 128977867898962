import styled from 'styled-components';
import { Paper } from '@mui/material';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const GridContainer = styled(Container)`
  display: grid;
`;

export const FlexContainer = styled(Container)`
  display: flex;
`;

export const StreamContainer = styled(Paper)`
  display: grid;
  border-radius: 1rem;
  grid-template-rows: min-content auto;

  :hover {
    box-shadow: rgb(57 113 235) 0 0 0pt 2pt;
    cursor: pointer;
  }
`

export const StreamItemHeader = styled(GridContainer)`
  padding: 0.4rem;
  padding-left: 0.6rem;
  grid-column-gap: 0.5rem;
  grid-template-columns: min-content auto min-content min-content;
  align-items: center;
`