import React, { useState } from 'react';
import { Colors } from '@utils/Styles';
import { Box, MenuItem, Popover, PopoverOrigin, Select, SelectProps, Tooltip, Typography } from '@mui/material';
import styled from 'styled-components';
import { KeyboardArrowDown } from '@mui/icons-material';
import Utils from '@utils/index';
import ScheduleInputContent from './ScheduleInputContent';
import { DayLabel } from '@declarations/enums';
import ScheduleHoverInfo from './ScheduleHoverInfo';
import { useAsync } from 'react-async-hook';
import API from '@API/index';
import { useDispatch } from 'react-redux';
import { setScheduleTemplates } from '@store/scheduleTemplates/actions';

const PREFIX = 'ScheduleInput';

const classes = {
  noMaxWidth: `${PREFIX}-noMaxWidth`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() =>
({
  [`& .${classes.noMaxWidth}`]: {
    maxWidth: 'none',
  }
}));

interface CustomSelectProps {
  outlined?: boolean;
}

export const CustomSelect = styled(({ outlined, ...props }: CustomSelectProps & SelectProps<string | any[] | undefined>) =>
  <Select {...props} />) <CustomSelectProps>`
  ${props => props.outlined ?
    `.MuiSelect-select.MuiSelect-select {
    padding-right: 0;
    padding-top: 0.5rem;
  }
  .MuiSelect-icon {
    display: none;
  }

  .MuiInputBase-root{
    font-size: inherit;
  }` : ""}
`

const CustomPopover = styled(Popover)`
  div[class*=makeStyles-header-] {
    color: white;
    background-color:  ${Colors.PRIMARY};
    .MuiSvgIcon-colorAction {
      filter: invert(1);
    }
  }
`

interface IScheduleInputProps {
  value: Record<DayLabel, Array<ActiveDayTime>>;
  onChange: (value: Record<DayLabel, Array<ActiveDayTime>>) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  fullWidth?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  maxWidth?: string;
  siteId?: string;
}

const ScheduleInput: React.FC<IScheduleInputProps> = (props) => {
  const { value, onChange, anchorOrigin, transformOrigin, fullWidth, outlined, disabled, maxWidth, siteId } = props;

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [anchorElDateRange, setAnchorElDateRange] = useState<Element | null>(null);

  const onClick = (e: any) => {
    if (disabled === true) return;
    e.stopPropagation();
    setIsOpen(!isOpen);
    setAnchorElDateRange(e.currentTarget);
  }

  function onScheduleValueChange(value: Record<DayLabel, Array<ActiveDayTime>>) {
    setIsOpen(false);
    setAnchorElDateRange(null);
    onChange(value);
  }

  useAsync(async () => {
    try {
      if (siteId) {
        const schedules = await API.ScheduleTemplates.listScheduleTemplates(siteId);
        dispatch(setScheduleTemplates(schedules))
      }
    } catch (error) {
      console.log(error);
    }
  }, [siteId])

  return (
    (<Root>
      <Tooltip
        classes={{ tooltip: classes.noMaxWidth }}
        placement="top"
        title={Object.keys(value).length > 0 ? <ScheduleHoverInfo value={value} /> : ""}
      >
        <Box>
          <CustomSelect
            IconComponent={KeyboardArrowDown}
            fullWidth={true}
            value={"default"}
            onClick={onClick}
            open={false}
            style={fullWidth ? { fontSize: "inherit" } : { fontSize: "inherit", maxWidth: maxWidth || '15vw' }}
            autoFocus={false}
            variant={outlined ? "outlined" : "standard"}
            outlined={outlined}
            disabled={disabled}
            data-cy="schedule-input-select"
          >
            <MenuItem value={"default"}>
              <Typography noWrap={true} variant="button" color="textPrimary" >
                {Utils.Text.getScheduleDisplayText(value, "Select time range")}
              </Typography>
            </MenuItem>
          </CustomSelect>
        </Box>
      </Tooltip>
      <CustomPopover
        open={isOpen}
        anchorEl={anchorElDateRange}
        anchorOrigin={anchorOrigin || {
          horizontal: 'center',
          vertical: 'bottom'
        }}
        transformOrigin={transformOrigin || {
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => setIsOpen(false)}
        className={"date-range"}
      >
        <ScheduleInputContent
          value={value}
          onChange={onScheduleValueChange}
        />
      </CustomPopover>
    </Root>)
  );
}

export default ScheduleInput;