function addGeoJSONSource(map : mapboxgl.Map, id : string, data : GeoJSON.FeatureCollection): void {
    if(!map.getSource(id)){
        map.addSource(id, {
            type : 'geojson',
            data : data
        })
    }
}


export default addGeoJSONSource;