import getCompanyLogo from "./getCompanyLogo";
import listCompanies from "./listCompanies";
import postNewCompany from "./postNewCompany";
import updateCompany from "./updateCompany";
import deleteCompany from "./deleteCompany";

export interface ICompanyAPI {
  listCompanies(): Promise<Company[]>;
  updateCompany(company: Company): Promise<Company>;
  postNewCompany(company: Company): Promise<Company>;  
  getCompanyLogo(companyId: string, type?: string): Promise<{url: string}>;
  deleteCompany(companyId: string): Promise<void>;
}

const Company: ICompanyAPI = {
  listCompanies,
  updateCompany,
  postNewCompany,
  getCompanyLogo,
  deleteCompany
}

export default Company;
