import { Delete, AddCircle } from "@mui/icons-material";
import { Button, Box, Typography } from "@mui/material";
import { Field, Operator } from "@classes/Filter/Field";

interface IFilterListActionsProps {
  currentFilters: FilterToApply[],
  properties: FilterProperties[],
  newFilterBasedOnType: (type: "string" | "number" | "date" | "boolean" | "select" | "array" | "multi_select" | "duration", name: string, operators: Operator[]) => Field<any, any>,
  setCurrentFilters: React.Dispatch<React.SetStateAction<FilterToApply[]>>,
  restartFilters: () => void,
  addFilter: () => void,
  noPadding?: boolean,
  filterPerProperty?: boolean;
  disableResetButton?: boolean;
  addFilterDefaultValues?: Map<string, string | number | boolean | string[]>;
}

const FilterListActions: React.FC<IFilterListActionsProps> = (props) => {
  const {
    currentFilters,
    properties,
    restartFilters,
    noPadding,
    filterPerProperty,
    disableResetButton,
    addFilter
  } = props;

  return <Box display="grid" gridTemplateColumns="max-content auto max-content" paddingRight={noPadding ? 0 : "1rem"} paddingLeft={noPadding ? 0 : "1rem"} paddingBottom="1rem">
    <Button
      startIcon={(<AddCircle style={{ fontSize: '1.25rem' }} />)}
      size="large"
      variant="outlined"
      disabled={filterPerProperty && (currentFilters?.length === properties?.length)}
      onClick={addFilter}
      data-cy="filter-add-filter-button"
    >
      <Typography style={{ marginTop: 2 }} variant="button">Add filter</Typography>
    </Button>
    <div />
    {!Boolean(disableResetButton) &&
      <Button
        startIcon={(<Delete style={{ fontSize: '1.25rem' }} />)}
        onClick={() => { restartFilters(); }}
        size="large"
        data-cy="filter-reset-all-button"
        disabled={currentFilters.length === 0}
      >
        <Typography style={{ marginTop: 2 }} variant="button">Reset all</Typography>
      </Button>}
  </Box>;
}

export default FilterListActions;