import KeyMirror from "keymirror";

export const ActionTypes = KeyMirror({
  SET_CREATING_ACTION: null,
  SET_SAVING_ACTION: null,
  SET_SCHEDULE_TEMPLATES_FILTER_TEXT: null
});

export interface IActionsViewState {
  isCreatingAction: boolean,
  isSavingAction: boolean;
  scheduleTemplateFilterText: string | undefined;
}
