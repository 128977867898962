import PubSub from "./PubSub";
import API from '@API/index';

class PubSubStream extends PubSub {
  protected stream: VideoStream;

  constructor(stream: VideoStream, onMessage: Function, onError?: Function, onComplete?: Function) {
    super(onMessage, onError, onComplete);
    this.stream = stream;
  }

  public connect(): void {
    const { companyId, siteId, kvsName } = this.stream;

    const topicName: string = `events/aggregated/${companyId}/${siteId}/${kvsName}`;
    this.subscription = API.PubSub.subscribeToTopic(topicName, this.onMessage, this.onError, this.onComplete);

    this.interval = setInterval(() => { this.checkSubscriptionHealth() }, 15000);
  }
}

export default PubSubStream;
