/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { DateRange, DefinedRange } from '../types';
import { parseOptionalDate } from '../utils';
import { defaultRanges } from '../defaults';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';

const dateFns = require('date-fns');
const {
  addYears,
} = dateFns;

type Marker = symbol;

export const MARKERS: { [key: string]: Marker } = {
  FIRST_MONTH: Symbol('firstMonth'),
  SECOND_MONTH: Symbol('secondMonth'),
};

interface DateRangePickerProps {
  open: boolean;
  initialDateRange?: DateRange;
  definedRanges?: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;
  error?: string;
  onChange: (dateRange: DateRange) => void;
  maxRangeInHours?: number;
}

const DateRangePicker: React.FC<DateRangePickerProps> = (props: DateRangePickerProps) => {
  const today = new Date();

  const { open, onChange, initialDateRange, minDate, maxDate, definedRanges = defaultRanges } = props;

  const minDateValid = parseOptionalDate(minDate, addYears(today, -10));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today, 10));

  return open ? (
    <StaticDateRangePicker
      defaultValue={initialDateRange ? [initialDateRange.startDate, initialDateRange.endDate] : undefined}
      minDate={minDateValid}
      maxDate={maxDateValid}
      calendars={2}
      onChange={(range) => {
        if (range[0] && range[1]) onChange({ startDate: range[0], endDate: range[1] })
      }}
      slotProps={{
        shortcuts: {
          items: definedRanges || defaultRanges,
          changeImportance: 'set'
        },
        actionBar: {
          actions: []
        }
      }}
    />
  ) : null
};

export default DateRangePicker;
