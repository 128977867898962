import HttpSynchronize from "@classes/HttpSynchronize";
import { API } from "aws-amplify";
import EventUpdateTracker from "./EventUpdateTracker";

const event_map: Map<string, HttpSynchronize> = new Map();

async function updateEvent(kvsName: string, startDate: number, event: VideoStreamEvent): Promise<void> {
  try {
    if (!event_map.has(event.id)) {
      event_map.set(event.id, new HttpSynchronize())
    }

    const path: string = `/events/${kvsName}/${startDate}`;

    EventUpdateTracker.register({
      ...event,
      tab: event.tab ? event.tab : 'unassigned'
    });
    
    const params = {
      owner: event.owner,
      tab: event.tab,
      tags: event.tags
    }
    
    const options = {
      body: params
    };

    event_map.get(event.id)?.add(async () => {
      await API.put(
        'api-service',
        path,
        options
      )
    })

  } catch (e) {
    console.log(e);
    throw e;
  }
}

export default updateEvent;
