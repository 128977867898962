import getCanvasSize from './getCanvasSize';
import getBoardPosition from './getBoardPosition';
import isInsideTheCanvas from './isInsideCanvas';

export interface IGeometry {
  getCanvasSize: (videoWidth: number, videoHeight: number, offsetWidth: number, offsetHeight: number) => Vector2
  getBoardPosition: (boardVector: Vector2, parentVector: Vector2, pointPoisition: Vector2, offsetVector: Vector2, transform?: { x: number, y: number, scale: number }) => Vector2
  isInsideTheCanvas: (position: Vector2, parentVector: Vector2) => boolean
}

const Geometry: IGeometry = {
  getCanvasSize,
  getBoardPosition,
  isInsideTheCanvas,
}

export default Geometry;
