import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import './i18n';

import { configureStore } from './store/index';

import App from './App';

// import * as serviceWorker from './serviceWorker';

import './index.css';
import { StyledEngineProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENCE_KEY as string);


Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'api-service',
        endpoint: process.env.REACT_APP_API_GATEWAY_URL,
        region: process.env.REACT_APP_API_GATEWAY_REGION
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_HEATMAPS_S3_BUCKET,
      region: process.env.REACT_APP_API_GATEWAY_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      customPrefix: {
        public: '',
        protected: '',
        private: ''
      },
    }
  }
});

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: `${process.env.REACT_APP_API_GATEWAY_REGION}`,
  aws_pubsub_endpoint: `${process.env.REACT_APP_PUBSUB_ENDPOINT}`,
  debug: true
}));

export const store = configureStore({});

const container = document.getElementById('root');
const root = createRoot(container!);


root.render(<Suspense fallback="loading">
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </Provider>
</Suspense>);

if (module.hot) {
  module.hot.accept('./App.tsx', () => {
    const NextApp = require('./App.tsx').default;
    root.render(NextApp);
  });
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();