import { Close, FilterList as FilterListIcon } from '@mui/icons-material';
import { Badge, Box, IconButton, Popover, PopoverOrigin, Tooltip } from '@mui/material';
import React from 'react';
import FilterProvider, { IFilterProviderHandles } from './Filters/FilterProvider';

export type SearchHeaderFilterProps = {
  properties: Array<FilterProperties>;
  data: Array<any>;
  onFilteredDataChange?: (data: Array<any>) => void;
  origin?: PopoverOrigin;
  transform?: PopoverOrigin;
  disabled?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

const SearchHeaderFilter: React.FC<SearchHeaderFilterProps> = (props) => {
  const { properties, data, onFilteredDataChange, origin, transform, disabled, setOpen } = props;

  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [currentActiveFilters, setCurrentActiveFilters] = React.useState<number>(0);
  const filterProviderRef = React.useRef<IFilterProviderHandles | null>(null);
  
  const change = React.useCallback((value: number) => {
    setCurrentActiveFilters(value)
  }, [])

  return (
    <>
      <Box display="flex">
        <Tooltip title="Reset all">
          <IconButton
            style={{ visibility: (filterProviderRef.current && (currentActiveFilters > 0)) ? 'visible' : 'hidden' }}
            onClick={() => { filterProviderRef.current && filterProviderRef.current.restartFilters() }}
            size="small"
            data-cy="filter-reset-all-icon-button"
          >
            <Close fontSize='small' />
          </IconButton>
        </Tooltip>
        <Tooltip title="Filters">
          <Badge badgeContent={filterProviderRef.current && currentActiveFilters} color="primary">
            <IconButton
              disabled={disabled}
              onClick={(e) => { setAnchorEl(e.target); setOpen && setOpen(true); }}
              size="small"
              data-cy="filter-filters-icon-button"
            >
              <FilterListIcon color={filterProviderRef.current && (currentActiveFilters > 0) ? "primary" : undefined} />
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>
      <Popover
        style={{ marginTop: origin === undefined && transform === undefined ? '1rem' : undefined, zIndex: 9999 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => { setAnchorEl(null); setOpen && setOpen(false); }}
        anchorOrigin={origin || {
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={transform || {
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
      >
        <FilterProvider
          properties={properties}
          data={data}
          onFilteredDataChange={onFilteredDataChange}
          ref={filterProviderRef}
          oneRowLayout={true}
          suggested={true}
          onCurrentActiveFiltersChange={change}
        />
      </Popover>
    </>
  )
}

export default SearchHeaderFilter;
