import React from 'react';
import { Checkbox, Divider, IconButton, InputAdornment, ListItemText, MenuItem, Select, SelectProps, Tooltip, Typography, useTheme } from '@mui/material';
import styled from 'styled-components';
import Utils from '@utils/index';
import { Close, ExpandMore, Info } from '@mui/icons-material';

interface InlineSelectProps {
  notInline?: boolean;
}

const InlineSelect = styled(({ notInline, ...props }: InlineSelectProps & SelectProps<string | any[] | undefined>) =>
  <Select {...props} />) <InlineSelectProps>`
  ${props => props.notInline ? "" :
    `.MuiSelect-select.MuiSelect-select {
        padding-right: 0;
    }`
  }
  .MuiSelect-icon {
    display: ${props => props.notInline ? "inline" : "none"};
  }

  .MuiInputBase-root{
    font-size: inherit;
    ${props => props.notInline ? "margin-top: 0" : ""}
  }
`

const CustomMenuItem = styled(MenuItem)`
  border-bottom: 1px solid white;
`


interface IProps {
  options?: any[];
  value?: string | any[] | undefined;
  onChange: (value: string | any[] | undefined) => void;
  optionValueProp?: string;
  optionDisplayProp?: string;
  optionElementProp?: string;
  multiple?: boolean;
  placeholder?: string;
  notInline?: boolean;
  noExtensionText?: boolean;
  renderValue?: ((value: unknown) => React.ReactNode) | undefined
  disabled?: boolean;
  error?: boolean;
  clear?: boolean;
  dataCy?: string;
}

const TextInlineSelect: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const {
    value,
    options,
    onChange,
    optionValueProp,
    optionDisplayProp,
    optionElementProp,
    multiple,
    placeholder,
    notInline,
    noExtensionText,
    renderValue,
    disabled,
    error,
    clear,
    dataCy
  } = props;

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <InlineSelect
        disabled={disabled || options?.length === 0}
        onChange={(e) => {
          if (typeof (e?.target?.value) === "string") {
            onChange(e.target.value as string);
          }
          else {
            const value = e?.target?.value as any[];
            if (value.includes("all")) {
              onChange(((value?.length - 1) === options?.length) ? [] : (options?.map(o => optionValueProp ? o[optionValueProp] : o) || []))
              return;
            }
          }
          onChange(e.target.value as any[]);
        }}
        multiple={multiple}
        error={error}
        value={value}
        style={{ fontSize: "inherit" }}
        variant={notInline ? "outlined" : "standard"}
        MenuProps={{
          style: {
            maxHeight: "50vh",
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          //@ts-ignore
          MenuListProps: { "data-cy": dataCy ? `${dataCy}-list` : undefined }
        }}
        endAdornment={
          (value && clear) ?
            <InputAdornment position="end">
              <IconButton
                onClick={() => onChange(undefined)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="small"
                style={{ marginRight: "1rem" }}
              >
                <Close fontSize="small" />
              </IconButton>
            </InputAdornment> : <></>
        }
        displayEmpty
        notInline={notInline}
        IconComponent={notInline ? ExpandMore : undefined}
        placeholder={placeholder || ""}
        renderValue={renderValue ? renderValue : (value: any) => {
          return value === "" || value === undefined || value?.length === 0 ?
            <Typography color='textSecondary'>{placeholder}</Typography> :
            <>{Utils.Text.getDisplayText(value, multiple, optionDisplayProp, optionValueProp, options, placeholder, noExtensionText || false)}</>;
        }}
        data-cy={dataCy}
      >
        {multiple &&
          <MenuItem
            value={"all"}
            key={"all"}
          >
            <Checkbox inputProps={{ style: { paddingLeft: 0 } }} color="primary" checked={(options?.length && value?.length === options?.length) || false} />
            <ListItemText primary={"Select all"} />
          </MenuItem>}
        {multiple &&
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />}
        {options?.map(o =>
          <CustomMenuItem
            value={optionValueProp ? o[optionValueProp] : o}
            key={optionValueProp ? o[optionValueProp] : o}
            theme={theme}
            data-cy={`select-${optionValueProp ? o[optionValueProp] : o}-menu-item`}
          >
            {multiple && optionElementProp === undefined && <Checkbox color="primary" checked={(value as any[])?.indexOf(optionValueProp ? o[optionValueProp] : o) > -1} />}
            <ListItemText primary={optionElementProp ? o[optionElementProp] : (optionDisplayProp ? o[optionDisplayProp] : o)} />
            {o.info &&
              <Tooltip title={o.info}>
                <Info />
              </Tooltip>
            }
          </CustomMenuItem>)}
      </InlineSelect>
    </>
  );
}

export default TextInlineSelect;