import StreamEventsMerger from "@classes/StreamEvents/StreamEventsMerger";
import EventConverter from "@classes/StreamEvents/WSEventConverter";
import { API } from "aws-amplify";
import { isString } from "lodash";

export type GetTabEventsOptions = {
  mergeZoneEvents?: boolean;
  mergeSiteEvents?: boolean;
}

const DEFAULT_EVENT_LIMIT = 2000;

export function convertResponse(data: APIResponse<GlobalEvent[]>, limit?: number, mergeZoneEvents?: boolean, mergeSiteEvents?: boolean): APIResponse<VideoStreamEvent[]> {
  return {
    status: data.status,
    lastKey: data.lastKey,
    limit: limit,
    data: data.data ? new StreamEventsMerger(data.data.filter(el => (el.e !== undefined && el.s !== undefined) && el.s !== el.e).map(el => (
      new EventConverter().convertFromDynamoDB(el)
    ))).merge(mergeZoneEvents, mergeSiteEvents) : []
  }
}

async function getTabEvents(
  companyId: string,
  start: number,
  end: number,
  tab?: EventState,
  lastKey?: { s: number, src: string },
  limit?: number,
  options?: GetTabEventsOptions
): Promise<APIResponse<VideoStreamEvent[]>> {
  try {
    let path: string = `/global/events/${companyId}/${start}/${end}`;
    if (tab) path = path += `?tab=${tab}&limit=${limit || DEFAULT_EVENT_LIMIT}`;
    else path = path += `?limit=${limit || DEFAULT_EVENT_LIMIT}`

    if (lastKey) {
      if (path.includes('?')) {
        path = path += `&lastTs=${lastKey.s}`;
        path = path += `&lastSrc=${lastKey.src}`;
      } else {
        path = path += `?lastTs=${lastKey.s}`;
        path = path += `&lastSrc=${lastKey.src}`;
      }
    }

    const data: APIResponse<GlobalEvent[]> = await API.get(
      'api-service',
      path,
      {}
    );

    return convertResponse(data, limit || DEFAULT_EVENT_LIMIT, options?.mergeZoneEvents, options?.mergeSiteEvents);
  } catch (error) {
    console.log(error);
    const e = JSON.parse(JSON.stringify(error)) as { message: string };
    if (e && isString(e.message) && e.message === "Request failed with status code 502") {
      return getTabEvents(companyId, start, end, tab, lastKey, 100, options);
    } else {
      throw error;
    }
  }
}

export default getTabEvents;
