import {
  ActionTypes, IActionsViewState
} from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IActionsViewState = {
  isCreatingAction: false,
  isSavingAction: false,
  scheduleTemplateFilterText: undefined
}

export const actionsViewReducer = Utils.Redux.createReducer<
  IActionsViewState,
  Types,
  Actions
>(initialState, {

  [ActionTypes.SET_CREATING_ACTION]: (state, action) => ({
    ...state,
    isCreatingAction: action.value
  }),

  [ActionTypes.SET_SAVING_ACTION]: (state, action) => ({
    ...state,
    isSavingAction: action.value
  }),

  [ActionTypes.SET_SCHEDULE_TEMPLATES_FILTER_TEXT]: (state, action) => ({
    ...state,
    scheduleTemplateFilterText: action.value
  })
});
