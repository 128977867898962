import API from '@API/index';

async function getViewsFromSite(companyId: string, siteId: string): Promise<UserView[]> {
  try {
    const views: UserView[] = await API.Views.getViewsFromCompany(companyId);
    return views.filter(el => el.siteId === siteId).sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0
    });
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export default getViewsFromSite;
