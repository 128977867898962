import activateUsers from "./activateUsers";
import createUser from "./createUser";
import deactivateUsers from "./deactivateUsers";
import getUserData from "./getUserData";
import getUserPic from "./getUserPic";
import getUsers from "./getUsersFromCompany";
import registerUser from "./registerUser";
import reinviteUsers from "./reinviteUsers";
import { CreateUserForm } from "./types";
import updateUser from "./updateUser";
import updateUserRole from "./updateUserRole";
import updateWebPushSubscriptions from "./updateWebPushSubscriptions";

export interface IUsers {
  registerUser(userId: string, form: Role): Promise<void>;
  createUser(form: CreateUserForm): Promise<User>;
  getUserData(userId: string): Promise<User>;
  getUsers(companyId?: string): Promise<User[]>;
  updateWebPushSubscriptions(webPushSubscriptions: PushSubscription[], userId: string): Promise<void>;
  updateUser(userData: User): Promise<User>;
  updateUserRole(userId: string, role: string | Record<string, Record<string, string[]>>): Promise<boolean>;
  deactivateUsers(userIds: string[]): Promise<void>;
  activateUsers(userIds: string[], newPassword: string): Promise<void>;
  reinviteUsers(userIds: string[]): Promise<void>;
  getUserPic(userId:string, type?: string): Promise<{ url: string }>;
}

const Users: IUsers = {
  registerUser,
  createUser,
  deactivateUsers,
  getUserData,
  getUsers,
  updateWebPushSubscriptions,
  updateUser,
  updateUserRole,
  activateUsers,
  reinviteUsers,
  getUserPic
}

export default Users;
