export type FilterType = "string" | "number" | "date" | "boolean" | "select" | "array" | "multi_select" | "duration";
export type Operator =
  "contains" |
  "equals" |
  "starts_with" |
  "ends_with" |
  "is_empty" |
  "is_not_empty" |
  "is_not" |
  "greater" |
  "greater_or_equal" |
  "lesser" |
  "lesser_or_equal" |
  "is_after" |
  "is_on_or_after" |
  "is_before" |
  "is_on_or_before" |
  "is" |
  "includes" |
  "does_not_include" |
  "is_any_of";

export class Field<T, N> {
  protected _name: string = "";
  protected _path: string[] = [];
  protected _type: FilterType = "string";
  protected _operators: Array<Operator> = [];
  protected _currentOperator: Operator | null = null;

  public withType(type: FilterType): Field<T, N> {
    this._type = type;
    return this;
  }

  public withOperators(operators: Array<Operator>): Field<T, N> {
    this._operators = operators;

    if (operators[0]) {
      this._currentOperator = operators[0];
    }

    return this;
  }

  public withName(name: string): Field<T, N> {
    this._name = name;
    this._path = name.split('.');
    return this;
  }

  public getOperators(): Array<Operator> {
    return this._operators;
  }

  public getType(): FilterType {
    return this._type;
  }

  public getName(): string {
    return this._name;
  }

  public setCurrentOperator(operator: Operator): Operator {
    if (!this._operators.includes(operator)) throw new Error("Operator is not valid");
    this._currentOperator = operator;
    return operator;
  }

  public getCurrentOperator(): Operator | null {
    return this._currentOperator;
  }

  public hasValues(name: string, type: FilterType, currentOperator: Operator | null): boolean {
    return name === this._name && type === this._type && (currentOperator ? currentOperator === this._currentOperator : true);
  }

  public filter(data: Array<T>, target: N): Array<T> {
    throw new Error("Not implemented");
  }
}