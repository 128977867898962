import createTrade from "./createTrade";
import listTrades from "./listTrades";

export interface ITrades {
  createTrade(trade: AddTradeForm, asCompany?: string): Promise<Trade>;
  listTrades(asCompany?: string): Promise<string[]>;
}

const Trades: ITrades = {
  createTrade,
  listTrades
}

export default Trades;
