import { ICredentials } from "@store/session/types";
import { getMetric } from "./getMetric";

export interface IPrometheusAPI {
  getMetric(companyId: string, macAddress: string, metric: string, from: Date, to: Date, credentials: ICredentials): Promise<any>;
}

const Prometheus: IPrometheusAPI = {
  getMetric
}

export default Prometheus;
