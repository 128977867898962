import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_TARGET_EXPORT: null,
  CLEAR_TARGET_EXPORT: null
});

type TargetExport = {
  type: "timelapse" | "video_clip" | "snapshot";
  id: string;
  kvsName: string;
}

export interface ISavedViewState {
  readonly target: TargetExport | undefined;
}
