import { API } from "aws-amplify";
import { flattenDeep } from "lodash";
import qs from 'qs';

async function getSiteVehicles(siteId: string, companyId?: string): Promise<Array<VehicleDetection>> {
  try {
    const paths: string[] = [];

    let current: number = Date.now();
    const start: number = Date.now() - (2592000000 * 12);

    while (current > start) {
      paths.push(`/vehicles?${qs.stringify({ siteId, asCompany: companyId, startTime: current - 2592000000, endTime: current })}`)
      current -= 2592000000;
    }

    const promises = await Promise.allSettled(paths.map(el => API.get('api-service', el, {})));

    //@ts-ignore
    const data = flattenDeep(promises.filter(el => el.status === "fulfilled").map(el => el.value.data));

    if (data) {
      return data?.map((vehicleDetection) => ({
        ...vehicleDetection,
        eventTimestamp: new Date(vehicleDetection.eventTimestamp).getTime(),
        detectionTimestamp: new Date(vehicleDetection.detectionTimestamp).getTime(),
        plate: vehicleDetection.plate.toLowerCase()
      }));
    }

    return [];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default getSiteVehicles;
