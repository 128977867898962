import connect from './procore/connect';

export interface IIntegrations {
  Procore: {
    connect: () => Promise<string>
  }
}

const Integrations: IIntegrations = {
  Procore: {
    connect
  }
};

export default Integrations;
