import { 
  ActionTypes, 
  ISelectedStreamState
} from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: ISelectedStreamState = {
  stream: undefined,
  streamChunks: [],
  videoState: "loading",
  playerType: "KVS",
  isLive: false,
  subscription: undefined
};

export const selectedStreamReducer = Utils.Redux.createReducer<
  ISelectedStreamState,
  Types,
  Actions
>(initialState, {
  [ActionTypes.SET_ACTIVE_STREAM]: (state, action) => ({
    ...state,
    stream: action.stream,
    streamChunks: action.streamChunks,
    streamEvents: action.streamEvents
  }),

  [ActionTypes.CLEAR_ACTIVE_STREAM]: state => ({
    ...state,
    stream: undefined,
    streamChunks: [],
    streamEvents: []
  }),

  [ActionTypes.ADD_ACTIVE_STREAM_TIMES]: (state, action) => ({
    ...state,
    streamChunks: [...action.streamChunks]
  }),

  [ActionTypes.SET_VIDEO_STATE]: (state, action) => ({
    ...state,
    videoState: action.videoState
  }),

  [ActionTypes.SET_PLAYER_TYPE]: (state, action) => ({
    ...state,
    playerType: action.value
  }),

  [ActionTypes.SET_VIDEO_LIVE]: (state, action) => ({
    ...state,
    isLive: action.value
  }),

  [ActionTypes.SET_PUBSUB_SUBSCRIPTION_FUNCTION]: (state, action) => ({
    ...state,
    subscription: action.value
  }),

  [ActionTypes.SET_PUBSUB_PTZ_SUBSCRIPTION_FUNCTION]: (state, action) => ({
    ...state,
    ptzSubscription: action.value
  })
});
