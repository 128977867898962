import React from 'react';
import useSetActivePage from '@hooks/useSetActivePage';
import { PageView } from '@store/session/types';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { NavBar } from '@components/common/NavBar/NavBar';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { EventsTabs } from '@store/views/overviewView/types';
import { useSearchParams } from 'react-router-dom';
import useUpdateSites from '@hooks/useUpdateSites';
import Loading from '@components/common/Loading';
import { fetchAllSitesStreams } from '@hooks/Streams/ReduxStreamHooks';
import useRenderOnWindowResize from '@hooks/useRenderOnWindowResize';
import EventsContent from '@components/events/EventsContent';

const TAB_PARAM = "tab";

const EventsView: React.FC<{}> = () => {
  useSetActivePage(PageView.Events);
  useRenderOnWindowResize();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabAsserted, setTabAsserted] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [activeTab, setActiveTab] = React.useState<"event_inbox" | "reports">("event_inbox");


  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const loadingSites = useUpdateSites(selectedCompany?.companyId);

  const changeSearchParams = React.useCallback((key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleNavbarOptionSelection = React.useCallback((tab: string) => {
    if (searchParams.get(TAB_PARAM) !== tab) {
      changeSearchParams(TAB_PARAM, tab)
    }
  }, [changeSearchParams, searchParams])

  const isValidTab = React.useCallback((tab: string) => {
    return tab === "event_inbox" ||
      tab === "reports";
  }, [])

  const processActiveTab = React.useCallback(() => {
    const tab = searchParams.get(TAB_PARAM);
    if (tab && isValidTab(tab)) {
      setActiveTab(tab as EventsTabs)
    }

    setTabAsserted(true);
  }, [searchParams, isValidTab])

  const tabs = React.useMemo(() => {
    return [
      {
        value: EventsTabs.EventInbox.toString(),
        onClick: () => { handleNavbarOptionSelection("event_inbox") },
        label: "Event inbox",
        dataCy: "events-event-inbox-tab"
      },
      {
        value: EventsTabs.Reports.toString(),
        onClick: () => { handleNavbarOptionSelection("reports") },
        label: "Reports",
        dataCy: "events-reports-tab"
      }
    ]
  }, [handleNavbarOptionSelection])

  React.useEffect(() => {
    if (loadingSites === false) {
      fetchAllSitesStreams()
        .then(() => {
          setLoading(false);
        })
    }
  }, [loadingSites])

  React.useEffect(processActiveTab, [processActiveTab])

  if (!tabAsserted) return null;

  return (
    <Box width="100%" height="100%" display="grid" gridTemplateRows="min-content auto">
      <Helmet title="Forsight | Events" />
      <NavBar
        title="Events"
        selectedValue={activeTab.toString()}
        tabs={tabs}
      />
      {loading === true &&
        <Loading />
      }
      {loading === false &&
        <EventsContent activeTab={activeTab as EventsTabs} />
      }
    </Box>
  )
}


export default EventsView;
