import attachStrobe from "./attachStrobe";
import controleStrobe from "./controlStrobe";
import deleteStrobe from "./deleteStrobe";
import listStrobes from "./listStrobes";
import updateStrobe from "./updateStrobe";

export interface IStrobes {
  attachStrobe(streamId: string, device: Strobe): Promise<void>;
  deleteStrobe(streamId: string, deviceIdentifier: string, deviceType: string): Promise<void>;
  listStrobes(streamId: string, type?: string): Promise<Strobe[]>;
  updateStrobe(streamId: string, device: Strobe): Promise<void>;
  controleStrobe(streamId: string, device: Strobe): Promise<void>;
}

const Strobes: IStrobes = {
  attachStrobe,
  deleteStrobe,
  listStrobes,
  updateStrobe,
  controleStrobe
};

export default Strobes;
