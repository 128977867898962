import { IBitMovinPlayerHandles } from '@components/common/BitMovinPlayer';
import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import API from '@API/index';
import Utils from '@utils/index';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { isArray, isString, uniqBy } from 'lodash';
import VideoBoundingBoxes from '@classes/VideoBoundingBoxes';
import { IEventContentVideoDetectionBoxesHandles } from './EventContentVideoDetectionBoxes';
import usePrevious from '@hooks/usePrevious';
import { StreamStatus } from '@components/common/StreamStatus';
import styled from 'styled-components';
import KinesisPlayer, { IKinesisPlayerHandles } from '@components/common/KinesisPlayer/KinesisPlayer';
import { setVideoState } from '@store/selectedStream/actions';
import { useDispatch } from 'react-redux';
import { setHoveredZoneId, setSelectedZone } from '@store/views/overviewView/actions';
import { useSnackbar } from 'notistack';
import KinesisCache from '@classes/KinesisCache';

const StyledPaper = styled(Paper)<{ disabled?: boolean }>`
  opacity: ${props => props.disabled === true ? 0.5 : undefined};

  :hover {
    cursor: ${props => props.disabled === true ? 'not-allowed !important' : undefined}
  }

  .bitmovinplayer-container {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
`

type EventContentVideoProps = {
  streams: VideoStream[];
  event: VideoStreamEvent;
  videoBoundingBoxes?: VideoBoundingBoxes;
  onVideoStartRangeAquired?: (params: { start: number, end: number }) => void;
  onVideoStateChange?: (videoState: "error" | "buffering" | "loading" | "ready") => void;
  onVideoTimestampUpdate?: (timestamp: number) => void;
  kvsName: string;
  onSourceLoaded?: (kvsName: string, player: any) => void;
  autoPlay?: boolean;
  paused: boolean;
  cacheOnly?: boolean;
}

export interface IEventContentVideoHandles {
  goToTime: (date: Date) => void;
  play: () => void;
  pause: () => void;
  seek: (target: number) => void;
}

const EventContentVideo: React.ForwardRefRenderFunction<IEventContentVideoHandles, EventContentVideoProps> = (props, ref) => {
  const {
    event,
    videoBoundingBoxes,
    onVideoStartRangeAquired,
    onVideoStateChange,
    streams,
    onVideoTimestampUpdate,
    kvsName,
    onSourceLoaded,
    autoPlay,
    paused,
    cacheOnly
  } = props;

  React.useImperativeHandle(ref, () => ({
    goToTime,
    play,
    pause,
    seek
  }))

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const credentials = useSelector((store: IStoreState) => store.session.credentials);
  const playbackRate = useSelector((store: IStoreState) => store.overviewView.overviewOptions.playbackRate);
  const filteredZones = useSelector((store: IStoreState) => store.overviewView.filteredZones);
  const activePanel = useSelector((store: IStoreState) => store.overviewView.eventInboxSidePanel);
  const selectedZone = useSelector((store: IStoreState) => store.overviewView.selectedZone);
  const hoveredZoneId = useSelector((store: IStoreState) => store.overviewView.hoveredZoneId);
  const currentZones = useSelector((store: IStoreState) => store.overviewView.currentZones);
  const permissions = useSelector((store: IStoreState) => store.session.permissions);

  const [triggeredZoneId, setTriggeredZoneId] = React.useState<string[] | undefined>(undefined);
  const [zoneToDisplay, setZoneToDisplay] = React.useState<Zone[] | undefined>(undefined);
  const [zoneVisible, setZoneVisible] = React.useState<boolean>(false);
  const [stream, setVideoStream] = React.useState<VideoStream | undefined>(undefined);
  const previousEvent = usePrevious(event);

  const hoveredZoneIdRef = React.useRef<undefined | string | string[]>(hoveredZoneId);
  const prev = usePrevious<VideoStreamEvent>(event);
  const bitmovin = React.useRef<IBitMovinPlayerHandles>(null);
  const videoBoundingBoxesRef = React.useRef<VideoBoundingBoxes | undefined>(undefined);
  const lastVideoTimestampRef = React.useRef<number>(0);
  const eventContentVideoDetectionBoxesRef = React.useRef<IEventContentVideoDetectionBoxesHandles | null>(null);
  const onVideoStartRangeAquiredRef = React.useRef(onVideoStartRangeAquired);
  const onVideoStateChangeRef = React.useRef(onVideoStateChange);
  const eventRef = React.useRef<VideoStreamEvent>(event);
  const prevEventRef = React.useRef<VideoStreamEvent | undefined>(prev);
  const credentialsRef = React.useRef(credentials);
  const kvsNameRef = React.useRef(kvsName);
  const kinesisPlayerRef = React.useRef<IKinesisPlayerHandles | null>(null);
  const previousRef = React.useRef(previousEvent);

  const hoveredZones = React.useMemo(() => {
    const z = uniqBy([...zoneToDisplay || [], ...filteredZones], 'id').filter(el => hoveredZoneId?.includes(el.id) && el.kvsName === kvsName);
    if (z.length === 0) return undefined;
    return z;
  }, [filteredZones, hoveredZoneId, kvsName, zoneToDisplay])

  const selectedStream = React.useMemo(() => {
    return streams.find(el => el.kvsName === kvsName)
  }, [kvsName, streams])

  const initialTtimestamp = React.useMemo(() => {
    return Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate);
  }, [event])

  const play = React.useCallback(() => {
    if (kinesisPlayerRef.current) {
      kinesisPlayerRef.current.play();
    }
  }, [])

  const pause = React.useCallback(() => {
    if (kinesisPlayerRef.current) {
      kinesisPlayerRef.current.pause();
    }
  }, [])

  const streamFeatures = React.useMemo(() => {
    const kvsNames = new Set();
    currentZones.forEach((zone) => {
      if (isString(zone.kvsName))
        kvsNames.add(zone.kvsName)
    })

    kvsNames.add(event.sourceCamera);

    const map = new Map<string, StreamFeatures>();
    Array.from(kvsNames).forEach((kvsName) => {
      const stream = streams.find(el => el.kvsName === kvsName);
      if (stream && stream.features) {
        let features = {
          stream: { view: false },
          events: {
            motion: { active: false, read: false },
            zones: { active: false, read: false },
            safetyInfraction: { active: false, read: false },
            person: { active: false, read: false },
            vehicle: { active: false, read: false },
            socialDistance: { active: false, read: false },
            fire: { active: false, read: false },
            vehicleLicensePlate: { active: false, read: false }
          },
          zones: { active: false, read: false, write: false },
          exports: { active: false, read: false, write: false },
          imageAnnotations: { active: false, read: false, write: false },
          timelapse: { active: false, read: false, write: false },
          ptz: { active: false, read: false, write: false },
          ppeDetection: false,
          socialDistancing: false,
          fireDetection: false,
          licensePlateDetection: false
        };
        const zoneEnabledForStream = stream.features.zones;
        const userHasPermissionForZones = Utils.Permissions.userHasPermission(permissions, "GEOFENCES", stream.siteId, stream.kvsName);
        features.zones.write = zoneEnabledForStream && userHasPermissionForZones;
        map.set(stream.kvsName, features);
      }
    })

    return map;
  }, [currentZones, event.sourceCamera, permissions, streams])

  const disabledFeedback = React.useMemo(() => {
    if (selectedZone && selectedStream) {
      if (streamFeatures.get(selectedStream.kvsName)?.zones.write === false) {
        return true;
      }

      if (selectedZone.kvsName && selectedZone.kvsName !== selectedStream.kvsName) {
        return true;
      }
    }

    return false;
  }, [selectedStream, selectedZone, streamFeatures])

  const disableZoneClick = React.useMemo(() => {
    if (!selectedZone) return true;

    return false;
  }, [selectedZone])

  const handlePointsChange = React.useCallback((zone: Zone) => {
    if (zone.kvsName) {
      if (streamFeatures.has(zone.kvsName)) {
        if (streamFeatures.get(zone.kvsName)?.zones.write) {
          dispatch(setSelectedZone(zone))
          return;
        }
      }
    }

    enqueueSnackbar("Zones disabled for this stream.", { variant: 'error' });
  }, [dispatch, enqueueSnackbar, streamFeatures])

  React.useEffect(() => {
    hoveredZoneIdRef.current = hoveredZoneId;
  }, [hoveredZoneId])

  React.useEffect(() => {
    const isZoneBeingEdited: boolean = selectedZone !== undefined;
    const isZoneBeingHovered: boolean = Boolean(hoveredZoneId !== undefined && zoneToDisplay && (zoneToDisplay.filter(el => hoveredZoneId.includes(el.id)).length > 0 || filteredZones.filter(el => el.kvsName === kvsName).filter(el => hoveredZoneId.includes(el.id)).length > 0));
    const showZone: boolean = Boolean(isZoneBeingEdited || isZoneBeingHovered);

    setZoneVisible(showZone);
  }, [activePanel, filteredZones, hoveredZoneId, kvsName, selectedZone, zoneToDisplay])
  
  React.useEffect(() => {
    if (paused === true) pause();
    else play();
  }, [pause, paused, play])

  React.useEffect(() => { previousRef.current = previousEvent }, [previousEvent])
  React.useEffect(() => {
    if (kinesisPlayerRef.current && previousRef.current) {
      if (event.sourceCamera === previousRef.current.sourceCamera) {
        kinesisPlayerRef.current.goToTime(Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate));
      }
    }
  }, [event])
  React.useEffect(() => { kvsNameRef.current = kvsName }, [kvsName])
  React.useEffect(() => { eventRef.current = event }, [event])
  React.useEffect(() => { prevEventRef.current = prev }, [prev]);
  React.useEffect(() => { credentialsRef.current = credentials }, [credentials])
  React.useEffect(() => { onVideoStateChangeRef.current = onVideoStateChange }, [onVideoStateChange]);
  React.useEffect(() => { onVideoStartRangeAquiredRef.current = onVideoStartRangeAquired }, [onVideoStartRangeAquired])
  React.useEffect(() => { setVideoStream(streams.find(el => el.kvsName === kvsName)) }, [event, kvsName, streams])
  React.useEffect(() => {
    if (event.eventType === "Geofence triggered") {
      if (isArray(event.zoneId)) {
        setTriggeredZoneId(event.zoneId);
        return;
      }
    }

    setTriggeredZoneId(undefined);
    setZoneToDisplay(undefined);
  }, [event.eventType, event.id, event.zoneId]);
  React.useEffect(handleZoneIdChange, [kvsName, triggeredZoneId]);
  React.useEffect(() => { videoBoundingBoxesRef.current = videoBoundingBoxes }, [videoBoundingBoxes])
  React.useEffect(() => {
    if (event.eventType === "Geofence triggered" && event.zoneId) {
      if (hoveredZoneIdRef.current !== undefined) {
        if (isArray(hoveredZoneIdRef.current)) {
          dispatch(setHoveredZoneId([...hoveredZoneIdRef.current, ...event.zoneId]))
        } else {
          dispatch(setHoveredZoneId([hoveredZoneIdRef.current, ...event.zoneId]))
        }
      } else {
        dispatch(setHoveredZoneId(event.zoneId))
      }
    }
  }, [dispatch, event.eventType, event.zoneId])

  function goToTime(date: Date): void {
    if (kinesisPlayerRef.current) {
      kinesisPlayerRef.current.goToTime(date.getTime())
    }
  }

  function seek(target: number): void {
    bitmovin.current?.seek(target);
  }

  function handleZoneIdChange(): void {
    if (triggeredZoneId) {
      API.Geofences.getZones(kvsName)
        .then((zones) => {
          const target = zones.filter(el => triggeredZoneId.includes(el.id));
          setZoneToDisplay(target)
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  function handleTimeChange(_timestamp: number): void {
    if (videoBoundingBoxesRef.current && eventContentVideoDetectionBoxesRef.current) {
      videoBoundingBoxesRef.current.setCurrentTimestamp(_timestamp);
      eventContentVideoDetectionBoxesRef.current.draw(videoBoundingBoxesRef.current.getCurrentBoundingBox(), undefined)
    }

    const diff = _timestamp - lastVideoTimestampRef.current;
    if (onVideoTimestampUpdate && (diff > 500 || diff < -500)) {
      onVideoTimestampUpdate(_timestamp);
      lastVideoTimestampRef.current = _timestamp;
    }
  }

  if (cacheOnly === true && KinesisCache.getInstance().isEventCached(event.id)) {
    return null;
  }

  return (
    <StyledPaper variant="outlined" disabled={disabledFeedback} >
      <Box display="grid" gridTemplateRows="min-content auto min-content" width="100%" height="100%">
        <Box
          height="2.5rem"
          display="grid"
          gridTemplateColumns={`min-content auto`}
          columnGap="0.75rem"
          alignItems="center"
          paddingLeft="0.5rem"
        >
          <StreamStatus stream={stream} />
          <Typography variant="subtitle1" style={{ whiteSpace: "nowrap" }} data-cy="event-content-video-camera-typography" >{stream ? Utils.Stream.getStreamName(stream) : "Camera"}</Typography>
        </Box>
        <KinesisPlayer
          usePanZoom
          ref={kinesisPlayerRef}
          stream={selectedStream}
          credentials={credentials}
          paused={paused}
          playbackMode='ON_DEMAND'
          onVideoStateChange={(state) => {
            setVideoState(state)
          }}
          initialTimestamp={initialTtimestamp}
          onTimeUpdate={handleTimeChange}
          playbackRate={playbackRate}
          detectionBoxesVisible={cacheOnly !== true}
          onSourceLoaded={(kvsName, player) => {
            if (onSourceLoaded) onSourceLoaded(kvsName, player)
          }}
          autoPlay={autoPlay}
          zonesFeature={{
            active: zoneVisible,
            zones: activePanel === "zones" && selectedZone ? filteredZones.filter(el => el.kvsName === kvsName) : undefined,
            focusedZone: selectedZone?.kvsName === kvsName || selectedZone?.kvsName === undefined ? selectedZone : undefined,
            onZonePointsChange: handlePointsChange,
            kvsName: kvsName,
            nonClickable: disableZoneClick,
            hoveredEventZone: hoveredZones
          }}
          onCacheReady={() => {
            KinesisCache.getInstance().setEventCached(event.id)
          }}
        />
      </Box>
    </StyledPaper>
  )
}

export default React.forwardRef(EventContentVideo);
