import { download } from "./download";
import { getBase64FromUrl } from "./getBase64FromUrl";

export interface IWindow {
  download(filenName: string, url: string): void;
  getBase64FromUrl(url: string, width?: number, height?: number): Promise<{ b64: string, width: number, height: number }>
}

const Window: IWindow = {
  download,
  getBase64FromUrl
}

export default Window;

