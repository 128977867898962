import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_ACTIVE_USERS: null,
  SET_VIEW_TYPE: null,
  SET_CALENDAR_VIEW: null,
  SET_GROUP_BY_SUB: null,
  SET_OPENED_SUBS: null,
  SET_TIME_ENTRIES: null,
  SET_TIME_ENTRIES_DATE_RANGE: null,
  SET_TIME_ENTRIES_SITE_ID: null,
  SET_ACTIVITIES: null,
});

export interface ICalendarState {
  activeUser: CrewWorker | null,
  viewType: WorkersViewType,
  calendarStep: 'day' | 'week' | 'month',
  groupBySub: boolean;
  openedSubs: string[];
  timeEntries: TimeEntry[],
  timeEntriesDateRange?: { start: Date, end: Date },
  activities: { [key: string]: WorkerActivity[] },
  timeEntriesSiteId?: string
}