import { isArray } from "lodash";

const HOUR_IN_MILISECONDS = 3600000;

function calculateRetentionTimestamp(stream: { retentionPeriodInHours?: number } | { retentionPeriodInHours?: number }[], now: number): number {
  let start = now - (HOUR_IN_MILISECONDS * 720);

  if (isArray(stream)) {
    const streams = stream as VideoStream[];
    let greaterRetention: number = 0;

    streams.forEach(el => {
      if (el.retentionPeriodInHours && el.retentionPeriodInHours > greaterRetention) greaterRetention = el.retentionPeriodInHours;
    })

    if (greaterRetention > 0) {
      start = now - (greaterRetention * HOUR_IN_MILISECONDS);
    }
  } else {
    const cast = stream as VideoStream;
    if (cast && cast.retentionPeriodInHours) {
      start = now - (cast.retentionPeriodInHours * HOUR_IN_MILISECONDS)
    }
  }

  return start;
}

export default calculateRetentionTimestamp;
