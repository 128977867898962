import { API } from "aws-amplify";
import * as InternalAPI from '@API/index';

async function activateZone(streamId: string, geofenceId: string): Promise<Zone[]> {
  try {
    const path = `/geofences/${streamId}/${geofenceId}/reactivate`;

    const data: APIResponse<Zone[]> = await API.post(
      'api-service',
      path,
      {}
    );
  
    return data.data as Zone[];
  } catch (e) {
    const geofences = await InternalAPI.default.Geofences.getZones(streamId);
    return geofences || [];
  }
}

export default activateZone;
