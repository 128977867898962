import { capitalize } from 'lodash';

/**
 * Extract the entire User name from the object, capitalizing the first character of each name.
 * @param user Object with givenName and familyName
 * @returns Parsed string as full name.
 */
function getUserName(user: { givenName: string, familyName?: string }): string {
  const { givenName, familyName } = user;

  if (familyName === undefined || familyName.length === 0) return capitalize(givenName);
  return `${capitalize(givenName)} ${capitalize(familyName)}`;
}

export default getUserName;
