import React from 'react';
import { Box, IconButton, Slider, Tooltip } from '@mui/material';
import { ZoomIn, ZoomOut } from '@mui/icons-material';

type ZoomSliderProps = {
  zoomRate: number;
  onZoomInClick?: () => void;
  onZoomOutClick?: () => void;
  onChange?: (value: number) => void;
  disabled: boolean;
  min?: number;
  max?: number;
  step?: number;
  width?: string;
}

export const ZoomSlider: React.FC<ZoomSliderProps> = (props) => {
  const { zoomRate: videoZoom, onZoomInClick, onZoomOutClick, onChange, disabled, min, max, step, width } = props;

  function handleSliderChange(_event: object, value: number | number[]) {
    if (onChange) onChange(value as number);
  }

  return (
    <Box
      height="2.5rem"
      display="grid"
      gridTemplateColumns="min-content auto min-content"
      columnGap="0.75rem"
      alignItems="center"
      id="zoom-bar-description"
    >
      <Tooltip title="Zoom out" arrow placement="right">
        <IconButton disabled={disabled} onClick={onZoomOutClick} size="small">
          <ZoomOut />
        </IconButton>
      </Tooltip>
      <Slider
        disabled={disabled}
        color="secondary"
        style={{ width: width ? width : "6rem" }}
        value={videoZoom}
        min={min || 1}
        max={max || 3}
        step={step || 0.1}
        onChange={handleSliderChange}
      />
      <Tooltip title="Zoom in" arrow placement="right">
        <IconButton disabled={disabled} onClick={onZoomInClick} size="small">
          <ZoomIn />
        </IconButton>
      </Tooltip>
    </Box>
  );
}