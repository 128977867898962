import { AssetsPermissions, PermissionType } from "@store/session/types";

function userHasPermission(assetPermissions: AssetsPermissions, permission: PermissionType, siteId?: string, cameraId?: string): boolean {
  if (cameraId) {
    const cameraPermissions = assetPermissions[cameraId];
    return cameraPermissions?.includes(permission) || cameraPermissions?.includes("ALL") || false;
  }
  if (siteId) {
    return Object.entries(assetPermissions).some(([key, permissions]) => {
      return (key?.includes(siteId) && (permissions?.includes(permission) || permissions?.includes("ALL")))
    })
  }
  return Object.values(assetPermissions).some((permissions) => {
    return permissions?.includes(permission) || permissions?.includes("ALL");
  })
}

export default userHasPermission;
