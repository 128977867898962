import API from '@API/index';
import { KinesisVideo, KinesisVideoArchivedMedia } from "aws-sdk";
import { filter, first, isEmpty, last } from 'lodash';

type TimestampRange = { start: number, end: number };

const KVS_API_NAME = 'GET_HLS_STREAMING_SESSION_URL';
const PLAYBACK_MODE = 'ON_DEMAND'; // 'LIVE' or 'ON_DEMAND'
const FRAGMENT_SELECTOR_TYPE = 'PRODUCER_TIMESTAMP'; // 'SERVER_TIMESTAMP' or 'PRODUCER_TIMESTAMP'
const DISPLAY_FRAGMENT_TIMESTAMP = "ALWAYS";

/**
 * Offset of the start/end timestamp asked from the API caller.
 */
const TIMESTAMP_OFFSET = 10000;

/**
 * Lower max fragments can speed up API call but lower acuracy.
 */
const MAX_KVS_FRAGMENTS = 100;

const URL_DATABASE = new Map<string, { url: string, start: number, end: number }>();

async function getOnDemandStream(
  streamName: string,
  options: KinesisVideo.ClientConfiguration,
  startTimestamp: Date,
  endTimestamp: Date
): Promise<{ url: string, start: number, end: number }> {
  try {
    const preLoaded = URL_DATABASE.get(`${streamName}-${startTimestamp.getTime()}-${endTimestamp.getTime()}-${JSON.stringify(options)}`);
    if (preLoaded) {
      return preLoaded;
    }

    const dataEndpoint: string = await API.Kinesis.getDataEndpoint(
      streamName,
      options,
      KVS_API_NAME
    );

    let start = startTimestamp;
    let end = endTimestamp;

    const fragments: KinesisVideoArchivedMedia.FragmentList = await API.Kinesis.listStreamFragments(
      streamName,
      options,
      dataEndpoint,
      startTimestamp,
      endTimestamp,
      {
        maxResults: MAX_KVS_FRAGMENTS,
        startOffset: TIMESTAMP_OFFSET,
        endOffset: TIMESTAMP_OFFSET
      }
    )

    if (!isEmpty(fragments)) {
      const range: TimestampRange = getSuitableTimestampRange(startTimestamp.getTime(), endTimestamp.getTime(), fragments);
      start = new Date(range.start);
      end = new Date(range.end);
    } else {
      const diff = TIMESTAMP_OFFSET - (end.getTime() - start.getTime());
      const toAdd = Math.floor(diff / 2);
      start = new Date(start.getTime() - toAdd);
      end = new Date(end.getTime() + toAdd);
    }

    const streamUrl: string = await API.Kinesis.getHLSStreamingSessionURL(
      streamName,
      options,
      dataEndpoint,
      {
        PlaybackMode: PLAYBACK_MODE,
        DisplayFragmentTimestamp: DISPLAY_FRAGMENT_TIMESTAMP,
        HLSFragmentSelector: {
          FragmentSelectorType: FRAGMENT_SELECTOR_TYPE,
          TimestampRange: {
            StartTimestamp: start,
            EndTimestamp: end
          }
        }
      }
    );

    const result = {
      url: streamUrl,
      start: startTimestamp.getTime(),
      end: endTimestamp.getTime()
    }

    URL_DATABASE.set(`${streamName}-${startTimestamp.getTime()}-${endTimestamp.getTime()}-${JSON.stringify(options)}`, result);
    return result;

  } catch (error) {
    throw error;
  }
}

/**
 * Takes a AWS Kinesis Video Stream fragment and returns only the ProducerTimestamp from it.
 * @param fragment AWS Kinesis Video Stream fragment.
 */
function extractFragmentTimestamp(fragment?: KinesisVideoArchivedMedia.Fragment): number {
  return fragment?.ProducerTimestamp?.getTime() || 0;
}

/**
 * Takes a list of AWS Kinesis Video Stream fragments and returns an Array of timestamps from them.
 * @param fragments AWS Kinesis Video Stream fragments.
 */
function fragmentListToTimestampList(fragments: KinesisVideoArchivedMedia.FragmentList): number[] {
  const timestamps: number[] = fragments.map(fragment => extractFragmentTimestamp(fragment));
  timestamps.sort((a, b) => { return a - b })
  return timestamps;
}

/**
 * Finds a suitable range of timestamps close to the choosen start/end timestamps.
 * Given a list of fragments, this function will return the closest timestamps to those targets.
 * @param start Timestamp.
 * @param end Timestamp.
 * @param fragments List of AWS Kinesis Video Stream fragments for a given stream.
 */
function getSuitableTimestampRange(start: number, end: number, fragments: KinesisVideoArchivedMedia.FragmentList): TimestampRange {
  if (isEmpty(fragments)) {
    return {
      start,
      end
    }
  }

  const timestamps: number[] = fragmentListToTimestampList(fragments);

  const previous: number[] = filter(timestamps, (timestamp) => { return timestamp <= start });
  const after: number[] = filter(timestamps, (timestamp) => { return timestamp >= end });

  return {
    start: last(previous) || start,
    end: first(after) || end
  }
}

export default getOnDemandStream;
