import { Auth } from "aws-amplify";

async function enableMFA(): Promise<void> {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.setPreferredMFA(currentUser, "SMS");
  } catch (error: any) {
    throw error;
  }
}

export default enableMFA;