import { API } from 'aws-amplify';

async function updateWorker(worker: CrewWorker, asCompany?: string): Promise<any> {
  try {
    const tempWorker: any = worker;
    delete tempWorker.certification;
    delete tempWorker.certifications;
    delete tempWorker.siteIds;
    delete tempWorker.foremanName;
    delete tempWorker.companyId;
    delete tempWorker.foreman;
    delete tempWorker.title;
    delete tempWorker.description;
    delete tempWorker.phone;
    delete tempWorker.subcontractorName;
    delete tempWorker.subcontractor;
    if (tempWorker.foremanId === null)
      tempWorker.foremanId = undefined;
    if (tempWorker.email === null)
      tempWorker.email = undefined
    if (tempWorker.trade === null)
      tempWorker.trade = undefined
    
    const path = `/workers/${worker.id}` + (asCompany ? `?asCompany=${asCompany}` : "");

    const { data } = await API.patch('api-service', path, { body: tempWorker });
    return data;
  } catch (error: any) {
    throw error;
  }
}

export default updateWorker;
