import {
  applyMiddleware,
  createStore,
  StoreEnhancer,
  combineReducers
} from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { IAssetsState } from './assets/types';
import { ICompaniesState } from './companies/types';
import { ISessionState } from './session/types';
import { ISitesState } from './sites/types';
import { ISelectedCompaniesState } from './selectedCompanies/types';
import { ISelectedSitesState } from './selectedSites/types';
import { ISelectedStreamState } from './selectedStream/types';
import { IStreamsState } from './streams/types';
import { IUsersState } from './users/types';
import { IAppViewState } from './views/appView/types';
import { IGridViewState } from './views/gridView/types';
import { sessionReducer } from './session/reducers';
import { streamsReducer } from './streams/reducers';
import { assetsReducer } from './assets/reducers';
import { companiesReducer } from './companies/reducers';
import { selectedCompaniesReducer } from './selectedCompanies/reducers';
import { selectedSitesReducer } from './selectedSites/reducers';
import { selectedStreamReducer } from './selectedStream/reducers';
import { sitesReducer } from './sites/reducers';
import { usersReducer } from './users/reducers';
import { appViewReducer } from './views/appView/reducers';
import { gridViewReducer } from './views/gridView/reducers';
import 'map.prototype.tojson';
import { IActionsViewState } from './views/actionsView/types';
import { actionsViewReducer } from './views/actionsView/reducers';
import { IActionsState } from './actions/types';
import { actionsReducer } from './actions/reducers';
import { IScheduleTemplatesState } from './scheduleTemplates/types';
import { scheduleTemplatesReducer } from './scheduleTemplates/reducers';
import { IGridsState } from './grids/types';
import { gridsReducer } from './grids/reducers';
import { viewsViewReducer } from './views/viewsView/reducers';
import { IViewsViewState } from './views/viewsView/types';
import { exportsReducer } from './exports/reducers';
import { IExportsState } from './exports/types';
import { savedViewReducer } from './views/savedView/reducers';
import { ISavedViewState } from './views/savedView/types';
import { IOverviewViewState } from './views/overviewView/types';
import { overviewReducer } from './views/overviewView/reducers';
import { ITutorialsState } from './tutorials/types';
import { tutorialsReducer } from './tutorials/reducers';
import { IWorkersState } from '@store/workers/types';
import { workersReducer } from '@store/workers/reducers';
import { calendarReducer } from '@store/calendar/reducers';
import { ICalendarState } from '@store/calendar/types';
import { settingsReducer } from './views/settingsView/reducers';
import { ISettingsViewState } from './views/settingsView/types';
import { IHeatmapsState } from './heatmaps/types';
import { heatmapsReducer } from './heatmaps/reducers';

export interface IStoreState {
  actions: IActionsState;
  assets: IAssetsState;
  companies: ICompaniesState;
  session: ISessionState;
  scheduleTemplates: IScheduleTemplatesState;
  sites: ISitesState;
  streams: IStreamsState;
  users: IUsersState;
  selectedCompanies: ISelectedCompaniesState;
  selectedSites: ISelectedSitesState;
  selectedStream: ISelectedStreamState;
  appView: IAppViewState;
  gridView: IGridViewState;
  actionsView: IActionsViewState;
  grids: IGridsState;
  viewsView: IViewsViewState;
  exports: IExportsState;
  savedView: ISavedViewState;
  overviewView: IOverviewViewState;
  settingsView: ISettingsViewState;
  tutorials: ITutorialsState;
  workers: IWorkersState;
  calendar: ICalendarState;
  heatmaps: IHeatmapsState
}

// const loggerMiddleware = createLogger({});

const rootReducer = combineReducers({
  actions: actionsReducer,
  assets: assetsReducer,
  companies: companiesReducer,
  session: sessionReducer,
  scheduleTemplates: scheduleTemplatesReducer,
  sites: sitesReducer,
  streams: streamsReducer,
  users: usersReducer,
  selectedCompanies: selectedCompaniesReducer,
  selectedSites: selectedSitesReducer,
  selectedStream: selectedStreamReducer,
  appView: appViewReducer,
  gridView: gridViewReducer,
  actionsView: actionsViewReducer,
  grids: gridsReducer,
  viewsView: viewsViewReducer,
  exports: exportsReducer,
  savedView: savedViewReducer,
  overviewView: overviewReducer,
  settingsView: settingsReducer,
  tutorials: tutorialsReducer,
  workers: workersReducer,
  calendar: calendarReducer,
  heatmaps: heatmapsReducer
});

export function configureStore(preloadedState: any) {

  let middlewares = [];
  if (process.env.NODE_ENV !== 'production')
    middlewares = [thunkMiddleware];
  else
    middlewares = [thunkMiddleware];
  
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers: StoreEnhancer = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept(['./views/appView/reducers'], () =>
      store.replaceReducer(rootReducer)
    );
  }

  return store;
}
