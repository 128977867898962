import Tutorial from "./Tutorial";

class ViewsCameraSearchTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#camera-search-list-container',
        popover: {
          title: 'Cameras',
          description: 'List of all cameras present on the current site.',
        }
      },
      {
        element: '#search-header-common-field',
        popover: {
          title: 'Camera search',
          description: 'Find cameras by their name.',
        }
      },
      {
        element: '#camera-search-select-stream-state',
        popover: {
          title: 'Camera status',
          description: 'List cameras based on their current status.<br />Active - streams are up and running.<br />Archived - moved or replaced streams.<br />All - Active & Archived streams.',
          position: 'left',
        }
      },
    ])
  }
}

export default ViewsCameraSearchTutorial;
