import { Auth } from 'aws-amplify';
import disableMFA from './disableMFA';

async function resendPhoneCode(phone: string): Promise<void> {
  try {
    await disableMFA();
    await Auth.verifyCurrentUserAttribute(`phone_number`);

    // if (response !== "SUCCESS") throw new Error(response);
  } catch (error: any) {
    throw error;
  }
}

export default resendPhoneCode;
