import Utils from '@utils/index';
import { isNumber } from 'lodash';

function getStreamShareUrl(stream: VideoStream, timestamp?: number): string {
  const host: string = process.env.REACT_APP_HOST || "http://localhost:3000/";

  const params = Utils.Stream.getIdsFromStreamName(stream.kvsName);
  const { companyId, siteId, streamId } = params;
  const t: string = isNumber(timestamp) ? `&t=${timestamp}` : '';

  const url: string = `${host}timeline?company=${companyId}&site=${siteId}&stream=${streamId}${t}`;

  return url;
}

export default getStreamShareUrl;
