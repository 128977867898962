import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import LoadingView from '@pages/LoadingView';
import LocationHandler from './LocationHandler';
import AppBar from '@components/appbar/AppBar';
import MainSwitch from './MainSwitch';
import { Paper } from '@mui/material';
import ErrorFeedback from './ErrorFeedback';
import TutorialsHandler from './TutorialsHandler';
import TermsOfUseDialog from '@components/register/TermsOfUseDialog';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { isUndefined } from 'lodash';
import { PageView } from '@store/session/types';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';

const AppContainer = styled(Paper)`
  display: grid;
  height: 100%;
  width: 100%;
  border-radius: 0px;
`

const MainContainer = styled.div`
  display: flex;
  overflow: overlay;
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  opacity: 1;

  > main {
    flex: 1;
  }
`;

type MainRouterProps = {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isLoadingBasicData: boolean;
  isSuperAdministrator: boolean;
  isAdministrator: boolean;
  isTemporaryAccessRole: boolean;
}

/**
 * Main router of the App, renders <LoadingView /> or <MainSwitch />.
 * Responsabilities:
 * - Renders <LoadingView /> if the Dashboard is still Authenticating the user.
 * - Renders <MainSwitch /> if loading process is done.
 * @returns LoadingView or MainSwitch
 */
const MainRouter: React.FC<MainRouterProps> = (props) => {
  const { isAuthenticating, isAuthenticated, isLoadingBasicData, isSuperAdministrator, isAdministrator, isTemporaryAccessRole } = props;

  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();

  const userData = useSelector((store: IStoreState) => store.session.userData)
  const user = useSelector((store: IStoreState) => store.session.user);
  const activePage = useSelector((store: IStoreState) => store.session.activePage);

  return (
    <BrowserRouter>
      <AppContainer
        elevation={0}
        style={{
          gridTemplateColumns: Boolean(isMobile || isSmallScreen) === false ? 'min-content auto' : undefined,
          gridTemplateRows: Boolean(isMobile || isSmallScreen) === true ? 'min-content auto' : undefined
        }}
      >
        {isAuthenticating === true || isLoadingBasicData === true ? (
          <>
            <div />
            <LoadingView message="Please wait" />
          </>
        ) : (
          <>
            <LocationHandler />
            <TutorialsHandler />
            <ErrorFeedback />
            <AppBar />
            {!isUndefined(user) && !isUndefined(userData) && userData && userData.termsAccepted === false && activePage !== PageView.Policy && isAuthenticated === true &&
              <TermsOfUseDialog
                open={!isUndefined(user) && !isUndefined(userData) && userData && userData.termsAccepted === false}
              />
            }
            <React.Suspense fallback={<LoadingView message="Please wait" />}>
              <MainContainer>
                <MainSwitch
                  isAuthenticated={Boolean(isAuthenticated)}
                  isSuperAdministrator={Boolean(isSuperAdministrator)}
                  isAdministrator={Boolean(isAdministrator)}
                  isTemporaryAccessRole={Boolean(isTemporaryAccessRole)}
                />
              </MainContainer>
            </React.Suspense>
          </>
        )}
      </AppContainer>
    </BrowserRouter>
  )
}

export default MainRouter;
