import { INotificationChannels } from "@store/session/types";
import getNotificationChannelStatus from "./getNotificationChannelStatus";

export interface INotifications {
  getNotificationChannelStatus(): Promise<INotificationChannels>;
}

const Notifications: INotifications = {
  getNotificationChannelStatus
}

export default Notifications;
