import { API } from "aws-amplify";
import Utils from '@utils/index';

async function listSiteTimelapses(siteId: string): Promise<Timelapse[]> {
  try {
    const path: string = `/timelapse/${siteId}`;

    const response = await API.get(
      'api-service',
      path,
      {}
    ) as APIResponse<Timelapse[]>;

    return response.data?.map(el => ({ ...el, endTime: el.endTime - 1 })) || [];
  } catch (error) {
    console.log(Utils.Error.getErrorMessage(error));
    throw error;
  }
}

export default listSiteTimelapses;
