import { API } from "aws-amplify";

async function controleStrobe(streamId: string, device: Strobe): Promise<void> {
  const path: string = `/devices/attached/control/${streamId}/${device.type}`;
  const options = {
    body: {
      message: "on"
    }
  };

  try {
    await API.post('api-service', path, options);
  } catch (error: any) {
    throw error;
  }
}

export default controleStrobe;