import isSafari from "./isSafari";

export interface IBrowser {
  isSafari(): boolean;
}

const Browser: IBrowser = {
  isSafari,
}

export default Browser;
