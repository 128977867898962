import keyMirror from 'keymirror';
import { ISessionUser } from '@store/users/types';

export const ActionTypes = keyMirror({
  AUTHENTICATE_USER: null,
  SET_USER_DATA: null,
  CLEAR_USER: null,
  SET_ACTIVE_PAGE: null,
  SET_ACTIVE_COMPONENT: null,
  SET_SUPER_ADMINISTRATOR: null,
  SET_PERMISSIONS: null,
  SET_SESSION_USER: null
});

export interface ISessionState {
  isAuthenticated?: boolean;
  isSuperAdministrator?: boolean;
  user?: ISessionUser;
  credentials?: ICredentials;
  userData?: User;
  activePage: PageView;
  activeComponent: Components;
  permissions: AssetsPermissions;
}

export interface ICredentials {
  accessKeyId: string;
  sessionToken: string;
  secretAccessKey: string;
  identityId: string;
  authenticated: boolean;
}

export interface INotificationChannels {
  email: boolean;
  sms: boolean;
  web: boolean;
}

export enum PageView {
  None,
  Home,
  Streams,
  Timelapse,
  Player,
  Actions,
  Metrics,
  MyAccount,
  Organization,
  Site,
  Camera,
  Analytics,
  GetStarted,
  Exports,
  Attendance,
  Workers,
  Subcontractors,
  CalendarDirectory,
  Policy,
  Satellite,
  FlaggedWorkers,
  WorkersCertifications,
  Events,
  Map,
  Health,
  Sites,
  Companies,
  Management,
  ImageMatch,
  Vehicles
}

export enum Components {
  None,
  Player,
  Timelaps,
  Geofence,
  Exports
}

export type AssetsPermissions = { [key: string]: PermissionType[] };
export type PermissionType =
  "ALL" | // - view/edit everything

  "GEOFENCES" | // - edit geofences
  "GEOFENCES_VIEW" | // - view geofences

  "TIMELAPSES" | // - view/edit timelapses
  "TIMELAPSES_VIEW" | // - view timelapses

  "EVENTS" | // - edit all events
  "EVENTS_VIEW" | // - view all events

  "VIEW" | // - just view the stream
  "VIDEO_DOWNLOADS" | // - complete downloads permission
  "VIDEO_DOWNLOADS_VIEW" | // - just view the downloads
  "ANNOTATED_IMAGES" | // - complete snapshot permission
  "ANNOTATED_IMAGES_VIEW" | // - just view the snapshots
  "HEATMAPS" | // - view heatmaps
  "ACTIONS_VIEW" | // - view actions
  "ACTIONS" | // - edit actions
  "PTZ_CONTROL" // - control camera PTZ settings