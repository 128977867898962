export function createTimelapseFilters(isSuperAdministrator?: boolean): FilterProperties[] {
  return [
    { label: "Author", property: 'author', type: 'string' },
    { label: "Name", property: 'name', type: 'string' },
    { label: "Description", property: 'description', type: 'string' },
    { label: "KVS Name", property: 'streamName', type: "string" },
    { label: "Framerate", property: 'framerate', type: "number" },
    { label: "Duration", property: 'duration', type: "number" },
    { label: "Created at", property: 'createdAt', type: "date" },
    { label: "Start time", property: 'startTime', type: "date" },
    { label: "End time", property: 'endTime', type: "date" },
    { label: "Position", property: 'position', type: "number" },
    { label: "Status", property: 'status', type: "select", options: ["IN_PROGRESS", "READY", "FAILURE", "PARTIAL", "MISSING_FRAMES"] }
  ]
}