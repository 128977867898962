import StreamThumbnailManager from '@classes/StreamThumbnailManager';
import { Skeleton } from '@mui/material';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import background from '../../assets/images/placeholders/stream_offline.svg';

const SiteThumbnail: React.FC<{ site: Site }> = (props) => {
  const { site } = props;

  const [ready, setReady] = React.useState<boolean>(false);
  const [thumbnail, setThumbnail] = React.useState<string>('');
  const streams = useSelector((store: IStoreState) => store.streams.streams);

  const streamsRef = React.useRef(streams);
  const setThumbnailRef = React.useRef(setThumbnail)
  const siteRef = React.useRef(site);

  const currentPosition = React.useRef<number>(0);

  React.useEffect(() => { siteRef.current = site }, [site])
  React.useEffect(() => { setThumbnailRef.current = setThumbnail }, [setThumbnail])
  React.useEffect(() => { streamsRef.current = streams }, [streams])
  React.useEffect(mount, []);

  function mount(): void { tryNext() }

  async function tryNext() {
    try {
      const siteStreams = streamsRef.current.filter(el => el.siteId === siteRef.current.siteId);

      if (currentPosition.current > (siteStreams.length - 1)) {
        setThumbnailRef.current(background);
        return;
      }

      const url = await StreamThumbnailManager.instance.thumbnailOfStream(siteStreams[currentPosition.current]);
      setThumbnailRef.current(url);
      currentPosition.current += 1;
    } catch (error) {}
  }

  return (
    <>
      {ready === false &&
        <Skeleton
          variant="rectangular"
          style={{ aspectRatio: '16/9', height: '2rem', borderRadius: '4px', visibility: ready === false ? 'visible' : 'hidden' }}
        />
      }
      <img
        alt=""
        src={thumbnail}
        style={{ aspectRatio: '16/9', height: '2rem', borderRadius: '4px', display: ready === false ? 'none': undefined }}
        onLoad={() => { setReady(true); }}
        onError={() => { tryNext() }}
      />
    </>
  )
}

export default SiteThumbnail;
