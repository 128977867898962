import { GridColDef } from '@mui/x-data-grid-pro';
import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  ACTIVE_SUBCONTRACTOR: null,
  ACTIVE_FOREMAN: null,
  SET_WORKERS: null,
  SET_SUBCONTRACTORS: null,
  SET_WORKERS_FILTER_TEXT: null,
  SET_FILTERED_WORKERS: null,
  SET_FILTERED_SUBCONTRACTORS: null,
  SET_SUBCONTRACTORS_FILTER_TEXT: null,
  SET_SELECTED_WORKERS: null,
  SET_WORKER_IMAGES: null,
  SET_WORKER_TO_EDIT: null,
  SET_WORKER_TO_EDIT_DIALOG_OPEN: null,
  SET_SUB_TO_EDIT: null,
  SET_SUB_TO_EDIT_DIALOG_OPEN: null,
  SET_SUBS_COLUMNS: null,
  SET_WORKERS_COLUMNS: null,
});

export interface IWorkersState {
  workers: CrewWorker[];
  subcontractors: Subcontractor[];
  filteredWorkers: CrewWorker[];
  filteredSubcontractors: Subcontractor[];
  selectedWorkers: CrewWorker[];
  workerImages: { [key: string]: string };
  workerToEditDialogOpen: boolean;
  subToEditDialogOpen: boolean;
  subcontractor?: Subcontractor;
  foreman?: CrewWorker;
  workersFilterText?: string;
  subcontractorsFilterText?: string;
  workerToEditId?: number;
  subToEdit?: Subcontractor;
  subColumns: GridColDef[];
  workerColumns: GridColDef[];
}
