import { Box } from '@mui/material';
import { HealthTabs } from '@store/views/overviewView/types';
import React from 'react';
import HealthEdgeDevices from './HealthEdgeDevices';
import HealthCameras from './HealthCameras';

type HealthContentProps = {
  activeTab: HealthTabs;
}

const HealthContent: React.FC<HealthContentProps> = (props) => {
  const { activeTab } = props;

  return (
    <Box width="100%" height="100%">
      {activeTab === HealthTabs.EdgeDevices && <HealthEdgeDevices />}
      {activeTab === HealthTabs.Cameras && <HealthCameras />}
    </Box>
  )
}

export default HealthContent;
