export function download(fileName: string, url: string): void {
  try {
    var tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
    tempLink.remove();
  } catch (error) {
    throw error;
  }
}
