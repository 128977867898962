function findClosestTimestamp(items: { timestamp: number }[], timestamp: number): object | undefined {
  if (items.length > 0) {
    const closest = items.reduce(function (prev, curr) {
      return (Math.abs(curr.timestamp - timestamp) < Math.abs(prev.timestamp - timestamp) ? curr : prev);
    })

    return {
      ...closest,
      timestamp: +closest.timestamp
    }
  }
}

export default findClosestTimestamp;