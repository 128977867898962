import { Storage } from 'aws-amplify';
import Utils from '@utils/index';

interface IS3Image {
  url: string,
  timestamp: number
}

async function getEventFootage(stream: VideoStream, start: number, end: number): Promise<IS3Image[]> {
  try {
    const date = new Date(start);

    const companyId = stream.companyId;
    const siteId = stream.siteId;
    const kvsName = stream.kvsName;
    const year = date.getUTCFullYear();
    const month = Utils.Date.getTwoDigitsUTCMonth(date);
    const day = Utils.Date.getTwoDigitsUTCDate(date);
    const hours = Utils.Date.getTwoDigitsUTCHours(date);

    const s3path: string = `${companyId}/${siteId}/${kvsName}/${year}/${month}/${day}/${hours}`
    const listing = await Storage.list(
      s3path,
      {
        bucket: process.env.REACT_APP_AGGREGATED_IMAGES_S3_BUCKET
      }
    );

    const filteredImages = listing.results.filter(image => Utils.RegEx.findTimestamp(image.key || "") >= start && Utils.RegEx.findTimestamp(image.key || "") <= end);
    if (process.env.REACT_APP_AGGREGATED_IMAGES_S3_BUCKET) {
      const getImagesUrlPromises = filteredImages.map(object =>
        Utils.S3.getImageUrl(
          object.key || "",
          { timestamp: Utils.RegEx.findTimestamp(object.key || "") },
          process.env.REACT_APP_AGGREGATED_IMAGES_S3_BUCKET
        ) as Promise<IS3Image>
      );

      const images = await Promise.all(getImagesUrlPromises);
      const finalImages = await Promise.all(images.map(element => Utils.S3.decodeUrl(element)));

      return finalImages;
    }
    else {      
      throw Object.assign(
        new Error("Images source not set"),
        { code: 404 }
      );
    }
  } catch (error: any) {
    throw error;
  }
}

export default getEventFootage;
