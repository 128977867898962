const ZOOM_LEVELS = new Map([
  [(3600000 * 24) * 30, 0],
  [2356363637, 0.1], [2142148760, 0.2], [1947407963, 0.3], [1770370876, 0.4],
  [1609428069, 0.5], [1463116426, 0.6], [1330105841, 0.7], [1209187128, 0.8], [1099261025, 0.9],
  [999328204, 1.0], [908480185, 1.1], [825891078, 1.2], [750810071, 1.3], [682554610, 1.4],
  [620504191, 1.5], [564094720, 1.6], [512813381, 1.7], [466193982, 1.8],
  [423812711, 1.9], [385284282, 2.0], [350258439, 2.1], [318416762, 2.2], [289469783, 2.3],
  [263154348, 2.4], [239231225, 2.5], [217482932, 2.6], [197711757, 2.7],
  [179737960, 2.8], [163398145, 2.9], [148543768, 3.0], [135039789, 3.1],
  [122763444, 3.2], [111603131, 3.3], [101457392, 3.4], [92233993, 3.5], [83849084, 3.6],
  [76226440, 3.7], [69296763, 3.8], [62997058, 3.9], [57270053, 4.0],
  [52063684, 4.1], [47330621, 4.2], [43027838, 4.3], [39116217, 4.4], [35560198, 4.5],
  [32327453, 4.6], [29388594, 4.7], [26716903, 4.8], [24288094, 4.9],
  [22080085, 5.0], [20072804, 5.1], [18248003, 5.2], [16589094, 5.3], [15080995, 5.4],
  [13709996, 5.5], [12463633, 5.6], [11330576, 5.7], [10300523, 5.8],
  [9364112, 5.9], [8512829, 6.0], [7738936, 6.1], [7035397, 6.2], [6395816, 6.3],
  [5814379, 6.4], [5285800, 6.5], [4805273, 6.6], [4368430, 6.7],
  [3971300, 6.8], [3610273, 6.9], [3282066, 7.0], [2983697, 7.1], [2712452, 7.2],
  [2465865, 7.3], [2241696, 7.4], [2037905, 7.5], [1852640, 7.6],
  [1684219, 7.7], [1531108, 7.8], [1391917, 7.9], [1265380, 8.0], [1150345, 8.1],
  [1045768, 8.2], [950699, 8.3], [864272, 8.4], [785701, 8.5], [714274, 8.6],
  [649340, 8.7], [590309, 8.8], [536644, 8.9], [487859, 9.0],
  [443508, 9.1], [403189, 9.2], [366536, 9.3], [333215, 9.4], [302922, 9.5],
  [275383, 9.6], [250348, 9.7], [227589, 9.8], [206900, 9.9],
  [188091, 10], [170992, 10.1], [155447, 10.2], [141316, 10.3],
  [128469, 10.4], [116790, 10.5], [106173, 10.6], [96520, 10.7], [87745, 10.8],
  [79768, 10.9], [72517, 11], [65924, 11.1],
  [60000, 11.2],
])

class ZoomLevel {
  private start: number;
  private end: number;

  constructor(start: number, end: number) {
    this.start = start;
    this.end = end;
  }

  public getZoomLevel(): number {
    const offset = this.end - this.start;

    const vals = Array.from(ZOOM_LEVELS.keys());
    let key: undefined | number = 0.0;

    for (let i = 0; i < vals.length; i += 1) {
      if (offset >= (3600000 * 24) * 30) {
        key = 0;
        break;
      }
      if (offset <= vals[i] && offset >= vals[i + 1]) {
        key = ZOOM_LEVELS.get(vals[i])
        break;
      }

      if (offset === 60000) {
        key = 11.2;
        break;
      }
    }

    return key || 0;
  }
}

export default ZoomLevel;
