function getHost(url: string): string {
  if (hasEmbededCredentials(url)) {
    return url?.split('@')[1]?.split(':')[0] || "";
  }

  const host = url?.split('//')[1]?.split('/')[0] || "";

  if (hasPort(host)) {
    return host.split(':')[0];
  }

  return host;
}

function hasEmbededCredentials(url: string): boolean {
  return url.includes('@') && url.includes(':');
}

function hasPort(url: string): boolean {
  return url.includes(':')
}

export default getHost;
