import { ISiteGrid } from "@store/selectedStream/types";
import listCompanyGrids from "./listCompanyGrids";

export interface IGrids {
  listCompanyGrids(companyId: string): Promise<ISiteGrid[]>;
}

const Grids: IGrids = {
  listCompanyGrids
}

export default Grids;
