import { DbTable } from "./types";

function getObjectName(table: DbTable, objectId: string): string {
  switch (table) {
    case DbTable.Actions:
      return ""

    default:
      return objectId;
  }
}

export default getObjectName;