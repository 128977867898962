import React from 'react';
import API from '@API/index';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { Box, Button, CircularProgress, Fade, FormControl, Typography } from '@mui/material';
import NonIdealState from '@components/common/NonIdealState/NonIdealState';
import Header from '@components/common/Header/Header';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';
import CustomInputLabel from './Input/CustomInputLabel';
import MultiSelectInput from './Input/MultiSelectInput';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { Info, InsertChart } from '@mui/icons-material';
import { IEdgeDevice } from '@store/assets/types';
import { first, isEmpty } from 'lodash';
import LoadingContent from '@components/overview/LoadingContent';
import { PrometheusQuery } from '@classes/prometheus/PrometheusQuery';
import TimeRangeSelector from './Input/TimeRangeSelector';
import * as moment from 'moment';
import { ICredentials } from '@store/session/types';
import { NoMaxWidthTooltip } from '@components/views/site/StreamItem';
import MetricsLineChart from './MetricsLineChart';

const METRICS = [
  {
    id: "calculated:cpu_utilization",
    label: "CPU utilization"
  },
  {
    id: "calculated:ram_utilization",
    label: "RAM utilization"
  },
  {
    id: "calculated:swap_utilization",
    label: "SWAP utilization"
  },
  {
    id: "nvidia_gpu_duty_cycle",
    label: "Nvidia - GPU usage"
  },
  {
    id: "calculated:gpu_ram_utilization",
    label: "Nvidia - GPU RAM"
  },
  // {
  //   id: "nvidia_gpu_fanspeed_percent",
  //   label: "Nvidia - Fan speed"
  // },
  // {
  //   id: "nvidia_gpu_temperature_celsius",
  //   label: "Nvidia - Temperature (°C)"
  // },
  // {
  //   id: "nvidia_gpu_power_usage_milliwatts",
  //   label: "Nvidia - Power usage (mW)"
  // },
  // {
  //   id: "jetson_fan_speed",
  //   label: "Jetson - Fan speed"
  // },
  // {
  //   id: "jetson_temperature_C",
  //   label: "Jetson - Temperature (°C)"
  // },
  {
    id: "jetson_usage_gpu",
    label: "Jetson - GPU usage"
  },
  // {
  //   id: "jetson_usage_power_mW",
  //   label: "Jetson - Power usage (mW)"
  // },
  // {
  //   id: "jetson_usage_ram_kb",
  //   label: "Jetson - GPU RAM usage"
  // },
  // {
  //   id: "node_boot_time_seconds",
  //   label: "NodeJS - Boot time"
  // },
  {
    id: "calculated:filesystem_utilization_root",
    label: "Used disk (root)"
  },
  {
    id: "calculated:filesystem_utilization_data",
    label: "Used disk (data)"
  },
  // {
  //   id: "node_memory_SwapTotal_bytes",
  //   label: "SWAP Utilization"
  // },
  // {
  //   id: "node_network_receive_bytes_total",
  //   label: "Network received"
  // },
  // {
  //   id: "node_network_transmit_bytes_total",
  //   label: "Network sent"
  // },
  {
    id: "calculated:edge_inference_request_success_total",
    label: "Interference request success"
  },
  {
    id: "calculated:edge_inference_request_failure_total",
    label: "Interference request failure"
  }
]

type MetricsProps = {
  devices: IEdgeDevice[];
  onClose: () => void;
};

const Metrics: React.FC<MetricsProps> = (props) => {
  const { devices, onClose } = props;

  const credentials = useSelector((store: IStoreState) => store.session.credentials);
  const isSmallScreen = useSmallScreenQuery();
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);

  const [data, setData] = React.useState<PrometheusQuery[][] | undefined>(undefined);
  const [error, setError] = React.useState<boolean>(false);
  const [selectedMetrics, setSelectedMetrics] = React.useState<string[]>(METRICS.map(el => el.label));
  const [dateRange, setDateRange] = React.useState<{ start: Date, end: Date }>({ start: new Date(moment.utc().valueOf() - (86400000)), end: new Date(moment.utc().valueOf()) })
  const [loading, setLoading] = React.useState<boolean>(false);
  const [hidden, setHidden] = React.useState<Map<string, boolean>>(new Map())

  const devicesRef = React.useRef(devices);
  const credentialsRef = React.useRef(credentials);
  const dateRangeRef = React.useRef(dateRange);
  const selectedMetricsRef = React.useRef(selectedMetrics);

  const RechartsGraph = React.useMemo(() => {
    return (
      <Graph
        params={data}
        loading={loading}
        error={error}
        hidden={hidden}
        setHidden={setHidden}
      />
    )
  }, [data, error, hidden, loading])

  const getGraph = React.useCallback((devices: IEdgeDevice[], selectedMetrics: string[], dateRange: { start: Date, end: Date }, credentials?: ICredentials) => {
    setLoading(true);
    if (selectedMetrics.length && devices.length && credentials) {
      setError(false);
      Promise.all(METRICS.filter(el => selectedMetrics.includes(el.label)).map(metric => {
        let query: string = `${metric.id}{instance="${first(devices)?.mac || ""}"}`;
        if (metric.id === "node_network_receive_bytes_total" || metric.id === "node_network_transmit_bytes_total") {
          query = `rate(${metric.id}{instance="${first(devices)?.mac || ""}"}[5m])`
        }

        if (metric.id === "calculated:edge_inference_request_failure_total" || metric.id === "calculated:edge_inference_request_success_total") {
          query = `increase(${metric.id}{instance="${first(devices)?.mac || ""}"}[2m])`
        }

        return API.Prometheus.getMetric(
          selectedCompany?.companyId || "",
          query,
          metric.id || "",
          dateRange.start,
          dateRange.end,
          credentials
        );
      }))
        .then((response: PrometheusQuery[]) => {
          const querries: PrometheusQuery[][] = [];
          const common = response.filter(el =>
            el.getId() !== "node_network_receive_bytes_total" &&
            el.getId() !== "node_network_transmit_bytes_total" &&
            el.getId() !== "calculated:filesystem_utilization_data" &&
            el.getId() !== "calculated:filesystem_utilization_root" &&
            el.getId() !== "calculated:ram_utilization" &&
            el.getId() !== "calculated:swap_utilization" &&
            el.getId() !== "jetson_usage_ram_kb" &&
            el.getId() !== "node_memory_SwapTotal_bytes" &&
            el.getId() !== "jetson_usage_gpu" &&
            el.getId() !== "nvidia_gpu_duty_cycle" &&
            el.getId() !== "nvidia_gpu_fanspeed_percent" &&
            el.getId() !== "jetson_fan_speed" &&
            el.getId() !== "jetson_temperature_C" &&
            el.getId() !== "nvidia_gpu_temperature_celsius" &&
            el.getId() !== "nvidia_gpu_power_usage_milliwatts" &&
            el.getId() !== "jetson_usage_power_mW" &&
            el.getId() !== "calculated:edge_inference_request_success_total" &&
            el.getId() !== "calculated:edge_inference_request_failure_total" &&
            el.getId() !== "calculated:gpu_ram_utilization"
          );

          const ram = response.filter((el) =>
            el.getId() === "calculated:ram_utilization" ||
            el.getId() === "calculated:swap_utilization" ||
            el.getId() === "calculated:gpu_ram_utilization"
          )

          const gpu_ram = response.filter((el) =>
            el.getId() === "jetson_usage_ram_kb" ||
            el.getId() === "node_memory_SwapTotal_bytes"
          )

          const network = response.filter(el => el.getId() === "node_network_receive_bytes_total" || el.getId() === "node_network_transmit_bytes_total");
          const filesystem = response.filter(el => el.getId() === "calculated:filesystem_utilization_root" || el.getId() === "calculated:filesystem_utilization_data");
          const gpu = response.filter(el => el.getId() === "jetson_usage_gpu" || el.getId() === "nvidia_gpu_duty_cycle");
          const fan = response.filter(el => el.getId() === "jetson_fan_speed" || el.getId() === "nvidia_gpu_fanspeed_percent");
          const temperature = response.filter(el => el.getId() === "nvidia_gpu_temperature_celsius" || el.getId() === "jetson_temperature_C");
          const power = response.filter(el => el.getId() === "nvidia_gpu_power_usage_milliwatts" || el.getId() === "jetson_usage_power_mW");
          const inferenceSuccess = response.filter(el => el.getId() === "calculated:edge_inference_request_success_total");
          const inferenceFailure = response.filter(el => el.getId() === "calculated:edge_inference_request_failure_total")


          if (!isEmpty(inferenceSuccess)) querries.push(inferenceSuccess);
          if (!isEmpty(inferenceFailure)) querries.push(inferenceFailure);

          common.forEach(el => querries.push([el]))
          if (!isEmpty(ram)) querries.push(ram);

          if (!isEmpty(gpu_ram)) querries.push(gpu_ram);
          if (!isEmpty(network)) querries.push(network);
          if (!isEmpty(filesystem)) querries.push(filesystem);
          if (!isEmpty(gpu)) querries.push(gpu);
          if (!isEmpty(fan)) querries.push(fan);
          if (!isEmpty(temperature)) querries.push(temperature);
          if (!isEmpty(power)) querries.push(power);

          const map = new Map();
          querries.forEach((group => {
            group.forEach((query) => {
              query.getPossibleValues().forEach((value) => {
                map.set(value.id, false);
              })
            })
          }));

          setHidden(map)

          setData(querries)
        })
        .catch((error) => {
          setError(true);
          console.log(error)
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }, [selectedCompany?.companyId])

  React.useEffect(() => { getGraph(devicesRef.current, selectedMetricsRef.current, dateRangeRef.current, credentialsRef.current) }, [getGraph])

  return (
    <Fade in={true}>
      <Box
        padding="1.5rem"
        display="grid"
        gridTemplateRows="min-content auto"
        width="100%"
        height="100%"
        overflow-y="hidden"
      >
        <Header
          large
          title={<Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h4">Device info</Typography>
              <NoMaxWidthTooltip style={{ maxWidth: 'unset' }} title={
                <>
                  <p><b>Company: </b>{first(devices)?.companyId}</p>
                  <p><b>Site: </b>{first(devices)?.siteId}</p>
                  <p><b>IoT thing name: </b>{first(devices)?.iotThingName}</p>
                  <p><b>MAC address: </b>{first(devices)?.mac}</p>
                </>
              }>
                <Info style={{ marginLeft: '0.5rem' }} />
              </NoMaxWidthTooltip>
            </Box>
            {first(devices) && <Typography variant="subtitle2">{`(${first(devices)?.mac})`}</Typography>}
          </Box>}
          onClose={() => { if (onClose) onClose() }}
        >
          <>
            <FormControl style={{ width: isSmallScreen ? "7rem" : '20rem' }}>
              <CustomInputLabel id="roles">Metrics</CustomInputLabel>
              <MultiSelectInput
                onChange={(values: string[]) => setSelectedMetrics(values)}
                options={METRICS.map(met => met.label)}
                disableWidth
                initialSelection={selectedMetrics}
                selectAllOption={{
                  label: "All metrics"
                }}
                value={selectedMetrics}
                rootFontSize={rootFontSize}
              />
            </FormControl>
            <TimeRangeSelector
              timeRange={dateRange}
              onTimeRangeChange={(start, end) => {
                if (start && end) setDateRange({ start, end })
              }}
              enableLiveOption={false}
              disableLongTermOptions={true}
              timezone={"UTC (GMT+00:00)"}
              selectedSite={{ timezone: "UTC (GMT+00:00)" } as Site}
              calendarUtcMessage={'Timezone of the filter is UTC.'}
            />
            <Button
              id="create-report-description"
              variant="contained"
              color="primary"
              onClick={() => getGraph(devices, selectedMetrics, dateRange, credentials)}
              data-cy="trends-create-report-button"
              disabled={selectedMetrics.length === 0}
            >
              Create report
            </Button>
          </>
        </Header>
        {RechartsGraph}
      </Box >
    </Fade>
  );
};

type GraphProps = {
  params: PrometheusQuery[][] | undefined
  loading: boolean;
  error?: any;
  hidden: Map<string, boolean>;
  setHidden: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
}

const Graph: React.FC<GraphProps> = (props) => {
  const { params, loading, error, hidden, setHidden } = props;

  const isMobile = useMobileQuery();

  function getRows(params: PrometheusQuery[][] | undefined) {
    if (params) {
      if (params.length < 5) return "1fr";
      return "1fr 1fr";
    }

    return undefined;
  }

  if (loading) {
    return <LoadingContent message="Fetching metrics..." />
  }

  if (error) {
    return <NonIdealState title="There has been problem with getting data!" icon="error" />
  }

  if (params)
    return (
      <Box
        width="100%"
        height="100%"
        display="grid"
        gridTemplateColumns={getRows(params)}
      >
        {params.map((data) => {
          return (
            <MetricsLineChart
              data={data}
              setHidden={setHidden}
              hidden={hidden}
            />
          )
        })}
      </Box>
    )

  if (params === undefined)
    return <NonIdealState title={"Create report"} description="In the top right, select the metrics and date range to visualize them." padding={isMobile ? "5vh 2rem" : "30vh 2rem"} icon={<InsertChart />} />

  else
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}><CircularProgress /></div>;
}

export default Metrics;
