import { setCompanies } from '@store/companies/actions';
import { setSites } from '@store/sites/actions';
import { store } from '../../index';
import API from '@API/index';
import { setGrids } from '@store/grids/actions';
import { setErrorLoadingCompanies } from '@store/views/appView/actions';
import { setStreamList } from '@store/streams/actions';
import { setPermissions } from '@store/session/actions';
import { AssetsPermissions } from '@store/session/types';

export const fetchAllCompaniesAndSites = async () => {
  const params = new URLSearchParams(window.location.search);

  const urlCompany = params.get('company');
  const urlSite = params.get('site');
  const urlStream = params.get('stream');
  const urlView = params.get('view');

  await Promise.all([
    loadCompanies(urlCompany),
    loadSites(urlCompany, urlSite),
    loadStream(urlCompany, urlSite, urlStream),
    loadView(urlCompany, urlView)
  ])
}

async function loadCompanies(urlCompany: string | null): Promise<Company | undefined> {
  try {
    const companies: Company[] = [];
    try {
      const apiCompanies = await API.Company.listCompanies();
      companies.push(...apiCompanies);
    } catch (error) {
      store.dispatch(setErrorLoadingCompanies(error))
    }
    store.dispatch(setCompanies(companies));
    return companies.find(el => el?.companyId === urlCompany);
  } catch (e) {
    console.log(e);
  }
}

async function loadSites(urlCompany: string | null, urlSite: string | null): Promise<Site | undefined> {
  try {
    if (urlCompany && urlSite) {
      const sites = await API.Site.listSites(urlCompany || "");
      store.dispatch(setSites(urlCompany, sites))
      return sites.find(el => el.siteId === `${urlCompany}-${urlSite}`);
    }

  } catch (e) {
    console.log(e);
  }
}

async function loadStream(urlCompany: string | null, urlSite: string | null, urlStream: string | null): Promise<VideoStream | undefined> {
  try {
    if (urlCompany && urlSite && urlStream) {
      const streams = await API.Streams.listStreams(`${urlCompany}-${urlSite}`);
      store.dispatch(setStreamList(streams));

      const userData = store.getState().session.userData;
      const targetStream = streams.find(el => el.kvsName.includes(urlStream));

      if (userData && targetStream) {
        if (userData.roles === 'superadmin' || userData.roles === 'admin') {
          const permissions: AssetsPermissions = {};
          permissions[targetStream.kvsName] = ["ALL"];
          store.dispatch(setPermissions(permissions));
        } else {
          const apiPermissions = await API.Permissions.listPermissionsForStream(userData.userId, targetStream.kvsName);
          const permissions: AssetsPermissions = {};
          permissions[targetStream.kvsName] = apiPermissions.permissions;
          store.dispatch(setPermissions(permissions));
        }
      }

      return streams.find(el => el.kvsName.includes(urlStream));
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
}

async function loadView(urlCompany: string | null, urlView: string | null): Promise<UserView | undefined> {
  try {
    if (urlCompany) {
      const views = await API.Views.getViewsFromCompany(urlCompany);
      const v = { [urlCompany]: views };

      store.dispatch(setGrids(v));
      if (urlView) {
        return views.find(el => el.id === urlView)
      }
    }
  } catch (e) {
    throw e;
  }
}
