import EventsFilter, { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Chip, CircularProgress, IconButton, Typography } from '@mui/material';
import { ArrowBack, ExpandMore, Fullscreen } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import TagFilter from './TagFilter';
import SiteSelector from './SiteSelector';
import TimeRangeSelector, { TimeRangeSelectorProps } from '@components/common/Input/TimeRangeSelector';
import TutorialButton from '@components/common/TutorialButton';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import FilterProvider, { IFilterProviderHandles } from '@components/common/Filters/FilterProvider';

const ScrollingWraper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
`

export type FilterPanelProps = {
  features: StreamFeatures;
  zones: Zone[];
  filterOptions: Record<string, string[]>;
  defaultState: boolean;
  eventsFilterParams?: EventsFilterParams;
  onReturnClick?: () => void;
  onApplyFilters?: (filter?: EventsFilter) => void;
  areaSelection?: Rectangle;
  onRemoveAreaSearch?: () => void;
  onAreaSearchClick?: () => void;
  areaSearchActive?: boolean;
  noPadding?: boolean;
  maxContainerHeight?: string | number;
  disableIndividualZones?: boolean;
  siteFiltering?: {
    sites: Site[];
  }
  timeRangeSelectorProps?: TimeRangeSelectorProps,
  disableEventsTutorial?: boolean;
  onSiteListOpen?: () => void;
  onSiteListClose?: () => void;
  onInputFocus?: () => void;
  onInputBlur?: () => void;
  showTruePositiveFilters?: boolean;
  showFalsePositiveFilters?: boolean;
  showEscalationFilters?: boolean;
  singleSite?: boolean;
}

const FilterPanel: React.FC<FilterPanelProps> = (props) => {
  const {
    onReturnClick,
    onApplyFilters,
    eventsFilterParams: eventsFilter,
    features,
    areaSelection,
    onRemoveAreaSearch,
    onAreaSearchClick,
    areaSearchActive,
    zones,
    noPadding,
    maxContainerHeight,
    disableIndividualZones,
    siteFiltering,
    timeRangeSelectorProps,
    disableEventsTutorial,
    onSiteListOpen,
    onSiteListClose,
    showTruePositiveFilters,
    showFalsePositiveFilters,
    showEscalationFilters,
    singleSite,
    filterOptions,
    onInputBlur,
    onInputFocus,
    defaultState
  } = props;

  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const permissions = useSelector((store: IStoreState) => store.session.permissions);
  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);
  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);

  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [tagWraperHeight, setTagWraperHeight] = React.useState<number>(0);
  const [selectedSites, setSelectedSites] = React.useState<Site[]>([]);
  const [onGoingReset, setOnGoingReset] = React.useState<boolean>(false);

  const [motionFilters, setMotionsFilter] = React.useState<FilterToApply[]>([])
  const [peopleFilters, setPeopleFilter] = React.useState<FilterToApply[]>([])
  const [vechileFilters, setVehicleFilter] = React.useState<FilterToApply[]>([])
  const [safetyFilters, setSafetyFilter] = React.useState<FilterToApply[]>([])
  const [zonesFilters, setZonesFilter] = React.useState<FilterToApply[]>([])

  const [enableMotion, setEnableMotion] = React.useState(defaultState);
  const [enablePeople, setEnablePeople] = React.useState(defaultState);
  const [enableVehicle, setEnableVehicle] = React.useState(defaultState);
  const [enableSafety, setEnableSafety] = React.useState(defaultState);
  const [enableZones, setEnableZones] = React.useState(defaultState);

  const [expanded, setExpanded] = React.useState<string[]>([]);

  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const titleRef = React.useRef<HTMLDivElement | null>(null);
  const scrollHeightRef = React.useRef<HTMLDivElement | null>(null);
  const tagContainerRef = React.useRef<HTMLDivElement | null>(null);
  const eventsFilterRef = React.useRef(eventsFilter);
  const siteFilteringRef = React.useRef(siteFiltering)
  const loadedRef = React.useRef(loaded);
  const disableIndividualZonesRef = React.useRef(disableIndividualZones);
  const onApplyFiltersRef = React.useRef(onApplyFilters);
  const debounceRef = React.useRef<NodeJS.Timeout | null>(null);


  const motionFilterProviderRef = React.useRef<IFilterProviderHandles | null>(null);
  const peopleFilterProviderRef = React.useRef<IFilterProviderHandles | null>(null);
  const vehicleFilterProviderRef = React.useRef<IFilterProviderHandles | null>(null);
  const safetyFilterProviderRef = React.useRef<IFilterProviderHandles | null>(null);
  const zonesFilterProviderRef = React.useRef<IFilterProviderHandles | null>(null);

  const stringOperators = React.useMemo(() => ["contains"], []);
  const stringArrayOperators = React.useMemo(() => ["is_any_of"], []);
  const durationOperators = React.useMemo(() => ["greater_or_equal"], []);

  const sharedFilterProps: FilterProperties[] = React.useMemo(() => {

    const sharedFilters: FilterProperties[] = [
      { label: "Event duration", property: "duration", type: "duration", operators: durationOperators },
    ]

    const truePositiveTagOptions = [
      { label: "Person", value: "true_positive_person" },
      { label: "Vehicle", value: "true_positive_vehicle" },
    ]
    const falsePositiveTagOptions = [
      { label: "Animal", value: "false_positive_animal" },
      { label: "Light", value: "false_positive_light" },
      { label: "Other", value: "false_positive_other" },
      { label: "Unknown", value: "false_positive_unknown" },
      { label: "Weather", value: "false_positive_weather" }
    ]

    const escalationTagOptions = [
      { label: "Theft", value: "escalate_theft" },
      { label: "Contact police", value: "escalate_contact_police" },
      { label: "Contact manager", value: "escalate_contact_manager" },
      { label: "Fire", value: "escalate_fire" },
      { label: "Damage", value: "escalate_damage" },
      { label: "Open door/gate", value: "escalate_open_door_gate" },
      { label: "Check subject", value: "escalate_check_subject" },
      { label: "Facility issue", value: "escalate_facility_issue" },
    ]

    if (showTruePositiveFilters || showFalsePositiveFilters) {
      sharedFilters.push({
        label: "Tags",
        property: "tags",
        type: "multi_select",
        options: [
          ...(showTruePositiveFilters ? truePositiveTagOptions : []),
          ...(showFalsePositiveFilters ? falsePositiveTagOptions : []),
        ],
        operators: stringArrayOperators
      })
    }

    if (showEscalationFilters) {
      sharedFilters.push({
        label: "Escalations", property: "escalations", type: "multi_select", options: escalationTagOptions, operators: stringArrayOperators
      })
    }

    return sharedFilters
  }, [showEscalationFilters, showFalsePositiveFilters, showTruePositiveFilters, stringArrayOperators, durationOperators])

  const sharedFilterProviderProps = React.useMemo(() => ({
    noPadding: true,
    suggested: false,
    filterPerProperty: true,
    onInputFocus: onInputFocus,
    onInputBlur: onInputBlur,
    disableResetButton: true,
    addFilterDefaultValues: new Map([["duration", 1000]]),
    rootFontSize: rootFontSize
  }), [onInputBlur, onInputFocus, rootFontSize])

  React.useEffect(() => { onApplyFiltersRef.current = onApplyFilters }, [onApplyFilters])
  React.useEffect(() => { disableIndividualZonesRef.current = disableIndividualZones }, [disableIndividualZones])
  React.useEffect(() => { loadedRef.current = loaded }, [loaded])
  React.useEffect(() => { siteFilteringRef.current = siteFiltering }, [siteFiltering])
  React.useEffect(() => { eventsFilterRef.current = eventsFilter }, [eventsFilter]);
  React.useEffect(applyExistingFilters, [selectedSite]);
  React.useEffect(reRender, [scrollHeightRef]);
  React.useEffect(applyFilters, [
    motionFilters,
    peopleFilters,
    vechileFilters,
    safetyFilters,
    zonesFilters,
    selectedSites,
    streams,
    permissions,
    enableMotion,
    enablePeople,
    enableSafety,
    enableVehicle,
    enableZones
  ])
  React.useEffect(updateTagWraperHeight);

  function reRender(): void {
    if (scrollHeightRef.current) setLoaded(true);
  }

  function updateTagWraperHeight() {
    setTagWraperHeight(tagContainerRef.current?.clientHeight || 0)
  }

  function applyExistingFilters() {
    if (eventsFilterRef.current) {
      const params = new EventsFilter(eventsFilterRef.current).getCurrentParams();
      setEnableMotion(params.motion.enable);
      setEnablePeople(params.people.enable);
      setEnableSafety(params.safety.enable);
      setEnableVehicle(params.vehicles.enable);
      setEnableZones(params.zones.enable);

      setMotionsFilter(params.motion.filters);
      setPeopleFilter(params.people.filters);
      setSafetyFilter(params.safety.filters);
      setVehicleFilter(params.vehicles.filters);
      setZonesFilter(params.zones.filters);

      if (params.sites !== undefined && siteFilteringRef.current !== undefined) {
        if (selectedSite !== undefined) setSelectedSites([selectedSite])
        else {
          setSelectedSites(siteFilteringRef.current.sites.filter(el => params.sites?.sites.includes(el.siteId)));
        }
      }

    } else {
      if (siteFilteringRef.current) {
        setSelectedSites(siteFilteringRef.current.sites);
      }
    }
  }

  function applyExisting(filters?: EventsFilter) {
    if (filters) {
      const params = filters.getCurrentParams();

      setEnableMotion(params.motion.enable);
      setEnablePeople(params.people.enable);
      setEnableSafety(params.safety.enable);
      setEnableVehicle(params.vehicles.enable);
      setEnableZones(params.zones.enable);

      setMotionsFilter(params.motion.filters);
      setPeopleFilter(params.people.filters);
      setSafetyFilter(params.safety.filters);
      setVehicleFilter(params.vehicles.filters);
      setZonesFilter(params.zones.filters);

      if (params.sites && siteFiltering) {
        setSelectedSites(siteFiltering.sites.filter(el => params.sites?.sites.includes(el.siteId)));
      }
    } else {
      clearAllFilters();
    }
  }


  function clearAllFilters(): void {
    setOnGoingReset(true);

    setExpanded([]);
    motionFilterProviderRef.current?.restartFilters();
    peopleFilterProviderRef.current?.restartFilters();
    safetyFilterProviderRef.current?.restartFilters();
    vehicleFilterProviderRef.current?.restartFilters();
    zonesFilterProviderRef.current?.restartFilters();
    setMotionsFilter([]);
    setPeopleFilter([]);
    setSafetyFilter([]);
    setVehicleFilter([]);
    setZonesFilter([]);
    setEnableMotion(defaultState);
    setEnablePeople(defaultState);
    setEnableSafety(defaultState);
    setEnableVehicle(defaultState);
    setEnableZones(defaultState);

    //if (onApplyFilters) onApplyFilters(undefined);
    if (onRemoveAreaSearch) onRemoveAreaSearch();
    //if (onReturnClick) onReturnClick();
  }

  function applyFilters(): void {
    if (loadedRef.current === false) return;

    if (debounceRef.current) clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      setOnGoingReset(false);
      const filter: EventsFilter = new EventsFilter({
        motion: { enable: enableMotion, filters: motionFilters },
        people: { enable: enablePeople, filters: peopleFilters },
        safety: { enable: enableSafety, filters: safetyFilters },
        vehicles: { enable: enableVehicle, filters: vechileFilters },
        zones: { enable: enableZones, filters: zonesFilters },
        sites: siteFilteringRef.current ? {
          sites: selectedSites.map(el => el.siteId)
        } : undefined,
        streams: {
          streams: streams.map(el => el.kvsName)
        },
        permissions: {
          permissions: permissions
        }
      });

      if (onApplyFiltersRef.current) {
        onApplyFiltersRef.current(filter);
      }
    }, 1000)
  }

  function isResetDisabled(): boolean {
    if (onGoingReset === true) return true;

    if ((enableMotion !== defaultState) || (enablePeople !== defaultState) || (enableSafety !== defaultState) || (enableVehicle !== defaultState) || (enableZones !== defaultState)) return false;
    if (eventsFilter !== undefined) {
      const params = new EventsFilter(eventsFilter).getCurrentParams();
      if (params) {
        if (params?.motion?.filters?.length > 0) return false;
        if (params?.vehicles?.filters?.length > 0) return false;
        if (params?.people?.filters?.length > 0) return false;
        if (params?.safety?.filters?.length > 0) return false;
        if (params.zones?.filters?.length > 0) return false;
      }
    }

    return true;
  }

  const accordionProps = { variant: 'elevation' as 'elevation', elevation: 0, disableGutters: true, sx: { '&:before': { display: 'none' }, paddingRight: '1rem' } };

  function getAccordionSummary(title: string, activeFilters: number, enabled: boolean) {
    return <AccordionSummary expandIcon={<ExpandMore />} disabled={!enabled}>
      <Box style={{ justifyContent: "space-between", display: "flex", width: "100%" }}>
        <Box display="flex">
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Chip label={`${activeFilters}`} color={activeFilters > 0 ? "primary" : "default"} />
      </Box>
    </AccordionSummary>;
  }

  function handleExpandedChange(type: "motion" | "people" | "vehicle" | "safety" | "zones") {
    setExpanded(e => {
      const expanded = e.includes(type);
      if (expanded)
        return e.filter(t => t !== type)
      else {
        switch (type) {
          case 'motion':
            if (motionFilters.length === 0)
              motionFilterProviderRef.current?.addFilter();
            break;
          case 'people':
            if (peopleFilters.length === 0)
              peopleFilterProviderRef.current?.addFilter();
            break;
          case 'vehicle':
            if (vechileFilters.length === 0)
              vehicleFilterProviderRef.current?.addFilter();
            break;
          case 'safety':
            if (safetyFilters.length === 0)
              safetyFilterProviderRef.current?.addFilter();
            break;
          case 'zones':
            if (zonesFilters.length === 0)
              zonesFilterProviderRef.current?.addFilter();
            break;
        }
        return [...e, type];
      }
    })
  }

  function handleChipRemoved(type: string, property: string) {
    switch (type) {
      case "People":
        peopleFilterProviderRef.current?.removeFilter(property);
        break;
      case "Vehicle":
        vehicleFilterProviderRef.current?.removeFilter(property);
        break;
      case "Safety":
        safetyFilterProviderRef.current?.removeFilter(property);
        break;
      case "Zones":
        zonesFilterProviderRef.current?.removeFilter(property);
        break;
      case "Motion":
        motionFilterProviderRef.current?.removeFilter(property);
        break;
    }
  }

  return (
    <div id="events-filter-container" ref={containerRef} style={{ height: maxContainerHeight, overflowY: 'hidden' }}>
      <Box display="grid" gridTemplateRows="min-content min-content auto" rowGap="1rem" padding={noPadding ? undefined : '1rem'}>
        <div ref={titleRef}>
          <Box display="grid" gridTemplateRows="min-content auto">
            <Box
              display="grid"
              gridTemplateColumns={onReturnClick ? "min-content min-content auto" : 'min-content auto'}
              columnGap="0.5rem"
              alignItems="center"
            >
              {onReturnClick &&
                <IconButton size="small" onClick={onReturnClick}>
                  <ArrowBack />
                </IconButton>
              }
              <Typography style={{ marginLeft: 8 }} variant="h6" >Filters</Typography>
              <Box display="flex" justifyContent="flex-end">
                <TutorialButton
                  tutorialType="events_filter"
                />
                <Button style={{ width: '5rem', height: '2rem' }} disabled={isResetDisabled()} onClick={clearAllFilters} variant="outlined" data-cy="timeline-event-filter-reset-button">
                  <Typography variant="body2">{onGoingReset === true ? <CircularProgress size="1rem" style={{ marginTop: 2 }} /> : 'Reset'}</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
        <div ref={tagContainerRef}>
          <TagFilter
            eventsFilterParams={eventsFilter}
            onApplyFilters={(filter: EventsFilter | undefined) => {
              applyExisting(filter);
            }}
            areaSelection={areaSelection}
            onRemoveAreaSearch={onRemoveAreaSearch}
            removeAddButton={true}
            onFilteringClicked={() => { }}
            disableEventsTutorial={disableEventsTutorial}
            zones={zones}
            onChipRemove={handleChipRemoved}
          />
        </div>
        <ScrollingWraper ref={scrollHeightRef} id="scrolling-wrapper">
          {scrollHeightRef.current && tagContainerRef.current && titleRef.current && containerRef.current && loaded === true &&
            <Box
              display="grid"
              gridAutoRows="min-content"
              rowGap="1rem"
              paddingLeft="0.625rem"
              overflow="auto"
              height={containerRef.current.offsetHeight - (titleRef.current.offsetHeight + tagWraperHeight + 16 + 16)}
              width="100%"
              paddingBottom="1rem"
              style={{ overflowX: "hidden" }}
            >
              <Box display="grid" alignItems="center" rowGap="0.5rem" gridTemplateRows="min-content min-content">
                {siteFiltering &&
                  <Box display="grid">
                    <Box display="grid" alignItems="center" rowGap="0.5rem" gridTemplateRows="min-content min-content">
                      <Typography variant="h6" >Sites</Typography>
                    </Box>
                    <Box width="100%" paddingRight="1rem">
                      <SiteSelector
                        availableSites={siteFiltering.sites}
                        selectedSites={selectedSites}
                        onSelectionChange={(sites: Site[]) => {
                          setSelectedSites(sites);
                        }}
                        disabled={false}
                        onSiteListClose={onSiteListClose}
                        onSiteListOpen={onSiteListOpen}
                        singleSite={singleSite}
                      />
                    </Box>
                  </Box>
                }
                {timeRangeSelectorProps &&
                  <Box display="grid" paddingRight="1rem" alignItems="center" rowGap="0.5rem" gridTemplateRows={timeRangeSelectorProps ? 'min-content min-content min-content' : 'min-content min-content'}>
                    <Typography variant="h6" >Time</Typography>
                    <TimeRangeSelector {...timeRangeSelectorProps} />
                  </Box>
                }
              </Box>
              {onAreaSearchClick &&
                <Box id="events-filter-roi-container" paddingRight="1.5rem" display="grid" alignItems="center" rowGap="0.5rem" gridTemplateRows="min-content min-content">
                  <Typography variant="h6" >Location</Typography>
                  <Button color={areaSearchActive ? "primary" : undefined} onClick={onAreaSearchClick} startIcon={<Fullscreen fontSize="large" />} variant="outlined" fullWidth={true} data-cy="timeline-events-select-area-button">
                    <Typography>Select area</Typography>
                  </Button>
                </Box>
              }

              {features.events.motion.active && features.events.motion.read &&
                <Accordion {...accordionProps} data-cy={`filter-motion-type`} expanded={expanded.includes("motion")} onChange={() => handleExpandedChange("motion")} >
                  <Box display="grid" gridTemplateColumns="min-content auto" >
                    <Box alignContent="center">
                      <Checkbox
                        checked={enableMotion}
                        onClick={e => { e.stopPropagation(); }}
                        onChange={(e) => { !e.target.checked && setExpanded(e => e.filter(f => f !== "motion")); setEnableMotion(e.target.checked) }}
                        size="small"
                        style={{ marginLeft: "-0.5rem" }}
                      />
                    </Box>
                    {getAccordionSummary("Motion", motionFilters.length, enableMotion)}
                  </Box>
                  <AccordionDetails style={{ padding: 0 }}>
                    <FilterProvider
                      {...sharedFilterProviderProps}
                      activeFilters={motionFilters}
                      ref={motionFilterProviderRef}
                      properties={sharedFilterProps}
                      performFiltering={setMotionsFilter}
                    />
                  </AccordionDetails>
                </Accordion>}

              {features.events.zones.active && features.events.zones.read &&
                <Accordion {...accordionProps} data-cy={`filter-zones-type`} expanded={expanded.includes("zones")} onChange={() => handleExpandedChange("zones")}>
                  <Box display="grid" gridTemplateColumns="min-content auto" >
                    <Box alignContent="center">
                      <Checkbox
                        checked={enableZones}
                        onClick={e => { e.stopPropagation(); }}
                        onChange={(e) => { !e.target.checked && setExpanded(e => e.filter(f => f !== "zones")); setEnableZones(e.target.checked) }}
                        size="small"
                        style={{ marginLeft: "-0.5rem" }}
                      />
                    </Box>
                    {getAccordionSummary("Zones", zonesFilters.length, enableZones)}
                  </Box>
                  <AccordionDetails style={{ padding: 0 }}>
                    <FilterProvider
                      {...sharedFilterProviderProps}
                      addFilterDefaultValues={new Map([["personDetectionDuration", 1000], ["vehicleDetectionDuration", 1000], ["unknownDetectionDuration", 1000],])}
                      activeFilters={zonesFilters}
                      ref={zonesFilterProviderRef}
                      properties={[
                        { label: "Person detection duration", property: "personDetectionDuration", type: "duration", operators: durationOperators },
                        { label: "Vehicle detection duration", property: "vehicleDetectionDuration", type: "duration", operators: durationOperators },
                        { label: "Motion detection duration", property: "unknownDetectionDuration", type: "duration", operators: durationOperators },
                        ...sharedFilterProps.filter(f => f.property !== "duration"),
                        ...(disableIndividualZones ? [] : [{
                          label: "Zone name",
                          property: "zoneId",
                          type: "multi_select" as "multi_select",
                          options: zones.filter(el => el.state === "ACTIVE").map(z => ({ label: z.name, value: z.id })),
                          operators: stringArrayOperators
                        }]),
                      ]}
                      performFiltering={setZonesFilter}
                    />
                  </AccordionDetails>
                </Accordion>}

              {features.events.safetyInfraction.active && features.events.safetyInfraction.read &&
                <Accordion {...accordionProps} data-cy={`filter-safety-type`} expanded={expanded.includes("safety")} onChange={() => handleExpandedChange("safety")}>
                  <Box display="grid" gridTemplateColumns="min-content auto" >
                    <Box alignContent="center">
                      <Checkbox
                        checked={enableSafety}
                        onClick={e => { e.stopPropagation(); }}
                        onChange={(e) => { !e.target.checked && setExpanded(e => e.filter(f => f !== "safety")); setEnableSafety(e.target.checked) }}
                        size="small"
                        style={{ marginLeft: "-0.5rem" }}
                      />
                    </Box>
                    {getAccordionSummary("Safety infractions", safetyFilters.length, enableSafety)}
                  </Box>
                  <AccordionDetails style={{ padding: 0 }} >
                    <FilterProvider
                      {...sharedFilterProviderProps}
                      activeFilters={safetyFilters}
                      ref={safetyFilterProviderRef}
                      properties={sharedFilterProps}
                      performFiltering={setSafetyFilter}
                    />
                  </AccordionDetails>
                </Accordion>}

              {features.events.person.active && features.events.person.read &&
                <Accordion {...accordionProps} data-cy={`filter-people-type`} expanded={expanded.includes("people")} onChange={() => handleExpandedChange("people")}>
                  <Box display="grid" gridTemplateColumns="min-content auto" >
                    <Box alignContent="center">
                      <Checkbox
                        checked={enablePeople}
                        onClick={e => { e.stopPropagation(); }}
                        onChange={(e) => { !e.target.checked && setExpanded(e => e.filter(f => f !== "people")); setEnablePeople(e.target.checked) }}
                        size="small"
                        style={{ marginLeft: "-0.5rem" }}
                      />
                    </Box>
                    {getAccordionSummary("People", peopleFilters.length, enablePeople)}
                  </Box>
                  <AccordionDetails style={{ padding: 0 }}>
                    <FilterProvider
                      {...sharedFilterProviderProps}
                      activeFilters={peopleFilters}
                      ref={peopleFilterProviderRef}
                      properties={[
                        ...sharedFilterProps,
                      ]}
                      performFiltering={setPeopleFilter}
                    />
                  </AccordionDetails>
                </Accordion>}

              {features.events.vehicle.active && features.events.vehicle.read &&
                <Accordion {...accordionProps} data-cy={`filter-vehicle-type`} expanded={expanded.includes("vehicle")} onChange={() => handleExpandedChange("vehicle")}>
                  <Box display="grid" gridTemplateColumns="min-content auto" >
                    <Box alignContent="center">
                      <Checkbox
                        checked={enableVehicle}
                        onClick={e => { e.stopPropagation(); }}
                        onChange={(e) => { !e.target.checked && setExpanded(e => e.filter(f => f !== "vehicle")); setEnableVehicle(e.target.checked) }}
                        size="small"
                        style={{ marginLeft: "-0.5rem" }}
                      />
                    </Box>
                    {getAccordionSummary("Vehicle", vechileFilters.length, enableVehicle)}
                  </Box>
                  <AccordionDetails style={{ padding: 0 }}>
                    <FilterProvider
                      {...sharedFilterProviderProps}
                      activeFilters={vechileFilters}
                      ref={vehicleFilterProviderRef}
                      properties={[
                        ...sharedFilterProps,
                        { label: "Model", property: "metadata.model", type: "multi_select", options: filterOptions["model"], operators: stringArrayOperators },
                        { label: "Make", property: "metadata.make", type: "multi_select", options: filterOptions["make"], operators: stringArrayOperators },
                        { label: "Color", property: "metadata.color", type: "multi_select", options: filterOptions["color"], operators: stringArrayOperators },
                        { label: "State", property: "metadata.region", type: "multi_select", options: filterOptions["region"], operators: stringArrayOperators },
                        { label: "License plate", property: "metadata.plate", type: "string", operators: stringOperators },
                      ]}
                      performFiltering={setVehicleFilter}
                    />
                  </AccordionDetails>
                </Accordion>}
            </Box>
          }
        </ScrollingWraper>
      </Box >
    </div >
  );
}

export default FilterPanel;