import React, { useCallback } from 'react';
import { Field, Operator } from '@classes/Filter/Field';
import { FieldFactory } from '@classes/Filter/FieldFactory';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import { capitalize, isEmpty } from 'lodash';
import MultiSelectInput from '../Input/MultiSelectInput';
import TargetDateInput from '../Input/TargetDateInput';
import { useMobileQuery } from '@hooks/useMobileQuery';
import FilterList from './FilterList';
import FilterListActions from './FilterListActions';
import DurationInput from '../Input/DurationInput';
import { Filter } from '@classes/Filter/Filter';
import { last, findIndex, first } from "lodash";

export interface IFilterProviderHandles {
  restartFilters: () => void;
  currentActiveFilters: number;
  removeFilter: (property: string) => void;
  addFilter: () => void;
}

export type FilterProviderPropsBase = {
  properties: Array<FilterProperties>;
  activeFilters?: FilterToApply[];
  predefinedFilters?: FilterToApply[];
  filterPerProperty?: boolean;
  noPadding?: boolean;
  suggested?: boolean;
  onInputFocus?: () => void;
  onInputBlur?: () => void;
  oneRowLayout?: boolean;
  disableResetButton?: boolean;
  addFilterDefaultValues?: Map<string, string | number | boolean | string[]>;
  onCurrentActiveFiltersChange?: (value: number) => void;
  rootFontSize?: number;
}

export type FilterProviderPropsUncontrolled = FilterProviderPropsBase & {
  data: Array<any>;
  onFilteredDataChange?: (data: Array<any>) => void;
}


export type FilterProviderPropsControlled = FilterProviderPropsBase & {
  performFiltering: (filters: FilterToApply[]) => void;
}

const FilterProvider: React.ForwardRefRenderFunction<IFilterProviderHandles, FilterProviderPropsControlled | FilterProviderPropsUncontrolled> = (props, ref) => {
  const {
    properties: initialProperties,
    predefinedFilters,
    noPadding,
    suggested = true,
    activeFilters,
    filterPerProperty = true,
    onInputBlur,
    onInputFocus,
    oneRowLayout,
    disableResetButton,
    addFilterDefaultValues,
    onCurrentActiveFiltersChange,
    rootFontSize
  } = props;

  const data = (props as FilterProviderPropsUncontrolled).data;
  const onFilteredDataChange = (props as FilterProviderPropsUncontrolled).onFilteredDataChange;

  const parentPerformFiltering = (props as FilterProviderPropsControlled).performFiltering;

  React.useImperativeHandle(ref, () => ({
    restartFilters,
    currentActiveFilters,
    removeFilter,
    addFilter
  }))

  const isMobile = useMobileQuery();

  const [properties, setProperties] = React.useState(initialProperties);
  const [currentActiveFilters, setCurrentActiveFilters] = React.useState<number>(0);

  const getInitFilters = useCallback(() => {
    const start = properties?.length ? properties[0] : undefined;
    if (start && suggested) {
      return [{ text: '', filter: newFilterBasedOnType(start.type, start.property, start.operators) }];
    }
    return []
  }, [properties, suggested])

  const [currentFilters, setCurrentFilters] = React.useState<Array<FilterToApply>>(activeFilters || getInitFilters())

  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const onFilteredDataChangeRef = React.useRef(onFilteredDataChange);
  const currentFiltersRef = React.useRef(currentFilters);
  const dataRef = React.useRef(data);
  const propertiesRef = React.useRef(properties);
  const onCurrentActiveFiltersChangeRef = React.useRef(onCurrentActiveFiltersChange)

  React.useEffect(() => { propertiesRef.current = properties }, [properties])
  React.useEffect(() => { dataRef.current = data }, [data])
  React.useEffect(() => { currentFiltersRef.current = currentFilters }, [currentFilters])
  React.useEffect(() => { onFilteredDataChangeRef.current = onFilteredDataChange }, [onFilteredDataChange])
  React.useEffect(() => { onCurrentActiveFiltersChangeRef.current = onCurrentActiveFiltersChange }, [onCurrentActiveFiltersChange])
  React.useEffect(() => { if (onCurrentActiveFiltersChangeRef.current) onCurrentActiveFiltersChangeRef.current(currentActiveFilters) }, [currentActiveFilters])

  const performFiltering = useCallback(() => {
    const currentActiveFiltersTemp = currentFiltersRef.current.filter(el => el.filter.getCurrentOperator() === "is_empty" || !isEmpty(el.text) || (el.text && +el.text > 0))
    const allFilters = currentActiveFiltersTemp.length ? [...(predefinedFilters || []), ...currentActiveFiltersTemp] : [];
    if (parentPerformFiltering) {
      parentPerformFiltering(allFilters);
    } else if (dataRef.current) {
      if (onFilteredDataChangeRef.current) {
        const filter = new Filter(allFilters);
        onFilteredDataChangeRef.current(filter.filter([...dataRef.current]));
      }
    }

    setCurrentActiveFilters(currentActiveFiltersTemp.length)
  }, [parentPerformFiltering, predefinedFilters]);

  React.useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      performFiltering()
    }, 300);
  }, [currentFilters, performFiltering]);

  React.useEffect(() => {
    if (filterPerProperty)
      setProperties([...initialProperties].map(p => ({ ...p, preventSelect: currentFilters.some(f => f.filter.getName() === p.property) })));
  }, [initialProperties, currentFilters, filterPerProperty])

  React.useEffect(() => {
    performFiltering()
  }, [data, performFiltering]);

  function restartFilters() {
    setCurrentFilters(getInitFilters())
  }

  function removeFilter(property: string) {
    setCurrentFilters(current => [...current.filter(f => f.filter.getName() !== property)])
  }

  function getTextFieldType(filter: FilterToApply): "text" | "number" | "datetime-local" {
    if (filter.filter.getType() === 'string') return 'text';
    if (filter.filter.getType() === 'number') return 'number';
    if (filter.filter.getType() === 'date') return 'datetime-local';

    return 'text';
  }

  function newFilterBasedOnType(type: "string" | "number" | "date" | "boolean" | "select" | "array" | "multi_select" | "duration", name: string, operators: Operator[]): Field<any, any> {
    if (type === "number") return FieldFactory.createNumberField(name, operators)
    if (type === "date") return FieldFactory.createDateField(name, operators)
    if (type === "boolean") return FieldFactory.createBooleanField(name, operators);
    if (type === "select") return FieldFactory.createSelectField(name, operators);
    if (type === "array") return FieldFactory.createArrayField(name, operators);
    if (type === "multi_select") return FieldFactory.createMultiSelectField(name, operators);
    if (type === "duration") return FieldFactory.createDurationField(name, operators);
    return FieldFactory.createStringField(name, operators)
  }

  const addFilter = () => {
    const lastAdded = last(currentFilters);
    const index = findIndex(properties, { property: lastAdded?.filter.getName() });
    let element: FilterProperties | undefined = undefined;

    if (index !== -1 && properties[index + 1]) {
      element = properties[index + 1];
    } else {
      element = first(properties);
    }

    if (element) {
      const newArray = [...currentFilters];
      newArray.push({ text: addFilterDefaultValues?.get(element.property) || '', filter: newFilterBasedOnType(element.type, element.property, element.operators) });
      setCurrentFilters(newArray);
    }
  }

  const createInputField = React.useCallback((filter: FilterToApply, index: number): JSX.Element => {
    if (filter.filter.getType() === "multi_select") {
      const options = properties.find(el => el.property === filter.filter.getName())?.options || [];
      return (
        <MultiSelectInput
          value={filter.text as string[]}
          options={options}
          disabled={options.length === 0}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          searchable={true}
          onChange={(values: string[]) => {
            const newArray = [...currentFilters];
            newArray[index] = { text: values, filter: newArray[index].filter };
            setCurrentFilters(newArray);
          }}
          rootFontSize={rootFontSize}
        />
      )
    }

    if (filter.filter.getType() === "select") {
      return (
        <Select
          label="Select"
          variant="outlined"
          style={{ width: '11rem' }}
          value={filter.text}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          IconComponent={KeyboardArrowDown}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            style: { zIndex: 9999 }
          }}
          onChange={(e) => {
            const newArray = [...currentFilters];
            newArray[index] = { text: e.target.value.toString(), filter: newArray[index].filter };
            setCurrentFilters(newArray);
          }}
          data-cy="filter-select-input"
        >
          {properties.find(el => el.property === filter.filter.getName())?.options?.map(el => (
            <MenuItem key={(typeof el === "string") ? el : el.value} value={(typeof el === "string") ? el : el.value}><Typography variant="button">{capitalize(((typeof el === "string") ? el : el.label).toLowerCase()).replaceAll('_', ' ')}</Typography></MenuItem>
          ))}
        </Select>
      )
    }

    if (filter.filter.getType() === "boolean") {
      return (
        <Select
          label="Filter value"
          variant="outlined"
          IconComponent={KeyboardArrowDown}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          style={{ width: '11rem' }}
          fullWidth
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            style: { zIndex: 9999 }
          }}
          value={filter.text}
          onChange={(e) => {
            const newArray = [...currentFilters];
            newArray[index] = { text: e.target.value.toString(), filter: newArray[index].filter };
            setCurrentFilters(newArray);
          }}
          disabled={filter.filter.getCurrentOperator() === "is_empty" || filter.filter.getCurrentOperator() === "is_not_empty"}
          data-cy="filter-select-boolean-input"
        >
          <MenuItem value="" >None</MenuItem>
          <MenuItem value="true" >True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </Select>
      )
    }

    if (filter.filter.getType() === 'date') {
      return (
        <Box
          width="11rem"
          data-cy="filter-date-input"
        >
          <TargetDateInput
            value={new Date(filter.text as string).getTime()}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            fullWidth
            outlined
            disablePast={false}
            disableFuture={false}
            timezone={"UTC (GMT+00:00)"}
            hintEnd="00:00"
            onChange={(timestamp: number) => {
              const date = new Date(timestamp);
              const newArray = [...currentFilters];
              newArray[index] = { text: date.toISOString(), filter: newArray[index].filter };
              setCurrentFilters(newArray)
            }}
          />
        </Box>
      )
    }

    if (filter.filter.getType() === 'duration') {
      return (
        <Box
          width="11rem"
          data-cy="filter-duration-input"
        >
          <DurationInput
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            value={Number(filter.text)}
            onChange={(duration) => {
              const newArray = [...currentFilters];
              newArray[index] = { text: duration, filter: newArray[index].filter };
              setCurrentFilters(newArray)
            }}
          />
        </Box>
      )
    }

    return (
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        placeholder="Filter value"
        type={getTextFieldType(filter)}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        inputProps={{
          maxLength: 100
        }}
        onClick={(e) => {
          // @ts-ignore
          e.target.focus();
          e.stopPropagation();
        }}
        style={{ marginTop: 0, width: '11rem' }}
        value={filter.text}
        disabled={filter.filter.getCurrentOperator() === "is_empty" || filter.filter.getCurrentOperator() === "is_not_empty"}
        onChange={(e) => {
          const newArray = [...currentFilters];
          newArray[index] = { text: e.target.value, filter: newArray[index].filter };
          setCurrentFilters(newArray)
        }}
        data-cy="filter-text-field-input"
      />
    )
  }, [currentFilters, onInputBlur, onInputFocus, properties, rootFontSize])

  return <Box id="filter-provider">
    <FilterList
      currentFilters={currentFilters}
      isMobile={isMobile}
      currentActiveFilters={currentActiveFilters}
      restartFilters={restartFilters}
      setCurrentFilters={setCurrentFilters}
      properties={properties}
      newFilterBasedOnType={newFilterBasedOnType}
      createInputField={createInputField}
      addFilterDefaultValues={addFilterDefaultValues}
      noPadding={noPadding}
      oneRowLayout={oneRowLayout}
    />
    <FilterListActions
      currentFilters={currentFilters}
      properties={properties}
      newFilterBasedOnType={newFilterBasedOnType}
      setCurrentFilters={setCurrentFilters}
      restartFilters={restartFilters}
      noPadding={noPadding}
      filterPerProperty={filterPerProperty}
      disableResetButton={disableResetButton}
      addFilterDefaultValues={addFilterDefaultValues}
      addFilter={addFilter}
    />
  </Box>
}

export default React.forwardRef(FilterProvider);