import { ActionTypes, Components, ISessionState, PageView } from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: ISessionState = {
  isAuthenticated: undefined,
  isSuperAdministrator: undefined,
  user: undefined,
  credentials: undefined,
  userData: undefined,
  activePage: PageView.None,
  activeComponent: Components.None,
  permissions: {}
};

export const sessionReducer = Utils.Redux.createReducer<ISessionState, Types, Actions>(
  initialState,
  {
    [ActionTypes.AUTHENTICATE_USER]: (state, action) => ({
      ...state,
      user: action.user,
      credentials: action.credentials,
      isAuthenticated: Boolean(action.user),
      isLoading: false
    }),

    [ActionTypes.CLEAR_USER]: state => ({
      ...state,
      user: undefined,
      isAuthenticated: undefined,
      userData: undefined
    }),

    [ActionTypes.SET_USER_DATA]: (state, action) => {
      return ({
        ...state,
        userData: action.userData
      })
    },

    [ActionTypes.SET_ACTIVE_PAGE]: (state, action) => ({
      ...state,
      activePage: action.pageView
    }),

    [ActionTypes.SET_ACTIVE_COMPONENT]: (state, action) => ({
      ...state,
      activeComponent: action.component
    }),

    [ActionTypes.SET_SUPER_ADMINISTRATOR]: (state, action) => ({
      ...state,
      isSuperAdministrator: action.value
    }),

    [ActionTypes.SET_PERMISSIONS]: (state, action) => ({
      ...state,
      permissions: action.value
    }),

    [ActionTypes.SET_SESSION_USER]: (state, action) => ({
      ...state,
      user: action.user
    })
  }
);
