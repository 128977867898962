import { isEmpty, isEqual } from "lodash";
import { Field } from "./Field";

export class SelectField<T> extends Field<T, string> {
  public override filter(data: Array<T>, target?: string): Array<T> {
    return data.filter((el: any) => {
      const value = this._path?.reduce((a, b) => a ? a[b] : undefined, el)
      if (this._currentOperator === "is_empty") {
        if (!value) return true;
        if (value && value === '') return true;
        return false;
      }

      if (this._currentOperator === "is_not_empty") {
        return value && !isEmpty(value);
      }

      if (value) {
        const targetProperty = value as string;

        if (this._currentOperator === "is") return isEmpty(target) || (target && isEqual(targetProperty, target));
        if (this._currentOperator === "is_not") return isEmpty(target) || (target && !isEqual(targetProperty, target));
      }

      return false;
    })
  }
}