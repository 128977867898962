export function createUsersFilters(isSuperAdministrator: boolean): FilterProperties[] {
  if (isSuperAdministrator === true) {
    return [
      { label: 'Given name', property: 'givenName', type: 'string' },
      { label: 'Family name', property: 'familyName', type: 'string' },
      { label: 'E-mail', property: 'email', type: 'string' },
      { label: 'Status', property: 'status', type: 'select', options: ["Active", "Invited", "Deactivated"] },
      { label: 'Company ID', property: 'companyId', type: 'string' },
      { label: 'Created by (ID)', property: 'createdBy', type: 'string' },
      { label: 'Terms accepted', property: 'termsAccepted', type: 'boolean' },
      { label: 'Updated by (ID)', property: 'updatedBy', type: 'string' },
      { label: 'User ID', property: 'userId', type: 'string' },
      { label: 'Created at', property: 'createdAt', type: 'date' },
      { label: 'Updated at', property: 'updatedAt', type: 'date' },
    ];
  }

  return [
    { label: 'Given name', property: 'givenName', type: 'string' },
    { label: 'Family name', property: 'familyName', type: 'string' },
    { label: 'E-mail', property: 'email', type: 'string' },
  ];
}
