import { SvgIcon, SxProps, Theme } from '@mui/material';
import React from 'react';

const StrobeIcon: React.FC<{ id?: string, sx?: SxProps<Theme> }> = (props) => {
  const { id, sx } = props;

  return (
    <SvgIcon id={id} sx={sx}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="100%" viewBox="0 0 1176 941" enable-background="new 0 0 1176 941" xmlSpace="preserve">

        <path fill="#010101" opacity="1.000000" stroke="none"
          d="
M1177.000000,403.536804 
	C1174.152954,410.251038 1172.152344,417.092621 1168.286743,422.633087 
	C1159.617920,435.058167 1146.980713,440.791992 1131.883545,440.816772 
	C1092.081177,440.882080 1052.278320,440.921936 1012.476135,440.813416 
	C995.723450,440.767731 979.429688,427.998566 973.447632,410.699005 
	C967.656616,393.951935 973.232605,374.203979 986.657654,363.180695 
	C994.527222,356.719055 1003.437012,352.979065 1013.580261,352.946991 
	C1053.715088,352.820099 1093.851807,352.702515 1133.985352,352.960846 
	C1155.690063,353.100525 1173.395386,369.237885 1176.656982,390.817413 
	C1177.000000,395.024536 1177.000000,399.049103 1177.000000,403.536804 
z"/>
        <path fill="#010101" opacity="1.000000" stroke="none"
          d="
M1.000000,388.466858 
	C1.802072,386.092407 2.678810,384.212372 3.394576,382.272919 
	C9.989244,364.403473 25.387201,353.050293 44.492809,352.937805 
	C83.964455,352.705414 123.439575,352.661407 162.910782,352.919403 
	C184.286163,353.059113 200.746536,366.690186 205.610992,387.284485 
	C210.217514,406.786743 201.104904,426.794525 182.296783,435.929474 
	C176.093674,438.942291 168.609146,440.562042 161.685867,440.640442 
	C123.050446,441.077820 84.406441,441.013672 45.767422,440.780945 
	C23.024717,440.643921 6.143445,426.411896 1.322621,404.195709 
	C1.000000,398.977905 1.000000,393.955811 1.000000,388.466858 
z"/>
        <path fill="#010101" opacity="1.000000" stroke="none"
          d="
M620.999878,881.853882 
	C503.020813,881.851562 385.541718,881.868835 268.062653,881.835693 
	C229.473999,881.824829 196.871643,860.486450 183.627258,825.452087 
	C179.994217,815.841980 177.873871,805.120789 177.663300,794.856018 
	C177.003998,762.715088 176.711823,730.515686 177.922653,698.400940 
	C179.283585,662.305237 206.390076,629.757385 241.050507,620.841492 
	C249.496872,618.668823 258.418091,617.328613 267.122467,617.320740 
	C482.750549,617.127014 698.378845,617.136169 914.007080,617.183289 
	C958.716064,617.192993 998.379700,654.508057 999.850037,699.315002 
	C1000.974731,733.590027 1001.222412,767.979065 999.788330,802.230713 
	C998.096069,842.648682 961.377808,878.279236 920.914490,881.129578 
	C914.441284,881.585571 907.938782,881.820984 901.449646,881.824036 
	C808.133057,881.867615 714.816528,881.853882 620.999878,881.853882 
M265.660370,706.825134 
	C265.660370,735.573975 265.660370,764.322815 265.660370,793.226257 
	C481.502625,793.226257 696.692749,793.226257 912.005432,793.226257 
	C912.005432,763.890198 912.005432,734.851685 912.005432,705.497009 
	C910.375061,705.497009 909.069275,705.497009 907.763489,705.497009 
	C695.158081,705.497986 482.552673,705.498169 269.947266,705.519470 
	C268.652344,705.519592 267.357422,705.814209 265.660370,706.825134 
z"/>
        <path fill="#010101" opacity="1.000000" stroke="none"
          d="
M779.530701,294.574463 
	C777.439758,278.364624 769.921082,268.555206 757.243896,265.544861 
	C754.359131,264.859833 751.317200,264.551880 748.347412,264.550812 
	C643.688477,264.513428 539.028564,264.773987 434.371460,264.341431 
	C413.573486,264.255463 400.356354,273.884369 397.907379,296.789948 
	C393.645691,336.649872 388.140533,376.376312 383.209503,416.165283 
	C378.400848,454.967133 373.668854,493.778534 368.878387,532.582642 
	C367.839752,540.995728 366.695435,549.395752 365.550812,558.177612 
	C336.098145,558.177612 306.700195,558.177612 276.732269,558.177612 
	C277.958374,548.065186 279.145599,538.036743 280.394745,528.015991 
	C283.934723,499.618225 287.483704,471.221527 291.060059,442.828339 
	C294.594757,414.765869 298.181763,386.709961 301.718842,358.647797 
	C305.152283,331.408356 308.479675,304.155396 311.981964,276.924866 
	C317.782074,231.828354 352.090637,192.345169 396.184875,181.084518 
	C407.659088,178.154251 419.771759,176.419220 431.598785,176.378998 
	C537.256714,176.019608 642.916260,176.149918 748.575378,176.163361 
	C808.572266,176.171005 857.833984,218.697433 866.057922,278.159698 
	C871.163940,315.078186 875.431824,352.112427 880.099976,389.091644 
	C884.288757,422.274109 888.524719,455.450653 892.712585,488.633240 
	C895.442078,510.260132 898.129333,531.892395 900.811096,553.525208 
	C900.992859,554.991516 900.968750,556.483276 901.054138,558.247925 
	C871.560852,558.247925 842.306580,558.247925 812.378174,558.247925 
	C801.468506,470.529480 790.553345,382.766541 779.530701,294.574463 
z"/>
        <path fill="#010101" opacity="1.000000" stroke="none"
          d="
M963.594177,123.573967 
	C992.365356,104.376900 1020.807068,85.334282 1049.347168,66.440308 
	C1070.226440,52.617851 1096.964233,57.960190 1110.861328,78.538513 
	C1124.440796,98.646515 1119.104492,125.493515 1098.388672,139.515167 
	C1068.943604,159.445358 1039.389771,179.217560 1009.749512,198.856491 
	C990.023865,211.926147 963.513855,206.262909 949.348938,186.292862 
	C936.151611,167.686905 940.407227,141.177734 958.875000,127.016479 
	C960.324280,125.905159 961.828308,124.865257 963.594177,123.573967 
z"/>
        <path fill="#010101" opacity="1.000000" stroke="none"
          d="
M64.676849,82.766586 
	C78.300972,58.324619 106.661789,51.614441 129.402832,66.902992 
	C157.268692,85.636902 185.224213,104.239670 213.277512,122.691566 
	C227.298584,131.913849 235.722900,144.256027 236.020462,161.321304 
	C236.403549,183.289581 220.386581,201.640350 198.689850,205.074417 
	C186.835861,206.950607 176.276596,204.294601 166.492554,197.749390 
	C137.897141,178.619995 109.319153,159.464539 80.735222,140.317993 
	C60.894951,127.028252 54.655548,105.042686 64.676849,82.766586 
z"/>
        <path fill="#020202" opacity="1.000000" stroke="none"
          d="
M453.083923,526.119507 
	C455.731995,505.050323 458.357697,484.429596 460.952057,463.804932 
	C464.169556,438.226379 467.356476,412.643982 470.568420,387.064697 
	C471.107025,382.775543 471.283478,378.385376 472.352753,374.228485 
	C475.981995,360.119781 490.029480,351.214447 505.378021,352.960449 
	C518.851990,354.493225 530.481140,367.076538 529.876404,381.312775 
	C529.306091,394.737793 527.168457,408.105133 525.525513,421.473877 
	C522.075562,449.546661 518.489746,477.602753 514.975647,505.667664 
	C512.887451,522.344116 510.834015,539.024841 508.743713,555.701050 
	C508.643616,556.499695 508.337708,557.272522 508.070465,558.271118 
	C488.493591,558.271118 469.071411,558.271118 449.269135,558.271118 
	C450.542328,547.587891 451.794983,537.077087 453.083923,526.119507 
z"/>
        <path fill="#FEFEFE" opacity="1.000000" stroke="none"
          d="
M265.861420,706.398193 
	C267.357422,705.814209 268.652344,705.519592 269.947266,705.519470 
	C482.552673,705.498169 695.158081,705.497986 907.763489,705.497009 
	C909.069275,705.497009 910.375061,705.497009 912.005432,705.497009 
	C912.005432,734.851685 912.005432,763.890198 912.005432,793.226257 
	C696.692749,793.226257 481.502625,793.226257 265.660370,793.226257 
	C265.660370,764.322815 265.660370,735.573975 265.861420,706.398193 
z"/>
      </svg>
    </SvgIcon>
  )
}

export default StrobeIcon;