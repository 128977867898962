import { ZenObservable } from '../../node_modules/zen-observable-ts';

abstract class PubSub {
  protected onMessage: Function;
  protected onError: Function | undefined;
  protected onComplete: Function | undefined;
  protected subscription: ZenObservable.Subscription | null = null;
  protected interval: NodeJS.Timeout | null = null;

  constructor(onMessage: Function, onError?: Function, onComplete?: Function) {
    this.onMessage = onMessage;
    this.onError = onError;
    this.onComplete = onComplete;
  }

  abstract connect(): void;

  public disconnect(): void {
    if (this.interval) clearInterval(this.interval);

    this.subscription?.unsubscribe();
  }

  public isClosed(): boolean {
    if (this.subscription) {
      return this.subscription.closed;
    }

    return true;
  }

  protected checkSubscriptionHealth(): void {
    if (this.isClosed() === false) {
      return;
    }

    this.connect();
  }
}

export default PubSub;
