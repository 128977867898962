import { Box } from '@mui/material';
import { SitesTabs } from '@store/views/overviewView/types';
import React from 'react';
import SiteList from './SiteList';

type SitesContentProps = {
  activeTab: SitesTabs;
}

const SitesContent: React.FC<SitesContentProps> = (props) => {
  const { activeTab } = props;

  return (
    <Box width="100%" height="100%">
      {activeTab === SitesTabs.Sites && <SiteList />}
    </Box>
  )
}

export default SitesContent;
