import { findIndex } from 'lodash';

export function getListMovement(oldData: VideoStreamEvent[], newData: VideoStreamEvent[], focusId: string): number {

  const oldIndex = findIndexOfId(focusId, oldData);
  const newIndex = findIndexOfId(focusId, newData);

  return newIndex - oldIndex;
}

function findIndexOfId(id: string, data: VideoStreamEvent[]): number {
  let oldPosition = -1;

  oldPosition = findIndex(data, { id: id });
  
  if (oldPosition === -1) {
    for (let i = 0; i < data.length; i += 1) {
      const children = data[i].children
      if (children) {
        for (let j = 0; j < children.length; j += 1) {
          const index = findIndex(children, { id: id });
          if (index !== -1) {
            oldPosition = findIndex(data, { id: data[i].id });
            break;
          }
        }
      }

      if (oldPosition !== -1) break;
    }
  }

  return oldPosition;
}