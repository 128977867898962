import React from 'react';
import { PageView } from "@store/session/types";
import styled from 'styled-components';
import { Box, Divider, Drawer, IconButton, List, Paper, SvgIcon } from '@mui/material';
import AppLogo from './AppLogo';
import AppBarButton from './AppBarButton';
import {
  AccountCircleOutlined,
  BookmarkBorderOutlined,
  Help,
  LocalShippingOutlined,
  MapOutlined,
  Menu,
  SatelliteAlt,
  VideocamOutlined
} from '@mui/icons-material';
import UserAvatar from './UserAvatar';
import OpenCloseDrawer from './OpenCloseDrawer';
import SiteSelection from './SiteSelection';
import Utils from '@utils/index';
import { AssetsPermissions } from "@store/session/types";
import { styled as styledMui } from '@mui/material/styles';
import { DrawerProps } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setAppBarAnimationEndedAt } from '@store/views/appView/actions';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';
import CompanySelection from './CompanySelection';

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: min-content min-content min-content auto min-content;
  height: 100%;

  .MuiListItem-gutters {
    padding-left: 1.5rem;
  }
`

const CustomDrawer = styledMui(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ theme, open }) => ({
  width: "4.5rem",
  flexShrink: 0,
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  height: '100%',
  position: 'unset',
  overflow: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  ...(open && {
    width: "15.5rem",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

interface IAppMenuProps {
  open: boolean,
  onExpand: () => void,
  onViewsClick: () => void,
  onTimelapsesClick: () => void,
  onAnalyticsClick: () => void,
  onActionsClick: () => void,
  onExportsClick: () => void,
  onHelpCenterClick: (event: any) => void,
  onUserClick: (event: any) => void,
  onSiteClick: (event: any) => void,
  onCompanyClick: (event: any) => void,
  onMapClick: () => void,
  onManagementClick: () => void,
  onHealthClick: () => void,
  onVehiclesClick: () => void,

  onCalendarClick: () => void,
  onEventsClick: () => void,
  userData?: User,
  permissions: AssetsPermissions,
  selectedSite?: Site;
  selectedCompany?: Company;
  isSuperAdministrator?: boolean;
  isAdministrator?: boolean;
  activePage: PageView;

  onSatelliteClick?: () => void;
}

// Handle displaying content and applying css
const AppMenu: React.FC<IAppMenuProps> = (props) => {
  const {
    open,
    onExpand,
    userData,
    onViewsClick,
    onActionsClick,
    onAnalyticsClick,
    onHelpCenterClick,
    onUserClick,
    selectedSite,
    onSiteClick,
    selectedCompany,
    activePage,
    onExportsClick,
    permissions,
    isSuperAdministrator,
    onCalendarClick,
    onCompanyClick,
    onSatelliteClick,
    onEventsClick,
    onMapClick,
    onManagementClick,
    onHealthClick,
    isAdministrator,
    onVehiclesClick
  } = props;

  const dispatch = useDispatch();

  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();

  const userHasExportsPermissions = React.useMemo(() => {
    return Utils.Permissions.userHasPermission(permissions, "TIMELAPSES") ||
      Utils.Permissions.userHasPermission(permissions, "TIMELAPSES_VIEW") ||
      Utils.Permissions.userHasPermission(permissions, "VIDEO_DOWNLOADS") ||
      Utils.Permissions.userHasPermission(permissions, "VIDEO_DOWNLOADS_VIEW") ||
      Utils.Permissions.userHasPermission(permissions, "ANNOTATED_IMAGES") ||
      Utils.Permissions.userHasPermission(permissions, "ANNOTATED_IMAGES_VIEW") ||
      Utils.Permissions.userHasPermission(permissions, "ALL") ||
      isSuperAdministrator ||
      isAdministrator
  }, [isAdministrator, isSuperAdministrator, permissions])

  const userHasAnalyticsPermissions = React.useMemo(() => {
    return Utils.Permissions.userHasPermission(permissions, "HEATMAPS") ||
      Utils.Permissions.userHasPermission(permissions, "ALL") || isAdministrator || isSuperAdministrator;
  }, [isAdministrator, isSuperAdministrator, permissions])

  const userHasActionsPermissions = React.useMemo(() => {
    return Utils.Permissions.userHasPermission(permissions, "ACTIONS") ||
      Utils.Permissions.userHasPermission(permissions, "ACTIONS_VIEW") ||
      isSuperAdministrator ||
      isAdministrator
  }, [isAdministrator, isSuperAdministrator, permissions])

  const commonContent = (
    <ContentContainer>
      <List>
        <AppLogo
          open={open}
        />
      </List>
      <List style={{ padding: 0 }}>
        <CompanySelection
          selectedCompany={selectedCompany}
          open={open}
          onCompanyClick={onCompanyClick}
          isSuperAdministrator={isSuperAdministrator}
        />
      </List>
      <List style={{ maxWidth: "15.5rem", padding: 0 }}>
        <SiteSelection
          selectedCompany={selectedCompany}
          selectedSite={selectedSite}
          open={open}
          onSiteClick={onSiteClick}
        />
        <Divider />
      </List>
      <List style={{ padding: 0 }}>
        <AppBarButton
          selected={Boolean([PageView.Events].includes(activePage))}
          open={open}
          onClick={onEventsClick}
          icon={
            <SvgIcon id="events-icon" style={{ fontSize: '1.4rem', marginLeft: '0.10rem' }}>
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 640.1 416">
                <path d="M64,48h384c8.8,0,16,7.2,16,16v22.1l-22.6,18.5C458.6,99,477,96,496,96c5.4,0,10.7,0.2,16,0.7V64c0-35.3-28.7-64-64-64H64
                C28.7,0,0,28.7,0,64v256c0,35.3,28.7,64,64,64h296.2c-11.8-14.3-21.4-30.5-28.2-48H64c-8.8,0-16-7.2-16-16V148.2l142,116.6
                c37.7,31,91.8,31.5,130.1,1.5c0.9-29.2,8.9-56.5,22.4-80.4l-50.9,41.8c-20.7,17-50.4,17-71.1,0L48,86.1V64C48,55.2,55.2,48,64,48z"
                />
                <path d="M496.1,128c79.5,0,144,64.5,144,144s-64.5,144-144,144s-144-64.5-144-144S416.5,128,496.1,128z M496.1,368
                c13.3,0,24-10.7,24-24s-10.7-24-24-24s-24,10.7-24,24S482.8,368,496.1,368z M496.1,176c-8.8,0-16,7.2-16,16v80c0,8.8,7.2,16,16,16
                s16-7.2,16-16v-80C512.1,183.2,504.9,176,496.1,176z"/>
              </svg>
            </SvgIcon>
          }
          text="Events"
          disabled={selectedCompany === undefined}
          dataCy="app-menu-events-button"
        />
        <AppBarButton
          selected={Boolean(activePage === PageView.Streams || activePage === PageView.Player)}
          open={open}
          onClick={onViewsClick}
          icon={<VideocamOutlined style={{ fontSize: '1.7rem', marginLeft: '-0.10rem' }} id="views-icon" />}
          text="Views"
          dataCy="app-menu-views-button"
          disabled={selectedSite === undefined}
        />
        <AppBarButton
          selected={Boolean(activePage === PageView.Exports)}
          open={open}
          onClick={onExportsClick}
          icon={<BookmarkBorderOutlined id="saved-icon" style={{ fontSize: '1.6rem', marginRight: '-0.10rem' }} />}
          dataCy="app-menu-exports-button"
          text="Saved"
          disabled={selectedCompany === undefined || selectedSite === undefined || userHasExportsPermissions === false}
          />
        <AppBarButton
          selected={Boolean(activePage === PageView.Vehicles)}
          open={open}
          onClick={onVehiclesClick}
          icon={<LocalShippingOutlined style={{ fontSize: '1.5rem', marginLeft: '0.10rem'  }} />}
          dataCy="app-menu-vehicles-button"
          text="Vehicles"
          disabled={selectedCompany === undefined || selectedSite === undefined || isSuperAdministrator === false}
        />
        <AppBarButton
          selected={Boolean([PageView.Attendance, PageView.Workers, PageView.Subcontractors, PageView.FlaggedWorkers, PageView.WorkersCertifications].includes(activePage))}
          open={open}
          onClick={onCalendarClick}
          icon={<AccountCircleOutlined id="workers-icon" style={{ fontSize: '1.5rem' }} />}
          dataCy="app-menu-workers-button"
          text="Workers"
          disabled={isSuperAdministrator === false || selectedCompany === undefined}
        />
        <AppBarButton
          selected={Boolean(activePage === PageView.Analytics)}
          open={open}
          onClick={onAnalyticsClick}
          icon={
            <SvgIcon id="analytics-icon" style={{ fontSize: '1.4rem', marginRight: '0.1rem' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M493.1 192H352V50.9C423.5 64 480 120.5 493.1 192zM352 240H527.4c9 0 16.6-7 16.6-16C544 100.3 443.7 0 320 0c-9 0-16 7.6-16 16.6V192v48h48zM222.1 321.9L348.4 448.2C325 458.4 299.2 464 272 464C166 464 80 378 80 272c0-83.6 53.4-154.7 128-181.1V288c0 12.7 5.1 24.9 14.1 33.9zM239 34.3C122.1 50.3 32 150.7 32 272c0 132.5 107.5 240 240 240c51.8 0 99.8-16.4 139-44.3c7.7-5.5 8.2-16.5 1.5-23.1L256 288V49.6c0-9.2-7.8-16.6-17-15.4zM558.4 288H320L478.7 446.7c5.8 5.8 15.2 6.3 21.2 .7c39.3-36.7 66.2-86.5 73.9-142.3c1.3-9.2-6.1-17-15.4-17z" /></svg>
            </SvgIcon>
          }
          dataCy="app-menu-analytics-button"
          text="Analytics"
          disabled={userHasAnalyticsPermissions === false || selectedSite === undefined || selectedCompany === undefined}
        />
        <AppBarButton
          selected={Boolean(activePage === PageView.Map)}
          open={open}
          onClick={onMapClick}
          icon={<MapOutlined id="map-icon" />}
          dataCy="app-menu-map-button"
          text="Map"
          disabled={selectedCompany === undefined}
        />
        <AppBarButton
          selected={Boolean(activePage === PageView.Actions)}
          open={open}
          onClick={onActionsClick}
          icon={
            <SvgIcon style={{ fontSize: '1.3rem', marginLeft: '0.1rem' }} id="actions-icon" >
              <svg width="24" height="24" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" fill="black">
                <path d="M6.40793 6.62598H0.194824V0.625977H6.40793V6.62598ZM1.7481 5.12598H4.85465V2.12598H1.7481V5.12598Z" />
                <path d="M21.9407 21.626H15.7276V15.626H21.9407V21.626ZM17.2808 20.126H20.3874V17.126H17.2808V20.126Z" />
                <path d="M14.1743 19.376H4.85465C3.61879 19.376 2.43354 18.9019 1.55966 18.058C0.685769 17.214 0.194824 16.0695 0.194824 14.876C0.194824 13.6825 0.685769 12.5379 1.55966 11.694C2.43354 10.8501 3.61879 10.376 4.85465 10.376V11.876C4.03074 11.876 3.24058 12.192 2.65799 12.7547C2.0754 13.3173 1.7481 14.0803 1.7481 14.876C1.7481 15.6716 2.0754 16.4347 2.65799 16.9973C3.24058 17.5599 4.03074 17.876 4.85465 17.876H14.1743V19.376Z" />
                <path d="M17.2809 11.876V10.376C18.1048 10.376 18.8949 10.0599 19.4775 9.4973C20.0601 8.93469 20.3874 8.17163 20.3874 7.37598C20.3874 6.58033 20.0601 5.81727 19.4775 5.25466C18.8949 4.69205 18.1048 4.37598 17.2809 4.37598H7.9612V2.87598H17.2809C17.8928 2.87598 18.4987 2.99237 19.0641 3.21852C19.6295 3.44466 20.1431 3.77613 20.5759 4.194C21.0086 4.61186 21.3518 5.10794 21.586 5.6539C21.8202 6.19987 21.9407 6.78503 21.9407 7.37598C21.9407 7.96693 21.8202 8.55209 21.586 9.09805C21.3518 9.64402 21.0086 10.1401 20.5759 10.558C20.1431 10.9758 19.6295 11.3073 19.0641 11.5334C18.4987 11.7596 17.8928 11.876 17.2809 11.876Z" />
                <path d="M13.3977 7.37598H8.73784L6.40792 10.376L11.0678 14.876L15.7276 10.376L13.3977 7.37598Z" />
              </svg>
            </SvgIcon>
          }
          dataCy="app-menu-actions-button"
          text="Actions"
          disabled={selectedCompany === undefined || userHasActionsPermissions === false || selectedSite === undefined}
        />
        {onSatelliteClick &&
          <AppBarButton
            selected={Boolean(activePage === PageView.Satellite)}
            open={open}
            onClick={onSatelliteClick}
            icon={<SatelliteAlt id="saved-icon" />}
            dataCy="app-menu-exports-button"
            text="Satellite"
            disabled={selectedCompany === undefined}
          />}
        {isSuperAdministrator &&
          <AppBarButton
            selected={Boolean(activePage === PageView.Health)}
            open={open}
            onClick={onHealthClick}
            icon={
              <SvgIcon id="health-icon" style={{ fontSize: '1.4rem', marginLeft: '0.1rem' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M225.8 468.2c8.2 7.6 19 11.9 30.2 11.9s22-4.2 30.2-11.9l2.5-2.3L445.7 320H375.2L256 430.7 136.8 320H66.3L223.3 465.9l2.5 2.3zM0 192.8c0 34.9 12.1 68.5 33.9 95.2H56h46.3 20.3c19.4 0 36.9-11.7 44.3-29.5l17.6-42.2 56.8 126.3c2.5 5.6 8.1 9.3 14.3 9.4s11.9-3.3 14.6-8.8L320 243.8l8.8 17.7c8.1 16.3 24.8 26.5 42.9 26.5h37.9H456h22.1c21.8-26.7 33.9-60.3 33.9-95.2v-3.3c0-70.4-50-130.8-119.2-144C353.4 37.9 313.1 47 281 69.6l0 0c-4 2.8-7.8 5.8-11.5 9c-4.7 4.1-9.3 8.5-13.5 13.3c-7.5-8.4-15.9-15.9-25-22.3C198.9 47 158.6 37.9 119.2 45.4C50 58.6 0 119.1 0 189.5v3.3zM442.2 256H371.8c-6.1 0-11.6-3.4-14.3-8.8l-23.2-46.3c-2.7-5.4-8.2-8.8-14.3-8.8s-11.6 3.4-14.3 8.8l-48.9 97.9L198.6 169.4c-2.6-5.8-8.4-9.5-14.8-9.4s-12.1 4-14.5 9.8l-31.8 76.3c-2.5 6-8.3 9.8-14.8 9.8H69.8C55.8 238.1 48 215.9 48 192.8v-3.3c0-47.3 33.6-88 80.1-96.9c34-6.5 69 5.4 92 31.2l0 0 .1 .1 17.8 20c.3 .4 .7 .7 1 1.1c4.5 4.5 10.6 7 16.9 7s12.4-2.5 16.9-7c.4-.3 .7-.7 1-1.1l17.8-20 .1-.1 .1-.1c23.1-25.9 58-37.7 92-31.2c46.5 8.9 80.1 49.5 80.1 96.9v3.3c0 23.1-7.8 45.3-21.8 63.2z" /></svg>
              </SvgIcon>
            }
            dataCy="app-menu-health-button"
            text="Health"
            disabled={selectedCompany === undefined}
          />
        }
        <AppBarButton
          selected={Boolean(activePage === PageView.Management)}
          open={open}
          onClick={onManagementClick}
          icon={<SvgIcon id="management-icon" style={{ fontSize: '1.20rem', marginLeft: '0.15rem' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M208 176c0-70.7 57.3-128 128-128s128 57.3 128 128s-57.3 128-128 128c-10.4 0-20.5-1.2-30.1-3.6c-8.1-2-16.7 .4-22.6 6.4L254.1 336H200c-13.3 0-24 10.7-24 24v40H136c-13.3 0-24 10.7-24 24v40H48V385.9L205.2 228.7c5.9-5.9 8.3-14.5 6.4-22.6c-2.3-9.6-3.6-19.7-3.6-30.1zM336 0C238.8 0 160 78.8 160 176c0 9.5 .7 18.8 2.2 27.9L7 359c-4.5 4.5-7 10.6-7 17V488c0 13.3 10.7 24 24 24H136c13.3 0 24-10.7 24-24V448h40c13.3 0 24-10.7 24-24V384h40c6.4 0 12.5-2.5 17-7l27.2-27.2c9.1 1.4 18.4 2.2 27.9 2.2c97.2 0 176-78.8 176-176S433.2 0 336 0zm32 176a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" /></svg>
          </SvgIcon>}
          dataCy="app-menu-access-button"
          text="Management"
          disabled={selectedCompany === undefined}
        />
        <Divider />
        <AppBarButton
          selected={false}
          open={open}
          onClick={onHelpCenterClick}
          dataCy="app-menu-help-button"
          icon={<Help />}
          text="Help"
        />
      </List>
      <List style={{ maxWidth: "15rem" }}>
        <UserAvatar
          onUserClick={onUserClick}
          userData={userData}
          open={open}
        />
        <OpenCloseDrawer
          open={open}
          onClick={onExpand}
        />
      </List>
    </ContentContainer>
  )

  if (isMobile || isSmallScreen) {
    return (
      <>
        <Box>
          <Paper style={{ borderRadius: 0, marginBottom: 1 }} elevation={2}>
            <Box display="flex" flexDirection="row" alignItems="space-between">
              <AppLogo
                logoOnly
                open={false}
              />
              <IconButton onClick={() => onExpand()}>
                <Menu />
              </IconButton>
            </Box>
          </Paper>
        </Box>
        <Drawer open={open} anchor='left' onClose={onExpand}>
          {commonContent}
        </Drawer>
      </>
    )
  }

  return (
    <CustomDrawer
      variant="permanent"
      open={open}
      PaperProps={{
        style: {
          position: "unset",
          overflow: "hidden"
        }
      }}
      onAnimationEnd={() => {
        dispatch(setAppBarAnimationEndedAt(Date.now()));
      }}
    >
      {commonContent}
    </CustomDrawer>
  )
}

export default AppMenu;
