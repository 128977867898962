import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import API from '@API/index';
import { PageView } from '@store/session/types';
import { first, flattenDeep, isArray, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { setSites } from '@store/sites/actions';
import { setStreamList } from '@store/streams/actions';
import { getPermissions } from '@hooks/useUpdateStreams';
import { clearUser, setPermissions } from '@store/session/actions';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';

const EVENT_TYPE_MAP = new Map([
  ["M", "Motion"],
  ["SI", "Safety infraction"],
  ["GT", "Geofence triggered"],
  ["PD", "Person detected"],
  ["VD", "Vehicle detected"],
  // legacy
  ["SD", "Social distancing"],
  ["FD", "Fire detected"],
  ["LPD", "License plate detected"]
])

const PubSubHandler: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const userData = useSelector((store: IStoreState) => store.session.userData);
  const streams = useSelector((store: IStoreState) => store.streams.streams);

  const selectedStream: VideoStream | undefined = useSelector((store: IStoreState) => store.selectedStream.stream);
  const activePage = useSelector((store: IStoreState) => store.session.activePage);
  const isStreamLive: boolean = useSelector((store: IStoreState) => store.selectedStream.isLive);
  const subFunction = useSelector((store: IStoreState) => store.selectedStream.subscription);
  const ptzSubFunction = useSelector((store: IStoreState) => store.selectedStream.ptzSubscription);

  const subFunctionRef = React.useRef<((data: VideoStreamEvent[]) => void) | undefined>(subFunction);
  const ptzSubFunctionRef = React.useRef<((data: PTZEvent) => void) | undefined>(ptzSubFunction);
  const activePageRef = React.useRef(activePage);

  const permissionsTopicSubscription = React.useRef<{ unsubscribe: () => void; } | undefined>(undefined);
  const logoutTopicSubscription = React.useRef<{ unsubscribe: () => void; } | undefined>(undefined);
  const userDataRef = React.useRef<User | undefined>(userData);
  const dispatchRef = React.useRef(dispatch);
  const streamsRef = React.useRef(streams);

  const handleUserDeactivation = React.useCallback(() => {
    const topicName = `${process.env.REACT_APP_STACK}/logout/${userDataRef.current?.userId}`

    const onMessage = () => {
      Auth.signOut()
        .then(() => {
          dispatchRef.current(clearUser());
          window.userId = undefined;
        })
        .catch((error) => {

        });
    }

    logoutTopicSubscription.current = API.PubSub.subscribeToTopic(topicName, onMessage);

    return () => {
      logoutTopicSubscription.current?.unsubscribe();
    }
  }, [])

  const handleReload = React.useCallback(() => {
    const topicName = `${process.env.REACT_APP_STACK}/reload/${userDataRef.current?.userId}`

    const onMessage = () => {
      enqueueSnackbar("Your request has been approved. Reloading in 10 seconds.", { variant: "success" })
      setTimeout(() => window.location.reload(), 8000);
    }

    logoutTopicSubscription.current = API.PubSub.subscribeToTopic(topicName, onMessage);

    return () => {
      logoutTopicSubscription.current?.unsubscribe();
    }
  }, [enqueueSnackbar])

  const handleSelectedCameraPTZEvents = React.useCallback(() => {
    const shouldSubscribe: boolean = selectedStream !== undefined && activePageRef.current === PageView.Player;

    if (shouldSubscribe && ptzSubscription.current === undefined && selectedStream) {
      const topicName = `${process.env.REACT_APP_STACK}/events/control/${selectedStream.companyId}/${selectedStream.siteId}/${selectedStream.kvsName}`;

      const onMessage = (message: any) => {
        console.log('PTZ Message: ', message)
        const data = isArray(message) ? first(message) : message;

        if (ptzSubFunctionRef.current) ptzSubFunctionRef.current(data);
      }

      ptzSubscription.current = API.PubSub.subscribeToTopic(topicName, onMessage);
    } else {
      ptzSubscription.current?.unsubscribe();
      ptzSubscription.current = undefined;
    }
  }, [selectedStream])

  React.useEffect(() => { streamsRef.current = streams }, [streams]);
  React.useEffect(() => { dispatchRef.current = dispatch }, [dispatch])
  React.useEffect(() => { userDataRef.current = userData }, [userData]);
  React.useEffect(() => { activePageRef.current = activePage }, [activePage])
  React.useEffect(handleSubscription, [selectedStream, isStreamLive]);
  React.useEffect(handleSelectedCameraPTZEvents, [handleSelectedCameraPTZEvents]);
  React.useEffect(() => {
    subFunctionRef.current = subFunction;
  }, [subFunction])
  React.useEffect(() => {
    ptzSubFunctionRef.current = ptzSubFunction;
  }, [ptzSubFunction])

  React.useEffect(() => {
    const topicName = `${process.env.REACT_APP_STACK}/dashboard/updates/permissions`;

    const onMessage = (message: { target: string }) => {
      if (userDataRef.current && message && message.target && isEqual(message.target, userDataRef.current?.userId)) {
        console.log('Permissions for logged user changed...');
        const { companyId } = userDataRef.current;

        API.Site.listSites(companyId)
          .then((sites) => {
            dispatchRef.current(setSites(companyId, sites));
            return Promise.all(sites.map(site => API.Streams.listStreams(site.siteId)))
          })
          .then((response: VideoStream[][]) => {
            const streams = flattenDeep(response);
            dispatchRef.current(setStreamList(streams));
            return getPermissions(streams, userDataRef.current?.roles, userDataRef.current?.userId);
          })
          .then((permissions) => {
            dispatchRef.current(setPermissions(permissions));
            console.log('Done');
          })
          .catch((e) => {
            console.log(e);
            console.log('Error while updating sites/streams.')
          })
      } else if (userDataRef.current && userDataRef.current.roles && (Object.keys(userDataRef.current?.roles) as Array<string>).includes(message.target)) {
        getPermissions(streamsRef.current, userDataRef.current?.roles, userDataRef.current?.userId)
          .then((permissions) => {
            dispatchRef.current(setPermissions(permissions))
          })
          .catch((e) => {
            console.log(e);
            console.log('Error while updating sites/streams.')
          })
      }
    }

    permissionsTopicSubscription.current = API.PubSub.subscribeToTopic(topicName, onMessage);
    return () => {
      permissionsTopicSubscription.current?.unsubscribe();
    }
  }, [])

  React.useEffect(handleUserDeactivation, [handleUserDeactivation])
  React.useEffect(handleReload, [handleReload])

  const subscription = React.useRef<{ unsubscribe: () => void; } | undefined>(undefined);
  const ptzSubscription = React.useRef<{ unsubscribe: () => void; } | undefined>(undefined);

  function handleSubscription(): void {
    const shouldSubscribe: boolean = selectedStream !== undefined && activePageRef.current === PageView.Player && isStreamLive === true;

    if (shouldSubscribe && subscription.current === undefined) {
      const topicName = `dashboard/events/${selectedStream?.companyId}/${selectedStream?.siteId}/${selectedStream?.kvsName}/#`;

      const onMessage = (message: any) => {
        if ((message.e && message.e !== 0) || (message.ce && message.ce !== 0)) {
          if (subFunctionRef.current) subFunctionRef.current([{
            id: `${message.src}-${message.s}`,
            endDate: +message.e,
            startDate: +`${message.s}`.substring(0, `${message.s}`.length - 2),
            duration: +message.e - (+`${message.s}`.substring(0, `${message.s}`.length - 2)),
            sourceCamera: message.src,
            eventType: EVENT_TYPE_MAP.get(message.t) || message.t,
            labels: Array.isArray(message.l) ? message.l : typeof message.l === "string" ? [message.l] : undefined,
            zoneId: message.gid,
            new: message.e ? true : false,
            siteId: selectedStream?.siteId || "",
            companyId: selectedStream?.companyId || "",
            currentEnd: +message.ce,
            ongoing: message.ce && message.ce !== 0 ? true : false,
            arrived: Date.now()
          }]);
        }
      };

      subscription.current = API.PubSub.subscribeToTopic(topicName, onMessage);
    } else {
      subscription.current?.unsubscribe();
      subscription.current = undefined;
    }
  }


  return null;
}

export default PubSubHandler;
