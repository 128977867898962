import { getListMovement } from "./getListMovement";

export interface IEventInbox {
  getListMovement(oldData: VideoStreamEvent[], newData: VideoStreamEvent[], focusId: string): number
}

const EventInbox: IEventInbox = {
  getListMovement
}

export default EventInbox
