import moment from "moment";

export function findLeastRequiredFilter(targetTimestamp: number, timezone: string): 'Last 7 days' | 'Last 14 days' | 'Last 30 days' | 'Last 90 days' | 'Custom' {
  const daysDifference = (moment.tz(timezone).set('hours', 23).set('minutes', 59).set('seconds', 59).set('milliseconds', 999).valueOf() - targetTimestamp) / 86400000;
  
  if (daysDifference < 7) return 'Last 7 days';
  if (daysDifference < 14) return 'Last 14 days';
  if (daysDifference < 30) return 'Last 30 days';
  if (daysDifference < 90) return 'Last 90 days';
  
  return 'Custom';
}