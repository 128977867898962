import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

interface IProps {
  title?: string;
  flex?: boolean;
  center?: boolean;
  column?: boolean;
  padding?: number;
  noMargin?: boolean;
  children?: React.ReactNode;
}

const Container = styled(
  ({ flex, center, column, theme, noMargin, ...rest }) => (
    <div {...rest} />
  )
) <IProps>`
  padding: ${props => props.noMargin === true ? '0 !important' : '1rem !important'};
  display: ${props => (props.flex ? 'flex' : 'block')};
  align-items: ${props => (props.flex && props.center ? 'center' : undefined)};
  justify-content: ${props =>
    props.flex && props.center ? 'center' : undefined};
  flex-direction: ${props =>
    props.flex && props.column ? 'column' : undefined};
  height: 100%;
`;

const BaseViewContainer: React.FC<IProps> = props => {
  const { title, children, ...extraProps } = props;

  return (
    <Container {...extraProps}>
      {title &&
        <Helmet>
          <title>{`Forsight${title ? ` | ${title} ` : ''}`}</title>
        </Helmet>}

      {children}
    </Container>
  );
};

export default BaseViewContainer;
