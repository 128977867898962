import { CircularProgress, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { Avatar } from '@components/common/Avatar';

interface IUserAvatarProps {
  userData?: User;
  onUserClick: (event?: any) => void;
  open: boolean;
}

const UserAvatar: React.FC<IUserAvatarProps> = (props) => {
  const {
    onUserClick,
    open,
    userData
  } = props;

  const avatarRef = React.useRef(null);

  const userFullName = `${userData?.givenName} ${userData?.familyName}`;

  return (
    <ListItem
      style={{ paddingLeft: "1.3125rem" }}
      button
      onClick={(e) => { onUserClick({ currentTarget: avatarRef.current }) }}
      data-cy="user-avatar-list-item"
    >
      <Tooltip arrow placement="right" title={open ? "" : "Your profile and company settings"} >
        <ListItemIcon>
          <Avatar
            id="user-icon"
            user={userData}
            width="2rem"
            height="2rem"
            badge={true}
          />
          <div ref={avatarRef} />
        </ListItemIcon>
      </Tooltip>
      {userData ?
        <Tooltip title={userFullName}>
          <ListItemText
            primaryTypographyProps={{
              variant: "body2",
              style: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }
            }}
          >{userFullName}</ListItemText>
        </Tooltip> :
        <CircularProgress style={{ paddingLeft: "1.3125rem" }} />}
    </ListItem>
  )
}

export default UserAvatar;
