import ZonesTab from '@components/timeline/CommandPanel/ZonesTab/ZonesTab';
import { Box } from '@mui/material';
import React from 'react';
import API from '@API/index';
import { findIndex, first, flattenDeep, isString } from 'lodash';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { useDispatch } from 'react-redux';
import { pushZones, setCurrentZones, setFilteredZones, setHoveredZoneId, setSelectedZone } from '@store/views/overviewView/actions';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';
import SaveBar from '@components/common/SaveBar';
import { ZoneEntity } from '@classes/entity/ZoneEntity';

const EventInboxZonesPanel: React.FC<{}> = () => {
  const { enqueueSnackbar } = useSnackbar();

  const eventSelection = useSelector((store: IStoreState) => store.overviewView.selectedEvents);
  const currentZones = useSelector((store: IStoreState) => store.overviewView.currentZones);
  const zoneDatabase = useSelector((store: IStoreState) => store.overviewView.zoneDatabase);
  const selectedZone = useSelector((store: IStoreState) => store.overviewView.selectedZone);
  const sites = useSelector((store: IStoreState) => store.sites.sites);
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const permissions = useSelector((store: IStoreState) => store.session.permissions);

  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [timezone, setTimezone] = React.useState<string>("UTC (GMT+00:00)");
  const [siteId, setSiteId] = React.useState<string | undefined>(undefined);

  const zoneDatabaseRef = React.useRef<{ [key: string]: Zone[] }>(zoneDatabase);
  const currentZonesRef = React.useRef<Zone[]>(currentZones);

  const streamFeatures = React.useMemo(() => {
    const kvsNames = new Set();
    currentZones.forEach((zone) => {
      if (isString(zone.kvsName))
        kvsNames.add(zone.kvsName)
    })

    eventSelection.forEach((event) => {
      if (isString(event.sourceCamera)) {
        kvsNames.add(event.sourceCamera);
      }
    })

    const map = new Map<string, StreamFeatures>();
    Array.from(kvsNames).forEach((kvsName) => {
      const stream = streams.find(el => el.kvsName === kvsName);
      if (stream && stream.features) {
        let features = {
          stream: { view: false },
          events: {
            motion: { active: false, read: false },
            zones: { active: false, read: false },
            safetyInfraction: { active: false, read: false },
            person: { active: false, read: false },
            vehicle: { active: false, read: false },
            socialDistance: { active: false, read: false },
            fire: { active: false, read: false },
            vehicleLicensePlate: { active: false, read: false }
          },
          zones: { active: false, read: false, write: false },
          exports: { active: false, read: false, write: false },
          imageAnnotations: { active: false, read: false, write: false },
          timelapse: { active: false, read: false, write: false },
          ptz: { active: false, read: false, write: false },
          ppeDetection: false,
          socialDistancing: false,
          fireDetection: false,
          licensePlateDetection: false
        };
        const zoneEnabledForStream = stream.features.zones;
        const userHasPermissionForZones = Utils.Permissions.userHasPermission(permissions, "GEOFENCES", stream.siteId, stream.kvsName);
        features.zones.write = zoneEnabledForStream && userHasPermissionForZones;
        map.set(stream.kvsName, features);
      }
    })

    return map;
  }, [currentZones, eventSelection, permissions, streams])

  const loadStreamZones = React.useCallback(async (kvsName: string) => {
    try {
      const zones = await API.Geofences.getZones(kvsName);
      return {
        kvsName,
        zones
      }
    } catch (e) {
      return { kvsName, zones: [] }
    }
  }, [])

  const getZoneKvsName = React.useCallback((zone: Zone, zones: { [key: string]: Zone[] }) => {
    let kvsName = '';

    Object.entries(zones).forEach(([key, value]) => {
      const index = findIndex(value, zone)
      if (index >= 0) {
        kvsName = key;
      }
    })

    return kvsName;
  }, [])

  const updateZone = React.useCallback((zone: Zone, zones: { [key: string]: Zone[] }, kvsName: string) => {
    const currentZones = zones[kvsName];
    if (currentZones) {
      const newZones = [...currentZones];
      const index = findIndex(newZones, { id: zone.id });
      if (index >= 0) {
        newZones[index] = zone;
      } else {
        newZones.push(zone);
      }

      dispatch(pushZones(kvsName, newZones));

      const currentZoneIndex = findIndex(currentZonesRef.current, { id: zone.id });
      const newCurrentZones = [...currentZonesRef.current];
      if (currentZoneIndex >= 0) {
        newCurrentZones[currentZoneIndex] = zone;
      } else {
        newCurrentZones.push(zone)
      }

      dispatch(setCurrentZones(newCurrentZones));
    }
  }, [dispatch])

  const changeZoneState = React.useCallback(async (zone: Zone, action: "deactivate" | "activate" | "archive", callback?: () => void) => {
    try {
      const functions = new Map([
        ["deactivate", API.Geofences.deactivateZone],
        ["activate", API.Geofences.activateZone],
        ["archive", API.Geofences.deleteZone],
      ])

      const algorithm = functions.get(action);
      const kvsName = getZoneKvsName(zone, zoneDatabaseRef.current);
      if (algorithm && kvsName) {
        await algorithm(kvsName, zone.id);
        if (action === "deactivate") {
          updateZone({ ...zone, state: "INACTIVE" }, zoneDatabaseRef.current, kvsName);
        } else if (action === "activate") {
          updateZone({ ...zone, state: "ACTIVE" }, zoneDatabaseRef.current, kvsName);
        } else if (action === "archive") {
          updateZone({ ...zone, state: "ARCHIVED" }, zoneDatabaseRef.current, kvsName);
        }
      }

      enqueueSnackbar("Zone deactivated!", { variant: "success" });
      if (callback) callback();
    } catch (error) {
      enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: "error" });
      if (callback) callback();
    }
  }, [enqueueSnackbar, getZoneKvsName, updateZone])

  const isZoneDirty = React.useMemo(() => {
    return ZoneEntity.isZoneDirty(currentZones, selectedZone)
  }, [currentZones, selectedZone])

  const loadZones = React.useCallback(async () => {
    const streamsNames: Set<string> = new Set<string>();
    eventSelection.forEach(el => {
      streamsNames.add(el.sourceCamera)
      el.children?.forEach((child) => {
        streamsNames.add(child.sourceCamera);
      })
    });

    const zones: Zone[] = [];

    Object.entries(zoneDatabaseRef.current).forEach(([key, value]) => {
      if (Array.from(streamsNames).includes(key)) {
        zones.push(...value);
      }
    })

    if (zones.length > 0) {
      dispatch(setCurrentZones(zones))
    } else {
      setLoading(true)
    }

    try {
      const result = await Promise.all(Array.from(streamsNames).map(el => loadStreamZones(el)));
      result.forEach(el => dispatch(pushZones(el.kvsName, el.zones)));
      const flat: Zone[] = flattenDeep(result.map(el => el.zones));
      dispatch(setCurrentZones(flat));
    } catch (error) {
      console.log(error)
      dispatch(setCurrentZones([]));
    } finally {
      setLoading(false);
    }
  }, [dispatch, eventSelection, loadStreamZones])

  const handleSaveZone = React.useCallback(async (callback?: () => void) => {
    if (selectedZone) {
      try {
        setLoading(true);

        if (ZoneEntity.isCreatingNewZone(selectedZone)) {
          await API.Geofences.createZone(selectedZone.kvsName || "", selectedZone);
        } else {
          await API.Geofences.updateZone(selectedZone?.kvsName || "", selectedZone);
        }

        await loadZones();

        dispatch(setSelectedZone(undefined))
        enqueueSnackbar("Zone saved!", { variant: "success" });
        if (callback) callback();
      } catch (error) {
        enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' });
        if (callback) callback();
      } finally {
        setLoading(false);
      }
    }
  }, [dispatch, enqueueSnackbar, loadZones, selectedZone])

  const handleZoneMouseEnter = React.useCallback((zoneId: string) => {
    dispatch(setHoveredZoneId([zoneId]))
  }, [dispatch])

  const handleZoneMouseLeave = React.useCallback(() => {
    dispatch(setHoveredZoneId(undefined))
  }, [dispatch])

  React.useEffect(() => {
    dispatch(setSelectedZone(undefined))
  }, [dispatch, eventSelection])

  React.useEffect(() => {
    zoneDatabaseRef.current = zoneDatabase;
  }, [zoneDatabase])

  React.useEffect(() => {
    currentZonesRef.current = currentZones
  }, [currentZones])

  React.useEffect(() => {
    const firstElement = first(eventSelection);
    if (firstElement) {
      const companySites = sites.get(firstElement.companyId);
      const site = companySites?.find(el => el.siteId === firstElement.siteId);
      setTimezone(site?.timezone || "");
      setSiteId(site?.siteId);
    }

    setTimezone("UTC (GMT+00:00)")

  }, [eventSelection, sites])

  React.useEffect(() => { loadZones() }, [loadZones])

  return (
    <>
      <Box
        width="100%"
        height="100%"
        borderLeft="1px solid rgba(0, 0, 0, 0.12)"
        display="grid"
        gridTemplateRows="auto"
      >
        <ZonesTab
          zones={currentZones}
          isLoadingZones={loading}
          onFilteredZonesChange={(zones: Zone[]) => dispatch(setFilteredZones(zones))}
          onSelectedZoneChange={(zone?: Zone) => {
            dispatch(setHoveredZoneId(undefined));
            dispatch(setSelectedZone(zone))
          }}
          disableCreateNewZone={eventSelection.length !== 1}
          onActivateZone={(zone: Zone, callback?: () => void) => { changeZoneState(zone, "activate", callback) }}
          onArchiveZone={(zone: Zone, callback?: () => void) => { changeZoneState(zone, "archive", callback) }}
          onDeactivateZone={(zone: Zone, callback?: () => void) => { changeZoneState(zone, "deactivate", callback) }}
          focusedZone={selectedZone}
          timezone={timezone}
          features={streamFeatures}
          onZoneMouseEnter={handleZoneMouseEnter}
          onZoneMouseLeave={handleZoneMouseLeave}
          siteId={siteId}
        />
      </Box>
      <SaveBar
        open={isZoneDirty}
        onDiscardChanges={() => dispatch(setSelectedZone(undefined))}
        onSaveChanges={handleSaveZone}
      />
    </>
  )
}

export default EventInboxZonesPanel;
