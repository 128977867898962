import React from 'react';
import { Box, Button, Popover, PopoverProps, TextField, Typography } from '@mui/material';
import Utils from '@utils/index';
import * as moment from 'moment';
import styled from 'styled-components';
import { DateRangePickerComponent } from '@components/common/Input/DateRangePicker';
import { isEqual } from 'lodash';
import { DateCalendar } from '@mui/x-date-pickers-pro';

const CustomPopover = styled(Popover)`
  .MuiPickerStaticWrapper-root > div > div {
    width: 20rem !important;
    max-height: 22.375rem !important;
  }

  .PrivateDatePickerToolbar-penIcon {
    top: 0px !important;
  }

  .PrivatePickersToolbar-dateTitleContainer {
    align-items: center !important;
  }

  .MuiCalendarPicker-root {
    width: 20rem !important;
    max-height: 22.375rem !important;
  }

  .MuiCalendarPicker-root > :first-child {
    max-height: 1.875rem !important;
    min-height: 1.875rem !important;
    padding-left: 2rem !important;
    padding-right: 1.5rem !important;
  }

  .PrivatePickersFadeTransitionGroup-root > :first-child > :first-child {
    .MuiTypography-root {
      width: 2.25rem !important;
      height: 2.5rem !important;
    }
  }

  .MuiPickersDay-root {
    width: 2.25rem !important;
    height: 2.25rem !important;
  }
  
  .MuiButtonBase-root-MuiPickersDay-root {
    width: 2.25rem !important;
    height: 2.25rem !important;
  }

  .PrivatePickersFadeTransitionGroup-root > :first-child > :nth-child(2) {
    min-height: 15rem !important;
  }

  .PrivatePickersYear-yearButton {
    width: 4.5rem !important;
    height: 2.25rem !important;
  }

  .MuiButtonBase-root {
    font-size: 0.80rem !important;
  }
`

type DatePickerPoppoverProps = PopoverProps & {
  onCancelClick?: () => void;
  onConfirmClick?: (start: Date, end: Date) => void;
  timezone: string;
  hintStart?: string;
  hintEnd?: string;
  customErrorCheck?: (start: Date, end: Date) => string | undefined;
  disableFuture?: boolean;
  disablePast?: boolean;
  endOnly?: boolean;
  showTimeEstimate?: boolean;
  startTimeText?: string;
  endTimeText?: string;
  multiDay?: boolean;
  maxTimeRangeInHours?: number;
  startDayHint?: number | string;
  endDayHint?: number | string;
  calendarUtcMessage?: string;
}

const DatePickerPopover: React.FC<DatePickerPoppoverProps> = (props) => {
  const {
    onCancelClick,
    onConfirmClick,
    timezone,
    hintStart,
    hintEnd,
    customErrorCheck,
    disableFuture,
    disablePast,
    endOnly,
    showTimeEstimate,
    startTimeText,
    endTimeText,
    multiDay,
    maxTimeRangeInHours,
    startDayHint,
    endDayHint,
    calendarUtcMessage,
    ...popoverProps } = props;

  const [startTime, setStartTime] = React.useState<string | undefined>(hintStart || "00:00:00");
  const [endTime, setEndTime] = React.useState<string | undefined>(hintEnd || "23:59:00");
  const [startDay, setStartDay] = React.useState<Date>(new Date(startDayHint || Date.now()));
  const [endDay, setEndDay] = React.useState<Date>(new Date(endDayHint || Date.now()));
  const [error, setError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string[]>([])

  const isToday = React.useCallback((someDate: Date) => {
    try {
      const today = new Date(moment.default(Date.now()).tz(timezone.includes(' ') ? timezone.split(' ')[0] : timezone).format('MM/DD/YYYY'));
      return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
    } catch (e) {
      return false;
    }
  }, [timezone])

  const getFinalEndDate = React.useCallback((endString: string) => {
    const endDayTemp = multiDay ? endDay : startDay;
    return Utils.Date.stringToDate(endString, timezone, endDayTemp.getDate(), endDayTemp.getMonth(), endDayTemp.getFullYear());
  }, [endDay, multiDay, startDay, timezone])

  const getFinalDates = React.useCallback((startString: string, endString: string) => {
    const start = Utils.Date.stringToDate(startString, timezone, startDay.getDate(), startDay.getMonth(), startDay.getFullYear());
    const end = getFinalEndDate(endString);
    return { start, end };
  }, [getFinalEndDate, startDay, timezone])

  const calculateTimeEstimate = React.useMemo(() => {
    if (endTime) {
      const start: number = Date.now();
      const end: number = getFinalEndDate(endTime).getTime();
      return `${moment.default(start).to(end).replace('in ', '~')} from now`;
    }

    return "";
  }, [endTime, getFinalEndDate])

  React.useEffect(errorHandler,
    [startTime,
      endTime,
      startDay,
      endDay,
      endOnly,
      disablePast,
      calculateTimeEstimate,
      timezone,
      getFinalDates,
      disableFuture,
      maxTimeRangeInHours,
      customErrorCheck,
      isToday]
  )
  React.useEffect(() => {
    if (popoverProps.open === true) {
      setStartTime(hintStart || "00:00:00");
      setEndTime(hintEnd || "23:59:00");
      setStartDay(new Date(startDayHint || Date.now()));
      setEndDay(new Date(endDayHint || Date.now()));
    }
  }, [endDayHint, hintEnd, hintStart, popoverProps.open, startDayHint])

  function errorHandler(): void {
    if (endOnly && disablePast === true) {
      if (calculateTimeEstimate.includes('ago')) {
        setErrorMessage(['End time must be in the future']);
        setError(true)
      } else {
        setErrorMessage([]);
        setError(false);
      }
      return;
    }

    if (startTime && endTime) {
      const tz = timezone.includes(' ') ? timezone.split(' ')[0] : "";

      const { start, end } = getFinalDates(startTime, endTime);

      if (endOnly === undefined || endOnly === false) {
        const error = start.getTime() >= end.getTime();
        setError(error);
        if (error) {
          setErrorMessage(["Start cannot be greater than end."]);
          return;
        }
        else setErrorMessage([]);
      }

      if (disableFuture !== false) {
        const error = start.getTime() > moment.tz(tz).valueOf() || end.getTime() > moment.tz(tz).valueOf();
        setError(error);
        if (error) {
          setErrorMessage(["The selected timestamp is in the future.", "Please select a valid timestamp."]);
          return;
        } else setErrorMessage([]);
      }

      if (maxTimeRangeInHours) {
        const error = (end.getTime() - start.getTime()) > (3600000 * maxTimeRangeInHours);
        setError(error);
        if (error) {
          setErrorMessage([`Maximum time range is ${maxTimeRangeInHours}h`]);
          return;
        } else setErrorMessage([]);
      }
      if (customErrorCheck) {
        const err = customErrorCheck(start, end);
        if (err !== undefined) {
          setError(true);
          setErrorMessage([err]);
        }
      }
    }
  }

  function handleDatePickerChange(date: any): void {
    if (date) {
      setStartDay(new Date(new Date(date).getTime()));
    }
  }

  function handleStartDateChange(event: any) {
    setStartTime(event.target.value);
  }

  function handleEndDateChange(event: any) {
    setEndTime(event.target.value);
  }

  function handleOkClick() {
    if (startTime && endTime && onConfirmClick) {
      const { start, end } = getFinalDates(startTime, endTime);

      onConfirmClick(start, end);
    }
  }

  function getMaxDate() {
    if (disableFuture === false) {
      return undefined;
    }

    if (timezone && timezone.includes(' ')) {
      return new Date(moment.default(Date.now()).tz(timezone.split(' ')[0]).format('MM/DD/YYYY'))
    }

    return new Date(moment.default(Date.now()).format('MM/DD/YYYY'))
  }

  return (
    <CustomPopover {...popoverProps} >
      <Box display="grid" gridTemplateRows="auto(min-content)" data-cy="date-picker-container">
        {multiDay ?
          <DateRangePickerComponent
            open={true}
            onChange={(dateRange) => {
              if (dateRange.startDate) {
                setStartDay(dateRange.startDate);
                setEndDay(dateRange.endDate ? dateRange.endDate : dateRange.startDate);
              }
            }}
            initialDateRange={{ startDate: startDay, endDate: endDay }}
            definedRanges={[]}
            maxDate={new Date()}
            maxRangeInHours={24}
            data-cy="date-range-picker"
          /> : <DateCalendar
            value={startDay}
            onChange={handleDatePickerChange}
            maxDate={getMaxDate()}
            disablePast={disablePast}
            disableHighlightToday
            data-cy="date-static-picker"
          />}
        <Box
          display="grid"
          gridTemplateColumns={(endOnly === false || endOnly === undefined) ? '1fr 1fr' : '1fr'}
          padding="1rem"
          columnGap="1rem"
          data-cy="date-picker-start-end-container"
        >
          {(endOnly === false || endOnly === undefined) &&
            <TextField
              error={error}
              value={startTime}
              label={startTimeText || "Start time"}
              onChange={handleStartDateChange}
              variant="outlined"
              size="small"
              type="time"
              data-cy="date-picker-start-input"
              inputProps={{ step: 1 }}
            />
          }
          <TextField
            error={error}
            value={endTime}
            label={endTimeText || "End time"}
            onChange={handleEndDateChange}
            variant="outlined"
            size="small"
            type="time"
            data-cy="date-picker-end-input"
            inputProps={{ step: 1 }}
          />
        </Box>
        <FeedbackMessage
          errorMessage={errorMessage}
          showTimeEstimate={showTimeEstimate}
          visibility={calculateTimeEstimate.includes('ago') ? 'hidden' : 'visible'}
          estimate={calculateTimeEstimate}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
          padding="0 1rem 1rem"
        >
          <Typography style={{ visibility: isEqual(timezone, 'UTC (GMT+00:00)') ? 'visible' : 'hidden' }} variant="caption" paddingBottom="0.5rem">{calendarUtcMessage || 'Timezone of the filter is UTC'}</Typography>
          <Box>
            <Button
              color="primary"
              onClick={onCancelClick}
              data-cy="date-picker-cancel-button"
            >
              <Typography variant="body1">Cancel</Typography>
            </Button>
            <Button
              disabled={error}
              color="primary"
              onClick={handleOkClick}
              data-cy="date-picker-ok-button"
            >
              <Typography variant="body1">Ok</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </CustomPopover>
  );
}

const FeedbackMessage: React.FC<{
  errorMessage: string[];
  estimate: string;
  showTimeEstimate?: boolean;
  visibility: 'hidden' | 'visible';
}> = (props) => {
  const { errorMessage, showTimeEstimate, visibility, estimate } = props;

  if (errorMessage.length > 0) {
    return (
      <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {errorMessage.map((msg) => (
          <Typography variant="body2" color="error" >{msg}</Typography>
        ))}
      </Box>
    )
  }

  if (showTimeEstimate) {
    return (
      <Box style={{ visibility: visibility }} display="flex" paddingLeft="1rem">
        <Typography variant="caption">{estimate}</Typography>
      </Box>
    )
  }

  return null;
}

export default DatePickerPopover;
