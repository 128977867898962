import {
  ActionTypes, IGridsState,
} from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IGridsState = {
  grids: {}
}

export const gridsReducer = Utils.Redux.createReducer<
IGridsState,
  Types,
  Actions
>(initialState, {
  [ActionTypes.SET_GRIDS]: (state, action) => ({
    ...state,
    grids: action.value
  }),
  [ActionTypes.ADD_GRIDS]: (state, action) => ({
    ...state,
    grids: addGrids(state.grids, action.value.companyId, action.value.views)
  })
});

function addGrids(grids: {[key: string]: UserView[]}, companyId: string, views: UserView[]): {[key: string]: UserView[]} {
  const map = {...grids};
  map[companyId] = views;
  return map;
}
