import { API } from 'aws-amplify';

async function getDetections(
  startTime: Date,
  endTime: Date,
  siteId: string,
  asCompany?: string,
  workerId?: number
): Promise<{
  entries: Detection[];
  startTime: Date,
  endTime: Date,
  siteId: string,
  workerId?: number
}> {
  try {
    const path = `/workers/detections`;
    const queryStringParameters: any = {};
    if (startTime)
      queryStringParameters.startTime = startTime.getTime();
    if (endTime)
      queryStringParameters.endTime = endTime.getTime();
    if (siteId)
      queryStringParameters.siteId = siteId;
    if (asCompany)
      queryStringParameters.asCompany = asCompany;
    if (workerId)
      queryStringParameters.workerId = workerId;

    const { data } = await API.get('api-service', path, {
      queryStringParameters
    });

    return {
      entries: data,
      startTime,
      endTime,
      siteId,
      workerId
    }
  } catch (error: any) {
    throw error;
  }
}

export default getDetections;
