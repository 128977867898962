import Tutorial from "./Tutorial";

class TimelineEventsTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#time-interval-description',
        popover: {
          title: 'Time interval',
          description: 'Filter all events by time period',
          position: 'bottom-left'
        }
      },
      {
        element: '#add-filter-description',
        popover: {
          title: 'Filter events',
          description: 'Find what’s most important quickly by filtering the event list with event duration, location on stream, motion, zones, fire, personal protective equipment, social distancing, and vehicle license plates.',
          position: 'left'
        }
      },
      {
        element: '#motion-count-description',
        popover: {
          title: 'Motion events',
          description: 'A drop-down menu of all motion events that happened during a selected period. It includes the count of all motion events as well as timestamps of detected objects. By clicking on a detected object you can navigate the stream back to that specific event. There are multiple types of motion such as person based, vehicle based, or generalized movement.',
          position: 'left'
        }
      },
      {
        element: '#fire-count-description',
        popover: {
          title: 'Fire',
          description: 'A drop-down menu of all fire detected events that happened during a selected period. It includes the count of such events as well as timestamps of detected fires. By clicking on a detected fire event you can navigate the stream back to that specific event.',
          position: 'left'
        }
      },
      {
        element: '#safety-infraction-count-description',
        popover: {
          title: 'Protective equipment',
          description: 'A drop-down menu of all events that detect people not wearing protective equipment during a selected period. It includes the count of such events as well as timestamps of these detected safety infractions. By clicking on a detected safety infraction you can navigate the stream back to that specific event.',
          position: 'top'
        }
      },
      {
        element: '#social-distance-count-description',
        popover: {
          title: 'Social distancing',
          description: 'A drop-down menu of all events in which people are not in compliance with social distancing guidelines. It includes the count of such events as well as timestamps of detected these safety breaches. By clicking on a detected social distance breach you can navigate the stream back to that specific event.',
          position: 'top'
        }
      },
      {
        element: '#vehicle-license-plate-count-description',
        popover: {
          title: 'Vehicle license plate',
          description: 'A drop-down menu of all events in which the camera detected license plates of vehicles. It includes the count of such events as well as timestamps of detected license plates. By clicking on a detected license plate you can navigate the stream back to that specific event.',
          position: 'top'
        }
      },
      {
        element: '#zones-count-description',
        popover: {
          title: 'Zones',
          description: 'A drop-down menu of all events that triggered selected zones on the stream. It includes the count of such events as well as timestamps of detected zone activity. By clicking on a detected zone clip you can navigate the stream back to that specific event.',
          position: 'top'
        }
      }
    ])
  }

}

export default TimelineEventsTutorial;