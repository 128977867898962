import { Avatar } from '@components/common/Avatar';
import useDebouncedState from '@hooks/Debounce/useDebounceState';
import { PersonOff } from '@mui/icons-material';
import { Box, InputAdornment, ListItemAvatar, ListItemText, ListSubheader, MenuItem, MenuList, Popover, TextField, Checkbox, Divider } from '@mui/material';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import API from '@API/index';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';
import SearchHeaderFilter from '@components/common/SearchHeaderFilter';
import { extractKeywords, matchesKeywordPeriod } from '@utils/filtering/filter-utils';
import SearchHeaderTooltipIcon from '@components/common/SearchHeaderTooltipIcon';
import { selectIsSuperAdminUser } from '@store/selectors';
import { isArray, isEmpty, isEqual, isUndefined } from 'lodash';
import usePrevious from '@hooks/usePrevious';

type UserlistProps = {
  anchor: HTMLElement | null;
  anchorPosition?: { top: number, left: number }
  onClose: () => void;
  onUserSelected: (id?: string) => void;
  value?: string[];
  multiple?: boolean;
  activeUsersOnly?: boolean;
  excludeInvited?: boolean;
  targetKvsNames?: string[]
}

const UserList: React.FC<UserlistProps> = (props) => {
  const {
    anchor,
    onClose,
    onUserSelected,
    value,
    multiple,
    activeUsersOnly,
    anchorPosition,
    excludeInvited,
    targetKvsNames
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const isSuperAdministrator = useSelector(selectIsSuperAdminUser);
  const users = useSelector((store: IStoreState) => store.users.users);
  const [filterText, setFilterText] = useDebouncedState("");
  const prevText = usePrevious(filterText)
  const prevTargetKvsNames = usePrevious(targetKvsNames);

  const [filteredUsers, setFilteredUsers] = React.useState<User[]>(users.filter((user) => {
    if (activeUsersOnly) return user.status === "Active";
    if (excludeInvited) return user.status !== "Invited";
    return true;
  }));

  const [allowedUsers, setAllowedUsers] = React.useState<User[]>([]);
  const [finalUsers, setFinalUsers] = React.useState<User[]>([]);

  const selectedCompanyRef = React.useRef(selectedCompany);
  const enqueueSnackbarRef = React.useRef(enqueueSnackbar);
  const activeUsersOnlyRef = React.useRef(activeUsersOnly);
  const setFilterTextRef = React.useRef(setFilterText);
  const excludeInvitedRef = React.useRef(excludeInvited)

  React.useEffect(() => { excludeInvitedRef.current = excludeInvited }, [excludeInvited])
  React.useEffect(() => { setFilterTextRef.current = setFilterText }, [setFilterText])
  React.useEffect(() => { activeUsersOnlyRef.current = activeUsersOnly }, [activeUsersOnly])
  React.useEffect(() => { enqueueSnackbarRef.current = enqueueSnackbar }, [enqueueSnackbar]);
  React.useEffect(() => { selectedCompanyRef.current = selectedCompany }, [selectedCompany])
  React.useEffect(getUsers, [prevTargetKvsNames, prevText, targetKvsNames]);
  React.useEffect(() => { if (anchor === null) setFilterTextRef.current(""); }, [anchor])

  React.useEffect(() => {
    const keywords = extractKeywords(filterText);
    const filteredUsers = allowedUsers.filter(u => {
      if (activeUsersOnlyRef.current) {
        return matchesKeywordPeriod(keywords, u) && u.status === "Active";
      }

      if (excludeInvitedRef.current) {
        return matchesKeywordPeriod(keywords, u) && u.status !== "Invited";
      }

      return matchesKeywordPeriod(keywords, u);
    })
    setFilteredUsers(filteredUsers);
  }, [allowedUsers, filterText])

  function getUsers(): void {
    if (!isEqual(targetKvsNames, prevTargetKvsNames) || isUndefined(targetKvsNames)) {
      const algorithm =
        !isUndefined(targetKvsNames) && isArray(targetKvsNames) && !isEmpty(targetKvsNames)
          ? () => getFilteredUsersByStreams(selectedCompanyRef.current?.companyId || "", targetKvsNames)
          : () => API.Users.getUsers(selectedCompanyRef.current?.companyId);

      algorithm()
        .then((res: User[]) => {
          setAllowedUsers(res);
        })
        .catch((e) => {
          enqueueSnackbarRef.current(Utils.Error.getErrorMessage(e), { variant: 'error' });
        })
    }
  }

  async function getFilteredUsersByStreams(companyId: string, kvsNames: string[]): Promise<User[]> {
    try {
      const [users, mappings] = await Promise.all([
        API.Users.getUsers(companyId),
        API.Permissions.listUsersAndStreamsMapping(companyId)
      ]);

      const filtered = users.filter((user) => {
        if (user.roles === 'superadmin') return false;
        if (user.roles === 'admin') return true;

        let shouldReturn: boolean = true;

        for (let i = 0; i < kvsNames.length; i += 1) {
          if (isArray(mappings.get(kvsNames[i])) && mappings.get(kvsNames[i])?.includes(user.userId)) continue;

          shouldReturn = false;
          break;
        }

        return shouldReturn;
      })

      return filtered;
    } catch (error) {
      throw error;
    }
  }

  return (
    <Popover
      anchorReference={anchorPosition ? 'anchorPosition' : 'anchorEl'}
      anchorEl={anchor}
      anchorPosition={anchorPosition}
      open={Boolean(anchor || anchorPosition)}
      onClose={onClose}
      anchorOrigin={anchor ? {
        vertical: 'bottom',
        horizontal: 'center',
      } : undefined}
      transformOrigin={anchor ? {
        vertical: 'top',
        horizontal: 'center',
      } : undefined}
      PaperProps={{ style: { width: '27rem', overflow: 'hidden' } }}
    >
      <MenuList disablePadding style={{ paddingBottom: 8 }}>
        <MenuList disablePadding>
          <ListSubheader style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: "0.5rem"
          }} >
            <Box width="100%" alignItems="center" display="grid" gap="0.5rem" gridTemplateColumns={`auto`}>
              <TextField
                style={{ marginTop: 0, width: '100%' }}
                autoFocus={true}
                placeholder="Search"
                variant="standard"
                defaultValue={filterText}
                onChange={(event) => { event.stopPropagation(); event.preventDefault(); setFilterText(event.target.value); }}
                onKeyDown={(e) => { e.stopPropagation(); }}
                size="medium"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchHeaderTooltipIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchHeaderFilter
                        properties={Utils.Filters.createUsersFilters(isSuperAdministrator || false)}
                        data={filteredUsers}
                        onFilteredDataChange={(data: any[]) => {
                          setFinalUsers(data);
                        }}
                      />
                    </InputAdornment>
                  )
                }}
                data-cy="site-list-search-input"
              />
            </Box>
          </ListSubheader>
        </MenuList>
        <Divider />
        <MenuList style={{ maxHeight: '40vh', overflowY: 'auto' }}>
          {multiple ?
            <MenuItem onClick={() => { onUserSelected('all'); }} key='all' style={{ paddingTop: 0, paddingBottom: 0 }} data-cy="overview-user-list-all-menu-item">
              {multiple &&
                <Checkbox
                  checked={(value?.length || 0) >= filteredUsers.length}
                  indeterminate={value && value.length > 0 && value.length < filteredUsers.length}
                />}
              <ListItemText primary={`Select all`} />
            </MenuItem>
            :
            <MenuItem onClick={() => { !multiple && onClose(); onUserSelected(undefined); }} data-cy="overview-user-list-unassign-menu-item">
              <ListItemAvatar>
                <Box height="100%" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                  <PersonOff style={{ height: 30, width: 30 }} />
                </Box>
              </ListItemAvatar>
              <ListItemText primary={`Unasigned`} />
            </MenuItem>}
          <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
          {Utils.Array.orderAlphabetically(finalUsers, 'givenName').map((user) => (
            <MenuItem onClick={() => { !multiple && onClose(); onUserSelected(user.userId); }} key={user.userId} data-cy={`overview-user-list-${user.email}-menu-item`}>
              {multiple &&
                <Checkbox
                  checked={value?.some(v => v === user.userId) || false}
                />}
              <ListItemAvatar>
                <Avatar user={user as User} width={30} height={30} />
              </ListItemAvatar>
              <ListItemText primary={`${user.givenName} ${user.familyName}`} secondary={user.email} />
            </MenuItem>
          ))}
        </MenuList>
      </MenuList>
    </Popover>
  )
}

export default UserList;
