import { API } from "aws-amplify";

async function updateStream(stream: VideoStreamBase): Promise<void> {
  try {
    const path: string = `/streams/${stream.siteId}/${stream.kvsName}`;
    const tempStream: any = {
      ...stream
    }
    delete tempStream.kvsName
    if (tempStream.deviceShadowName) delete tempStream.deviceShadowName;
    if (!tempStream.retentionPeriodInHours) delete tempStream.retentionPeriodInHours;

    const options = {
      body: tempStream
    };

    await API.put(
      'api-service',
      path,
      options
    );

  } catch (error: any) {
    throw error;
  }
}

export default updateStream;
