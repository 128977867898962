import { Box, CircularProgress, Dialog, DialogProps, Typography } from '@mui/material';
import React from 'react';
import { Button } from '@mui/material';
import { useMobileQuery } from '@hooks/useMobileQuery';

interface IConfirmationDialogProps extends DialogProps {
  title: string;
  description: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  loading?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  width?: string;
  disableConfirmButton?: boolean;
}

/**
 * Very simple and lightweight Dialog to display confirmation to the user.
 * Containing a Title, short description and two actions, Cancel and Confirm.
 * @returns JSX.Element.
 */
const ConfirmationDialog: React.FC<IConfirmationDialogProps> = (props) => {
  const { title, description, onCancel, onConfirm, loading, confirmLabel, cancelLabel, width, disableConfirmButton, ...restOfProps } = props;

  const isMobile = useMobileQuery();

  return (
    <Dialog {...restOfProps}>
      <Box borderRadius="12px" width={width || isMobile ? '80vw' : "27rem"} display="grid" padding="2rem" gridTemplateRows="auto auto auto" >
        <Typography noWrap={true} variant="h5" data-cy="confirmation-dialog-title" >{title}</Typography>
        <Typography variant='body1' style={{ paddingTop: "0.5rem" }} data-cy="confirmation-dialog-description" >{description}</Typography>
        <Box paddingTop="2rem" display="grid" gridTemplateColumns="max-content max-content" columnGap="1.5rem" width="100%" justifyContent="flex-end" >
          {onCancel && <Button
            size='large'
            onClick={onCancel}
            disabled={loading === true}
            variant="outlined"
            data-cy="confirmation-dialog-secondary-button"
          >
            {cancelLabel || "Cancel"}
          </Button>}
          <Button
            size='large'
            color='primary'
            onClick={onConfirm}
            disabled={loading || disableConfirmButton}
            variant="contained"
            data-cy="confirmation-dialog-primary-button"
          >
            {loading ? <CircularProgress size="1.5rem" /> : confirmLabel || 'Confirm'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ConfirmationDialog;
