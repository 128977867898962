/**
 * Returns 01-12 to UTC Month of given Date.
 * @param date Date object.
 * @returns string from 01 to 12.
 */
function getTwoDigitsUTCMonth(date: Date): string {
  const month = date.getUTCMonth() + 1;
  return month < 10 ? '0' + month : '' + month;
}

export default getTwoDigitsUTCMonth;
