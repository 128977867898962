import mapboxgl from 'mapbox-gl';
import icon from '../../assets/images/icons/mapbox_stream.svg';
import * as uid from 'uuid';
class StreamMarker extends mapboxgl.Marker {
  private _uid: string = '';

  constructor (options?: mapboxgl.MarkerOptions) {
    super({...options, element: document.createElement('div')});
    this.getElement().style.backgroundImage = `url('${icon}')`;
    this.getElement().style.backgroundSize = 'cover';
    this.getElement().style.width = '40px';
    this.getElement().style.height = '40px';
    this.getElement().style.borderRadius = '50%';
    this.getElement().style.cursor = 'pointer';

    this._uid = uid.v4();
  }

  public setInteractable(interactable: boolean): StreamMarker {
    if (interactable) {
      this.getElement().style.opacity = '1';
      this.getElement().style.pointerEvents = 'all';
    } else {
      this.getElement().style.opacity = '0.5';
      this.getElement().style.pointerEvents = 'none';
    }
    return this;
  }

  public setAngleEastOfNorth(value: number): StreamMarker {
    this.setRotation(value - 90);
    return this;
  }

  public withLabel(map: mapboxgl.Map, label: string): StreamMarker {
    if (!this.getLngLat()) throw new Error("Labels must be placed after setLngLat");
    this.paintLabel(map, label);
    map.on('style.load', () => {
      this.paintLabel(map, label);
    })

    return this;
  }

  public cleanUp(map: mapboxgl.Map): void {
    if (map) {
      try { map.removeLayer(`label-layer-${this._uid}`); } catch (e) {}
      try { map.removeSource(`label-source-${this._uid}`); } catch (e) {}
    }
  }

  private paintLabel(map: mapboxgl.Map, label: string): void {
    const feature: GeoJSON.Feature = {
      type: 'Feature',
      properties: {
        description: label
      },
      geometry: {
        type: 'Point',
        coordinates: [this.getLngLat().lng, this.getLngLat().lat]
      }
    }

    const styleName = map.getStyle().name?.toLowerCase();
    
    let fontColor = 'black';
    let haloColor = 'white';

    if (styleName?.includes('satellite') || styleName?.includes('dark')) {
      fontColor = 'white'
      haloColor = 'black';
    };

    map.addSource(`label-source-${this._uid}`, {
      type: 'geojson',
      data: feature
    })

    map.addLayer({
      id: `label-layer-${this._uid}`,
      type: 'symbol',
      source: `label-source-${this._uid}`,
      layout: {
        'text-field': ['get', 'description'],
        'text-variable-anchor': ['bottom'],
        'text-radial-offset': 1,
        'text-justify': 'auto',
        'text-size': 18,
        'text-font': ['Open Sans Extrabold']
      },
      paint: {
        'text-color': fontColor,
        'text-opacity': 1,
        'text-halo-color': haloColor,
        'text-halo-width': 2
      }
    })
  }
}


export default StreamMarker;
