import { API } from "aws-amplify";

async function restartStream(kvsName: string): Promise<void> {
  try {
    const path: string = `/restart/streaming`;

    const options = {
      body: {
        kvsName
      }
    }
    await API.post(
      'api-service',
      path,
      options
    );

  } catch (error: any) {
    throw error;
  }
}

export default restartStream;
