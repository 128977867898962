import { Box, Menu, MenuItem } from '@mui/material';
import React from 'react';
import Utils from "@utils/index";
import styled from 'styled-components';
import ZoneShape from './ZoneShape';
import ZoneMinimumObjectSize from './ZoneMinimumObjectSize';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';

export const Board = styled.div<{ width?: string, height?: string, cursor?: string }>`
  position: relative;

  width: ${props => props.width};
  height: ${props => props.height};

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  :hover {
    cursor: ${props => props.cursor};
  }

  z-index: 11;
`

const GeofencePoint = styled.div<{ color: string }>`
  position: absolute;
  height: 0.7rem;
  width: 0.7rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-color: white;
  background-color: ${props => props.color};

  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  z-index: 12;

  :hover {
    cursor: grabbing;
  }
`

const GhostGeofencePoint = styled.div`
  position: absolute;
  height: 0.7rem;
  width: 0.7rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-color: black;
  background-color: white;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  z-index: 12;

  opacity: 0.8;

  :hover {
    cursor: grabbing;
  }
`

export type ZonesCanvasProps = {
  active: boolean;
  zones?: Zone[];
  focusedZone?: Zone;
  onZoneSelected?: (zone: Zone) => void;
  onZonePointsChange?: (zone: Zone) => void;
  hoveredEventZone?: Zone | Zone[];
  nonClickable?: boolean;
  kvsName: string;
}

type ParentProps = {
  videoWidth: number;
  videoHeight: number;
  containerWidth: number;
  containerHeight: number;
  videoWindowOffset: Vector2;
  videoState: "error" | "buffering" | "loading" | "ready";
}

export interface IZonesCanvasHandles {
  draw: (transform: { x: number, y: number, scale: number }) => void;
}

const ZonesCanvas: React.ForwardRefRenderFunction<IZonesCanvasHandles, ZonesCanvasProps & ParentProps> = (props, ref) => {
  const {
    active,
    containerWidth,
    containerHeight,
    videoWidth,
    videoHeight,
    zones,
    videoState,
    focusedZone,
    onZonePointsChange,
    hoveredEventZone,
    videoWindowOffset,
    kvsName,
    nonClickable
  } = props;

  React.useImperativeHandle(ref, () => ({
    draw
  }))

  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize)

  const [canvasSize, setCanvasSize] = React.useState<Vector2>({ x: 0, y: 0 });
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
    index: number;
  } | null>(null);
  const [mouseDownTimestamp, setMouseDownTimestamp] = React.useState<number>(Date.now());
  const [mouseUpTimestamp, setMouseUpTimestamp] = React.useState<number>(Date.now());

  const mouseOverPointRef = React.useRef<boolean>(false);
  const transformRef = React.useRef<{ x: number, y: number, scale: number }>({ x: 0, y: 0, scale: 1 })
  const drawableChildrenRefs = React.useRef<Map<string, { draw: (transform: { x: number, y: number, scale: number }) => void } | null>>(new Map())
  const geofencePointsRefs = React.useRef<Map<number, HTMLDivElement | null>>(new Map());
  const geofenceGhostPointsRefs = React.useRef<Map<number, HTMLDivElement | null>>(new Map());

  const mouseEventSubscriptionRef = React.useRef<boolean>(false);
  const kvsNameRef = React.useRef<string>("");

  React.useEffect(() => { kvsNameRef.current = kvsName }, [kvsName])
  React.useEffect(() => draw(transformRef.current));

  const propagateMouseEvent = React.useCallback((originalEvent: any, type: any) => {
    if (mouseOverPointRef.current === true) return;

    let newEvent = undefined;

    if (type === 'wheel') {
      newEvent = new WheelEvent('wheel', {
        bubbles: true,
        cancelable: true,
        view: window,
        detail: originalEvent.detail,
        screenX: originalEvent.screenX,
        screenY: originalEvent.screenY,
        clientX: originalEvent.clientX,
        clientY: originalEvent.clientY,
        ctrlKey: originalEvent.ctrlKey,
        altKey: originalEvent.altKey,
        shiftKey: originalEvent.shiftKey,
        metaKey: originalEvent.metaKey,
        button: originalEvent.button,
        deltaX: originalEvent.deltaX,
        deltaY: originalEvent.deltaY,
        deltaZ: originalEvent.deltaZ,
        deltaMode: originalEvent.deltaMode,
      });
    } else {
      newEvent = new MouseEvent(type, {
        bubbles: true,
        cancelable: true,
        view: window,
        detail: originalEvent.detail,
        screenX: originalEvent.screenX,
        screenY: originalEvent.screenY,
        clientX: originalEvent.clientX,
        clientY: originalEvent.clientY,
        ctrlKey: originalEvent.ctrlKey,
        altKey: originalEvent.altKey,
        shiftKey: originalEvent.shiftKey,
        metaKey: originalEvent.metaKey,
        button: originalEvent.button,
      });
    }

    // Dispatch the new event on the element
    document.getElementById(kvsNameRef.current)?.dispatchEvent(newEvent);
  }, [])

  const propagateWheel = React.useCallback((event: any) => {
    propagateMouseEvent(event, 'wheel');
  }, [propagateMouseEvent])

  const propagateMouseDown = React.useCallback((event: any) => {
    setMouseDownTimestamp(Date.now())
    propagateMouseEvent(event, 'mousedown');
  }, [propagateMouseEvent]);

  const propagateMouseUp = React.useCallback((event: any) => {
    setMouseUpTimestamp(Date.now());
    propagateMouseEvent(event, 'mouseup');
  }, [propagateMouseEvent])

  const propageteMouseMove = React.useCallback((event: any) => {
    propagateMouseEvent(event, 'mousemove');
  }, [propagateMouseEvent])

  React.useEffect(calculateCanvasSize, [videoWidth, videoHeight, containerWidth, containerHeight]);
  React.useEffect(() => {
    const element = document.getElementById(`zones-canvas-${kvsName}`);
    const target = document.getElementById(kvsName);

    if (element && target && videoState === "ready") {
      element.addEventListener('wheel', propagateWheel);
      element.addEventListener('mousedown', propagateMouseDown);
      element.addEventListener('mouseup', propagateMouseUp);
      element.addEventListener('mousemove', propageteMouseMove);
    }
  });

  React.useEffect(() => {
    return () => {
      mouseEventSubscriptionRef.current = false;
    }
  }, [])

  function draw(transform: { x: number, y: number, scale: number }) {
    transformRef.current = transform;
    drawableChildrenRefs.current.forEach((value) => {
      value?.draw(transform);
    })

    geofencePointsRefs.current.forEach((value, key) => {
      if (value && focusedZone) {
        const points = focusedZone.coordinates[key];
        const plane = canvasSize;
        const position = toVector(points, plane);

        value.style.top = `${position.y - 3}px`;
        value.style.left = `${position.x - 3}px`;

        const ghostTarget = geofenceGhostPointsRefs.current.get(key);
        const nextPosition = toVector(focusedZone.coordinates[key + 1] ? focusedZone.coordinates[key + 1] : focusedZone.coordinates[0], plane);
        if (ghostTarget) {
          const ghostPosition = {
            x: (position.x + nextPosition.x) / 2,
            y: (position.y + nextPosition.y) / 2
          };


          ghostTarget.style.top = `${ghostPosition.y - 3}px`;
          ghostTarget.style.left = `${ghostPosition.x - 3}px`;
        }
      }
    })
  }

  function handleContextMenu(event: React.MouseEvent, index: number) {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
          index: index
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    );
  };

  function calculateCanvasSize() {
    if (videoWidth !== 0 && videoHeight !== 0 && containerWidth !== 0 && containerHeight !== 0) {
      const size = Utils.Geometry.getCanvasSize(
        videoWidth || 0,
        videoHeight || 0,
        containerWidth || 0,
        containerHeight || 0
      );

      setCanvasSize(size);
    }
  }

  function toVector(point: number[], plane: Vector2): Vector2 {
    const offsetY = ((containerHeight - plane.y) * (transformRef.current.scale)) / 2;
    const offsetX = ((containerWidth - plane.x) * (transformRef.current.scale)) / 2;

    const newPoint = {
      x: plane.x * point[0] * transformRef.current.scale + transformRef.current.x + offsetX,
      y: plane.y * point[1] * transformRef.current.scale + transformRef.current.y + offsetY,
    }
    return newPoint;
  }

  function generateGeofencePoints(
    geofencePoints: Array<Array<number>>,
    color: string,
    plane: Vector2
  ) {
    let divs: JSX.Element[] = [];
    for (let i = 0; i < geofencePoints.length; i += 1) {
      const position = toVector(geofencePoints[i], plane);
      divs.push(
        <GeofencePoint
          ref={(ref) => geofencePointsRefs.current.set(i, ref)}
          color={color}
          key={i}
          style={{
            top: position.y - (rootFontSize * 0.35),
            left: position.x - (rootFontSize * 0.35)
          }}
          draggable={true}
          onDragEnd={(e: any) => onDragZonePoint(canvasSize, { x: containerWidth, y: containerHeight }, e, i)}
          onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
          onContextMenu={(event: any) => handleContextMenu(event, i)}
          onMouseOver={() => mouseOverPointRef.current = true}
          onMouseLeave={() => mouseOverPointRef.current = false}
        />
      );

      if (geofencePoints.length > 1) {
        // Calculate position of ghost point between current and next point
        const nextPosition = toVector(geofencePoints[i + 1] ? geofencePoints[i + 1] : geofencePoints[0], plane);
        const ghostPosition = {
          x: (position.x + nextPosition.x) / 2,
          y: (position.y + nextPosition.y) / 2
        };

        divs.push(
          <GhostGeofencePoint
            key={`ghost-${i}`}
            ref={(ref) => geofenceGhostPointsRefs.current.set(i, ref)}
            style={{
              top: ghostPosition.y - (rootFontSize * 0.35),
              left: ghostPosition.x - (rootFontSize * 0.35)
            }}
            draggable={true}
            onDragEnd={(e: any) => addGeofencePoint(canvasSize, { x: containerWidth, y: containerHeight }, e, i)}
            onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
            onMouseOver={() => mouseOverPointRef.current = true}
            onMouseLeave={() => mouseOverPointRef.current = false}
          />
        );
      }
    }
    return divs;
  }

  function onDragZonePoint(boardSize: Vector2, canvasSize: Vector2, event: React.DragEvent, index: number) {
    if (focusedZone) {
      const { coordinates } = focusedZone;
      const points: Array<Array<number>> = [];

      const position = Utils.Geometry.getBoardPosition(
        { x: boardSize.x, y: boardSize.y },
        { x: canvasSize.x, y: canvasSize.y },
        { x: event.clientX, y: event.clientY },
        videoWindowOffset,
        transformRef.current
      );

      const offsetY = ((canvasSize.y - boardSize.y) * (transformRef.current.scale - 1)) / 2;
      const offsetX = ((canvasSize.x - boardSize.x) * (transformRef.current.scale - 1)) / 2;

      if (position.x < 0 || position.y < 0) return;
      if (position.x < offsetX / 3 || position.y < offsetY / 3) return;
      if (position.y > boardSize.y + (offsetY / 3) || position.x > boardSize.x + (offsetX / 3)) return;

      const newPoint = [
        parseFloat(((position.x - (offsetX / transformRef.current.scale)) / boardSize.x).toFixed(4)),
        parseFloat(((position.y - (offsetY / transformRef.current.scale)) / boardSize.y).toFixed(4))
      ]
      //coordinates[index] = newPoint;

      for (let i = 0; i < coordinates.length; i += 1) {
        if (i === index) {
          points.push(newPoint);
        } else {
          points.push(coordinates[i]);
        }
      }

      if (onZonePointsChange) onZonePointsChange({ ...focusedZone, coordinates: points, kvsName });
    }
  }

  function addGeofencePoint(boardSize: Vector2, canvasSize: Vector2, event: React.MouseEvent, afterIndex?: number) {
    if (mouseUpTimestamp - mouseDownTimestamp > 200) return;

    if (focusedZone) {
      const position = Utils.Geometry.getBoardPosition(
        { x: boardSize.x, y: boardSize.y },
        { x: canvasSize.x, y: canvasSize.y },
        { x: event.clientX, y: event.clientY },
        videoWindowOffset,
        transformRef.current
      );

      const offsetY = ((canvasSize.y - boardSize.y) * (transformRef.current.scale - 1)) / 2;
      const offsetX = ((canvasSize.x - boardSize.x) * (transformRef.current.scale - 1)) / 2;

      if (position.x < 0 || position.y < 0) return;
      if (position.x < offsetX / 3 || position.y < offsetY / 3) return;
      if (position.y > boardSize.y + (offsetY / 3) || position.x > boardSize.x + (offsetX / 3)) return;

      const { coordinates } = focusedZone;

      const newPoints: Array<Array<number>> = [];

      const newPoint = [
        parseFloat(((position.x - (offsetX / transformRef.current.scale)) / boardSize.x).toFixed(4)),
        parseFloat(((position.y - (offsetY / transformRef.current.scale)) / boardSize.y).toFixed(4))
      ]

      for (let i = 0; i < coordinates.length; i += 1) {
        newPoints.push(
          [coordinates[i][0], coordinates[i][1]]
        );
      }

      if (afterIndex !== undefined) newPoints.splice(afterIndex + 1, 0, newPoint);
      else newPoints.push(newPoint);

      if (onZonePointsChange) onZonePointsChange({ ...focusedZone, coordinates: newPoints, kvsName });
    }
  }

  function deletePoint(): void {
    try {
      if (focusedZone && contextMenu) {
        const { coordinates } = focusedZone;
        const newPoints: Array<Array<number>> = [];
        for (let i = 0; i < coordinates.length; i += 1) {
          if (i !== contextMenu.index) newPoints.push(coordinates[i]);
        }

        setContextMenu(null);
        if (onZonePointsChange) onZonePointsChange({ ...focusedZone, coordinates: newPoints, kvsName });
      }
    } catch (e) {
      console.log(e);
    }
  }

  let zoneDots: Array<JSX.Element[]> = [];

  if (focusedZone && focusedZone.coordinates.length > 0) {
    const color = focusedZone.type === "ignore" ? 'rgba(255, 99, 67, 1)' : focusedZone.color;

    zoneDots.push(generateGeofencePoints(
      focusedZone.coordinates,
      color,
      canvasSize
    ));
  }

  if (hoveredEventZone) {
    if (isArray(hoveredEventZone)) {
      return (
        <Box style={{ mixBlendMode: 'overlay', pointerEvents: 'none' }} position="absolute" zIndex={32} width={containerWidth} height={containerHeight}>
          <Board
            width={`${containerWidth}px`}
            height={`${containerHeight}px`}
            data-cy="zone-drawing-board"
          >
            {hoveredEventZone.map(zone => (
              <ZoneShape
                ref={(ref) => drawableChildrenRefs.current.set("hovered", ref)}
                zone={zone}
                plane={canvasSize}
                isUserDrawingZone={hoveredEventZone !== undefined}
                isSelected={false}
                isHovered={true}
                containerHeight={containerHeight}
                containerWidth={containerWidth}
              />
            ))
            }
          </Board>
        </Box>
      )
    }
    return (
      <Box style={{ mixBlendMode: 'overlay', pointerEvents: 'none' }} position="absolute" zIndex={32} width={containerWidth} height={containerHeight}>
        <Board
          width={`${containerWidth}px`}
          height={`${containerHeight}px`}
          data-cy="zone-drawing-board"
        >
          {hoveredEventZone &&
            <ZoneShape
              ref={(ref) => drawableChildrenRefs.current.set("hovered", ref)}
              zone={hoveredEventZone}
              plane={canvasSize}
              isUserDrawingZone={hoveredEventZone !== undefined}
              isSelected={false}
              isHovered={true}
              containerHeight={containerHeight}
              containerWidth={containerWidth}
            />
          }
        </Board>
      </Box>
    )
  }

  if (active === false || videoState === "error" || videoState === "loading") return null;

  return (
    <>
      <Box style={{ pointerEvents: nonClickable ? 'none' : undefined }} position="absolute" zIndex={32} width={containerWidth} height={containerHeight}>
        <Board
          width={`${containerWidth}px`}
          height={`${containerHeight}px`}
          onClick={(e: any) => addGeofencePoint(canvasSize, { x: containerWidth, y: containerHeight }, e)}
          cursor={focusedZone ? 'crosshair' : undefined}
        >
          {focusedZone &&
            <ZoneMinimumObjectSize
              ref={(ref) => drawableChildrenRefs.current.set("minimum-drawable-object", ref)}
              zone={focusedZone}
              plane={canvasSize}
              isUserDrawingZone={focusedZone !== undefined}
              isSelected={true}
              isHovered={false}
              transform={transformRef.current}
              containerHeight={containerHeight}
              containerWidth={containerWidth}
            />
          }
        </Board>
      </Box>
      <Box style={{ mixBlendMode: 'overlay', pointerEvents: nonClickable ? 'none' : undefined }} position="absolute" zIndex={32} width={containerWidth} height={containerHeight}>
        <Board
          width={`${containerWidth}px`}
          height={`${containerHeight}px`}
          onClick={(e: any) => addGeofencePoint(canvasSize, { x: containerWidth, y: containerHeight }, e)}
          cursor={focusedZone ? 'crosshair' : undefined}
        >
          {zones &&
            zones.filter(el => el.id !== focusedZone?.id).map((zone) => (
              <ZoneShape
                ref={(ref) => drawableChildrenRefs.current.set(zone.id, ref)}
                key={zone.id}
                zone={zone}
                plane={canvasSize}
                isUserDrawingZone={focusedZone !== undefined}
                isSelected={false}
                isHovered={false}
                containerHeight={containerHeight}
                containerWidth={containerWidth}
              />
            ))
          }
          {focusedZone &&
            <ZoneShape
              ref={(ref) => drawableChildrenRefs.current.set("focused", ref)}
              zone={focusedZone}
              plane={canvasSize}
              isUserDrawingZone={focusedZone !== undefined}
              isSelected={true}
              isHovered={false}
              containerHeight={containerHeight}
              containerWidth={containerWidth}
            />
          }
        </Board>
      </Box>

      <Box position="absolute" zIndex={33} width={containerWidth} height={containerHeight} style={{ pointerEvents: nonClickable ? 'none' : undefined }}>
        <Board
          id={`zones-canvas-${kvsName}`}
          width={`${containerWidth}px`}
          height={`${containerHeight}px`}
          onClick={(e: any) => addGeofencePoint(canvasSize, { x: containerWidth, y: containerHeight }, e)}
          cursor={focusedZone ? 'crosshair' : undefined}
          style={{ overflow: 'hidden' }}
        >
          {zoneDots}
        </Board>
        <Menu
          open={contextMenu !== null}
          onClose={() => { setContextMenu(null); }}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={deletePoint} data-cy={`zones-canvas-delete-menu-item`} >Delete</MenuItem>
        </Menu>
      </Box >
    </>
  )
}

export default React.forwardRef(ZonesCanvas);
