import getStreamTimeChunks from './getStreamTimeChunks';
import getGroupMetricTimestamps from './getGroupMetricTimestamps';

export interface ICloudWatch {
  getStreamTimeChunks: (
    stream: VideoStream,
    timestampList?: Date[],
    period?: number
  ) => VideoStreamTimeChunk[];
  getGroupMetricTimestamps: (
    timestamps: Date[],
    period?: number
  ) => Date[];
}

const CloudWatch: ICloudWatch = {
  getStreamTimeChunks,
  getGroupMetricTimestamps
}

export default CloudWatch;
