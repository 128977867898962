import React from 'react';
import styled from 'styled-components';
import API from '@API/index';
import { ICredentials } from '@store/session/types';
import { CircularProgress } from '@mui/material';
import AreaSelector from './AreaSelector';
import useRenderOnWindowResize from '@hooks/useRenderOnWindowResize';
import ZonesCanvas, { IZonesCanvasHandles, ZonesCanvasProps } from './ZonesCanvas';
import { VideocamOff } from '@mui/icons-material';
import BitMovinPlayer, { IBitMovinPlayerHandles } from '../BitMovinPlayer';
import VideoBoundingBoxes from '@classes/VideoBoundingBoxes';
import EventContentVideoDetectionBoxes, { IEventContentVideoDetectionBoxesHandles } from '@components/overview/EventContent/EventContentVideoDetectionBoxes';
import DetectionBoxesLoader, { IDetectionBoxesLoaderHandles } from './DetectionBoxesLoader';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import PoseEstimationLoader, { IPoseEstimationLoaderHandles } from './PoseEstimationLoader';
import VideoPoseEstimations from '@classes/VideoPoseEstimations';
import PoseEstimationRenderer, { IPoseEstimationRendererHandles } from './PoseEstimationRenderer';
import { isNumber } from 'lodash';
import StreamSatelliteView, { IStreamSatelliteViewHandles } from '@components/satellite/StreamSatelliteView';

const VideoContainer = styled.div<{ maxHeight?: string }>`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
  max-height: ${props => props.maxHeight};

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .MuiSvgIcon-root {
    position: absolute;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    max-height: ${props => props.maxHeight ? `${props.maxHeight}` : '100%'};
  }
`;

const FeedbackContainer = styled.div`
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MuiTypography-root {
    color: white;
  }
`

type KinesisPlayerProps = {
  stream?: VideoStream;
  credentials?: ICredentials;
  onPause?: () => void;
  onPlay?: () => void;
  playbackRate?: number;
  onInitialTimestampParsed?: (timestamp: number) => void;
  wraperStyles?: React.CSSProperties;
  onTimeUpdate?: (time: number) => void;
  onStreamUrlChange?: () => void;
  isZoomPanEnabled?: boolean;
  videoZoom?: number;
  onZoom?: (zoomFactor: number) => void;
  showMinimap?: boolean;
  onVideoStateChange?: (state: "error" | "buffering" | "loading" | "ready") => void;
  areaSelector?: boolean;
  onAreaSelectionChange?: (params?: Rectangle) => void;
  areaSelection?: Rectangle;
  initialTimestamp?: number;
  paused: boolean;
  maxHeight?: string;
  roundedBottom?: boolean;
  zonesFeature?: ZonesCanvasProps
  usePanZoom?: boolean;
  rootFontSite?: number;
  onSourceLoaded?: (kvsName: string, player: any) => void;
  playbackMode: "ON_DEMAND" | "LIVE_REPLAY";
  detectionBoxesVisible?: boolean;
  poseEstimationVisible?: boolean;
  autoPlay?: boolean;
  volume?: number;
  satelliteView?: boolean;
  debug?: boolean;
  onCacheReady?: () => void;
}

export interface IKinesisPlayerHandles {
  fullscreen: () => void;
  play: () => void;
  pause: () => void;
  reload: () => void;
  zoomIn: (value?: number) => void;
  zoomOut: (value?: number) => void;
  goToTime: (time: number) => void;
  goLive: () => void;
  setZoom: (value: number) => void;
  isFullscreen: () => boolean;
}

const KinesisPlayer: React.ForwardRefRenderFunction<IKinesisPlayerHandles, KinesisPlayerProps> = (props, ref) => {
  const {
    stream,
    credentials,
    onPause,
    onPlay,
    playbackRate,
    wraperStyles,
    onInitialTimestampParsed,
    onTimeUpdate,
    onStreamUrlChange,
    onZoom,
    showMinimap,
    onVideoStateChange,
    areaSelector,
    onAreaSelectionChange,
    areaSelection,
    initialTimestamp,
    paused,
    maxHeight,
    roundedBottom,
    zonesFeature,
    usePanZoom,
    rootFontSite,
    onSourceLoaded,
    playbackMode,
    detectionBoxesVisible,
    poseEstimationVisible,
    autoPlay,
    volume,
    satelliteView,
    onCacheReady
  } = props;

  const appBarAnimationEndedAt = useSelector((store: IStoreState) => store.appView.appBarAnimationEndedAt)

  const isMounted = React.useRef<boolean>(true);
  const liveRef = React.useRef<boolean>(true);
  const videoBoundingBoxesRef = React.useRef<VideoBoundingBoxes | undefined>(undefined);
  const videoPoseEstimationsRef = React.useRef<VideoPoseEstimations | undefined>(undefined)
  const currentStreamRef = React.useRef<VideoStream | undefined>(stream);
  const videoInitializedCorrectly = React.useRef<boolean>(false);
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const windowSize = useRenderOnWindowResize();

  const videoRef = React.useRef<IBitMovinPlayerHandles | null>(null);
  const videoContainerRef = React.useRef<HTMLDivElement | null>(null)
  const startTimestampRef = React.useRef<number>(0);
  const eventContentVideoDetectionBoxesRef = React.useRef<IEventContentVideoDetectionBoxesHandles | null>(null);
  const streamSatelliteViewRef = React.useRef<IStreamSatelliteViewHandles | null>(null);
  const poseEstimationRendererRef = React.useRef<IPoseEstimationRendererHandles | null>(null);
  const detectionBoxesLoader = React.useRef<IDetectionBoxesLoaderHandles | null>(null);
  const zonesCanvasHandler = React.useRef<IZonesCanvasHandles | null>(null);
  const poseEstimationLoaderRef = React.useRef<IPoseEstimationLoaderHandles | null>(null)
  const transformRef = React.useRef<{ x: number, y: number, scale: number } | undefined>(undefined);

  const [streamUrl, setStreamUrl] = React.useState<string | undefined>(undefined);
  const [videoTimestamp, setVideoTimestamp] = React.useState<number>(Date.now());
  const [isLive, setLive] = React.useState<boolean>(true);
  const [videoState, setVideoState] = React.useState<"error" | "buffering" | "loading" | "ready">("loading");
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
  const [windowOffsetVector, setWindowOffsetVector] = React.useState<Vector2>({ x: 0, y: 0 });
  const [zoomActive, setZoomActive] = React.useState<boolean>(false);

  const [videoBoundingBoxes, setVideoBoundingBoxes] = React.useState<VideoBoundingBoxes | undefined>(undefined);
  const [videoPoseEstimations, setVideoPoseEstimations] = React.useState<VideoPoseEstimations | undefined>(undefined);

  React.useEffect(() => {
    if (videoRef.current && isNumber(volume)) {
      videoRef.current.setVolume(volume);
    }
  }, [volume])

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, [])

  React.useEffect(() => {
    if (onVideoStateChange) onVideoStateChange(videoState);
  }, [videoState, onVideoStateChange])

  React.useEffect(() => { currentStreamRef.current = stream }, [stream])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(reload, [stream, credentials])

  React.useEffect(() => {
    if (onTimeUpdate) onTimeUpdate(videoTimestamp);
  }, [videoTimestamp, onTimeUpdate])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(handleStreamUrlChange, [streamUrl])
  React.useEffect(() => { setOffset() }, [rootFontSite, videoState, appBarAnimationEndedAt, windowSize]);
  React.useEffect(() => { liveRef.current = isLive }, [isLive])
  React.useEffect(() => { videoBoundingBoxesRef.current = videoBoundingBoxes }, [videoBoundingBoxes])
  React.useEffect(() => { videoPoseEstimationsRef.current = videoPoseEstimations }, [videoPoseEstimations])

  React.useImperativeHandle(ref, () => ({
    fullscreen,
    play,
    pause,
    reload,
    zoomIn,
    zoomOut,
    goToTime,
    goLive,
    setZoom,
    isFullscreen
  } as IKinesisPlayerHandles))

  function handleStreamUrlChange() {
    if (onStreamUrlChange) onStreamUrlChange()
  }

  async function getLiveStream() {
    const target = { ...currentStreamRef.current };
    videoInitializedCorrectly.current = false;
    clearTimeout(timeoutRef.current)

    if (stream && credentials) {
      resetStreamState(true)
      setVideoTimestamp(Date.now());
      startTimestampRef.current = Date.now();
      try {
        const options = generateOptions();
        const url = await API.Kinesis.getLiveStream(stream.kvsName, options);
        if (isMounted.current && target.kvsName === currentStreamRef.current?.kvsName) setStreamUrl(url);
      } catch (error: any) {
        if (isMounted.current && target.kvsName === currentStreamRef.current?.kvsName) onUrlLoadError();
      }
    }
  }

  async function getLiveReplayStream(time: number) {
    const n = performance.now();
    console.log('Getting live replay stream...');
    const target = { ...currentStreamRef.current };
    videoInitializedCorrectly.current = false;
    clearTimeout(timeoutRef.current)

    if (stream && credentials) {
      resetStreamState(false);
      setVideoTimestamp(time);
      startTimestampRef.current = time;
      try {
        const options = generateOptions();
        const url = await API.Kinesis.getLiveReplayStream(stream.kvsName, options, new Date(time));
        if (isMounted.current && target.kvsName === currentStreamRef.current?.kvsName) setStreamUrl(url);
      } catch (error: any) {
        if (isMounted.current && target.kvsName === currentStreamRef.current?.kvsName) onUrlLoadError();
        console.log(error);
      } finally {
        console.log('Done getting live replay stream...', `${(performance.now() - n).toFixed(2)}ms`);
      }
    }
  }

  async function getOldStream(time: number) {
    const target = { ...currentStreamRef.current };
    videoInitializedCorrectly.current = false;
    clearTimeout(timeoutRef.current)

    if (stream && credentials) {
      resetStreamState(false);
      setVideoTimestamp(time);
      try {
        const options = generateOptions();
        const url = await API.Kinesis.getOnDemandStream(stream.kvsName, options, new Date(time), new Date(time + 43200000));
        startTimestampRef.current = url.start;
        if (isMounted.current && target.kvsName === currentStreamRef.current?.kvsName) setStreamUrl(url.url);
      } catch (error: any) {
        if (isMounted.current && target.kvsName === currentStreamRef.current?.kvsName) onUrlLoadError();
        console.log(error);
      }
    }
  }

  function resetStreamState(live: boolean) {
    setStreamUrl(undefined);
    setVideoState("loading");
    setLive(live);
  }

  function generateOptions() {
    return {
      credentials,
      region: process.env.REACT_APP_KINESIS_VIDEO_STREAMS_REGION
    };
  }

  function onUrlLoadError() {
    setVideoState("error");
    setStreamUrl(undefined);
  }

  function handleVideoTimeUpdate(_timestamp: number, time: number) {
    if (videoState !== "ready") setVideoState("ready");

    if (liveRef.current === false && playbackMode === "ON_DEMAND") {
      setVideoTimestamp(_timestamp)
      videoBoundingBoxesRef.current?.setCurrentTimestamp(_timestamp);
      videoPoseEstimationsRef.current?.setCurrentTimestamp(_timestamp)
    } else {
      setVideoTimestamp(time * 1000)
      videoBoundingBoxesRef.current?.setCurrentTimestamp(time * 1000);
      videoPoseEstimationsRef.current?.setCurrentTimestamp(time * 1000);
    }

    const bboxes = videoBoundingBoxesRef.current?.getCurrentBoundingBox();
    const poses = videoPoseEstimationsRef.current?.getCurrentPoseEstimations();

    if (eventContentVideoDetectionBoxesRef.current) {
      eventContentVideoDetectionBoxesRef.current.draw(bboxes, transformRef.current)
    }

    if (streamSatelliteViewRef.current) {
      if (bboxes) {
        streamSatelliteViewRef.current.draw({ ...bboxes, boxes: bboxes.boxes.map(el => ({ ...el, satelliteParameters: stream?.satelliteParameters })) })
      } else {
        streamSatelliteViewRef.current.draw(undefined)
      }
    }

    if (poseEstimationRendererRef.current) {
      poseEstimationRendererRef.current.draw(poses);
    }

    if (detectionBoxesLoader.current) {
      detectionBoxesLoader.current.setDetectionBoxes(bboxes)
    }

    if (poseEstimationLoaderRef.current) {
      poseEstimationLoaderRef.current.setPoseEstimations(poses);
    }
  }

  function fullscreen() {
    if (videoRef.current) {
      videoRef.current.enterFullScreen();
    }
  }

  function isFullscreen(): boolean {
    if (videoRef.current) {
      return videoRef.current.isFullscreen();
    }
    return false;
  }

  function play() {
    if (videoRef.current) {
      videoRef.current.play();
      if (onPlay) onPlay();
    }
  }

  function pause() {
    if (videoRef.current) {
      videoRef.current.pause();
      if (onPause) onPause();
    }
  }


  function reload() {
    setVideoState("loading");
    if (stream && credentials) {
      setStreamUrl(undefined);
      if (initialLoad === true && initialTimestamp) getOldStream(initialTimestamp);
      else if (isLive === true) getLiveStream();
      else getOldStream(videoTimestamp);

      if (initialLoad === true) setInitialLoad(false);
    }
  }

  function zoomIn(value?: number) {
    if (videoRef.current) {
      videoRef.current.zoomIn(value);
    }
  }

  function zoomOut(value?: number) {
    if (videoRef.current) {
      videoRef.current.zoomOut(value);
    }
  }

  function setZoom(value: number) {
    if (videoRef.current) {
      videoRef.current.setZoom(value);
    }
  }

  function goToTime(time: number) {
    if (playbackMode === "ON_DEMAND") {
      if (videoRef.current && startTimestampRef.current > 0 && time > startTimestampRef.current) {
        const videoDuration = videoRef.current.duration();
        const start = startTimestampRef.current;
        const end = startTimestampRef.current + (videoDuration * 1000);

        if (time >= start && time <= end) {
          const diff = (time - start) / 1000;
          videoRef.current.seek(diff);
          return;
        }
      }

      setVideoState('loading');
      getOldStream(time);
    } else {
      setVideoState('loading');
      getLiveReplayStream(time);
    }
  }

  function goLive() {
    setVideoState('loading');
    getLiveStream();
  }

  function setOffset() {
    if (isMounted.current) {
      const rect = videoRef.current?.videoRef.current?.getBoundingClientRect();
      if (rect && rect.x && rect.y) {
        if (transformRef.current) {
          setWindowOffsetVector({ x: rect.x - transformRef.current.x, y: rect.y - transformRef.current.y })
        } else {
          setWindowOffsetVector({ x: rect.x, y: rect.y })
        }
      }
    }
  }

  return (
    <div ref={videoContainerRef} style={{ ...wraperStyles, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
      <DetectionBoxesLoader
        ref={detectionBoxesLoader}
        detectionBoxesVisible={detectionBoxesVisible}
        isLive={isLive}
        streamUrl={streamUrl}
        videoTimestamp={videoTimestamp}
        stream={stream}
        videoBoundingBoxes={videoBoundingBoxes}
        onLoad={(bbox: VideoBoundingBoxes | undefined) => setVideoBoundingBoxes(bbox)}
      />
      <PoseEstimationLoader
        ref={poseEstimationLoaderRef}
        poseEstimationsVisible={poseEstimationVisible}
        isLive={isLive}
        streamUrl={streamUrl}
        videoTimestamp={videoTimestamp}
        stream={stream}
        poseEstimations={videoPoseEstimations}
        onLoad={(poses: VideoPoseEstimations | undefined) => setVideoPoseEstimations(poses)}
      />
      <AreaSelector
        active={areaSelector}
        onAreaSelectionChange={onAreaSelectionChange}
        areaSelection={areaSelection}
        containerHeight={videoRef.current?.videoRef.current?.offsetHeight || 0}
        containerWidth={videoRef.current?.videoRef.current?.offsetWidth || 0}
        videoWidth={videoRef.current?.videoRef.current?.videoWidth || 0}
        videoHeight={videoRef.current?.videoRef.current?.videoHeight || 0}
        videoState={videoState}
      />
      {poseEstimationVisible && !zoomActive &&
        <PoseEstimationRenderer
          ref={poseEstimationRendererRef}
          videoState={videoState}
          containerHeight={videoRef.current?.videoRef.current?.offsetHeight || 0}
          containerWidth={videoRef.current?.videoRef.current?.offsetWidth || 0}
          videoWidth={videoRef.current?.videoRef.current?.videoWidth || 0}
          videoHeight={videoRef.current?.videoRef.current?.videoHeight || 0}
          videoWindowOffset={windowOffsetVector}
        />
      }
      {detectionBoxesVisible === true &&
        <EventContentVideoDetectionBoxes
          ref={eventContentVideoDetectionBoxesRef}
          videoState={videoState}
          containerHeight={videoRef.current?.videoRef.current?.offsetHeight || 0}
          containerWidth={videoRef.current?.videoRef.current?.offsetWidth || 0}
          videoWidth={videoRef.current?.videoRef.current?.videoWidth || 0}
          videoHeight={videoRef.current?.videoRef.current?.videoHeight || 0}
          videoWindowOffset={windowOffsetVector}
        />
      }
      {zonesFeature &&
        <ZonesCanvas
          ref={zonesCanvasHandler}
          videoState={videoState}
          containerHeight={videoRef.current?.videoRef.current?.offsetHeight || 0}
          containerWidth={videoRef.current?.videoRef.current?.offsetWidth || 0}
          videoWidth={videoRef.current?.videoRef.current?.videoWidth || 0}
          videoHeight={videoRef.current?.videoRef.current?.videoHeight || 0}
          videoWindowOffset={windowOffsetVector}
          {...zonesFeature}
        />
      }
      {satelliteView && stream &&
        <StreamSatelliteView
          ref={streamSatelliteViewRef}
          containerHeight={videoContainerRef.current?.offsetHeight || 0}
          containerWidth={videoContainerRef.current?.offsetWidth || 0}
        />
      }
      <VideoContainer
        maxHeight={maxHeight}
        style={{
          borderBottomLeftRadius: roundedBottom === true ? '1rem' : undefined,
          borderBottomRightRadius: roundedBottom === true ? '1rem' : undefined,
          height: satelliteView ? '42.2vh' : undefined,
          width: satelliteView ? '40vw' : undefined,
          padding: '0.1rem'
        }}
      >
        <FeedbackContainer>
          {videoState === "loading" || videoState === "buffering" ? (
            <CircularProgress data-cy="video-player-circular-progress" />
          ) : null}
          {videoState === "error" ? (
            <VideocamOff style={{ justifySelf: 'center', color: 'white', width: '5rem', height: '5rem', opacity: 0.7 }} />
          ) : null}
        </FeedbackContainer>
        <BitMovinPlayer
          ref={videoRef}
          autoPlay={autoPlay || paused === true ? false : true}
          src={streamUrl}
          playbackRate={playbackRate}
          usePanZoom={usePanZoom}
          onZoom={onZoom}
          showMinimap={showMinimap}
          onBufferStalled={() => { setVideoState("buffering"); }}
          onTokenExpired={() => { reload(); }}
          onTimeChanged={handleVideoTimeUpdate}
          live={isLive}
          kvsName={stream?.kvsName || ''}
          onSourceLoaded={(kvsName, player) => {
            if (isLive === true) {
              timeoutRef.current = setTimeout(() => {
                if (videoInitializedCorrectly.current === false) {
                  reload();
                }
              }, 10000)
            }

            if (onSourceLoaded) onSourceLoaded(kvsName, player)
          }}
          paused={paused}
          onInitialTimestampParsed={(timestamp: number) => {
            videoInitializedCorrectly.current = true;
            if (onInitialTimestampParsed) {
              onInitialTimestampParsed(timestamp)
            }

            setVideoTimestamp(timestamp);
          }}
          targetTimestamp={videoTimestamp}
          onZoomActivate={(active: boolean) => {
            setZoomActive(active);
          }}
          onPanZoomMovement={(transform) => {
            transformRef.current = transform;

            if (zonesCanvasHandler.current) {
              zonesCanvasHandler.current.draw(transform);
            }

            const bboxes = videoBoundingBoxesRef.current?.getCurrentBoundingBox();
            const poses = videoPoseEstimationsRef.current?.getCurrentPoseEstimations();

            if (eventContentVideoDetectionBoxesRef.current) {
              eventContentVideoDetectionBoxesRef.current.draw(bboxes, transformRef.current)
            }

            if (poseEstimationRendererRef.current) {
              poseEstimationRendererRef.current.draw(poses);
            }

          }}
          onCacheReady={onCacheReady}
        />
      </VideoContainer>
    </div>
  )
}

export default React.forwardRef<IKinesisPlayerHandles, KinesisPlayerProps>(KinesisPlayer);
