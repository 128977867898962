import LedFeedback from '@components/common/Feedback/LedFeedback';
import { Box, Button, Card, CircularProgress, IconButton, Popover, Slider, Tooltip, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight, ExpandLess, ExpandMore, Home, HomeOutlined, Refresh, Speed, ZoomIn, ZoomOut } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import Utils from '@utils/index';
import { API } from 'aws-amplify';

type CameraSpeed = "slow" | "medium" | "fast";
type CameraSpeedSlider = 1 | 2 | 3;
type CameraDirection = "up" | "down" | "left" | "right";

const DirectionContainer = styled.div`
  position: absolute;
  width: 7.5rem;
  height: 7.5rem;
  left: calc(50% - 3.75rem);
  bottom: calc(50% - 3.75rem);
  display: flex;
  flex-direction: column;

  background: rgba(50, 50, 50, 0.0);
  border-radius: 50%;
  border: 1px solid #FFFFFF;

  .MuiSvgIcon-root {
    visibility: hidden;
  }

  :hover {
    .MuiSvgIcon-root {
      visibility: visible;
    }

    background: rgba(50, 50, 50, 0.5);
  }

  svg {
    color: white;
  }

  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;

  z-index: 5;
`

const ArrowContainer = styled.div`
  width: 100%;
  height: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ZoomContainer = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  display: grid;
  grid-template-columns: min-content min-content min-content;
  grid-gap: 1rem;
  align-items: center;

  svg {
    color: black;
  }

  .MuiButton-contained {
    background-color: rgba(255, 255, 255, 0.8) !important;
    background: rgba(255, 255, 255, 0.8) !important;

    min-width: 0 !important;
  }

  z-index: 5;
`

const SpeedContainer = styled.div`
  position: absolute;
  left: 2rem;
  bottom: 2rem;
  display: flex;
  flex-direction: row;

  .MuiButton-contained {
    min-width: 0 !important;
  }

  svg {
    color: black;
  }

  z-index: 5;
`

const AXIS_API_PATH = '/axis-cgi/com/ptz.cgi';
const AXIS_API_CONFIG_PATH = '/axis-cgi/com/ptzconfig.cgi'
const AXIS_MOVE = '?move=';
const AXIS_ZOOM = '?rzoom=';
const AXIS_SPEED = '?speed=';
const AXIS_SET_CHOME = '&home=yes'
const AXIS_SET_PRESET = '?setserverpresetname=Home'
const CAMERA_INDEX = '&camera='

interface IPTZControlProps {
  kvsName?: string;
  siteId?: string;
  rportCameraId?: string;
  onExitRequest?: () => void;
  wraperStyles?: React.CSSProperties;
  ptz?: { mjpegUrl: string, onvifPassword: string, onvifUser: string };
  isSuperAdministrator?: boolean;
}

const PTZControl: React.FC<IPTZControlProps> = (props) => {
  const {
    kvsName,
    wraperStyles,
    siteId,
    rportCameraId,
    ptz,
    isSuperAdministrator
  } = props;

  const [mjpegUrl, setMjpegUrl] = React.useState<string | undefined>(undefined);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [host, setHost] = React.useState<string>("");
  const [cameraSpeed, setCameraSpeed] = React.useState<CameraSpeed>("medium");
  const [cameraSpeedSlider, setCameraSpeedSlider] = React.useState<CameraSpeedSlider>(3);
  const [commandFeedback, setCommandFeedback] = React.useState<boolean | undefined>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [cameraIndex, setCameraIndex] = React.useState<number>(1);

  const pingIntervalRef = React.useRef<NodeJS.Timeout | undefined>(undefined);
  const hostRef = React.useRef(host);
  const ptzRef = React.useRef(ptz);
  const cameraIndexRef = React.useRef(cameraIndex);
  const rportCameraIdRef = React.useRef(rportCameraId);
  const siteIdRef = React.useRef(siteId);
  const kvsNameRef = React.useRef(kvsName);

  const speed = React.useCallback((speed: number) => {
    const url = `${hostRef.current}${AXIS_API_PATH}${AXIS_SPEED}${speed}${CAMERA_INDEX}${cameraIndexRef.current}`;
    issueCommand(url);
  }, [])

  const perform = React.useCallback(async () => {
    try {
      setError(undefined);
      const token = await getToken();
      const url = await getExposedUrl(token, rportCameraIdRef.current || "");

      let camIndex = 1;
      let fps: number | undefined | string = 5;
      let resolution: string | undefined = "1280x720";

      if (ptzRef.current && ptzRef.current.mjpegUrl) {
        camIndex = Utils.PTZ.getSubstream(ptzRef.current.mjpegUrl);
        setCameraIndex(camIndex);

        if (ptzRef.current.mjpegUrl.includes('fps=')) {
          fps = ptzRef.current.mjpegUrl.split('fps=')[1].charAt(0);
        }

        if (ptzRef.current.mjpegUrl.includes('resolution=')) {
          resolution = ptzRef.current.mjpegUrl.split('resolution=')[1];
        }
      }

      setMjpegUrl(`${url.url}/axis-cgi/mjpg/video.cgi?camera=${camIndex}&fps=${fps}&resolution=${resolution}`)

      setHost(Utils.PTZ.getHost(url.url));

      pingIntervalRef.current = setInterval(() => {
        window.fetch(url.heartbeatUrl, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      }, 6000)
    } catch (error: any) {
      setError(error.message);
      console.log(error);
    }
  }, [])

  React.useEffect(() => { siteIdRef.current = siteId }, [siteId]);
  React.useEffect(() => { kvsNameRef.current = kvsName }, [kvsName])
  React.useEffect(() => { ptzRef.current = ptz }, [ptz])
  React.useEffect(() => { hostRef.current = host }, [host]);
  React.useEffect(() => { cameraIndexRef.current = cameraIndex }, [cameraIndex]);
  React.useEffect(() => { rportCameraIdRef.current = rportCameraId }, [rportCameraId])
  React.useEffect(onMount, [kvsName, perform]);

  React.useEffect(() => {
    if (cameraSpeedSlider === 1) {
      setCameraSpeed("slow");
      speed(25);
    } else if (cameraSpeedSlider === 2) {
      setCameraSpeed("medium");
      speed(50);
    } else if (cameraSpeedSlider === 3) {
      setCameraSpeed("fast")
      speed(100);
    };
  }, [cameraSpeedSlider, setCameraSpeed, speed])

  React.useEffect(unMount, []);

  function unMount() {
    return () => {
      clearInterval(pingIntervalRef.current);
    }
  }

  function onMount() {
    perform();
  }

  async function getToken(): Promise<string> {
    try {
      const res = await API.get('api-service', `/streams/${siteIdRef.current}/${kvsNameRef.current}/rport/authorize`, {})
      return res.data.token;
    } catch (error) {
      throw new Error(Utils.Error.getErrorMessage(error));
    }
  }

  async function getExposedUrl(token: string, cameraId: string): Promise<{ url: string, heartbeatUrl: string }> {
    try {
      const result = await window.fetch(`${process.env.REACT_APP_RPORT_WRAPER_API_URL}/cameras/${cameraId}/expose`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        method: 'PUT'
      })

      const json = await result.json();
      if (json.error) {
        if (json.status_code === 400 && json.error.includes('camera') && json.error.includes('exposed')) throw new Error('Camera is being controlled by someone else')
        else throw new Error(json.error)
      }

      return {
        url: json.data.exposed_url,
        heartbeatUrl: json.data.heartbeat_url
      };
    } catch (error: any) {
      console.log(error);
      throw new Error(error.message || "Failed to connect.");
    }
  }

  function move(direction: CameraDirection) {
    const url = `${host}${AXIS_API_PATH}${AXIS_MOVE}${direction}${CAMERA_INDEX}${cameraIndex}`;
    issueCommand(url);

    if (cameraSpeed !== "fast") {
      setTimeout(() => {
        const url = `${host}${AXIS_API_PATH}${AXIS_MOVE}stop${CAMERA_INDEX}${cameraIndex}`;
        window.fetch(url, { credentials: 'include', mode: 'no-cors' })
      }, 500)
    }
  }

  function home() {
    const url = `${host}${AXIS_API_PATH}${AXIS_MOVE}home${CAMERA_INDEX}${cameraIndex}`
    issueCommand(url);
  }

  function zoom(depth: number) {
    const url = `${host}${AXIS_API_PATH}${AXIS_ZOOM}${depth}${CAMERA_INDEX}${cameraIndex}`;
    issueCommand(url);
  }

  function setCurrentPositionAsHome(): void {
    const url = `${host}${AXIS_API_CONFIG_PATH}${AXIS_SET_PRESET}${AXIS_SET_CHOME}${CAMERA_INDEX}${cameraIndex}`;
    issueCommand(url);
  }

  function issueCommand(url: string) {
    Utils.PTZ.issueCommand(url, (error: any) => {
      if (error) {
        setCommandFeedback(false);
        setTimeout(() => {
          setCommandFeedback(undefined);
        }, 500)
      } else {
        setCommandFeedback(true);
        setTimeout(() => {
          setCommandFeedback(undefined);
        }, 500)
      }
    });
  }

  function getCameraSpeedText(): string {
    if (cameraSpeedSlider === 1) return "0.5x";
    else if (cameraSpeedSlider === 2) return "1x";
    else return "2x";
  }

  function renderMainBody(): JSX.Element {
    if (error !== undefined) {
      return (
        <Box display="flex" flexDirection="column">
          <Box>
            <Typography variant="body2" style={{ color: 'white' }} >{error}</Typography>
          </Box>
          <Box display="flex" width="100%" justifyContent="center" alignItems="center">
            <IconButton onClick={perform} style={{ color: 'white' }} size="large">
              <Refresh />
            </IconButton>
          </Box>
        </Box>
      );
    }

    if (Boolean(mjpegUrl)) {
      return (<img style={{ width: '100%', height: '100%', objectFit: 'contain', position: 'absolute' }} src={mjpegUrl} alt="NOT FOUND" />);
    }

    return (<CircularProgress />)
  }

  return (
    <div style={wraperStyles}>
      <Box
        width="100%"
        height="100%"
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          bgcolor="black"
          height="100%"
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          {error === undefined ? (
            <>
              <DirectionContainer id="ptz-control-description">
                <ArrowContainer>
                  <IconButton
                    onClick={() => { move('up') }}
                  >
                    <ExpandLess />
                  </IconButton>
                </ArrowContainer>
                <ArrowContainer>
                  <IconButton
                    onClick={() => { move('left') }}
                  >
                    <ChevronLeft />
                  </IconButton>
                  <Box
                    width="50%"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <Box borderRadius="50%" padding="1rem" >
                      {commandFeedback ? (
                        <LedFeedback
                          active={commandFeedback}
                        />
                      ) : (
                        <Tooltip arrow placement="top" title="Go to home position">
                          <IconButton
                            onClick={() => { home() }}
                          >
                            <Home />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                  <IconButton
                    onClick={() => { move('right') }}
                  >
                    <ChevronRight />
                  </IconButton>
                </ArrowContainer>
                <ArrowContainer>
                  <IconButton
                    onClick={() => { move('down') }}
                  >
                    <ExpandMore />
                  </IconButton>
                </ArrowContainer>
              </DirectionContainer>
              <SpeedContainer id="ptz-control-speed-description">
                <Tooltip placement="top" arrow title="Adjust camera speed">
                  <Button onClick={(e) => setAnchorEl(e.currentTarget)} endIcon={<Speed style={{ fontSize: '1.25rem' }} />} variant="contained" data-cy="timeline-ptz-speed-button">
                    <Typography style={{ fontSize: '1rem' }} variant="subtitle2">{getCameraSpeedText()}</Typography>
                  </Button>
                </Tooltip>
              </SpeedContainer>
              <ZoomContainer id="zoom-control-description">
                {isSuperAdministrator && isSuperAdministrator === true &&
                  <Tooltip placement="top" arrow title="Set current position as home">
                    <Button onClick={() => { setCurrentPositionAsHome() }} variant="contained" data-cy="timeline-ptz-set-home-button" >
                      <HomeOutlined />
                    </Button>
                  </Tooltip>
                }
                <Tooltip placement="top" arrow title="Zoom in">
                  <Button variant="contained" onClick={() => { zoom(100) }} data-cy="timeline-ptz-zoom-in-button">
                    <ZoomIn />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" arrow title="Zoom out">
                  <Button variant="contained" onClick={() => { zoom(-100) }} data-cy="timeline-ptz-zoom-out-button">
                    <ZoomOut />
                  </Button>
                </Tooltip>
              </ZoomContainer>
            </>
          ) : null}
          {renderMainBody()}
        </Box>
      </Box >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => { setAnchorEl(null); }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Card style={{ paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '2rem', paddingRight: '2rem', width: '15vw' }}>
          <Typography variant="subtitle2">PTZ control speed</Typography>
          <Slider
            min={1}
            max={3}
            value={cameraSpeedSlider}
            onChange={(_e: object, value: any) => { setCameraSpeedSlider(value) }}
            step={1}
            marks={[
              {
                value: 1,
                label: '0.5x'
              },
              {
                value: 2,
                label: '1x'
              },
              {
                value: 3,
                label: '2x'
              }
            ]}
          />
        </Card>
      </Popover>
    </div>
  )
}

export default PTZControl;
