import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_ROLE_FILTER_TEXT: null,
  SET_USER_FILTER_TEXT: null,
});

export interface ISettingsViewState {
  roleFilterText: string;
  userFilterText: string;
}
