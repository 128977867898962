import { API } from 'aws-amplify';

async function deleteSubcontractor(
  subcontractorId: string,
  asCompany?: string
): Promise<boolean> {
  try {
    const path = `/subcontractors/${subcontractorId}` + (asCompany ? `?asCompany=${asCompany}` : "");
    const { data } = await API.del('api-service', path, {});

    return data;
  } catch (error: any) {
    throw error;
  }
}

export default deleteSubcontractor;
