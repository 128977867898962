import getTimezone from './getTimezone';
import findDuplicateName from './findDuplicateName'

export interface ISite {
  getTimezone(site?: Site): string | undefined;
  findDuplicateName(target: { companyId: string, siteId: string, name: string }, sites: Site[]): boolean
}

const Site: ISite = {
  getTimezone,
  findDuplicateName
}

export default Site;
