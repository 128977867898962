import { driver } from 'driver.js';

abstract class Tutorial {
  private _driver;;

  constructor () {
    this._driver = driver({ animate: false })
    this.setup();
  }

  protected defineSteps(args: any) {
    this._driver.setSteps(args)
  }

  public start() {
    this._driver.drive();
  }

  protected abstract setup(): void;
}

export default Tutorial;
