import React from 'react';
import API from '@API/index';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { setStreamList } from '@store/streams/actions';
import { isEqual, sortBy } from 'lodash';
import { setPermissions } from '@store/session/actions';
import { AssetsPermissions } from '@store/session/types';

/**
 * Hook that will perform an update to Redux Store (Streams) when called.
 * This hook is useful when you want to check for updates on backend and update the App State at the same time.
 * @param siteId ID of the site to perform the update.
 * @param deps Dependencies to trigger the update logic.
 * 
 * @example
 * const Component: React.FC<{}> = () => {
 *  // Updates Sites every time this component mounts.
 *  useUpdateStreams("forsight_company-staging1");
 * }
 * 
 * const Component: React.FC<{}> = () => {
 *  const [selectedSiteId, setSelectedSiteId] = React.useState<string>("forsight_company-staging1");
 *  const [selectedTab, setSelectedTab] = React.useState<number>(0);
 * 
 *  // Update Sites every time the selectedTab state changes.
 *  useUpdateStreams(selectedSiteId, [selectedTab])
 * }
 */
function useUpdateStreams(
  siteId?: string,
  companyId?: string,
  role?: string | Record<string, Record<string, string[]>>,
  userId?: string,
  deps?: React.DependencyList
) {
  const dispatch = useDispatch();
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const streamsRef = React.useRef(streams);

  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => { streamsRef.current = streams }, [streams])

  const perform = React.useCallback(() => {
    if (siteId || companyId) {
      setLoading(true);
      API.Streams.listStreams(siteId, companyId)
        .then((result: VideoStream[]) => {
          const filtered = streamsRef.current.filter(el => el.siteId !== siteId);
          const final = siteId ? filtered.concat(result) : result;
          if (isEqual(sortBy(final, ['kvsName']), sortBy(streamsRef.current, ['kvsName'])) === false) {
            dispatch(setStreamList(final));
          }

          return getPermissions(final, role, userId);
        })
        .then((permissions) => {
          dispatch(setPermissions(permissions));
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 40)
        })
    }
  }, [dispatch, role, siteId, userId, companyId])

  React.useEffect(perform, [...(deps || []), siteId, perform])
  return loading;
}

export async function getPermissions(
  streams: VideoStream[],
  role?: string | Record<string, Record<string, string[]>>,
  userId?: string
): Promise<AssetsPermissions> {
  try {
    const permissions: AssetsPermissions = {};

    if (role === 'superadmin' || role === 'admin') {
      streams.forEach(stream => permissions[stream.kvsName] = ["ALL"]);
    } else {
      const apiPermissions = await Promise.all(streams.map(stream => API.Permissions.listPermissionsForStream(userId || "", stream.kvsName)));
      apiPermissions.forEach(element => permissions[element.kvsName] = element.permissions);
    }

    return permissions;
  } catch (e) {
    throw e;
  }
}

export default useUpdateStreams;
