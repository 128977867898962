const theme = {
  "breakpoints": {
    "keys": [
      "xs",
      "sm",
      "md",
      "lg",
      "xl"
    ],
    "values": {
      "xs": 0,
      "sm": "37.5rem",
      "md": "60rem",
      "lg": "80rem",
      "xl": "120rem"
    },
    "unit": "px"
  },
  "direction": "ltr",
  "mixins": {
    "toolbar": {
      "minHeight": "3.5rem",
      "@media (min-width:0px) and (orientation: landscape)": {
        "minHeight": "3rem"
      },
      "@media (min-width:600px)": {
        "minHeight": "4rem"
      }
    }
  },
  "components": {
    MuiCssBaseline: {
      '@global': {
        '::-webkit-scrollbar': {
          width: "3px",
          height: "11px",
          borderRight: "1px solid #FFFFFF1F",
          borderLeft: "1px solid #FFFFFF1F"
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: "#8D8D8D61"
        }
      }
    },
    MuiButton: {
      "styleOverrides": {
        root: {
          minWidth: "unset",
          minHeight: "unset",
          height: "2.5rem",
        },
        sizeMedium: {
          padding: "0.4rem 1rem"
        },
        contained: {
          boxShadow: "none"
        }
      }
    },
    MuiCard: {
      "styleOverrides": {
        root: {
          width: "100%",
        }
      }
    },
    MuiMenuItem: {
      "styleOverrides": {
        root: {
          alignItems: "center"
        }
      }
    },
    MuiSlider: {
      "styleOverrides": {
        thumb: {
          background: "#FFFFFF !important",
          border: "0.5px solid rgba(0, 0, 0, 0.04) !important",
          boxShadow: "0 3px 0.5rem rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.16), 0 3px 1px rgba(0, 0, 0, 0.1) !important",
          bordeRadius: "6.25rem !important",
          width: "12px !important;",
          height: "12px !important",
        }
      }
    },
    MuiTab: {
      "styleOverrides": {
        root: {
          minWidth: '0px !important',
          '&:hover': {
            color: '#0048E3 !important'
          },
          padding: "10px 12px"
        }
      }
    },
    MuiTableRow: {
      "styleOverrides": {
        root: {
          "&:last-child td": {
            borderBottom: 0,
          },
        }
      }
    },
    MuiFormControl: {
      "styleOverrides": {
        root: {
          marginTop: '1.5rem'
        },
        marginDense: {
          marginTop: '1.5rem'
        }
      }
    },
    MuiOutlinedInput: {
      "styleOverrides": {
        notchedOutline: {
          height: '2.5rem',
          top: 0,
          "legend": {
            display: "none",
          },
          "input": {
            padding: "8px 14px"
          }
        },
        input: {
          padding: "7px 14px"
        }
      }
    },
    MuiInputBase: {
      "styleOverrides": {
        root: {
          height: "2.5rem"
        }
      }
    },
    MuiInputLabel: {
      "styleOverrides": {
        outlined: {
          transform: 'translate(0, -1.5rem)',
          fontSize: '0.875rem',
          fontFamily: 'Roboto',
          fontWeight: 700,
          lineHeight: 1.57,
          letterSpacing: '0.00714em',
          color: 'unset',
          '&$shrink': {
            transform: 'translate(0, -1.5rem)',
            fontSize: '0.875rem',
            fontFamily: 'Roboto',
            fontWeight: 700,
            lineHeight: 1.57,
            letterSpacing: '0.00714em',
            color: 'unset'
          },
        },
        marginDense: {
          transform: 'translate(0, -1.5rem) !important',
          fontSize: '0.875rem',
          fontFamily: 'Roboto',
          fontWeight: 700,
          lineHeight: 1.57,
          letterSpacing: '0.00714em',
          color: 'unset'
        }
      }
    },
    MuiChip: {
      "styleOverrides": {
        root: {
          borderRadius: '0.25rem',
          margin: '0.2rem'
        },
        sizeMedium: {
          height: '1.75rem',
        }
      }
    },
    MuiAutocomplete: {
      "styleOverrides": {
        tag: {
          marginTop: 0
        },
        inputRoot: {
          fontSize: "0.75rem"
        },
        endAdornment: {
          top: "calc(50% - 0.9rem)"
        },
      }
    },
    MuiListItem: {
      "styleOverrides": {
        root: {
          "&$selected": {
            backgroundColor: 'transparent !important',
            color: "#0048e3 !important",
            "&:hover": {
              backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
            }
          },
          "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.08) !important'
          },
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        },
        gutters: {
          paddingLeft: "1rem"
        }
      }
    },
    MuiListItemIcon: {
      "styleOverrides": {
        root: {
          minWidth: "3.5rem"
        }
      }
    },
    MuiSelect: {
      "styleOverrides": {
        select: {
          paddingTop: "0.625rem",
          paddingBottom: "0.625rem",
          paddingRight: "1.5rem !important"
        },
        icon: {
          top: "calc(50% - 0.75rem)"
        }
      }
    },

    MuiDialog: {
      "styleOverrides": {
        paper: {
          borderRadius: 12
        }
      }
    },
    MuiSwitch: {
      "styleOverrides": {
        root: {
          "@media only screen and (min-width: 2560px)": {
            transform: "scale(1.5)"
          },
          "@media only screen and (min-width: 3200px)": {
            transform: "scale(2)"
          }
        }
      }
    },
    MuiDataGrid: {
      "styleOverrides": {
        root: {
          fontSize: "inherit"
        },
        main: {
          borderRadius: "10px"
        },
        overlay: {
          backgroundColor: 'transparent'
        }
      }
    },

    MuiIconButton: {
      "styleOverrides": {
        sizeSmall: {
          padding: "3px"
        }
      }
    },
    MuiPickersSlideTransition: {
      "styleOverrides": {
        root: {
          minHeight: '17rem !important'
        }
      }
    },

    MuiDateCalendar: {
      "styleOverrides": {
        root: {
          width: '20rem',
          maxHeight: '20rem'
        }
      }
    },

    MuiYearCalendar: {
      "styleOverrides": {
        root: {
          width: '20rem',
          maxHeight: '15rem'
        }
      }
    },

    MuiMonthCalendar: {
      "styleOverrides": {
        root: {
          width: '20rem',
          maxHeight: '15rem'
        }
      }
    },

    MuiPickersLayout: {
      "styleOverrides": {
        root: {
          "ul": {
            maxHeight: '24rem !important'
          }
        },
        contentWrapper: {
          height: 'fit-content'
        },
        shortcuts: {
          maxHeight: '24rem !important'
        }
      }
    },

    MuiPickersCalendarHeader: {
      "styleOverrides": {
        root: {
          minHeight: '1.875rem',
          maxHeight: '1875rem'
        }
      }
    },

    MuiDayCalendar: {
      "styleOverrides": {
        weekDayLabel: {
          width: '2.25rem',
          height: '2.25rem'
        }
      }
    },
    MuiPickersYear: {
      "styleOverrides": {
        yearButton: {
          width: '4rem',
          height: '2.25rem'
        }
      }
    },
    MuiPickersMonth: {
      "styleOverrides": {
        monthButton: {
          width: '4rem',
          height: '2.25rem'
        }
      }
    },
    MuiPickersDay: {
      "styleOverrides": {
        root: {
          width: '2.25rem',
          height: '2.25rem'
        }
      }
    },

    MuiPickersToolbar: {
      "styleOverrides": {
        root: {
          padding: '1rem !important'
        }
      }
    },

    MuiDateRangeCalendar: {
      "styleOverrides": {
        monthContainer: {
          width: '23rem !important'
        }
      }
    }
  },
  "palette": {
    "mode": "light",
    "common": {
      "black": "#1D1D1D",
      "white": "#fff"
    },
    "primary": {
      "light": "#5f8dee",
      "main": "#0048e3",
      "dark": "#00287f",
      "contrastText": "#fff"
    },
    "secondary": {
      "light": "#a2a2a2",
      "main": "#323232",
      "dark": "#000000",
      "contrastText": "#fff"
    },
    "error": {
      "light": "#d97060",
      "main": "#bf260e",
      "dark": "#6b1507",
      "contrastText": "#fff"
    },
    "warning": {
      "light": "#ffb74d",
      "main": "#ffa720",
      "dark": "#8f5d13",
      "contrastText": "#000000"
    },
    "info": {
      "light": "rgb(41, 182, 246)",
      "main": "rgb(41, 182, 246)",
      "dark": "#00287f",
      "contrastText": "#fff"
    },
    "success": {
      "light": "#52a982",
      "main": "#107145",
      "dark": "#093f27",
      "contrastText": "#fff"
    },
    "grey": {
      "50": "#F3F6F9",
      "100": "#E7EBF0",
      "200": "#E0E3E7",
      "300": "#CDD2D7",
      "400": "#B2BAC2",
      "500": "#A0AAB4",
      "600": "#6F7E8C",
      "700": "#3E5060",
      "800": "#2D3843",
      "900": "#1A2027",
      "A100": "#f5f5f5",
      "A200": "#eeeeee",
      "A400": "#bdbdbd",
      "A700": "#616161"
    },
    "contrastThreshold": 3,
    "tonalOffset": 0.2,
    "text": {
      "primary": "#1A2027",
      "secondary": "#3E5060",
      "disabled": "rgba(0, 0, 0, 0.38)",
      "hint": "rgba(0, 0, 0, 0.38)"
    },
    "divider": "rgba(0, 0, 0, 0.12)",
    "background": {
      "paper": "#ffffff",
      "default": "#0048e3"
    },
    "action": {
      "active": "rgba(0, 0, 0, 1)",
      "hover": "rgba(0, 0, 0, 0.04)",
      "hoverOpacity": 0.04,
      "selected": "rgba(0, 0, 0, 0.08)",
      "selectedOpacity": 0.08,
      "disabled": "rgba(0, 0, 0, 0.26)",
      "disabledBackground": "rgba(0, 0, 0, 0.12)",
      "disabledOpacity": 0.38,
      "focus": "rgba(0, 0, 0, 0.12)",
      "focusOpacity": 0.12,
      "activatedOpacity": 0.12
    }
  },
  "props": {},
  "shadows": [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
  ],
  "typography": {
    "htmlFontSize": 16,
    "fontFamily": "Roboto",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "fontWeightBold": 700,
    "h1": {
      "fontFamily": "Roboto",
      "fontWeight": 300,
      "fontSize": "6rem",
      "lineHeight": 1.167,
      "letterSpacing": "-0.01562em"
    },
    "h2": {
      "fontFamily": "Roboto",
      "fontWeight": 300,
      "fontSize": "3.75rem",
      "lineHeight": 1.2,
      "letterSpacing": "-0.00833em"
    },
    "h3": {
      "fontFamily": "Roboto",
      "fontWeight": 400,
      "fontSize": "3rem",
      "lineHeight": 1.167,
      "letterSpacing": "0em"
    },
    "h4": {
      "fontFamily": "Roboto",
      "fontWeight": 700,
      "fontSize": "2.125rem",
      "lineHeight": 1.235,
      "letterSpacing": "0.00735em"
    },
    "h5": {
      "fontFamily": "Roboto",
      "fontWeight": 700,
      "fontSize": "1.5rem",
      "lineHeight": 1.334,
      "letterSpacing": "0em"
    },
    "h6": {
      "fontFamily": "Roboto",
      "fontWeight": 500,
      "fontSize": "1.25rem",
      "lineHeight": 1.6,
      "letterSpacing": "0.0075em"
    },
    "subtitle1": {
      "fontFamily": "Roboto",
      "fontWeight": 700,
      "fontSize": "1rem",
      "lineHeight": 1.75,
      "letterSpacing": "0.00938em"
    },
    "subtitle2": {
      "fontFamily": "Roboto",
      "fontWeight": 700,
      "fontSize": "0.875rem",
      "lineHeight": 1.57,
      "letterSpacing": "0.00714em"
    },
    "body1": {
      "fontFamily": "Roboto",
      "fontWeight": 400,
      "fontSize": "1rem",
      "lineHeight": 1.5,
      "letterSpacing": "0.00938em"
    },
    "body2": {
      "fontFamily": "Roboto",
      "fontWeight": 400,
      "fontSize": "0.875rem",
      "lineHeight": 1.43,
      "letterSpacing": "0.01071em"
    },
    "button": {
      "fontFamily": "Roboto",
      "fontWeight": 500,
      "fontSize": "0.875rem",
      "lineHeight": 1.75,
      "letterSpacing": "0.02857em",
      "textTransform": "none"
    },
    "caption": {
      "fontFamily": "Roboto",
      "fontWeight": 400,
      "fontSize": "0.75rem",
      "lineHeight": 1.66,
      "letterSpacing": "0.03333em"
    },
    "overline": {
      "fontFamily": "Roboto",
      "fontWeight": 400,
      "fontSize": "0.75rem",
      "lineHeight": 2.66,
      "letterSpacing": "0.08333em",
      "textTransform": "uppercase"
    },
    "chip": {
      "textTransform": "none"
    }
  },
  "shape": {
    "borderRadius": 10
  },
  "transitions": {
    "easing": {
      "easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
      "easeOut": "cubic-bezier(0.0, 0, 0.2, 1)",
      "easeIn": "cubic-bezier(0.4, 0, 1, 1)",
      "sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
    },
    "duration": {
      "shortest": 150,
      "shorter": 200,
      "short": 250,
      "standard": 300,
      "complex": 375,
      "enteringScreen": 225,
      "leavingScreen": 195
    }
  },
  "zIndex": {
    "mobileStepper": 1000,
    "speedDial": 1050,
    "appBar": 1100,
    "drawer": 1200,
    "modal": 1300,
    "snackbar": 1400,
    "tooltip": 1500
  },
  spacing: factor => `${factor}rem`,
}

export default theme;