import React from 'react';
import API from '@API/index';
import { ICredentials } from '@store/session/types';
import { first, last } from 'lodash';

type StreamUptimeLoaderProps = {
  stream: VideoStream;
  onLoadedStreamUptime: (uptime: VideoStreamTimeChunk[]) => void;
  onError?: (error: any) => void;
  credentials?: ICredentials;
  uptime?: VideoStreamTimeChunk[];
}

export interface IStreamUptimeLoaderHandles {
  getStreamUptime: (stream: VideoStream) => Promise<void>;
}

const StreamUptimeLoader: React.ForwardRefRenderFunction<IStreamUptimeLoaderHandles, StreamUptimeLoaderProps> = (props, ref) => {
  const { stream, onLoadedStreamUptime, onError, credentials, uptime } = props;

  const intervalRef = React.useRef<any>(null);
  const streamUptimeRef = React.useRef<VideoStreamTimeChunk[]>([]);
  const streamRef = React.useRef(stream);

  React.useEffect(() => { if (uptime) streamUptimeRef.current = uptime }, [uptime])
  React.useEffect(() => { streamRef.current = stream }, [stream])
  React.useEffect(() => { credentialsRef.current = credentials }, [credentials])

  React.useImperativeHandle(ref, () => ({
    getStreamUptime: async (stream: VideoStream) => { getStreamUptime(stream) }
  }))

  const onMount = React.useCallback(() => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(async () => {
      const { kvsName } = streamRef.current;

      try {
        const NOW = Date.now();
        const ONE_MINUTES_AGO = NOW - 60000;
        const KINESIS_API_NAME = "LIST_FRAGMENTS";
        const LIST_FRAGMENT_OPTIONS = {
          startOffset: 0,
          endOffset: 0,
          maxResults: 25
        }

        const OPTIONS = {
          region: process.env.REACT_APP_KINESIS_VIDEO_STREAMS_REGION,
          credentials: credentialsRef.current
        }

        const dataEndpoint = await API.Kinesis.getDataEndpoint(
          kvsName,
          OPTIONS,
          KINESIS_API_NAME
        )

        const res = await API.Kinesis.listStreamFragments(
          kvsName,
          OPTIONS,
          dataEndpoint,
          new Date(ONE_MINUTES_AGO),
          new Date(NOW),
          LIST_FRAGMENT_OPTIONS
        )

        if (res.length === 0) return;

        const latestFragment = res.length === 1 ? first(res) : res.reduce((maxValue, currentObject) => {
          if (currentObject.ProducerTimestamp && maxValue.ProducerTimestamp) {
            if (currentObject.ProducerTimestamp > maxValue.ProducerTimestamp) {
              return currentObject
            }
          }

          return maxValue;
        })

        if (latestFragment) {
          const targetTimestamp = latestFragment.ProducerTimestamp || latestFragment.ServerTimestamp;

          if (targetTimestamp && streamUptimeRef.current) {
            const newUptime = [...streamUptimeRef.current];
  
            const lastUptime = last(newUptime);
  
            if (lastUptime) {
              lastUptime.end = targetTimestamp;
              onLoadedStreamUptimeRef.current(newUptime);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 15000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  React.useEffect(onMount, [onMount])

  const getStreamUptime = React.useCallback(async (stream: VideoStream) => {
    const target = { ...currentStreamRef.current };

    try {
      const data = await API.Kinesis.getStreamUptime(stream, credentialsRef.current)
      if (target.kvsName === currentStreamRef.current.kvsName) onLoadedStreamUptimeRef.current(data);
    } catch (error) {
      if (onErrorRef.current && target.kvsName === currentStreamRef.current.kvsName) onErrorRef.current(error)
    }
  }, [])

  const currentStreamRef = React.useRef<VideoStream>(stream);
  const credentialsRef = React.useRef(credentials);
  const onLoadedStreamUptimeRef = React.useRef(onLoadedStreamUptime)
  const onErrorRef = React.useRef(onError);

  React.useEffect(() => { onErrorRef.current = onError }, [onError])
  React.useEffect(() => { onLoadedStreamUptimeRef.current = onLoadedStreamUptime }, [onLoadedStreamUptime])
  React.useEffect(() => { credentialsRef.current = credentials }, [credentials])
  React.useEffect(() => { currentStreamRef.current = stream }, [stream])

  React.useEffect(() => {
    getStreamUptime(stream);
  }, [credentials, getStreamUptime, stream]);

  return null;
}

export default React.forwardRef(StreamUptimeLoader);
