import { Endpoint, KinesisVideoArchivedMedia } from "aws-sdk";

function getHLSStreamingSessionURL(
  streamName: string,
  options: KinesisVideoArchivedMedia.ClientConfiguration,
  dataEndpoint: string,
  videoOptions: KinesisVideoArchivedMedia.GetHLSStreamingSessionURLInput
): Promise<string> {
  const kinesisVideoArchivedContent = new KinesisVideoArchivedMedia(options);

  kinesisVideoArchivedContent.endpoint = new Endpoint(dataEndpoint);

  return new Promise((resolve, reject) => {
    kinesisVideoArchivedContent.getHLSStreamingSessionURL(
      {
        StreamName: streamName,
        Expires: 43200,
        ...videoOptions
      },
      function (err, response) {
        if (err) {
          return reject(err);
        }

        resolve(response.HLSStreamingSessionURL || "");
      }
    );
  });
}

export default getHLSStreamingSessionURL;
