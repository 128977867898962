import { IStoreState } from '@store/index';
import { setSelectedCompany } from '@store/selectedCompanies/actions';
import { setSelectedSite } from '@store/selectedSites/actions';
import { setActiveStream } from '@store/selectedStream/actions';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Utils from '@utils/index';
import { PageView } from '@store/session/types';
import { setSelectedView } from '@store/views/viewsView/actions';
import { selectIsSuperAdminUser } from '@store/selectors';

type Selection = {
  company?: string;
  site?: string;
  stream?: string;
  view?: string;
  tab?: string;
}

/**
 * Component that handles state change in the Redux Store
 * and propagates accordindly on the browser history/url.
 * Responsabilities:
 * - When location (Browser URL) ?company= changes, dispatch it to Redux Store as selected company.
 * - When location (Browser URL) ?site= changes, dispatch it to Redux Store as selected site.
 * - When location (Browser URL) ?stream= changes, dispatch it to Redux Store as selected stream.
 * - When selected Company Changes on redux store, propagate it to the History.
 * - When selected Site Changes on redux store, propagate it to the History.
 * - When selected Stream Changes on redux store, propagate it to the History.
 * @returns null
 */
const LocationHandler: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);
  const selectedStream = useSelector((store: IStoreState) => store.selectedStream.stream);
  const selectedView = useSelector((store: IStoreState) => store.viewsView.selectedView);
  const companies = useSelector((store: IStoreState) => store.companies.companies);
  const sites = useSelector((store: IStoreState) => store.sites.sites);
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const activePage = useSelector((store: IStoreState) => store.session.activePage);
  const phone1 = useSelector((store: IStoreState) => store.session.userData?.communication?.phone1);
  const isLoadingBasicData = useSelector((store: IStoreState) => store.appView.isLoadingBasicData);
  const views = useSelector((store: IStoreState) => store.grids.grids);
  const isSuperAdministrator = useSelector(selectIsSuperAdminUser);

  const isReady = React.useRef<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleSelectionChange, [selectedCompany, selectedSite, selectedStream, selectedView, activePage, isLoadingBasicData]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleLocationChange, [location, isLoadingBasicData])

  /**
   * Triggered every time there is a selection change on redux (Company, Site, Stream or View).
   * Applies new selection to the Browser URL.
   */
  function handleSelectionChange(): void {
    try {
      if (registerViewActive() || isDataLoading()) return;

      const selection: Partial<Selection> = getCurrentReduxSelection();
      const search: Selection = getCurrentSearchParams();

      const pathname: string = buildBasePath();

      // We do not want to add duplicate entry in the history, only change
      // when the site, company, stream or view is different
      if (isSelectionDifferent(search, selection) || pathname !== location.pathname) {
        // Removing stream from search params if the user is not currently on the timeline.
        if (pathname !== '/timeline' && pathname !== '/camera') delete selection.stream;

        // Removing view from params if the user is not currently on the views tab.
        if (pathname !== '/cameras') delete selection.view;

        if (pathname === '/overview') delete selection.site;
        //if (search.tab) selection.tab = search.tab;

        navigate({
          ...location,
          pathname: pathname,
          search: `?${new URLSearchParams({ ...selection })}`
        })
      }
    } catch (error: any) {
      console.log('<LocationHandler /> Error');
      console.log("Error while performing handleSelectionChange");
      console.log(error);
    }
  }

  /**
   * Triggered every time the Browser URL changes, update redux selection
   * to match URL params.
   */
  function handleLocationChange(): void {
    if (isLoadingBasicData === true) return;

    const search: Selection = getCurrentSearchParams();

    updateCompanySelection(search);
    updateSiteSelection(search);
    updateStreamSelection(search);
    updateViewSelection(search);

    isReady.current = true;
  }

  function isDataLoading(): boolean { return isLoadingBasicData === true || isReady.current === false; }
  function registerViewActive(): boolean { return location.pathname.split("/")[1] === 'register'; }
  function isPhoneNotVerified(phone?: string): boolean { return phone === undefined || phone === "" }
  function pageRequireSiteSelection(activePage: PageView): boolean { return ![PageView.Companies, PageView.Sites, PageView.MyAccount, PageView.Site, PageView.Organization, PageView.None, PageView.Policy, PageView.Management, PageView.Workers, PageView.WorkersCertifications, PageView.FlaggedWorkers, PageView.ImageMatch, PageView.Attendance, PageView.Map, PageView.Health, PageView.Events].includes(activePage) }
  function isSiteSelected(site?: string, selectedSite?: Site): boolean { return site !== undefined && selectedSite !== undefined }

  function shouldRedirectUserToDefaultPage(pathname: string, selectedSite?: Site): boolean {
    if (activePage === PageView.Site && Boolean(selectedSite)) return false;
    if (pathname === '/get-started' && Boolean(selectedSite)) return true;

    return false;
  };

  function getActiveViewPathname(activePage: PageView, currentPath: string): string {
    if (activePage === PageView.Timelapse) return '/timelapses';
    if (activePage === PageView.Streams) return '/cameras';
    if (activePage === PageView.Player) return '/timeline';
    if (activePage === PageView.Actions) return '/actions';
    if (activePage === PageView.Metrics) return '/metrics';
    if (activePage === PageView.Organization) return '/organization';
    if (activePage === PageView.Site) return '/site';
    if (activePage === PageView.Camera) return '/camera';
    if (activePage === PageView.MyAccount) return '/my-account';
    if (activePage === PageView.Analytics) return '/analytics';
    if (activePage === PageView.GetStarted) return '/get-started';
    if (activePage === PageView.Exports) return '/exports';
    if (activePage === PageView.Satellite) return '/satellite'
    if (activePage === PageView.Sites) return '/sites';
    if (activePage === PageView.Map) return '/map';
    if (activePage === PageView.Events) return '/events';
    if (activePage === PageView.Management) return '/management';
    if (activePage === PageView.Attendance) return '/workers/attendance';
    if (activePage === PageView.FlaggedWorkers) return '/workers/flagged';
    if (activePage === PageView.ImageMatch) return '/workers/image_match';
    if (activePage === PageView.Health) return '/health';
    if (activePage === PageView.Companies) return '/companies'
    if (activePage === PageView.Vehicles) return '/vehicles'
    if (activePage === PageView.Policy) return '/policy';
    if (activePage === PageView.Home) return '/';
    return currentPath;
  }

  function buildBasePath(): string {
    const currentSearch: URLSearchParams = new URLSearchParams(location.search);
    const site: string | undefined = currentSearch.get("site") || undefined;

    let pathname: string = location.pathname;

    if (pathname === '/policy') return '/policy'

    if (isPhoneNotVerified(phone1)) pathname = '/my-account';
    else if (shouldRedirectUserToDefaultPage(location.pathname, selectedSite)) {
      if (Boolean(selectedCompany && selectedSite)) {
        pathname = '/cameras';
      } else if (Boolean(selectedCompany)) {
        pathname = '/events';
      }
    }
    else if (activePage === PageView.Events && selectedSite === undefined) pathname = "/events";
    else if (pageRequireSiteSelection(activePage) && isSiteSelected(site, selectedSite) === false) pathname = '/get-started';
    else pathname = getActiveViewPathname(activePage, pathname);

    return pathname;
  }

  function getCurrentReduxSelection(): Selection {
    let search: any = {};

    if (selectedCompany) search.company = selectedCompany.companyId;
    if (selectedSite) search.site = selectedSite.siteId.split("-")[1];
    if (selectedStream) search.stream = Utils.Stream.getIdsFromStreamName(selectedStream.kvsName).streamId;
    if (selectedView) search.view = selectedView.id;

    return search;
  }

  function getCurrentSearchParams(): Selection {
    const currentSearch: URLSearchParams = new URLSearchParams(location.search);

    const stream: string | undefined = currentSearch.get("stream") || undefined;
    const site: string | undefined = currentSearch.get("site") || undefined;
    const company: string | undefined = currentSearch.get("company") || undefined;
    const view: string | undefined = currentSearch.get("view") || undefined;
    const tab: string | undefined = currentSearch.get("tab") || undefined;

    return { company, site, stream, view, tab };
  }

  function isSelectionDifferent(search: Selection, selection: Selection): boolean {
    return search.stream !== selection.stream ||
      search.site !== selection.site ||
      search.company !== selection.company ||
      search.view !== selection.view;
  }

  function updateCompanySelection(search: Selection): void {
    const targetCompany = companies.find(element => element.companyId === search.company);

    // Do not allow to set company as undefined for regular users that are not sudos.
    if (isSuperAdministrator && targetCompany !== selectedCompany) dispatch(setSelectedCompany(targetCompany));
    else if (targetCompany && targetCompany !== selectedCompany) dispatch(setSelectedCompany(targetCompany));
  }

  function updateSiteSelection(search: Selection): void {
    const siteGroup = sites.get(search?.company || "");
    const targetSite = siteGroup?.find(element => element.siteId === `${search.company}-${search.site}`);
    if (targetSite !== selectedSite) dispatch(setSelectedSite(targetSite));
  }

  function updateStreamSelection(search: Selection): void {
    if (search.stream && (location.pathname === '/timeline' || location.pathname === '/camera')) {
      const targetStream = streams.find(element => element.kvsName.includes(search.stream || ""));
      if (targetStream && targetStream !== selectedStream) dispatch(setActiveStream(targetStream));
    }
  }

  function updateViewSelection(search: Selection): void {
    if (search.company && location.pathname === '/cameras' && search.view) {
      const companyViews = views[search.company];
      if (companyViews) {
        const targetView = companyViews.find(el => el.id === search.view);
        if (targetView && targetView !== selectedView) dispatch(setSelectedView(targetView));
      }
    } else {
      if (selectedView !== undefined) dispatch(setSelectedView(undefined));
    }
  }

  return null;
}

export default LocationHandler;
