import { API } from "aws-amplify";

async function updateScheduleTemplate(scheduleTemplate: ScheduleTemplate): Promise<ScheduleTemplate> {
  try {
    const path: string = `/scheduleTemplate`;

    const options = {
      body: scheduleTemplate
    };

    const data = await API.put(
      'api-service',
      path,
      options
    );

    
    return data.data as ScheduleTemplate;
  } catch (error: any) {
    throw error;
  }
}

export default updateScheduleTemplate;
