import React from 'react';
import API from '@API/index';

type ScheduleTemplatesLoaderProps = {
  stream: VideoStream;
  onLoadStart?: () => void;
  onLoaded?: (data: ScheduleTemplate[]) => void;
  onError?: (error: any) => void;
}

const ScheduleTemplatesLoader: React.FC<ScheduleTemplatesLoaderProps> = (props) => {
  const { onLoadStart, onLoaded, onError, stream } = props;

  const currentStreamRef = React.useRef<VideoStream>(stream);

  const onLoadStartRef = React.useRef(onLoadStart)
  const onLoadedRef = React.useRef(onLoaded)
  const onErrorRef = React.useRef(onError)
  const siteIdRef = React.useRef(stream.siteId);

  const perform = React.useCallback(async () => {
    const target = {...currentStreamRef.current};

    try {
      if (onLoadStartRef.current) onLoadStartRef.current();
      const data: ScheduleTemplate[] = await API.ScheduleTemplates.listScheduleTemplates(siteIdRef.current);
      if (onLoadedRef.current && currentStreamRef.current.kvsName === target.kvsName) onLoadedRef.current(data);
    } catch (error) {
      if (onErrorRef.current && currentStreamRef.current.kvsName === target.kvsName) onErrorRef.current(error);
    }
  }, [])

  React.useEffect(() => { siteIdRef.current = stream.siteId }, [stream.siteId]);
  React.useEffect(() => { onLoadStartRef.current = onLoadStart }, [onLoadStart])
  React.useEffect(() => { onLoadedRef.current = onLoaded }, [onLoaded])
  React.useEffect(() => { currentStreamRef.current = stream }, [stream])
  React.useEffect(() => { onErrorRef.current = onError }, [onError])
  React.useEffect(load, [stream, perform]);

  function load() { perform(); }
  
  return null;
}

export default ScheduleTemplatesLoader;
