import Utils from "@utils/index";
import { ActionTypes, IExportsState } from "./types";
import * as actions from './actions';
import * as uid from 'uuid';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IExportsState = {
  requests: [],
  downloadRequests: [],
  videoClips: {},
  timelapseRequests: [],
  timelapses: {}
}

export const exportsReducer = Utils.Redux.createReducer<
  IExportsState,
  Types,
  Actions
>(initialState, {
  [ActionTypes.POST_REQUEST]: (state, value) => ({
    ...state,
    requests: [{
      requestId: uid.v4(),
      kvsName: value.stream.kvsName,
      companyId: value.stream.companyId,
      siteId: value.stream.siteId,
      timerange: value.timerange,
      progress: 0,
      error: false,
      url: "",
      streamName: value.stream.defaultName ? value.stream.defaultName : value.stream.kvsName,
      canceled: false,
      options: value.options,
      name: value.name,
      description: value.description
    }, ...state.requests]
  }),

  [ActionTypes.UPDATE_REQUEST_LINK]: (state, value) => ({
    ...state,
    requests: updateLink<ExportRequest>(state.requests, value.requestId, value.url)
  }),

  [ActionTypes.SET_REQUEST_AS_DONE]: (state, value) => ({
    ...state,
    requests: changeToDone<ExportRequest>(state.requests, value.requestId, value.exportId)
  }),

  [ActionTypes.SET_REQUEST_AS_ERROR]: (state, value) => ({
    ...state,
    requests: changeToError<ExportRequest>(state.requests, value.requestId)
  }),

  [ActionTypes.SET_REQUEST_AS_CANCELED]: (state, value) => ({
    ...state,
    requests: changeToCanceled<ExportRequest>(state.requests, value.requestId)
  }),

  [ActionTypes.POST_DOWNLOAD_REQUEST]: (state, value) => ({
    ...state,
    downloadRequests: [{
      requestId: uid.v4(),
      progress: 0,
      error: false,
      canceled: false,
      exportRequest: value.exportRequest
    }, ...state.downloadRequests
    ]
  }),

  [ActionTypes.SET_DOWNLOAD_REQUEST_AS_DONE]: (state, value) => ({
    ...state,
    downloadRequests: changeToDone<DownloadRequest>(state.downloadRequests, value.requestId)
  }),

  [ActionTypes.SET_DOWNLOAD_REQUEST_AS_CANCELED]: (state, value) => ({
    ...state,
    downloadRequests: changeToCanceled<DownloadRequest>(state.downloadRequests, value.requestId)
  }),

  [ActionTypes.SET_DOWNLOAD_REQUEST_AS_ERROR]: (state, value) => ({
    ...state,
    downloadRequests: changeToError<DownloadRequest>(state.downloadRequests, value.requestId)
  }),

  [ActionTypes.SET_VIDEO_CLIPS_FOR_CAMERA]: (state, value) => ({
    ...state,
    videoClips: { ...state.videoClips, [value.kvsName]: value.videoClips }
  }),

  [ActionTypes.SET_TIMELAPSES_FOR_CAMERA]: (state, value) => ({
    ...state,
    timelapses: { ...state.timelapses, [value.kvsName]: value.timelapses }
  }),

  [ActionTypes.POST_TIMELAPSE_REQUEST]: (state, value) => ({
    ...state,
    timelapseRequests: [{
      requestId: uid.v4(),
      progress: 0,
      error: false,
      canceled: false,
      params: value.params,
      kvsName: value.kvsName,
      url: "",
      name: value.params.name,
      description: value.params.description
    }]
  }),

  [ActionTypes.SET_TIMELAPSE_REQUEST_AS_DONE]: (state, value) => ({
    ...state,
    timelapseRequests: changeToDone<TimelapseRequest>(state.timelapseRequests, value.requestId, value.exportId)
  }),

  [ActionTypes.SET_TIMELAPSE_REQUEST_AS_ERROR]: (state, value) => ({
    ...state,
    timelapseRequests: changeToError<TimelapseRequest>(state.timelapseRequests, value.requestId)
  }),

  [ActionTypes.SET_TIMELAPSE_REQUEST_AS_CANCELED]: (state, value) => ({
    ...state,
    timelapseRequests: changeToCanceled<TimelapseRequest>(state.timelapseRequests, value.requestId)
  }),

  [ActionTypes.UPDATE_TIMELAPSE_REQUEST_LINK]: (state, value) => ({
    ...state,
    timelapseRequests: updateLink<TimelapseRequest>(state.timelapseRequests, value.requestId, value.url)
  })
})

function updateLink<T extends BaseRequest>(currentRequests: T[], requestId: string, url: string): T[] {
  const target = currentRequests.find(el => el.requestId === requestId);

  if (target) {
    const newRequests: T[] = currentRequests.filter(el => el.requestId !== requestId);

    return [{
      ...target,
      url: url
    }, ...newRequests]
  }

  return currentRequests;
}

function changeToDone<T extends BaseRequest>(currentRequests: T[], requestId: string, exportId?: string): T[] {
  const target = currentRequests.find(el => el.requestId === requestId);

  if (target) {
    const newRequests: T[] = currentRequests.map(el => {
      if (el.requestId === requestId) return {
        ...target,
        progress: 100,
        exportId: exportId
      }

      return el;
    });

    return newRequests;
  }

  return currentRequests;
}

function changeToError<T extends BaseRequest>(currentRequests: T[], requestId: string): T[] {
  const target = currentRequests.find(el => el.requestId === requestId);

  if (target) {
    const newRequests: T[] = currentRequests.map(el => {
      if (el.requestId === requestId) return {
        ...target,
        error: true
      }

      return el;
    })

    return newRequests;
  }

  return currentRequests;
}

function changeToCanceled<T extends BaseRequest>(currentRequests: T[], requestId: string): T[] {
  const target = currentRequests.find(el => el.requestId === requestId);

  if (target) {
    const newRequests: T[] = currentRequests.map(el => {
      if (el.requestId === requestId) return {
        ...target,
        canceled: true,
        error: true
      }

      return el;
    })

    return newRequests;
  }

  return currentRequests;
}