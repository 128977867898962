import { API } from "aws-amplify";

async function updateWebPushSubscriptions(webPushSubscriptions: PushSubscription[], userId: string): Promise<void> {
  try {
    const path: string = `/users/${userId}`;
    const options = {
      body: {
        "webPushSubscriptions": webPushSubscriptions.map(wps => wps.options ? wps.toJSON() : wps)
      }
    }

    await API.put(
      'api-service',
      path,
      options
    );

  } catch (e) {
    throw e;
  }
}

export default updateWebPushSubscriptions;
