import React from 'react';
import { IStoreState } from '@store/index';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import PushNotification from '@components/common/PushNotification/PushNotification';
import { useSelector } from 'react-redux';
import MainRouter from '@components/app/MainRouter';
import useLoadUserPreferences from '@hooks/useLoadUserPreferences';
import useLoadSession from '@hooks/useLoadSession';
import useLoadBasicData from '@hooks/useLoadBasicData';
import usePeriodicallyRefreshSession from '@hooks/usePeriodicallyRefreshSession';
import { hotjar } from 'react-hotjar';
import theme from './theme.js';
import darkTheme from './dark-theme.js';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'styled-components';
import FootageExporter from '@components/app/FootageExporter';
import TimelapseExporter from '@components/app/TimelapseExporter';
import PubSubHandler from '@components/app/PubSubHandler';
import RootFontSizeListener from '@components/app/RootFontSizeListener';
import { setDefaultOptions } from 'date-fns';
import CloseSnackbarAction from '@components/common/Buttons/CloseSnackbarAction';
import { Report } from '@mui/icons-material';
import { CubeProvider } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import { UserRole } from '@store/users/types';
import { selectIsSuperAdminUser, selectIsRestrictedUser } from '@store/selectors';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
require('bitmovin-player-ui/dist/css/bitmovinplayer-ui.min.css');

// global date-fns start of week on monday
setDefaultOptions({ weekStartsOn: 1 })
hotjar.initialize(2494228, 6);

const lightThemeMUI = createTheme({}, theme);

const darkThemeMUI = createTheme(darkTheme, theme);

/**
 * Main component of the Dashboard.
 * Responsabilities:
 * - Load Theme from LocalStorage (Dark / Light).
 * - Periodically Refresh Session.
 * - Load user Session.
 * - Load Basic App Data (Companies, Sites and Streams)
 * - Renders the main Router.
 */
const App: React.FC<{}> = () => {
  const selectedTheme: AppTheme = useSelector((store: IStoreState) => store.appView.theme);
  const isAuthenticated: boolean | undefined = useSelector((store: IStoreState) => store.session.isAuthenticated);
  const isSuperAdministrator: boolean | undefined = useSelector(selectIsSuperAdminUser);
  const isAdministrator: boolean | undefined = useSelector((store: IStoreState) => store.session.userData?.roles === UserRole.Admin) || isSuperAdministrator;
  const isTemporaryAccessRole = useSelector(selectIsRestrictedUser);
  const token = useSelector((state: any) => state.session.user?.signInUserSession?.idToken?.jwtToken);

  useLoadUserPreferences();
  usePeriodicallyRefreshSession();

  const isAuthenticating = useLoadSession(isAuthenticated || false);
  const isLoadingBasicData = useLoadBasicData(isAuthenticated);

  return (
    <MuiThemeProvider theme={selectedTheme === 'dark' ? darkThemeMUI : lightThemeMUI}>
      <ThemeProvider theme={selectedTheme === 'dark' ? darkThemeMUI : lightThemeMUI} >
        <SnackbarProvider
          maxSnack={3}
          style={{ marginLeft: "4rem" }}
          SnackbarProps={{
            // @ts-ignore
            "data-cy": "snackbar-message"
          }}
          iconVariant={{
            error: <Report style={{ marginRight: 8 }} />,
          }}
          action={key => <CloseSnackbarAction id={key} />}
          preventDuplicate={true}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CubeProvider cubejsApi={cubejs(token, { apiUrl: process.env.REACT_APP_CUBE_JS_URL || "" }
            )}>
              <>
                <MainRouter
                  isAuthenticated={Boolean(isAuthenticated)}
                  isAuthenticating={Boolean(isAuthenticating)}
                  isSuperAdministrator={Boolean(isSuperAdministrator)}
                  isAdministrator={Boolean(isAdministrator)}
                  isLoadingBasicData={Boolean(isLoadingBasicData)}
                  isTemporaryAccessRole={Boolean(isTemporaryAccessRole)}
                />
                {isAuthenticated && Boolean('PushManager' in window) && <PushNotification />}
                {isAuthenticated && <FootageExporter />}
                {isAuthenticated && <TimelapseExporter />}
                {isAuthenticated && <PubSubHandler />}
                <RootFontSizeListener />
              </>
            </CubeProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export default App;
