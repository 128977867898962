import Tutorial from "./Tutorial";

class CreateActionTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#select-section-description',
        popover: {
          title: 'Select people',
          description: 'You can select distinct users or you can select users by their role in the dashboard to create unique notifications for them.',
          position: 'bottom'
        }
      },
      {
        element: '#users-table-description',
        popover: {
          title: 'Users table',
          description: 'List of all users registered to the dashboard with their role specified.',
          position: 'top'
        }
      },
      {
        element: '#select-event-types-description',
        popover: {
          title: 'Event types',
          description: 'Select the event types that are going to trigger notifications for the users.',
          position: 'bottom'
        }
      },
      {
        element: '#detected-by-description',
        popover: {
          title: 'Cameras',
          description: 'List of all the cameras located on the selected site.',
          position: 'bottom'
        }
      },
      {
        element: '#select-notifications-description',
        popover: {
          title: 'Notification alarm',
          description: 'Choose how to be notified, such as via email, SMS, or Web.',
          position: 'bottom'
        }
      },
      {
        element: '#select-at-time-description',
        popover: {
          title: 'Action time intervals',
          description: 'Select a time interval for the specific notification you are configuring.',
          position: 'bottom'
        }
      }
    ])
  }

}

export default CreateActionTutorial;