import { ActionTypes, AppTheme } from './types';

export function setAppTheme(theme: AppTheme) {
  return { type: ActionTypes.SET_APP_THEME, theme };
}

export function setLoadingBasicData(value: boolean) {
  return { type: ActionTypes.SET_LOADING_BASIC_DATA, value }
}

export function setAppBarExpanded(value: boolean) {
  return { type: ActionTypes.SET_APP_BAR_EXPANDED, value }
}

export function setRootFontSize(value: number) {
  return { type: ActionTypes.SET_ROOT_FONT_SIZE, value }
}

export function setErrorLoadingCompanies(value?: any) {
  return { type: ActionTypes.SET_ERROR_LOADING_COMPANIES, value }
}

export function setErrorLoadingUserData(value?: any) {
  return { type: ActionTypes.SET_ERROR_LOADING_USER_DATA, value }
}

export function setErrorLoadingSites(value?: any) {
  return { type: ActionTypes.SET_ERROR_LOADING_SITES, value }
}

export function setAppBarAnimationEndedAt(value: number) {
  return { type: ActionTypes.SET_APP_BAR_ANIMATION_ENDED, value }
}
