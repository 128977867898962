import { API } from 'aws-amplify';

async function cancelVideoDownload(kvsName: string, videoDownloadId: string): Promise<void> {
  try {
    const path: string = `/download/video/cancel/${kvsName}/${videoDownloadId}`;

    await API.post(
      'api-service',
      path,
      {}
    );

  } catch (error) {
    throw error;
  }
}

export default cancelVideoDownload;
