import TextInlineSelect from '@components/common/Dropdown/TextInlineSelect';
import CustomInputLabel from '@components/common/Input/CustomInputLabel';
import { Box, Checkbox, Fade, FormControl, FormControlLabel } from '@mui/material';
import React from 'react';
import { ChannelTypes } from './ExportsTab';

type ExportNotificationProps = {
  onNotifyChange: (value: boolean) => void;
  onChannelsChange: (value: ChannelTypes[]) => void;
}

const ExportNotification: React.FC<ExportNotificationProps> = (props) => {
  const { onChannelsChange, onNotifyChange } = props;
  
  const [checked, setChecked] = React.useState<boolean>(false);
  const [channel, setChannel] = React.useState<ChannelTypes[]>([ChannelTypes.Email]);

  const reverseChecked = React.useCallback(() => {
    setChecked(!checked);
  }, [checked])

  React.useEffect(() => {
    onNotifyChange(checked);
  }, [checked, onNotifyChange])

  React.useEffect(() => {
    onChannelsChange(channel)
  }, [channel, onChannelsChange])

  return (
    <Box display="flex" flexDirection="column">
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onClick={reverseChecked}
          />
        }
        label="Notify me when my export is ready."
      />
      <Fade in={checked} style={{ display: checked ? undefined : 'none' }}>
        <FormControl id="select-notifications-description" required>
          <CustomInputLabel id="channel">Channels</CustomInputLabel>
          <TextInlineSelect
            multiple
            options={[
              { label: "SMS", value: ChannelTypes.Sms, info: "SMSes are only sent to US phone numbers." },
              { label: "E-mail", value: ChannelTypes.Email },
              { label: "Web", value: ChannelTypes.Web }
            ]}
            onChange={(value) => { setChannel(value as ChannelTypes[]) }}
            value={channel}
            placeholder={"Channel"}
            optionDisplayProp="label"
            optionValueProp="value"
            notInline={true}
            noExtensionText={true}
          />
        </FormControl>
      </Fade>
    </Box>
  )
}

export default ExportNotification;
