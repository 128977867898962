function findIPAddress(value: string): string {
  try {
    const IP_REGEX = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
    const ip = value.match(IP_REGEX);
    if (ip && ip[0]) return ip[0];
    return "";
  } catch (error) {
    return "";
  }
}

export default findIPAddress;
