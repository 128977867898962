import extractViewFromGrids from './extractViewFromGrids';
import findViewByIdOrName from './findViewByIdOrName';
import updateViews from './updateViews';

export interface IViews {
  extractViewFromGrids(grids: { [key: string]: UserView[] }, selectedSite?: Site): UserView[];
  findViewByIdOrName(companyViews: UserView[], view: UserView): UserView | undefined;
  updateViews(
    grids: { [key: string]: UserView[] },
    selectedSite: Site,
    companyViews: UserView[]
  ): { [key: string]: UserView[] };
}

const Views: IViews = {
  extractViewFromGrids,
  findViewByIdOrName,
  updateViews
}

export default Views;
