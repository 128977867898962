import MapBox from '@components/views/mapbox/MapBox';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';

const SitesMap: React.FC<{}> = () => {
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const sites = useSelector((store: IStoreState) => store.sites.sites);

  function getPossibleSites(): Site[] {
    const companySites: Site[] = sites.get(selectedCompany?.companyId || "") || [];

    return companySites;
  }

  function getMapHeight(): number | string {
    const element = document.getElementById("active-nav-bar");
    if (element) {
      return window.innerHeight - element.scrollHeight;
    }

    return "94.5vh"
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <MapBox
        height={getMapHeight()}
        streams={streams.filter(el => el.companyId === selectedCompany?.companyId)}
        multiplesites={{
          sites: getPossibleSites()
        }}
      />
    </div>
  )
}

export default SitesMap;
