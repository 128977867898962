import { KinesisVideo, KinesisVideoArchivedMedia } from "aws-sdk";
import describeStream from "./describeStream";
import getDataEndpoint from "./getDataEndpoint";
import getHLSStreamingSessionURL from "./getHLSStreamingSessionURL";
import getLiveReplayStream from "./getLiveReplayStream";
import getLiveStream from "./getLiveStream";
import getOnDemandStream from "./getOnDemandStream";
import getStreamUptime from "./getStreamUptime";
import listStreamFragments, { ListFragmentOptions } from "./listStreamFragments";
import listStreamImages from "./listStreamImages";
import { ICredentials } from "@store/session/types";

export interface IKinesis {
  getStreamUptime: (stream: VideoStream | VideoStream[], credentials: any) => Promise<VideoStreamTimeChunk[]>;
  getLiveReplayStream: (streamName: string, options: KinesisVideo.ClientConfiguration, startTimestamp: Date) => Promise<any>;
  getDataEndpoint: (streamName: string, options: KinesisVideo.ClientConfiguration, APIName: KinesisVideo.APIName, nocache?: boolean) => Promise<string>
  getHLSStreamingSessionURL: (streamName: string, options: KinesisVideoArchivedMedia.ClientConfiguration, dataEndpoint: string, videoOptions: KinesisVideoArchivedMedia.GetHLSStreamingSessionURLInput) => Promise<string>
  getLiveStream: (streamName: string, options: KinesisVideo.ClientConfiguration) => Promise<any>;
  getOnDemandStream: (streamName: string, options: KinesisVideo.ClientConfiguration, startTimestamp: Date, endTimestamp: Date) => Promise<{ url: string, start: number, end: number }>;
  describeStream(streamName: string, options: KinesisVideo.ClientConfiguration): Promise<KinesisVideo.DescribeStreamOutput>;
  listStreamFragments(
    streamName: string,
    options: KinesisVideo.ClientConfiguration,
    dataEndpoint: string,
    targetStart: Date,
    targetEnd: Date,
    config: ListFragmentOptions
  ): Promise<KinesisVideoArchivedMedia.FragmentList>;
  listStreamImages(
    streamName: string,
    options: KinesisVideo.ClientConfiguration,
    dataEndpoint: string,
    targetStart: Date,
    targetEnd: Date,
    credentials?: ICredentials,
  ): Promise<KinesisVideoArchivedMedia.Images>;
}

const Kinesis: IKinesis = {
  getStreamUptime,
  getLiveReplayStream,
  getDataEndpoint,
  getHLSStreamingSessionURL,
  getLiveStream,
  getOnDemandStream,
  describeStream,
  listStreamFragments,
  listStreamImages
}

export default Kinesis;
