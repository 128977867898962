import React from 'react';
import { Box, Button, CircularProgress, Paper, Skeleton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { setActivePage } from '@store/session/actions';
import { PageView } from '@store/session/types';
import { setSelectedSite } from '@store/selectedSites/actions';
import styled from 'styled-components';
import SearchHeader from '@components/common/SearchHeader';
import Utils from '@utils/index';
import CustomDataGrid from '@components/common/DataGrid/CustomDataGrid';
import { setActiveStream } from '@store/selectedStream/actions';
import { setSelectedCompany } from '@store/selectedCompanies/actions';
import { useAsync } from 'react-async-hook';
import { ImageNotSupportedOutlined } from '@mui/icons-material';
import { isEmpty, isString, last } from 'lodash';
import API from '@API/index';

export const GridBox = styled(Box)`
  .MuiDataGrid-renderingZone{
    cursor: pointer;
  }

  .MuiDataGrid-overlay {
    background-color: white !important;
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-footerContainer {
    min-height: 3.5rem;
  }
`

type CompanyView = Company & {
  id: string,
}

const CompanySiteCount: React.FC<{ company: Company }> = (props) => {
  const { company } = props;

  const [siteCount, setSiteCount] = React.useState<number | undefined>(undefined);

  useAsync(async () => {
    const sites = await API.Site.listSites(company.companyId);
    const count = sites.length;
    setSiteCount(count);
  }, [company])

  return (
    <Box display="flex" alignItems="center" height="100%">
      <Typography noWrap variant="body2">{siteCount !== undefined ? siteCount : <CircularProgress size="1rem" />}</Typography>
    </Box>
  )
}

const CompanyName: React.FC<{ company: Company }> = (props) => {
  const { company } = props;

  const [url, setUrl] = React.useState<string | undefined>(undefined)
  const [loading, setLoading] = React.useState<boolean>(true);

  useAsync(async () => {
    setLoading(true);
    if (isString(company.logo) && !isEmpty(company.logo)) {
      const url = await Utils.S3.getImageUrl(last(company.logo.split('/')) || "", {}, process.env.REACT_APP_COMPANY_LOGO_S3_BUCKET)
      setUrl(url.url);
    } else {
      setUrl(undefined);
    }
    setLoading(false);
  }, [company])

  return (
    <Box display="grid" gap="1rem" gridTemplateColumns="min-content auto" flexDirection="row" alignItems="center">
      <Box width="2rem" height="2rem" borderRadius={5}>
        {loading === true &&
          <Skeleton style={{ borderRadius: 5 }} variant="rectangular" width="100%" height="100%" />
        }
        {loading === false && url === undefined &&
          <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
            <ImageNotSupportedOutlined />
          </Box>
        }
        {loading === false && url !== undefined &&
          <img style={{ width: '2rem', height: '2rem' }} src={url} alt="" onError={() => setUrl(undefined)} />
        }
      </Box>
      <Typography noWrap variant="body2">{company.name}</Typography>
    </Box>
  )
}

const CompaniesList: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const reduxCompanies = useSelector((store: IStoreState) => store.companies.companies);
  const [companies, setCompanies] = React.useState<CompanyView[]>(reduxCompanies.map((el) => ({ ...el, id: el.companyId })))
  const [filteredCompanies, setFilteredCompanies] = React.useState<CompanyView[]>([]);

  const handleAddNewCompany = React.useCallback(() => {
    dispatch(setActiveStream(undefined));
    dispatch(setSelectedSite(undefined));
    dispatch(setSelectedCompany(undefined));
    dispatch(setActivePage(PageView.Organization));
  }, [dispatch])

  const handleEditCompany = React.useCallback((company: Company) => {
    dispatch(setSelectedCompany(company));
    dispatch(setActivePage(PageView.Management));
  }, [dispatch])

  React.useEffect(() => {
    setCompanies(reduxCompanies.map((el) => ({ ...el, id: el.companyId })))
  }, [reduxCompanies])

  const rows = React.useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => {
          return (
            <CompanyName company={params.row} />
          )
        }
      },
      {
        field: 'street',
        headerName: 'Address',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Typography noWrap variant="body2">{params.row.address.street}</Typography>
        )
      },
      {
        field: 'city',
        headerName: 'City',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Typography noWrap variant="body2">{params.row.address.city}</Typography>
        )
      },
      {
        field: 'state',
        headerName: 'State',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Typography noWrap variant="body2">{params.row.address.state}</Typography>
        )
      },
      {
        field: 'zipCode',
        headerName: 'Zip code',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Typography noWrap variant="body2">{params.row.address.zipCode}</Typography>
        )
      },
      {
        field: 'country',
        headerName: 'Country',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Typography noWrap variant="body2">{params.row.address.country}</Typography>
        )
      },
      {
        field: 'siteCount',
        headerName: 'Site count',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <CompanySiteCount company={params.row} />
        )
      },
      {
        field: 'actions',
        headerName: '',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Box display="flex" width="100%" justifyContent="flex-end" >
            <Button variant="outlined" onClick={() => handleEditCompany(params.row)} >Edit</Button>
          </Box>
        )
      },
    ]
  }, [handleEditCompany])

  const textFilterProps = React.useMemo(() => ["name", "location"], [])

  return (
    <Box display="grid" gridTemplateRows="min-content auto" width="100%" height="100%" padding="1.5rem" gap="2rem">
      <SearchHeader
        title={"Company list"}
        noPadding
        textFiltering={true}
        textFilterProps={textFilterProps}
        searchHeaderFilterProps={{
          onFilteredDataChange: (data: any[]) => { setFilteredCompanies(data) },
          data: companies,
          properties: Utils.Filters.createCompanyFilters()
        }}
      >
        <Paper elevation={0}>

        </Paper>
        <Button
          onClick={handleAddNewCompany}
          variant="contained"
          data-cy="overview-add-site-button"
          color="primary"
        >
          Add company
        </Button>
      </SearchHeader>
      <CustomDataGrid
        dataCy="overview-site-list"
        defaultHeight='82vh'
        hideHeaders={false}
        disableColumnFilter
        disableRowSelectionOnClick
        onCellClick={(e) => {
          dispatch(setSelectedSite(e.row.site));
        }}
        rows={filteredCompanies}
        columns={rows}
      />
    </Box>
  )
}

export default CompaniesList;
