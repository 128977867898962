import {
  ActionTypes
} from './types';

export function setActiveStream(
  stream?: VideoStream,
  streamChunks: VideoStreamTimeChunk[] = [],
  streamEvents: VideoStreamEventChunk[] = []
) {
  return {
    type: ActionTypes.SET_ACTIVE_STREAM,
    stream,
    streamChunks,
    streamEvents
  };
}

export function addSelectedStreamTimes(streamChunks: VideoStreamTimeChunk[]) {
  return { type: ActionTypes.ADD_ACTIVE_STREAM_TIMES, streamChunks };
}

export function clearSelectedStream() {
  return {
    type: ActionTypes.CLEAR_ACTIVE_STREAM
  };
}

export function setVideoState(videoState: VideoState) {
  return { type: ActionTypes.SET_VIDEO_STATE, videoState }
}

export function setPlayerType(value: "KVS" | "PTZ") {
  return { type: ActionTypes.SET_PLAYER_TYPE, value }
}

export function setSelectedStreamLive(value: boolean) {
  return { type: ActionTypes.SET_VIDEO_LIVE, value }
}

export function setPubSubSubscriptionFunction(value?: (data: VideoStreamEvent[]) => void) {
  return { type: ActionTypes.SET_PUBSUB_SUBSCRIPTION_FUNCTION, value }
}

export function setPubSubPtzSubscriptionFunction(value?: (data: PTZEvent | PTZEvent[]) => void) {
  return { type: ActionTypes.SET_PUBSUB_PTZ_SUBSCRIPTION_FUNCTION, value }
}
