import deleteVideoDownload from "./deleteVideoDownload";
import getShareDownloadLink from "./getShareDownloadLink";
import postDownloadStart from "./postDownloadStart";
import getDownloadVideoLink from './getDownloadVideoLink';
import getFootageThumbnail from './getFootageThumbnail';
import listSiteDownloads from "./listSiteDownloads";
import cancelVideoDownload from "./cancelVideoDownload";
import getDownloadStatus, { PartialVideoDownload } from "./getDownloadStatus";

export interface IDownloadsAPI {
  postDownloadStart(kvsName: string, start: number, end: number, options?: { channels?: string[] }, name?: string, description?: string): Promise<{ videoDownloadId: string } | undefined>;
  deleteVideoDownload(download: VideoDownload, kvsName: string): Promise<void>;
  getShareDownloadLink(path: string): Promise<string>
  getDownloadVideoLink(path: string): Promise<string>;
  getFootageThumbnail(path: string): Promise<string>;
  listSiteDownloads(siteId: string): Promise<VideoDownload[]>;
  cancelVideoDownload(kvsName: string, videoDownloadId: string): Promise<void>;
  getDownloadStatus(kvsName: string, videoDownloadId: string): Promise<PartialVideoDownload | undefined>;
}

const Downloads: IDownloadsAPI = {
  postDownloadStart,
  deleteVideoDownload,
  getShareDownloadLink,
  getDownloadVideoLink,
  getFootageThumbnail,
  listSiteDownloads,
  cancelVideoDownload,
  getDownloadStatus
}

export default Downloads;
