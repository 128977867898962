import archiveStream from "./archiveStream";
import createStream from "./createStream";
import deleteStream from "./deleteStream";
import listStreams from "./listStreams";
import updateStream from "./updateStream";
import getCameraPosition from "./getCameraPositions";
import getStreamParameterValues from "./getStreamParameterValues";
import restartStream from "./restartStream";
import { getMutedStreams } from './getMutedStreams';
import { muteStream } from './muteStream';
import { unmuteStream } from './unmuteStream';
import { getStreamPTZEvents } from "./getStreamPTZEvents";
import { getStreamUptime } from "./getStreamUptime";
import { estimateHomographyMatrix } from "./estimateHomographyMatrix";

export interface IGetStreamPTZEvents {
  getStreamPTZEvents: (kvsName: string, start: number, end: number) => Promise<Array<{
    src: string;
    eventTime: number;
    orientationId: string;
  }>>
}

export interface IEstimateMatrix {
  estimateHomographyMatrix(pairPoints: Array<{cameraViewPoints: number[], satelliteViewPoints: number[]}>): Promise<Array<Array<number>>>;
}

export interface IGetStreamUptime {
  getStreamUptime: (stream: VideoStream) => Promise<UptimeEvent[]>
}
export interface IStreamsAPI extends IGetStreamPTZEvents, IGetStreamUptime, IEstimateMatrix {
  archiveStream(siteId: string, kvsName: string, state: string): Promise<void>;
  deleteStream(siteId: string, kvsName: string): Promise<void>;
  listStreams(siteId?: string, companyId?: string): Promise<VideoStream[]>;
  updateStream(stream: VideoStreamBase): Promise<void>;
  createStream(stream: VideoStreamBase): Promise<VideoStream>;
  getCameraPosition(kvsName: string): Promise<StreamPosition[]>;
  getStreamParameterValues(): Promise<StreamParameterValues>;
  restartStream(kvsName: string): Promise<void>;
  getMutedStreams(companyId: string): Promise<string[]>;
  muteStream(companyId: string, kvsName: string, duration: number): Promise<void>;
  unmuteStream(companyId: string, kvsName: string): Promise<void>
}

const Streams: IStreamsAPI = {
  archiveStream,
  deleteStream,
  listStreams,
  updateStream,
  createStream,
  getCameraPosition,
  getStreamParameterValues,
  restartStream,
  getMutedStreams,
  muteStream,
  unmuteStream,
  getStreamPTZEvents,
  getStreamUptime,
  estimateHomographyMatrix
}

export default Streams;
