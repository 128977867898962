import Utils from '@utils/index';

/**
 * Singleton that manages all stream thumbnails.
 * This class should be used to fetch the URL for the Thumbnail of a specific stream.
 * This singledton was designed to avoid generating new Thumbnail URLs for the same stream.
 * To make sure the browser can properly cache them.
 */
class StreamThumbnailManager {
  private static _instance: StreamThumbnailManager;
  private _thumbnails: Map<string, { expires: number, url: string }> = new Map();

  /**
   * Returns the instance of the Singleton.
   */
  public static get instance() {
    if (!StreamThumbnailManager._instance) {
      StreamThumbnailManager._instance = new StreamThumbnailManager();
    }

    return this._instance
  }


  private constructor() { }

  /**
   * Method to generate or retrieve an existing Thumbnail URL for a given VideoStream.
   * @param stream Target VideoStream.
   * @returns URL of Thumbnails from a S3 request to the images.
   * @example
   * const videoStream = { kvsName: 'camera-1', siteId: 'site-1', companyId: 'company-1', ... };
   * const thumbnailUrl = StreamThumbnailManager.instance.thumbnailOf(videoStream); => // https://s3...etc...
   * const thumbnailRequestAgain = StreamThumbnailManager.instace.thumbnailOf(videoStream); => // Same URL as Above.
   * 
   * // Time passes by a day.
   * const thumbnailUrlAfterADay = StreamThumbNailManager.instance.thumbnailOf(videoStream); => // New URL, since previous link expired.
   */
  public async thumbnailOfStream(stream: VideoStream, forceReload: boolean = false) {
    const target = this._thumbnails.get(stream.kvsName);
    if ((!target || target.expires < Date.now()) || forceReload === true) {
      const url = await Utils.Stream.getStreamThumbnail(stream);
      this._thumbnails.set(stream.kvsName, { expires: Date.now() + (86400 * 1000), url });
      return url;
    }

    return target.url;
  }
}

export default StreamThumbnailManager;
