import { API } from "aws-amplify";

export async function getOverrideZoneSchedule(): Promise<{ from: string, to: string }[]> {
  try {
    const path: string = `/geofence/override`;
    const response = await API.get('api-service', path, {});
    return response.data || [];
  } catch (error) {
    console.log(error);
    throw error;
  }
}