export function createVehicleDetectionsFilters(): FilterProperties[] {
  return [
    { label: 'ID', property: 'id', type: 'number' },
    { label: 'Detection timestamp', property: 'detectionTimestamp', type: 'date' },
    { label: 'Confidence score', property: 'confidenceScore', type: 'number' },
    { label: 'Event timestamp', property: 'eventTimestamp', type: 'date' },
    { label: 'Stream ID', property: 'streamId', type: 'number' },
    { label: 'Ordinal', property: 'ordinal', type: 'number' },
    { label: 'Site ID', property: 'siteId', type: 'string' },
    { label: 'License plate', property: 'plate', type: 'string' },
    { label: 'Country', property: 'country', type: 'string' },
    { label: 'Region', property: 'region', type: 'string' },
    { label: 'Vehicle type', property: 'vehicleType', type: 'string' },
    { label: 'Manufacturer', property: 'make', type: 'string' },
    { label: 'Model', property: 'model', type: 'string' },
    { label: 'Color', property: 'color', type: 'string' },
    { label: 'Orientation', property: 'orientation', type: 'string' }
  ];
}
