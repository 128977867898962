import { Filter } from "@classes/Filter/Filter";
import { EventsFilterParams } from "./EventsFilter";
import IFilterEvents from "./IFilterEvents";

class PeopleFilter implements IFilterEvents {
  filter(params: EventsFilterParams, events: VideoStreamEvent[]): VideoStreamEvent[] {
    const { people } = params;
    
    if(!people.enable) {
      return [];
    }

    if (people?.filters?.length) {
      return new Filter(people.filters).filter(events);
    } else {
      return events;
    }
  }
}

export default PeopleFilter;
