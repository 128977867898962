import TargetDateInput from '@components/common/Input/TargetDateInput';
import { ArrowBack, Compare, Fullscreen, MoreHoriz } from '@mui/icons-material';
import { Box, CircularProgress, Dialog, Divider, FormControlLabel, IconButton, Menu, MenuItem, Radio, Tooltip, Typography } from '@mui/material';
import React from 'react';
import API from '@API/index';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import ReactCompareImage from 'react-compare-image';
import background from '../../../assets/images/placeholders/stream_offline.svg';

type XRayCompareOptions = {
  aspectRatio: 'taller' | 'wider';
  hover: boolean;
  vertical: boolean;
}

type XRayCompareDialogButtonProps = {
  disabled: boolean;
  stream?: VideoStream;
  site?: Site;
}

const XRayCompareDialogButton: React.FC<XRayCompareDialogButtonProps> = (props) => {
  const { disabled, stream, site } = props;

  const credentials = useSelector((store: IStoreState) => store.session.credentials)

  const [open, setOpen] = React.useState<boolean>(false);
  const [timestamps, setTimestamps] = React.useState<{ start: number, end: number }>({ start: Date.now() - (86400000 * 7), end: Date.now() });
  const [startImage, setStartImage] = React.useState<string | undefined>(background);
  const [startImageError, setStartImageError] = React.useState<boolean>(false);
  const [endImageError, setEndImageError] = React.useState<boolean>(false);
  const [endImage, setEndImage] = React.useState<string | undefined>(background);
  const [loadingStartImage, setLoadingStartImage] = React.useState<boolean>(false);
  const [loadingEndImage, setLoadingEndImage] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<XRayCompareOptions>({
    aspectRatio: 'taller',
    hover: false,
    vertical: false
  })
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const [fullScreen, setFullScreen] = React.useState<boolean>(false);

  const tooltip = React.useMemo(() => { return "Compare" }, [])

  const closeModal = React.useCallback(() => { setOpen(false) }, [])
  const openModel = React.useCallback(() => { setOpen(true) }, []);

  React.useEffect(() => {
    setLoadingStartImage(true);
    setStartImageError(false);
    const options = {
      credentials,
      region: process.env.REACT_APP_KINESIS_VIDEO_STREAMS_REGION
    };

    API.Kinesis.getDataEndpoint(stream?.kvsName || "", options, 'GET_IMAGES')
      .then((endpoint) => {
        return API.Kinesis.listStreamImages(
          stream?.kvsName || "",
          options,
          endpoint,
          new Date(timestamps.start - 60000),
          new Date(timestamps.start + 60000)
        )
      })
      .then((images) => {
        const start = images.find(el => Boolean(el.ImageContent));

        if (start && start.ImageContent) setStartImage(`data:image/jpeg;base64,${start?.ImageContent}`);
        else {
          setStartImage(background)
          setStartImageError(true);
        }
      })
      .catch((error) => {
        setStartImage(background)
        setStartImageError(true);
      })
      .finally(() => { setLoadingStartImage(false) })
  }, [timestamps.start, credentials, stream?.kvsName])

  React.useEffect(() => {
    setLoadingEndImage(true);
    setEndImageError(false);
    const options = {
      credentials,
      region: process.env.REACT_APP_KINESIS_VIDEO_STREAMS_REGION
    };

    API.Kinesis.getDataEndpoint(stream?.kvsName || "", options, 'GET_IMAGES')
      .then((endpoint) => {
        return API.Kinesis.listStreamImages(
          stream?.kvsName || "",
          options,
          endpoint,
          new Date(timestamps.end - 60000),
          new Date(timestamps.end + 60000)
        )
      })
      .then((images) => {
        const end = images.find(el => Boolean(el.ImageContent));

        if (end && end.ImageContent) setEndImage(`data:image/jpeg;base64,${end?.ImageContent}`);
        else {
          setEndImage(background)
          setEndImageError(true);
        }
      })
      .catch((error) => {
        setEndImage(background)
        setEndImageError(true);
      })
      .finally(() => { setLoadingEndImage(false) })
  }, [timestamps.end, credentials, stream?.kvsName])

  return (
    <>
      <Tooltip title={tooltip} arrow placement="right">
        <span>
          <IconButton
            onClick={openModel}
            id="x-ray-compare-button"
            size="small"
            disabled={disabled || stream === undefined}
          >
            <Compare />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog
        open={open}
        onClose={closeModal}
      >
        <Box padding="1rem" display="grid" gap="1rem" gridTemplateRows="min-content min-content auto">
          <Box display="flex" width="100%" justifyContent="space-between">
            <Tooltip title="Close">
              <IconButton size="small" onClick={closeModal}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Box>
              <Tooltip title="Options">
                <IconButton
                  size="small"
                  onClick={(e) => setFullScreen(true)}
                >
                  <Fullscreen />
                </IconButton>
              </Tooltip>
              <Tooltip title="Options">
                <IconButton
                  size="small"
                  onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                >
                  <MoreHoriz />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="1rem">
            <TargetDateInput
              value={timestamps.start}
              onChange={(timestamp: number) => setTimestamps({ start: timestamp, end: timestamps.end })}
              disableFuture={true}
              disablePast={false}
              fullWidth={true}
              timezone={site?.timezone?.split(' ')[0] || ""}
              outlined={true}
              error={startImageError}
              disabled={loadingEndImage || loadingStartImage}
              endTimeText='Time'
            />

            <TargetDateInput
              value={timestamps.end}
              onChange={(timestamp: number) => setTimestamps({ start: timestamps.start, end: timestamp })}
              disableFuture={true}
              disablePast={false}
              fullWidth={true}
              timezone={site?.timezone?.split(' ')[0] || ""}
              outlined={true}
              error={endImageError}
              disabled={loadingEndImage || loadingStartImage}
              endTimeText='Time'
            />
          </Box>
          <Box bgcolor="black" position='relative' minHeight="70vh" style={{ aspectRatio: '16/9' }}>
            {(loadingStartImage || loadingEndImage) &&
              <Box
                bgcolor="white"
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                width="100%"
                height="100%"
                style={{ opacity: 0.6, zIndex: 9999 }}
                onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
              >
                <CircularProgress />
              </Box>
            }
            <ReactCompareImage
              leftImageAlt={background}
              leftImage={startImage || background}
              rightImage={endImage || background}
              rightImageAlt={background}
              aspectRatio={options.aspectRatio}
              vertical={options.vertical}
              hover={options.hover}
              leftImageCss={{ objectFit: 'contain', height: '70vh' }}
              rightImageCss={{ objectFit: 'contain', height: '70vh' }}
            />
          </Box>
        </Box>
        <Menu
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          <Divider style={{ marginTop: '0.5rem' }}>
            <Typography variant="caption">Compare</Typography>
          </Divider>
          <MenuItem
            dense
            onClick={() => setOptions({ ...options, vertical: false })}
          >
            <FormControlLabel control={<Radio size="small" checked={options.vertical === false} />} label="Horizontal" />
          </MenuItem>
          <MenuItem
            style={{ width: '15rem' }}
            dense
            onClick={() => setOptions({ ...options, vertical: true })}
          >
            <FormControlLabel control={<Radio size="small" checked={options.vertical === true} />} label="Vertical" />
          </MenuItem>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} ><Typography variant="caption">Move line style</Typography></Divider>
          <MenuItem
            dense
            onClick={() => setOptions({ ...options, hover: false })}
          >
            <FormControlLabel control={<Radio size="small" checked={options.hover === false} />} label="Click and drag" />
          </MenuItem>
          <MenuItem
            dense
            onClick={() => setOptions({ ...options, hover: true })}
          >
            <FormControlLabel control={<Radio size="small" checked={options.hover === true} />} label="Hover" />
          </MenuItem>
        </Menu>
      </Dialog>
      {fullScreen === true &&
        <Dialog
          open={fullScreen}
          onClose={() => setFullScreen(false)}
          fullScreen
          PaperProps={{ style: { backgroundColor: 'black', borderRadius: 0 } }}
        >
          <Box position="absolute" style={{ zIndex: 99999999 }}>
            <Box bgcolor="rgba(255, 255, 255, 0.5)" borderRadius="50%" marginTop="1rem" marginLeft="1rem">
              <Tooltip title="Close">
                <IconButton size="small" onClick={() => setFullScreen(false)}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <ReactCompareImage
            leftImageAlt={background}
            leftImage={startImage || background}
            rightImage={endImage || background}
            rightImageAlt={background}
            aspectRatio={options.aspectRatio}
            vertical={options.vertical}
            hover={options.hover}
            leftImageCss={{ objectFit: 'contain', height: '100vh', width: '100vw' }}
            rightImageCss={{ objectFit: 'contain', height: '100vh', width: '100vw' }}
          />
        </Dialog>
      }
    </>
  )
}

export default XRayCompareDialogButton;
