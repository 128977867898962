import { ActionTypes, ISavedViewState } from "./types";
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: ISavedViewState = {
  target: undefined
};

export const savedViewReducer = Utils.Redux.createReducer<ISavedViewState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_TARGET_EXPORT]: (state, action) => ({
      ...state,
      target: {
        type: action.exportType,
        id: action.id,
        kvsName: action.kvsName
      }
    }),

    [ActionTypes.CLEAR_TARGET_EXPORT]: (state, action) => ({
      ...state,
      target: undefined
    })
  }
);
