import activateZone from "./activateZone";
import createZone from "./createZone";
import deactivateZone from "./deactivateZone";
import deleteZone from "./deleteZone";
import getZones from "./getZones";
import updateZone from "./updateZone";
import getZonesNames from './getZonesNames';
import { overrideZoneSchedule } from "./overrideZoneSchedule";
import { getOverrideZoneSchedule } from "./getOverrideZoneSchedule";
import { delOverrideZoneSchedule } from "./delOverrideZoneSchedule";

export interface IZonesAPI {
  getZones(streamId: string): Promise<Zone[]>;
  activateZone(streamId: string, zoneId: string): Promise<Zone[]>;
  createZone(streamId: string, zone: Zone): Promise<Zone[]>;
  deactivateZone(streamId: string, zoneId: string): Promise<Zone[]>;
  deleteZone(streamId: string, zoneId: string): Promise<Zone[]>;
  updateZone(streamId: string, zone: Zone): Promise<Zone[]>;
  getZonesNames(companyId: string): Promise<Map<string, string>>;
  overrideZoneSchedule(schedule: { from: string, to: string }[]): Promise<void>
  getOverrideZoneSchedule(): Promise<{ from: string, to: string }[]>;
  delOverrideZoneSchedule(): Promise<void>;
}

const Geofences: IZonesAPI = {
  getZones,
  activateZone,
  createZone,
  deactivateZone,
  deleteZone,
  updateZone,
  getZonesNames,
  overrideZoneSchedule,
  getOverrideZoneSchedule,
  delOverrideZoneSchedule
}

export default Geofences;
