import getWorker from "./getWorker";
import updateWorker from "./updateWorker";
import listActions from '../actions/listActions'
import deleteAction from '../actions/deleteAction';
import { isObject } from "lodash";

export default async function unblacklistWorker(workerId: number, siteId: string, asCompany?: string): Promise<CrewWorker> {
  try {
    const actions = await listActions(siteId, "");
    console.log(actions)
    const targets = actions.filter((action) => {
      const target = action.sentence.find((sentence) => {
        if (isObject(sentence) && sentence.key === "EventType") {
          if (sentence.value) {
            if (sentence.value[0]) {
              if (sentence.value[0].filters) {
                if (sentence.value[0].filters.workerId) {
                  if (sentence.value[0].filters.workerId.value) {
                    if (sentence.value[0].filters.workerId.value === workerId) {
                      return true;
                    }
                  }
                }
              }
            }
          }
        }

        return false;
      })

      if (target) return true;

      return false;
    })

    console.log('Unblacklisting worker...');
    console.log(targets);
    console.log('Deleting actions: ', targets.map(el => el.id))
    await Promise.all(targets.map(el => deleteAction(siteId, el.id)))
    const worker = await getWorker(workerId, asCompany);
    worker.blacklisted = 'previously_blacklisted';
    await updateWorker(worker, asCompany);
    return worker;
  } catch (error) {
    throw error;
  }
}