import createAccessRequest from "./createAccessRequest";
import listAccessRequests from "./listAcccessRequests";
import updateAccessRequest from "./updateAccessRequest";

export interface IAccessRequestsAPI {
  listAccessRequests(): Promise<AccessRequest[]>;
  updateAccessRequest(id: string, status: DashboardAccessRequestStatus): Promise<AccessRequest>;
  createAccessRequest(duration: number, siteIds: string[], type: "readonly" | "superadmin"): Promise<AccessRequest>;
}

const AccessRequests: IAccessRequestsAPI = {
  listAccessRequests,
  updateAccessRequest,
  createAccessRequest
}

export default AccessRequests;
