import React from 'react';
import { IStoreState } from '@store/index';
import { useDispatch, useSelector } from 'react-redux';
import TutorialFactory, { TutorialType } from '@classes/Tutorials/TutorialFactory';
import { setActiveTutorial } from '@store/tutorials/actions';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';

const TutorialsHandler: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const dispatchRef = React.useRef(dispatch);
  const enqueueSnackbarRef = React.useRef(enqueueSnackbar);

  const activeTutorial: TutorialType | undefined = useSelector((store: IStoreState) => store.tutorials.activeTutorial);

  React.useEffect(() => { enqueueSnackbarRef.current = enqueueSnackbar }, [enqueueSnackbar])
  React.useEffect(() => { dispatchRef.current = dispatch }, [dispatch])
  React.useEffect(perform, [activeTutorial])

  function perform(): void {
    if (activeTutorial) {
      try {
        TutorialFactory.createTutorialFor(activeTutorial).start();
      } catch (e) {
        enqueueSnackbarRef.current(Utils.Error.getErrorMessage(e), { variant: 'error' })
      }
      
      dispatchRef.current(setActiveTutorial(undefined));
    }
  }

  return null;
}

export default TutorialsHandler;
