import { store } from '../../index';
import * as StreamActions from '@store/streams/actions';
import * as SessionActions from '@store/session/actions';
import API from '@API/index';
import { AssetsPermissions } from '@store/session/types';

export const fetchAllSitesStreams = async () => {
  const { sites } = store.getState().sites;
  const { userData } = store.getState().session;
  
  const grids: Site[] = []; 
  sites.forEach((value, key) => {
    grids.push(...value);
  })

  const promises: Promise<VideoStream[]>[] = [];

  grids.forEach(element => {
    promises.push(API.Streams.listStreams(element.siteId));
  });

  const finishedPromises = await Promise.all(promises);

  const streams: VideoStream[] = [];
  finishedPromises.forEach(element => {
    streams.push(...element);
  });

  //@ts-ignore
  const builtStreams: IVideoStream[] = streams.map(stream => ({
    ...stream,
    state: stream.state ? stream.state.toUpperCase() : "ACTIVE"
  }))

  if (userData) {
    if (userData.roles === 'superadmin' || userData.roles === 'admin') {
      const permissions: AssetsPermissions = {};
      streams.forEach(stream => permissions[stream.kvsName] = ["ALL"]);
      store.dispatch(SessionActions.setPermissions(permissions));
    } else {
      const apiPermissions = await Promise.all(streams.map(stream => API.Permissions.listPermissionsForStream(userData.userId, stream.kvsName)));
      const permissions: AssetsPermissions = {};
      apiPermissions.forEach(element => permissions[element.kvsName] = element.permissions);
      store.dispatch(SessionActions.setPermissions(permissions));
    }
  }

  store.dispatch(StreamActions.setStreamList(builtStreams));
}
