import { isEmpty } from "lodash";

class EventPooler {
  private _timeout: number;
  private _action: (events: VideoStreamEvent[]) => void;
  private _pool: Map<string, VideoStreamEvent> = new Map();
  private _interval: NodeJS.Timeout | null = null;

  constructor(timeout: number, action: (events: VideoStreamEvent[]) => void) {
    this._timeout = timeout;
    this._action = action;
  }

  public start(): void {
    this._interval = setInterval(() => { this.perform(); }, this._timeout);
  }

  public stop(): void {
    if (this._interval)
      clearInterval(this._interval);
  }

  public add(events: VideoStreamEvent[]): void {
    events.forEach((event) => {
      this._pool.set(`${event.sourceCamera}-${event.startDate}`, event);
    })
  }

  private perform(): void {
    const events = Array.from(this._pool.values());

    if (!isEmpty(events)) this._action(events);
    this._pool.clear();
  }
}

export default EventPooler;
