import PubSub from "./PubSub";
import API from '@API/index';

class PubSubSafetyInfraction extends PubSub {
  protected company: Company;

  constructor(company: Company, onMessage: Function, onError?: Function, onComplete?: Function) {
    super(onMessage, onError, onComplete);
    this.company = company;
  }

  public connect(): void {
    const { companyId } = this.company;

    const topicName: string = `dashboard/events/${companyId}/+/+/safety-infraction`;
    this.subscription = API.PubSub.subscribeToTopic(topicName, this.onMessage, this.onError, this.onComplete);

    this.interval = setInterval(() => { this.checkSubscriptionHealth() }, 15000);
  }
}

export default PubSubSafetyInfraction;
