import { API } from "aws-amplify";

async function updateAttendance(siteId: string, id: string, form: CreateTimeEntryForm, asCompany?: string): Promise<TimeEntry> {
  try {
    const path: string = `/workers/attendance/${id}` + (asCompany ? `?asCompany=${asCompany}` : "");
    const options = {
      body: {
        workerId: form.workerId,
        startTime: form.from,
        endTime: form.to,
        siteId
      }
    };

    const data = await API.patch(
      'api-service',
      path,
      options
    );

    return data.data as TimeEntry;
  } catch (error: any) {
    throw error;
  }
}

export default updateAttendance;
