import { createActionsFilters } from "./createActionsFilters";
import { createRolesFilters } from "./createRolesFilters";
import { createSitesFilters } from "./createSitesFilters";
import { createSnapshotFilters } from "./createSnapshotFilters";
import { createTimelapseFilters } from "./createTimelapseFilters";
import { createUsersFilters } from "./createUsersFilters";
import { createVideoDownloadsFilters } from "./createVideoDownloadsFilters";
import { createVideoStreamFilters } from "./createVideoStreamFilters";
import { createSubcontractorsFilters } from "./createSubcontractorsFilters";
import { createWorkersFilters } from "./createWorkersFilters";
import { createHeatmapsFilters } from "./createHeatmapsFilters";
import { createAccessRequestsFilters } from "./createAccessRequestsFilters";
import { createFlaggedWorkersFilters } from "./createFlaggedWorkersFilters";
import { createCompanyFilters } from "./createCompanyFilters";
import { createVehicleDetectionsFilters } from "./createVehicleDetectionsFilters";

export interface IFilter {
  createVideoStreamFilters(isSuperAdministrator?: boolean): FilterProperties[]
  createTimelapseFilters(isSuperAdministrator?: boolean): FilterProperties[]
  createVideoDownloadsFilters(isSuperAdministrator?: boolean): FilterProperties[]
  createSnapshotFilters(isSuperAdministrator?: boolean): FilterProperties[]
  createSitesFilters(isSuperAdministrator: boolean, sites: Site[]): FilterProperties[]
  createActionsFilters(isSuperAdministrator: boolean): FilterProperties[]
  createUsersFilters(isSuperAdministrator: boolean): FilterProperties[]
  createRolesFilters(isSuperAdministrator: boolean): FilterProperties[]
  createSubcontractorsFilters(isSuperAdministrator: boolean): FilterProperties[]
  createWorkersFilters(isSuperAdministrator: boolean): FilterProperties[]
  createHeatmapsFilters(): FilterProperties[]
  createAccessRequestsFilters(): FilterProperties[]
  createFlaggedWorkersFilters(showSite?: boolean): FilterProperties[]
  createCompanyFilters(): FilterProperties[]
  createVehicleDetectionsFilters() : FilterProperties[]
}

const Filters: IFilter = {
  createVideoStreamFilters,
  createTimelapseFilters,
  createVideoDownloadsFilters,
  createSnapshotFilters,
  createSitesFilters,
  createActionsFilters,
  createUsersFilters,
  createRolesFilters,
  createSubcontractorsFilters,
  createWorkersFilters,
  createHeatmapsFilters,
  createAccessRequestsFilters,
  createFlaggedWorkersFilters,
  createCompanyFilters,
  createVehicleDetectionsFilters
}

export default Filters;
