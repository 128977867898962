import { Box, FormControlLabel, IconButton, Menu, MenuItem, Switch, Tooltip, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import React from 'react';

type VideoVisibilityMenuProps = {
  boundingBoxVisible: boolean;
  minimapVisible: boolean;
  poseEstimation: boolean;
  onBoundingBoxChange: (event: React.ChangeEvent<{}>, checked: boolean) => void;
  onPoseEstimationChange: (event: React.ChangeEvent<{}>, checked: boolean) => void;
  onMinimapChange: (event: React.ChangeEvent<{}>, checked: boolean) => void;
}

export const VideoVisibilityMenu: React.FC<VideoVisibilityMenuProps> = (props) => {
  const {
    minimapVisible,
    onMinimapChange,
    boundingBoxVisible,
    onBoundingBoxChange,
    onPoseEstimationChange,
    poseEstimation
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Visibility options" arrow placement="right">
        <IconButton id="zoom-minimap-description" onClick={handleClick} size="small">
          <Visibility color="action" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <FormControlLabel
            value="start"
            control={<Switch color="primary" size="small" data-cy="switch" />}
            label={
              <Box width="9rem">
                <Typography variant="body1">Analytics Metadata</Typography>
              </Box>
            }
            labelPlacement="start"
            checked={boundingBoxVisible}
            onChange={onBoundingBoxChange}
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            value="start"
            control={<Switch color="primary" size="small" data-cy="switch" />}
            label={
              <Box width="9rem">
                <Typography variant="body1">Pose Estimation</Typography>
              </Box>
            }
            labelPlacement="start"
            checked={poseEstimation}
            onChange={onPoseEstimationChange}
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            value="start"
            control={<Switch color="primary" size="small" data-cy="timeline-player-minimap-switch" />}
            label={
              <Box width="9rem">
                <Typography variant="body1">Zoom MiniMap</Typography>
              </Box>
            }
            labelPlacement="start"
            checked={minimapVisible}
            onChange={onMinimapChange}
            style={{ marginRight: "0.5rem" }}
          />
        </MenuItem>
      </Menu>
    </>
  )
}