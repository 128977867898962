import removeChars from './removeChars';
import maskPhone from './maskPhone';
import getDisplayText from './getDisplayText';
import getScheduleDisplayText from './getScheduleDisplayText';
import { DayLabel } from '@declarations/enums';

export interface IText {

  /**
   * Removes all aparitions of one or more selected chars from a string.
   * @param input {string} string to be removed from.
   * @param chars {string} string containing all chars to be removed.
   * @returns {string} string.
   * @example
   * const phone: string = "+1 (324) 124-2412";
   * const result: string = Utils.Text.removeChars("() -")
   * console.log(result) => //+13241242412
   */
  removeChars: (input: string, chars: string) => string;

  /**
   * Remove middle digits from Phone Numbers.
   * @param phone Phone number to mask.
   * @returns {string} Masked phone number.
   * @example
   * const phone: string = '1 (324) 124-2412'
   * const masked: string = Utils.Text.maskPhone(phone);
   * console.log(masked); // => 1 (324) ***-2412
   */
  maskPhone(phone: string): string;

  /**
   * Get display value from string or array values with extension like "and 5 more"
   * @param value 
   * @param multiple 
   * @param optionDisplayProp 
   * @param optionValueProp 
   * @param options 
   * @param placeholder 
   */
  getDisplayText(value: unknown, multiple: boolean | undefined, optionDisplayProp: string | undefined, optionValueProp: string | undefined, options: any[] | undefined, placeholder: string | undefined, noExtension: boolean): string;

  /**
   * Get display text for time range
   * @param value
   * @param fallback
   */
  getScheduleDisplayText(value: Record<DayLabel, Array<ActiveDayTime>>, fallback: string): string;
}

const Text: IText = {
  removeChars,
  maskPhone,
  getDisplayText,
  getScheduleDisplayText
};

export default Text;
