import { PrometheusQuery } from "./PrometheusQuery";

export class NodeMemorySwapTotalBytesQuery extends PrometheusQuery {
  protected _id: string = "node_memory_SwapTotal_bytes";

  public override getPossibleValues() {
    return [
      { id: "Total SWAP", color: "#FFA500" }
    ]
  };
  
  public override getRefinedData() {
    const refinedData = [];

    if (this.isEmpty()) return [];
    
    for (let i = 0; i < this._queryData.data.result[0].values.length; i += 1) {
      refinedData.push({
        date: this.formatDate(this._queryData.data.result[0].values[i][0]),
        "Total SWAP": this.bytesToGigabytes(this._queryData.data.result[0].values[i][1])
      })
    }

    return refinedData;
  }

  public override getDomain() {
    if (this.isEmpty()) return [0, 32] 
    return undefined;
  }

  public override getMiddleValue() {
    if (this.isEmpty()) return 16;
    return undefined;
  }

  public override getValueLabel() {
    return "GB";
  }
}
