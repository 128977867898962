import { PrometheusQuery } from "./PrometheusQuery";

export class DataDiskUtilizationQuery extends PrometheusQuery {
  protected _id: string = "calculated:filesystem_utilization_data";
  
  public override getPossibleValues() {
    return [
      { id: "Used disk (data)", color: "#0048e3" }
    ]
  };
  
  public override getRefinedData() {
    const refinedData = [];

    if (this.isEmpty()) return [];
    
    for (let i = 0; i < this._queryData.data.result[0].values.length; i += 1) {
      refinedData.push({
        date: this.formatDate(this._queryData.data.result[0].values[i][0]),
        "Used disk (data)": Math.round((this._queryData.data.result[0]?.values[i][1] || 0 * 100 + Number.EPSILON) * 100) / 100
      })
    }

    return refinedData;
  }

  public override getDomain() {
    if (this.isEmpty()) return [0, 100]
    return undefined;
  }

  public override getMiddleValue() {
    if (this.isEmpty()) return 500;
    return undefined;
  }

  public override getValueLabel() {
    return "%";
  }
}