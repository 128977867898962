import { ActionTypes, ISettingsViewState } from "./types";
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: ISettingsViewState = {
  roleFilterText: "", 
  userFilterText: ""
};

export const settingsReducer = Utils.Redux.createReducer<ISettingsViewState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_ROLE_FILTER_TEXT]: (state, action) => ({
      ...state,
      roleFilterText: action.value
    }),
    [ActionTypes.SET_USER_FILTER_TEXT]: (state, action) => ({
      ...state,
      userFilterText: action.value
    })
  }
);
