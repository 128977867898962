import getTabEvents, { GetTabEventsOptions } from './getTabEvents';
import updateEvent from './updateEvent';
import listEventChanges from './listEventChanges';
import EventChange from '@classes/GEI/EventChange';
import listEvents from './listEvents';
import { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';
import { countEvents } from './countEvents';

export interface IGlobalEventInbox {
  getTabEvents(
    companyId: string,
    start: number,
    end: number,
    tab?: EventState,
    lastKey?: { s: number, src: string },
    limit?: number,
    options?: GetTabEventsOptions
  ): Promise<APIResponse<VideoStreamEvent[]>>;
  updateEvent(kvsName: string, startDate: number, event: VideoStreamEvent): Promise<void>;
  listEventChanges(kvsName: string, startTimestamp: number): Promise<EventChange[]>;
  listEvents(
    companyId: string,
    start: number,
    end: number,
    timezone: 'local' | "utc",
    tab?: EventState,
    filters?: EventsFilterParams,
    options?: GetTabEventsOptions,
    isSuperAdministrator?: boolean,
    lastKey?: number
  ): Promise<VideoStreamEvent[]>;
  countEvents(
    companyId: string,
    start: number,
    end: number,
    timezone: 'local' | "utc",
    filters?: EventsFilterParams,
    options?: GetTabEventsOptions,
    isSuperAdministrator?: boolean
  ): Promise<EventInboxCount>;
}

const GlobalEventInbox: IGlobalEventInbox = {
  getTabEvents,
  updateEvent,
  listEventChanges,
  listEvents,
  countEvents
}

export default GlobalEventInbox;
