import { API } from 'aws-amplify';

async function getZonesNames(companyId: string): Promise<Map<string, string>> {
  try {
    const path: string = `/geofence/names/${companyId}`;

    const data = await API.get(
      'api-service',
      path,
      {}
    )

    const map = new Map();

    if (data.data) {
      const keys = Object.keys(data.data);
      const values = Object.values(data.data);

      for (let i = 0; i < keys.length; i += 1) {
        if (keys[i] && values[i]) map.set(keys[i], values[i]);
      }
    }

    return map;
  } catch (e) {
    throw e;
  }
}

export default getZonesNames;
