import { Box, Button, Typography } from '@mui/material';
import { IStoreState } from '@store/index';
import Utils from '@utils/index';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

type EventInformationProps = {
  event?: VideoStreamEvent;
  onClose: () => void;
}

const EventInformation: React.FC<EventInformationProps> = (props) => {
  const { event, onClose } = props;

  const sites = useSelector((store: IStoreState) => store.sites.sites);
  const streams = useSelector((store: IStoreState) => store.streams.streams);

  const timezone = React.useMemo(() => {
    const targetStream = streams.find(el => el.kvsName === event?.sourceCamera);
    if (targetStream) {
      const target = sites.get(targetStream.companyId || "")?.find(el => el.siteId === targetStream.siteId)?.timezone;
      if (target) {
        return target.split(' ')[0]
      }
    }

    return "";
  }, [streams, event, sites])
  
  return (
    <Box padding="1.5rem" display="grid" gridTemplateRows="auto auto" gap="1rem">
      <Box display="grid" gridTemplateRows="min-content min-content min-content">
        <Typography variant="h6">Vehicle Details</Typography>
        <Typography variant="h4">{event?.metadata?.plate}</Typography>
        <Typography variant="body1" color="grey">{`${Utils.Date.format(new Date(event?.startDate || 0), "MMM Do, YYYY h:mm:ss A", timezone)}`}</Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 1fr" width="25vw" height="20vh">
        <Box display="grid" gridTemplateRows="1fr 1fr 1fr">
          <Box>
            <Typography variant="body2" color="grey">Region</Typography>
            <Typography variant="body1">{event?.metadata?.region || "-"}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="grey">Make</Typography>
            <Typography>{event?.metadata?.make || "-"}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="grey">Color</Typography>
            <Typography>{_.capitalize(event?.metadata?.color) || "-"}</Typography>
          </Box>
        </Box>
        <Box display="grid" gridTemplateRows="1fr 1fr 1fr">
          <Box>
            <Typography variant="body2" color="grey">Country</Typography>
            <Typography>{event?.metadata?.country || "-"}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="grey">Model</Typography>
            <Typography>{event?.metadata?.model || "-"}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" color="grey">Orientation</Typography>
            <Typography>{event?.metadata?.orientation || "-"}</Typography>
          </Box>
        </Box>
      </Box>
      <Box width="100%" display="flex" flexDirection="column" justifyItems="flex-end" alignItems="flex-end">
        <Button onClick={onClose} variant="text" color="secondary">Close</Button>
      </Box>
    </Box>
  )
}

export default EventInformation;
