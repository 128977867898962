import { Box, Button, Checkbox, Divider, InputAdornment, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, MenuList, TextField, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import React from 'react';
import Utils from '@utils/index';
import SearchHeaderFilter from '@components/common/SearchHeaderFilter';
import { useSelector } from 'react-redux';
import { extractKeywords, matchesKeywordPeriod } from '@utils/filtering/filter-utils';
import SearchHeaderTooltipIcon from '@components/common/SearchHeaderTooltipIcon';
import { selectIsSuperAdminUser } from '@store/selectors';
import { first } from 'lodash';

type SiteSelectorProps = {
  availableSites: Site[];
  selectedSites: Site[];
  onSelectionChange: (sites: Site[]) => void;
  disabled: boolean;
  onSiteListOpen?: () => void;
  onSiteListClose?: () => void;
  singleSite?: boolean;
}

const SiteSelector: React.FC<SiteSelectorProps> = (props) => {
  const { selectedSites, availableSites, onSelectionChange, disabled, onSiteListClose, onSiteListOpen, singleSite } = props;

  const isSuperAdministrator = useSelector(selectIsSuperAdminUser);

  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [filterText, setFilterText] = React.useState<string>("");
  const [filteredSites, setFilteredSites] = React.useState<Site[]>(availableSites);
  const [finalSites, setFinalSites] = React.useState<Site[]>(availableSites)

  const onSiteListCloseRef = React.useRef(onSiteListClose);
  const onSiteListOpenRef = React.useRef(onSiteListOpen);

  React.useEffect(() => { onSiteListCloseRef.current = onSiteListClose }, [onSiteListClose])
  React.useEffect(() => { onSiteListOpenRef.current = onSiteListOpen }, [onSiteListOpen])
  React.useEffect(() => {
    const keywords = extractKeywords(filterText);
    const finalSites = filteredSites.filter((site) => matchesKeywordPeriod(keywords, site, ["name"]))
    setFinalSites(finalSites);
  }, [filterText, filteredSites])

  React.useEffect(() => {
    if (anchorEl !== null && onSiteListOpenRef.current) onSiteListOpenRef.current()
    else if (onSiteListCloseRef.current) onSiteListCloseRef.current();
  }, [anchorEl])

  function getSitesText(): string {
    if (singleSite) return first(selectedSites)?.name || "";

    if (selectedSites.length === availableSites.length) return "All";
    else return `${selectedSites.length}/${availableSites.length} sites`;
  }

  return (
    <Box width="100%">
      <Button
        disabled={disabled || singleSite === true}
        variant="outlined"
        endIcon={<KeyboardArrowDown />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        data-cy="select-zone-button"
        color="secondary"
      >
        <Typography style={{ width: '7rem' }} noWrap={true} variant="button">{getSitesText()}</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setTimeout(() => { setFilterText("") }, 300)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList disablePadding>
          <ListSubheader style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

          }} >
            <TextField
              style={{ marginTop: 0 }}
              autoFocus={true}
              placeholder="Search"
              variant="standard"
              defaultValue={filterText}
              onChange={(event) => { event.stopPropagation(); event.preventDefault(); setFilterText(event.target.value); }}
              onKeyDown={(e) => { e.stopPropagation(); }}
              size="medium"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchHeaderTooltipIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchHeaderFilter
                      properties={Utils.Filters.createSitesFilters(isSuperAdministrator || false, availableSites)}
                      data={availableSites}
                      onFilteredDataChange={(data: any[]) => {
                        setFilteredSites(data);
                      }}
                    />
                  </InputAdornment>
                )
              }}
              data-cy="site-list-search-input"
            />
          </ListSubheader>
        </MenuList>
        <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
        <MenuItem
          style={{ paddingTop: 0, paddingBottom: 0 }}
          dense
          data-cy={`site-selector-all-sites-menu-item`}
          onClick={() => {
            if (selectedSites.length === availableSites.length) {
              onSelectionChange([])
            } else {
              onSelectionChange([...availableSites]);
            }
          }}
        >
          <ListItemIcon
            style={{ minWidth: "2.25rem" }}
          >
            <Checkbox
              color="primary"
              size="small"
              checked={Boolean(selectedSites.length === availableSites.length)}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography noWrap={true} style={{ width: '15rem' }}>All sites</Typography>
          </ListItemText>
        </MenuItem>
        <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
        <MenuList disablePadding style={{ maxHeight: '40vh', overflowY: 'auto' }}>
          {Utils.Array.orderAlphabetically(finalSites, 'name').map(site => (
            <MenuItem dense style={{ paddingTop: 0, paddingBottom: 0 }} key={site.siteId} data-cy={`site-selector-${site?.name}-menu-item`}
              onClick={() => {
                if (Boolean(selectedSites.find(availableSite => availableSite.siteId === site.siteId)))
                  onSelectionChange(selectedSites.filter(el => el.siteId !== site.siteId))
                else
                  onSelectionChange([...selectedSites, site].sort((s1, s2) => (s1?.name || "z").localeCompare((s2?.name || "z"))));
              }}>
              <ListItemIcon
                style={{ minWidth: "2.25rem" }}
              >
                <Checkbox
                  color="primary"
                  size="small"
                  checked={Boolean(selectedSites.find(availableSite => availableSite.siteId === site.siteId))}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography noWrap={true} style={{ width: '15rem' }}>{site.name}</Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}

export default SiteSelector;
