import { API } from "aws-amplify";
import { sortBy } from "lodash";

async function getZones(streamId: string): Promise<Zone[]> {
  try {
    const path = `/geofences/${streamId}`;

    const data: APIResponse<Zone[]> = await API.get(
      'api-service',
      path,
      {}
    );

    if (data.data) {
      const sortedByCreation = sortBy(data.data, ['created']);
      return sortedByCreation.map((el: any) => ({
        ...el,
        name: el.name ? el.name : `Zone ${sortedByCreation.indexOf(el)}`,
        color: el.color ? el.color : `#0048E3`,
        texture: el.texture ? el.texture : `smooth`,
        kvsName: streamId
      }))
    }

    return [];
  } catch (e) {
    return [];
  }
}

export default getZones;
