
import KeyMirror from "keymirror";

export const ActionTypes = KeyMirror({
  POST_REQUEST: null,
  UPDATE_REQUEST_LINK: null,
  SET_REQUEST_AS_DONE: null,
  SET_REQUEST_AS_ERROR: null,
  SET_REQUEST_AS_CANCELED: null,
  POST_DOWNLOAD_REQUEST: null,
  SET_DOWNLOAD_REQUEST_AS_DONE: null,
  SET_DOWNLOAD_REQUEST_AS_ERROR: null,
  SET_DOWNLOAD_REQUEST_AS_CANCELED: null,
  SET_VIDEO_CLIPS_FOR_CAMERA: null,
  POST_TIMELAPSE_REQUEST: null,
  UPDATE_TIMELAPSE_REQUEST_LINK: null,
  SET_TIMELAPSE_REQUEST_AS_DONE: null,
  SET_TIMELAPSE_REQUEST_AS_ERROR: null,
  SET_TIMELAPSE_REQUEST_AS_CANCELED: null,
  SET_TIMELAPSES_FOR_CAMERA: null
});

export interface IExportsState {
  requests: ExportRequest[];
  downloadRequests: DownloadRequest[];
  videoClips: { [key: string]: VideoDownload[] };
  timelapses: { [key: string]: Timelapse[] };
  timelapseRequests: TimelapseRequest[];
}