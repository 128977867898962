import EventType from "@classes/Event/EventType";
import { v4 as uuidv4 } from 'uuid';

function extractChunksFromArray(eventList: VideoStreamEvent[], miliseconds: number, elementSize: number): VideoStreamEventChunk[] {
  const chunks: VideoStreamEventChunk[] = [];
  for (let i = 0; i < eventList.length; i += 1) {
    let start = eventList[i].startDate;
    let end = eventList[i].endDate;

    for (let j = i + 1; j < eventList.length; j += 1) {
      if (start - eventList[j].startDate < miliseconds) {
        start = eventList[j].startDate;
        i += 1;
      } else {
        break;
      }
    }
    chunks.push({
      id: `${start}/${end}/${uuidv4()}`,
      start: new Date(start),
      end: new Date(end),
      style: `background: ${EventType.fromString(eventList[i].eventType).color};
                  height: 1.5vh;
                  border: none;
                  opacity: 0.7`,
      group: eventList[i].eventType
    })
  }

  return chunks;
}

export default extractChunksFromArray;
