import { isEqual, isNull, isUndefined } from "lodash";
import { Field } from "./Field";

export class DateField<T> extends Field<T, number> {
  public override filter(data: Array<T>, target?: number): Array<T> {
    return data.filter((el: any) => {
      const value = this._path?.reduce((a, b) => a ? a[b] : undefined, el)
      if (this._currentOperator === "is_empty") {
        if (!value) return true;
        if (value && value === '') return true;
        return false;
      }

      if (this._currentOperator === "is_not_empty") {
        return value;
      }

      const targetProperty = value as number;
      const parsedTarget = Number.parseInt(target?.toString() || "");

      if (isNaN(parsedTarget)) return true;
      if (isEqual(target, '')) return true;
      if (isNull(target)) return true;
      if (isUndefined(target)) return true;

      if (this._currentOperator === "is") return isEqual(targetProperty, parsedTarget);
      if (this._currentOperator === "is_not") return !isEqual(targetProperty, parsedTarget);
      if (this._currentOperator === "is_after") return target && targetProperty > parsedTarget;
      if (this._currentOperator === "is_on_or_after") return parsedTarget && targetProperty >= parsedTarget;
      if (this._currentOperator === "is_before") return parsedTarget && targetProperty < target;
      if (this._currentOperator === "is_on_or_before") return parsedTarget && targetProperty <= parsedTarget;

      return false;
    })
  }
}