import Tutorial from "./Tutorial";

class EventsReportsTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#reports',
        popover: {
          title: 'Events Reports',
          description: "These reports are centralized hub for accessing event counts based on assigned labels. Simplify your search and streamline data retrival, ensuring efficient access to valuable insights and analysis from past events."
        }
      },
      {
        element: '#header-children',
        popover: {
          title: 'Filters',
          description: 'Select your time range, tags, and cameras to generate a report with your desired data.',
        }
      },
      {
        element: '#reports-company',
        popover: {
          title: 'Expand',
          description: 'Expand the report for a site by site and camera by camera breakdown of the data.',
        }
      }
    ])
  }

}

export default EventsReportsTutorial;
