import { Box, Chip, Paper, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

const Container = styled(Box)`
  :hover {
    cursor: pointer;
  }
`

const OutlinedContainer = styled(Paper)`
  :hover {
    cursor: pointer;
    border: 1px solid black;
    border-radius: 4px;
  }

  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-column-gap: 1rem;
  align-items: center;
  padding-left: 0.8rem;
  padding-right: 0.8rem;

  -webkit-transition : border 50ms ease-out;
  -moz-transition : border 50ms ease-out;
  -o-transition : border 50ms ease-out;
  transition: border 50ms ease-out;

  height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.25)
`;

type TimeRangeProps = {
  text: string;
  chipText?: string;
  expandable: boolean;
  onClick: (e: any) => void;
  width?: string | number | undefined;
  icon?: JSX.Element;
  padding?: string;
  variant?: "outlined";
  focused?: boolean;
}

const TimeRange: React.FC<TimeRangeProps> = (props) => {
  const { text, chipText, expandable, onClick, width, icon, padding, variant, focused } = props;

  if (variant === "outlined") {
    return (
      <OutlinedContainer
        style={{ border: focused ? '1px solid black' : undefined, borderRadius: 10 }}
        onClick={onClick} variant="outlined"
      >
        <Chip
          icon={icon}
          label={chipText ? (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="1.5rem">
              <Typography variant="caption">{chipText}</Typography>
            </Box>
          ) : <Box display="none" />}
          size="small"
          style={{ height: '1.5rem' }}
        />
        <Typography style={{ alignSelf: 'center' }} noWrap={true} variant="body2">{text}</Typography>
        {expandable && (
          <ExpandMore color="action" />
        )}
      </OutlinedContainer>
    )
  }

  return (
    <Container onClick={onClick} width={width} padding={padding || "0.5rem"} display="grid" gridTemplateColumns="min-content auto min-content" columnGap="1rem">
      <Chip
        icon={icon}
        label={chipText ? (
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="1.5rem">
            <Typography variant="caption">{chipText}</Typography>
          </Box>
        ) : <Box display="none" />}
        size="small"
        style={{ height: '1.5rem' }}
      />
      <Typography style={{ alignSelf: 'center' }} noWrap={true} variant="body2">{text}</Typography>
      {expandable && (
        <ExpandMore color="action" />
      )}
    </Container>
  )
}

export default TimeRange;
