import Tutorial from './Tutorial';

class TimelineSaveVideoTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: "#timeline-save-video-type-selector-container",
        popover: {
          title: "Type",
          description: "You can choose between a standard video clip or a timelapse video clip.",
          position: "auto"
        }
      },
      {
        element: "#timeline-save-video-date-selector-container",
        popover: {
          title: "Date range",
          description: "If you are generating a standard video clip, you can download a video that has a maximum duration of 1 hour during a 24 hour period.\nIf you are generating a timelapse video clip, you can specify your date range in the calendar (the timelapse frame rate is lower than the frame rate viewed in a normal sequence so that time appears to be moving faster).",
          position: "auto"
        }
      },
      {
        element: "#timeline-save-video-generate-button",
        popover: {
          title: "Generate video",
          description: "After the Type and Date Range are selected, you can generate a clip that can be viewed and saved in the dashboard, downloaded to your hard drive, or generate a unique URL for sharing.",
          position: "auto"
        }
      },
    ])
  }
}

export default TimelineSaveVideoTutorial;
