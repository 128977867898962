function getTimezone(site?: Site): string | undefined {
  if (site && site.timezone) {
    if (site.timezone.includes(" ")) return site.timezone.split(" ")[0];
    return site.timezone;
  }

  return undefined;
}

export default getTimezone;
