import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { isString } from 'lodash';
import SearchHeaderFilter, { SearchHeaderFilterProps } from './SearchHeaderFilter';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useVerySmallScreenQuery } from '@hooks/useVerySmallScreenQuery';
import { extractKeywords, matchesKeywordPeriod } from '@utils/filtering/filter-utils';
import SearchHeaderTooltipIcon from './SearchHeaderTooltipIcon';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;

  .MuiSelect-iconOutlined {
    right: 1rem;
  }

  .MuiSelect-root {
    .MuiTypography-root {
      margin-left: 0.5rem;
    }
  }

  .MuiButtonBase-root {
    padding-right: 1.375rem;
    padding-left: 1.375rem;
  }
`;

type SearchHeaderProps = {
  title: string | JSX.Element;
  secondaryTitle?: string;
  noPadding?: boolean;
  searchHeaderFilterProps?: SearchHeaderFilterProps;
  children?: React.ReactNode;
  textFilterProps?: string[];
  hideSearchBar?: boolean;
};

type ConditionalSearchHeaderProps = {
  onSearchChange: (e: any) => void;
  filterText: string;
  textFiltering?: undefined | false;
}
  | {
    textFiltering: true;
    onSearchChange?: undefined;
    filterText?: undefined;
  }

const SearchHeader: React.FC<SearchHeaderProps & ConditionalSearchHeaderProps> = (props) => {
  const {
    title,
    onSearchChange,
    children,
    noPadding,
    searchHeaderFilterProps,
    filterText,
    textFiltering,
    textFilterProps,
    hideSearchBar,
    secondaryTitle
  } = props;

  const isMobile = useMobileQuery();
  const isVerySmallScreen = useVerySmallScreenQuery();

  const [localFilterText, setLocalFilterText] = React.useState<string | undefined>("");
  const [localData, setLocalData] = React.useState<any[]>(searchHeaderFilterProps?.data || []);

  const data = React.useMemo(() => searchHeaderFilterProps?.data, [searchHeaderFilterProps?.data]);

  React.useEffect(() => {
    if (data) {
      if (filterText?.length || localFilterText?.length) {
        const keywords = extractKeywords(filterText || localFilterText || "");
        const filteredRows = data.filter((row: any) => matchesKeywordPeriod(keywords, row, textFilterProps)) || [];
        setLocalData(filteredRows);
      } else {
        setLocalData([...data]);
      }
    }
  }, [data, filterText, localFilterText, textFilterProps])

  const commonContent = (
    <>
      <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
        <>
          {isString(title) ? <Typography variant="h4">{title}</Typography> : { ...title }}
          {secondaryTitle && isString(secondaryTitle) ? <Typography variant="body1" color="#3E5060">{secondaryTitle}</Typography> : null}
        </>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box id="search-header-common-field" width="100%" marginRight={children ? "1.5rem" : undefined}>
          {hideSearchBar !== true &&
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Search"
              onChange={(e) => textFiltering ? setLocalFilterText(e.target.value) : onSearchChange(e)}
              value={textFiltering ? localFilterText : filterText}
              style={{ marginTop: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchHeaderTooltipIcon />
                  </InputAdornment>
                ),
                endAdornment: searchHeaderFilterProps ? (
                  <InputAdornment position="end">
                    <SearchHeaderFilter {...searchHeaderFilterProps} data={localData} />
                  </InputAdornment>
                ) : undefined
              }}
              data-cy="header-search-input"
            />
          }
          {hideSearchBar === true && searchHeaderFilterProps &&
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <SearchHeaderFilter {...searchHeaderFilterProps} data={localData} />
            </Box>
          }
        </Box>
      </Box>
      <ActionsContainer>{children}</ActionsContainer>
    </>
  );

  if (isMobile || isVerySmallScreen) {
    return (
      <Box display="grid" gridTemplateRows="auto min-content max-content" gap="1rem" padding={noPadding ? undefined : "1.5rem"}>
        {commonContent}
      </Box>
    );
  }

  return (
    <Box display="grid" gridTemplateColumns={hideSearchBar ? "auto min-content max-content" : "auto minmax(30vw, 35vw) max-content"} padding={noPadding ? undefined : "1.5rem"}>
      {commonContent}
    </Box>
  );
};

export default SearchHeader;
