export function createRolesFilters(isSuperAdministrator: boolean): FilterProperties[] {
  if (isSuperAdministrator === true) {
    return [
      { label: 'Name', property: 'name', type: 'string' },
      { label: 'Description', property: 'description', type: 'string' },
      { label: 'Editable', property: 'editable', type: 'boolean' },
      { label: 'ID', property: 'id', type: 'string' },
      { label: 'Company ID', property: 'companyId', type: 'string' },
      { label: 'Created at', property: 'createdAt', type: 'date' },
      { label: 'Created by (ID)', property: 'createdBy', type: 'string' },
      { label: 'Updated at', property: 'updatedAt', type: 'date' },
      { label: 'Updated by (ID)', property: 'updatedBy', type: 'string' },
      {
        label: 'Permissions', property: 'permissions', type: 'multi_select', options: [
          "ALL",
          "GEOFENCES",
          "TIMELAPSES",
          "TIMELAPSES_VIEW", // - view timelapses
          "EVENTS", // - edit all events
          "EVENTS_VIEW", // - view all events
          "VIEW", // - just view the stream
          "VIDEO_DOWNLOADS", // - complete downloads permission
          "VIDEO_DOWNLOADS_VIEW", // - just view the downloads
          "ANNOTATED_IMAGES", // - complete snapshot permission
          "ANNOTATED_IMAGES_VIEW", // - just view the snapshots
          "HEATMAPS", // - view heatmaps
          "ACTIONS" // - view/edit actions
        ]
      },
    ];
  }

  return [
    { label: 'Name', property: 'name', type: 'string' },
    { label: 'Description', property: 'description', type: 'string' }
  ];
}
