import getDateTimePickerErrorMessage from "./getDateTimePickerErrorMessage";
import getErrorMessage from "./getErrorMessage";

export interface IError {
  getErrorMessage(error: any): string;
  getDateTimePickerErrorMessage(error: string | null | undefined): string | undefined,
}

const Error: IError = {
  getErrorMessage,
  getDateTimePickerErrorMessage
}

export default Error;
