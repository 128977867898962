import React from 'react';
import PanZoom, { IPanZoomHandles } from './PanZoom';
import styled from 'styled-components';
import { Player, PlayerAPI, SourceConfig, PlayerConfig, PlayerEvent, TimelineReferencePoint, PlayerType, StreamType, PlayerEventBase } from 'bitmovin-player';
import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts';
import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';
import { UIFactory, UIManager } from 'bitmovin-player-ui';
import * as uuid from 'uuid';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { PageView } from '@store/session/types';
import KinesisCache from '@classes/KinesisCache';

const Container = styled.div`
  .video {
    z-index: 0 !important;
  }

  min-height: 0px !important;
  min-width: 0px !important;

  .bmpui-ui-watermark {
    display: none;
  }

  .bmpui-controls-hidden {
    all: unset !important;
  }

  .bmpui-ui-controlbar.bmpui-hidden {
    visibility: unset !important;
    opacity: unset !important;
  }

  .bmpui-ui-titlebar.bmpui-hidden {
    visibility: unset !important;
    opacity: unset !important;
  }

  .bmpui-ui-hugeplaybacktogglebutton {
    display: none !important;
  }
`

export type BitmovinSeekEvent = {
  timestamp: number,
  position: number,
  seekTarget: number,
  issuer: "api" | "ui"
}

type BitMovinPlayerProps = React.VideoHTMLAttributes<HTMLVideoElement> & {
  src?: string;
  onHLSError?: (e: any) => void;
  playbackRate?: number;
  handelZoomPanReset?: () => void;
  startPosition?: number;
  onInitialTimestampParsed?: (timestamp: number, endTimestamp: number) => void;
  onBufferStalled?: () => void;
  onBufferAppended?: () => void;
  usePanZoom?: boolean;
  onZoom?: (zoomFactor: number) => void;
  showMinimap?: boolean;
  onTokenExpired?: () => void;
  onTimeChanged?: (timestamp: number, time: number) => void;
  showUi?: boolean;
  kvsName?: string;
  live?: boolean;
  targetTimestamp?: number;
  onSourceLoaded?: (kvsName: string, player: any) => void;
  paused?: boolean;
  minimapMargin?: {
    left: string,
    bottom: string
  },
  onScaleChange?: (scale: number) => void;
  playResetVideoPlayback?: boolean;
  disableDoubleClickZoom?: boolean;
  onBitmovinPlay?: (event: PlayerEventBase) => void;
  onBitmovinPause?: (event: PlayerEventBase) => void;
  onBitmovinReady?: (data: any) => void;
  onBitmovinSeek?: (data: BitmovinSeekEvent) => void;
  onZoomActivate?: (active: boolean) => void;
  onPanZoomMovement?: (transform: { x: number, y: number, scale: number }) => void;
  onCacheReady?: () => void;
}

export interface IBitMovinPlayerHandles {
  videoRef: React.RefObject<HTMLVideoElement>;
  play: () => void;
  pause: () => void;
  enterFullScreen: () => void;
  seek: (seconds: number) => void;
  currentTime: () => number | undefined;
  zoomIn: (value?: number) => void;
  zoomOut: (value?: number) => void;
  setZoom: (value: number) => void;
  isFullscreen: () => boolean;
  duration: () => number;
  setVolume: (value: number) => void;
}

const BitMovinPlayer: React.ForwardRefRenderFunction<IBitMovinPlayerHandles, BitMovinPlayerProps> = (props, ref) => {
  const {
    autoPlay,
    src,
    onHLSError,
    playbackRate,
    onBufferStalled,
    onBufferAppended,
    usePanZoom,
    onZoom,
    showMinimap,
    onTokenExpired,
    onTimeChanged,
    showUi,
    kvsName,
    live,
    targetTimestamp,
    onSourceLoaded,
    paused,
    minimapMargin,
    onScaleChange,
    onEnded,
    playResetVideoPlayback,
    onInitialTimestampParsed,
    disableDoubleClickZoom,
    onBitmovinPlay,
    onBitmovinPause,
    onBitmovinReady,
    onBitmovinSeek,
    onLoadedData,
    onZoomActivate,
    onPanZoomMovement,
    onCacheReady
  } = props;

  React.useImperativeHandle(ref, () => ({
    videoRef,
    play,
    pause,
    enterFullScreen,
    seek,
    currentTime,
    zoomIn,
    zoomOut,
    setZoom,
    isFullscreen,
    duration,
    setVolume
  }))

  const userId = useSelector((store: IStoreState) => store.session.userData?.userId);
  const activePage = useSelector((store: IStoreState) => store.session.activePage);

  const [fullscreen, setFullscreen] = React.useState<boolean>(false);
  const [video, setVideo] = React.useState<HTMLVideoElement | null>(null);

  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const panZoom = React.useRef<IPanZoomHandles | null>(null);
  const srcRef = React.useRef<string | undefined>();
  const player = React.useRef<PlayerAPI>();
  const fullscreenRef = React.useRef<boolean>(false);
  const liveRef = React.useRef<boolean>(live || true);
  const targetTimestampRef = React.useRef<number>(targetTimestamp || 0);
  const firstLoad = React.useRef<boolean>(true);
  const pausedRef = React.useRef<boolean>(false);
  const playbackRateRef = React.useRef<number>(1);
  const sourceChangedRef = React.useRef<boolean>(true);
  const sourceLoadedRef = React.useRef<boolean>(false);
  const isUnmountingRef = React.useRef<boolean>(false);
  const initialTimestampOfVideo = React.useRef<number>(Date.now());
  const isInitialTimestampSet = React.useRef<boolean>(false);
  const showUiRef = React.useRef(showUi)
  const kvsNameRef = React.useRef(kvsName);
  const onEndedRef = React.useRef(onEnded)
  const onSourceLoadedRef = React.useRef(onSourceLoaded)
  const activePageRef = React.useRef(activePage)
  const autoPlayRef = React.useRef(autoPlay)
  const onBitmovinPauseRef = React.useRef(onBitmovinPause);
  const onBitmovinPlayRef = React.useRef(onBitmovinPlay)
  const onBitmovinReadyRef = React.useRef(onBitmovinReady)
  const onBitmovinSeekRef = React.useRef(onBitmovinSeek)
  const onBufferAppendedRef = React.useRef(onBufferAppended)
  const onBufferStalledRef = React.useRef(onBufferStalled)
  const onHLSErrorRef = React.useRef(onHLSError)
  const onInitialTimestampParsedRef = React.useRef(onInitialTimestampParsed)
  const onTimeChangedRef = React.useRef(onTimeChanged)
  const onTokenExpiredRef = React.useRef(onTokenExpired)
  const playResetVideoPlaybackRef = React.useRef(playResetVideoPlayback)
  const onLoadedDataRef = React.useRef(onLoadedData)
  const userIdRef = React.useRef(userId)
  const uiManagerRef = React.useRef<UIManager | null>(null);
  const currentAPICall = React.useRef<number>(0);
  const currentSaveCall = React.useRef<number>(0);
  const onCacheReadyRef = React.useRef(onCacheReady);
  const isCacheReadyRef = React.useRef<boolean>(false);

  const initializePlayer = React.useCallback(() => {
    if (containerRef.current) {
      try {
        const playerConfig: PlayerConfig = {
          key: process.env.REACT_APP_BITMOVING_KEY || "",
          analytics: {
            key: process.env.REACT_APP_BITMOVING_ANALYTICS_KEY,
            userId: userIdRef.current,
            videoId: kvsNameRef.current || '',
            title: activePageRef.current === PageView.Player ? 'Timeline' : activePageRef.current === PageView.Streams ? 'View grid' : 'unknown',
            customData1: userIdRef.current,
            customData2: kvsNameRef.current,
            customData3: activePageRef.current === PageView.Player ? 'Timeline' : activePageRef.current === PageView.Streams ? 'View grid' : 'unknown',
          },
          ui: false,
          playback: {
            autoplay: autoPlayRef.current,
            muted: true,
            preferredTech: [{
              player: PlayerType.Html5,
              streaming: StreamType.Hls
            }]
          },
          tweaks: {
            startup_threshold: 0.5
          },
          buffer: {
            video: {
              forwardduration: 5,
              backwardduration: 5
            }
          },
          network: {
            preprocessHttpResponse: async function (_type, response) {
              if (currentSaveCall.current > 4 && onCacheReadyRef.current && isCacheReadyRef.current === false) {
                onCacheReadyRef.current();
                isCacheReadyRef.current = true;
              }

              if (currentSaveCall.current < 6) {
                KinesisCache.getInstance().set(`${currentSaveCall.current}-${response.url}`, response);
                currentSaveCall.current += 1;
              }

              return response;
            },

            sendHttpRequest: function (_type, request) {
              const target = KinesisCache.getInstance().get(`${currentAPICall.current}-${request.url}`);
              if (target) {
                currentAPICall.current += 1;
                return {
                  getResponse: function () {
                    return Promise.resolve(target as any)
                  },
                  setProgressListener: function () { },
                  cancel: function () { }
                }
              }
              currentAPICall.current += 1;

              return undefined
            }
          },
          events: {
            [PlayerEvent.SourceLoaded]: (data: any) => {
              if (onLoadedDataRef.current) onLoadedDataRef.current(data as any);
            },
            [PlayerEvent.StallStarted]: (_data: any) => {
              if (onBufferStalledRef.current) onBufferStalledRef.current()
            },
            [PlayerEvent.StallEnded]: (_data: any) => {
              if (onBufferAppendedRef.current) onBufferAppendedRef.current();
            },
            [PlayerEvent.TimeChanged]: (_data: any) => {
              if (firstLoad.current === true) {
                firstLoad.current = false;
                if (videoRef.current) {
                  videoRef.current.playbackRate = playbackRateRef.current;
                }
              }
              if (onTimeChangedRef.current) {
                onTimeChangedRef.current(initialTimestampOfVideo.current + (_data.time * 1000), _data.time);
              }
              if (pausedRef.current === true && player.current) {
                player.current.pause()
              }
            },
            [PlayerEvent.Error]: (data: any) => {
              console.log(data);

              onHLSErrorRef.current && onHLSErrorRef.current(data);
              if (data.code === 403 && onTokenExpiredRef.current) {
                onTokenExpiredRef.current();
              }
            },
            [PlayerEvent.Seek]: (data: any) => {
              if (onBitmovinSeekRef.current) onBitmovinSeekRef.current(data as BitmovinSeekEvent)
              if (onBufferStalledRef.current) onBufferStalledRef.current();
            },
            [PlayerEvent.Seeked]: () => {
              if (onBufferAppendedRef.current) onBufferAppendedRef.current();
            },
            [PlayerEvent.Play]: (event: any) => {
              if (playResetVideoPlaybackRef.current === true && sourceChangedRef.current === true) {
                sourceChangedRef.current = false;
                seek(0);
              }

              if (onBitmovinPlayRef.current && event.issuer && event.issuer === "ui") onBitmovinPlayRef.current(event)
            },
            [PlayerEvent.SegmentPlayback]: (data: any) => {
              if (isInitialTimestampSet.current === false) {
                try {
                  const initialTimestamp = data.dateTime.getTime();
                  const videoDurationInMiliseconds = (player.current?.getVideoElement()?.duration || 1) * 1000;
                  const endTimestamp = initialTimestamp + videoDurationInMiliseconds;
                  if (onInitialTimestampParsedRef.current) onInitialTimestampParsedRef.current(initialTimestamp, endTimestamp);
                  initialTimestampOfVideo.current = initialTimestamp;
                  isInitialTimestampSet.current = true;
                } catch (error) {
                  console.log(error);
                }
              }
            },

            [PlayerEvent.Paused]: (event: any) => {
              if (onBitmovinPauseRef.current && event.issuer && event.issuer === "ui") onBitmovinPauseRef.current(event)
            },

            [PlayerEvent.Ready]: (event: any) => {
              if (onBitmovinReadyRef.current) onBitmovinReadyRef.current(event);

              if (Boolean(showUiRef.current) && player.current) {
                uiManagerRef.current = UIFactory.buildModernUI(player.current, { playbackSpeedSelectionEnabled: false });
                window.document.addEventListener('fullscreenchange', handleFullScreenChange);
              }
            }
          },
        }

        Player.addModule(EngineBitmovinModule);
        Player.addModule(MseRendererModule);
        Player.addModule(XmlModule);
        Player.addModule(DashModule);
        Player.addModule(AbrModule);
        Player.addModule(ContainerTSModule);
        Player.addModule(ContainerMp4Module);
        Player.addModule(PolyfillModule);

        player.current = new Player(containerRef.current, playerConfig);
      } catch (error) {
        console.log(error);
        if (
          videoRef &&
          videoRef.current &&
          videoRef.current.canPlayType('application/vnd.apple.mpegurl') &&
          srcRef.current
        ) {
          videoRef.current.src = srcRef.current;
          videoRef.current.addEventListener('loadedmetadata', () => {
            if (videoRef.current) {
              videoRef.current.setAttribute('playsinline', 'true');
              play();
            }
          });
        }
      }
    }
  }, [])

  React.useEffect(initialize, [initializePlayer]);
  React.useEffect(() => { userIdRef.current = userId }, [userId])
  React.useEffect(() => { onLoadedDataRef.current = onLoadedData }, [onLoadedData])
  React.useEffect(() => { onTokenExpiredRef.current = onTokenExpired }, [onTokenExpired])
  React.useEffect(() => { onInitialTimestampParsedRef.current = onInitialTimestampParsed }, [onInitialTimestampParsed])
  React.useEffect(() => { onHLSErrorRef.current = onHLSError }, [onHLSError])
  React.useEffect(() => { onBufferStalledRef.current = onBufferStalled });
  React.useEffect(() => { onBufferAppendedRef.current = onBufferAppended }, [onBufferAppended])
  React.useEffect(() => { onBitmovinSeekRef.current = onBitmovinSeek });
  React.useEffect(() => { onBitmovinPauseRef.current = onBitmovinPause }, [onBitmovinPause])
  React.useEffect(() => { autoPlayRef.current = autoPlay }, [autoPlay])
  React.useEffect(() => { activePageRef.current = activePage }, [activePage])
  React.useEffect(() => { onBitmovinPlayRef.current = onBitmovinPlay }, [onBitmovinPlay])
  React.useEffect(() => { onBitmovinReadyRef.current = onBitmovinReady }, [onBitmovinReady])
  React.useEffect(() => { onTimeChangedRef.current = onTimeChanged }, [onTimeChanged]);
  React.useEffect(() => { playResetVideoPlaybackRef.current = playResetVideoPlayback }, [playResetVideoPlayback])
  React.useEffect(() => { onSourceLoadedRef.current = onSourceLoaded }, [onSourceLoaded])
  React.useEffect(() => { onEndedRef.current = onEnded }, [onEnded])
  React.useEffect(() => { kvsNameRef.current = kvsName }, [kvsName])
  React.useEffect(() => { showUiRef.current = showUi }, [showUi])
  React.useEffect(() => { onCacheReadyRef.current = onCacheReady }, [onCacheReady]);
  React.useEffect(() => {
    return () => {
      if (player.current) {
        isUnmountingRef.current = true;
        if (showUiRef.current) {
          window.document.removeEventListener('fullscreenchange', handleFullScreenChange);
          if (uiManagerRef.current) uiManagerRef.current.release();
        }
        player.current.unload()
          .then(() => {
            return player.current?.destroy();
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }, [])
  React.useEffect(loadSource, [src])
  React.useEffect(() => { sourceChangedRef.current = true }, [src])
  React.useEffect(() => { fullscreenRef.current = fullscreen }, [fullscreen])
  React.useEffect(() => {
    if (videoRef.current && playbackRate) {
      videoRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate])
  React.useEffect(() => { liveRef.current = live || true }, [live]);
  React.useEffect(() => { targetTimestampRef.current = targetTimestamp || 0 }, [targetTimestamp])
  React.useEffect(() => { if (paused !== undefined) pausedRef.current = paused }, [paused])
  React.useEffect(() => { if (playbackRate !== undefined) playbackRateRef.current = playbackRate }, [playbackRate])

  function initialize() {
    initializePlayer();
  }

  function setVolume(value: number) {
    if (value === 0) {
      player.current?.setVolume(value)
      player.current?.mute();
    } else {
      player.current?.unmute();
      player.current?.setVolume(value)
    }
  }

  function handleFullScreenChange(): void {
    setFullscreen(!fullscreenRef.current)
  }

  function loadSource() {
    if (isUnmountingRef.current) return;

    sourceLoadedRef.current = false;

    if (src === undefined && player.current) {
      player.current.unload()
      return;
    }

    isInitialTimestampSet.current = false;

    if (player.current) {
      const sourceConfig: SourceConfig = {
        hls: src,
        options: {
          startOffset: 0,
          startOffsetTimelineReference: TimelineReferencePoint.Start
        }
      }

      firstLoad.current = true;

      player.current?.load(sourceConfig)
        .then(() => {
          return player.current?.preload();
        })
        .then(() => {
          if (player.current?.getVideoElement()) {
            videoRef.current = player.current?.getVideoElement();
            videoRef.current.id = kvsNameRef.current || "";

            videoRef.current.onfullscreenchange = (_e: Event) => {
              setFullscreen(!fullscreenRef.current)
            }

            videoRef.current.onended = (_e: Event) => {
              //@ts-ignore
              if (onEndedRef.current) onEndedRef.current();
            }

            if (player.current && playbackRateRef.current) player.current.setPlaybackSpeed(playbackRateRef.current);

            setVideo(videoRef.current);
          }

          if (onSourceLoadedRef.current) onSourceLoadedRef.current(kvsNameRef.current || "", player.current);
          sourceLoadedRef.current = true;
        })
        .catch((error) => {
          console.log('Error');
          console.log(error);
          sourceLoadedRef.current = false;
        });
    } else {
      console.log('No player was set')
    }
  }

  function duration() {
    if (player.current) return player.current.getVideoElement()?.duration;
    return -1;
  }

  function play() {
    if (player.current && sourceLoadedRef.current) {
      player.current.play();
    }
  }

  function pause() {
    player.current && player.current.pause();
  }

  function seek(seconds: number) {
    if (player.current) {
      player.current.seek(seconds);
      if (onTimeChangedRef.current) onTimeChangedRef.current(initialTimestampOfVideo.current + (seconds * 1000), seconds);
    }
  }

  function enterFullScreen() {
    player.current && player.current.getVideoElement().requestFullscreen();
  }

  function isFullscreen() {
    return fullscreen;
  }

  function currentTime(): number | undefined {
    if (videoRef.current) return videoRef.current.currentTime;
  }

  function zoomIn(value?: number) {
    if (panZoom.current) {
      panZoom.current.zoomIn(value)
    }
  }

  function zoomOut(value?: number) {
    if (panZoom.current) {
      panZoom.current.zoomOut(value)
    }
  }

  function setZoom(value: number) {
    if (panZoom.current) {
      panZoom.current.setZoom(value);
    }
  }

  return (
    <Container id={uuid.v4()} ref={containerRef}>
      {usePanZoom === true && video ? (
        <PanZoom
          ref={panZoom}
          videoRef={video}
          onZoom={onZoom}
          showMinimap={showMinimap}
          isFullscreen={fullscreen}
          minimapMargin={minimapMargin}
          onScaleChange={onScaleChange}
          disableDoubleClickZoom={disableDoubleClickZoom}
          onZoomActivate={onZoomActivate}
          onPanZoomMovement={onPanZoomMovement}
        />
      ) : null}
    </Container>
  )
}

export default React.forwardRef(BitMovinPlayer);