import { API } from 'aws-amplify';

async function mergeWorkers(fromId: number, intoId: number, asCompany?: string): Promise<any> {
  try {
    const path = `/workers/merge` + (asCompany ? `?asCompany=${asCompany}` : "");
    const options = {
      body: {
        fromId,
        intoId
      }
    };
    const { data } = await API.post('api-service', path, options);
    return data;
  } catch (error: any) {
    throw error;
  }
}

export default mergeWorkers;
