import Tutorial from "./Tutorial";

class OverviewSiteMapTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '.mapboxgl-canvas',
        popover: {
          title: 'Site map',
          description: 'Satellite view of all the company sites in a geographical region.',
          position: 'mid-center'
        }
      },
      {
        element: '.mapboxgl-ctrl-icon.mapboxgl-style-switcher',
        popover: {
          title: 'Map type',
          description: 'Different visual representations of an entire area or a part of an area in Dark, Light, Outdoor, Satellite and Street View.',
          position: 'left-bottom'
        }
      }
    ])
  }
}

export default OverviewSiteMapTutorial;
