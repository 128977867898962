import update from 'immutability-helper';
import { ActionTypes, IStreamsState } from './types';
import * as actions from './actions';
import Utils from '@utils/index';
import { uniq } from 'lodash';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IStreamsState = {
  streams: [],
  gridList: [],
  activeGridId: undefined,
  streamThumbnails: [],
  streamInfoList: [],
  mutedStreams: []
};

export const streamsReducer = Utils.Redux.createReducer<IStreamsState, Types, Actions>(
  initialState,
  {
    // Add the stream to the list and the grid
    [ActionTypes.CREATE_STREAM]: (state, action) => ({
      ...update(state, {
        streams: {
          $push: [action.stream]
        }
      })
    }),

    [ActionTypes.DELETE_STREAM]: (state, action) => {
      const streamIndex = state.streams.findIndex(
        stream => stream.kvsName === action.stream.kvsName
      );

      if (streamIndex === -1) {
        return state;
      }

      return update(state, {
        streams: {
          $splice: [[streamIndex, 1]]
        }
      });
    },

    [ActionTypes.SET_STREAM_LIST]: (state, action) => ({
      ...state,
      streams: [...action.streamList]
    }),

    [ActionTypes.SET_STREAM_THUMBNAIL]: (state, action) => ({
      ...state,
      streamThumbnails: action.streamThumbnails
    }),

    [ActionTypes.SET_STREAM_LIST_INFORMATION]: (state, action) => ({
      ...state,
      streamInfoList: action.streamInformationList
    }),

    [ActionTypes.SET_MUTED_STREAMS]: (state, action) => ({
      ...state,
      mutedStreams: action.kvsNames
    }),

    [ActionTypes.ADD_MUTED_STREAM]: (state, action) => ({
      ...state,
      mutedStreams: addToList(action.kvsName, state.mutedStreams)
    }),

    [ActionTypes.REMOVE_MUTED_STREAM]: (state, action) => ({
      ...state,
      mutedStreams: removeFromList(action.kvsName, state.mutedStreams)
    })
  }
);

function addToList(kvsName: KVSName, list: KVSName[]): KVSName[] {
  try {
    return uniq([...list, kvsName]);
  } catch (error) {
    console.log(error);
    return list;
  }
}

function removeFromList(kvsName: KVSName, list: KVSName[]): KVSName[] {
  try {
    return uniq(list.filter(el => el !== kvsName));
  } catch (error) {
    console.log(error);
    return list;
  }
}
