import { API } from 'aws-amplify';

async function deleteSnapshot(
  siteId: string,
  id: string,
): Promise<boolean> {
  try {
    const path = `/annotated-images/${siteId}/${id}`;
    const { data } = await API.del('api-service', path, {});

    return data;
  } catch (error: any) {
    throw error;
  }
}

export default deleteSnapshot;
