import { Auth } from "aws-amplify";

async function submitPhoneConfirmation(code: string): Promise<void> {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    await Auth.verifyUserAttributeSubmit(user, 'phone_number', code);
  } catch (error: any) {
    throw error;
  }
}

export default submitPhoneConfirmation;
