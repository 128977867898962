import Tutorial from "./Tutorial";

class EventsFilterTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#events-filter-roi-container',
        popover: {
          title: 'Select area',
          description: 'Rectangular shaped area that is of the user’s interest.'
        }
      },
      {
        element: '#events-filter-container',
        popover: {
          title: 'Event types',
          description: 'By selecting different event types, the user can filter their search based on that specific event type.',
          position: 'mid-center'
        }
      }
    ])
  }
}

export default EventsFilterTutorial;
