import { Storage } from 'aws-amplify';

async function getCameraPosition(kvsName: string): Promise<StreamPosition[]> {
  try {
    const positions: StreamPosition[] = [];

    const res = await Storage.list(`${kvsName}/latest/`, { bucket: process.env.REACT_APP_SNAPSHOTS_S3_BUCKET || '' });

    const promises = res.results.filter(el => el && el.size && el.size > 0).map(el => {
      return Storage.get(el.key || "",
        {
          bucket: process.env.REACT_APP_SNAPSHOTS_S3_BUCKET,
          contentDisposition: "inline",
          expires: 86400
        }
      );
    })

    const resolvedPromises = await Promise.all(promises);

    resolvedPromises.forEach(el => {
      positions.push({
        kvsName,
        index: resolvedPromises.indexOf(el),
        snapshotUrl: el
      })
    })

    return positions;
  } catch (error) {
    throw error;
  }
}

export default getCameraPosition;
