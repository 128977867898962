import Utils from '@utils/index';

function getStreamTimeChunks(
  stream: VideoStream,
  timestampList: Date[] = [],
  period: number = 300
): VideoStreamTimeChunk[] {
  try {
    const chunkList: VideoStreamTimeChunk[] = [];

    const groupedTimestamps = Utils.CloudWatch.getGroupMetricTimestamps(timestampList, period);
  
    for (let i = 0; i < groupedTimestamps.length; i += 2) {
      chunkList.push({
        period,
        id: `stream-chunk-${chunkList.length}`,
        kvsName: stream.kvsName,
        start: groupedTimestamps[i],
        end: groupedTimestamps[i + 1]
      });
    }
  
    return chunkList;
  } catch (e) {
    return [];
  }
}

export default getStreamTimeChunks;
