import getAccessTimeUrl from './getAccessTimeUrl'
import getHost from './getHost';
import isRtsp from './isRtsp';
import { shorten } from './shorten';

export interface IURL {
  getAccessTimeUrl(url: string): string;

  /**
   * Gets the base path from URL.
   * Example: https://username-password@myhost.com:443/mypath/index.html -> myhost.com
   * @param URL URL to extract the host from.
   * @returns String with host or empty String;
   */
  getHost(url: string): string;
  isRtsp(url: string): boolean;
  shorten(url: string): Promise<string>;
}

const URL: IURL = {
  getAccessTimeUrl,
  getHost,
  isRtsp,
  shorten
}

export default URL;