import { API } from 'aws-amplify';

async function updateSubcontractor(
  subcontractorId: string,
  subcontractor: AddSubForm, 
  asCompany?: string
): Promise<Subcontractor> {
  try {
    const path = `/subcontractors/${subcontractorId}` + (asCompany ? `?asCompany=${asCompany}` : "");
    const { data } = await API.patch('api-service', path, {
      body: subcontractor,
    });

    return data;
  } catch (error: any) {
    throw error;
  }
}

export default updateSubcontractor;
