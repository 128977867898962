import Tutorial from './Tutorial';

class SettingsAuditLogsTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#settings-audit-logs-main-container',
        popover: {
          title: 'Audit log',
          description: 'Set of records showing the sequence of activities that have affected a specific operation, procedure, event, or device.',
          position: 'mid-center'
        }
      },
      {
        element: '#settings-audit-logs-date-range-container',
        popover: {
          title: 'Filters - time range',
          description: 'Filter events by a specific time range set in the calendar.',
          position: 'left'
        }
      },
      {
        element: '#settings-audit-logs-site-selector-container',
        popover: {
          title: 'Filters - site',
          description: 'Filter events by site.',
          position: 'left'
        }
      },
      {
        element: '#settings-audit-logs-users-filter-container',
        popover: {
          title: 'Filters - user',
          description: 'List a specific User’s activity.',
          position: 'left'
        }
      },
      {
        element: '#settings-audit-logs-table-container',
        popover: {
          title: 'Log table',
          description: 'All the recorded activity in the dashboard.',
          position: 'mid-center'
        }
      },
    ])
  }
}

export default SettingsAuditLogsTutorial;
