import extractChunksFromArray from './extractChunksFromArray';
import extractUptimeFromTimeChunks from './extractUptimeFromTimeChunks';

export interface ITimeline {
  extractChunksFromArray: (eventList: VideoStreamEvent[], miliseconds: number, elementSize: number) => VideoStreamEventChunk[];
  extractUptimeFromTimeChunks: (streamChunks: VideoStreamTimeChunk[]) => VideoStreamTimeChunk[]
}

const Timeline: ITimeline = {
  extractChunksFromArray,
  extractUptimeFromTimeChunks
}

export default Timeline;
