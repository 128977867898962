import { isEmpty, merge } from 'lodash';

export class PrometheusQuery {
  protected _queryData: any = null;
  protected _id: string = "base";

  constructor (queryData: any) {
    this._queryData = queryData;
  }

  public getPossibleValues(): { id: string, color: string }[] {
    return [];
  }

  public getRefinedData(): any {
    return this._queryData;
  }

  public getDomain(): any {
    if (this.isEmpty()) return [0, 100];
    return undefined;
  }

  public getMiddleValue(): any {
    if (this.isEmpty()) return 50;
    return undefined;
  }

  public getValueLabel(): any {

  }

  public getId(): string {
    return this._id;
  }

  protected bytesToMegabytes(bytes: number): number {
    return bytes / (1024 ** 2)
  }

  protected kbToMegabytes(kb: number): number {
    return kb / 1024;
  }

  protected formatDate(timestamp: number): string {
    return `${timestamp * 1000}`
  }

  protected bytesToGigabytes(bytes: number): number {
    return parseFloat((bytes / Math.pow(1024, 3)).toFixed(2))
  }

  protected kbToGigabytes(bytes: number): number {
    return parseFloat((bytes / Math.pow(1024, 2)).toFixed(2))
  }

  protected bytesToKb(bytes: number): number {
    return parseFloat((bytes / Math.pow(1024, 1)).toFixed(2))
  }

  protected convertBytes(bytes: number): string {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

    if (bytes === 0) {
      return "n/a"
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024))

    if (i === 0) {
      return bytes + " " + sizes[i]
    }

    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i]
  }

  public static merge(querries: PrometheusQuery[]) {
    try {
      if (querries.length === 1) return querries[0].getRefinedData();

      const data: any[][] = [];
      querries.filter(el => !isEmpty(el.getRefinedData())).forEach(el => data.push(el.getRefinedData()));

      const newRefinedData = [];
  
      if (data.length === 1) return data[0];
  
      if (data[0]) {
        for (let i = 0; i < data[0].length; i += 1) {
          if (data.length === 2) newRefinedData.push(merge(data[0][i], data[1][i]))
          else if (data.length === 3) newRefinedData.push(merge(data[0][i], data[1][i], data[2][i]))
          else if (data.length === 4) newRefinedData.push(merge(data[0][i], data[1][i], data[2][i], data[3][i]))
          else if (data.length === 5) newRefinedData.push(merge(data[0][i], data[1][i], data[2][i], data[3][i], data[4][i]))
          else if (data.length === 6) newRefinedData.push(merge(data[0][i], data[1][i], data[2][i], data[3][i], data[4][i], data[5][i]))
        }
      }
  
      return newRefinedData;
    } catch (error) {
      console.log(error);
      console.log(querries);
      return [];
    }
  }

  protected isEmpty(): boolean {
    if (!this._queryData) return true;
    if (!this._queryData.data) return true;
    if (!this._queryData.data.result) return true;
    if (!this._queryData.data.result[0]) return true;

    return false;
  }
}