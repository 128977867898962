import Tutorial from './Tutorial';

class OverviewEventInboxTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#overview-event-inbox-container',
        popover: {
          title: 'Event inbox',
          description: 'Video gallery of all events detected on all company sites listed in chronological order. Events are categorized by type: Motion, Zones, Fire, PPE, Social Distance, and Vehicle License Plate.',
          position: 'mid-center'
        }
      },
      {
        element: '#overview-event-inbox-tabs',
        popover: {
          title: 'Unassigned / assigned / archived / trash',
          description: 'Classification of events based on event significance. The Unassigned tab gathers all incoming events and orders them by time. The Assigned tab handles events that were specifically appointed to team members for further discussion. The Archived tab handles processed events and the Trash tab events have no significance.',
          position: 'right'
        }
      },
      {
        element: '#overview-event-inbox-event-list',
        popover: {
          title: 'Events',
          description: 'List of events.',
          position: 'right'
        }
      },
      {
        element: '#overview-event-inbox-event-content-container',
        popover: {
          title: 'Event information',
          description: 'Lists the Event type, Company, Site,  Stream information and the duration of the event.',
          position: 'mid-center'
        }
      },
      {
        element: '#overview-event-inbox-event-content-video',
        popover: {
          title: 'Video clip',
          description: 'You can watch and inspect the triggered event.',
          position: 'mid-center'
        }
      },
      {
        element: '#overview-event-inbox-events-filter-container',
        popover: {
          title: 'Filters',
          description: 'Filter events by your interest and preference. You can select sites of interest and do a search for a specific time range with single or combined event types.',
          position: 'left'
        }
      },
    ])
  }
}

export default OverviewEventInboxTutorial;
