import Tutorial from "./Tutorial";

class ViewsSelectedViewTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#views-grid-button',
        popover: {
          title: "Grid",
          description: 'Allows the user to have a Grid overview of all custom selected streams on the page.',
          position: 'left'
        }
      },
      {
        element: '#views-map-button',
        popover: {
          title: "Map",
          description: "Shows a bird's-eye view of the current site on a map and cameras with their exact position, angle and depth of view.",
          position: 'left'
        }
      },
      {
        element: '#selected-view-cameras-container',
        popover: {
          title: "Views",
          description: 'Custom site overview',
          position: 'mid-center'
        }
      },
      {
        element: '#selected-view-timeline',
        popover: {
          title: "Timeline",
          description: 'The timeline allows you to easily navigate to a specific day and time and view your custom layout.',
          position: 'top'
        }
      },
      {
        element: '#selected-view-play-and-pause-button',
        popover: {
          title: "Pause",
          description: 'Pause camera stream.',
          position: 'top'
        }
      },
      {
        element: '#selected-view-playbackrate-button',
        popover: {
          title: "Speed up",
          description: 'By looking at past events the user can fast forward them up to 4x simultaneously on all streams present in the custom view.',
          position: 'left-center'
        }
      },
    ])
  }
}

export default ViewsSelectedViewTutorial;
