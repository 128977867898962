import SiteMarker from '@classes/MapBox/SiteMarker';
import mapboxgl from 'mapbox-gl';
import React from 'react';

type MapboxSiteMarkerProps = {
  map: mapboxgl.Map | null;
  site: Site;
  onClick: (site: Site) => void;
  onCoordinateAquired: (siteId: string, coordinates: number[]) => void;
}

const MapboxSiteMarker: React.FC<MapboxSiteMarkerProps> = (props) => {
  const { map, site, onClick, onCoordinateAquired } = props;

  const markerRef = React.useRef<SiteMarker | null>(null);
  const mapRef = React.useRef(map);
  const onClickRef = React.useRef(onClick);
  const onCoordinateAquiredRef = React.useRef(onCoordinateAquired);
  const siteRef = React.useRef<Site>(site);

  React.useEffect(() => { mapRef.current = map }, [map]);
  React.useEffect(() => { onClickRef.current = onClick }, [onClick]);
  React.useEffect(() => { onCoordinateAquiredRef.current = onCoordinateAquired }, [onCoordinateAquired])
  React.useEffect(() => { siteRef.current = site }, [site])

  React.useEffect(placeSiteMarker, []);

  function placeSiteMarker() {
    if (mapRef.current && siteRef.current.coordinates && siteRef.current.coordinates.latitude && siteRef.current.coordinates.longitude) {
      onCoordinateAquiredRef.current(siteRef.current.siteId, [siteRef.current.coordinates.longitude, siteRef.current.coordinates.latitude]);
      const marker = new SiteMarker()
        .setLngLat(new mapboxgl.LngLat(siteRef.current.coordinates.longitude, siteRef.current.coordinates.latitude))
        .setDraggable(false)
        .withLabel(mapRef.current as mapboxgl.Map, siteRef.current.name);

      const markerDiv = marker.getElement();
      markerDiv.addEventListener('click', (e) => { onClickRef.current(siteRef.current); });

      marker.addTo(mapRef.current);
      markerRef.current = marker;
    }

    return () => {
      if (markerRef.current && mapRef.current) {
        markerRef.current.cleanUp(mapRef.current);
        markerRef.current.remove();
      }
    }
  }

  return null;
}

export default MapboxSiteMarker;
