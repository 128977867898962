import React from 'react';
import styled from 'styled-components';
import { TimelineEventGroupID } from '@classes/StreamEvents/StreamEvents';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography, Zoom } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { List, ListRowProps } from 'react-virtualized';
import EventListItem from './EventListItem';
import { replace } from 'lodash';

const CustomTypography = styled(Typography) <{ ongoing?: boolean }>`
  color: black;
  animation-name: ${props => props.ongoing === true ? 'typocolor' : undefined};
  animation-duration: 1s;
  animation-iteration-count: ${props => props.ongoing === true ? 'infinite' : undefined};

  @keyframes typocolor {
    0% {
      color: black;
    }
    50% {
      color: #5f8dee;
    }
    100% {
      color: black;
    }
  }
`

type EventsGroupProps = {
  type: TimelineEventGroupID;
  events: VideoStreamEvent[];
  disabled?: boolean;
  onEventClick?: (event: VideoStreamEvent, animateTimeline?: boolean) => void;
  maxHeightPx: number;
  rowHeight: number;
  onOpen?: (type: TimelineEventGroupID) => void;
  onClose?: (type: TimelineEventGroupID) => void;
  stream?: VideoStream;
  expanded: boolean;
  timezone?: string;
  onMouseEnterEvent?: (event: VideoStreamEvent) => void;
  onMouseLeaveEvent?: () => void;
  zones: Zone[];
  onMarkAsReadClick?: (data: VideoStreamEvent) => void;
  onInformationClick?: (data: VideoStreamEvent) => void;
}

const EventsGroup: React.FC<EventsGroupProps> = (props) => {
  const {
    type,
    disabled,
    events,
    onEventClick,
    maxHeightPx,
    rowHeight,
    onOpen,
    onClose,
    stream,
    expanded,
    timezone,
    onMouseEnterEvent,
    onMouseLeaveEvent,
    zones,
    onMarkAsReadClick,
    onInformationClick
  } = props;

  const divRef = React.useRef<HTMLDivElement | null>(null);

  function rowRenderer(props: ListRowProps): JSX.Element {
    return (
      <div
        key={props.key}
        style={props.style}
      >
        <EventListItem
          event={events[props.index]}
          onEventClick={onEventClick}
          stream={stream}
          timezone={timezone}
          onMouseEnterEvent={onMouseEnterEvent}
          onMouseLeaveEvent={onMouseLeaveEvent}
          zones={zones}
          onMarkAsReadClick={onMarkAsReadClick}
          onInformationClick={onInformationClick}
          key={`${events[props.index].startDate}/${events[props.index].endDate}`}
          rowHeight={rowHeight}
        />
      </div>
    )
  }

  return (
    <Accordion
      onMouseLeave={() => { if (onMouseLeaveEvent) onMouseLeaveEvent(); }}
      expanded={expanded}
      onChange={(_event: React.ChangeEvent<{}>, expanded: boolean) => {
        if (expanded === true && onOpen) onOpen(type)
        else if (onClose) onClose(type);
      }}
      disabled={disabled}
      id={`${replace(type, " ", "-").toLowerCase()}-count-description`}
    >
      <AccordionSummary
        expandIcon={disabled === false ? <ExpandMore fontSize="small" /> : undefined}
      >
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <CustomTypography ongoing={Boolean(events.find(el => el.ongoing === true))} variant="h6" >{`${type} (${events.length})`}</CustomTypography>
          <Box marginRight="0px">
            <Zoom in={Boolean(events.find(el => el.new === true))}>
              <Chip
                style={{ margin: 0 }}
                label={`${events.filter(el => el.new === true).length} new`}
              />
            </Zoom>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <div ref={divRef}>
          {/* @ts-ignore */}
          <List
            height={maxHeightPx}
            width={divRef.current?.scrollWidth || 0}
            rowCount={events.length}
            rowHeight={rowHeight}
            rowRenderer={(props: ListRowProps) => rowRenderer(props)}
            overscanRowCount={10}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default EventsGroup;
