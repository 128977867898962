import Tutorial from "./Tutorial";

class HeatmapsDialogTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#select-heatmap',
        popover: {
          title: 'Timeframe',
          description: "Select the specific timeframe for viewing the heatmap."
        }
      },
      {
        element: '#heatmap-edit',
        popover: {
          title: 'Edit',
          description: 'Choose from a number of editing tools for marking up the heatmaps.',
        }
      },
      {
        element: '#heatmap-download',
        popover: {
          title: 'Download',
          description: 'Click here to download this specific heatmap.'
        }
      },
      {
        element: '#heatmap-next',
        popover: {
          title: 'Navigate',
          description: 'Click here to navigate through the heatmap timeline.'
        }
      }
    ])
  }

}

export default HeatmapsDialogTutorial;
