function removeChars(target: string, chars: string): string {
  let result: string = target;
  for (let i = 0; i < chars.length; i+=1) {
    if (target.includes(chars.charAt(i))) {
      while (result.includes(chars.charAt(i))) {
        result = result.replace(chars.charAt(i), '');
      }
    }
  } 
  
  return result;
}

export default removeChars;
