import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_USERS: null,
});

export interface IUsersState {
  users: User[],
}

export interface ISessionUser {
  email: string;
  userId: string;
  preferredMFA?: string;
  username?: string;
  attributes?: {
    phone_number?: string;
    phone_number_verified?: boolean;
  }
}

export enum UserStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
  Invited = 'Invited'
}

export enum UserRole {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  SiteAdmin = 'siteadmin',
  Restricted = 'restricted-access',
  Readonly = 'readonly'
}