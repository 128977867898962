export function createWorkersFilters(isSuperAdministrator: boolean): FilterProperties[] {
  const filters: FilterProperties[] = [
    { label: 'Name', property: 'givenName', type: 'string' },
    { label: 'Foreman', property: 'foremanName', type: 'string' },
    { label: 'Subcontractor', property: 'subcontractorName', type: 'string' },
    { label: 'Email', property: 'email', type: 'string' },
    { label: 'Role', property: 'type', type: 'string' },
    { label: 'Trade', property: 'trade', type: 'string' },
    { label: 'Certifications', property: 'certification', type: 'string' },
  ]

  if (isSuperAdministrator === true) {
    filters.push({ label: 'Id', property: 'id', type: 'string' })
    filters.push({ label: 'Foreman Id', property: 'foremanId', type: 'string' })
    filters.push({ label: 'Subcontractor Id', property: 'subcontractorId', type: 'string' })
  }

  return filters;
}
