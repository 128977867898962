import { useKeyPress } from '@hooks/KeyPress/KeyPressHooks';
import { Typography } from '@mui/material';
import { Error, Info, Search, Warning } from '@mui/icons-material';
import { Images } from '@utils/Styles';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isString } from 'lodash';
import { useMobileQuery } from '@hooks/useMobileQuery';


const NonIdealStateContainer = styled.div<{ padding?: string, mobile: boolean }>`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content min-content min-content min-content min-content;
  align-items: center;
  justify-items: center;
  text-align: center;
  width: 100%;
  padding: ${props => props.padding ? props.padding : props.mobile ? "15vh 2rem" : "25vh 2rem"};
  grid-gap: 1rem;
`
interface IProps {
  icon?: 'warning' | 'error' | 'info' | 'search' | 'none' | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  title?: string;
  description?: React.ReactNode;
  action?: JSX.Element;
  children?: React.ReactNode;
  padding?: string;
  onClose?: () => void;
  errorCode?: number;
  id?: string;
}

const NonIdealState: React.FC<IProps> = (props) => {
  const { icon, title, description, action, children, onClose, padding, errorCode, id } = props;

  const isMobile = useMobileQuery();

  useKeyPress("Escape", () => onClose && onClose(), [])
  const [errorImage, setErrorImage] = useState("");
  const [selectedIcon, setSelectedIcon] = useState<React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined>(undefined);

  useEffect(() => {
    let errorImageSrc: { default?: string } = {};
    switch (errorCode) {
      case 404:
        errorImageSrc = Images.NOT_FOUND_404;
        break;
      case 403:
        errorImageSrc = Images.FORBIDDEN_403;
        break;
    }
    if (errorImageSrc.default)
      setErrorImage(errorImageSrc.default);
  }, [errorCode])

  useEffect(() => {
    switch (icon) {
      case 'warning':
        setSelectedIcon(<Warning />);
        break;
      case 'error':
        setSelectedIcon(<Error />);
        break;
      case 'info':
        setSelectedIcon(<Info />);
        break;
      case 'search':
        setSelectedIcon(<Search />);
        break;
      case 'none':
        setSelectedIcon(undefined);
        break;
      default:
        setSelectedIcon(icon);
        break;
    }
  }, [icon])

  return (
    <NonIdealStateContainer mobile={isMobile} id={id} padding={padding} data-cy="non-ideal-state-container">
      {selectedIcon ? React.cloneElement(selectedIcon, { color: "disabled", style: { fontSize: "8rem" } }) : errorImage ? <img src={errorImage} alt="Something went wrong.." /> : null}
      {title && <Typography variant="h5" style={{ margin: 0, padding: 0 }} data-cy="non-ideal-state-title">{title}</Typography>}
      {isString(description) ? (
        <Typography variant="body2" style={{ width: "20rem" }} data-cy="non-ideal-state-description">{description}</Typography>

      ) : (
        <>{description}</>
      )
      }
      {children}
      <br />
      {action}
    </NonIdealStateContainer>
  )
}

export default NonIdealState
