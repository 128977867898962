import Utils from "@utils/index";

const EVENT_TYPE_MAP = new Map([
  ["M", "Motion"],
  ["SI", "Safety infraction"],
  ["GT", "Geofence triggered"],
  ["PD", "Person detected"],
  ["VD", "Vehicle detected"],
  ["VC", "Viewpoint changed"],
  // legacy
  ["SD", "Social distancing"],
  ["FD", "Fire detected"],
  ["LPD", "License plate detected"]
])

/**
 * Factory class that converts an Websocket event into a VideoStreamEvent.
 */
class EventConverter {
  private _stream: VideoStream | undefined;

  /**
   * Stream is optional to add companyId and siteId to events.
   */
  constructor(stream?: VideoStream) {
    this._stream = stream;
  }

  /**
   * Makes the conversion of Websocket Event into VideoStreamEvent.
   */
  public convert(wsevent: WebsocketVideoStreamEvent): VideoStreamEvent {
    return {
      id: `${wsevent.src}-${wsevent.s}`,
      eventType: EVENT_TYPE_MAP.get(wsevent.t) || wsevent.t,
      endDate: wsevent.e && wsevent.e !== 0 ? +wsevent.e : wsevent.ce && wsevent.ce !== 0 ? +wsevent.ce : 0,
      currentEnd: wsevent.ce ? +wsevent.ce : 0,
      startDate: +wsevent.s,
      duration: ((wsevent.e && wsevent.e !== 0 ? +wsevent.e : wsevent.ce && wsevent.ce !== 0 ? +wsevent.ce : 0) || (wsevent.ce ? +wsevent.ce : 0)) - (+wsevent.s/100),
      sourceCamera: wsevent.src,
      labels: wsevent.l,
      new: false,
      companyId: wsevent.ct ? wsevent.ct : this._stream?.companyId || "",
      siteId: wsevent.cs ? wsevent.cs : this._stream?.siteId || "",
      tab: 'unassigned',
      ongoing: wsevent.ce && wsevent.ce !== 0 ? true : false,
      zoneId: wsevent.gid ? [wsevent.gid] : wsevent.g ? [wsevent.g] :  undefined,
      tags: [],
      escalations: [],
      arrived: Date.now()
    }
  }

  /**
   * Makes a DynamoDB event into a VideoStreamEvent.
   */
  public convertFromDynamoDB(event: GlobalEvent): VideoStreamEvent {
    return {
      id: `${event.c || this._stream?.kvsName || ""}-${event.s}`,
      sourceCamera: event.c || this._stream?.kvsName || "",
      startDate: +event.s,
      endDate: +event.e,
      duration: (+event.e) - (+event.s/100),
      eventType: EVENT_TYPE_MAP.get(event.t) || "Motion",
      labels: Array.isArray(event.l) ? event.l : typeof event.l === "string" ? [event.l] : undefined,
      zoneId: event.g ? [event.g] : event.gid ? [event.gid] : undefined,
      new: false,
      ongoing: false,
      siteId: event.cs || "",
      companyId: event.cs && event.cs.includes('-') ? event.cs.split('-')[0] : event.cs || "",
      owner: event.o,
      tags: event.tg ? event.tg as EventTag[] : event.ts ? event.ts as EventTag[] : [],
      escalations: event.tg ? event.tg as EventTag[] : [],
      tab: this.assertTab(event),
      metadata: event.m ? event.m : undefined,
      arrived: Utils.Timestamp.edgeTimestampToMiliseconds(+event.s)
    }
  }

  private assertTab(event: GlobalEvent): EventState {
    if (event.tg && event.tg[0] && event.tg[0] === "muted") {
      return "muted";
    }

    if (event.ts && event.ts[0] && event.ts[0] === "muted") {
      return "muted";
    }

    if (event.ct) {
      if (event.ct.includes('#')) return event.ct.split('#')[1] as EventState
      
      if (event.ct === "archived" || event.ct === "assigned" || event.ct === "unassigned" || event.ct === "trash" || event.ct === "muted") return event.ct;
    }

    return "unassigned";
  }
}

export default EventConverter;
