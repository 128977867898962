export function createSubcontractorsFilters(isSuperAdministrator: boolean): FilterProperties[] {
  const filters: FilterProperties[] = [
    { label: 'Name', property: 'name', type: 'string' },
    { label: 'Address', property: 'address', type: 'string' },
    { label: 'City', property: 'city', type: 'string' },
    { label: 'State', property: 'state', type: 'string' },
    { label: 'Zip', property: 'zip', type: 'string' },
    { label: 'Foreman', property: 'foremenOnSite', type: 'number' },
    { label: 'Workers', property: 'crewSize', type: 'number' },
  ]

  if (isSuperAdministrator === true) {
    filters.push({ label: 'Subcontractor Id', property: 'subcontractorId', type: 'string' })
  }

  return filters;
}
