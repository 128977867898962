import { Endpoint, KinesisVideo, KinesisVideoArchivedMedia } from "aws-sdk";


export type ListFragmentOptions = {
  startOffset: number;
  endOffset: number;
  maxResults: number;
}

async function listStreamFragments(
  streamName: string,
  options: KinesisVideo.ClientConfiguration,
  dataEndpoint: string,
  targetStart: Date,
  targetEnd: Date,
  config: ListFragmentOptions
): Promise<KinesisVideoArchivedMedia.FragmentList> {
  return new Promise((resolve) => {
    const { startOffset, endOffset, maxResults } = config;

    const kinesisVideoArchivedContent = new KinesisVideoArchivedMedia(options);
    kinesisVideoArchivedContent.endpoint = new Endpoint(dataEndpoint);

    kinesisVideoArchivedContent.listFragments({
      "FragmentSelector": {
        "FragmentSelectorType": "PRODUCER_TIMESTAMP",
        "TimestampRange": {
          "StartTimestamp": new Date(targetStart.getTime() - startOffset),
          "EndTimestamp": new Date(targetEnd.getTime() + endOffset)
        }
      },
      "MaxResults": maxResults,
      "StreamName": streamName
    }, (err, data) => {
      if (err) {
        resolve([]);
      }

      if (data.Fragments) {
        resolve(data.Fragments);
      }
      resolve([]);
    })
  })
}

export default listStreamFragments;
