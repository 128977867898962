import getSnapshotGraph from "./getSnapshotGraph";
import getStreamMetrics from "./getStreamMetrics";
import { CloudWatch as AWSCloudWatch } from "aws-sdk";

export interface IGetStreamMetrics {
  getStreamMetrics: (streamName: string | string[],
    options: AWSCloudWatch.ClientConfiguration,
    startDate: Date,
    endDate: Date,
    metricName?: string,
    period?: number,
    stat?: string) => Promise<AWSCloudWatch.GetMetricDataOutput>;
}

export interface ICloudWatch extends IGetStreamMetrics {
  getSnapshotGraph: (options: AWSCloudWatch.ClientConfiguration,
    stacked: Boolean,
    metrics: string[][],
    width: number,
    height: number,
    start: string,
    end: string,
    title?: string,) => Promise<AWSCloudWatch.GetMetricWidgetImageOutput>;
}

const CloudWatch: ICloudWatch = {
  getStreamMetrics,
  getSnapshotGraph
}

export default CloudWatch;
