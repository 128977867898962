export class Icons {
  static ICON_GEOFENCE = require('../assets/images/icons/icon_geofence.png');
  static ICON_CALENDAR = require('../assets/images/icons/icon_calendar.png');
  static ICON_EVENT_INFRACTION = require('../assets/images/icons/icon_event_infraction.png');
  static ICON_EVENT_MOTION = require('../assets/images/icons/icon_event_motion.png');
  static ICON_EVENT_DISTANCE = require('../assets/images/icons/icon_event_distance.png');
  static ICON_EVENT_FIRE = require('../assets/images/icons/icon_event_fire.png');
  static ICON_EVENT_LICENCE = require('../assets/images/icons/icon_event_licence.png');
}

export class Logos {
  static FORSIGHT_LOGO = require('../assets/images/logos/forsight_logo_icon.svg').default;
  static FORSIGHT_LOGO_TEXT = require('../assets/images/logos/forsight_logo_text.svg').default;
  static PROCORE_LOGO = require('../assets/images/logos/procore.png');
}

export class Images {
  static IMAGE_NOT_FOUND = "Not Found";
  static NOT_FOUND_404 = require('../assets/images/404.svg');
  static FORBIDDEN_403 = require('../assets/images/403.svg');
}

export class Colors {

  static EVENT_MOTION = '#F6E654';
  static EVENT_GEOFENCE = '#50BDFF';
  static EVENT_INFRACTION = '#FE8A6B';
  static EVENT_DISTANCE = '#33b74f';
  static EVENT_FIRE = '#FFA500';
  static EVENT_LICENCE = '#DA70D6'
  static ERROR = '#BF260E';
  static PRIMARY = '#0048E3';
  static WHITE = '#FFFFFFDE';
  static SECONDARY_MAIN = '#323232';
  static BORDER_GREY = 'rgba(0, 0, 0, 0.12)';
  static PRIMARY_MAIN = '#0048E3';
  static PRIMARY_LIGHT = '#5F8DEE';
  static TEXT_DISABLE = '#00000061';
  static TEXT_SECONDARY = '#000000A6';
  static PRIMARY_BACKGROUND = '#e5edfc';
}

export class Inverters {
  static WHITE_TO_GREY = 'invert(82%) sepia(0%) saturate(3067%) hue-rotate(328deg) brightness(84%) contrast(84%)';
}
