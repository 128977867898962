import { last, sortBy } from "lodash";

class VideoBoundingBoxes {
  private _boundingBoxes: EventBoundingBox[] | undefined = undefined;
  private _currentTimestamp: number = 0;
  private _kvsName: string;

  constructor (kvsName: string, apiData?: EventBoundingBox[]) {
    if (apiData) {
      this._boundingBoxes = sortBy(apiData, 'timestamp');
    }
    this._kvsName = kvsName;
  }

  public setCurrentTimestamp(timestamp: number): void {
    this._currentTimestamp = timestamp;
  }

  public getCurrentBoundingBox(): EventBoundingBox | undefined {
    const closest = this.findClosest(this._currentTimestamp);
    return closest;
  }

  public isEmpty(): boolean {
    return this._boundingBoxes === undefined || this._boundingBoxes.length === 0;
  }

  public getLast(): EventBoundingBox | undefined {
    if (this._boundingBoxes) {
      return last(this._boundingBoxes);
    }
  }

  public getFirst(): EventBoundingBox | undefined {
    if (this._boundingBoxes) {
      return last(this._boundingBoxes);
    }
  }

  public getKvsName(): string {
    return this._kvsName;
  }

  private findClosest(target: number) {
    if (this._boundingBoxes && !this.isEmpty()) {
      const box = this._boundingBoxes.reduce(function (prev, current) {
        return (Math.abs(target - current.timestamp) < Math.abs(target - prev.timestamp) ? current : prev)
      })
      if (box) {
        if ((box.timestamp - target) >= 1000 || (target - box.timestamp >= 1000)) return undefined;
        else return box;
      }
    }
  }
}

export default VideoBoundingBoxes;
