import moment from 'moment';
import React from 'react';
import { Timeline, DataSet } from 'vis-timeline/standalone';
import './timeline.css';
import { ColorMap, TimelineEventMap } from '@classes/StreamEvents/StreamEvents';
import ZoomLevel from '@classes/Timeline/ZoomLevel';
import Utils from '@utils/index';
import { first, isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { TIMELINE_GROUPS, generateMinItem, generateTimeItem } from './VideoTimeline';

const TODAY = new Date(Date.now());
const START_DATE = new Date(Date.now());
const END_DATE = new Date(Date.now());
START_DATE.setDate(TODAY.getDate() - 15);
END_DATE.setDate(TODAY.getDate() + 15);

export type TimelineVisibilityHint = {
  motion?: boolean;
  fire?: boolean;
  safetyInfraction?: boolean;
  socialDistance?: boolean;
  vehicleLicensePlate?: boolean;
  zones?: boolean;
}

type MultiStreamTimelineProps = {
  timezone?: string;
  onClick: (date: Date) => void;
  timeEvents: VideoStreamTimeChunk[];
  events: VideoStreamEvent[];
  onTimeRangeChange?: (start: Date, end: Date) => void;
  timeRange?: { start: Date, end: Date };
  timerangeBackground?: boolean;
  features: StreamFeatures;
  min?: number;
  highlightEvents?: VideoStreamEvent[];
  mouseMarker?: boolean;
  onEventClick?: (id: string) => void;
  noPadding?: boolean;
  renderEventsTags?: boolean;
  hint?: TimelineVisibilityHint;
  streams: VideoStream[];
}

export interface IMultiStreamTimelineHandles {
  updateTime: (timestamp: number, id?: string) => void;
  animateTo: (timestamp: number) => void;
  markEventTime: (event: VideoStreamEvent) => void;
  clearEventTime: () => void;
  moveTo: (start: Date, end: Date) => void;
}

const customTimeID = 'custom-time';

const MultiStreamTimeline: React.ForwardRefRenderFunction<IMultiStreamTimelineHandles, MultiStreamTimelineProps> = (props, ref) => {
  const {
    timezone,
    onClick,
    events,
    timeRange,
    timerangeBackground,
    onTimeRangeChange,
    timeEvents,
    min,
    highlightEvents,
    mouseMarker,
    onEventClick,
    noPadding,
    streams
  } = props;

  const [timeline, setTimeline] = React.useState<any>(null);
  const [timelineZoom, setTimelineZoom] = React.useState<number>(0);
  const [timerangeBackgroundVisible, setTimerangeBackgroundVisible] = React.useState<boolean>(true);

  const containerRef = React.useRef(null);
  const rangeChanged = React.useRef<boolean>(false);
  const previousZoom = React.useRef<number>(0);
  const zoomByUser = React.useRef<boolean>(false);
  const timerangeMarkerMoved = React.useRef<boolean>(false);
  const timeRangeRef = React.useRef<{ start: Date, end: Date }>();
  const eventsRef = React.useRef<VideoStreamEvent[]>(events)
  const timeEventsRef = React.useRef<VideoStreamTimeChunk[]>([]);
  const minRef = React.useRef<number>(min || 0);
  const mouseFeedbackRef = React.useRef<Element | undefined>(undefined);
  const timezoneRef = React.useRef<string | undefined>(timezone);
  const highlightEventsRef = React.useRef(highlightEvents);
  const timerangeBackgroundVisibleRef = React.useRef(timerangeBackgroundVisible);
  const mouseMarkerRef = React.useRef(mouseMarker);
  const onClickRef = React.useRef(onClick);
  const onEventClickRef = React.useRef(onEventClick);
  const onTimeRangeChangeRef = React.useRef(onTimeRangeChange);
  const timelineRef = React.useRef(timeline);
  const streamsRef = React.useRef(streams);

  React.useImperativeHandle(ref, () => ({
    updateTime,
    animateTo,
    markEventTime,
    clearEventTime,
    moveTo
  }))

  const lateUpdate = React.useCallback((ref: any) => {
    if (ref && timeRangeRef.current) {
      const e: any[] = [];

      eventsRef.current.forEach(event => {
        const isSelected = highlightEventsRef.current?.find(el =>
          el.sourceCamera === event.sourceCamera &&
          el.startDate === event.startDate &&
          el.endDate === event.endDate
        ) !== undefined;
        e.push({
          id: `${event.sourceCamera}-${event.startDate}#${uuidv4()}`,
          start: new Date(Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate)),
          end: new Date(event.endDate),
          style: `background: ${ColorMap.get(event.eventType)};
                  outline: ${isSelected ? '2px ridge rgba(0, 72, 227, 0.8)' : 'none'};
                  border: none;
                  height: 1rem;
                  z-index: ${isSelected ? '9999' : '1'}`,
          group: streamsRef.current.length === 1 ? TimelineEventMap.get(event.eventType) : streamsRef.current.find(el => el.kvsName === event.sourceCamera)?.kvsName || "",
          // title: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined,
          // content: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined
        });

        if (isArray(event.children)) {
          event.children.forEach((event) => {
            e.push({
              id: `${event.sourceCamera}-${event.startDate}#${uuidv4()}`,
              start: new Date(Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate)),
              end: new Date(event.endDate),
              style: `background: ${ColorMap.get(event.eventType)};
                      outline: ${isSelected ? '2px ridge rgba(0, 72, 227, 0.8)' : 'none'};
                      border: none;
                      height: 1rem;
                      z-index: ${isSelected ? '9999' : '1'}`,
              group: streamsRef.current.length === 1 ? TimelineEventMap.get(event.eventType) : streamsRef.current.find(el => el.kvsName === event.sourceCamera)?.kvsName || "",
              // title: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined,
              // content: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined
            });
          })
        }
      })
      const data: any[] = [...e];
      data.push(...Utils.Timeline.extractUptimeFromTimeChunks(timeEventsRef.current))

      const groups = getGroups();
      ref.setGroups(groups);

      if (timerangeBackgroundVisibleRef.current === true) data.push(generateTimeItem(timeRangeRef.current.start, timeRangeRef.current.end));
      if (minRef.current) data.push(generateMinItem(new Date(minRef.current)));
      const newData = new DataSet(data);
      ref.setItems(newData);
    }
  }, [])

  React.useEffect(() => { eventsRef.current = events }, [events])
  React.useEffect(setBackground, [timerangeBackground]);
  React.useEffect(initTimeline, [lateUpdate]);
  React.useEffect(updateTimelineEvents, [events, timeEvents, highlightEvents])
  React.useEffect(handleZoom, [timelineZoom])
  React.useEffect(zoomToTimeRange, [timeRange, timeline]);
  React.useEffect(setTimerangeMarkers, [timeRange, timeline]);
  React.useEffect(setTimeRangeRef, [timeRange]);
  React.useEffect(() => { timeEventsRef.current = timeEvents }, [timeEvents]);
  React.useEffect(() => { minRef.current = min || 0 }, [min]);
  React.useEffect(() => { timezoneRef.current = timezone }, [timezone]);
  React.useEffect(() => { highlightEventsRef.current = highlightEvents }, [highlightEvents])
  React.useEffect(() => { timerangeBackgroundVisibleRef.current = timerangeBackgroundVisible }, [timerangeBackgroundVisible]);
  React.useEffect(() => { mouseMarkerRef.current = mouseMarker }, [mouseMarker]);
  React.useEffect(() => { onClickRef.current = onClick }, [onClick])
  React.useEffect(() => { onEventClickRef.current = onEventClick }, [onEventClick]);
  React.useEffect(() => { onTimeRangeChangeRef.current = onTimeRangeChange }, [onTimeRangeChange]);
  React.useEffect(() => { timelineRef.current = timeline }, [timeline])
  React.useEffect(() => { streamsRef.current = streams }, [streams]);

  function setTimeRangeRef(): void {
    timeRangeRef.current = timeRange;
  }

  function setTimerangeMarkers(): void {
    const parsedTimezone = timezoneRef.current?.split(" ")[0] || "";

    if (timeline && timeRange) {
      try {
        timeline.setCustomTime(timeRange.start, 'start');
        timeline.setCustomTimeTitle(moment.tz(timeRange.start, parsedTimezone).format('MMM D, h:mm:ss A'), 'start');
      } catch (e) {
        timeline.addCustomTime(timeRange.start, 'start');
        timeline.setCustomTimeTitle(moment.tz(timeRange.start, parsedTimezone).format('MMM D, h:mm:ss A'), 'start');
      }

      try {
        timeline.setCustomTime(timeRange.end, 'end')
        timeline.setCustomTimeTitle(moment.tz(timeRange.end, parsedTimezone).format('MMM D, h:mm:ss A'), 'end');
      } catch (e) {
        timeline.addCustomTime(timeRange.end, 'end')
        timeline.setCustomTimeTitle(moment.tz(timeRange.end, parsedTimezone).format('MMM D, h:mm:ss A'), 'end');
      }
    }
  }

  function setBackground(): void {
    if (timerangeBackground !== undefined) setTimerangeBackgroundVisible(timerangeBackground);
  }

  function zoomToTimeRange() {
    if (timerangeMarkerMoved.current === true) {
      timerangeMarkerMoved.current = false;
      return;
    }

    if (timeline && timeRange) {
      const offset = timeRange.end.getTime() - timeRange.start.getTime();
      const buffer = offset * 0.05;
      timeline.setWindow(new Date(timeRange.start.getTime() - buffer), new Date(timeRange.end.getTime() + buffer), () => {
        setTimelineZoom(new ZoomLevel(timeRange.start.getTime(), timeRange.end.getTime()).getZoomLevel());
      });
    }
  }

  function moveTo(start: Date, end: Date) {
    if (timeline) {
      const offset = end.getTime() - start.getTime();
      const buffer = offset * 1;
      timeline.setWindow(new Date(start.getTime() - buffer), new Date(end.getTime() + buffer), () => {
        setTimelineZoom(new ZoomLevel(start.getTime(), end.getTime()).getZoomLevel());
      });
    }
  }

  function handleZoom() {
    if (zoomByUser.current === true) {
      zoomByUser.current = false;
      previousZoom.current = timelineZoom;
      return;
    }

    if (timelineRef.current) {
      const previous = previousZoom.current || 0;
      if (previous < timelineZoom) {
        const offset = timelineZoom - previous;
        //timelineRef.zoomIn(Number(offset.toFixed(2)), { animation: false });
        if (timelineZoom === 11.2) timelineRef.current.zoomIn(1, { animation: false })
        else timelineRef.current.zoomIn(Number(offset.toFixed(2)), { animation: false });
      } else if (previous > timelineZoom) {
        const offset = previous - timelineZoom;
        //timelineRef.zoomOut(Number(offset.toFixed(2)), { animation: false })
        if (timelineZoom === 0) timelineRef.current.zoomOut(1, { animation: false })
        else timelineRef.current.zoomOut(Number(offset.toFixed(2)), { animation: false })
      }

      previousZoom.current = timelineZoom;
    }
  }

  function initTimeline() {
    const options = {
      autoResize: true,
      zoomMin: 60000,
      zoomMax: (3600000 * 24) * 30,
      zoomable: true,
      stack: false,
      minHeight: 40,
      maxMinorChars: 15,
      snap: null,
      orientation: {
        axis: 'top',
        item: 'top'
      },
      margin: {
        item: {
          vertical: 0
        }
      },
      cluster: false,
      showMajorLabels: false,
      verticalScroll: false,
      showCurrentTime: false,
      format: {
        minorLabels: {
          second: 'ss',
          minute: 'h:mm A',
          hour: 'h:mm A',
          day: 'ddd D',
          week: 'ddd D',
        },
      },
      moment: function (date: Date) {
        if (timezoneRef.current) {
          const parsedTimezone = timezoneRef.current.split(" ")[0];
          return moment.tz(date, parsedTimezone);
        }
        // const offset = utcOffset ? utcOffset : 0;
        return moment(date).utcOffset(0);
      },

      locale: 'en_US'
    };

    const e: any[] = [];

    eventsRef.current.forEach(event => {
      const isSelected = highlightEventsRef.current?.find(el =>
        el.sourceCamera === event.sourceCamera &&
        el.startDate === event.startDate &&
        el.endDate === event.endDate
      ) !== undefined;
      e.push({
        id: `${event.sourceCamera}-${event.startDate}#${uuidv4()}`,
        start: new Date(Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate)),
        end: new Date(event.endDate),
        style: `background: ${ColorMap.get(event.eventType)};
                outline: ${isSelected ? '2px ridge rgba(0, 72, 227, 0.8)' : 'none'};
                border: none;
                height: 1rem;
                z-index: ${isSelected ? '9999' : '1'}`,
        group: streamsRef.current.length === 1 ? TimelineEventMap.get(event.eventType) : streamsRef.current.find(el => el.kvsName === event.sourceCamera)?.kvsName || "",
        // title: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined,
        // content: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined
      });

      if (isArray(event.children)) {
        event.children.forEach((event) => {
          e.push({
            id: `${event.sourceCamera}-${event.startDate}#${uuidv4()}`,
            start: new Date(Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate)),
            end: new Date(event.endDate),
            style: `background: ${ColorMap.get(event.eventType)};
                    outline: ${isSelected ? '2px ridge rgba(0, 72, 227, 0.8)' : 'none'};
                    border: none;
                    height: 1rem;
                    z-index: ${isSelected ? '9999' : '1'}`,
            group: streamsRef.current.length === 1 ? TimelineEventMap.get(event.eventType) : streamsRef.current.find(el => el.kvsName === event.sourceCamera)?.kvsName || "",
            // title: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined,
            // content: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined
          });
        })
      }
    })
    const data: any[] = [...e];

    if (timerangeBackgroundVisibleRef.current && timeRangeRef.current) data.push(generateTimeItem(timeRangeRef.current.start, timeRangeRef.current.end))
    //if (min) data.push(generateMinItem(new Date(min)));

    const dataSet = new DataSet(data);

    const groups: any[] = [];

    if (streamsRef.current.length === 1) {
      const newGroups = [...TIMELINE_GROUPS].filter((el) => {
        if (el.id.includes('Bottom') || el.id.includes('uptime')) return true;
        if (el.id.includes('blank')) {
          if (eventsRef.current.find(e => TimelineEventMap.get(e.eventType) === el.id.split('blank-')[1]) !== undefined) {
            return true;
          }
        }
        if (eventsRef.current.find(e => TimelineEventMap.get(e.eventType) === el.id)) {
          return true;
        }

        return false
      });

      groups.push(...newGroups)
    } else {
      groups.push({
        id: 'uptime',
        content: "<div style='height: 0' />",
        className: "Uptime",
        visible: true
      })

      groups.push({
        id: 'blank-Motion',
        className: "BlankSpace",
        content: "<div style='height: 0' />",
        visible: true
      })

      streamsRef.current.forEach(stream => {
        groups.push({
          id: stream.kvsName,
          className: "Motion",
          visible: true,
          content: stream.defaultName || stream.kvsName
        });
        groups.push({
          id: `blank-${stream.kvsName}`,
          className: "BlankSpace",
          content: "<div style='height: 0' />",
          visible: true
        })
      })

      groups.push({
        id: 'Bottom',
        className: "Bottom",
        content: "<div style='height: 0' />",
        visible: true
      })
    }

    //@ts-ignore
    const ref = new Timeline(containerRef.current, dataSet, groups, options);

    ref.on('click', function (properties: any) {
      if (onEventClickRef.current && properties.item) {
        onEventClickRef.current(properties.item.includes('#') ? properties.item.split('#')[0] : properties.item);
      }

      // Prevent clicking on group section triggering goToTime logic.
      if (properties.x < 0) return;
      if (properties.group === 'Time' || properties.item === 'timebar') return;
      if (properties.customTime && properties.customTime === 'start') return;
      if (properties.customTime && properties.customTime === 'end') return;
      if (properties.what === null) return;

      if (rangeChanged.current) {
        rangeChanged.current = false;
      } else {
        onClickRef.current(
          properties.time
        );
      }
    });

    ref.on('timechanged', handleTimeChanged);

    ref.on('rangechanged', (properties: any) => {
      rangeChanged.current = true;

      setTimeout(() => {
        rangeChanged.current = false;
      }, 250);

      const { start, end } = properties;

      if (properties.byUser === true) {
        zoomByUser.current = true;

        setTimelineZoom(new ZoomLevel(start, end).getZoomLevel());
      }

      lateUpdate(ref);
    });

    ref.on('mouseMove', (properties: any) => {
      if (mouseMarkerRef.current === false) return;

      const parsedTimezone = timezoneRef.current?.split(" ")[0] || "";
      if (properties.time && ref) {
        try {
          ref.setCustomTime(properties.time, 'mouse-feedback');
          ref.setCustomTimeTitle(moment.tz(properties.time, parsedTimezone).format('MMM D, h:mm:ss A'), 'mouse-feedback');
        } catch (e) {
          ref.addCustomTime(properties.time, 'mouse-feedback');
          //@ts-ignore          
          ref.customTimes[ref.customTimes.length - 1].hammer.off("panstart panmove panend");
        }

        if (!mouseFeedbackRef.current) {
          mouseFeedbackRef.current = first(document.getElementsByClassName('mouse-feedback'))
        }

        if (mouseFeedbackRef.current) {
          if (properties.time > minRef.current) {
            mouseFeedbackRef.current.classList.remove("unavailable")
          } else {
            mouseFeedbackRef.current.classList.add("unavailable");
          }
        }
      }
    })

    setTimeline(ref);
  }

  /**
   * Handles timechanged events from the Timeline, that means any
   * element from the timeline had its time changed either by the
   * user or automatically by the code.
   */
  function handleTimeChanged(properties: any): void {
    const { id: markerId, time: markerTime } = properties;

    if (timeRangeRef.current && onTimeRangeChangeRef.current) {
      const { start, end } = timeRangeRef.current;

      if (markerId === 'start') {
        timerangeMarkerMoved.current = true;
        if (markerTime > end) onTimeRangeChangeRef.current(end, markerTime);
        else onTimeRangeChangeRef.current(markerTime, end);
      }

      if (markerId === 'end') {
        timerangeMarkerMoved.current = true;
        if (start > markerTime) onTimeRangeChangeRef.current(markerTime, start);
        else onTimeRangeChangeRef.current(start, markerTime);
      }
    }
  }

  function setCurrentTime(time: Date, id?: string) {
    try {
      timeline.getCustomTime(id || customTimeID);
      timeline.setCustomTime(time, id || customTimeID);

      let str = "";

      const item = document.getElementsByClassName('vis-custom-time-marker');
      const el = item.item(0);
      if (el) {
        el.remove()
      }

      timeline.setCustomTimeMarker(str, id || customTimeID);
    } catch {
      timeline.addCustomTime(time, id || customTimeID);
    }
  }

  function getGroups() {
    const groups = [];

    if (streamsRef.current.length === 1) {
      const newGroups = [...TIMELINE_GROUPS].filter((el) => {
        if (el.id.includes('Bottom') || el.id.includes('uptime')) return true;
        if (el.id.includes('blank')) {
          if (eventsRef.current.find(e => TimelineEventMap.get(e.eventType) === el.id.split('blank-')[1]) !== undefined) {
            return true;
          }
        }
        if (eventsRef.current.find(e => TimelineEventMap.get(e.eventType) === el.id)) {
          return true;
        }

        return false
      });

      return newGroups;
    }


    groups.push({
      id: 'uptime',
      content: "<div style='height: 0' />",
      className: "Uptime",
      visible: true
    })

    groups.push({
      id: 'blank-Motion',
      className: "BlankSpace",
      content: "<div style='height: 0' />",
      visible: true
    })


    streamsRef.current.forEach(stream => {
      groups.push({
        id: stream.kvsName,
        className: "Motion",
        visible: true,
        content: stream.defaultName || stream.kvsName
      });
      groups.push({
        id: `blank-${stream.kvsName}`,
        className: "BlankSpace",
        content: "<div style='height: 0' />",
        visible: true
      })
    })

    groups.push({
      id: 'Bottom',
      className: "Bottom",
      content: "<div style='height: 0' />",
      visible: true
    })

    return groups;
  }

  function updateTimelineEvents() {
    if (timelineRef.current) {
      const e: any[] = [];

      events.forEach(event => {
        const isSelected = highlightEvents?.find(el =>
          el.sourceCamera === event.sourceCamera &&
          el.startDate === event.startDate &&
          el.endDate === event.endDate
        ) !== undefined;
        e.push({
          id: `${event.sourceCamera}-${event.startDate}#${uuidv4()}`,
          start: new Date(Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate)),
          end: new Date(event.endDate),
          style: `background: ${ColorMap.get(event.eventType)};
                  outline: ${isSelected ? '2px ridge rgba(0, 72, 227, 0.8)' : 'none'};
                  border: none;
                  height: 1rem;
                  z-index: ${isSelected ? '9999' : '1'}`,
          group: streamsRef.current.length === 1 ? TimelineEventMap.get(event.eventType) : streamsRef.current.find(el => el.kvsName === event.sourceCamera)?.kvsName || "",
          // title: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined,
          // content: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined
        });

        if (isArray(event.children)) {
          event.children.forEach((event) => {
            e.push({
              id: `${event.sourceCamera}-${event.startDate}#${uuidv4()}`,
              start: new Date(Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate)),
              end: new Date(event.endDate),
              style: `background: ${ColorMap.get(event.eventType)};
                      outline: ${isSelected ? '2px ridge rgba(0, 72, 227, 0.8)' : 'none'};
                      border: none;
                      height: 1rem;
                      z-index: ${isSelected ? '9999' : '1'}`,
              group: streamsRef.current.length === 1 ? TimelineEventMap.get(event.eventType) : streamsRef.current.find(el => el.kvsName === event.sourceCamera)?.kvsName || "",
              // title: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined,
              // content: renderEventsTags && isArray(event.tags) ? this.getTagText(event) : undefined
            });
          })
        }
      })
      const data: any[] = [...e];
      data.push(...Utils.Timeline.extractUptimeFromTimeChunks(timeEvents))

      const groups = getGroups();
      timelineRef.current.setGroups(groups);

      if (timerangeBackgroundVisibleRef.current === true && timeRangeRef.current) data.push(generateTimeItem(timeRangeRef.current.start, timeRangeRef.current.end));
      if (minRef.current) data.push(generateMinItem(new Date(minRef.current)));
      const newData = new DataSet(data);
      timelineRef.current.setItems(newData);
    }
  }

  function updateTime(timestamp: number, id?: string) {
    if (timeline) {
      setCurrentTime(new Date(timestamp), id);
    }
  }

  function animateTo(timestamp: number) {
    if (timeline) {
      timeline.moveTo(timestamp, { animation: true })
    }
  }

  function removeCursorMarker(): void {
    if (timeline) {
      try {
        timeline.removeCustomTime('mouse-feedback');
        mouseFeedbackRef.current = undefined;
      } catch (e) { }
    }
  }

  function markEventTime(event: VideoStreamEvent) {
    if (timeline) {
      try {
        timeline.setCustomTime(event.startDate, 'event-feedback');
      } catch (e) {
        timeline.addCustomTime(event.startDate, 'event-feedback');
      }
    }
  }

  function clearEventTime() {
    if (timeline) {
      try {
        timeline.removeCustomTime('event-feedback');
      } catch (e) { }
    }
  }

  return (
    <div
      id="timeline-container-description"
      className="Container multistream"
      ref={containerRef}
      onMouseLeave={removeCursorMarker}
      style={{
        paddingBottom: noPadding ? undefined : '1rem',
        paddingLeft: noPadding ? undefined : '1rem',
        paddingRight: noPadding ? undefined : '1rem',
      }}
    >

    </div>
  )
}

export default React.forwardRef(MultiStreamTimeline);
