import { Typography, PaperProps, IconButton, Menu, MenuItem, CircularProgress, Tooltip, Box, TooltipProps, tooltipClasses } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';
import React from 'react';
import styled from 'styled-components';
import Utils from '@utils/index';
import { FileCopy, Gamepad, HighlightOffRounded, MoreHoriz, VideocamOff } from '@mui/icons-material';
import { Colors } from '@utils/Styles';
import { StreamStatus } from '@components/common/StreamStatus';
import { FlexContainer, StreamContainer, StreamItemHeader } from '../common';
import background from '../../../assets/images/placeholders/stream_offline.svg';
import { Skeleton } from '@mui/material';
import StreamThumbnailManager from '@classes/StreamThumbnailManager';
import { AssetsPermissions } from '@store/session/types';

const PREFIX = 'StreamItem';

const classes = {
  noMaxWidth: `${PREFIX}-noMaxWidth`
};

const Root = styledMui('a')((
  {
    theme
  }
) => ({
  [`& .${classes.noMaxWidth}`]: {
    maxWidth: 'none',
  }
}));

export const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const ViewContainer = styled(StreamContainer)`
  border-radius: 1rem;
`

const Image = styled(FlexContainer) <{ src?: string }>`
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-image: ${props => `url(${props.src})`};

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  background-color: black;

  p {
    color: white;
  }
`

const IconContainer = styled.div`
  svg {
    height: 4rem;
    width: 4rem;
  }
`

type StreamThumbnailProps = PaperProps & {
  stream: VideoStream;
  onStreamClick?: (stream: VideoStream) => void;
  height: string;
  width?: string;
  removable?: boolean;
  onRemoval?: (stream: VideoStream) => void;
  isSuperAdministrator?: boolean;
  isAdministrator?: boolean;
  onStreamEdit?: (stream: VideoStream) => void;
  onStreamArchive?: (stream: VideoStream, callback?: () => void) => void;
  onStreamActivate?: (stream: VideoStream, callback?: () => void) => void;
  onStreamDelete?: (stream: VideoStream) => void;
  onStreamDetails?: (stream: VideoStream) => void;
  opacity?: number;
  onPtzClick?: (stream: VideoStream) => void;
  disableAnchor?: boolean;
  permissions?: AssetsPermissions;
}

const StreamItem: React.FC<StreamThumbnailProps> = (props) => {
  const {
    stream,
    onStreamClick,
    height,
    width,
    removable,
    onRemoval,
    isSuperAdministrator,
    isAdministrator,
    permissions,
    onStreamEdit,
    onStreamArchive,
    onStreamActivate,
    onStreamDelete,
    onStreamDetails,
    opacity,
    onPtzClick,
    disableAnchor,
    ...paperProps
  } = props;

  const [isHovered, setHovered] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [thumbnailSrc, setThumbnailSrc] = React.useState<string | undefined>("");

  const [srcStatus, setSrcStatus] = React.useState<"loading" | "error" | "success">("loading");
  const [loadingStatus, setLoadingStatus] = React.useState<undefined | "error">(undefined);

  const getThumbnail = React.useCallback(() => {
    StreamThumbnailManager.instance.thumbnailOfStream(stream, loadingStatus === "error")
      .then(url => {
        setThumbnailSrc(url);
      })
      .catch(() => {
        setSrcStatus("error")
      })
  }, [loadingStatus, stream])

  React.useEffect(getThumbnail, [getThumbnail])

  function closeMenu() {
    setAnchorEl(null);
  }

  function getStreamLink() {
    return `${window.location.href?.replace("cameras", "timeline")}&stream=${Utils.Stream.getIdsFromStreamName(stream.kvsName).streamId}`
  }

  function onClickCommon(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    closeMenu();
  }

  const content = () =>
    <ViewContainer
      onMouseEnter={() => { setHovered(true) }}
      onMouseLeave={() => { setHovered(false); }}
      style={{ height: height === undefined ? '100%' : undefined, opacity: opacity }}
      {...paperProps}
      onClick={() => { if (onStreamClick) onStreamClick(stream) }}
      onMouseDown={(e) => {
        if (e.button === 1 && window.location.href)
          window.open(getStreamLink(), "_blank");
      }}
      variant="outlined"
    >
      <StreamItemHeader>
        <StreamStatus
          stream={stream}
        />
        <NoMaxWidthTooltip
          arrow={true}
          disableFocusListener
          placement="top-start"
          style={{ maxWidth: 'none' }}
          title={
            <Box
              onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
              display="flex"
              flexDirection="column"
            >
              <Box
                onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography variant="body2"><b>KVS Name: </b>{`${stream.kvsName}`}</Typography>
                <IconButton
                  style={{ width: '1rem', height: '1rem' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigator.clipboard.writeText(stream.kvsName);
                  }}
                  size="large"
                  data-cy="stream-copy-name-icon-button"
                >
                  <FileCopy style={{ width: '1rem', height: '1rem', color: 'white' }} />
                </IconButton>
              </Box>
              <Box onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} display="flex" flexDirection="row" alignItems="center">
                <Typography variant="body2"><b>IP: </b>{`${Utils.RegEx.findIPAddress(stream.rtspUrl)}`}</Typography>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigator.clipboard.writeText(Utils.RegEx.findIPAddress(stream.rtspUrl));
                  }}
                  style={{ width: '1rem', height: '1rem' }}
                  size="large"
                  data-cy="stream-copy-ip-icon-button"
                >
                  <FileCopy style={{ width: '1rem', height: '1rem', color: 'white' }} />
                </IconButton>
              </Box>
            </Box>
          }
        >
          <Typography
            variant="subtitle1"
            noWrap={true}
          >
            {Utils.Stream.getStreamName(stream)}
          </Typography>
        </NoMaxWidthTooltip>
        {stream?.features?.ptz && stream.rportCameraId
          && onPtzClick && permissions && Utils.Permissions.userHasPermission(permissions, "PTZ_CONTROL", stream.siteId, stream.kvsName) &&
          <Tooltip title="PTZ" arrow placement="top">
            <IconButton
              size="small"
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                onPtzClick(stream);
              }}
              style={{ padding: 0 }}
              data-cy="stream-ptz-icon-button"
            >
              <Gamepad fontSize='small' />
            </IconButton>
          </Tooltip>
        }
        {removable === undefined ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(e.currentTarget);
            }}
            style={{ padding: 0 }}
            size="small"
            data-cy="stream-more-icon-button"
          >
            <MoreHoriz />
          </IconButton>
        ) : null}
        {removable === true && isHovered === true ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (onRemoval)
                onRemoval(stream);
            }}
            size="small"
            data-cy="stream-remove-icon-button"
          >
            <HighlightOffRounded fontSize="small" htmlColor={Colors.ERROR} />
          </IconButton>
        ) : null}
      </StreamItemHeader>
      {srcStatus === "loading" &&
        <Skeleton variant="rectangular" width={width} height={height || '100%'} style={{ borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem' }} />
      }

      {srcStatus !== "loading" &&
        <Image
          style={{ width: width, height: height || '100%', backgroundColor: '#E0E0E0' }}
          src={srcStatus === "success" ? thumbnailSrc : background}>
          {loading === true ? (
            <CircularProgress size="4rem" color="primary" />
          ) : thumbnailSrc === undefined ? (
            <IconContainer>
              <VideocamOff color="disabled" />
            </IconContainer>
          ) : null}
        </Image>
      }
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => closeMenu()}
        onClick={(e) => onClickCommon(e)}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {onStreamDetails && <MenuItem onClick={(e) => {
          onClickCommon(e);
          onStreamDetails(stream);
        }}
          data-cy={`stream-details-menu-item`}
        >{isAdministrator ? "Edit" : "Details"}</MenuItem>}
        {onStreamEdit && <MenuItem onClick={(e) => {
          onClickCommon(e);
          onStreamEdit(stream);
        }}
          data-cy={`stream-edit-menu-item`}
        >Edit</MenuItem>}
        {onStreamArchive && <MenuItem onClick={(e) => {
          onClickCommon(e);
          setLoading(true);
          onStreamArchive(stream, () => {
            setLoading(false);
          });
        }} data-cy={`stream-archived-menu-item`}
        >Archive</MenuItem>}
        {onStreamActivate && <MenuItem onClick={(e) => {
          onClickCommon(e);
          setLoading(true);
          onStreamActivate(stream, () => {
            setLoading(false);
          });
        }} data-cy={`stream-activate-menu-item`}
        >Activate</MenuItem>}
        {onStreamDelete && <MenuItem onClick={(e) => {
          onClickCommon(e);
          onStreamDelete(stream);
        }}
          data-cy={`stream-delete-menu-item`}
        >Delete</MenuItem>}
      </Menu>
      {/* Used only to pre-load the image into the cache to avoid slowly showing the image, when done, display the actual div instead of a skeleton */}
      {thumbnailSrc &&
        <img
          style={{ display: 'none' }}
          src={thumbnailSrc}
          onLoad={() => { setSrcStatus("success"); }}
          onError={() => {
            setSrcStatus("error");
            if (loadingStatus === undefined) setLoadingStatus("error");
          }}
          alt=""
        />
      }
    </ViewContainer>

  if (disableAnchor)
    return content();

  return (
    <Root
      style={{ textDecoration: "none" }}
      onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); }}
      href={getStreamLink()}
    >
      {content()}
    </Root>
  );
}

export default StreamItem;
