import { setEvents, setUnloadedEventCount } from '@store/views/overviewView/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '@API/index';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';
import { IStoreState } from '@store/index';
import EventsFilter, { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';
import { flattenDeep, isEmpty } from 'lodash';
import { selectIsSuperAdminUser } from '@store/selectors';

type DateRange = {
  start: Date;
  end: Date;
}

type TabEventsLoaderProps = {
  timeRange: DateRange | undefined;
  timezone: 'local' | 'utc',
  onLoadStart: () => void;
  onLoadEnd: () => void;
  onTabLoadStatusCange: (status: boolean) => void;
}

export interface ITabEventsLoaderHandles {
  fetchTabEvents(start: number, end: number, tab?: EventState, lastKey?: number): Promise<VideoStreamEvent[]>
}

const TabEventsLoader: React.ForwardRefRenderFunction<ITabEventsLoaderHandles, TabEventsLoaderProps> = (props, ref) => {
  const { timeRange, onLoadStart, onLoadEnd, onTabLoadStatusCange, timezone } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  React.useImperativeHandle(ref, () => ({
    fetchTabEvents
  }))

  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const options = useSelector((store: IStoreState) => store.overviewView.overviewOptions);
  const filters = useSelector((store: IStoreState) => store.overviewView.filters);
  const hideOngoingEvents = useSelector((store: IStoreState) => store.overviewView.overviewOptions.hideOngoingEvents);
  const isSuperAdministrator = useSelector(selectIsSuperAdminUser);
  const eventCount = useSelector((store: IStoreState) => store.overviewView.eventCount);

  const [loadingUnassigned, setLoadingUnassigned] = React.useState<boolean>(false);
  const [loadingAssigned, setLoadingAssigned] = React.useState<boolean>(false);
  const [loadingArchived, setLoadingArchived] = React.useState<boolean>(false);
  const [loadingTrash, setLoadingTrash] = React.useState<boolean>(false);

  const isMounted = React.useRef<boolean>(true);
  const timeRangeRef = React.useRef<DateRange | undefined>(undefined)
  const filtersRef = React.useRef<EventsFilterParams | undefined>(filters)
  const hideOngoingEventsRef = React.useRef<boolean>(hideOngoingEvents);

  const onLoadEndRef = React.useRef(onLoadEnd)
  const onTabLoadStatusCangeRef = React.useRef(onTabLoadStatusCange)
  const dispatchRef = React.useRef(dispatch)
  const onLoadStartRef = React.useRef(onLoadStart)
  const enqueueSnackbarRef = React.useRef(enqueueSnackbar)
  const selectedCompanyRef = React.useRef(selectedCompany)
  const optionsRef = React.useRef(options)
  const isSuperAdministratorRef = React.useRef(isSuperAdministrator);
  const timezoneRef = React.useRef(timezone)
  const eventCountRef = React.useRef(eventCount);

  const fetchTabEvents = React.useCallback(async (start: number, end: number, tab?: EventState, lastKey?: number) => {
    return await API.GlobalEventInbox.listEvents(
      selectedCompanyRef.current?.companyId || "",
      start,
      end,
      timezoneRef.current,
      tab,
      filtersRef.current,
      {
        mergeZoneEvents: optionsRef.current.mergeZoneEvents,
        mergeSiteEvents: optionsRef.current.mergeSiteEvents
      },
      isSuperAdministratorRef.current,
      lastKey
    )
  }, [])

  const fetchEvents = React.useCallback(async (start: number, end: number) => {
    const events = await Promise.all([
      fetchTabEvents(start, end, undefined),
      fetchTabEvents(start, end, "archived"),
      fetchTabEvents(start, end, "assigned"),
      fetchTabEvents(start, end, "muted"),
      fetchTabEvents(start, end, "trash"),
    ])
    
    return flattenDeep(events);
  }, [fetchTabEvents])

  const fetchEventCount = React.useCallback(async (start: number, end: number) => {
    return await API.GlobalEventInbox.countEvents(
      selectedCompanyRef.current?.companyId || "",
      start,
      end,
      timezoneRef.current,
      filtersRef.current,
      {
        mergeZoneEvents: optionsRef.current.mergeZoneEvents,
        mergeSiteEvents: optionsRef.current.mergeSiteEvents
      },
      isSuperAdministratorRef.current
    )
  }, [])

  React.useEffect(() => { eventCountRef.current = eventCount }, [eventCount])
  React.useEffect(() => { timezoneRef.current = timezone }, [timezone]);
  React.useEffect(() => { isSuperAdministratorRef.current = isSuperAdministrator }, [isSuperAdministrator])
  React.useEffect(() => { optionsRef.current = options }, [options])
  React.useEffect(() => { selectedCompanyRef.current = selectedCompany }, [selectedCompany])
  React.useEffect(() => { enqueueSnackbarRef.current = enqueueSnackbar }, [enqueueSnackbar])
  React.useEffect(() => { onLoadStartRef.current = onLoadStart }, [onLoadStart])
  React.useEffect(() => { dispatchRef.current = dispatch }, [dispatch])
  React.useEffect(() => { onTabLoadStatusCangeRef.current = onTabLoadStatusCange }, [onTabLoadStatusCange])
  React.useEffect(() => { onLoadEndRef.current = onLoadEnd }, [onLoadEnd])
  React.useEffect(setup, [])
  React.useEffect(() => { filtersRef.current = filters }, [filters]);
  React.useEffect(() => { hideOngoingEventsRef.current = hideOngoingEvents }, [hideOngoingEvents])

  React.useEffect(fetchInitialEvents, [fetchEvents, timeRange, filters, fetchEventCount]);
  React.useEffect(() => {
    if (timeRange === undefined) {
      onLoadEndRef.current();
      setLoadingUnassigned(false);
      setLoadingAssigned(false);
      setLoadingArchived(false);
      setLoadingTrash(false);
    }
    timeRangeRef.current = timeRange
  }, [timeRange]);

  React.useEffect(() => {
    onTabLoadStatusCangeRef.current(loadingUnassigned === true || loadingAssigned === true || loadingArchived === true || loadingTrash === true)
  }, [loadingUnassigned, loadingAssigned, loadingArchived, loadingTrash])

  function setup() {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }

  function fetchInitialEvents(): void {
    if (timeRange) {
      let eventCount: EventInboxCount | undefined = undefined;

      onLoadStartRef.current();
      setLoadingUnassigned(true);
      setLoadingAssigned(true);
      setLoadingArchived(true);
      setLoadingTrash(true);
      fetchEventCount(timeRange.start.getTime(), timeRange.end.getTime())
        .then((count) => {
          eventCount = count;
          return fetchEvents(timeRange.start.getTime(), timeRange.end.getTime());
        })
        .then((events) => {
          if (eventCount) {
            const current = { ...eventCount }
            events.forEach((parent) => {
              if (parent.tab === "unassigned" || parent.tab === undefined) current.unassigned -= 1;
              if (parent.tab === "assigned") current.assigned -= 1;
              if (parent.tab === "archived") current.archived -= 1;
              if (parent.tab === "trash") current.trash -= 1;
              if (parent.tab === "muted") current.muted -= 1;

              parent.children?.forEach(() => {
                if (parent.tab === "unassigned" || parent.tab === undefined) current.unassigned -= 1;
                if (parent.tab === "assigned") current.assigned -= 1;
                if (parent.tab === "archived") current.archived -= 1;
                if (parent.tab === "trash") current.trash -= 1;
                if (parent.tab === "muted") current.muted -= 1;
              })
            })
            pushEventsCountToRedux(current)
          }

          pushEventsToRedux(events);
        })
        .catch((error) => { enqueueSnackbarRef.current(Utils.Error.getErrorMessage(error), { variant: 'error' }); })
        .finally(() => {
          if (onLoadEndRef.current) onLoadEndRef.current();
          setLoadingUnassigned(false);
          setLoadingAssigned(false);
          setLoadingArchived(false);
          setLoadingTrash(false);
        })
    }
  }
  function pushEventsCountToRedux(data: EventInboxCount): void {
    try {
      dispatchRef.current(setUnloadedEventCount(data));
    } catch (error) {
      console.log(error);
    }
  }

  function pushEventsToRedux(data: VideoStreamEvent[]): void {
    try {
      if (filtersRef.current) {
        const events = new EventsFilter(filtersRef.current).getFilteredEvents(data, hideOngoingEventsRef.current);
        if (!isEmpty(events)) {
          dispatchRef.current(setEvents(events))
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return null;
}

export default React.forwardRef(TabEventsLoader);
