import API from '@API/index'
import { StreamUptime } from '@classes/StreamUptime';

async function getStreamUptime(stream: VideoStream | VideoStream[], credentials: any): Promise<VideoStreamTimeChunk[]> {
  try {
    const streamUptime: StreamUptime = new StreamUptime(
      stream,
      credentials,
      API.CloudWatch,
      API.Streams
    )

    return await streamUptime.build();
  } catch (error: any) {
    throw error;
  }
}

export default getStreamUptime;
