import StrobeIcon from '@components/common/Icon/StrobeIcon'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

const StrobeButton: React.FC<{ onActivateStrobes: () => void }> = ({ onActivateStrobes }) => {
  return <Tooltip title="Strobe light">
    <IconButton
      onClick={onActivateStrobes}
      color={"primary"}
      size="small"
    >
      <StrobeIcon />
    </IconButton>
  </Tooltip>
}

export default StrobeButton