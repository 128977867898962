import Tutorial from './Tutorial';

class SettingsGeneralTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#company-form-main-container',
        popover: {
          title: 'General',
          description: 'Information about the company’s corporate office.',
          position: 'mid-center'
        }
      }
    ])
  }
}

export default SettingsGeneralTutorial;
