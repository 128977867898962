import createScheduleTemplate from "./createScheduleTemplate";
import deleteScheduleTemplate from "./deleteScheduleTemplate";
import listScheduleTemplates from "./listScheduleTemplates";
import updateScheduleTemplate from "./updateScheduleTemplate";

export interface IScheduleTemplatesAPI {
  createScheduleTemplate(scheduleTemplate: ScheduleTemplate): Promise<ScheduleTemplate>;
  updateScheduleTemplate(scheduleTemplate: ScheduleTemplate): Promise<ScheduleTemplate>;
  deleteScheduleTemplate(siteId: string, scheduleTemplateId: string): Promise<void>;
  listScheduleTemplates(siteId: string, filterText?: string): Promise<ScheduleTemplate[]>;
}

const ScheduleTemplates: IScheduleTemplatesAPI = {
  createScheduleTemplate,
  updateScheduleTemplate,
  deleteScheduleTemplate,
  listScheduleTemplates
}

export default ScheduleTemplates;

