import { API } from 'aws-amplify';

async function deleteWorker(
  workerId: number, asCompany?: string
): Promise<void> {
  try {
    const path = `/workers/${workerId}` + (asCompany ? `?asCompany=${asCompany}` : "");

    return await API.del(
      'api-service',
      path,
      {}
    );
  } catch (error: any) {
    throw error;
  }
}

export default deleteWorker;
