import { API } from "aws-amplify";

async function deleteCertificate(workerId: number, certificateId: string, asCompany?: string): Promise<void> {
  try {
    const path: string = `/certificates/${workerId}/${certificateId}` + (asCompany ? `?asCompany=${asCompany}` : "");

    await API.del(
      'api-service',
      path,
      {}
    );

  } catch (error: any) {
    throw error;
  }
}

export default deleteCertificate;
