import { TutorialType } from "@classes/Tutorials/TutorialFactory";
import KeyMirror from "keymirror";

export const ActionTypes = KeyMirror({
  SET_TUTORIALS_ENABLED: null,
  SET_ACTIVE_TUTORIAL: null
});

export interface ITutorialsState {
  tutorialsEnabled: boolean,
  activeTutorial?: TutorialType
}
