import { MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { first } from 'lodash';
import React from 'react';

type TagMenuProps = {
  anchor: HTMLElement | null;
  onClose: () => void;
  onTagSelected: (tag: EventTag) => void;
  anchorPosition?: { top: number, left: number };
  selectedEvents?: VideoStreamEvent[];
}

const TagMenu: React.FC<TagMenuProps> = (props) => {
  const { anchor, onClose, onTagSelected, anchorPosition, selectedEvents } = props;

  function handleClick(tag: EventTag) {
    onClose();
    onTagSelected(tag);
  }

  function isSelected(tag: EventTag): boolean {
    if (selectedEvents) {
      const firstTag = first(first(selectedEvents)?.tags);
      const isAllTheSameTag = selectedEvents.filter(el => el.tags && first(el.tags) === firstTag).length === selectedEvents.length;
      if (isAllTheSameTag) {
        return firstTag === tag;
      }
    }

    return false;
  }

  return (
    <Popover
      anchorReference={anchorPosition ? 'anchorPosition' : 'anchorEl'}
      anchorEl={anchor}
      anchorPosition={anchorPosition}
      open={Boolean(anchor || anchorPosition)}
      onClose={onClose}
      anchorOrigin={anchor ? {
        vertical: 'bottom',
        horizontal: 'center',
      } : undefined}
      transformOrigin={anchor ? {
        vertical: 'top',
        horizontal: 'center',
      } : undefined}
    >
      <MenuList>
        <MenuItem selected={isSelected("escalate_theft")} onClick={() => { handleClick("escalate_theft") }} ><Typography color={isSelected("escalate_theft") ? "primary" : undefined} variant="body1" >Theft</Typography></MenuItem>
        <MenuItem selected={isSelected("escalate_contact_police")} onClick={() => { handleClick("escalate_contact_police") }} ><Typography color={isSelected("escalate_contact_police") ? "primary" : undefined} variant="body1" >Contact police</Typography></MenuItem>
        <MenuItem selected={isSelected("escalate_contact_manager")} onClick={() => { handleClick("escalate_contact_manager") }} ><Typography color={isSelected("escalate_contact_manager") ? "primary" : undefined} variant="body1" >Contact manager</Typography></MenuItem>
        <MenuItem selected={isSelected("escalate_fire")} onClick={() => { handleClick("escalate_fire") }} ><Typography color={isSelected("escalate_fire") ? "primary" : undefined} variant="body1" >Fire</Typography></MenuItem>
        <MenuItem selected={isSelected("escalate_damage")} onClick={() => { handleClick("escalate_damage") }} ><Typography color={isSelected("escalate_damage") ? "primary" : undefined} variant="body1" >Damage</Typography></MenuItem>
        <MenuItem selected={isSelected("escalate_open_door_gate")} onClick={() => { handleClick("escalate_open_door_gate") }} ><Typography color={isSelected("escalate_open_door_gate") ? "primary" : undefined} variant="body1" >Open door/gate</Typography></MenuItem>
        <MenuItem selected={isSelected("escalate_check_subject")} onClick={() => { handleClick("escalate_check_subject") }} ><Typography color={isSelected("escalate_check_subject") ? "primary" : undefined} variant="body1" >Check subject</Typography></MenuItem>
        <MenuItem selected={isSelected("escalate_facility_issue")} onClick={() => { handleClick("escalate_facility_issue") }} ><Typography color={isSelected("escalate_facility_issue") ? "primary" : undefined} variant="body1" >Facility issue</Typography></MenuItem>
      </MenuList>
    </Popover>
  )
}

export default TagMenu;
