import moment from 'moment';

function getAmPmHours(date: Date, timezone?: string): string {
  let hours = date.getUTCHours();

  if (timezone) {
    const parsedTimezone = timezone.split(" ")[0]; 
    hours = moment.tz(date.toISOString(), parsedTimezone).hours();
  }

  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${hours} ${ampm}`;
}

export default getAmPmHours;
