import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_STREAM_LIST: null,
  CREATE_STREAM: null,
  DELETE_STREAM: null,
  SET_STREAM_THUMBNAIL: null,
  SET_STREAM_LIST_INFORMATION: null,
  SET_MUTED_STREAMS: null,
  ADD_MUTED_STREAM: null,
  REMOVE_MUTED_STREAM: null
});

interface IGridData {
  name: string;
  description?: string;
}

interface IGrid extends IGridData {
  userId: string;
  gridId: string;
  createdAt: number;
  updatedAt?: number;
}

export interface IStreamsState {
  readonly streams: VideoStream[];
  readonly gridList: IGrid[];
  readonly activeGridId?: IGrid['gridId'];
  readonly streamThumbnails: IThumbnail[];
  readonly streamInfoList: IKinesisStreamInformation[];
  readonly mutedStreams: KVSName[];
}

export interface IThumbnail {
  kvsName: string,
  location: string,
  siteId: string
}

export interface IKinesisStreamInformation {
  creationTime?: Date;
  dataRetentionInHours?: number;
  deviceName?: string;
  kmsKeyId?: string;
  mediaType?: string;
  status?: string;
  streamARN?: string;
  streamName?: string;
  version?: string;
}