import { Box, Fade } from '@mui/material';
import { IStoreState } from '@store/index';
import { clearEvents, pushEvents, setInboxFilter, setSelectedEvents, setUnloadedEventCount, updateEvents } from '@store/views/overviewView/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebsocketConnections from './WebsocketConnections';
import { setUsers } from '@store/users/actions';
import { isEmpty, isEqual, isString, orderBy } from 'lodash';
import Utils from '@utils/index';
import EventPooler from '@classes/EventPooler';
import { useSnackbar } from 'notistack';
import Toolbar from './Toolbar';
import CompanyZoneNamesLoader from '@components/common/Loaders/CompanyZoneNamesLoader';
import CompanyUsersLoader from '@components/common/Loaders/CompanyUsersLoader';
import TabEventsLoader, { ITabEventsLoaderHandles } from './TabEventsLoader';
import EventInboxMainContent from './EventInboxMainContent';
import EventInboxRightPanel from './EventInboxRightPanel';
import { useUpdateMutedStreams } from '@hooks/useUpdateMutedStreams';
import { addMutedStream, removeMutedStream } from '@store/streams/actions';
import EventsFilter, { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';
import { loadOverviewFiltersFromStorage } from '@hooks/useLoadUserPreferences';
import LoadingContent from './LoadingContent';
import usePrevious from '@hooks/usePrevious';
import API from '@API/index';

type DateRange = {
  start: Date;
  end: Date;
  timezone: 'local' | 'utc';
}

export const STREAM_FEATURES = {
  stream: { view: true },
  events: {
    motion: { active: true, read: true },
    zones: { active: true, read: true },
    safetyInfraction: { active: true, read: true },
    person: { active: true, read: true },
    vehicle: { active: true, read: true },
    // legacy
    socialDistance: { active: true, read: true },
    fire: { active: true, read: true },
    vehicleLicensePlate: { active: true, read: true }
  },
  zones: { active: true, read: true, write: true },
  exports: { active: true, read: true, write: true },
  imageAnnotations: { active: true, read: true, write: true },
  timelapse: { active: true, read: true, write: true },
  ptz: { active: true, read: true, write: true },
  ppeDetection: true,
  socialDistancing: true,
  fireDetection: true,
  licensePlateDetection: true
};

const OverviewEventInbox: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const filters = useSelector((store: IStoreState) => store.overviewView.filters);
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);
  const eventInboxSidePanel = useSelector((store: IStoreState) => store.overviewView.eventInboxSidePanel);
  const sites = useSelector((store: IStoreState) => store.sites.sites);
  const hideOngoingEvents = useSelector((store: IStoreState) => store.overviewView.overviewOptions.hideOngoingEvents);
  const userData = useSelector((store: IStoreState) => store.session.userData);
  const users = useSelector((store: IStoreState) => store.users.users);
  const zoneDatabase = useSelector((store: IStoreState) => store.overviewView.zoneDatabase);
  const eventCount = useSelector((store: IStoreState) => store.overviewView.eventCount);
  const events = useSelector((store: IStoreState) => store.overviewView.events);
  const unloadedEventCount = useSelector((store: IStoreState) => store.overviewView.unloadedEventCount);
  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);
  const previousSite = usePrevious(selectedSite);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [timeRange, setTimeRange] = React.useState<DateRange | undefined>(undefined);
  const [siteListOpen, setSiteListOpen] = React.useState<boolean>(false);
  const [timeInputOpen, setTimeInputOpen] = React.useState<boolean>(false);
  const [zoneNames, setZoneNames] = React.useState<Map<string, string>>(new Map());
  const [loadingZones, setLoadingZones] = React.useState<boolean>(true);
  const [isLoadingAnyTab, setLoadingAnyTab] = React.useState<boolean>(false);
  const [inputFocused, setInputFocused] = React.useState<boolean>(false);
  const [connectedToLiveEvents, setConnectedToLiveEvents] = React.useState<boolean>(true);
  const [otherUsersSelectedEvents, setOtherUsersSelectedEvents] = React.useState<Array<{ userId: string, eventId: string }>>([]);
  const [filterOptions, setFilterOptions] = React.useState<Record<string, string[]>>({});
  const [changingContext, setChangingContext] = React.useState<boolean>(false);
  const [changingSiteContext, setChangingSiteContext] = React.useState<boolean>(false);

  const zoneNamesRef = React.useRef(zoneNames);
  const eventPooler = React.useRef<EventPooler>();
  const filtersRef = React.useRef<EventsFilterParams | undefined>(filters);
  const otherUsersSelectedEventsRef = React.useRef<Array<{ userId: string, eventId: string }>>(otherUsersSelectedEvents)
  const hideOngoingEventsRef = React.useRef<boolean>(hideOngoingEvents);
  const dispatchRef = React.useRef(dispatch);
  const tabEventsLoaderRef = React.useRef<ITabEventsLoaderHandles | null>(null);
  const eventCountRef = React.useRef(eventCount);

  const siteFiltering = React.useMemo(() => {
    if (selectedSite === undefined) return {
      sites: sites.get(selectedCompany?.companyId || "") || [],
    }

    return {
      sites: [selectedSite],
    }
  }, [selectedCompany, selectedSite, sites])

  React.useEffect(() => {
    if (previousSite !== undefined && selectedSite === undefined) {
      setChangingContext(true);
      setTimeout(() => {
        setTimeRange(undefined)
        dispatch(clearEvents())
        dispatch(setSelectedEvents([]))
        loadOverviewFiltersFromStorage(dispatch);
      }, 1000)
      setTimeout(() => {
        setChangingContext(false);
      }, 2000)
    }
  }, [dispatch, selectedSite, previousSite])

  React.useEffect(() => {
    if ((previousSite === undefined && selectedSite !== undefined)) {
      setChangingSiteContext(true);
      setTimeout(() => {
        setTimeRange(undefined)
        dispatch(clearEvents())
        dispatch(setSelectedEvents([]))
        dispatch(setInboxFilter({
          motion: { enable: true, filters: [] },
          people: { enable: true, filters: [] },
          safety: { enable: true, filters: [] },
          vehicles: { enable: true, filters: [] },
          zones: { enable: true, filters: [] },
          sites: { sites: [selectedSite.siteId] },
          streams: { streams: streams.filter(el => el.siteId === selectedSite.siteId).map(el => el.kvsName) }
        }, false))
      }, 1000)
      setTimeout(() => {
        setChangingSiteContext(false);
      }, 2000)
    }
  }, [dispatch, selectedSite, previousSite, streams])

  React.useEffect(() => {
    if (previousSite !== undefined && selectedSite !== undefined && previousSite.siteId !== selectedSite.siteId) {
      setChangingSiteContext(true);
      setTimeout(() => {
        setTimeRange(undefined)
        dispatch(clearEvents())
        dispatch(setSelectedEvents([]))
        dispatch(setInboxFilter({
          motion: { enable: true, filters: [] },
          people: { enable: true, filters: [] },
          safety: { enable: true, filters: [] },
          vehicles: { enable: true, filters: [] },
          zones: { enable: true, filters: [] },
          sites: { sites: [selectedSite.siteId] },
          streams: { streams: streams.filter(el => el.siteId === selectedSite.siteId).map(el => el.kvsName) }
        }, false))
      }, 1000)
      setTimeout(() => {
        setChangingSiteContext(false);
      }, 2000)
    }
  }, [dispatch, selectedSite, previousSite, streams])

  React.useEffect(() => { eventCountRef.current = eventCount }, [eventCount])
  React.useEffect(() => { zoneNamesRef.current = zoneNames }, [zoneNames])
  React.useEffect(() => { dispatchRef.current = dispatch }, [dispatch])
  React.useEffect(() => {
    otherUsersSelectedEventsRef.current = otherUsersSelectedEvents;
  }, [otherUsersSelectedEvents])
  React.useEffect(() => {
    hideOngoingEventsRef.current = hideOngoingEvents;
  }, [hideOngoingEvents])
  React.useEffect(setup, []);
  React.useEffect(cleanUp, []);
  React.useEffect(() => { filtersRef.current = filters; }, [filters])
  useUpdateMutedStreams(selectedCompany?.companyId);
  React.useEffect(handleTimeRangeChange, [timeRange])
  React.useEffect(() => {
    const newNames = new Map(zoneNamesRef.current);
    let nameAdded: boolean = false;

    Object.values(zoneDatabase).forEach((value) => {
      value.forEach((zone) => {
        if (!newNames.has(zone.id)) {
          nameAdded = true;
          newNames.set(zone.id, zone.name);
        }
      })
    })

    if (nameAdded) setZoneNames(newNames);
  }, [zoneDatabase])

  function setup() {
    eventPooler.current = new EventPooler(15000, (events: VideoStreamEvent[]) => {
      dispatchRef.current(pushEvents([...events]));
    });
    eventPooler.current.start();
  }

  function cleanUp() {
    return () => {
      if (eventPooler.current) {
        eventPooler.current.stop();
      }
      dispatchRef.current(clearEvents());
    }
  }

  function handleTimeRangeChange() {
    const now = Date.now();
    if (timeRange) {
      const diffThreshold = 10000;
      const diff = now - timeRange.end.getTime();
      setConnectedToLiveEvents(diff < diffThreshold);
    } else {
      setConnectedToLiveEvents(true);
    }
  }

  function getFilterFeatures() {
    const companyStreams = streams.filter(stream => stream.companyId === selectedCompany?.companyId || "");
    const fireDetection = Utils.Stream.includeFeature(companyStreams, 'fireDetection');

    return {
      ...STREAM_FEATURES,
      events: {
        ...STREAM_FEATURES.events,
        fire: {
          active: fireDetection,
          read: fireDetection
        }
      }
    }
  }

  function onTimeRangeChange(start?: Date, end?: Date, timezone?: 'local' | 'utc') {
    if (start && end) {
      setTimeRange({ start, end, timezone: timezone || 'utc' })
    } else {
      setTimeRange(undefined);
    }
  }

  function showErrorSnackbar(message: string): void {
    enqueueSnackbar(message, { variant: 'error' });
  }

  function handleWebsocketNewEventArrival(message: Array<VideoStreamEvent>): void {
    if (filtersRef.current) {
      const events = new EventsFilter(filtersRef.current).getFilteredEvents(message, hideOngoingEventsRef.current);
      if (!isEmpty(events)) {
        eventPooler.current?.add(events)
      }
    } else {
      eventPooler.current?.add(message);
    }
  }

  React.useEffect(() => {
    API.Events.fetchFilterSuggestions()
      .then((suggestions) => {
        setFilterOptions(values => {
          const distinctValues = Object.fromEntries(Object.entries(values).map(([key, value]) => [key, new Set(value)]));

          if (Object.keys(suggestions.vehicles).length) {
            distinctValues["make"] = new Set(Array.from(distinctValues["make"] || []).concat(Object.keys(suggestions.vehicles)));
            distinctValues["model"] = new Set(Array.from(distinctValues["model"] || []).concat(Object.values(suggestions.vehicles).flat()));
          }
          if (suggestions.colors) {
            distinctValues["color"] = new Set(Array.from(distinctValues["color"] || []).concat(suggestions.colors));
          }
          if (suggestions.states) {
            distinctValues["region"] = new Set(Array.from(distinctValues["region"] || []).concat(suggestions.states));
          }
          
          return Object.entries(distinctValues).reduce((acc, [key, value]) => ({ ...acc, [key]: Array.from(value) }), {});
        })
      })
  }, []);

  React.useEffect(() => {
    setFilterOptions(values => {
      const distinctValues = Object.fromEntries(Object.entries(values).map(([key, value]) => [key, new Set(value)]));
      events.forEach(e => {
        if (e.metadata) {
          const { make, model, color, region } = e.metadata;

          if (make) {
            if (!distinctValues["make"]) {
              distinctValues["make"] = new Set();
            }
            distinctValues["make"].add(make);
          }
          if (model) {
            if (!distinctValues["model"]) {
              distinctValues["model"] = new Set();
            }
            distinctValues["model"].add(model);
          }
          if (color) {
            if (!distinctValues["color"]) {
              distinctValues["color"] = new Set();
            }
            distinctValues["color"].add(color);
          }
          if (region) {
            if (!distinctValues["region"]) {
              distinctValues["region"] = new Set();
            }
            distinctValues["region"].add(region);
          }
        }
      });

      return Object.entries(distinctValues).reduce((acc, [key, value]) => ({ ...acc, [key]: Array.from(value) }), {});
    })
  }, [events])

  if (changingContext === true) {
    return (
      <Fade key="loading-company" in={true} timeout={700}>
        <div style={{ width: '100%', height: '100%' }}>
          <LoadingContent message="Switching to organization context" />
        </div>
      </Fade>
    )
  }

  if (changingSiteContext === true) {
    return (
      <Fade key="loading-site" in={true} timeout={700}>
        <div style={{ width: '100%', height: '100%' }}>
          <LoadingContent message={`Switching to ${selectedSite?.name} context`} />
        </div>
      </Fade>
    )
  }

  return (
    <Fade key="main-body" in={true} timeout={700}>
      <Box id="overview-event-inbox-container" gridTemplateRows="auto" width="100%" height="100%">
        <CompanyUsersLoader
          onLoad={(users) => { dispatch(setUsers(orderBy(users, ['givenName'], ['asc']))) }}
          onError={(error) => { showErrorSnackbar(Utils.Error.getErrorMessage(error)); }}
        />
        <CompanyZoneNamesLoader
          onLoad={(zoneNames) => { setZoneNames(zoneNames); }}
          onError={(error) => showErrorSnackbar(`Error getting zone names for company: ${Utils.Error.getErrorMessage(error)}`)}
          onRoutineCompleted={() => setLoadingZones(false)}
          updateInterval={600000}
        />
        <WebsocketConnections
          onMessage={(e: VideoStreamEvent) => { handleWebsocketNewEventArrival([e]) }}
          onUpdateMessage={(e: VideoStreamEvent) => { dispatch(updateEvents([e])); }}
          streams={streams.filter((stream) => stream.companyId === selectedCompany?.companyId || "")}
          company={selectedCompany}
          connectToLiveEvents={connectedToLiveEvents}
          filters={filters}
          onMutedMessage={(message) => {
            const target: VideoStream | undefined = streams.find((el) => el.kvsName === message.src);

            if (message.muted === true) {
              dispatch(addMutedStream(message.src))
            } else {
              dispatch(removeMutedStream(message.src));
            }

            if (target && selectedCompany && target.companyId === selectedCompany.companyId) {
              const streamName: string | undefined = target.defaultName;
              const author: User | undefined = isString(message.userId) && !isEmpty(message.userId) ? users.find(el => el.userId === message.userId) : undefined;

              if (userData && author && streamName && author.userId === userData.userId) {
                enqueueSnackbar(`${streamName} was ${message.muted === true ? "muted" : "unmuted"} by you.`, { variant: 'warning' });
              } else if (author && streamName) {
                enqueueSnackbar(`${streamName} was ${message.muted === true ? "muted" : "unmuted"} by ${author.givenName} ${author.familyName}`, { variant: 'warning' });
              } else if (streamName) {
                enqueueSnackbar(`${streamName} was ${message.muted === true ? "muted" : "unmuted"}`, { variant: 'warning' });
              }
            }
          }}
          onEventSelectionChange={(message: any) => {
            const castMessage = message as { userId: string, data: Array<string> };
            const { userId, data } = castMessage;

            // Discard messages from the own user sending them.
            if (userData?.userId === userId) return;

            const newArray = otherUsersSelectedEventsRef.current.filter(el => el.userId !== userId);
            newArray.push(...data.map(el => ({ userId, eventId: el })));
            setOtherUsersSelectedEvents(newArray);
          }}
        />
        <TabEventsLoader
          ref={tabEventsLoaderRef}
          timeRange={timeRange}
          onLoadStart={() => setLoading(true)}
          onLoadEnd={() => setLoading(false)}
          onTabLoadStatusCange={(status) => setLoadingAnyTab(status)}
          timezone={timeRange?.timezone || 'utc'}
        />
        <Box
          overflow="hidden"
          display="grid"
          height="100%"
          gridTemplateColumns={eventInboxSidePanel === "filter_panel" || eventInboxSidePanel === "options_panel" || eventInboxSidePanel === "zones" ? `auto min-content calc(${rootFontSize}px * 23.75)` : 'auto min-content'}
        >
          <EventInboxMainContent
            siteListOpen={siteListOpen}
            zoneNames={zoneNames}
            loading={loading === true || loadingZones === true}
            hideOngoingEvents={hideOngoingEvents}
            filters={filters}
            inputFocused={inputFocused}
            otherUsersSelectedEvents={otherUsersSelectedEvents}
            timeInputOpen={timeInputOpen}
            onScrollEnd={(tab, lastKey) => {
              if (tabEventsLoaderRef.current && timeRange) {
                if (Utils.Timestamp.edgeTimestampToMiliseconds(lastKey) >= timeRange.end.getTime()) return;

                setLoadingAnyTab(true);
                tabEventsLoaderRef.current.fetchTabEvents(timeRange.start.getTime(), timeRange.end.getTime(), tab === "unassigned" ? undefined : tab, lastKey)
                  .then((events) => {
                    dispatch(pushEvents(events));
                    const count = { ...unloadedEventCount };

                    events.forEach((parent) => {
                      if (parent.tab === "unassigned") count.unassigned -= 1;
                      if (parent.tab === "assigned") count.assigned -= 1;
                      if (parent.tab === "archived") count.archived -= 1;
                      if (parent.tab === "trash") count.trash -= 1;
                      if (parent.tab === "muted") count.muted -= 1;

                      parent.children?.forEach(() => {
                        if (parent.tab === "unassigned") count.unassigned -= 1;
                        if (parent.tab === "assigned") count.assigned -= 1;
                        if (parent.tab === "archived") count.archived -= 1;
                        if (parent.tab === "trash") count.trash -= 1;
                        if (parent.tab === "muted") count.muted -= 1;
                      })
                    })

                    dispatch(setUnloadedEventCount(count));
                  })
                  .catch((err) => {
                    enqueueSnackbar(Utils.Error.getErrorMessage(err), { variant: 'error' })
                  })
                  .finally(() => {
                    setLoadingAnyTab(false);
                  });
              }
            }}
          />
          <Toolbar loadingTab={isLoadingAnyTab} />
          <EventInboxRightPanel
            filterPanelProps={{
              onInputFocus: () => setInputFocused(true),
              onInputBlur: () => setInputFocused(false),
              onApplyFilters: (newFilters) => {
                if (!isEqual(newFilters?.getCurrentParams(), filters)) {
                  dispatch(setInboxFilter(newFilters?.getCurrentParams(), Boolean(selectedSite === undefined)))
                }
              },
              eventsFilterParams: filters,
              features: getFilterFeatures(),
              zones: [],
              noPadding: true,
              maxContainerHeight: "90vh",
              disableIndividualZones: true,
              onSiteListClose: () => setSiteListOpen(false),
              onSiteListOpen: () => setSiteListOpen(true),
              siteFiltering: siteFiltering,
              showTruePositiveFilters: true,
              showFalsePositiveFilters: true,
              showEscalationFilters: true,
              timeRangeSelectorProps: {
                onTimeRangeChange: onTimeRangeChange,
                timeRange: timeRange,
                selectedSite: { timezone: "UTC (GMT+00:00)" } as Site,
                enableLiveOption: true,
                disableLongTermOptions: true,
                multiDay: true,
                maxTimeRangeInHours: 24,
                calendarUtcMessage: 'Timezone of the filter is local',
                onTimeInputClose: () => { setTimeInputOpen(false) },
                onTimeInputOpen: () => { setTimeInputOpen(true) }
              },
              disableEventsTutorial: true,
              singleSite: Boolean(selectedSite),
              filterOptions: filterOptions,
              defaultState: false
            }}
          />
        </Box>
      </Box>
    </Fade>
  )
}

export default OverviewEventInbox;