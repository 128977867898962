import { API } from 'aws-amplify';
import qs from 'qs';

async function getSimilarWorkers(
    workerId: number,
    maxMatches?: number, 
    asCompany?: string
): Promise<CrewWorker[]> {
    try {
        const path = `/workers/${workerId}/similar?${qs.stringify({
            maxMatches,
            asCompany: asCompany
        })}`;
        const data = await API.get('api-service', path, {});

        return data.data;
    } catch (error: any) {
        throw error;
    }
}

export default getSimilarWorkers;
