import { API } from "aws-amplify";

async function updateUser(userData: User): Promise<User> {
  try {
    const path: string = `/users/${userData.userId}`;
    const tempUser: any = {
      webPushSubscriptions: userData.communication?.webPushSubscriptions,
      base64picture: userData.pictureURL,
      givenName: userData.givenName,
      familyName: userData.familyName,
      pictureContentType: userData.pictureContentType,
      phone1: userData.communication?.phone1,
    }

    const options = {
      body: tempUser
    }
    const data = await API.put(
      'api-service',
      path,
      options
    );

    return data.data as User;
  } catch (error: any) {
    throw error;
  }
}

export default updateUser;
