import authorize from "./authorize";
import getHost from "./getHost";
import getSubstream from "./getSubstream";
import issueCommand from "./issueCommand";

export interface IPTZ {
  getHost(mjpgUrl: string): string;
  getSubstream(mjpgUrl: string): number;
  authorize(mjpgUrl: string): void;
  issueCommand(url: string, callback?: (error?: any) => void): void
}

const PTZ: IPTZ = {
  getHost,
  getSubstream,
  authorize,
  issueCommand
}

export default PTZ;
