export function createFlaggedWorkersFilters(showSite: boolean = false): FilterProperties[] {
  if (showSite === false) {
    return [
      { label: 'Name', property: 'name', type: 'string' },
      { label: 'Flagged On', property: 'timestamp', type: 'date' },
      { label: 'Reason', property: 'reason', type: 'string' },
      { label: 'Attempts', property: 'attemptsNumber', type: 'number' },
    ]
  }
  return [
    { label: 'Name', property: 'name', type: 'string' },
    { label: 'Site', property: 'site', type: 'string' },
    { label: 'Flagged On', property: 'timestamp', type: 'date' },
    { label: 'Reason', property: 'reason', type: 'string' },
    { label: 'Attempts', property: 'attemptsNumber', type: 'number' },
  ];
}
