import { EventsFilterParams } from "./EventsFilter";
import IFilterEvents from "./IFilterEvents";

class SitesFilter implements IFilterEvents {
  filter(params: EventsFilterParams, events: VideoStreamEvent[]): VideoStreamEvent[] {
    if (!params.sites) return events;

    return events.filter((event) => {
      const { siteId } = event;
      
      return params.sites?.sites.includes(siteId);
    })
  }
}

export default SitesFilter;
