import { API } from "aws-amplify";

async function postSnapshot(kvsName: string, base64Image: string, imageContentType: string, timestamp: number): Promise<ImageDownload> {
  try {
    const path: string = `/annotated-images`;

    const options = {
      body: {
        kvsName,
        base64Image,
        imageContentType,
        timestamp
      }
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    return data.data as ImageDownload;
  } catch (error: any) {
    throw error;
  }
}

export default postSnapshot;
