import { useMediaQuery } from '@mui/material';
import React from 'react';

export function useSmallScreenQuery() {
  const isTabletOrMobile = useMediaQuery('(max-width: 1000px)');
  
  const [state, setState] = React.useState<boolean>(isTabletOrMobile)

  React.useEffect(() => {
    setState(isTabletOrMobile)
  }, [isTabletOrMobile])

  return state;
}