import * as moment from 'moment-timezone';

function containsWeekend(start: number, end: number, timezone: string): boolean {
  try {
    const parsedTimezone: string = timezone.includes(' ') ? timezone.split(' ')[0] : timezone;
    const s = moment.tz(start, parsedTimezone);
    const e = moment.tz(end, parsedTimezone);
  
    let containsWeekend: boolean = false;
    
    while (s.toDate().valueOf() < e.toDate().valueOf()) {
      const isSaturdayOrSunday: boolean = s.get('day') === 6 || s.get('day') === 0;
      if (isSaturdayOrSunday === true) {
        containsWeekend = true;
        break;
      }
  
      s.add(1, 'day');
    }
    
    return containsWeekend;
  } catch (error) {
    return false;
  }
}

export default containsWeekend;
