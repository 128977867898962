import React from 'react'
import API from '@API/index';

type LivePortionProps = {
  Id: string,
  map: mapboxgl.Map,
  events?: GeoJSON.FeatureCollection
}

const LivePortion: React.FC<LivePortionProps> = (props) => {
  const { Id, map, events } = props;

  const IdRef = React.useRef(Id);
  const mapRef = React.useRef(map);
  const eventsRef = React.useRef(events);

  React.useEffect(() => { IdRef.current = Id }, [Id])
  React.useEffect(() => { mapRef.current = map }, [map]);
  React.useEffect(() => { eventsRef.current = events }, [events]);
  React.useEffect(onMount, []);

  function onMount() {
    const initialData = eventsRef.current ? eventsRef.current : { type: "FeatureCollection", features: [] } as GeoJSON.FeatureCollection;
    API.MapBox.addGeoJSONSource(mapRef.current, IdRef.current, initialData);
    API.MapBox.addCameraIcon(mapRef.current, IdRef.current);
  }

  const source = map.getSource(Id) as mapboxgl.GeoJSONSource;
  if (source && events) source.setData(events);

  return null;
}
export default LivePortion;