import PubSubsManager from '@classes/PubSubsManager';
import { CheckCircle, MonitorHeartOutlined, PauseCircleFilledOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';

type ConnectivityStatusProps = {

}

const ConnectivityStatus: React.FC<ConnectivityStatusProps> = () => {
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);

  const [connectedToCompanyMotionEvents, setConnectedToCompanyMotionEvents] = React.useState<boolean>(true);
  const [connectedToCompanyPersonEvents, setConnectedToCompanyPersonEvents] = React.useState<boolean>(true);
  const [connectedToCompanyVehicleEvents, setConnectedToCompanyVehicleEvents] = React.useState<boolean>(true);
  const [connectedToCompanySafetyEvents, setConnectedToCompanySafetyEvents] = React.useState<boolean>(true);
  const [connectedToCompanyZoneEvents, setConnectedToCompanyZoneEvents] = React.useState<boolean>(true);

  const [connectedToCompanyUpdates, setConnectedToCompanyUpdates] = React.useState<boolean>(true);

  const selectedCompanyRef = React.useRef(selectedCompany);

  const updateStates = React.useCallback(() => {
    if (selectedCompanyRef.current) {
      setConnectedToCompanyMotionEvents(PubSubsManager.instance.isSubscribedTo(`${selectedCompanyRef.current?.companyId}-motion`));
      setConnectedToCompanyVehicleEvents(PubSubsManager.instance.isSubscribedTo(`${selectedCompanyRef.current?.companyId}-vehicle-detected`));
      setConnectedToCompanyPersonEvents(PubSubsManager.instance.isSubscribedTo(`${selectedCompanyRef.current?.companyId}-person-detected`));
      setConnectedToCompanySafetyEvents(PubSubsManager.instance.isSubscribedTo(`${selectedCompanyRef.current?.companyId}-safety-infraction`));
      setConnectedToCompanyZoneEvents(PubSubsManager.instance.isSubscribedTo(`${selectedCompanyRef.current?.companyId}-geofence-triggered`));
      setConnectedToCompanyUpdates(PubSubsManager.instance.isSubscribedTo(`updates-${selectedCompanyRef.current?.companyId}`));
    }
  }, [])

  React.useEffect(() => { selectedCompanyRef.current = selectedCompany }, [selectedCompany]);
  React.useEffect(() => {
    const interval = setInterval(updateStates, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [updateStates])

  return (
    <Tooltip title={(
      <Box display="flex" flexDirection="column">
        <Box>
          <span>Health status:</span>
        </Box>
        <Box display="grid" gridTemplateColumns="auto min-content" alignItems="center" marginTop="3px" width="100%">
          <span>{`Motion events: `}</span>{connectedToCompanyMotionEvents ?
            <CheckCircle style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#52a982" /> :
            <PauseCircleFilledOutlined style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#d97060" />}
        </Box>
        <Box display="grid" gridTemplateColumns="auto min-content" alignItems="center" marginTop="3px" width="100%">
          <span>{`Zone events: `}</span>{connectedToCompanyZoneEvents ?
            <CheckCircle style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#52a982" /> :
            <PauseCircleFilledOutlined style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#d97060" />}
        </Box>
        <Box display="grid" gridTemplateColumns="auto min-content" alignItems="center" marginTop="3px" width="100%">
          <span>{`Safety events: `}</span>{connectedToCompanySafetyEvents ?
            <CheckCircle style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#52a982" /> :
            <PauseCircleFilledOutlined style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#d97060" />}
        </Box>
        <Box display="grid" gridTemplateColumns="auto min-content" alignItems="center" marginTop="3px" width="100%">
          <span>{`People events: `}</span>{connectedToCompanyPersonEvents ?
            <CheckCircle style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#52a982" /> :
            <PauseCircleFilledOutlined style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#d97060" />}
        </Box>
        <Box display="grid" gridTemplateColumns="auto min-content" alignItems="center" marginTop="3px" width="100%">
          <span>{`Vehicle events: `}</span>{connectedToCompanyVehicleEvents ?
            <CheckCircle style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#52a982" /> :
            <PauseCircleFilledOutlined style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#d97060" />}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <span>{`Updates: `}</span>{connectedToCompanyUpdates ?
            <CheckCircle style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#52a982" /> :
            <PauseCircleFilledOutlined style={{ width: '1rem', height: '1rem', marginLeft: '2px' }} fontSize="small" htmlColor="#d97060" />}
        </Box>
      </Box>
    )}>
      <IconButton disableRipple>
        <MonitorHeartOutlined htmlColor='black' />
      </IconButton>
    </Tooltip>
  )
}

export default ConnectivityStatus;
