import Utils from "..";

/**
 * Generates the Thumbnail for a given stream, based on a S3 bucket and company, site and camera ID.
 * @param stream Target stream.
 * @returns returns URL to a S3 Bucket thumbnail generated by the Backend.
 */
async function getStreamThumbnail(stream: VideoStream): Promise<string> {
  if (process.env.REACT_APP_STREAM_THUMBNAILS_S3_BUCKET) {
    const data = await Utils.S3.getImageUrl(`${stream.companyId}/${stream.siteId}/${stream.kvsName}`, {},
      process.env.REACT_APP_STREAM_THUMBNAILS_S3_BUCKET)
    if (data)
      return data.url;
    else
      throw Object.assign(
        new Error("Thumbnail image source not set"),
        { code: 404 }
      );
  } else
    throw Object.assign(
      new Error("Thumbnail source URL is not set"),
      { code: 404 }
    );
}

export default getStreamThumbnail;
