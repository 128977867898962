import React from 'react';
import PubSubsManager from '@classes/PubSubsManager';
import EventConverter from '@classes/StreamEvents/WSEventConverter';
import EventUpdateTracker from '@API/global_event_inbox/EventUpdateTracker';
import { isEqual } from 'lodash';
import { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';

type WebsocketConnectionsProps = {
  onMessage: (message: VideoStreamEvent) => void;
  onUpdateMessage: (message: VideoStreamEvent) => void;
  onMutedMessage: (message: MutedEvent) => void;
  onEventSelectionChange: (message: any) => void;
  streams: VideoStream[];
  company?: Company;
  connectToLiveEvents: boolean;
  filters: EventsFilterParams | undefined;
}

const WebsocketConnections: React.FC<WebsocketConnectionsProps> = (props) => {
  const { onMessage, onUpdateMessage, company, streams, connectToLiveEvents, onMutedMessage, onEventSelectionChange, filters } = props;

  const companyRef = React.useRef(company);
  const connectToLiveEventsRef = React.useRef(connectToLiveEvents)
  const streamsRef = React.useRef(streams);
  const onMessageRef = React.useRef(onMessage);
  const onUpdateMessageRef = React.useRef(onUpdateMessage);
  const onMutedMessageRef = React.useRef(onMutedMessage)
  const onEventSelectionChangeRef = React.useRef(onEventSelectionChange);

  const connectWebsockets = React.useCallback(() => {
    if (companyRef.current) {
      // if (connectToLiveEventsRef.current === true) {
      //   PubSubsManager.instance.subscribeToCompany(companyRef.current, (message: WebsocketVideoStreamEvent) => {
      //     if (isEventValid(message)) {
      //       const stream: VideoStream | undefined = streamsRef.current.find(el => el.kvsName === message.src);
      //       const converter: EventConverter = new EventConverter(stream);
      //       const event: VideoStreamEvent = converter.convert(message);
      //       onMessageRef.current(event);
      //     }
      //   })
      // }

      PubSubsManager.instance.subscribeToCompanyUpdates(companyRef.current, (message: GlobalEvent) => {
        const converter: EventConverter = new EventConverter();
        const event: VideoStreamEvent = converter.convertFromDynamoDB(message);

        if (EventUpdateTracker.has(event)) {
          EventUpdateTracker.unregister(event);
          return;
        }

        onUpdateMessageRef.current(event);
      })

      PubSubsManager.instance.subscribeToMutedStreamsUpdates((message: MutedEvent) => {
        onMutedMessageRef.current(message);
      })

      PubSubsManager.instance.subscribeToEventSelectionUpdates((message: any) => {
        onEventSelectionChangeRef.current(message);
      })
    }
  }, [])

  React.useEffect(() => { onEventSelectionChangeRef.current = onEventSelectionChange }, [onEventSelectionChange])
  React.useEffect(() => { onMutedMessageRef.current = onMutedMessage }, [onMutedMessage])
  React.useEffect(() => { onUpdateMessageRef.current = onUpdateMessage }, [onUpdateMessage])
  React.useEffect(() => { onMessageRef.current = onMessage }, [onMessage])
  React.useEffect(() => { streamsRef.current = streams }, [streams])
  React.useEffect(() => { connectToLiveEventsRef.current = connectToLiveEvents }, [connectToLiveEvents])
  React.useEffect(() => { companyRef.current = company }, [company])
  React.useEffect(() => {
    connectWebsockets();

    return () => {
      disconectWebsockets();
    }
  }, [connectWebsockets]);
  React.useEffect(handleWebsocketChange, [connectToLiveEvents, filters])
  // React.useEffect(handleLiveChange, [connectToLiveEvents])

  // function handleLiveChange(): void {
  //   if (companyRef.current) {
  //     if (connectToLiveEventsRef.current === true) {
  //       PubSubsManager.instance.subscribeToCompany(companyRef.current, (message: WebsocketVideoStreamEvent) => {
  //         if (isEventValid(message)) {
  //           const stream: VideoStream | undefined = streamsRef.current.find(el => el.kvsName === message.src);
  //           const converter: EventConverter = new EventConverter(stream);
  //           const event: VideoStreamEvent = converter.convert(message);
  //           onMessageRef.current(event);
  //         }
  //       })
  //     } else {
  //       PubSubsManager.instance.unsubscribe(companyRef.current);
  //     }
  //   }
  // }

  function handleWebsocketChange() {
    if (companyRef.current) {
      if (connectToLiveEvents === true) {
        const handler = (message: WebsocketVideoStreamEvent) => {
          if (isEventValid(message)) {
            const stream: VideoStream | undefined = streamsRef.current.find(el => el.kvsName === message.src);
            const converter: EventConverter = new EventConverter(stream);
            const event: VideoStreamEvent = converter.convert(message);
            onMessageRef.current(event);
          }
        };

        if (filters?.motion.enable) {
          PubSubsManager.instance.subscribeToCompanyByEventType(companyRef.current, "motion", handler)
        } else {
          PubSubsManager.instance.unsubscribeToCompanyEventsByType(companyRef.current, "motion")
        }

        if (filters?.people.enable) {
          PubSubsManager.instance.subscribeToCompanyByEventType(companyRef.current, "person-detected", handler)
        } else {
          PubSubsManager.instance.unsubscribeToCompanyEventsByType(companyRef.current, "person-detected")
        }

        if (filters?.vehicles.enable) {
          PubSubsManager.instance.subscribeToCompanyByEventType(companyRef.current, "vehicle-detected", handler)
        } else {
          PubSubsManager.instance.unsubscribeToCompanyEventsByType(companyRef.current, "vehicle-detected")
        }

        if (filters?.safety.enable) {
          PubSubsManager.instance.subscribeToCompanyByEventType(companyRef.current, "safety-infraction", handler)
        } else {
          PubSubsManager.instance.unsubscribeToCompanyEventsByType(companyRef.current, "safety-infraction")
        }

        if (filters?.zones.enable) {
          PubSubsManager.instance.subscribeToCompanyByEventType(companyRef.current, "geofence-triggered", handler)
        } else {
          PubSubsManager.instance.unsubscribeToCompanyEventsByType(companyRef.current, "geofence-triggered")
        }
      } else {
        PubSubsManager.instance.unsubscribeToCompanyEvents(companyRef.current);
      }
    }
  }

  function disconectWebsockets(): void {
    if (companyRef.current) {
      PubSubsManager.instance.unsubscribeToCompanyEvents(companyRef.current);
      PubSubsManager.instance.unsubscribeToCompanyUpdates(companyRef.current);
      PubSubsManager.instance.unsubscribeToMutedUpdates()
      PubSubsManager.instance.unsubscribeToEventSelectionUpdates();
    }
  }

  function isEventValid(message: WebsocketVideoStreamEvent): boolean {
    if (message.s && message.e && isEqual(message.s, message.e)) return false;
    return Boolean((message.e && message.e !== 0 && message.s) || (message.ce && message.ce !== 0));
  }

  return null;
}

export default WebsocketConnections;
