import React from 'react';
import { Box } from '@mui/material';

type ZoneThumbnailProps = {
  zone: Zone;
}

const ZoneThumbnail: React.FC<ZoneThumbnailProps> = (props) => {
  const { zone } = props;

  const canvasRef = React.useRef<null | HTMLCanvasElement>(null);
  const zoneRef = React.useRef(zone)

  React.useEffect(() => { zoneRef.current = zone }, [zone]);
  React.useEffect(draw, [])

  function draw() {
    if (canvasRef.current) {
      const { color, type, coordinates } = zoneRef.current;

      const context = canvasRef.current.getContext('2d');
      const width = canvasRef.current.width;
      const height = canvasRef.current.height;

      if (context) {
        try {
          context.clearRect(0, 0, width, height);

          context.lineWidth = 1;
          context.strokeStyle = color;
          context.fillStyle = color;
  
          if (type === "ignore") {
            context.strokeStyle = `rgba(255, 99, 67, 1)`;
            context.fillStyle = 'rgba(255, 99, 67, 0.5)';
          }
  
  
          context.beginPath();
  
          context.moveTo(width * coordinates[0][0], height * coordinates[0][1]);
          for (let i = 0; i < coordinates.length; i += 1) {
            context.lineTo(width * coordinates[i][0], height * coordinates[i][1]);
            context.stroke();
          }
  
          context.lineTo(width * coordinates[0][0], height * coordinates[0][1]);
          context.stroke();
  
          context.fill();
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  return (
    <Box marginRight="1rem" display="flex" height="40px" width="71.11px" border="1px solid rgba(0, 0, 0, 0.12)">
      <canvas style={{ width: '100%', height: '100%' }} ref={canvasRef} data-cy="zone-thumbnail-canvas" />
    </Box>
  )
}

export default ZoneThumbnail;