import deleteAttendance from "./deleteAttendance";
import getAttendance from "./getAttendance";
import postAttendance from "./postAttendance";
import updateAttendance from "./updateAttendance";
export interface IAttendanceAPI {
  getAttendance(
    startTime: Date,
    endTime: Date,
    siteId: string, 
    asCompany?: string
  ): Promise<{
    entries: (TimeEntry & { startTime: number, endTime: number })[];
    startTime: Date,
    endTime: Date,
    siteId: string,
  }>;
  postAttendance(siteId: string, form: CreateTimeEntryForm, asCompany?: string): Promise<TimeEntry>;
  updateAttendance(siteId: string, id: string, form: CreateTimeEntryForm, asCompany?: string): Promise<TimeEntry>;
  deleteAttendance(id: string, asCompany?: string): Promise<void>;
}

const Attendance: IAttendanceAPI = {
  getAttendance,
  postAttendance,
  updateAttendance,
  deleteAttendance
}

export default Attendance;
