import Utils from "@utils/index";
import { API } from "aws-amplify";

async function getUsers(companyId?: string): Promise<User[]> {
  try {
    const path: string = `/users`;
    const init = {
      queryStringParameters: {
        companyId: companyId
      },
    };
    const data = await API.get(
      'api-service',
      path,
      init
    )

    const users = data.data as User[];

    users.forEach(element => {
      if (!element.pictureURL || element.pictureURL === "") {
        element.pictureURL = 'https://forsight-user-pictures.s3-us-west-2.amazonaws.com/default.jpeg'
      }
      element.color = Utils.User.generateBackgroundFromColorRange();
    })
    
    const tempUsers = [...users].sort((u1, u2) =>
      (u1?.givenName || "z").localeCompare((u2?.givenName || "z")))

    return tempUsers as User[];
  } catch (error: any) {
    throw error;
  }
}

export default getUsers;
