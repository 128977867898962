import { API } from "aws-amplify";

async function listSiteDownloads(siteId: string): Promise<VideoDownload[]> {
  try {
    const path: string = `/download/video/list/${siteId}`;

    const response = await API.get(
      'api-service',
      path,
      {}
    ) as APIResponse<VideoDownload[]>;

    return response.data || [];
  } catch (error: any) {
    throw error;
  }
}

export default listSiteDownloads;
