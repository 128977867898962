import getMotionHeatmaps from "./getMotionHeatmaps";

export interface IHeatmaps {
  getMotionHeatmaps: (companyId: string, siteId?: string, timezone?: string) => Promise<Heatmap[]>;
};

const Heatmaps: IHeatmaps = {
  getMotionHeatmaps
}

export default Heatmaps;
