import getTimelapse from './getTimelapse';
import createTimelapse from './createTimelapse';
import getTimelapseVideoLink from './getTimelapseVideoLink';
import getTimelapseShareLink from './getTimelapseShareLink';
import deleteTimelapse from './deleteTimelapse';
import getTimelapseThumbnail from './getTimelapseThumbnail';
import listSiteTimelapses from './listSiteTimelapses';
import cancelTimelapse from './cancelTimelapse';

export interface ITimelapsesAPI {
  /**
   * Fetch a Timelapse data.
   * @param kvsName Stream ID.
   * @param timelapseId Timelapse ID.
   */
  getTimelapse(kvsName: string, timelapseId: string): Promise<Timelapse>;

  /**
   * Start timelapse creation on the Backend and notify in case of errors or success.
   * @param params Timelapse required params.
   * @param onSuccess Execute on creation.
   * @param onError Execute on failure.
   */
  createTimelapse(
    params: TimelapseCreationParams,
    requestId: string,
    onSuccess: (timelapse: Timelapse, requestId: string) => void,
    onError: (error: any, requestId: string) => void
  ): Promise<void>;

  getTimelapseVideoLink(path: string): Promise<string>;
  getTimelapseShareLink(path: string): Promise<string>;
  deleteTimelapse(kvsName: string, timelapseId: string): Promise<void>;
  getTimelapseThumbnail(path: string): Promise<string>;
  listSiteTimelapses(siteId: string): Promise<Timelapse[]>;
  cancelTimelapse(kvsName: string, timelapseId: string): Promise<void>;
}

const Timelapses: ITimelapsesAPI = {
  getTimelapse,
  createTimelapse,
  getTimelapseVideoLink,
  getTimelapseShareLink,
  deleteTimelapse,
  getTimelapseThumbnail,
  listSiteTimelapses,
  cancelTimelapse
}

export default Timelapses;
