import React from 'react';
import panzoom from 'panzoom';
import styled from 'styled-components';

const Container = styled.div`
  z-index: 99;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  pointer-events: none;
  position: absolute;
`

const Minimap = styled.div<{ minimapMargin?: { left: string; bottom: string } }>`
  width: 11.5vw;
  aspect-ratio: 16 / 9;

  border: 1px solid white;
  margin-left: ${props => props.minimapMargin ? props.minimapMargin.left : '1rem'};
  margin-bottom: ${props => props.minimapMargin ? props.minimapMargin.bottom : '1rem'};

  background-color: rgba(0, 0, 0, 0.5);
`

const Camera = styled.div`
  aspect-ratio: 16 / 9;

  border: 1px solid white;
`

type PanZoomProps = {
  videoRef: HTMLVideoElement | HTMLImageElement | null;
  disableMouseWheel?: boolean;
  onZoom?: (zoomFactor: number) => void;
  showMinimap?: boolean;
  isFullscreen: boolean;
  minimapMargin?: {
    left: string,
    bottom: string
  };
  onScaleChange?: (scale: number) => void;
  disableDoubleClickZoom?: boolean;
  onZoomActivate?: (active: boolean) => void;
  onPanZoomMovement?: (transform: { x: number, y: number, scale: number }) => void;
}

export interface IPanZoomHandles {
  zoomIn: (value?: number) => void;
  zoomOut: (value?: number) => void;
  setZoom: (value: number) => void;
}

const PanZoom: React.ForwardRefRenderFunction<IPanZoomHandles, PanZoomProps> = (props, ref) => {
  const {
    videoRef,
    disableMouseWheel,
    onZoom,
    showMinimap,
    isFullscreen,
    minimapMargin,
    onScaleChange,
    disableDoubleClickZoom,
    onZoomActivate,
    onPanZoomMovement
  } = props;

  const [scale, setScale] = React.useState<number>(1);
  const [position, setPosition] = React.useState<Vector2>({ x: 0, y: 0 })

  const minimapRef = React.useRef<HTMLDivElement>();
  const panZoom = React.useRef<any>();
  const vRef = React.useRef<HTMLVideoElement | HTMLImageElement>();
  const onScaleChangeRef = React.useRef(onScaleChange)
  const disableMouseWheelRef = React.useRef(disableMouseWheel)
  const disableDoubleClickZoomRef = React.useRef(disableDoubleClickZoom)
  const onZoomRef = React.useRef(onZoom)
  const onZoomActivateRef = React.useRef(onZoomActivate)
  const onMovementRef = React.useRef(onPanZoomMovement)

  const zoomActive = React.useMemo(() => {
    if (scale === 1) return false;
    return true;
  }, [scale])

  React.useEffect(() => { onMovementRef.current = onPanZoomMovement }, [onPanZoomMovement]);
  React.useEffect(() => { onZoomActivateRef.current = onZoomActivate }, [onZoomActivate])
  React.useEffect(() => { if (onZoomActivateRef.current) onZoomActivateRef.current(zoomActive) }, [zoomActive])
  React.useEffect(() => { onZoomRef.current = onZoom }, [onZoom])
  React.useEffect(() => { disableDoubleClickZoomRef.current = disableDoubleClickZoom }, [disableDoubleClickZoom])
  React.useEffect(() => { disableMouseWheelRef.current = disableMouseWheel }, [disableMouseWheel])
  React.useEffect(() => { onScaleChangeRef.current = onScaleChange }, [onScaleChange])

  React.useImperativeHandle(ref, () => ({
    zoomIn,
    zoomOut,
    setZoom
  }))

  function zoomIn(value?: number) {
    if (panZoom.current) {
      panZoom.current.zoomTo(0, 0, value || 1.1)
    }
  }

  function zoomOut(value?: number) {
    if (panZoom.current) {
      panZoom.current.zoomTo(0, 0, value || 0.9)
    }
  }

  function setZoom(value: number) {
    if (panZoom.current) {
      panZoom.current.zoomAbs(0, 0, value);
    }
  }

  React.useEffect(initialize, [videoRef])

  React.useEffect(() => { if (onScaleChangeRef.current) onScaleChangeRef.current(scale) }, [scale]);

  React.useEffect(() => {
    if (isFullscreen === true && panZoom.current) {
      setZoom(1)
      setTimeout(() => {
        panZoom.current.pause();
      }, 10)
    } else if (isFullscreen === false && panZoom.current) {
      setZoom(1)
      setTimeout(() => {
        panZoom.current.resume();
      }, 10)
    }
  }, [isFullscreen])

  function initialize() {
    if (videoRef && !panZoom.current && minimapRef.current) {
      vRef.current = videoRef;

      panZoom.current = panzoom(videoRef as HTMLElement, {
        maxZoom: 3,
        minZoom: 1,
        bounds: true,
        boundsPadding: 1,
        smoothScroll: false,
        zoomSpeed: 0.2,
        zoomDoubleClickSpeed: Boolean(disableDoubleClickZoomRef.current) === true ? 1 : undefined,
        beforeWheel: function () {
          return Boolean(disableMouseWheelRef.current);
        }
      });

      panZoom.current.on('zoom', function (e: any) {
        if (onMovementRef.current) onMovementRef.current(e.getTransform())
        if (onZoomRef.current) onZoomRef.current(e.getTransform().scale);
        setScale(e.getTransform().scale)
        const { x, y, scale } = e.getTransform();
        const relativeY = vRef.current ? (y * -1) / (vRef.current.offsetHeight * scale) : 0
        const relativeX = vRef.current ? (x * -1) / (vRef.current.offsetWidth * scale) : 0;
        const top = minimapRef.current ? minimapRef.current.offsetHeight * relativeY : 0;
        const left = minimapRef.current ? minimapRef.current.offsetWidth * relativeX : 0;

        setPosition({
          x: left,
          y: top
        })
      })

      panZoom.current.on('pan', function (e: any) {
        if (onMovementRef.current) onMovementRef.current(e.getTransform())
        const { x, y, scale } = e.getTransform();
        const relativeY = vRef.current ? (y * -1) / (vRef.current.offsetHeight * scale) : 0
        const relativeX = vRef.current ? (x * -1) / (vRef.current.offsetWidth * scale) : 0;
        const top = minimapRef.current ? minimapRef.current.offsetHeight * relativeY : 0;
        const left = minimapRef.current ? minimapRef.current.offsetWidth * relativeX : 0;

        setPosition({
          x: left,
          y: top
        })
      })
    }
  }

  const width = minimapRef.current ? minimapRef.current.offsetWidth / scale : 0;

  return (
    <Container
      style={{ visibility: showMinimap === false || scale === 1 ? 'hidden' : undefined, width: videoRef?.scrollWidth, height: videoRef?.scrollHeight }}
    >
      <Minimap
        minimapMargin={minimapMargin}
        //@ts-ignore
        ref={minimapRef}
      >
        <Camera
          style={{
            width: width,
            marginTop: position.y,
            marginLeft: position.x
          }}
        />
      </Minimap>
    </Container>
  );
}

export default React.forwardRef(PanZoom);
