import generateStylesUrl from './generateStyles';
import getDistance from './getDistance';

export interface IMapBox {
  generateStylesUrl(style: "light" | "dark" | "streets" | "satellite" | "outdoors" | "satellite-streets"): string;
  getDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: "K" | "N"): number;
}

const MapBox: IMapBox = {
  generateStylesUrl,
  getDistance
}

export default MapBox;
