import { Box, TextareaAutosize, TextareaAutosizeProps, Typography, TypographyProps } from '@mui/material';
import React from 'react';

type TextAreaProps = {
  title: string;
  titleProps?: TypographyProps;
  textAreaProps?: TextareaAutosizeProps;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string | number | readonly string[] | undefined;
}

const TextArea: React.FC<TextAreaProps> = (props) => {
  const { title, titleProps, textAreaProps, value, onChange } = props;

  return (
    <Box marginTop="0.5rem" width="100%" display="grid" gap="0.5rem" gridTemplateRows="min-content min-content">
      <Typography {...titleProps} variant={titleProps?.variant || "subtitle2"}>{title}</Typography>
      <TextareaAutosize
        placeholder={textAreaProps?.placeholder || "Type here..."}
        style={{...textAreaProps?.style, resize: textAreaProps?.style?.resize || "none", padding: textAreaProps?.style?.padding || "0.5rem", fontFamily: 'Roboto', borderRadius: 5}}
        minRows={textAreaProps?.minRows || 5}
        maxRows={textAreaProps?.maxRows || 5}
        value={value}
        onChange={onChange}
        {...textAreaProps}
      />
    </Box>
  );
}

export default TextArea;
