import React from 'react';
import styled from 'styled-components';
import { ViewsNavBar } from '@components/common/ViewsNavBar';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import Utils from '@utils/index';
import { setActivePage } from '@store/session/actions';
import { PageView } from '@store/session/types';
import { setCreatingView, setSelectedView } from '@store/views/viewsView/actions';
import { setActiveStream, setPlayerType, setPubSubPtzSubscriptionFunction, setPubSubSubscriptionFunction } from '@store/selectedStream/actions';
import { Box, CircularProgress, Dialog, Paper } from '@mui/material';
import VideoPlayer, { IVideoPlayerHandles } from '@components/timeline/player/VideoPlayer';
import VideoTimeline, { IVideoTimelineHandles } from '@components/timeline/timeline/VideoTimeline';
import { Helmet } from 'react-helmet';
import StreamValidator from '@components/timeline/StreamValidator';
import EventsLoader from '@components/timeline/EventsLoader';
import CommandPanel, { CommandPanelTabs } from '@components/timeline/CommandPanel/CommandPanel';
import EventsFilter from '@classes/StreamEvents/EventsFilter';
import { useLocation } from 'react-router-dom';
import ZonesLoader, { IZonesLoaderHandles } from '@components/timeline/ZonesLoader';
import API from '@API/index';
import { useSnackbar } from 'notistack';
import SaveBar from '@components/common/SaveBar';
import ScheduleTemplatesLoader from '@components/timeline/ScheduleTemplatesLoader';
import { setScheduleTemplates } from '@store/scheduleTemplates/actions';
import StreamFeaturesValidator from '@components/timeline/StreamFeaturesValidator';
import { setStreamList } from '@store/streams/actions';
import useSetActivePage from '@hooks/useSetActivePage';
import StreamUptimeLoader, { IStreamUptimeLoaderHandles } from '@components/timeline/StreamUptimeLoader';
import NonIdealState from '@components/common/NonIdealState/NonIdealState';
import { findIndex, first, isArray } from 'lodash';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';
import { selectIsSuperAdminUser } from '@store/selectors';
import { PTZUptimeConcatenator } from '@classes/StreamUptime';
import EventInformation from '@components/timeline/EventInformation';
import { UserRole } from '@store/users/types';
import PubSubsManager from '@classes/PubSubsManager';

const LoadContainer = styled(Paper)`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const TimelineView: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();

  const views = useSelector((store: IStoreState) => store.grids.grids);
  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);
  const selectedView = useSelector((store: IStoreState) => store.viewsView.selectedView);
  const selectedStream = useSelector((store: IStoreState) => store.selectedStream.stream);
  const credentials = useSelector((store: IStoreState) => store.session.credentials);
  const isLoadingBasicData = useSelector((store: IStoreState) => store.appView.isLoadingBasicData);
  const isFetchingGrids = useSelector((store: IStoreState) => store.gridView.isFetchingGrids);
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const isSuperAdministrator = useSelector(selectIsSuperAdminUser);
  const isAdministrator = useSelector((store: IStoreState) => store.session.userData?.roles === UserRole.Admin) || isSuperAdministrator;

  const videoPlayerRef = React.useRef<IVideoPlayerHandles | null>(null);
  const timelineRef = React.useRef<IVideoTimelineHandles | null>(null);
  const streamUptimeLoaderRef = React.useRef<IStreamUptimeLoaderHandles | null>(null);

  const [eventToShowInformation, setEventToShowInformation] = React.useState<VideoStreamEvent | undefined>(undefined);
  const [eventInformationDialogOpen, setEventInformationDialogOpen] = React.useState<boolean>(false);

  const [streamValidationCode, setStreamValidationCode] = React.useState<200 | 403 | 404 | undefined>(undefined);
  const [streamEvents, setStreamEvents] = React.useState<VideoStreamEvent[]>([]);
  const [streamUptime, setStreamUptime] = React.useState<VideoStreamTimeChunk[]>([]);
  const [eventsFilter, setEventsFilter] = React.useState<EventsFilter | undefined>(undefined);
  const [timelineTimeRange, setTimelineTimeRange] = React.useState<{ start: Date, end: Date }>({
    start: new Date(Date.now() - (3600000 * 24)),
    end: new Date(Date.now())
  })
  const [areaSelection, setAreaSelection] = React.useState<Rectangle | undefined>(undefined);
  const [isLoadingEvents, setLoadingEvents] = React.useState<boolean>(false);
  const [areaSearchActive, setAreaSearchActive] = React.useState<boolean>(false);
  const [initialTimestamp, setInitialTimestamp] = React.useState<number | undefined>(undefined);
  const [downloadingFootage, setDownloadingFootage] = React.useState<boolean>(false);
  const [timelineRetention, setTimelineRetentation] = React.useState<number | undefined>(undefined);
  const [strobes, setStrobes] = React.useState<Strobe[]>([]);

  // Tabs related states
  const [activeTab, setActiveTab] = React.useState<CommandPanelTabs>(CommandPanelTabs.None);

  // Zones related states
  const [isLoadingZones, setLoadingZones] = React.useState<boolean>(false);
  const [zones, setZones] = React.useState<Zone[]>([]);
  const [filteredZones, setFilteredZones] = React.useState<Zone[]>([]);
  const [focusedZone, setFocusedZone] = React.useState<Zone | undefined>(undefined);

  // Refs
  const zoneLoaderRef = React.useRef<IZonesLoaderHandles | null>(null);
  const streamEventsRef = React.useRef<VideoStreamEvent[]>([]);
  const eventsFilterRef = React.useRef(eventsFilter);
  const selectedStreamRef = React.useRef(selectedStream);
  const streamUptimeRef = React.useRef<VideoStreamTimeChunk[]>(streamUptime);
  const timelineTimestampRef = React.useRef<number>(Date.now());

  // Features
  const [features, setFeatures] = React.useState<StreamFeatures | undefined>(undefined)

  const onMount = React.useCallback(() => {
    const t = new URLSearchParams(location.search).get('t');
    if (t) {
      setInitialTimestamp(+t);
    }

    dispatch(setPubSubSubscriptionFunction(handleWebsocketEvent))
    dispatch(setPubSubPtzSubscriptionFunction(handlePtzWebsocketEvent));
  }, [dispatch, location.search])

  const onUnmount = React.useCallback(() => {
    return () => {
      dispatch(setPlayerType("KVS"));
      dispatch(setPubSubSubscriptionFunction(undefined))
      dispatch(setPubSubPtzSubscriptionFunction(undefined));
    }
  }, [dispatch])

  React.useEffect(() => {
    if (isMobile === true || isSmallScreen === true) {
      setTimelineTimeRange({
        start: new Date(Date.now() - (3600000 * 0.25)),
        end: new Date(Date.now())
      })
    }
  }, [isMobile, isSmallScreen])

  useSetActivePage(PageView.Player);
  React.useEffect(() => { streamUptimeRef.current = streamUptime }, [streamUptime])
  React.useEffect(() => { selectedStreamRef.current = selectedStream }, [selectedStream])
  React.useEffect(() => { eventsFilterRef.current = eventsFilter }, [eventsFilter]);
  React.useEffect(onMount, [onMount]);
  React.useEffect(onUnmount, [onUnmount]);
  React.useEffect(() => { streamEventsRef.current = streamEvents }, [streamEvents]);
  React.useEffect(() => {
    if (activeTab === CommandPanelTabs.Zones) zoneLoaderRef.current?.refresh();
  }, [activeTab])
  React.useEffect(() => {
    if (selectedStream && selectedStream.retentionPeriodInHours) {
      setTimelineRetentation(Utils.Stream.calculateRetentionTimestamp(selectedStream, Date.now()));
    }
  }, [selectedStream])

  React.useEffect(() => {
    if (selectedStream?.kvsName && isAdministrator)
      API.Strobes.listStrobes(selectedStream?.kvsName).then(setStrobes).catch(e => enqueueSnackbar(Utils.Error.getErrorMessage(e), { variant: "error" }))
  }, [selectedStream?.kvsName, enqueueSnackbar, isAdministrator])

  React.useEffect(() => {
    strobes.forEach(s => PubSubsManager.instance.subscribeToStrobe(s.endpointURI,
      () => enqueueSnackbar("The strobe trigger was successful!", { variant: 'success' })));

    return () => {
      strobes.forEach(s => PubSubsManager.instance.unsubscribeToStrobes(s.endpointURI));
    }
  }, [strobes, enqueueSnackbar])

  const handleActivateStrobes = React.useCallback(async () => {
    try {
      if (selectedStream?.kvsName) {
        await API.Strobes.controleStrobe(selectedStream?.kvsName, strobes[0]);
        enqueueSnackbar("Signal sent to device.");
      }
    } catch (e) {
      enqueueSnackbar("The strobe trigger failed", { variant: 'error' })
    }
  }, [selectedStream?.kvsName, strobes, enqueueSnackbar])


  function handlePtzWebsocketEvent(data: PTZEvent | PTZEvent[]) {
    try {
      const message = isArray(data) ? first(data) : data;

      if (message && selectedStreamRef.current && streamUptimeLoaderRef.current) {
        if (message.src === selectedStreamRef.current.kvsName) {
          setStreamUptime(new PTZUptimeConcatenator([]).pushPtzEvent(streamUptimeRef.current, message))
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

  function handleWebsocketEvent(data: VideoStreamEvent[]): void {
    if (data[0]) {
      let events: VideoStreamEvent[] = [data[0]];

      if (eventsFilterRef.current) {
        events = eventsFilterRef.current.getFilteredEvents([data[0]], false);
      }

      if (events[0]) {
        const filtered = streamEventsRef.current.filter(el => el.id !== data[0].id);
        if (!events[0].endDate && events[0].currentEnd) events[0].endDate = events[0].currentEnd;
        streamEventsRef.current = [events[0], ...filtered];
        setStreamEvents([events[0], ...filtered]);
      }
    }
  }

  function handleMarkAsReadEvent(data: VideoStreamEvent): void {
    const newArray = Array.from(streamEventsRef.current);
    const target = newArray.find(el => el.startDate === data.startDate && el.endDate === data.endDate && el.eventType === data.eventType);
    if (target) {
      newArray[newArray.indexOf(target)].new = false;
      setStreamEvents(newArray);
    }
  }

  function handleInformationClick(data: VideoStreamEvent): void {
    try {
      if (data) setEventToShowInformation(data)
      setEventInformationDialogOpen(true);
    } catch (error) { }
  }

  function handleApplyEventsFilter(filter?: EventsFilter): void {
    setEventsFilter(filter);
  }

  function handleCreateNewView(): void {
    dispatch(setCreatingView(true));
    dispatch(setActivePage(PageView.Streams));
  }

  function handleSelectView(view?: UserView): void {
    dispatch(setActivePage(PageView.Streams));
    dispatch(setSelectedView(view));
  }

  function handleTimelineClick(date: Date): void {
    if (isTimelikeClickOutsideOfRetention(date)) return;

    if (videoPlayerRef.current) {
      videoPlayerRef.current.goToTime(date.getTime())
    }
  }

  function isTimelikeClickOutsideOfRetention(date: Date): boolean {
    return Boolean(timelineRetention && date.getTime() <= timelineRetention)
  }

  function handleVideoTimestampChange(timestamp: number) {
    if (timelineRef.current) {
      timelineTimestampRef.current = timestamp;
      timelineRef.current.updateTime(timestamp);
    }
  }

  function handleAnimationRequest(timestamp: number) {
    if (timelineRef.current) {
      timelineRef.current.animateTo(timestamp);
    }
  }

  function handleTimeChange(start?: Date, end?: Date): void {
    if (start && end) setTimelineTimeRange({ start, end });
  }

  function handleEventClick(event: VideoStreamEvent, animateTimeline?: boolean): void {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.goToTime(event.startDate);
      if (animateTimeline === true && timelineRef.current) timelineRef.current.animateTo(event.startDate);
    }
  }

  function handleAreaSelectionChange(params?: Rectangle): void { setAreaSelection(params); }
  function handleDownloadClick(): void { if (downloadingFootage === false) setDownloadingFootage(true); }

  async function handleDeactivateZone(zone: Zone, callback?: () => void) {
    try {
      await API.Geofences.deactivateZone(selectedStream?.kvsName || "", zone.id);
      await zoneLoaderRef.current?.refresh();
      enqueueSnackbar("Zone deactivated!", { variant: "success" });
      if (callback) callback();
    } catch (error) {
      showErrorSnackbar(error);
      if (callback) callback();
    }
  }

  async function handleActivateZone(zone: Zone, callback?: () => void) {
    try {
      await API.Geofences.activateZone(selectedStream?.kvsName || "", zone.id);
      await zoneLoaderRef.current?.refresh();
      enqueueSnackbar("Zone activated!", { variant: "success" });
      if (callback) callback();
    } catch (error) {
      showErrorSnackbar(error);
      if (callback) callback();
    }
  }

  async function handleArchiveZone(zone: Zone, callback?: () => void) {
    try {
      await API.Geofences.deleteZone(selectedStream?.kvsName || "", zone.id);
      await zoneLoaderRef.current?.refresh();
      enqueueSnackbar("Zone deleted!", { variant: "success" });
      if (callback) callback();
    } catch (error) {
      showErrorSnackbar(error);
      if (callback) callback();
    }
  }

  async function handleSaveZone(callback?: () => void) {
    if (focusedZone) {
      try {
        setLoadingZones(true);
        if (isCreatingNewZone(focusedZone)) await API.Geofences.createZone(selectedStream?.kvsName || "", focusedZone);
        else await API.Geofences.updateZone(selectedStream?.kvsName || "", focusedZone);

        await zoneLoaderRef.current?.refresh();
        setFocusedZone(undefined);
        enqueueSnackbar("Zone saved!", { variant: "success" });
        if (callback) callback();
      } catch (error) {
        showErrorSnackbar(error);
        if (callback) callback();
      } finally {
        setLoadingZones(false);
      }
    }
  }

  function isZoneDirty(): boolean {
    if (!focusedZone) return false;

    if (isCreatingNewZone(focusedZone) && isZoneValidToSave(focusedZone)) return true;
    const target = findZoneById(focusedZone, zones);

    if (target) return areZoneDifferent(target, focusedZone);

    return false;
  }

  function isCreatingNewZone(zone: Zone): boolean {
    return Boolean(zone.id === "");
  }

  function isZoneValidToSave(zone: Zone): boolean {
    return Boolean(
      zone.coordinates.length > 2 &&
      (zone.schedule || zone.activeUntil) &&
      !zones.some(z => z.name === zone.name)
    );
  }

  function findZoneById(zone: Zone, zones: Zone[]): Zone | undefined {
    return zones.find(el => el.id === zone.id);
  }

  function areZoneDifferent(zoneA: Zone, zoneB: Zone): boolean {
    const targetString = JSON.stringify(zoneA);
    const focusString = JSON.stringify(zoneB);

    return targetString !== focusString;
  }

  function handleStreamEdit() {
    dispatch(setActivePage(PageView.Camera));
  }

  async function handleStreamStateChange(newState: "ARCHIVED" | "ACTIVE", fallbackState: "ARCHIVED" | "ACTIVE") {
    if (selectedStream) {
      try {
        dispatch(setActiveStream({ ...selectedStream, state: newState }));
        dispatch(setStreamList(updateStreamList(streams, selectedStream, newState)));
        await API.Streams.archiveStream(selectedStream.siteId, selectedStream.kvsName, newState);
        enqueueSnackbar("Stream state changed!", { variant: 'success' })
      } catch (error) {
        showErrorSnackbar(error);
        dispatch(setStreamList(updateStreamList(streams, selectedStream, fallbackState)));
      }
    }
  }

  function updateStreamList(streams: VideoStream[], selectedStream: VideoStream, state: "ARCHIVED" | "ACTIVE"): VideoStream[] {
    return streams.map(el => {
      if (el.kvsName === selectedStream.kvsName) {
        return {
          ...selectedStream,
          state: state
        }
      } else {
        return el;
      }
    });
  }

  function handleMouseEnterEvent(event: VideoStreamEvent) {
    if (timelineRef.current) { timelineRef.current.markEventTime(event); }

    if (videoPlayerRef.current) {
      const target = zones.find(el => event.zoneId?.includes(el.id));
      if (target) {
        videoPlayerRef.current.setHoveredZone(target);
      }
    }
  }

  function handleMouseLeaveEvent() {
    if (timelineRef.current) { timelineRef.current.clearEventTime(); }
    if (videoPlayerRef.current) videoPlayerRef.current.clearHoveredZone();
  }

  function showErrorSnackbar(error: any): void {
    enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' })
  }

  function shouldLoadEvents(): boolean {
    if (features) {
      if (features.events.motion.read) return true;
      if (features.events.zones.read) return true;
      if (features.events.safetyInfraction.read) return true;
      if (features.events.person.read) return true;
      if (features.events.vehicle.read) return true;
      // legacy
      if (features.events.socialDistance.read) return true;
      if (features.events.vehicleLicensePlate.read) return true;
      if (features.events.fire.read) return true;
    }

    return false;
  }

  function handleNextTimeRange(): void {
    const newTimerange = Utils.Timestamp.nextTimeRange(timelineTimeRange.start.getTime(), timelineTimeRange.end.getTime());
    setTimelineTimeRange({ start: new Date(newTimerange.start), end: new Date(newTimerange.end) });
  }

  function handlePreviousTimeRange(): void {
    const newTimerange = Utils.Timestamp.previousTimeRange(timelineTimeRange.start.getTime(), timelineTimeRange.end.getTime());
    setTimelineTimeRange({ start: new Date(newTimerange.start), end: new Date(newTimerange.end) });
  }

  function handleNextStreamClick(): void {
    if (selectedStream) {
      const siteStreams = getSelectedSiteStreams()
      if (siteStreams) {
        const index = findIndex(siteStreams, { kvsName: selectedStream.kvsName });
        if (index >= siteStreams.length - 1) {
          setSelectedStream(siteStreams[0]);
        } else {
          setSelectedStream(siteStreams[index + 1]);
        }
      }
    }
  }

  function handlePreviousStreamClick(): void {
    if (selectedStream) {
      const siteStreams = getSelectedSiteStreams()
      if (siteStreams) {
        const index = findIndex(siteStreams, { kvsName: selectedStream.kvsName });
        if (index <= 0) {
          setSelectedStream(siteStreams[siteStreams.length - 1])
        } else {
          setSelectedStream(siteStreams[index - 1])
        }
      }
    }
  }

  function setSelectedStream(stream: VideoStream) {
    dispatch(setActiveStream(stream))
    setStreamEvents([]);
    setStreamUptime([]);
    setFeatures(undefined);
  }

  function getSelectedSiteStreams(): VideoStream[] {
    return Utils.Array.orderAlphabetically(streams, 'defaultName').filter(el => el.siteId === selectedSite?.siteId && el.state === selectedStream?.state);
  }

  if (isLoadingBasicData === true || isFetchingGrids === true) {
    return (
      <LoadContainer>
        <CircularProgress />
      </LoadContainer>
    )
  }

  if (streamValidationCode === undefined) {
    return (
      <LoadContainer>
        <StreamValidator onValidationCompleted={(code: 200 | 403 | 404) => setStreamValidationCode(code)} />
        <CircularProgress />
      </LoadContainer>
    )
  }

  if (streamValidationCode === 404) {
    return (
      <NonIdealState
        title="Not found"
        description="Stream was not found, please check your information."
        errorCode={404}
      />
    )
  }

  if (streamValidationCode === 403) {
    return (
      <NonIdealState
        title="Access denied"
        description="You do not have access to this stream, please contact an Administrator."
        errorCode={403}
      />
    )
  }

  // This is just a precaution to avoid crashing errors for lack of selected stream.
  // The validation before this should take care of all cases.
  if (!selectedStream) {
    return (
      <NonIdealState
        title="Something went wrong."
        description="We ran into an issue finding the Stream you are looking for, please try again."
        icon="error"
      />
    )
  }

  if (features && features.stream.view === false) {
    return (
      <NonIdealState
        title="Access denied"
        description="You do not have access to this stream, please contact an Administrator."
        errorCode={403}
      />
    )
  }

  return (
    <Box display="grid" gridTemplateRows="min-content auto" width="100%" height="100%">
      {selectedStream && <Helmet title={`Forsight | ${Utils.Stream.getStreamName(selectedStream)}`} />}
      {selectedStream &&
        <>
          <StreamUptimeLoader
            ref={streamUptimeLoaderRef}
            stream={selectedStream}
            credentials={credentials}
            onLoadedStreamUptime={(uptime) => { setStreamUptime(uptime); }}
            onError={(error) => { showErrorSnackbar(error) }}
            uptime={streamUptime}
          />
          <ScheduleTemplatesLoader
            stream={selectedStream}
            onLoaded={(data) => {
              dispatch(setScheduleTemplates(data));
            }}
          />
          {shouldLoadEvents() &&
            <EventsLoader
              stream={selectedStream}
              range={timelineTimeRange}
              onLoadedData={(data) => { setStreamEvents(data); setLoadingEvents(false); }}
              areaSelection={areaSelection}
              onLoadStart={() => setLoadingEvents(true)}
              eventsFilter={eventsFilter}
              onError={(error) => {
                setStreamEvents([]);
                showErrorSnackbar(error);
                setLoadingEvents(false);
              }}
            />
          }
          {features?.zones.read &&
            <ZonesLoader
              ref={zoneLoaderRef}
              stream={selectedStream}
              onLoadStart={() => { setLoadingZones(true); }}
              onLoadDone={(zones: Zone[]) => { setZones(zones); setLoadingZones(false); }}
              onError={(error) => { showErrorSnackbar(error); setZones([]); setLoadingZones(false); }}
            />
          }
        </>
      }
      <ViewsNavBar
        onCreateViewClick={handleCreateNewView}
        onViewSelected={handleSelectView}
        siteViews={Utils.Views.extractViewFromGrids(views, selectedSite)}
        selectedSite={selectedSite}
        selectedView={selectedView}
        disableHighlight={true}
      />
      <Paper elevation={0}>
        <Box
          width="100%"
          height="100%"
          display="grid"
          gridTemplateRows={isMobile || isSmallScreen ? "auto min-content" : undefined}
          gridTemplateColumns={isMobile || isSmallScreen ? undefined : 'auto min-content'}
        >
          <Box
            height="100%"
            display="grid"
            gridTemplateRows="auto min-content"
            borderRight="1px solid rgba(0, 0, 0, 0.12)"
          >
            <VideoPlayer
              uptime={streamUptime}
              features={features}
              ref={videoPlayerRef}
              stream={selectedStream}
              credentials={credentials}
              autoplay={true}
              timezone={selectedSite?.timezone}
              onVideoTimestampChange={handleVideoTimestampChange}
              events={streamEvents}
              onTimelineAnimation={handleAnimationRequest}
              areaSelectorActive={areaSearchActive}
              onAreaSelectionChange={handleAreaSelectionChange}
              onActivateStrobes={strobes.length ? handleActivateStrobes : undefined}
              areaSelection={areaSelection}
              initialTimestamp={initialTimestamp}
              downloadingFootage={downloadingFootage}
              onDownloadFootage={handleDownloadClick}
              timelineTimeRange={timelineTimeRange}
              onEditStream={handleStreamEdit}
              details={!isSuperAdministrator}
              onArchiveStream={isSuperAdministrator && selectedStream.state === "ACTIVE" ? () => handleStreamStateChange("ARCHIVED", "ACTIVE") : undefined}
              onActivateStream={isSuperAdministrator && selectedStream.state === "ARCHIVED" ? () => handleStreamStateChange("ACTIVE", "ARCHIVED") : undefined}
              onNextStreamClick={handleNextStreamClick}
              onPrevioustStreamClick={handlePreviousStreamClick}
              disableNextStream={getSelectedSiteStreams().length < 2}
              disablePreviousStream={getSelectedSiteStreams().length < 2}
              zonesFeature={{
                active: activeTab === CommandPanelTabs.Zones,
                zones: filteredZones,
                focusedZone: focusedZone,
                onZonePointsChange: (zone: Zone) => setFocusedZone(zone),
                kvsName: selectedStream.kvsName
              }}
            />
            {selectedSite ? (
              <VideoTimeline
                ref={timelineRef}
                onClick={handleTimelineClick}
                timeEvents={streamUptime}
                timezone={selectedSite?.timezone}
                events={streamEvents}
                onTimeRangeChange={handleTimeChange}
                timeRange={timelineTimeRange}
                timerangeBackground={true}
                features={features}
                min={timelineRetention}
              />
            ) : null}
          </Box>
          <Box height="100%">
            <CommandPanel
              onTabChange={(tab: CommandPanelTabs) => setActiveTab(tab)}
              features={features}
              eventsTabProps={{
                timeRange: timelineTimeRange,
                selectedSite: selectedSite,
                onTimeRangeChange: handleTimeChange,
                events: streamEvents,
                onApplyFilters: handleApplyEventsFilter,
                eventsFilter: eventsFilter,
                onEventClick: handleEventClick,
                isLoadingEvents: isLoadingEvents,
                areaSearchActive: areaSearchActive,
                onAreaSearchClick: () => { setAreaSearchActive(!areaSearchActive) },
                areaSelection: areaSelection,
                onRemoveAreaSearch: () => setAreaSelection(undefined),
                stream: selectedStream,
                features: features,
                timezone: Utils.Site.getTimezone(selectedSite),
                onMouseEnterEvent: handleMouseEnterEvent,
                onMouseLeaveEvent: handleMouseLeaveEvent,
                onNextTimeRange: handleNextTimeRange,
                onPreviousTimeRange: handlePreviousTimeRange,
                zones: zones,
                onMarkAsReadClick: handleMarkAsReadEvent,
                onInformationClick: handleInformationClick
              }}
              zoneTabProps={{
                zones: zones,
                isLoadingZones: isLoadingZones,
                onFilteredZonesChange: (zones: Zone[]) => setFilteredZones(zones),
                onSelectedZoneChange: (zone?: Zone) => {
                  // TODO: can be replaced for "structuredClone" when node is updated
                  if (zone) setFocusedZone(JSON.parse(JSON.stringify(zone)))
                  else setFocusedZone(undefined)
                },
                focusedZone: focusedZone,
                onDeactivateZone: handleDeactivateZone,
                onActivateZone: handleActivateZone,
                onArchiveZone: handleArchiveZone,
                features: features
              }}
              exportsTabProps={{
                selectedSite: selectedSite,
                stream: selectedStream
              }}
            />
          </Box>
        </Box>
      </Paper>
      <SaveBar
        open={isZoneDirty()}
        onDiscardChanges={() => setFocusedZone(undefined)}
        onSaveChanges={handleSaveZone}
      />
      <StreamFeaturesValidator
        stream={selectedStream}
        onStreamFeaturesGenerated={(features: StreamFeatures) => setFeatures(features)}
        onError={() => {
          setStreamValidationCode(403);
        }}
      />
      <Dialog
        open={eventInformationDialogOpen}
        onClose={() => setEventInformationDialogOpen(false)}
        onAnimationEnd={(event) => { if (event.type === "animationend") setEventToShowInformation(undefined); }}
      >
        <EventInformation onClose={() => setEventInformationDialogOpen(false)} event={eventToShowInformation} />
      </Dialog>
    </Box >
  )
}
