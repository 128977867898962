import decodeUrl, { IS3Image } from './decodeUrl';
import getImageUrl from './getImageUrl';
import getKey from './getKey';
export interface IS3 {
  getImageUrl: (key: string, target: object, bucket?: string) => Promise<{ url: string }>;
  decodeUrl: (item: IS3Image) => Promise<IS3Image>;
  getKey(location: string): string;
}

const S3: IS3 = {
  getImageUrl,
  decodeUrl,
  getKey
}

export default S3;
