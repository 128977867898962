export function createSitesFilters(isSuperAdministrator: boolean, sites: Site[]): FilterProperties[] {
  return [
    { label: 'Name', property: 'name', type: 'string' },
    { label: 'Timezone', property: 'timezone', type: 'multi_select', options: getSiteTimezoneOptions(sites) },
    { label: 'Tags', property: 'tags', type: 'multi_select', options: getTagsOptions(sites) },
    { label: 'Status', property: 'status', type: 'select', options: ['Active', 'Comming soon', 'Maintenance'] },
    { label: 'Created at', property: 'createdAt', type: 'date' },
    { label: 'Updated at', property: 'updatedAt', type: 'date' }
  ];
}

function getSiteTimezoneOptions(sites: Site[]): string[] {
  const set: Set<string> = new Set<string>();

  sites.forEach((site) => {
    if (site.timezone) set.add(site.timezone)
  })

  return Array.from(set);
}


function getTagsOptions(sites: Site[]): string[] {
  const set: Set<string> = new Set<string>();

  sites.forEach((site) => {
    if (site.tags) {
      site.tags.forEach(tag => set.add(tag))
    }
  })

  return Array.from(set);
}