import download from "./download";

export interface ICSV {
  download: (rows: string[][], fileName: string) => void;
}

const Browser: ICSV = {
  download,
}

export default Browser;
