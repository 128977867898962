import findIPAddress from './findIPAddress';
import findTimestamp from './findTimestamp';
import containsNonEmailCharacters from './containsNonEmailCharacters';
import isEmailValid from './isEmailValid';

export interface IRegEx {
  findTimestamp: (value: string) => number;
  /**
   * Find the IP address within a String.
   * @param value String to extract the IP from.
   * @returns String with an IP or an empty String if not found. 
   */
  findIPAddress(rtspUrl: string): string;
  containsNonEmailCharacters(email: string): boolean;
  isEmailValid(email: string): boolean;
}

const RegEx: IRegEx = {
  findTimestamp,
  findIPAddress,
  containsNonEmailCharacters,
  isEmailValid
}

export default RegEx;
