import React from 'react';
import styled from 'styled-components';
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Header from '@components/common/Header/Header';
import DateRangeInput from '@components/common/Buttons/DateRangeInput';
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import CustomGridTreeDataGroupingHeader from './CustomGridTreeDataGroupingHeader';
import CustomGridTreeDataGroupingCell from './CustomGridTreeDataGroupingCell';
import { EventsReportData } from '@classes/cubejs/EventsReportConverter';
import Utils from '@utils/index';
import MultiSelectInput from '@components/common/Input/MultiSelectInput';
import CustomDataGrid from '@components/common/DataGrid/CustomDataGrid';
import TutorialHeaderTitle from '@components/common/TutorialHeaderTitle';

const EventsHiddenContainer = styled.div`
  opacity:0;
  filter: alpha(opacity = 0);
  position:absolute;
  top:0; bottom:0; left:0; right:0;
  display:block;
  z-index:2;
  background:transparent;
`

type OverviewReportsContentProps = {
  isMovingOrResizing: boolean | undefined;
  handleDateChange: (start?: Date, end?: Date) => void;
  dateRange: { start: Date; end: Date; };
  optionsWidth: string;
  selectedTags: string[];
  handleSelectedTagsChange: (e: string[]) => void;
  selectedSites: string[];
  handleSitesSelectChange: (e: string[]) => void;
  filteredStreamOptions: {
    sectionLabel: string;
    options: Array<{
      id: string;
      label: string;
    }>;
  }[],
  selectedStreams: string[];
  handleSelectedStreamsChange: (values: string[]) => void;
  loading: boolean;
  data: undefined | EventsReportData;
  getSiteName: (siteId: string) => string | undefined;
  tagOptions: string[];
  siteOptions: string[];
  companyId?: string;
}

const OverviewReportsContent: React.FC<OverviewReportsContentProps> = (props) => {
  const {
    isMovingOrResizing,
    handleDateChange,
    dateRange,
    optionsWidth,
    selectedTags,
    handleSelectedTagsChange,
    selectedSites,
    handleSitesSelectChange,
    filteredStreamOptions,
    selectedStreams,
    handleSelectedStreamsChange,
    loading,
    data,
    getSiteName,
    tagOptions,
    siteOptions,
    companyId
  } = props;

  const isSmallDesktop = useMediaQuery('(max-width: 1800px)');

  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);

  const renderCell = React.useCallback((params: any) => {
    return (
      <Box width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="row">
        <Typography style={{ fontWeight: params.row.type === "COMPANY" ? 550 : undefined }} >{params.value}</Typography>
      </Box>
    );
  }, [])

  const gridColDef = React.useMemo(() => {
    const baseDefinition = {
      renderCell,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: rootFontSize * 5
    }

    const base = [
      {
        ...baseDefinition,
        field: 'true_positive_person',
        headerName: 'Person',
      },
      {
        ...baseDefinition,
        field: 'true_positive_vehicle',
        headerName: 'Vehicle',
      },
      {
        ...baseDefinition,
        field: 'false_positive_animal',
        headerName: 'Animal',
      },
      {
        ...baseDefinition,
        field: 'false_positive_light',
        headerName: 'Light',
      },
      {
        ...baseDefinition,
        field: 'false_positive_weather',
        headerName: 'Weather',
        width: rootFontSize * 6
      },
      {
        ...baseDefinition,
        field: 'false_positive_unknown',
        headerName: 'Unknown',
        width: rootFontSize * 6
      },
      {
        ...baseDefinition,
        field: 'false_positive_other',
        headerName: 'Other',
      },
      {
        ...baseDefinition,
        field: 'escalate_contact_police',
        headerName: 'Contact Police',
        width: rootFontSize * 8
      },
      {
        ...baseDefinition,
        field: 'escalate_contact_manager',
        headerName: 'Contact Manager',
        width: rootFontSize * 10
      },
      {
        ...baseDefinition,
        field: 'escalate_fire',
        headerName: 'Fire',
        width: rootFontSize * 4
      },
      {
        ...baseDefinition,
        field: 'escalate_damage',
        headerName: 'Damage',
        width: rootFontSize * 6
      },
      {
        ...baseDefinition,
        field: 'escalate_open_door_gate',
        headerName: 'Open Door/Gate',
        width: rootFontSize * 9
      },
      {
        ...baseDefinition,
        field: 'escalate_check_subject',
        headerName: 'Check Subject',
        width: rootFontSize * 9
      },
      {
        ...baseDefinition,
        field: 'escalate_facility_issue',
        headerName: 'Facility Issue',
      }
    ] as GridColDef[]

    return base.filter(el => selectedTags.includes(el.field))
  }, [renderCell, rootFontSize, selectedTags])

  const rows = React.useMemo(() => {
    if (data) {
      return data.filter((row) => {
        if (row.type === "COMPANY") {
          return row.id === companyId
        }
        if (row.type === "SITE") {
          return selectedSites.includes(row.siteId)
        }

        if (row.type === "CAMERA") {
          return selectedStreams.includes(row.kvsName);
        }

        return true;
      })
    }

    return []
  }, [companyId, data, selectedSites, selectedStreams])

  function extractStreamOptions(options: {
    sectionLabel: string;
    options: Array<{
      id: string;
      label: string;
    }>;
  }[]): Array<{ value: string, label: string }> {
    const opt: Array<{ value: string, label: string }> = [];
    options.forEach((el) => {
      el.options.forEach((s) => {
        opt.push({ value: s.id, label: s.label })
      })
    })

    return opt;
  }

  function getSections(options: {
    sectionLabel: string;
    options: Array<{
      id: string;
      label: string;
    }>;
  }[]): Map<string, string[]> {
    const map: Map<string, string[]> = new Map();

    options.forEach(option => {
      map.set(option.sectionLabel, option.options.map(el => el.id));
    })

    return map;
  }

  return (
    <Box display="grid" gridTemplateRows="min-content auto" width="100%" height="100%" padding="1.5rem" gap="2rem">
      {isMovingOrResizing && <EventsHiddenContainer />}
      <Header
        title={
          <TutorialHeaderTitle
            title="Events Report"
            tutorialType='events_reports'
          />
        }
        large
      >
        <DateRangeInput
          onDateChange={handleDateChange}
          startDate={dateRange.start}
          endDate={dateRange.end}
          disabled={loading}
          disableMargin
        />
        <FormControl style={{ width: optionsWidth }}>
          <InputLabel id="tag-multiple-checkbox-label">Tags</InputLabel>
          <MultiSelectInput
            onChange={handleSelectedTagsChange}
            options={tagOptions.map(el => ({ value: el, label: Utils.StreamEvent.getTagText(el) }))}
            allSelected
            disableWidth
            disabled={loading}
            value={selectedTags}
            selectAllOption={
              { label: 'Select all' }
            }
            searchable
            rootFontSize={rootFontSize}
          />
        </FormControl>

        <FormControl style={{ width: optionsWidth }}>
          <InputLabel id="sites-multiple-checkbox-label">Sites</InputLabel>
          <MultiSelectInput
            onChange={handleSitesSelectChange}
            options={siteOptions.map(s => ({ value: s, label: getSiteName(s) || "" }))}
            disableWidth
            disabled={loading}
            value={selectedSites}
            selectAllOption={
              { label: 'Select all' }
            }
            searchable
            rootFontSize={rootFontSize}
          />
        </FormControl>
        <FormControl style={{ width: optionsWidth }}>
          <InputLabel id="cameras-multiple-checkbox-label">Cameras</InputLabel>
          <MultiSelectInput
            onChange={handleSelectedStreamsChange}
            options={extractStreamOptions(filteredStreamOptions)}
            disableWidth
            disabled={loading}
            value={selectedStreams}
            selectAllOption={
              { label: 'Select all' }
            }
            searchable
            sections={getSections(filteredStreamOptions)}
            rootFontSize={rootFontSize}
          />
        </FormControl>
      </Header>
      <CustomDataGrid
        defaultHeight='82vh'
        treeData
        getTreeDataPath={(row) => {
          return row.path;
        }}
        rows={rows}
        columns={gridColDef}
        loading={loading}
        hideFooterRowCount
        hideFooterSelectedRowCount
        groupingColDef={{
          renderHeader: () => <CustomGridTreeDataGroupingHeader />,
          renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
          width: rootFontSize * 25
        }}
        getRowClassName={(params: any) => {
          if (params.row.type === "CAMERA") return 'MuiDataGrid-darker-row'
          return ''
        }}
        disableColumnMenu={true}
        sx={{ '--DataGrid-overlayHeight': '300px' }}
        pinnedColumns={{
          left: isSmallDesktop ? ['__tree_data_group__'] : []
        }}
        hideHeaders={loading || data?.length === 0}
        dataCy="events-reports-data-grid"
      />
    </Box>
  )
}

export default OverviewReportsContent;
