function extractViewFromGrids(grids: { [key: string]: UserView[] }, selectedSite?: Site): UserView[] {
  if (selectedSite) {
    const companyViews = grids[selectedSite.companyId];
    if (companyViews) {
      const siteViews = companyViews.filter(el => el.siteId === selectedSite.siteId);
      siteViews.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0
      });
      return siteViews;
    }
  }

  return []
}

export default extractViewFromGrids;
