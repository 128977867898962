import Utils from "@utils/index";
import * as actions from './actions';
import { IScheduleTemplatesState, ActionTypes } from "./types";
type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IScheduleTemplatesState = {
  scheduleTemplates: []
}

export const scheduleTemplatesReducer = Utils.Redux.createReducer<
  IScheduleTemplatesState,
  Types,
  Actions
>(initialState, {

  [ActionTypes.SET_SCHEDULE_TEMPLATES]: (state, value) => ({
    ...state,
    scheduleTemplates: value.scheduleTemplates
  })
})