import React from 'react';
import { Box, Button, Popover, PopoverProps, Typography } from '@mui/material';
import styled from 'styled-components';
import * as moment from 'moment';
import { DateRangePicker } from '@components/common/Input/DateRangePicker';

const Container = styled(Popover)`
  .MuiPaper-elevation5 {
    box-shadow: none !important;
  }
`

type DateRangePickerPopoverProps = PopoverProps & {
  onCancelClick: () => void;
  onConfirmClick: (start: number, end: number) => void;
  timezone: string;
  start: Date;
  end: Date;
}

const DateRangePickerPopover: React.FC<DateRangePickerPopoverProps> = (props) => {
  const { start, end, onConfirmClick, onCancelClick, timezone, ...popoverProps } = props;

  const [error, setError] = React.useState<boolean>(false);
  const [innerStart, setInnerStart] = React.useState<Date>(start);
  const [innerEnd, setInnerEnd] = React.useState<Date>(end);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  React.useEffect(errorHandler, [innerStart, innerEnd]);

  function handleOkClick(): void {
    const s: number = Date.UTC(innerStart.getFullYear(), innerStart.getMonth(), innerStart.getDate(), 0, 0, 0, 0);
    const e: number = Date.UTC(innerEnd.getFullYear(), innerEnd.getMonth(), innerEnd.getDate(), 23, 59, 59, 999);

    const offset = moment.tz(timezone.split(' ')[0]).utcOffset() * 60000;

    onConfirmClick(s - offset, e - offset);
  }

  function handleCancelClick(): void {
    onCancelClick();
  }

  function errorHandler(): void {
    setError(innerStart.getTime() > innerEnd.getTime());
    if (innerStart.getTime() > innerEnd.getTime()) setErrorMessage("Start cannot be greater than end.")
    else setErrorMessage("");
  }

  function getMaxDate(): Date {
    try {
      return moment.tz(timezone.split(' ')[0] || '').toDate();
    } catch (error) {
      return moment.tz().toDate()
    }
  }

  return (
    <Container keepMounted={false} {...popoverProps} >
      <Box display="grid" gridTemplateRows="min-content min-content min-content min-content">
        <Box display="grid" width="100%" gridTemplateColumns="1fr">
          <DateRangePicker
            open={true}
            toggle={() => { if (popoverProps.onClose) popoverProps.onClose({}, "backdropClick") }}
            initialDateRange={{
              startDate: new Date((start.getTime() - moment.tz(moment.tz.guess()).utcOffset() * 60000)),
              endDate: new Date((end.getTime() - moment.tz(moment.tz.guess()).utcOffset() * 60000))
            }}
            maxDate={getMaxDate()}
            onChange={(range) => {
              if (range.startDate && range.endDate) {
                setInnerStart(range.startDate);
                setInnerEnd(range.endDate);
              }
            }}
          />
        </Box>
        <Box width="100%" display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2" color="error" >{errorMessage}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end" paddingBottom="1rem" paddingRight="1rem">
          <Button color="primary" onClick={handleCancelClick} data-cy="date-range-picker-cancel-button" ><Typography variant="body1">Cancel</Typography></Button>
          <Button disabled={error} color="primary" onClick={handleOkClick} data-cy="date-range-picker-ok-button" ><Typography variant="body1">Ok</Typography></Button>
        </Box>
      </Box>
    </Container>
  )
}

export default DateRangePickerPopover;
