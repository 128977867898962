import Tutorial from "./Tutorial";

class ViewsMapTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '.mapboxgl-canvas',
        popover: {
          title: "Bird's-eye view of the site",
          description: 'Camera locations across the site with their angle of view and depth. By hovering the mouse over the camera a live stream appears.',
          position: 'mid-center'
        }
      }
    ])
  }
}

export default ViewsMapTutorial;
