import { API } from 'aws-amplify';
import qs from 'qs';

async function getWorkers(
    siteId?: string,
    subcontractorId?: string, asCompany?: string
): Promise<CrewWorker[]> {
    try {
        const path = `/workers?${qs.stringify({
            siteId,
            subcontractorId,
            asCompany
        })}`;
        const data = await API.get('api-service', path, {});

        return data.data;
    } catch (error: any) {
        throw error;
    }
}

export default getWorkers;
