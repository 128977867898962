import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Business, ExpandMore } from '@mui/icons-material';
import React from 'react';

interface ISiteSelectionProps {
  open: boolean;
  onCompanyClick: (event: any) => void;
  selectedCompany?: Company;
  isSuperAdministrator?: boolean;
}

const CompanySelection: React.FC<ISiteSelectionProps> = (props) => {
  const { open, onCompanyClick, selectedCompany, isSuperAdministrator } = props;

  const expandMoreRef = React.useRef(null);

  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const [hovered, setHovered] = React.useState<boolean>(false);

  const hoveredRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    hoveredRef.current = hovered;
  }, [hovered]);

  function handleMouseEnter() {
    if (open === false) {
      setHovered(true);

      setTimeout(() => {
        if (hoveredRef.current === true) {
          setTooltipOpen(true);
        }
      }, 200)
    }
  }

  function handleMouseLeave() {
    if (open === false) {
      setHovered(false);
      setTooltipOpen(false);
    }
  }

  function handleClick(event: any): void {
    if (open === false) {
      onCompanyClick({ currentTarget: expandMoreRef.current })
    } else {
      onCompanyClick(event);
    }
  }

  return (
    <ListItem disabled={isSuperAdministrator === false} button onClick={handleClick} data-cy="company-selection-dropdown-button">
      <ListItemIcon data-cy="button-companies" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Tooltip open={tooltipOpen} arrow title={selectedCompany?.name || "Select a Company"} placement="right">
          <span>
            <Business id="company-selection-icon" />
          </span>
        </Tooltip>
        {open === false ? (<ExpandMore ref={expandMoreRef} />) : null}
      </ListItemIcon>
      <Tooltip enterDelay={500} title={selectedCompany?.name || "Select a Company"} >
        <ListItemText primaryTypographyProps={{ variant: 'body1', noWrap: true }} primary={selectedCompany?.name || "Select a Company"} />
      </Tooltip>
      <ExpandMore />
    </ListItem>
  )
}

export default CompanySelection;
