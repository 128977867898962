export enum DbTable {
  Users = "dashboard-users",
  Companies = "dashboard-companies",
  Sites = "dashboard-construction-sites",
  Cameras = "dashboard-streams",
  Views = "dashboard-grids-v2",
  Zones = "dashboard-geofences",
  Actions = "dashboard-actions",
  Roles = "dashboard-roles",
  ScheduleTemplates = "dashboard-schedule-templates",
  Workers = "dashboard-worker",
  Trades = "dashboard-trade",
  Subcontractors = "dashboard-subcontractors",
  Events = "aggregated-events",
  MutedStreams = "dashboard-muted-streams"
}