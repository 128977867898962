import { InputLabel } from "@mui/material"
import styled from "styled-components"

const CustomInputLabel = styled(InputLabel)`
  color: unset;
  font-size: 0.875rem;
  transform: translate(0, -1.5rem);
  font-family: "Roboto";
  font-weight: 700;
  line-height: 1.57;
  letter-spacing: 0.00714em;
`
export default CustomInputLabel