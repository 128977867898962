import { PermissionType } from "@store/session/types";
import listPermissionsForStream from "./listPermissionsForStream";
import { listUsersAndStreamsMapping } from "./listUsersAndStreamsMapping";

export interface IPermissionsAPI {
  listPermissionsForStream(userId: string, kvsName: string): Promise<{ kvsName: string, permissions: PermissionType[] }>;
  listUsersAndStreamsMapping(companyId: string): Promise<Map<string, string[]>>;
}

const Permissions: IPermissionsAPI = {
  listPermissionsForStream,
  listUsersAndStreamsMapping
}

export default Permissions;
