import { EventsFilterParams, TimeFiltering } from "@classes/StreamEvents/EventsFilter";
import StreamEventsMerger from "@classes/StreamEvents/StreamEventsMerger";
import EventConverter from "@classes/StreamEvents/WSEventConverter";
import { API } from "aws-amplify";
import { store } from '../../index';
import { FieldFactory } from '@classes/Filter/FieldFactory';

export type EventLabel = {
  name: string;
  eventDuration?: number,
  comparisonOperator?: ">" | "<" | null
}

export type EventType = {
  type: string;
  eventDuration?: number,
  comparisonOperator?: ">" | "<" | null,
  labels?: Array<EventLabel>,
  ids?: Array<string>
}

export type APIRequestBody = {
  timeRange: {
    from: number,
    to: number
  }
  eventTypes?: Array<EventType>,
  companyId?: string,
  sites?: string[],
  timezone?: "utc" | "local",
  offset?: number,
  areaFilter?: { x: number, y: number, w: number, h: number },
  tags?: string[]
}

export type GetTabEventsOptions = {
  mergeZoneEvents?: boolean;
  mergeSiteEvents?: boolean;
}

export function convertResponse(
  data: APIResponse<GlobalEvent[]>,
  mergeZoneEvents?: boolean,
  mergeSiteEvents?: boolean
): VideoStreamEvent[] {
  if (data.data) {
    return new StreamEventsMerger(data.data.filter(el => (el.e !== undefined && el.s !== undefined) && el.e !== el.s).map(el => (
      new EventConverter().convertFromDynamoDB(el)
    ))).merge(mergeZoneEvents, mergeSiteEvents);
  }

  return [];
}

export function getTimeInSeconds(params: TimeFiltering): number {
  if (params.timeType === "min") {
    return +params.timeValue * 60;
  }

  return +params.timeValue;
}


function getValue(f: FilterToApply) {
  if (f.filter._name.toLowerCase().includes("duration") && f.text)
    return +f.text / 1000;
  return f.text;
}


export function buildRequestBody(
  companyId: string,
  start: number,
  end: number,
  timezone?: 'local' | 'utc',
  companySites?: Site[],
  filters?: EventsFilterParams,
  isSuperAdministrator?: boolean,
  areaFilter?: { x: number, y: number, w: number, h: number }
): APIRequestBody {

  const eventTypeFilters: { type: string, filters?: Map<string, { operator: any, value: string | number | boolean | string[] | undefined }> }[] = [];

  const mergeTagsAndEscalations = (filters: FilterToApply[]) => {
    const escalations = filters.find(f => f.filter.getName() === "escalations")?.text as string[];
    if (escalations?.length) {
      const tagsFilter = filters.find(f => f.filter.getName() === "tags");
      if (tagsFilter) {
        return filters.map(f => f.filter.getName() === "tags" ? ({ ...f, text: [...f.text as string[], ...escalations] }) : f).filter(f => f.filter.getName() !== "escalations");
      } else {
        const missingTagsFilter = FieldFactory.createMultiSelectField("tags");
        missingTagsFilter.setCurrentOperator("is_any_of");
        return [...filters.filter(f => f.filter.getName() !== "escalations"), { filter: missingTagsFilter, text: escalations}]
      }
    }
    return filters;
  }

  if (filters) {
    const { motion, people, safety, vehicles, zones } = filters;

    if (motion.enable) {
      const filters: Map<string, { operator: any, value: string | number | boolean | string[] | undefined }> = new Map();
      const mergedFilters = mergeTagsAndEscalations([...motion.filters]);
      mergedFilters.forEach(f => {
        filters.set(f.filter.getName(), {
          operator: f.filter._currentOperator,
          value: getValue(f)
        })
      })
      
      eventTypeFilters.push({
        type: "Motion",
        filters: filters.keys() ? filters : undefined
      })
    }

    if (people.enable) {
      const filters: Map<string, { operator: any, value: string | number | boolean | string[] | undefined }> = new Map();

      const mergedFilters = mergeTagsAndEscalations([...people.filters]);
      mergedFilters.forEach(f => {
        filters.set(f.filter.getName(), {
          operator: f.filter.getCurrentOperator(),
          value: getValue(f)
        })
      })
      eventTypeFilters.push({
        type: "Person detected",
        filters: filters.keys() ? filters : undefined
      })
    }

    if (safety.enable) {
      const filters: Map<string, { operator: any, value: string | number | boolean | string[] | undefined }> = new Map();
      const mergedFilters = mergeTagsAndEscalations([...safety.filters]);
      mergedFilters.forEach(f => {
        filters.set(f.filter.getName(), {
          operator: f.filter.getCurrentOperator(),
          value: getValue(f)
        })
      })
      eventTypeFilters.push({
        type: "Safety infraction",
        filters: filters.keys() ? filters : undefined
      })
    }

    if (vehicles.enable) {
      const filters: Map<string, { operator: any, value: string | number | boolean | string[] | undefined }> = new Map();
      const mergedFilters = mergeTagsAndEscalations([...vehicles.filters]);
      mergedFilters.forEach(f => {
        filters.set(f.filter.getName().replace("metadata.", ""), {
          operator: f.filter.getCurrentOperator(),
          value: getValue(f)
        })
      })
      eventTypeFilters.push({
        type: "Vehicle detected",
        filters: filters.keys() ? filters : undefined
      })
    }

    if (zones.enable) {
      const filters: Map<string, { operator: any, value: string | number | boolean | string[] | undefined }> = new Map();
      const mergedFilters = mergeTagsAndEscalations([...zones.filters]);
      mergedFilters.forEach(f => {
        const name = f.filter.getName();
        filters.set(name === "zoneId" ? "id" : name, {
          operator: f.filter.getCurrentOperator(),
          value: getValue(f)
        })
      })
      eventTypeFilters.push({
        type: "Geofence triggered",
        filters: filters.keys() ? filters : undefined
      })
    }
  }


  const body = {
    timeRange: {
      from: start,
      to: end
    },
    companyId: isSuperAdministrator !== true ? companyId : undefined,
    eventTypeFilters: eventTypeFilters.length ? eventTypeFilters : undefined,
    sites: companySites?.length !== filters?.sites?.sites.length ? filters?.sites?.sites : undefined,
    timezone,
    areaFilter: areaFilter ? areaFilter : undefined,
  };

  const keys = Object.keys(body);
  //@ts-ignore
  keys.forEach((key) => { if (body[key] === undefined) delete body[key]; })

  return body;
}

async function listEvents(
  companyId: string,
  start: number,
  end: number,
  timezone: 'local' | "utc",
  tab?: EventState,
  filters?: EventsFilterParams,
  options?: GetTabEventsOptions,
  isSuperAdministrator?: boolean,
  lastKey?: number
): Promise<VideoStreamEvent[]> {
  try {
    const allCompanySites = store.getState().sites.sites.get(companyId);

    let path: string = `/global/inbox`;
    if (isSuperAdministrator === true) path = `/global/inbox?companyId=${companyId}`;

    const req = {
      body: {
        ...buildRequestBody(
          companyId,
          start,
          end,
          timezone,
          allCompanySites,
          filters,
          isSuperAdministrator,
        ),
        limit: 200,
        lastKey,
        tab
      }
    }

    const data = await API.post(
      'api-service',
      path,
      req
    )

    const convertedResponse = convertResponse(data, options?.mergeZoneEvents, options?.mergeSiteEvents);

    return convertedResponse.filter(el => {
      if (tab === undefined) return el.tab === "unassigned";
      return el.tab === tab;
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default listEvents;
