import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, styled } from '@mui/material';
import EventContentHistoryItem from './EventContentHistoryItem';
import EventChange from '@classes/GEI/EventChange';
import { ExpandMore } from '@mui/icons-material';

const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-content {
    flex-grow: 0 !important;
  }
`

type EventContentHistoryProps = {
  history?: EventChange[];
  onExpandClick: () => void;
  expanded: boolean;
}

const EventContentHistory: React.FC<EventContentHistoryProps> = (props) => {
  const { history, expanded, onExpandClick } = props;

  if (!history || history.length === 0) return null;

  return (
    <StyledAccordion disableGutters square elevation={0} expanded={expanded} onClick={onExpandClick}>
      <AccordionSummary expandIcon={<ExpandMore />} >
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Typography variant="subtitle1">Show edit history</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <Box display="grid" gridTemplateRows="min-content" gap="0.5rem" maxHeight="20vh" overflow="auto" width="100%" justifyContent="center" alignItems="center">
          {history?.map((item) => (
            <EventContentHistoryItem history={item} key={history.indexOf(item)} />
          ))}
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default EventContentHistory;
