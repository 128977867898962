import { API } from "aws-amplify";

async function listScheduleTemplates(siteId: string, filterText?: string): Promise<ScheduleTemplate[]> {
  try {
    const path: string = `/scheduleTemplate/${siteId}`;

    const queryStringParameters: any = {};
    if (filterText)
      queryStringParameters.filterText = filterText;

    const data = await API.get(
      'api-service',
      path,
      {
        queryStringParameters: queryStringParameters
      }
    )

    if (data.data) {
      return (data.data as ScheduleTemplate[]).sort((st1, st2) =>
        (st1?.name || "z").localeCompare((st2?.name || "z")));
    }

    return [];
  } catch (e) {
    return [];
  }
}

export default listScheduleTemplates;
