import React from 'react';
import {
  Box,
  CircularProgress,
  Fade,
  MenuItem,
  Menu,
  Dialog
} from '@mui/material';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import ZoneEdit from './ZoneEdit';
import ZoneList from './ZoneList';
import ZoneInfo from './ZoneInfo';

export type ZoneTabProps = {
  zones: Zone[];
  isLoadingZones: boolean;
  onSelectedZoneChange?: (zone: Zone | undefined) => void;
  onFilteredZonesChange?: (zones: Zone[]) => void;
  focusedZone?: Zone;
  onDeactivateZone?: (zone: Zone, callback?: () => void) => void;
  onActivateZone?: (zone: Zone, callback?: () => void) => void;
  onArchiveZone?: (zone: Zone, callback?: () => void) => void;
  onError?: (error: any) => void;
  features?: StreamFeatures | Map<string, StreamFeatures>;
  timezone?: string;
  disableCreateNewZone?: boolean;
  onZoneMouseEnter?: (zoneId: string) => void;
  onZoneMouseLeave?: (zoneId: string) => void;
  siteId?: string;
}

const ZonesTab: React.FC<ZoneTabProps> = (props) => {
  const {
    zones,
    isLoadingZones,
    onSelectedZoneChange,
    onFilteredZonesChange,
    focusedZone,
    onDeactivateZone,
    onActivateZone,
    onArchiveZone,
    onZoneMouseEnter,
    onZoneMouseLeave,
    disableCreateNewZone
  } = props;

  const [filteredZones, setFilteredZones] = React.useState<Zone[]>([]);
  const [filterCollapse, setFilterCollapse] = React.useState<boolean>(false);
  const [zonesFilter, setZonesFilter] = React.useState<{ active: boolean, inactive: boolean, archived: boolean }>({
    active: true,
    inactive: false,
    archived: false
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isViewingInfo, setViewingInfo] = React.useState<boolean>(false);

  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);
  const wraperRef = React.useRef<HTMLDivElement>(null);
  const onFilteredZonesChangeRef = React.useRef(onFilteredZonesChange);

  React.useEffect(() => { onFilteredZonesChangeRef.current = onFilteredZonesChange }, [onFilteredZonesChange])
  React.useEffect(getFilteredZones, [zones, zonesFilter]);

  React.useEffect(() => {
    if (onFilteredZonesChangeRef.current) onFilteredZonesChangeRef.current(filteredZones);
  }, [filteredZones]);

  function getFilteredZones(): void {
    setFilteredZones(zones.filter((zone) => {
      if (zonesFilter.active === true && zone.state === "ACTIVE") return true;
      if (zonesFilter.inactive === true && zone.state === "INACTIVE") return true;
      if (zonesFilter.archived === true && zone.state === "ARCHIVED") return true;
      return false;
    }))
  }

  function handleCreateZoneClick(): void {
    if (onSelectedZoneChange) {
      onSelectedZoneChange({
        name: `Zone ${zones.length + 1}`,
        coordinates: [],
        id: "",
        type: "inner",
        purpose: "security",
        state: "ACTIVE",
        description: "",
        createdBy: "",
        createdAt: Date.now(),
        email: "",
        color: "#0048E3",
        texture: "smooth",
        minimumObjectSize: 0.03
      })
    }
  }

  function handleCreateZoneFromClick(zone: Zone): void {
    if (onSelectedZoneChange) {
      onSelectedZoneChange({
        ...zone,
        name: `${zone.name} copy`,
        id: "",
        state: "ACTIVE",
        createdBy: "",
        createdAt: Date.now(),
      })
    }
  }

  function handleActivateZone(): void { performZoneAction(onActivateZone, focusedZone); }
  function handleDeactivateZone(): void { performZoneAction(onDeactivateZone, focusedZone); }
  function handleArchiveZone(): void { performZoneAction(onArchiveZone, focusedZone); }

  function performZoneAction(action?: (zone: Zone, callback?: () => void) => void, zone?: Zone): void {
    if (zone && action) {
      setAnchorEl(null);
      setLoading(true);
      action(zone, () => {
        setLoading(false);
        if (onSelectedZoneChange) onSelectedZoneChange(undefined);
      })
    }
  }

  function handleInfoClick() {
    setAnchorEl(null);
    setViewingInfo(true);
  }

  return (
    <div ref={wraperRef}>
      {(loading === true || isLoadingZones === true) && (
        <Box zIndex={5} bgcolor="rgba(255, 255, 255, 0.7)" width={wraperRef.current?.scrollWidth} height={wraperRef.current?.scrollHeight} display="flex" flexDirection="row" justifyContent="center" alignItems="center" position="absolute">
          <CircularProgress />
        </Box>
      )}
      {isLoadingZones === false && focusedZone === undefined &&
        <Fade in={true}>
          <Box>
            <ZoneList
              {...props}
              filterCollapse={filterCollapse}
              onCreateZoneClick={handleCreateZoneClick}
              filteredZones={filteredZones}
              onSelectZone={(zone: Zone) => {
                if (onSelectedZoneChange) onSelectedZoneChange({ ...zone });
              }}
              onCreateZoneFrom={handleCreateZoneFromClick}
              onFilterClick={() => setFilterCollapse(!filterCollapse)}
              zonesFilter={zonesFilter}
              setZonesFilter={setZonesFilter}
              disableCreateNewZone={disableCreateNewZone}
              onZoneMouseEnter={onZoneMouseEnter}
              onZoneMouseLeave={onZoneMouseLeave}
            />
          </Box>
        </Fade>
      }
      {isLoadingZones === false && focusedZone !== undefined &&
        <Fade in={true}>
          <Box>
            <ZoneEdit
              setAnchorEl={setAnchorEl}
              {...props}
            />
          </Box>
        </Fade>
      }
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {focusedZone && focusedZone.state === "ACTIVE" &&
          <MenuItem onClick={handleDeactivateZone} data-cy={`zone-deactivate-menu-item`} >Deactivate</MenuItem>
        }
        {focusedZone && focusedZone.state === "INACTIVE" &&
          <MenuItem onClick={handleActivateZone} data-cy={`zone-activate-menu-item`} >Activate</MenuItem>
        }
        {focusedZone && focusedZone.state === "INACTIVE" &&
          <MenuItem onClick={handleArchiveZone} data-cy={`zone-archive-menu-item`} >Archive</MenuItem>
        }
        {focusedZone && focusedZone.id !== "" &&
          <MenuItem onClick={handleInfoClick}>Info</MenuItem>
        }
      </Menu>
      <Dialog
        onClose={() => { setViewingInfo(false); }}
        onBackdropClick={() => { setViewingInfo(false); }}
        open={isViewingInfo}
      >
        <ZoneInfo
          focusedZone={focusedZone}
          onClose={() => { setViewingInfo(false); }}
          selectedSite={selectedSite}
        />
      </Dialog>
    </div>
  )
}

export default ZonesTab;
