import { ActionTypes, IOverviewViewState, OverviewTabs } from './types';
import * as actions from './actions';
import Utils from '@utils/index';
import { findIndex, first, isArray, isEqual } from 'lodash';
import StreamEventsMerger from '@classes/StreamEvents/StreamEventsMerger';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IOverviewViewState = {
  activeTab: OverviewTabs.SiteList,
  filters: undefined,
  events: [],
  eventInboxSidePanel: "filter_panel",
  overviewOptions: {
    playbackRate: 1,
    hideOngoingEvents: false,
    mergeZoneEvents: true,
    mergeSiteEvents: false
  },
  eventCount: {
    unassigned: 0,
    assigned: 0,
    archived: 0,
    trash: 0,
    muted: 0
  },
  unloadedEventCount: {
    unassigned: 0,
    assigned: 0,
    archived: 0,
    trash: 0,
    muted: 0
  },
  selectedEvents: [],
  zoneDatabase: {},
  currentZones: [],
  selectedZone: undefined,
  filteredZones: [],
  muteTimeValue: 4,
  muteTimeValueType: "hours",
  hoveredZoneId: undefined
};

export const overviewReducer = Utils.Redux.createReducer<IOverviewViewState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_ACTIVE_TAB]: (state, action) => ({
      ...state,
      activeTab: action.value
    }),

    [ActionTypes.SET_INBOX_FILTER]: (state, action) => ({
      ...state,
      filters: action.value
    }),

    [ActionTypes.PUSH_EVENT]: (state, action) => {
      const events = updateEventList(
        state.events,
        action.value,
        state.overviewOptions.mergeZoneEvents,
        state.overviewOptions.mergeSiteEvents,
        false
      );

      return {
        ...state,
        events: events
      }
    },

    [ActionTypes.CLEAR_EVENTS]: (state, action) => ({
      ...state,
      events: [],
      eventCount: {
        assigned: 0,
        unassigned: 0,
        trash: 0,
        muted: 0,
        archived: 0
      },
      unloadedEventCount: {
        assigned: 0,
        unassigned: 0,
        trash: 0,
        muted: 0,
        archived: 0
      }
    }),

    [ActionTypes.CHANGE_EVENT_STATUS]: (state, action) => ({
      ...state,
      events: updateEventStatus(state.events, action.events, action.value)
    }),

    [ActionTypes.CHANGE_EVENT_TAG]: (state, action) => ({
      ...state,
      events: updateEventsTag(state.events, action.events, action.value)
    }),

    [ActionTypes.SET_FILTER_BAR_EXPANDED]: (state, action) => ({
      ...state,
      eventInboxSidePanel: action.value
    }),

    [ActionTypes.UPDATE_EVENTS]: (state, action) => {
      return {
        ...state,
        events: updateEvents(state.events, action.events)
      }
    },

    [ActionTypes.SET_OVERVIEW_OPTIONS]: (state, action) => ({
      ...state,
      overviewOptions: action.value,
      events: evaluateNeedToMergeOrSplitEvents(action.value, state.events)
    }),

    [ActionTypes.SPLIT_MERGED_EVENT]: (state, action) => ({
      ...state,
      events: unmergeEvents(
        state.events,
        action.events,
        state.overviewOptions.mergeZoneEvents,
        state.overviewOptions.mergeSiteEvents,
        true
      )
    }),

    [ActionTypes.SET_EVENTS]: (state, action) => ({
      ...state,
      events: action.events
    }),

    [ActionTypes.SET_SELECTED_EVENTS]: (state, action) => ({
      ...state,
      selectedEvents: action.events
    }),

    [ActionTypes.SET_SELECTED_ZONE]: (state, action) => ({
      ...state,
      selectedZone: action.zone
    }),

    [ActionTypes.PUSH_ZONES]: (state, action) => ({
      ...state,
      zoneDatabase: addZones(state.zoneDatabase, action.kvsName, action.zones)
    }),

    [ActionTypes.SET_CURRENT_ZONES]: (state, action) => ({
      ...state,
      currentZones: action.zones
    }),

    [ActionTypes.SET_FILTERED_ZONES]: (state, action) => ({
      ...state,
      filteredZones: action.zones
    }),

    [ActionTypes.SET_EVENT_COUNT]: (state, action) => ({
      ...state,
      eventCount: {
        unassigned: action.eventCount.unassigned < 0 ? 0 : action.eventCount.unassigned,
        assigned: action.eventCount.assigned < 0 ? 0 : action.eventCount.assigned,
        archived: action.eventCount.archived < 0 ? 0 : action.eventCount.archived,
        trash: action.eventCount.trash < 0 ? 0 : action.eventCount.trash,
        muted: action.eventCount.muted < 0 ? 0 : action.eventCount.muted,
      }
    }),

    [ActionTypes.SET_MUTE_TIME_VALUE]: (state, action) => ({
      ...state,
      muteTimeValue: action.value
    }),

    [ActionTypes.SET_MUTE_TIME_VALUE_TYPE]: (state, action) => ({
      ...state,
      muteTimeValueType: action.value
    }),

    [ActionTypes.SET_UNLOADED_EVENT_COUNT]: (state, action) => ({
      ...state,
      unloadedEventCount: {
        unassigned: action.eventCount.unassigned < 0 ? 0 : action.eventCount.unassigned,
        assigned: action.eventCount.assigned < 0 ? 0 : action.eventCount.assigned,
        archived: action.eventCount.archived < 0 ? 0 : action.eventCount.archived,
        trash: action.eventCount.trash < 0 ? 0 : action.eventCount.trash,
        muted: action.eventCount.muted < 0 ? 0 : action.eventCount.muted,
      }
    }),

    [ActionTypes.SET_HOVERED_ZONE_ID]: (state, action) => ({
      ...state,
      hoveredZoneId: action.zoneId
    })
  }
);

function addZones(current: { [key: string]: Zone[] }, kvsName: string, zonesToAdd: Zone[]): { [key: string]: Zone[] } {
  const map = { ...current };
  map[kvsName] = zonesToAdd;
  return map;
}

function evaluateNeedToMergeOrSplitEvents(options: OverviewOptions, events: VideoStreamEvent[]): VideoStreamEvent[] {
  const newEvents: VideoStreamEvent[] = [];

  events.forEach(event => {
    event.children?.forEach(child => {
      newEvents.push(child)
    })
    event.children = undefined;
    event.mergedEndDate = undefined;
    event.zoneId = [first(event.zoneId) || '']
    event.splitByUser = false;

    newEvents.push(event);
  })

  return new StreamEventsMerger(newEvents).merge(options.mergeZoneEvents, options.mergeSiteEvents);
}

function updateEvents(currentEvents: VideoStreamEvent[], newEvents: VideoStreamEvent[]): VideoStreamEvent[] {
  const newEvent = first(newEvents);
  if (newEvent) {
    const index = findIndex(currentEvents, { id: newEvent.id });
    if (index !== -1 && !isEqual(newEvent, currentEvents[index])) {
      newEvent.children = currentEvents[index].children;
      newEvent.mergedEndDate = currentEvents[index].mergedEndDate;
      newEvent.zoneId = currentEvents[index].zoneId;
      newEvent.arrived = currentEvents[index].arrived;
      newEvent.ongoing = currentEvents[index].ongoing;

      const newArray = Array.from(currentEvents);
      newArray.splice(index, 1, newEvent)
      return newArray
    }

    const target = currentEvents.find(el => isArray(el.children) && findIndex(el.children, { id: newEvent.id }) !== -1);
    if (target) {
      const childIndex = findIndex(target.children, { id: newEvent.id });
      if (target.children && childIndex !== -1 && !isEqual(newEvent, target.children[childIndex])) {
        const newArray = Array.from(currentEvents);
        return newArray
      }
    }
  }

  return currentEvents
}

function unmergeEvents(
  currentEvents: VideoStreamEvent[],
  targets: VideoStreamEvent[],
  mergeZoneEvents: boolean,
  mergeSiteEvents: boolean,
  splitByUser: boolean = true
) {
  const events: VideoStreamEvent[] = [];

  targets.forEach(parent => {
    events.push(parent);
    parent.children?.forEach(child => {
      events.push(child)
    })
  })

  events.forEach(event => {
    event.children = undefined;
    event.mergedEndDate = undefined;
    event.zoneId = [first(event.zoneId) || '']
    event.splitByUser = splitByUser;
  })

  return updateEventList(currentEvents, events, mergeZoneEvents, mergeSiteEvents, true);
}

function updateEventList(
  currentEvents: VideoStreamEvent[],
  newEvents: VideoStreamEvent[],
  mergeZoneEvents: boolean,
  mergeSiteEvents: boolean,
  scanEntireList: boolean,
  currentCount?: EventInboxCount
): VideoStreamEvent[] {
  const newNewEvents = [...newEvents];
  for (let i = 0; i < newNewEvents.length; i += 1) {
    const target = currentEvents.find(el => el.id === newNewEvents[i].id);
    if (target) {
      newNewEvents[i].arrived = target.arrived;
      newNewEvents[i].tags = target.tags;
      newNewEvents[i].escalations = target.escalations;
      newNewEvents[i].owner = target.owner;
      newNewEvents[i].tab = target.tab;
    }
  }

  const ids: string[] = newNewEvents.map(el => el.id);
  const filtered = currentEvents.filter(el => !ids.includes(el.id));
  const merged = new StreamEventsMerger([...filtered, ...newNewEvents], scanEntireList).merge(mergeZoneEvents, mergeSiteEvents);

  return merged;
}

function updateEventStatus(events: VideoStreamEvent[], targets: VideoStreamEvent[], newStatus: EventState): VideoStreamEvent[] {
  const newArray = Array.from(events);
  const indexes: number[] = [];
  targets.forEach(el => indexes.push(events.indexOf(el)));
  indexes.forEach(el => {
    newArray[el].tab = newStatus
    newArray[el].children?.forEach(child => {
      child.tab = newStatus;
    })
  });
  return newArray;
}

function updateEventsTag(events: VideoStreamEvent[], targets: VideoStreamEvent[], newTags?: EventTag[]): VideoStreamEvent[] {
  const newArray = Array.from(events);
  const indexes: number[] = [];
  targets.forEach(el => indexes.push(findIndex(events, { id: el.id })));
  indexes.forEach(el => {
    newArray[el].tags = newTags;
    newArray[el].children?.forEach(child => {
      child.tags = newTags;
    })
  });
  return newArray;
}
