import React from 'react';
import { CommandPanelProps, CommandPanelTabs } from './CommandPanel';
import EventsTab from './EventsTab/EventsTab';
import ExportsTab from './ExportsTab/ExportsTab';
import ZonesTab from './ZonesTab/ZonesTab';

type TabRendererProps = CommandPanelProps & {
  selectedTab: CommandPanelTabs;
}

const TabRenderer: React.FC<TabRendererProps> = (props) => {
  const {
    selectedTab,
    eventsTabProps,
    zoneTabProps,
    exportsTabProps
  } = props;

  if (selectedTab === CommandPanelTabs.Events) {
    return (
      <EventsTab
        {...eventsTabProps}
      />
    )
  }

  if (selectedTab === CommandPanelTabs.Zones) {
    return (
      <ZonesTab
        {...zoneTabProps}
      />
    )
  }

  if (selectedTab === CommandPanelTabs.Exports) {
    return (
      <ExportsTab
        {...exportsTabProps}
      />
    )
  }

  return null;
}

export default TabRenderer;
