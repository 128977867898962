const getWorkersTimeLabelTooltip = ({ hours, minutes, workers }: {
    workers?: number;
    hours?: number;
    minutes?: number;
}) => {
    return (workers ? `${workers} Worker${workers > 1 ? "s" : ""} - ` : "") +
        (hours ? `${hours} h ` : "") +
        (minutes ? `${minutes} min` : "")
}

export default getWorkersTimeLabelTooltip