import { isBoolean } from "lodash";
import { Field } from "./Field";

export class BooleanField<T> extends Field<T, boolean> {
  public override filter(data: Array<T>, target?: boolean): Array<T> {
    return data.filter((el: any) => {      
      const value = this._path?.reduce((a, b) => a ? a[b] : undefined, el)
      if (this._currentOperator === "is_empty") {
        if (value === undefined) return true;
        return false;
      }

      if (this._currentOperator === "is_not_empty") {
        return value !== undefined;
      }

      const targetProperty = value as boolean;

      if (!isBoolean(target)) return true;

      if (this._currentOperator === "is") return targetProperty === target;
      if (this._currentOperator === "is_not") return targetProperty !== target;

      return false;
    })
  }
}