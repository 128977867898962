import { API } from "aws-amplify";

async function archiveStream(siteId: string, kvsName: string, state: string): Promise<void> {
  const path: string = `/streams/${siteId}/${kvsName}/state`
  try {
    await API.post(
      'api-service',
      path,
      { body: state.toString().toUpperCase() }
    )
  } catch (error: any) {
    throw error;
  }
}

export default archiveStream;
