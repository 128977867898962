import React from 'react';
import API from '@API/index';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';

type CompanyZoneNamesLoaderProps = {
  onLoad: (zoneNames: Map<string, string>) => void;
  onError?: (error: any) => void;
  onRoutineCompleted?: () => void;
  updateInterval?: number;
}

const CompanyZoneNamesLoader: React.FC<CompanyZoneNamesLoaderProps> = (props) => {
  const { onLoad, onError, onRoutineCompleted, updateInterval } = props;

  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);

  const updateIntervalRef = React.useRef(updateInterval)
  const zoneIntervalRef = React.useRef<NodeJS.Timeout | undefined>();
  const onRoutineCompletedRef = React.useRef(onRoutineCompleted)
  const onLoadRef = React.useRef(onLoad);
  const onErrorRef = React.useRef(onError)

  React.useEffect(() => { onErrorRef.current = onError }, [onError])
  React.useEffect(() => { onLoadRef.current = onLoad }, [onLoad])
  React.useEffect(() => { onRoutineCompletedRef.current = onRoutineCompleted }, [onRoutineCompleted])

  const fetchZones = React.useCallback(() => {
    if (selectedCompany && onLoadRef.current) {
      API.Geofences.getZonesNames(selectedCompany.companyId)
        .then((names) => {
          onLoadRef.current(names)
        })
        .catch((e) => {
          if (onErrorRef.current) onErrorRef.current(e);
        })
        .finally(() => {
          if (onRoutineCompletedRef.current) onRoutineCompletedRef.current();
        });
    }
  }, [selectedCompany])

  React.useEffect(() => { updateIntervalRef.current = updateInterval }, [updateInterval])
  React.useEffect(setup, [fetchZones]);

  function setup() {
    fetchZones();

    if (updateIntervalRef.current) {
      zoneIntervalRef.current = setInterval(() => {
        fetchZones();
      }, updateIntervalRef.current)
    }

    return () => {
      if (zoneIntervalRef.current) clearInterval(zoneIntervalRef.current)
    }
  }

  return null;
}

export default CompanyZoneNamesLoader;
