import { API } from "aws-amplify";

async function listAccessRequests(): Promise<AccessRequest[]> {
  try {
    const path: string = `/dashboard-access/requests`;

    const data = await API.get(
      'api-service',
      path,
      {}
    )

    if (data.data) {
      return data.data as AccessRequest[];
    }

    return [];
  } catch (e: any) {
    throw new Error(e);
  }
}

export default listAccessRequests;
