import Utils from '@utils/index';
import { isEmpty, trim } from 'lodash';

function isEmailValid(email: string): boolean {
  if(isEmpty(trim(email))) return false;
  if (Utils.RegEx.containsNonEmailCharacters(email)) return false;
  if (email === "") return false;
  if (!email.includes("@")) return false;
  if (!email.includes(".")) return false;
  if (email.split("@")[0].length === 0) return false;
  if (email.split("@").length < 2) return false;
  if (!email.split("@")[1].includes(".")) return false;
  if (email.split("@")[1].split(".")[1].length < 2) return false;

  return true;
}

export default isEmailValid;
