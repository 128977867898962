import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { setSelectedCompany } from '@store/selectedCompanies/actions';
import { setSelectedSite } from '@store/selectedSites/actions';
import { Auth } from 'aws-amplify';
import { clearUser, setActivePage } from '@store/session/actions';
import AppMenu from './AppMenu';
import UserMenu from './UserMenu';
import CompanyList from './CompanyList';
import SiteList from './SiteList';
import { PageView } from '@store/session/types';
import { setAppBarExpanded } from '@store/views/appView/actions';
import { StorageKeys } from '@store/views/appView/types';
import { setCreatingView, setSelectedView } from '@store/views/viewsView/actions';
import SupportMenu from './SupportMenu';
import { UserRole } from '@store/users/types';
import { selectIsRestrictedUser, selectIsSuperAdminUser, selectIsTemporarySuperAdminUser } from '@store/selectors';
import { setActiveStream } from '@store/selectedStream/actions';

const Container = styled.div`
  .MuiListItemIcon-root {
    color: rgba(0, 0, 0, 1) !important;
  }

  .MuiList-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`

// Handles State and Logic.
const AppBar: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const [anchorUserAvantar, setAnchorUserAvatar] = React.useState(null);
  const [anchorCompanies, setAnchorCompanies] = React.useState(null);
  const [anchorSites, setAnchorSites] = React.useState(null);
  const [anchorHelpCenter, setAnchorHelpCenter] = React.useState(null);
  const [siteScrollTop, setSiteScrollTop] = React.useState<number>(0);

  const userData = useSelector((store: IStoreState) => store.session.userData);
  const permissions = useSelector((store: IStoreState) => store.session.permissions);
  const isSuperAdministrator = useSelector(selectIsSuperAdminUser);
  const isAdministrator = useSelector((store: IStoreState) => store.session.userData?.roles === UserRole.Admin) || isSuperAdministrator;
  const isRestrictedAccessRole = useSelector(selectIsRestrictedUser);
  const isTemporarySuperAdmininistrator = useSelector(selectIsTemporarySuperAdminUser);
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);
  const sites = useSelector((store: IStoreState) => store.sites.sites);
  const activePage = useSelector((store: IStoreState) => store.session.activePage);
  const open = useSelector((store: IStoreState) => store.appView.appBarExpanded);
  const isAuthenticated = useSelector((store: IStoreState) => store.session.isAuthenticated);

  const handleManageCompanies = React.useCallback(() => {
    setAnchorCompanies(null);
    dispatch(setSelectedCompany(undefined));
    dispatch(setSelectedSite(undefined));
    dispatch(setActiveStream(undefined));
    dispatch(setSelectedView(undefined));
    dispatch(setActivePage(PageView.Companies))
  }, [dispatch])

  const handleHealthClick = React.useCallback(() => {
    dispatch(setActivePage(PageView.Health))
  }, [dispatch])

  const handleMapClick = React.useCallback(() => {
    dispatch(setActivePage(PageView.Map))
  }, [dispatch])

  const handleEventsClick = React.useCallback(() => {
    dispatch(setActivePage(PageView.Events))
  }, [dispatch])

  const handleCompanyClick = React.useCallback((event: any) => {
    setAnchorCompanies(event.currentTarget);
  }, [])

  const handleManageSitesClick = React.useCallback(() => {
    setAnchorSites(null);
    dispatch(setSelectedSite(undefined))
    dispatch(setActivePage(PageView.Sites));
  }, [dispatch])

  function handleOpenCloseDrawer(): void {
    try {
      localStorage.setItem(StorageKeys.appBarExpanded, (!open).toString());
    } catch (error: any) {
      console.log('Error saving preferences to local storage: appBarExpanded');
      console.log(error);
    } finally {
      dispatch(setAppBarExpanded(!open));
    }
  }

  function handleViewsClick(): void { dispatch(setActivePage(PageView.Streams)); dispatch(setCreatingView(false)); }
  function handleTimelapsesClick(): void { dispatch(setActivePage(PageView.Timelapse)) }
  function handleAnalyticsClick(): void { dispatch(setActivePage(PageView.Analytics)) }
  function handleActionsClick(): void { dispatch(setActivePage(PageView.Actions)) }
  function handleExportsClick(): void { dispatch(setActivePage(PageView.Exports)) }
  function handleCalendarClick(): void {
    if (Boolean(selectedSite)) {
      dispatch(setActivePage(PageView.Attendance))
    } else {
      dispatch(setActivePage(PageView.FlaggedWorkers))
    }
  }
  function handleUserAvatarClick(event: any): void { setAnchorUserAvatar(event.currentTarget); }
  function handleHelpCenterClick(event: any): void { setAnchorHelpCenter(event.currentTarget); }
  function handleSiteClick(event: any): void { setAnchorSites(event.currentTarget); }

  function handleCompanySelection(company: Company): void {
    setAnchorUserAvatar(null);
    setAnchorCompanies(null);

    dispatch(setSelectedSite(undefined));
    dispatch(setSelectedCompany(company));
    if (activePage !== PageView.MyAccount) dispatch(setActivePage(PageView.GetStarted))
  }

  function handleSiteSelection(site?: Site): void {
    setAnchorSites(null);
    if (!site) {
      dispatch(setSelectedSite(undefined));
      dispatch(setSelectedView(undefined));
      dispatch(setCreatingView(false));
      return;
    }

    if (!selectedSite || site?.siteId !== selectedSite.siteId) {
      if (activePage === PageView.Player || activePage === PageView.GetStarted) dispatch(setActivePage(PageView.Streams))
      dispatch(setSelectedSite(site));
      dispatch(setSelectedView(undefined));
      dispatch(setCreatingView(false));
    }
  }

  async function handleSignOut(): Promise<void> {
    setAnchorUserAvatar(null);
    try {
      await Auth.signOut();
      dispatch(clearUser());
      window.userId = undefined;
    } catch (e) {
      dispatch(clearUser());
    }
  }

  function handleManageAccount(): void {
    setAnchorUserAvatar(null);
    dispatch(setActivePage(PageView.MyAccount))
  }

  function handleManagementClick(): void {
    dispatch(setActivePage(PageView.Management))
  }

  function handleVehiclesClick(): void {
    dispatch(setActivePage(PageView.Vehicles))
  }

  function handleSiteEdit(site: Site): void {
    setAnchorSites(null);
    dispatch(setActivePage(PageView.Site));
    dispatch(setSelectedSite(site));
  }

  function handleCompanyEdit(company: Company): void {
    setAnchorCompanies(null);
    dispatch(setSelectedCompany(company));
    dispatch(setActivePage(PageView.Management));
  }

  // Return a shallow div to stay in place of the AppBar to not break the App.tsx layout.
  // In case the user is not Authenticed eg: Login / Register
  if (isAuthenticated === false || isAuthenticated === undefined || activePage === PageView.Policy) {
    return (<div />)
  }

  return (
    <>
      <Container id="app-menu-container">
        <AppMenu
          open={open}
          onExpand={handleOpenCloseDrawer}
          userData={userData}
          permissions={permissions}
          onViewsClick={handleViewsClick}
          onTimelapsesClick={handleTimelapsesClick}
          onUserClick={handleUserAvatarClick}
          selectedSite={selectedSite}
          onSiteClick={handleSiteClick}
          onCompanyClick={handleCompanyClick}
          selectedCompany={selectedCompany}
          activePage={activePage}
          onAnalyticsClick={handleAnalyticsClick}
          onActionsClick={handleActionsClick}
          onExportsClick={handleExportsClick}
          onCalendarClick={handleCalendarClick}
          onHelpCenterClick={handleHelpCenterClick}
          isSuperAdministrator={isSuperAdministrator}
          onSatelliteClick={undefined}
          onEventsClick={handleEventsClick}
          onMapClick={handleMapClick}
          onManagementClick={handleManagementClick}
          onHealthClick={handleHealthClick}
          isAdministrator={isAdministrator}
          onVehiclesClick={handleVehiclesClick}
        />
        <UserMenu
          open={Boolean(anchorUserAvantar)}
          onClose={() => { setAnchorUserAvatar(null) }}
          anchorEl={anchorUserAvantar}
          userData={userData}
          isSuperAdministrator={isSuperAdministrator}
          isTemporaryAccessRole={isRestrictedAccessRole}
          isTemporarySuperAdmininistrator={isTemporarySuperAdmininistrator}
          selectedCompany={selectedCompany}
          onCompanyClick={handleCompanyClick}
          disablePortal={true}
          onSignOutClick={handleSignOut}
          onManageAccountClick={handleManageAccount}
          onAccessRequestsClick={handleManagementClick}
          keepMounted={true}
        />
        <CompanyList
          anchorEl={anchorCompanies}
          open={Boolean(anchorCompanies)}
          onClose={() => { setAnchorCompanies(null) }}
          onCompanySelected={handleCompanySelection}
          disablePortal={true}
          keepMounted={false}
          selectedCompany={selectedCompany}
          onManageCompanies={handleManageCompanies}
          onCompanyEdit={isSuperAdministrator || isAdministrator ? handleCompanyEdit: undefined}
        />
        <SiteList
          anchorEl={anchorSites}
          open={Boolean(anchorSites)}
          onClose={() => { setAnchorSites(null) }}
          sites={sites.get(selectedCompany?.companyId || "") || []}
          onSiteSelected={handleSiteSelection}
          disablePortal={true}
          keepMounted={false}
          selectedSite={selectedSite}
          onSiteEdit={isAdministrator === true ? handleSiteEdit : undefined}
          siteScrollTop={siteScrollTop}
          setSiteScrollTop={setSiteScrollTop}
          onManageSites={handleManageSitesClick}
        />
        <SupportMenu
          anchorEl={anchorHelpCenter}
          open={Boolean(anchorHelpCenter)}
          onClose={() => setAnchorHelpCenter(null)}
          disablePortal={true}
          keepMounted={true}
        />
      </Container>
    </>
  )
}

export default AppBar;

