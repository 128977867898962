import { API } from "aws-amplify";

async function updateCertificate(certificate: Partial<Certificate>, asCompany?: string): Promise<Certificate> {
  try {
    const path: string = `/certificates/${certificate.workerId}/${certificate.id}` + (asCompany ? `?asCompany=${asCompany}` : "");

    const tempCertificate: any = {
      ...certificate
    }
    delete tempCertificate.workerId
    delete tempCertificate.id
    
    const options = {
      body: tempCertificate
    };

    const data = await API.patch(
      'api-service',
      path,
      options
    );

    
    return data.data as Certificate;
  } catch (error: any) {
    throw error;
  }
}

export default updateCertificate;
