import { API } from "aws-amplify";
import * as InternalAPI from '@API/index';

async function deleteZone(streamId: string, zoneId: string): Promise<Zone[]> {
  try {
    const path = `/geofences/${streamId}/${zoneId}`;

    const data: APIResponse<Zone[]> = await API.del(
      'api-service',
      path,
      {}
    );
  
    return data.data as Zone[];
  } catch (e) {
    const geofences = await InternalAPI.default.Geofences.getZones(streamId);
    return geofences || [];
  }
}

export default deleteZone;
