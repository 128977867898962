import { API } from 'aws-amplify';

async function getWorkerImages(
  workerId: number,
  startTime: number,
  endTime: number, asCompany?: string
): Promise<any> {
  try {
    const path = `/workers/${workerId}/images` + (asCompany ? `?asCompany=${asCompany}` : "");

    const { data } = await API.get('api-service', path, {
      queryStringParameters: {
        startTime,
        endTime
      }
    });

    return data as any;
  } catch (error: any) {
    throw error;
  }
}

export default getWorkerImages;
