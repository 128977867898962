import EventsFilter, { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';
import { Box } from '@mui/material';
import { IStoreState } from '@store/index';
import { orderBy } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import EventsInbox from './EventsInbox';
import { useDispatch } from 'react-redux';
import { setEventCount } from '@store/views/overviewView/actions';

type EventInboxMainContentProps = {
  siteListOpen: boolean;
  timeInputOpen: boolean;
  zoneNames: Map<string, string>;
  loading: boolean;
  hideOngoingEvents: boolean;
  filters?: EventsFilterParams;
  inputFocused?: boolean;
  otherUsersSelectedEvents: Array<{ userId: string, eventId: string }>;
  onScrollEnd: (tab: EventState, lastKey: number) => void;
}

const EventInboxMainContent: React.FC<EventInboxMainContentProps> = (props) => {
  const { loading, siteListOpen, zoneNames, hideOngoingEvents, filters, inputFocused, otherUsersSelectedEvents, timeInputOpen, onScrollEnd } = props;

  const dispatch = useDispatch();

  const events = useSelector((store: IStoreState) => store.overviewView.events);
  const unloadedEventCount = useSelector((store: IStoreState) => store.overviewView.unloadedEventCount)

  const getEvents = React.useMemo(() => {
    if (hideOngoingEvents === true) {
      return orderBy(filters ? new EventsFilter(filters)?.getFilteredEvents(events, hideOngoingEvents) : events, ['arrived', 'sourceCamera'], 'desc')
    }

    return orderBy(filters ? new EventsFilter(filters)?.getFilteredEvents(events, hideOngoingEvents) : events, ['arrived', 'sourceCamera'], 'desc')
  }, [events, filters, hideOngoingEvents])

  React.useEffect(() => {
    let unassigned: number = 0;
    let assigned: number = 0;
    let trash: number = 0;
    let archived: number = 0;
    let muted: number = 0;

    getEvents.forEach((parent) => {
      if (parent.tab === "unassigned") unassigned += 1;
      if (parent.tab === "archived") archived += 1;
      if (parent.tab === "assigned") assigned += 1;
      if (parent.tab === "muted") muted += 1;
      if (parent.tab === "trash") trash += 1;

      parent.children?.forEach((child) => {
        if (parent.tab === "unassigned") unassigned += 1;
        if (parent.tab === "archived") archived += 1;
        if (parent.tab === "assigned") assigned += 1;
        if (parent.tab === "muted") muted += 1;
        if (parent.tab === "trash") trash += 1;
      })
    })

    dispatch(setEventCount({
      unassigned: unloadedEventCount.unassigned + unassigned,
      archived: unloadedEventCount.archived + archived,
      trash: unloadedEventCount.trash + trash,
      muted: unloadedEventCount.muted + muted,
      assigned: unloadedEventCount.assigned + assigned
    }))
  }, [dispatch, getEvents, unloadedEventCount])

  return (
    <Box width="100%" height="100%">
      <EventsInbox
        loading={loading}
        events={getEvents}
        siteListOpen={siteListOpen}
        zoneNames={zoneNames}
        inputFocused={inputFocused}
        otherUsersEventSelection={otherUsersSelectedEvents}
        timeInputOpen={timeInputOpen}
        onScrollEnd={onScrollEnd}
      />
    </Box>
  )
}

export default EventInboxMainContent;
