import { slice } from "lodash";

const DIGITS_TO_LEAVE = 4;

function maskPhone(phone: string): string {
  try {
    const numberIndexes: number[] = getDigitsIndexes(phone);
    const indexesToMask = slice(numberIndexes, DIGITS_TO_LEAVE, numberIndexes.length - DIGITS_TO_LEAVE);
    
    let maskedPhone = phone;
    indexesToMask.forEach((index) => {
      maskedPhone = replaceAt(maskedPhone, index);
    })

    return maskedPhone;
  } catch (e) {
    console.log(e);
    return phone;
  }
}

/**
 * Returns all indexes of digits from 0 to 9.
 * @param value string to assert.
 * @returns {number[]} indexes.
 * @example
 * const myValue: string = "1-3-5";
 * const result: number[] = getDigitsIndexes(myValue);
 * console.log(result); // => [0, 2, 4]
 */
function getDigitsIndexes(value: string): number[] {
  const numberIndexes: number[] = [];

  for (let i = 0; i < value.length; i += 1) {
    if (isNumber(value.charAt(i))) {
      numberIndexes.push(i);
    }
  }

  return numberIndexes;
}

/**
 * Returns if a specific char is a numeral digit.
 * @param value char to assert.
 * @returns {boolean}
 * @example
 * const val1: string = '1';
 * console.log(isNumber(val1)); // => 'true';
 * 
 * const val2: string = ' ';
 * console.log(isNumber(val2)); // => 'false';
 */
function isNumber(value: string): boolean {
  return !isNaN(parseInt(value));
}

/**
 * Mask a char inside a string for * at specific index.
 * @param value String to be subject to replacement at.
 * @param index Index of the char to be replaced.
 * @returns {string} new String with the replaced value.
 * @example
 * const value: string = 'bob';
 * const result: string = replaceAt(value);
 * console.log(result); // => 'b*b'
 */
function replaceAt(value: string, index: number): string {
  return value.substring(0, index) + '*' + value.substring(index + 1, value.length);
}

export default maskPhone;
