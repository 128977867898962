import { API } from "aws-amplify";

async function createAccessRequest(duration: number, siteIds: string[], type: "readonly" | "superadmin"): Promise<AccessRequest> {
  try {
    const path: string = `/dashboard-access/requests`;

    const options = {
      body: {
        duration,
        siteIds,
        type
      }
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    return data.data as AccessRequest;
  } catch (error: any) {
    throw error;
  }
}

export default createAccessRequest;

