import { ActionTypes, ISelectedSitesState } from './types';
import * as Actions from './actions';
import Utils from '@utils/index';

type TypesType = ReduxActionTypes<typeof ActionTypes>;
type ActionsType = ReduxActions<typeof Actions>;

const initialState: ISelectedSitesState = {
};

export const selectedSitesReducer = Utils.Redux.createReducer<ISelectedSitesState, TypesType, ActionsType>(
  initialState,
  {
    [ActionTypes.SET_SELECTED_SITE]: (state, action) => ({
      ...state,
      selectedSite: action.selectedSite
    }),
  }
);
