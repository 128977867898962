import getDefaultCVParameters from "./getDefaultCVParameters";

export interface IParametersAPI {
  getDefaultCVParameters(): Promise<Object>;
}

const Parameters: IParametersAPI = {
  getDefaultCVParameters
}

export default Parameters;
