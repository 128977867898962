export function createAccessRequestsFilters(): FilterProperties[] {
  return [
    { label: 'Approved At', property: 'approvedAt', type: 'number' },
    { label: 'Approved At Label', property: 'approvedAtLabel', type: 'string' },
    { label: 'Approved By', property: 'approvedBy', type: 'string' },
    { label: 'Approved By Name', property: 'approvedByName', type: 'string' },
    { label: 'Company ID', property: 'companyId', type: 'string' },
    { label: 'Duration', property: 'duration', type: 'number' },
    { label: 'ID', property: 'id', type: 'string' },
    { label: 'Requested At', property: 'requestedAt', type: 'number' },
    { label: 'Requested At Label', property: 'requestedAtLabel', type: 'string' },
    { label: 'Requested By', property: 'requestedBy', type: 'string' },
    { label: 'Requested By Name', property: 'requestedByName', type: 'string' },
    { label: 'Site IDs', property: 'siteIds', type: 'array' },
    { label: 'Site Names', property: 'siteNames', type: 'string' },
    { label: 'Status', property: 'status', type: 'string' },
    { label: 'Type', property: 'type', type: 'string' },
  ];
}
