import { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';
import { ActionTypes, OverviewTabs } from './types';

export function setActiveOverviewTab(value: OverviewTabs) {
  return { type: ActionTypes.SET_ACTIVE_TAB, value };
}

export function setInboxFilter(value: EventsFilterParams | undefined, saveToLocalStorage: boolean = true) {
  try {
    if (saveToLocalStorage === true) localStorage.setItem('overview_eventsfilter_params', JSON.stringify(value));
  } catch (e) {
    console.log(e);
    console.log('Error writing to LocalStorage');
  }
  return { type: ActionTypes.SET_INBOX_FILTER, value };
}

export function pushEvents(value: VideoStreamEvent[]) {
  return { type: ActionTypes.PUSH_EVENT, value }
}

export function clearEvents() {
  return { type: ActionTypes.CLEAR_EVENTS }
}

export function changeEventState(events: VideoStreamEvent[], value: EventState) {
  return { type: ActionTypes.CHANGE_EVENT_STATUS, events, value }
}

export function changeEventTag(events: VideoStreamEvent[], value?: EventTag[]) {
  return { type: ActionTypes.CHANGE_EVENT_TAG, events, value }
}

export function setActiveSidePanel(value: OverviewSidePanel) {
  try {
    localStorage.setItem('eventInboxSidePanel', value);
  } catch (e) {
    console.log(e);
    console.log('Error writing to LocalStorage');
  }
  return { type: ActionTypes.SET_FILTER_BAR_EXPANDED, value }
}

export function updateEvents(events: VideoStreamEvent[]) {
  return { type: ActionTypes.UPDATE_EVENTS, events };
}

export function setOverviewOptions(value: OverviewOptions) {
  try {
    localStorage.setItem('eventInboxOptions', JSON.stringify(value));
  } catch (e) {
    console.log(e);
    console.log('Error writing to LocalStorage');
  }

  return { type: ActionTypes.SET_OVERVIEW_OPTIONS, value }
}

export function splitMergedEvents(events: VideoStreamEvent[]) {
  return { type: ActionTypes.SPLIT_MERGED_EVENT, events };
}

export function setEvents(events: VideoStreamEvent[]) {
  return { type: ActionTypes.SET_EVENTS, events }
}

export function setSelectedEvents(events: VideoStreamEvent[]) {
  return { type: ActionTypes.SET_SELECTED_EVENTS, events }
}

export function pushZones(kvsName: string, zones: Zone[]) {
  return { type: ActionTypes.PUSH_ZONES, kvsName, zones };
}

export function setCurrentZones(zones: Zone[]) {
  return { type: ActionTypes.SET_CURRENT_ZONES, zones }
}

export function setSelectedZone(zone?: Zone) {
  return { type: ActionTypes.SET_SELECTED_ZONE, zone }
}

export function setFilteredZones(zones: Zone[]) {
  return { type: ActionTypes.SET_FILTERED_ZONES, zones }
}

export function setEventCount(eventCount: EventInboxCount) {
  return { type: ActionTypes.SET_EVENT_COUNT, eventCount }
}

export function setMuteTimeValue(value?: number) {
  try {
    localStorage.setItem('eventInboxMuteTimeValue', `${value}`);
  } catch (error) {
    console.log(error)
  }
  return { type: ActionTypes.SET_MUTE_TIME_VALUE, value }
}

export function setMuteTimeValueType(value: "hours" | "minutes") {
  try {
    localStorage.setItem('eventInboxMuteTimeValueType', value);
  } catch (error) {
    console.log(error)
  }
  return { type: ActionTypes.SET_MUTE_TIME_VALUE_TYPE, value }
}

export function setUnloadedEventCount(eventCount: EventInboxCount) {
  return { type: ActionTypes.SET_UNLOADED_EVENT_COUNT, eventCount }
}

export function setHoveredZoneId(zoneId?: string[]) {
  return { type: ActionTypes.SET_HOVERED_ZONE_ID, zoneId }
}