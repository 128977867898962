function generateStylesUrl(style: "light" | "dark" | "streets" | "satellite" | "outdoors" | "satellite-streets"): string {
  const stylesToURL = {
    "streets": "streets-v11",
    "outdoors": "outdoors-v11",
    "light": "light-v10",
    "dark": "dark-v10",
    "satellite": "satellite-v9",
    "satellite-streets": "satellite-streets-v11"
  }

  return 'mapbox://styles/mapbox/' + stylesToURL[style];
}

export default generateStylesUrl;
