import { API } from "aws-amplify";

async function postDownloadStart(kvsName: string, start: number, end: number, options?: { channels?: string[] }, name?: string, description?: string): Promise<{ videoDownloadId: string } | undefined> {
  try {
    const path: string = `/download/video/start/${kvsName}`;

    const opt = {
      body: {
        start: start,
        end: end,
        options,
        name,
        description
      }
    }

    const data = await API.post(
      'api-service',
      path,
      opt
    ) as APIResponse<VideoDownload>;

    return data.data;

  } catch (error: any) {
    throw error;
  }
}

export default postDownloadStart;