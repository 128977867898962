function getDateTimePickerErrorMessage(error: string | null | undefined): string | undefined {
  switch (error) {
    case "maxTime":
      return "Time exceeds maximum allowed"      
    case "minTime":
      return "Time exceeds minimum allowed"
    case "invalidDate":
      return "Format of date is invalid"
    default:
      return undefined;
  }
}

export default getDateTimePickerErrorMessage;
