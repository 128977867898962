import { ExpandMore } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, MenuItem, Select, Typography } from '@mui/material';
import { IStoreState } from '@store/index';
import { setOverviewOptions } from '@store/views/overviewView/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const EventInboxOptions: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const options = useSelector((store: IStoreState) => store.overviewView.overviewOptions);
  const { playbackRate, hideOngoingEvents, mergeZoneEvents, mergeSiteEvents } = options;

  function handlePlaybackrateChange(value: number) {
    dispatch(setOverviewOptions({ ...options, playbackRate: value }))
  }

  function handleHideOnGoingEvents(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    dispatch(setOverviewOptions({ ...options, hideOngoingEvents: checked }))
  }

  function handleMergeZoneEventsChange(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    dispatch(setOverviewOptions({ ...options, mergeZoneEvents: checked }))
  }


  function handleMergeSiteEventsChange(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    dispatch(setOverviewOptions({ ...options, mergeSiteEvents: checked }))
  }

  return (
    <Box
      width="100%"
      height="100%"
      borderLeft="1px solid rgba(0, 0, 0, 0.12)"
      paddingTop="0.5rem"
      paddingRight="0.5rem"
      paddingLeft="0.5rem"
    >
      <Box
        display="grid"
        gap="0.5rem"
      >
        <Typography style={{ marginLeft: 8 }} variant="h6" >Options</Typography>
        <Box style={{ marginLeft: 8 }}>
          <Typography variant="subtitle2">{`Video playback rate`}</Typography>
          <Select IconComponent={ExpandMore} style={{ minWidth: '10rem' }} variant="outlined" value={playbackRate}>
            <MenuItem onClick={() => handlePlaybackrateChange(0.25)} value={0.25}>Slowest (0.25x)</MenuItem>
            <MenuItem onClick={() => handlePlaybackrateChange(0.5)} value={0.5}>Slow (0.5x)</MenuItem>
            <MenuItem onClick={() => handlePlaybackrateChange(1)} value={1}>Normal (1x)</MenuItem>
            <MenuItem onClick={() => handlePlaybackrateChange(2)} value={2}>Fast (2x)</MenuItem>
            <MenuItem onClick={() => handlePlaybackrateChange(4)} value={4}>Very fast (4x)</MenuItem>
            <MenuItem onClick={() => handlePlaybackrateChange(8)} value={8}>Fastest (8x)</MenuItem>
          </Select>
        </Box>
        <FormControlLabel
          style={{ marginLeft: 4 }}
          control={
            <Checkbox size="small" checked={hideOngoingEvents} onChange={handleHideOnGoingEvents} name="ongoing" />
          }
          label="Hide ongoing events"
        />
        <FormControlLabel
          style={{ marginLeft: 4 }}
          control={
            <Checkbox size="small" checked={mergeZoneEvents} onChange={handleMergeZoneEventsChange} name="mergeZone" />
          }
          label="Merge zone triggered events"
        />
        <FormControlLabel
          style={{ marginLeft: 4 }}
          control={
            <Checkbox size="small" checked={mergeSiteEvents} onChange={handleMergeSiteEventsChange} name="mergeSite" />
          }
          label="Merge site events"
        />
      </Box>
    </Box>
  )
}

export default EventInboxOptions;
