import getTagPositivity from "./getTagPositivity";
import getTagText from "./getTagText";

export interface IStreamEvent {
    getTagText(tag: string): string;
    getTagPositivity(label: string): boolean | undefined;
}

const StreamEvent: IStreamEvent = {
    getTagText,
    getTagPositivity
}

export default StreamEvent;
