import React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuProps,
  Switch
} from '@mui/material';
import { Help, InfoOutlined, SupportAgent } from '@mui/icons-material';
import { IStoreState } from '@store/index';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTutorial, setTutorialsEnabled } from '@store/tutorials/actions';
import { useSnackbar } from 'notistack';

const FORSIGHT_SUPPORT_URL = "https://forsight.atlassian.net/servicedesk/customer/portal/2";

type SupportMenuProps = MenuProps & {

}

const SupportMenu: React.FC<SupportMenuProps> = (props) => {
  const { ...menuProps } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const tutorialsEnabled = useSelector((store: IStoreState) => store.tutorials.tutorialsEnabled);

  function handleReportIssueClick(e: any): void {
    try {
      if (menuProps.onClose) menuProps.onClose(e, 'escapeKeyDown');
      window.open(FORSIGHT_SUPPORT_URL, "_blank")
    } catch (e) {
      enqueueSnackbar("Couldn't open the support center, please contact an Administrator.", { variant: 'error' });
    }
  }

  function handleSwitchChange(e: React.ChangeEvent<HTMLInputElement>): void {
    try {
      const value = e.target.checked;
      localStorage.setItem("tutorialsEnabled", (value).toString());
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setTutorialsEnabled(e.target.checked))
    }
  }

  function handleShowMeAroundClick(e: any): void {
    try {
      if (menuProps.onClose) menuProps.onClose(e, 'escapeKeyDown');
      dispatch(setActiveTutorial("get_started"))
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Menu
      {...menuProps}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      style={{ marginLeft: "2rem" }}
      elevation={3}
    >
      <List disablePadding >
        <ListItem>
          <ListItemIcon>
            <Help />
          </ListItemIcon>
          <ListItemText primary="Enable walkthroughs" secondary="Show usage tips. Hover over question marks to show the tips." />
          <Switch checked={tutorialsEnabled} onChange={handleSwitchChange} data-cy="enable-walkthrougths-switch" />
        </ListItem>
        <Divider />
        <ListItem onClick={handleShowMeAroundClick} button data-cy="support-menu-show-me-around-list-item">
          <ListItemIcon>
            <InfoOutlined />
          </ListItemIcon>
          <ListItemText primary="Show me around" />
        </ListItem>
        <Divider />
        <ListItem onClick={handleReportIssueClick} button data-cy="support-menu-report-list-item">
          <ListItemIcon>
            <SupportAgent />
          </ListItemIcon>
          <ListItemText primary="Report an issue / Request help" secondary="Open support ticket with details about your problem/question." />
        </ListItem>
      </List>
    </Menu>
  )
}

export default SupportMenu;
