import { KinesisVideo } from "aws-sdk";
import API from '@API/index';

async function getLiveStream(
  streamName: string,
  options: KinesisVideo.ClientConfiguration
) {

  const dataEndpoint = await API.Kinesis.getDataEndpoint(
    streamName,
    options,
    'GET_HLS_STREAMING_SESSION_URL'
  );

  const playbackMode = 'LIVE'; // 'LIVE' or 'ON_DEMAND or 'LIVE_REPLAY'
  const fragmentSelectorType = 'PRODUCER_TIMESTAMP'; // 'SERVER_TIMESTAMP' or 'PRODUCER_TIMESTAMP'

  const streamUrl = await API.Kinesis.getHLSStreamingSessionURL(
    streamName,
    options,
    dataEndpoint,
    {
      PlaybackMode: playbackMode,
      DisplayFragmentTimestamp: "ALWAYS",
      HLSFragmentSelector: {
        FragmentSelectorType: fragmentSelectorType,
      }
    }
  );

  return streamUrl;
}

export default getLiveStream;
