import TutorialButton from '@components/common/TutorialButton';
import { Box, Typography } from '@mui/material';
import React from 'react';

const Title: React.FC<{}> = () => {
  return (
    <Box paddingBottom="1rem" display="grid" gridTemplateColumns="auto min-content">
      <Typography variant="h6" data-cy="save-video-tab-title">Save video</Typography>
      <TutorialButton tutorialType="timeline_save_video" />
    </Box>
  )
}

export default Title;
