import { Box, Button, Checkbox, Collapse, FormControlLabel, IconButton, List, Tooltip, Typography } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import ZoneListItem from './ZoneListItem';
import { ZoneTabProps } from './ZonesTab';
import TutorialButton from '@components/common/TutorialButton';
import Utils from '@utils/index';
import { isMap, isObject } from 'lodash';
import { useSnackbar } from 'notistack';

type ZoneListProps = ZoneTabProps & {
  filterCollapse: boolean;
  onCreateZoneClick: () => void;
  filteredZones: Zone[];
  onSelectZone: (zone: Zone) => void;
  onFilterClick: () => void;
  zonesFilter: { active: boolean, inactive: boolean, archived: boolean };
  features?: StreamFeatures | Map<string, StreamFeatures>;
  disableCreateNewZone?: boolean;
  setZonesFilter: React.Dispatch<React.SetStateAction<{
    active: boolean;
    inactive: boolean;
    archived: boolean;
  }>>;
  onCreateZoneFrom?: (zone: Zone) => void;
  onZoneMouseEnter?: (zoneId: string) => void;
  onZoneMouseLeave?: (zoneId: string) => void;
}

const ZoneList: React.FC<ZoneListProps> = (props) => {
  const {
    zones,
    filterCollapse,
    onCreateZoneClick,
    filteredZones,
    onSelectZone,
    onFilterClick,
    zonesFilter,
    setZonesFilter,
    features,
    disableCreateNewZone,
    onZoneMouseEnter,
    onZoneMouseLeave,
    onCreateZoneFrom
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const createNewZoneDisabled = React.useMemo(() => {
    if (disableCreateNewZone === true) {
      return true;
    }

    if (features) {
      if (isMap(features)) {
        const map = features as Map<string, StreamFeatures>;
        let canCreate: boolean = false;

        map.forEach((value, key) => {
          if (value.zones.write === true) {
            canCreate = true;
          }
        })

        return !canCreate;
      }

      if (isObject(features)) {
        const feat = features as StreamFeatures;
        return !feat.zones.write;
      }
    }

    return true;
  }, [disableCreateNewZone, features])

  const handleZoneSelection = React.useCallback((zone: Zone) => {
    if (features) {
      if (isMap(features) && zone.kvsName) {
        const feat = features as Map<string, StreamFeatures>;
        if (feat.has(zone.kvsName)) {
          if (feat.get(zone.kvsName)?.zones.write) {
            onSelectZone(zone);
            return;
          }
        }
      }

      if (isObject(features)) {
        const obj = features as StreamFeatures;
        if (obj.zones.write === true) {
          onSelectZone(zone);
          return;
        }
      }
    }

    enqueueSnackbar("Cannot edit zone.", { variant: 'error' });
  }, [enqueueSnackbar, features, onSelectZone])

  return (
    <Box padding="1rem" width="100%" height="100%" display="grid" gridTemplateRows="repeat(3, min-content) auto">
      <Box width="100%" display="flex" flexDirection="row">
        <Box display="grid" gridTemplateColumns="max-content max-content" gap="0.5rem" flex={1} flexDirection="row">
          <Tooltip title={createNewZoneDisabled ? "Zone creation disabled" : "Create new zone"}>
            <span>
              <Button id="create-zones-description" disabled={createNewZoneDisabled} onClick={onCreateZoneClick} size="small" variant="outlined" data-cy="create-zone-button" >Create zone</Button>
            </span>
          </Tooltip>
        </Box>
        <Box display="flex" flex={1} justifyContent="flex-end" alignItems="flex-end" flexDirection="row">
          <TutorialButton tutorialType="timeline_zones" />
          <Tooltip title="Filter">
            <IconButton
              color={filterCollapse === true ? 'primary' : undefined}
              onClick={onFilterClick}
              data-cy={`zone-filter-icon-button`}
              size="small"
            >
              <FilterList />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Collapse in={filterCollapse}>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="h6" >Status</Typography>
          <Box display="flex" flexDirection="row" >
            <FormControlLabel
              control={
                <Checkbox data-cy={`zone-active-checkbox`}
                  onChange={(_event: any, checked: boolean) => setZonesFilter({ ...zonesFilter, active: checked })}
                  checked={zonesFilter.active}
                  color="primary"
                  size="small"
                />
              }
              label="Active" />
            <FormControlLabel
              control={
                <Checkbox
                  data-cy={`zone-inactive-checkbox`}
                  onChange={(_event: any, checked: boolean) => setZonesFilter({ ...zonesFilter, inactive: checked })}
                  checked={zonesFilter.inactive}
                  color="primary"
                  size="small"
                />
              }
              label="Inactive"
            />
          </Box>
        </Box>
      </Collapse>
      <Box paddingTop="1rem">
        <Typography variant="h6" data-cy="zones-tab-title">All zones</Typography>
      </Box>
      <Box id="all-zones-list-description" maxHeight="72vh" width="100%" height="100%" overflow="auto">
        <List data-cy="zone-list">
          {filteredZones.length ?
            <TransitionGroup>
              {Utils.Array.orderAlphabetically(filteredZones, 'name').map(zone => (
                <Collapse key={zone.id}>
                  <ZoneListItem
                    data-cy={`zone-${zone.name}-list-item`}
                    zone={zone}
                    index={zones.indexOf(zone) + 1}
                    onClick={() => handleZoneSelection(zone)}
                    onZoneMouseEnter={onZoneMouseEnter}
                    onZoneMouseLeave={onZoneMouseLeave}
                    onCreateNewFrom={onCreateZoneFrom}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
            :
            <Typography variant="body2">No results found.</Typography>}
        </List>
      </Box>
    </Box>
  )
}

export default ZoneList;
