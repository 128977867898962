import { ActionTypes, IUsersState } from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IUsersState = {
  users: [],
};

export const usersReducer = Utils.Redux.createReducer<IUsersState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_USERS]: (state, action) => ({
      ...state,
      users: action.value
    })
  }
);