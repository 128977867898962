
export const AvatarColors: string[] = [
  "#0048E3",
  "#107145",
  "#FFA720",
  "#BF260E",
  "#2D076B",
  "#07596B",
  "#FF6D1D",
  "#000000"
]

function generateBackgroundFromColorRange(value?: string): string | undefined {
  if (value) {
    let sum = 0;
    for (let i = 0; i < value.length; i += 1) {
      sum += value.charCodeAt(i);
    }

    return AvatarColors[sum % AvatarColors.length];
  } else {
    return AvatarColors[Math.floor(Math.random() * AvatarColors.length)];
  }
}

export default generateBackgroundFromColorRange;