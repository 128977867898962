import { Board } from '@components/common/KinesisPlayer/ZonesCanvas';
import { Box } from '@mui/material';
import React from 'react';
import Utils from '@utils/index';
import styled from 'styled-components';

const Canvas = styled.canvas`
  position: absolute;
  z-index: 3;
  display: block;

  min-height: 1px;
  min-width: 1px;
`;

export interface IPoseEstimationRendererHandles {
  draw: (boxes: PoseEstimations | undefined) => void;
}

type PoseEstimationRendererProps = {
  videoWidth: number;
  videoHeight: number;
  containerWidth: number;
  containerHeight: number;
  videoWindowOffset: Vector2;
  videoState: "error" | "buffering" | "loading" | "ready";
}

const PoseEstimationRenderer: React.ForwardRefRenderFunction<IPoseEstimationRendererHandles, PoseEstimationRendererProps> = (props, ref) => {
  const { containerWidth, containerHeight, videoWidth, videoHeight, videoState } = props;

  React.useImperativeHandle(ref, () => ({
    draw
  }))

  const [canvasSize, setCanvasSize] = React.useState<Vector2>({ x: 0, y: 0 });

  React.useEffect(calculateCanvasSize, [videoWidth, videoHeight, containerWidth, containerHeight]);

  const canvasRef = React.useRef<HTMLCanvasElement | null>(null)

  function draw(boxes: PoseEstimations | undefined): void {
    drawBoundingBoxes(canvasRef.current, boxes);
  }

  function calculateCanvasSize() {
    if (videoWidth !== 0 && videoHeight !== 0 && containerWidth !== 0 && containerHeight !== 0) {
      const size = Utils.Geometry.getCanvasSize(
        videoWidth || 0,
        videoHeight || 0,
        containerWidth || 0,
        containerHeight || 0
      );

      setCanvasSize(size);
    }
  }

  function drawGreenSquareOnPoint(context: CanvasRenderingContext2D, position: { x: number, y: number }): void {
    context.beginPath();
    context.strokeStyle = "limegreen";

    const { x, y } = position;
    const upperLeft = { x: x-2, y: y-2 };
    const upperRight = { x: x+2, y: y-2 };
    const lowerRight = { x: x+2, y: y+2 };
    const lowerLeft = { x: x-2, y: y+2 };

    context.moveTo(upperLeft.x, upperLeft.y);
    context.lineTo(upperRight.x, upperRight.y);
    context.stroke();
    context.lineTo(lowerRight.x, lowerRight.y);
    context.stroke();
    context.lineTo(lowerLeft.x, lowerLeft.y);
    context.stroke();
    context.lineTo(upperLeft.x, upperLeft.y);
    context.stroke();
    context.moveTo(x, y);
  }

  function drawBoundingBoxes(ref: HTMLCanvasElement | null, poseEstimation: PoseEstimations | undefined): void {
    try {
      if (ref !== null) {
        const context = ref.getContext('2d');
        if (context) {
          context.clearRect(0, 0, canvasSize.x, canvasSize.y);

          if (poseEstimation) {
            context.lineWidth = 1;
            context.fillStyle = "transparent";

            poseEstimation.points.forEach((pose) => {

              const positions: { x: number, y: number }[] = pose.map((coordinate) => {
                const x = coordinate[0];
                const y = coordinate[1];

                return { x: x * (canvasSize.x / videoWidth), y: y * (canvasSize.y / videoHeight) };
              })

              context.beginPath();
              context.strokeStyle = "red";

              if (positions.length === 17) {
                context.moveTo(positions[3].x, positions[3].y)
 
                context.lineTo(positions[1].x, positions[1].y);
                context.stroke();

                context.lineTo(positions[0].x, positions[0].y);
                context.stroke();

                context.lineTo(positions[2].x, positions[2].y);
                context.stroke();

                context.lineTo(positions[4].x, positions[4].y);
                context.stroke();

                context.moveTo(positions[9].x, positions[9].y);

                context.lineTo(positions[7].x, positions[7].y);
                context.stroke();

                context.lineTo(positions[5].x, positions[5].y);
                context.stroke();

                context.lineTo(positions[6].x, positions[6].y);
                context.stroke();

                context.lineTo(positions[8].x, positions[8].y);
                context.stroke();

                context.lineTo(positions[10].x, positions[10].y);
                context.stroke();

                context.moveTo(positions[5].x, positions[5].y);

                context.lineTo(positions[11].x, positions[11].y);
                context.stroke();

                context.lineTo(positions[12].x, positions[12].y);
                context.stroke();

                context.lineTo(positions[6].x, positions[6].y);
                context.stroke();

                context.moveTo(positions[15].x, positions[15].y);

                context.lineTo(positions[13].x, positions[13].y);
                context.stroke();

                context.lineTo(positions[11].x, positions[11].y);
                context.stroke();

                context.moveTo(positions[16].x, positions[16].y);

                context.lineTo(positions[14].x, positions[14].y);
                context.stroke();

                context.lineTo(positions[12].x, positions[12].y);
                context.stroke();
              }

              positions.forEach(el => drawGreenSquareOnPoint(context, el))

              context.fill();

            })
          }
        }
      }
    } catch (e) { }
  }

  if (videoState === "error" || videoState === "loading") return null;

  return (
    <Box style={{ pointerEvents: 'none' }} position="absolute" zIndex={32} width={containerWidth} height={containerHeight}>
      <Board
        width={`${canvasSize.x}px`}
        height={`${canvasSize.y}px`}
      >
        <Canvas
          width={canvasSize.x}
          height={canvasSize.y}
          ref={canvasRef}
          style={{
            width: canvasSize.x || 0,
            height: canvasSize.y || 0,
            left: 0,
            top: 0
          }}
          data-cy="event-content-detection-box-canvas"
        />
      </Board>
    </Box>
  )
}

export default React.forwardRef(PoseEstimationRenderer);
