import { API } from "aws-amplify";

export async function listImagesForReview(asCompany?: string): Promise<ImageMatchReview[]> {
  try {
    const path: string = `/workers/images/review` + (asCompany ? `?asCompany=${asCompany}` : "");
    const data = await API.get('api-service', path, {});

    return data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}