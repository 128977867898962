import { useSnackbar } from 'notistack';
import React from 'react';
import Utils from '@utils/index';

type ErrorHandlerProps = {
  message: string;
  error: any | undefined;
  reset?: () => void;
}

/**
 * Takes an error variable and an action and renders a snackbar every time the error becomes non undefined.
 * Responsabilities:
 * - Assert if the error variable is not undefined.
 * - Renders a Snackbar containing the error message plus the server error.
 * @example
 * const Comp: React.FC<{}> = () => {
 *  const [error, setError] = React.useState<any | undefined>(undefined);
 * 
 *  return (
 *    <ErrorHandler
 *      message={"First line error message"}
 *      error={error}
 *      reset={() => { setError(undefined); }}
 *    />
 *  )
 * }
 */
const ErrorHandler: React.FC<ErrorHandlerProps> = (props) => {
  const { message, error, reset } = props;

  const { enqueueSnackbar } = useSnackbar();

  const enqueueSnackbarRef = React.useRef(enqueueSnackbar);
  const resetRef = React.useRef(reset);
  const messageRef = React.useRef(message)

  const showError = React.useCallback((message: string) => {
    enqueueSnackbarRef.current(
      message,
      {
        variant: 'error', style: { whiteSpace: 'pre-line' }
      }
    );
  }, [])

  React.useEffect(() => { messageRef.current = message }, [message])
  React.useEffect(() => { enqueueSnackbarRef.current = enqueueSnackbar }, [enqueueSnackbar])
  React.useEffect(() => { resetRef.current = reset }, [reset])
  
  React.useEffect(() => {
    if (error === undefined) return;
    showError(`${messageRef.current}:\n${Utils.Error.getErrorMessage(error)}`)
    if (resetRef.current) resetRef.current();
  }, [error, showError]);

  React.useEffect(() => {
    return () => {
      if (resetRef.current) resetRef.current();
    }
  }, []);


  return null;
}

export default ErrorHandler;
