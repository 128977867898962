import { API } from "aws-amplify";
import { CreateUserForm } from "./types";

async function createUser(form: CreateUserForm): Promise<User> {
  try {
    const path: string = '/users';
    const options = {
      body: form
    }
  
    const response = await API.post(
      'api-service',
      path,
      options
    )

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data.error || error.message);
  }
}

export default createUser;
