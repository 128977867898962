import { API } from 'aws-amplify';

async function deleteView(view: UserView): Promise<void> {
  try {
    const path: string = `/grids/${view.id}`;

    await API.del(
      'api-service',
      path,
      {}
    );
  } catch (error: any) {
    throw new Error(error.response?.data.error || error.message);
  }
}

export default deleteView;
