import Tutorial from "./Tutorial";

class GetStartedTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#site-selection-icon',
        popover: {
          title: 'Site selection',
          description: "Select the 'Site' you would like to access here.",
          nextBtnText: "Next",
          position: 'right'
        }
      },
      {
        element: '#events-icon',
        popover: {
          title: 'Events',
          description: "Access the Event Inbox for both real-time and historical viewing of events of interest, in addition to reports of labeled Events.",
          nextBtnText: "Next",
          position: 'right'
        }
      },
      {
        element: '#views-icon',
        popover: {
          title: 'Views',
          description: 'Access all camera views on the selected site.',
          nextBtnText: "Next",
          position: 'right'
        }
      },
      {
        element: '#workers-icon',
        popover: {
          title: 'Workers',
          description: 'Coming soon!',
          nextBtnText: "Next",
          position: 'right'
        }
      },
      {
        element: '#analytics-icon',
        popover: {
          title: 'Analytics',
          description: 'Access heatmaps and graphs for insights to on-site operations for data-driven decision-making.',
          nextBtnText: "Next",
          position: 'right'
        }
      },
      {
        element: '#map-icon',
        popover: {
          title: 'Map',
          description: 'The map is a tool designed to display camera placements across the site in alignment with a satellite image. This allows you to explore camera layouts for enhanced surveillance and strategic monitoring capabilities.',
          nextBtnText: "Next",
          position: 'right'
        }
      },
      {
        element: '#actions-icon',
        popover: {
          title: 'Actions',
          description: 'Actions allows for the configuration of real-time notifications via text message, email, and dashboard for things such as motion detection, PPE infractions, and more.',
          nextBtnText: "Next",
          position: 'right'
        }
      },
      {
        element: '#saved-icon',
        popover: {
          title: 'Saved',
          description: 'Saved videos allows you to access video clips, timelapses clips, and snapshots which have been automatically or manually saved.',
          nextBtnText: "Next",
          position: 'right'
        }
      },
      // {
      //   element: '#health-icon',
      //   popover: {
      //     title: 'Health',
      //     description: 'The health section provides camera and edge device information in addition to the current status for all devices deployed to a given site.',
      //     position: 'upper-right'
      //   }
      // },
      {
        element: '#management-icon',
        popover: {
          title: 'Management',
          description: "Management functions include a list of 'Users' and permissions, the ability to create custom 'Roles' and 'Audit log'.",
          position: 'upper-right'
        }
      }
    ])
  }
}

export default GetStartedTutorial;
