import { Action } from 'redux';
import createReducer, { Handlers } from './createReducer'

export interface IRedux {
  createReducer: <State, Types extends string, Actions extends Action<Types>> (initialState: State, handlers: Handlers<State, Types, Actions>) => (state: State | undefined, action: Actions) => State;
}

const Redux: IRedux = {
  createReducer
}

export default Redux;
