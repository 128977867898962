import React from 'react';
import { Box, Button, MenuItem, Paper, Select, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { KeyboardArrowDown } from '@mui/icons-material';
import { setActivePage } from '@store/session/actions';
import { PageView } from '@store/session/types';
import { setSelectedSite } from '@store/selectedSites/actions';
import styled from 'styled-components';
import SiteThumbnail from './SiteThumbnail';
import TutorialHeaderTitle from '@components/common/TutorialHeaderTitle';
import SearchHeader from '@components/common/SearchHeader';
import Utils from '@utils/index';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';
import { selectIsSuperAdminUser } from '@store/selectors';
import CustomDataGrid from '@components/common/DataGrid/CustomDataGrid';

export const GridBox = styled(Box)`
  .MuiDataGrid-renderingZone{
    cursor: pointer;
  }

  .MuiDataGrid-overlay {
    background-color: white !important;
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-footerContainer {
    min-height: 3.5rem;
  }
`

type SiteView = Site & {
  id: string,
  name: string,
  site: Site,
  location: string,
  cameras: string,
  status?: string
}

const SiteList: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();

  const allSites = useSelector((store: IStoreState) => store.sites.sites);
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const streams = useSelector((state: IStoreState) => state.streams.streams);
  const userData = useSelector((store: IStoreState) => store.session.userData);

  const [siteStatus, setSiteStatus] = React.useState<"ALL" | "ACTIVE" | "COMING_SOON" | "MAINTENENCE">("ALL");
  const [companySites, setCompanySites] = React.useState<Site[]>([]);
  const [companySiteViews, setCompanySiteViews] = React.useState<SiteView[]>([]);
  const [filteredSites, setFilteredSites] = React.useState<SiteView[]>([]);
  const [statusFilteredSites, setStatusFilteredSites] = React.useState<SiteView[]>([]);

  const isSuperAdministrator = useSelector(selectIsSuperAdminUser);

  React.useEffect(() => {
    let tempSites: SiteView[] = filteredSites;

    if (siteStatus === "ALL") {
      tempSites = filteredSites;
    } else if (siteStatus === "ACTIVE") {
      tempSites = filteredSites.filter(el => el.status && el.status === 'Active');
    } else if (siteStatus === "COMING_SOON") {
      tempSites = filteredSites.filter(el => el.status && el.status === 'Coming soon');
    } else if (siteStatus === "MAINTENENCE") {
      tempSites = filteredSites.filter(el => el.status && el.status === 'Maintenence');
    }

    setStatusFilteredSites(tempSites);
  }, [siteStatus, filteredSites])

  React.useEffect(() => {
    const sites = allSites.get(selectedCompany?.companyId || "") || [];
    const siteViews = Utils.Array.orderAlphabetically(sites, 'name').map((site: Site) => ({
      ...site,
      id: site.siteId,
      name: site.name,
      site: site,
      location: `${site.address?.street}, ${site.address?.state}`,
      cameras: `${streams.filter(el => el.siteId === site.siteId && el.state === "ACTIVE").length} cameras`,
      status: site.status
    }))
    setCompanySiteViews(siteViews);
    setCompanySites(sites);
  }, [selectedCompany, allSites, streams])

  function handleAddNewSite(): void {
    dispatch(setSelectedSite(undefined));
    dispatch(setActivePage(PageView.Site));
  }

  const nameRow = {
    field: 'name',
    headerName: 'Name',
    flex: 0.25,
    sortable: true,
    renderCell: (params: any) => (
      <Typography noWrap variant="body2">{params.value}</Typography>
    )
  }

  const locationRow = {
    field: 'location',
    headerName: 'Location',
    minWidth: 200,
    flex: 0.25,
    sortable: true,
    renderCell: (params: any) => (
      <Typography variant="body2">{params.value}</Typography>
    )
  }

  const thumbnailRow = {
    field: 'cameras',
    headerName: 'Cameras',
    flex: 0.25,
    sortable: true,
    renderCell: (params: any) => (
      <Box
        width="100%" display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <SiteThumbnail site={params.row.site as Site} />
          <Typography
            style={{ marginLeft: 6 }}
            variant="body2"
          >
            {params.value}
          </Typography>
        </Box>
        <Button
          style={{ visibility: (isSuperAdministrator || userData?.roles === 'admin') ? 'visible' : 'hidden' }}
          variant="outlined"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setActivePage(PageView.Site));
            dispatch(setSelectedSite(params.row.site));
          }}
        >
          Edit
        </Button>
      </Box>
    ),
    sortComparator: (a: any, b: any) => {
      if (a && b) {
        const aNumber = +a.toString().split(' ')[0]
        const bNumber = +b.toString().split(' ')[0]
        return aNumber - bNumber;
      }

      return 1;
    }
  }

  const textFilterProps = React.useMemo(() => ["name", "location"], [])

  return (
    <Box display="grid" gridTemplateRows="min-content auto" width="100%" height="100%" padding="1.5rem" gap="2rem">
      <SearchHeader
        title={<TutorialHeaderTitle tutorialType="overview_site_list" title="Site list" />}
        noPadding
        textFiltering={true}
        textFilterProps={textFilterProps}
        searchHeaderFilterProps={{
          onFilteredDataChange: (data: any[]) => { setFilteredSites(data) },
          data: companySiteViews,
          properties: Utils.Filters.createSitesFilters(isSuperAdministrator || false, companySites)
        }}
      >
        <Paper elevation={0}>
          <Select
            id="overview-site-list-status-dropdown"
            variant="outlined"
            style={{ width: '9rem' }}
            value={siteStatus}
            onChange={(e: any) => { setSiteStatus(e.target.value) }}
            IconComponent={KeyboardArrowDown}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              }
            }}
          >
            <MenuItem value="ALL" data-cy={`all-sites-menu-item`} ><Typography variant="button" >All</Typography></MenuItem>
            <MenuItem value="ACTIVE" data-cy={`active-sites-menu-item`} ><Typography variant="button" >Active</Typography></MenuItem>
            <MenuItem value="COMING_SOON" data-cy={`coming-soon-menu-item`} ><Typography variant="button" >Coming soon</Typography></MenuItem>
            <MenuItem value="MAINTENENCE" data-cy={`maintenence-menu-item`} ><Typography variant="button" >Maintenence</Typography></MenuItem>
          </Select>
        </Paper>
        {Boolean(isSuperAdministrator) &&
          <Button
            onClick={handleAddNewSite}
            variant="contained"
            data-cy="overview-add-site-button"
            color="primary"
          >
            Add site
          </Button>
        }
      </SearchHeader>
      <CustomDataGrid
        dataCy="overview-site-list"
        defaultHeight='82vh'
        hideHeaders={false}
        disableColumnFilter
        disableRowSelectionOnClick
        onCellClick={(e) => {
          dispatch(setSelectedSite(e.row.site));
        }}
        rows={statusFilteredSites}
        columns={isMobile || isSmallScreen ? [
          nameRow,
          { ...thumbnailRow, flex: 0.50 }
        ] : [
          nameRow,
          locationRow,
          thumbnailRow
        ]}
      />
    </Box>
  )
}

export default SiteList;
