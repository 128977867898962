import { PrometheusQuery } from "./PrometheusQuery";

export class NvidiaUsageGPU extends PrometheusQuery {
  protected _id: string = "nvidia_gpu_duty_cycle";

  public override getPossibleValues() {
    return [
      { id: "Nvidia - GPU usage", color: "#0048e3" }
    ]
  };
  
  public override getRefinedData() {
    const refinedData = [];

    if (this.isEmpty()) return [];
    
    for (let i = 0; i < this._queryData.data.result[0].values.length; i += 1) {
      refinedData.push({
        date: this.formatDate(this._queryData.data.result[0].values[i][0]),
        "Nvidia - GPU usage": this._queryData.data.result[0].values[i][1]
      })
    }

    return refinedData;
  }

  public override getDomain() {
    if (this.isEmpty()) return [0, 100]
    return undefined;
  }

  public override getMiddleValue() {
    return 50;
  }

  public override getValueLabel() {
    return "%";
  }
}