import Utils from '@utils/index';
import { findIndex, first, isArray, isEqual, orderBy } from "lodash";
import moment from 'moment';

class StreamEventsMerger {
  private _events: VideoStreamEvent[] = [];
  private _scanEntireList: boolean = true;

  constructor (events: VideoStreamEvent[], scanEntireList: boolean = true) {
    this._events = orderBy(events, ['startDate', 'sourceCamera'], 'asc');
    this._scanEntireList = scanEntireList;
  }

  public merge(mergeZoneEvents?: boolean, mergeSiteEvents?: boolean): VideoStreamEvent[] {
    if (mergeZoneEvents === true) this.mergeZoneTriggered();
    if (mergeSiteEvents === true) this.mergeFOVEvents();

    return this._events;
  }

  private mergeFOVEvents(): void {
    const start = this.getStartIndex();

    for (let i = start; i < this._events.length; i += 1) {
      const parent = this._events[i];
      if (this.shouldSkipEventForFOVEvaluation(parent)) continue;

      for (let j = i + 1; j < this._events.length; j += 1) {
        const child = this._events[j];

        if (!this.isSameTab(parent, child)) continue;
        if (!this.isSameTag(parent, child)) continue;
        if (!this.isSameOwner(parent, child)) continue;
        if (!this.isEventsOnSameSite(parent, child)) continue;
        if (!this.isEventsOfTheSameType(parent, child)) continue;
        if (this.shouldSkipEventForFOVEvaluation(child)) continue;

        if (this.isChildStartGreaterThanParentEnd(parent, child)) break;

        if (this.isChildWithinTimeBoundsOfParent(parent, child)) {
          if (this.mergeFOVChildIntoParent(i, j)) j--
        }
      }
    }
  }

  private mergeZoneTriggered(): void {
    const start = this.getStartIndex();

    for (let i = start; i < this._events.length; i += 1) {
      const parent = this._events[i];
      if (this.shouldSkipEventForZoneEvaluation(parent)) continue;

      for (let j = i + 1; j < this._events.length; j += 1) {
        const child = this._events[j];

        if (!this.isSameTab(parent, child)) continue;
        if (!this.isSameTag(parent, child)) continue;
        if (!this.isSameOwner(parent, child)) continue;
        if (this.isChildStartGreaterThanTwoMinutes(parent, child)) break;
        if (!this.isEventsOnTheSameCamera(parent, child)) continue;
        if (this.shouldSkipEventForZoneEvaluation(child)) continue;
        if (this.isChildEndLessThanTwoMinutesFromParentStart(parent, child)) continue;

        if (this.isChildWithinParentThreholdToMerge(parent, child)) {
          if (this.mergeZoneChildIntoParent(i, j)) j--;
        }
      }
    }
  }

  private mergeFOVChildIntoParent(parentIndex: number, childIndex: number): boolean {
    const deleted = first(this._events.splice(childIndex, 1));
    if (deleted) {
      if (this._events[parentIndex].children) {
        if (!this._events.find(el => deleted.id === el.id)) this._events[parentIndex].children?.push(deleted)
      } else {
        this._events[parentIndex].children = [deleted]
      }

      if (this.isGeofenceTriggered(this._events[parentIndex]) && this.isGeofenceTriggered(deleted)) {
        const geofenceIds: Set<string> = new Set<string>();
        if (isArray(this._events[parentIndex].zoneId)) {
          this._events[parentIndex].zoneId?.forEach(id => geofenceIds.add(id));
        }

        if (isArray(deleted.zoneId)) {
          deleted.zoneId.forEach(id => geofenceIds.add(id))
        }

        this._events[parentIndex].zoneId = Array.from(geofenceIds);
      }

      return true;
    }

    return false;
  }

  private mergeZoneChildIntoParent(parentIndex: number, childIndex: number): boolean {
    const deleted = first(this._events.splice(childIndex, 1));
    if (deleted) {
      this._events[parentIndex].mergedEndDate = deleted.endDate;
      const set = new Set();

      this._events[parentIndex].zoneId?.forEach(el => set.add(el));
      deleted.zoneId?.forEach((el) => set.add(el));

      this._events[parentIndex].zoneId = Array.from(set) as string[];

      if (this._events[parentIndex].children) {
        const index = findIndex(this._events[parentIndex].children, { id: deleted.id })
        if (index === -1) this._events[parentIndex].children?.push(deleted)
      } else {
        this._events[parentIndex].children = [deleted]
      }

      return true;
    }
    
    return false;
  }

  private isChildWithinParentThreholdToMerge(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    const THRESHOLD = 1000 * 15;
    return child.endDate < parent.endDate + THRESHOLD;
  }

  private isChildStartGreaterThanParentEnd(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    const parentEndDate = parent.endDate;
    const childStartDate = Utils.Timestamp.edgeTimestampToMiliseconds(child.startDate);

    if (childStartDate > parentEndDate) return true;
    return false;
  }

  private isChildWithinTimeBoundsOfParent(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    const iEndDate = parent.endDate;
    const iStartDate = Utils.Timestamp.edgeTimestampToMiliseconds(parent.startDate);
    const jEndDate = child.endDate;
    const jStartDate = Utils.Timestamp.edgeTimestampToMiliseconds(child.startDate);

    return iStartDate < jStartDate && iEndDate > jEndDate;
  }

  private isChildEndLessThanTwoMinutesFromParentStart(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    const diff = child.endDate - Utils.Timestamp.edgeTimestampToMiliseconds(parent.startDate);
    return diff > 120000;
  }

  private isChildStartGreaterThanTwoMinutes(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    const jStart = Utils.Timestamp.edgeTimestampToMiliseconds(child.startDate);
    const iStart = Utils.Timestamp.edgeTimestampToMiliseconds(parent.startDate)
    const startDiff = jStart - iStart;
    return startDiff > 120000;
  }

  private shouldSkipEventForZoneEvaluation(event: VideoStreamEvent): boolean {
    if (!this.isGeofenceTriggered(event)) return true;
    if (this.isEventOnGoing(event)) return true;
    if (this.isEventSplitByUser(event)) return true;
    return false;
  }

  private shouldSkipEventForFOVEvaluation(event: VideoStreamEvent): boolean {
    if (this.isEventOnGoing(event)) return true;
    if (this.isEventSplitByUser(event)) return true;
    return false;
  }

  private getStartIndex(): number {
    let start = 0;

    if (this._scanEntireList === false) {
      const target = this._events.find(el => (moment().valueOf() - Utils.Timestamp.edgeTimestampToMiliseconds(el.startDate)) <= 240000)
      if (target) start = findIndex(this._events, { id: target.id });
    }
    return start;
  }

  private isGeofenceTriggered(event: VideoStreamEvent): boolean {
    return event.eventType === "Geofence triggered";
  }

  private isEventOnGoing(event: VideoStreamEvent): boolean {
    return event.ongoing === true;
  }

  private isEventSplitByUser(event: VideoStreamEvent): boolean {
    return event.splitByUser === true;
  }

  private isEventsOnSameSite(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    return parent.siteId === child.siteId;
  }

  private isEventsOfTheSameType(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    return parent.eventType === child.eventType;
  }

  private isEventsOnTheSameCamera(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    return parent.sourceCamera === child.sourceCamera;
  }

  private isSameTag(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    return isEqual(parent.tags, child.tags);
  }

  private isSameOwner(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    return isEqual(parent.owner, child.owner)
  }

  private isSameTab(parent: VideoStreamEvent, child: VideoStreamEvent): boolean {
    return isEqual(parent.tab, child.tab);
  }
}

export default StreamEventsMerger;
