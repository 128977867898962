import { CloudWatch } from "aws-sdk";

function getSnapshotGraph(
  options: CloudWatch.ClientConfiguration,
  stacked: Boolean,
  metrics: string[][],
  width: number,
  height: number,
  start: string,
  end: string,
  title?: string
)
: Promise<CloudWatch.GetMetricWidgetImageOutput> 
{

  const cloudWatch = new CloudWatch(options);

  return new Promise((resolve, reject) => {
    var widget = { 
      "metrics": metrics,
      "view": "timeSeries", 
      "stacked": stacked,  
      "width": width, 
      "height": height, 
      "start": start, 
      "end": end, 
      "title": title || " "
      };
    var params = {
      OutputFormat: 'png',
      MetricWidget: JSON.stringify(widget)
    };

    cloudWatch.getMetricWidgetImage(params, function(err, data) {
      if (err) {
        return reject(err);
      }

      if (data) {
        resolve(data);
      }
    });
  });
}

export default getSnapshotGraph;
