import { CPUUtilizationQuery } from "@classes/prometheus/CPUUtilizationQuery";
import { PrometheusQuery } from "@classes/prometheus/PrometheusQuery";
import { ICredentials } from "@store/session/types";
import { RAMUtilizationQuery } from "@classes/prometheus/RAMUtilizationQuery";
import { JetsonTemperatureC } from "@classes/prometheus/JetsonTemperatureC";
import { SwapUtilizationQuery } from "@classes/prometheus/SwapUtilizationQuery";
import { JetsonFanSpeed } from "@classes/prometheus/JetsonFanSpeed";
import { JetsonUsageGPU } from "@classes/prometheus/JetsonUsageGPU";
import { JetsonUsagePowerMW } from "@classes/prometheus/JetsonUsagePowerMWQuery";
import { JetsonUsageRAMKbQuery } from "@classes/prometheus/JetsonUsageRAMKbQuery";
import { NodeBootTimeSecondsQuery } from "@classes/prometheus/NodeBootTimeSecondsQuery";
import { RootDiskUtilizationQuery } from "@classes/prometheus/RootDiskUtilizationQuery";
import { DataDiskUtilizationQuery } from "@classes/prometheus/DataDiskUtilizationQuery";
import { NodeMemorySwapTotalBytesQuery } from "@classes/prometheus/NodeMemorySwapTotalBytesQuery";
import { NodeNetworkReceiveBytesTotalQuery } from "@classes/prometheus/NodeNetworkReceiveBytesTotalQuery";
import { NodeNetworkTransmitBytesTotalQuery } from "@classes/prometheus/NodeNetworkTransmitBytesTotalQuery";
import { API } from "aws-amplify";
import { NvidiaUsageGPU } from "@classes/prometheus/NvidiaUsageGPU";
import { NvidiaUsageRAMQuery } from "@classes/prometheus/NvidiaUsageRAMQuery";
import { NvidiaFanSpeed } from "@classes/prometheus/NvidiaFanSpeed";
import { NvidiaTemperatureC } from "@classes/prometheus/NvidiaTemperatureC";
import { NvidiaUsagePowerMWQuery } from "@classes/prometheus/NvidiaUsagePowerMWQuery";
import { InferenceRequestSuccess } from "@classes/prometheus/InferenceRequestSuccess";
import { InferenceRequestFailure } from "@classes/prometheus/InferenceRequestFailure";

export async function getMetric(companyId: string, query: string, metric: string, from: Date, to: Date, credentials: ICredentials): Promise<PrometheusQuery> {
  try {
    let path: string = '';

    if (metric === "calculated:edge_inference_request_success_total" || metric === "calculated:edge_inference_request_failure_total") {
      path = `/devices/metrics/${companyId}?query=${encodeURI(query)}&start=${from.getTime() / 1000}&end=${to.getTime() / 1000}&step=${60}&type=range`;
    } else {
      path = `/devices/metrics/${companyId}?query=${encodeURI(query)}&start=${from.getTime() / 1000}&end=${to.getTime() / 1000}&step=${120}&type=range`;
    }

    const response = await API.get('api-service', path, {});

    switch (metric) {
      case "calculated:cpu_utilization":
        return new CPUUtilizationQuery(response)
      case "calculated:ram_utilization":
        return new RAMUtilizationQuery(response)
      case "jetson_temperature_C":
        return new JetsonTemperatureC(response);
      case "calculated:swap_utilization":
        return new SwapUtilizationQuery(response)
      case "jetson_fan_speed":
        return new JetsonFanSpeed(response);
      case "jetson_usage_gpu":
        return new JetsonUsageGPU(response)
      case "jetson_usage_power_mW":
        return new JetsonUsagePowerMW(response);
      case "jetson_usage_ram_kb":
        return new JetsonUsageRAMKbQuery(response)
      case "node_boot_time_seconds":
        return new NodeBootTimeSecondsQuery(response);
      case "calculated:filesystem_utilization_root":
        return new RootDiskUtilizationQuery(response);
      case "calculated:filesystem_utilization_data":
        return new DataDiskUtilizationQuery(response);
      case "node_memory_SwapTotal_bytes":
        return new NodeMemorySwapTotalBytesQuery(response);
      case "node_network_receive_bytes_total":
        return new NodeNetworkReceiveBytesTotalQuery(response);
      case "node_network_transmit_bytes_total":
        return new NodeNetworkTransmitBytesTotalQuery(response);
      case "nvidia_gpu_duty_cycle":
        return new NvidiaUsageGPU(response);
      case "calculated:gpu_ram_utilization":
        return new NvidiaUsageRAMQuery(response);
      case "nvidia_gpu_fanspeed_percent":
        return new NvidiaFanSpeed(response);
      case "nvidia_gpu_temperature_celsius":
        return new NvidiaTemperatureC(response)
      case "nvidia_gpu_power_usage_milliwatts":
        return new NvidiaUsagePowerMWQuery(response);
      case "calculated:edge_inference_request_success_total":
        return new InferenceRequestSuccess(response)
      case "calculated:edge_inference_request_failure_total":
        return new InferenceRequestFailure(response)

      default:
        return new CPUUtilizationQuery(response)
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}