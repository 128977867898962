import { BugReport } from "@mui/icons-material";
import { Typography } from "@mui/material";
import EventChange from "./EventChange";

class UnknownChange extends EventChange {
  public getIconElement(): JSX.Element {
    return <BugReport fontSize="small" />
  }
  public getTextElement(): JSX.Element {
    return <Typography variant="body2">Unknown</Typography>
  }

}

export default UnknownChange;
