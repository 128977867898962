import { Auth } from "aws-amplify";

async function disableMFA(): Promise<void> {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.setPreferredMFA(currentUser, "NOMFA");
  } catch (error: any) {
    throw error;
  }
}

export default disableMFA;
