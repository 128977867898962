import { authenticateUser, clearUser, setSuperAdministrator, setUserData } from '@store/session/actions';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useDispatch } from 'react-redux';
import API from '@API/index';
import { UserRole } from '@store/users/types';
import { Dispatch } from 'redux';
import { setErrorLoadingUserData } from '@store/views/appView/actions';

/**
 * Load current session of the user, using AWS SDK Auth class.
 * Responsabilities:
 * - Load credentials from AWS SDK Auth Class and dispatch them to Redux Store.
 * - Load User Data from Dashboard Backend and dispatch them to the Redux Store.
 * @returns boolean - returns false when loading is done.
 */
function useLoadSession(isAuthenticaded: boolean) {
  const dispatch = useDispatch();

  const [isAuthenticating, setAuthenticating] = React.useState(true);

  React.useEffect(() => {
    performAuthentication(setAuthenticating, dispatch)
  }, [dispatch, isAuthenticaded])

  return isAuthenticating;
}

export async function performAuthentication(setAuthenticating: (value: boolean) => void, dispatcher: Dispatch<any>, onError?: () => void) {
  // if userId is already set on window, this means the user is already loaded.
  if (window.userId !== undefined) return;

  try {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    const credentials = await Auth.currentCredentials();
    try {
      const userData = await API.Users.getUserData(credentials.identityId) as User;
      
      // Setting a global variable with userId for Google Analytics.
      if (window.dataLayer && Boolean(window.userId) === false) {
        window.dataLayer.push({
          'event': 'IdAvailable',
          'userId': userData.userId,
          'companyId': userData.companyId
        });
        window.userId = userData.userId;
      }

      dispatcher(setUserData(userData));
      dispatcher(setSuperAdministrator(userData.roles === UserRole.SuperAdmin));
      const creds = Auth.essentialCredentials(credentials);
      dispatcher(authenticateUser(currentAuthenticatedUser, creds));
    } catch (e) {
      dispatcher(setErrorLoadingUserData(e));
      throw e;
    }
    setTimeout(() => {
      setAuthenticating(false);
    }, 20)
  } catch (e) {
    window.userId = undefined;
    dispatcher(clearUser());
    await Auth.signOut();
    setTimeout(() => {
      setAuthenticating(false);
    }, 20)
    if (onError) onError();
  }
}

export default useLoadSession;
