import { API } from "aws-amplify";

async function listCertificates(workerId: number, asCompany?: string): Promise<Certificate[]> {
  try {
    const path: string = `/certificates/${workerId}`+ (asCompany ? `?asCompany=${asCompany}` : "");

    const data = await API.get(
      'api-service',
      path,
      {}
    )

    if (data.data) {
      return data.data as Certificate[];
    }
    
    return [];
  } catch (error) {
    throw error;
  }
}

export default listCertificates;
