import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Collapse, Divider, FormControl, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Delete, ExpandMore } from '@mui/icons-material';
import { IStoreState } from '@store/index';
import { useDispatch, useSelector } from 'react-redux';
import API from '@API/index';
import { setScheduleTemplates } from '@store/scheduleTemplates/actions';
import { useSnackbar } from 'notistack';
import DayTimeRangeInput from './DayTimeRangeInput';
import { DayLabel } from '@declarations/enums';
import CustomInputLabel from '../Input/CustomInputLabel';
import ConfirmationDialog from '../ConfirmationDialog';
import Utils from '@utils/index';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useVerySmallScreenQuery } from '@hooks/useVerySmallScreenQuery';

interface IScheduleInputProps {
  value: Record<DayLabel, Array<ActiveDayTime>>;
  onChange: (value: Record<DayLabel, Array<ActiveDayTime>>) => void;
  fullWidth?: boolean;
  outlined?: boolean;
}

const ScheduleInputContent: React.FC<IScheduleInputProps> = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { value, onChange } = props;

  const isMobile = useMobileQuery();
  const isVerySmallScreen = useVerySmallScreenQuery();

  const scheduleTemplates = useSelector((state: IStoreState) => state.scheduleTemplates.scheduleTemplates);
  const companyId = useSelector((state: IStoreState) => state.selectedCompanies.selectedCompany?.companyId);
  const siteId = useSelector((state: IStoreState) => state.selectedSites.selectedSite?.siteId);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [activeTime, setActiveDayTime] = useState<Record<DayLabel, Array<ActiveDayTime>>>();
  const [selectedScheduleTemplate, setSelectedScheduleTemplate] = useState<ScheduleTemplate>();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [newScheduleTemplateName, setNewScheduleTemplateName] = useState<string>();
  const [newScheduleTemplateNameError, setNewScheduleTemplateNameError] = useState<string>();
  const [selectOpen, setSelectOpen] = useState(false);
  const [scheduleTemplateToDelete, setScheduleTemplateToDelete] = useState<ScheduleTemplate>();
  const [scheduleTemplateToUpdate, setScheduleTemplateToUpdate] = useState<ScheduleTemplate | undefined>(undefined);

  const valueRef = React.useRef(value);

  useEffect(() => { valueRef.current = value }, [value])
  useEffect(() => {
    setActiveDayTime({ ...valueRef.current })
  }, []);

  function onScheduleTemplateSelected(e: any) {
    const scheduleTemplate = scheduleTemplates.find(st => st.id === e.target.value);
    if (scheduleTemplate) {
      setSelectedScheduleTemplate(scheduleTemplate);
      setActiveDayTime(scheduleTemplate?.value);
    }
  }

  function saveScheduleTemplate() {
    if (newScheduleTemplateName && scheduleTemplates.some(st => st.name === newScheduleTemplateName)) {
      if (scheduleTemplateToUpdate === undefined) {
        setScheduleTemplateToUpdate(scheduleTemplates.find(el => el.name === newScheduleTemplateName));
        return;
      }
    }

    if (companyId && siteId && newScheduleTemplateName && activeTime) {
      const algorithm = scheduleTemplateToUpdate ? API.ScheduleTemplates.updateScheduleTemplate : API.ScheduleTemplates.createScheduleTemplate;
      setSaveLoading(true)
      algorithm({
        siteId: siteId,
        name: newScheduleTemplateName,
        value: activeTime,
        id: scheduleTemplateToUpdate ? scheduleTemplateToUpdate.id : "",
        editable: true
      })
        .then(() => {
          return API.ScheduleTemplates.listScheduleTemplates(siteId || "");
        })
        .then((templates) => {
          dispatch(setScheduleTemplates(templates));
        }).then(() => {
          if (scheduleTemplateToUpdate) enqueueSnackbar("New schedule saved!", { variant: "success" });
          else enqueueSnackbar("Schedule saved!", { variant: "success" });
        })
        .catch((e) => enqueueSnackbar("Error on schedule save", { variant: "error" }))
        .finally(() => {
          setSaveLoading(false);
          setScheduleTemplateToUpdate(undefined);
        })
    }
    else
      setNewScheduleTemplateNameError("Schedule name or value missing");
  }

  function onDayTimeRangeChange(day: DayLabel, timeRange?: ActiveDayTime, interval?: number) {
    if (activeTime) {
      const tempActiveTime: Record<DayLabel, Array<ActiveDayTime>> = { ...activeTime };
      if (interval !== undefined) {
        if (timeRange) {
          if (tempActiveTime[day] === undefined)
            tempActiveTime[day] = [];
          tempActiveTime[day][interval] = timeRange;
        } else {
          if (interval === 0) {
            delete tempActiveTime[day];
          }
          else {
            tempActiveTime[day].splice(interval, 1);
          }
        }
      }
      setActiveDayTime(tempActiveTime);
    }
  }

  function applyChanges() {
    if (activeTime)
      onChange(activeTime);
  }

  function getTimRangeInputValue(day: DayLabel, interval: number) {
    return activeTime && activeTime[day] ? activeTime[day][interval] : undefined;
  }

  function getShortWeekDayNames(scheduleTemplate: ScheduleTemplate) {
    return Object.keys(DayLabel)
      .filter(d =>
        Object.keys(scheduleTemplate.value).includes(d))
      .map(dl =>
        dl.slice(0, 3))
      .join(", ")
  }

  function onDeleteClick(e: any, scheduleTemplateId: string) {
    e.stopPropagation();
    e.preventDefault();
    setScheduleTemplateToDelete(scheduleTemplates.find(st => st.id === scheduleTemplateId));
  }

  function onDeleteConfirmation() {
    if (scheduleTemplateToDelete) {
      setLoading(true);
      setSelectOpen(false);
      API.ScheduleTemplates.deleteScheduleTemplate(siteId || "", scheduleTemplateToDelete.id)
        .then(() => {
          return API.ScheduleTemplates.listScheduleTemplates(siteId || "");
        })
        .then((templates) => {
          dispatch(setScheduleTemplates(templates));
          enqueueSnackbar("Schedule template deleted!", { variant: "success" })
        })
        .catch(() =>
          enqueueSnackbar("Could not delete schedule template!", { variant: "error" })
        ).finally(() => {
          setLoading(false);
          setScheduleTemplateToDelete(undefined);
        })
    }
  }

  function onDeleteCancel() {
    setScheduleTemplateToDelete(undefined);
  }


  return <>
    <Box display="grid" gap="1.5rem" padding="1.5rem">
      <Select
        fullWidth
        variant="outlined"
        value={selectedScheduleTemplate?.id || "placeholder"}
        onChange={onScheduleTemplateSelected}
        renderValue={() => {
          if (loading)
            return <CircularProgress size={"1.5rem"} />
          return selectedScheduleTemplate ?
            <>{selectedScheduleTemplate.name}</> :
            <Typography color='textSecondary'>Autofill saved schedule</Typography>
        }}
        open={selectOpen}
        disabled={loading}
        onOpen={() => setSelectOpen(true)}
        onClose={() => setSelectOpen(false)}
        endAdornment={<InputAdornment position="end" >
          <ExpandMore />
        </InputAdornment>}
        MenuProps={{
          style: {
            overflow: 'auto',
            maxHeight: '50vh',
          }
        }}
      >
        <MenuItem value={"placeholder"} style={{ height: 0, padding: 0 }} data-cy={`schedule-placeholder-menu-item`} ></MenuItem>
        {scheduleTemplates.length > 0 ?
          Utils.Array.orderAlphabetically(scheduleTemplates, 'name').map((st: ScheduleTemplate) =>
            <MenuItem value={st.id} key={st.id}>
              <Box display="grid" gridTemplateColumns="auto min-content" width="100%">
                <Typography>{`${st.name} (${getShortWeekDayNames(st)})`}</Typography>
                {st.editable &&
                  <Delete onClick={(e) => onDeleteClick(e, st.id)} />}
              </Box>
            </MenuItem>) :
          <Typography
            color='textSecondary'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            style={{ paddingLeft: "1rem" }}>No templates</Typography>}
      </Select >
      <Box data-cy="schedule-input-table" display="grid" gap="0.5rem">
        {Object.keys(DayLabel).map(dl => {
          const previousInterval = getTimRangeInputValue(dl as DayLabel, 0);
          return (
            <Box
              display="grid"
              gridTemplateColumns={isMobile === false && isVerySmallScreen === false ? "max-content max-content max-content" : undefined}
              gridTemplateRows={(isMobile === true || isVerySmallScreen === true) ? "max-content max-content max-content" : undefined}
              justifyContent="center"
              alignItems="center"
              data-cy={`schedule-input-day-${dl}-row`}
              key={dl}
            >
              <Typography style={{ width: "3rem" }}>{dl.slice(0, 3)}</Typography>
              <DayTimeRangeInput
                key={`${dl}-1`}
                value={getTimRangeInputValue(dl as DayLabel, 0)}
                interval={0}
                day={dl as DayLabel}
                onChange={onDayTimeRangeChange}
              />
              <DayTimeRangeInput
                mustStartAfter={previousInterval?.to}
                key={`${dl}-2`}
                value={getTimRangeInputValue(dl as DayLabel, 1)}
                day={dl as DayLabel}
                interval={1}
                onChange={onDayTimeRangeChange}
                disabled={!!!previousInterval || previousInterval?.to === "23:59"}
              />
            </Box>
          )
        })}
      </Box>
      <Button
        color="primary"
        fullWidth
        variant='contained'
        onClick={applyChanges}
        disabled={activeTime ? Object.keys(activeTime).length === 0 : false}
        data-cy="schedule-input-apply-button"
      >
        Apply
      </Button>
      <Divider />
      <Box display="grid" gap="0.5rem">
        <Box
          display="grid"
          gap="1.5rem"
          gridTemplateColumns="auto min-content"
          style={{ cursor: "pointer" }}
          onClick={() => setCollapseOpen(o => !o)}>
          <Typography>Save schedule</Typography>
          <ExpandMore />
        </Box>
        <Collapse in={collapseOpen}>
          <FormControl fullWidth required>
            <CustomInputLabel id="new-schedule-name">Schedule name</CustomInputLabel>
            <Box display="grid" gap="1.5rem" gridTemplateColumns="auto min-content">
              <TextField
                variant='outlined'
                placeholder="Set new schedule name"
                onChange={(e) => { setNewScheduleTemplateName(e.target.value); setNewScheduleTemplateNameError(undefined); }}
                error={!!newScheduleTemplateNameError}
                helperText={newScheduleTemplateNameError}
                style={{ marginTop: 0 }}
                inputProps={{
                  maxLength: 30
                }}
                data-cy="schedule-name-input"
              />
              <Button
                variant='outlined'
                onClick={saveScheduleTemplate}
                disabled={saveLoading}
                data-cy="schedule-save-button"
              >{saveLoading ? <CircularProgress size="1.5rem" /> : "Save"}</Button>
            </Box>
          </FormControl>
        </Collapse>
      </Box>
    </Box>
    <ConfirmationDialog
      title={"Delete template"}
      description={<span>Are you sure you want to delete <b>{scheduleTemplateToDelete?.name}</b>, template?</span>}
      open={!!scheduleTemplateToDelete}
      loading={loading}
      onConfirm={onDeleteConfirmation}
      onCancel={onDeleteCancel}
      confirmLabel="Delete"
    />
    <ConfirmationDialog
      title={"Update schedule"}
      description={<span>Are you sure you want to update <b>{scheduleTemplateToUpdate?.name}</b>, template?</span>}
      open={Boolean(scheduleTemplateToUpdate)}
      loading={saveLoading}
      onConfirm={saveScheduleTemplate}
      onCancel={() => setScheduleTemplateToUpdate(undefined)}
      confirmLabel="Update"
    />
  </>;
}

export default ScheduleInputContent;