import React from 'react';
import { NavBar } from '@components/common/NavBar/NavBar';
import Utils from '@utils/index';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';

type NavBarProps = {
  onCreateViewClick: () => void;
  siteViews: UserView[];
  onViewSelected: (view?: UserView) => void;
  selectedView?: UserView;
  selectedSite?: Site;
  variant?: "scrollable" | "standard" | "fullWidth",
  disableHighlight?: boolean
  onViewAuxClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => void;
}

export const ViewsNavBar: React.FC<NavBarProps> = (props) => {
  const { onCreateViewClick, siteViews, onViewSelected, selectedView, selectedSite, variant, disableHighlight, onViewAuxClick } = props;

  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();

  const tabs = [
    {
      value: "All cameras",
      onClick: () => { onViewSelected(undefined); },
      label: "Camera search",
      onAuxClick: onViewAuxClick
    },
    ...Utils.Array.orderAlphabetically(siteViews, 'name').map(view => ({
      value: view.id,
      onClick: () => { onViewSelected(view); },
      label: view.name,
      onAuxClick: onViewAuxClick
    })),
    {
      value: "+ Create view",
      onClick: onCreateViewClick,
      label: "+ Create view",
      disabled: selectedSite === undefined,
      dataCy: "button-create-view",
      onAuxClick: onViewAuxClick
    }
  ]

  const mobileTabs = [
    {
      value: "All cameras",
      onClick: () => { onViewSelected(undefined); },
      label: "Camera search",
      onAuxClick: onViewAuxClick
    }
  ]

  return (
    <NavBar
      title="Views"
      selectedValue={disableHighlight === true ? false : selectedView ? selectedView.id : "All cameras"}
      tabs={isMobile || isSmallScreen ? mobileTabs : tabs}
      variant={variant}
    />
  )
}
