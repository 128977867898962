import React from 'react';
import { ICredentials } from '@store/session/types';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import Utils from '@utils/index';
import {
  ChevronLeft,
  ChevronRight,
  Fullscreen,
  SatelliteAlt,
  SkipNext,
  SkipPrevious,
} from '@mui/icons-material';
import { StreamStatus } from '@components/common/StreamStatus';
import { VideoOptionsMenu } from './VideoOptionsMenu';
import { VideoVisibilityMenu } from './VideoVisibilityMenu';
import { PTZControlButton } from './PTZControlButton';
import { PlayPauseButton } from './PlayPauseButton';
import { PlaybackRate } from './PlaybackRate';
import KinesisPlayer, { IKinesisPlayerHandles } from '@components/common/KinesisPlayer/KinesisPlayer';
import { VideoDate } from './VideoDate';
import { ZoomSlider } from '../../common/Slider/ZoomSlider';
import PTZControl from './PTZControl';
import { ZonesCanvasProps } from '@components/common/KinesisPlayer/ZonesCanvas';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { setSelectedStreamLive } from '@store/selectedStream/actions';
import TutorialButton from '@components/common/TutorialButton';
import { SnapshotDialogButton } from './SnapshotDialogButton';
import { PlayerAPI } from 'bitmovin-player';
import XRayCompareDialogButton from './XRayCompareDialogButton';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';
import { selectIsSuperAdminUser } from '@store/selectors';
import PlayerVolume from './PlayerVolume';
import ViewFeedback from './ViewFeedback';
import StrobeButton from './StrobeButton';

enum PlayerType {
  KVS = "KVS",
  PTZ = "PTZ"
}

type VideoPlayerProps = {
  stream?: VideoStream;
  autoplay?: boolean;
  credentials?: ICredentials;
  timezone?: string;
  onVideoTimestampChange?: (timestamp: number) => void;
  events: VideoStreamEvent[];
  onTimelineAnimation?: (timestamp: number) => void;
  areaSelectorActive?: boolean;
  onAreaSelectionChange?: (params?: Rectangle) => void;
  areaSelection?: Rectangle;
  initialTimestamp?: number;
  onDownloadFootage: () => void;
  downloadingFootage: boolean;
  timelineTimeRange: { start: Date, end: Date };
  zonesFeature?: ZonesCanvasProps;
  features?: StreamFeatures;
  onEditStream?: () => void;
  onArchiveStream?: () => void;
  onActivateStream?: () => void;
  onDeleteStream?: () => void;
  details?: boolean;
  onNextStreamClick: () => void;
  onPrevioustStreamClick: () => void;
  disableNextStream: boolean;
  disablePreviousStream: boolean;
  uptime: VideoStreamTimeChunk[];
  onActivateStrobes?: () => void;
} & React.VideoHTMLAttributes<HTMLVideoElement>

export interface IVideoPlayerHandles {
  goToTime: (time: number) => void;
  goLive: () => void;
  setHoveredZone: (zone: Zone) => void;
  clearHoveredZone: () => void;
  isVideoLive: () => boolean;
}

const VideoPlayer: React.ForwardRefRenderFunction<IVideoPlayerHandles, VideoPlayerProps> = (props, ref) => {
  const {
    stream,
    credentials,
    timezone,
    onVideoTimestampChange,
    events,
    onTimelineAnimation,
    areaSelectorActive,
    onAreaSelectionChange,
    areaSelection,
    initialTimestamp,
    zonesFeature,
    onEditStream,
    onArchiveStream,
    onDeleteStream,
    onActivateStream,
    details,
    features,
    onNextStreamClick,
    onPrevioustStreamClick,
    disablePreviousStream,
    disableNextStream,
    uptime,
    onActivateStrobes
  } = props;

  const dispatch = useDispatch();

  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();

  const initialPlayer = useSelector((store: IStoreState) => store.selectedStream.playerType);
  const isSuperAdministrator = useSelector(selectIsSuperAdminUser);
  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);

  const [boundingBoxesVisible, setBoundingBoxesVisible] = React.useState<boolean>(true);
  const [poseEstimationVisible, setPoseEstimationVisible] = React.useState<boolean>(true);
  const [minimapVisible, setMinimapVisible] = React.useState<boolean>(true);
  const [playerType, setPlayerType] = React.useState<PlayerType>(initialPlayer === "KVS" ? PlayerType.KVS : PlayerType.PTZ);
  const [isLive, setLive] = React.useState<boolean>(initialTimestamp !== undefined ? false : true);
  const [paused, setPaused] = React.useState<boolean>(false);
  const [playbackRate, setPlaybackRate] = React.useState<number>(1);
  const [volume, setVolume] = React.useState<number>(0);
  const [timestamp, setTimestamp] = React.useState<number>(Date.now());
  const [videoZoom, setVideoZoom] = React.useState<number>(1);
  const [hoveredEventZone, setHoveredEventZone] = React.useState<Zone | undefined>(undefined);
  const [videoState, setVideoState] = React.useState<"error" | "buffering" | "loading" | "ready">("loading");
  const [satelliteViewActive, setSatelliteViewActive] = React.useState<boolean>(false);

  const [player, setPlayer] = React.useState<any>(undefined);

  const kinesisPlayerRef = React.useRef<IKinesisPlayerHandles | null>(null);
  const onVideoTimestampChangeRef = React.useRef(onVideoTimestampChange);

  React.useEffect(() => {
    onVideoTimestampChangeRef.current = onVideoTimestampChange
  }, [onVideoTimestampChange]);

  React.useEffect(() => {
    if (onVideoTimestampChangeRef.current) {
      onVideoTimestampChangeRef.current(timestamp)
    }
  }, [timestamp])

  React.useEffect(() => {
    dispatch(setSelectedStreamLive(isLive));
  }, [isLive, dispatch]);

  React.useEffect(() => {
    if ((areaSelectorActive === true) && kinesisPlayerRef.current) {
      kinesisPlayerRef.current?.setZoom(0);
      kinesisPlayerRef.current?.setZoom(0);
    }
  }, [areaSelectorActive, zonesFeature]);

  React.useEffect(resetPlaybackRate, [isLive]);
 
  React.useImperativeHandle(ref, () => ({
    goToTime,
    goLive,
    setHoveredZone,
    clearHoveredZone,
    isVideoLive
  }))

  function isVideoLive(): boolean {
    return isLive;
  }

  function resetPlaybackRate(): void {
    if (isLive === true) setPlaybackRate(1);
  }

  function goToTime(time: number): void {
    if (kinesisPlayerRef.current) {
      setLive(false);
      setTimestamp(time);
      kinesisPlayerRef.current.goToTime(time);
    }
  }

  function goLive(): void {
    if (kinesisPlayerRef.current) {
      const timestamp = Date.now();
      setLive(true);
      setTimestamp(timestamp);
      if (onTimelineAnimation) onTimelineAnimation(timestamp);
      kinesisPlayerRef.current.goLive();
    }
  }

  function setHoveredZone(zone: Zone) {
    setHoveredEventZone(zone);
  }

  function clearHoveredZone() {
    setHoveredEventZone(undefined);
  }

  function reload(): void {
    if (kinesisPlayerRef.current) {
      kinesisPlayerRef.current.reload();
    }
  }

  function fullscreen(): void {
    if (kinesisPlayerRef.current) {
      kinesisPlayerRef.current.fullscreen();
    }
  }

  function play(): void {
    if (kinesisPlayerRef.current) {
      kinesisPlayerRef.current.play();
      setPaused(false);
    }
  }

  function pause(): void {
    if (kinesisPlayerRef.current) {
      kinesisPlayerRef.current.pause();
      setPaused(true);
    }
  }

  function handleMinimapVisibilityChange(_event: React.ChangeEvent<{}>, checked: boolean): void {
    setMinimapVisible(checked);
  }

  function handleBoundingBoxVisibilityChange(_event: React.ChangeEvent<{}>, checked: boolean): void {
    setBoundingBoxesVisible(checked);
  }

  function handlePoseEstimationVisibilityChange(_event: React.ChangeEvent<{}>, checked: boolean): void {
    setPoseEstimationVisible(checked)
  }

  function handlePtzClick(): void {
    if (playerType === PlayerType.KVS) setPlayerType(PlayerType.PTZ);
    else setPlayerType(PlayerType.KVS);
  }

  function handleNextEventClick(): void {
    const tempEvents = [...events];
    tempEvents.sort((a, b) => {
      if (a.startDate > b.startDate) return 1;
      else if (a.startDate < b.startDate) return -1;
      return 0;
    })

    const eventsAfterTimestamp = tempEvents.filter((e) => timestamp < e.startDate);
    if (eventsAfterTimestamp.length > 0) {
      goToTime(eventsAfterTimestamp[0].startDate);
      if (onTimelineAnimation) onTimelineAnimation(eventsAfterTimestamp[0].startDate);
    }
  }

  function handlePreviousEventClick(): void {
    const tempEvents = [...events];
    tempEvents.sort((a, b) => {
      if (a.startDate < b.startDate) return 1;
      else if (a.startDate > b.startDate) return -1;
      return 0;
    })

    const eventsBehindTimestamp = tempEvents.filter((e) => timestamp > e.endDate);
    if (eventsBehindTimestamp.length > 0) {
      goToTime(eventsBehindTimestamp[0].startDate);
      if (onTimelineAnimation) onTimelineAnimation(eventsBehindTimestamp[0].startDate);
    }
  }

  function handleVideoTimeUpdate(time: number): void { setTimestamp(time); }

  function handleInitialVideoParse(timestamp: number): void { setTimestamp(timestamp); }

  function handleZoomInClick(): void { kinesisPlayerRef.current?.zoomIn(); }

  function handleZoomOutClick(): void { kinesisPlayerRef.current?.zoomOut(); }

  function handleZoomChange(value: number): void { kinesisPlayerRef.current?.setZoom(value); }

  function handleOnZoom(scale: number): void { setVideoZoom(scale); }

  function isVideoOffline(): boolean { return videoState !== "ready" }

  function getImage() {
    pause();
    if (player)
      return (player as PlayerAPI).getSnapshot();
    else
      play();
  }

  function renderPlayer(): JSX.Element {
    if (playerType === PlayerType.KVS) {
      return (
        <KinesisPlayer
          ref={kinesisPlayerRef}
          wraperStyles={{ paddingLeft: "1rem", paddingRight: "1rem" }}
          stream={stream}
          credentials={credentials}
          onTimeUpdate={handleVideoTimeUpdate}
          onInitialTimestampParsed={handleInitialVideoParse}
          isZoomPanEnabled={true}
          videoZoom={videoZoom}
          onZoom={handleOnZoom}
          showMinimap={minimapVisible}
          playbackRate={playbackRate}
          areaSelector={areaSelectorActive}
          onAreaSelectionChange={onAreaSelectionChange}
          areaSelection={areaSelection}
          initialTimestamp={initialTimestamp}
          paused={paused}
          zonesFeature={zonesFeature ? { ...zonesFeature, hoveredEventZone: hoveredEventZone } : undefined}
          usePanZoom={true}
          onVideoStateChange={(state) => setVideoState(state)}
          onSourceLoaded={(kvsName, player) => setPlayer(player)}
          playbackMode="ON_DEMAND"
          detectionBoxesVisible={boundingBoxesVisible}
          poseEstimationVisible={poseEstimationVisible && videoZoom === 1}
          volume={volume}
          satelliteView={satelliteViewActive}
        />
      )
    }

    return (
      <PTZControl
        kvsName={stream?.kvsName}
        siteId={stream?.siteId}
        rportCameraId={stream?.rportCameraId}
        onExitRequest={() => { setPlayerType(PlayerType.KVS) }}
        wraperStyles={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        ptz={stream?.ptz}
        isSuperAdministrator={isSuperAdministrator}
      />
    )
  }

  function gridSize() {
    let size = 6;
    if (stream?.rportCameraId && stream?.features.ptz)
      size++;
    if (features?.imageAnnotations.active && features?.imageAnnotations.write)
      size++;
    if (stream?.satelliteParameters && isSuperAdministrator)
      size++;
    if(onActivateStrobes)
      size++;

    if (isMobile || isSmallScreen) return size - 2;
    return size;
  }

  return (
    <Box display="grid" gridTemplateRows={isMobile ? "min-content 25vh min-content" : isSmallScreen ? 'min-content 60vh min-content' : "min-content auto min-content"}>
      <Box
        height="2.5rem"
        display="grid"
        gridTemplateColumns={`auto repeat(${gridSize()}, min-content)`}
        columnGap="0.75rem"
        alignItems="center"
        paddingRight="1rem"
        paddingLeft="1rem"
      >
        <Box display="grid" gridTemplateColumns="min-content min-content min-content min-content" gap="0.2rem" alignItems="center">
          <StreamStatus stream={stream} />
          <Tooltip title="Previous stream">
            <IconButton disabled={disablePreviousStream} onClick={onPrevioustStreamClick} size="small">
              <ChevronLeft />
            </IconButton>
          </Tooltip>
          <Typography data-cy="timeline-player-camera-name" variant="subtitle1" style={{ whiteSpace: "nowrap" }}>{stream ? Utils.Stream.getStreamName(stream) : "Camera"}</Typography>
          <Tooltip title="Next stream">
            <IconButton disabled={disableNextStream} onClick={onNextStreamClick} size="small">
              <ChevronRight />
            </IconButton>
          </Tooltip>
        </Box>
        <TutorialButton tutorialType="timeline_view" />
        {!isMobile && !isSmallScreen &&
          <ZoomSlider
            zoomRate={videoZoom}
            onZoomInClick={handleZoomInClick}
            onZoomOutClick={handleZoomOutClick}
            onChange={handleZoomChange}
            disabled={Boolean(areaSelectorActive)}
          />
        }
        {onActivateStrobes &&
          <StrobeButton onActivateStrobes={onActivateStrobes} />}
        {stream?.satelliteParameters && isSuperAdministrator &&
          <Tooltip title="Sattelite View">
            <IconButton
              onClick={() => setSatelliteViewActive(!satelliteViewActive)}
              color={satelliteViewActive ? "primary" : "secondary"}
              size="small"
            >
              <SatelliteAlt />
            </IconButton>
          </Tooltip>
        }
        {(stream?.rportCameraId && features?.ptz.active && features?.ptz.write) && !isMobile && !isSmallScreen &&
          <PTZControlButton
            stream={stream}
            onPtzClick={handlePtzClick}
            enabled={Boolean(playerType === PlayerType.PTZ)}
          />
        }
        {!isMobile && !isSmallScreen &&
          <XRayCompareDialogButton
            disabled={Boolean(playerType === PlayerType.PTZ)}
            stream={stream}
            site={selectedSite}
          />
        }
        {stream?.kvsName && features?.imageAnnotations.active && features?.imageAnnotations.write && !isMobile && !isSmallScreen &&
          <SnapshotDialogButton
            disabled={isVideoOffline()}
            kvsName={stream?.kvsName}
            getImage={getImage}
            timestamp={timestamp}
          />}
        <VideoVisibilityMenu
          boundingBoxVisible={boundingBoxesVisible}
          minimapVisible={minimapVisible}
          onBoundingBoxChange={handleBoundingBoxVisibilityChange}
          onMinimapChange={handleMinimapVisibilityChange}
          poseEstimation={poseEstimationVisible}
          onPoseEstimationChange={handlePoseEstimationVisibilityChange}
        />
        <Tooltip title="Fullscreen" arrow placement="right">
          <span>
            <IconButton id="fullscreen-description" disabled={isVideoOffline()} onClick={fullscreen} size="small">
              <Fullscreen />
            </IconButton>
          </span>
        </Tooltip>
        <VideoOptionsMenu
          onReload={reload}
          onEdit={onEditStream}
          onArchive={onArchiveStream}
          onDelete={onDeleteStream}
          onActivate={onActivateStream}
          details={details}
        />
      </Box>
      {renderPlayer()}
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" padding="1rem">
        <Box display="flex" flexDirection="row">
          <VideoDate timestamp={timestamp} timezone={timezone} />
          <ViewFeedback timestamp={timestamp} uptime={uptime} isLive={isLive} />
        </Box>
        <Box display="grid" columnGap="0.75rem" gridTemplateColumns="repeat(3, max-content) min-content" justifyContent="center">
          <Tooltip title="Previous event" arrow placement="right">
            <IconButton onClick={handlePreviousEventClick} size="small">
              <SkipPrevious color="action" />
            </IconButton>
          </Tooltip>
          <PlayPauseButton
            isPaused={paused}
            onPlay={play}
            onPause={pause}
          />
          <Tooltip title="Next event" arrow placement="right">
            <IconButton onClick={handleNextEventClick} size="small">
              <SkipNext color="action" />
            </IconButton>
          </Tooltip>
          <Tooltip title={isLive === false ? "Go live" : "Live footage"} arrow placement="right">
            <span>
              <Button
                disabled={isLive}
                onClick={goLive}
                variant="outlined"
                size="small"
                startIcon={
                  <Box
                    width="0.8rem"
                    height="0.8rem"
                    borderRadius="50%"
                    bgcolor={videoState === "error" && isLive ? '#bbbb00' : isLive ? '#107145' : '#aa0000'}
                  />
                }
                style={{ height: "unset" }}
                data-cy="timeline-player-live-button"
              >
                Live
              </Button>
            </span>
          </Tooltip>
        </Box>
        <Box display="grid" columnGap="0.75rem" gridTemplateColumns="repeat(2, max-content)" justifyContent="flex-end">
          <PlayerVolume
            volume={volume}
            onVolumeChange={(value) => setVolume(value)}
            disabled={false}
          />
          <PlaybackRate
            playbackRate={playbackRate}
            onPlaybackRateChange={(value: number) => { setPlaybackRate(value) }}
            disabled={isLive}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default React.forwardRef(VideoPlayer);
