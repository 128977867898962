import { EventsFilterParams } from "@classes/StreamEvents/EventsFilter";
import { GetTabEventsOptions, buildRequestBody } from "./listEvents";
import { store } from '../../index';
import { API } from "aws-amplify";

export async function countEvents(
  companyId: string,
  start: number,
  end: number,
  timezone: 'local' | "utc",
  filters?: EventsFilterParams,
  options?: GetTabEventsOptions,
  isSuperAdministrator?: boolean,
  previousData?: VideoStreamEvent[],
  offset?: number
): Promise<EventInboxCount> {
  try {
    const allCompanySites = store.getState().sites.sites.get(companyId);

    let path: string = `/global/inbox/count`;
    if (isSuperAdministrator === true) path = `/global/inbox/count?companyId=${companyId}`;

    const req = {
      body: buildRequestBody(
        companyId,
        start,
        end,
        timezone,
        allCompanySites,
        filters,
        isSuperAdministrator,
      )
    }

    const data = await API.post(
      'api-service',
      path,
      req
    )

    return {
      unassigned: data.data.unassigned || 0,
      trash: data.data.trash || 0,
      assigned: data.data.assigned || 0,
      muted: data.data.muted || 0,
      archived: data.data.archived || 0
    };
    
  } catch (error) {
    throw error;
  }
}