import { useMobileQuery } from '@hooks/useMobileQuery';
import useRenderOnWindowResize from '@hooks/useRenderOnWindowResize';
import { Box, Button, CircularProgress, Fade, Snackbar, SnackbarContent, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StyledSnackbar = styled(Snackbar)<{ mobile: boolean }>`
  top: 0px !important;
  left: ${props => props.mobile === true ? '0px !important' : undefined};
  width: ${props => props.mobile === true ? '100vw !important' : undefined};

  .MuiSnackbar-anchorOriginTopCenter {
    top: 0px !important;
    left: ${props => props.mobile === true ? '0px !important' : undefined};
  }

  .MuiSnackbar-anchorOriginTopCenter {
    top: 0px !important;
    left: ${props => props.mobile === true ? '0px !important' : undefined};
  }

  .MuiSnackbarContent-message {
    width: ${props => props.mobile === false ? '80% !important' : undefined};
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
  }

  svg {
    color: white;
  }
`

type SaveBarProps = {
  open: boolean;
  onDiscardChanges: () => void;
  onSaveChanges?: (callback?: () => void) => void;
  formId?: string;
  isSaving?: boolean;
  fullWidth?: boolean;
}

const SaveBar: React.FC<SaveBarProps> = (props) => {
  const { open, onDiscardChanges, onSaveChanges, formId, isSaving, fullWidth } = props;

  const isMobile = useMobileQuery();

  const [isSavingLocal, setSaving] = React.useState<boolean>(false);

  useRenderOnWindowResize();

  function onClick() {
    if (formId === undefined && onSaveChanges) {
      setSaving(true);
      onSaveChanges(() => {
        setSaving(false);
      })
    }
  }

  return (
    <StyledSnackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionComponent={Fade}
      mobile={isMobile}
    >
      <SnackbarContent
        message={<Typography variant="body2" style={{ alignSelf: 'flex-end', justifySelf: 'flex-end', color: '#FFFFFF' }}>Unsaved changes</Typography>}
        style={{
          backgroundColor: '#323232',
          width: fullWidth === true || (isMobile) ? '100vw' : `calc(${window.innerWidth}px - 4.5rem)`,
          marginLeft: fullWidth === true || (isMobile) ? undefined : "4.5rem",
          borderRadius: 0
        }}
        action={
          <Box display="grid" gridTemplateColumns="min-content min-content" gap="1rem" paddingRight="1rem">
            <Button
              size="small"
              variant="contained"
              onClick={onDiscardChanges}
              style={{ height: "2rem" }}
              color="inherit"
              data-cy="save-bar-discard-button"
            >
              Discard
            </Button>
            <Button
              disabled={isSavingLocal === true || isSaving === true}
              size="small"
              color="primary"
              variant="contained"
              style={{ minWidth: '5rem', height: "2rem" }}
              type="submit"
              form={formId}
              onClick={onClick}
              data-cy="save-bar-save-button"
            >
              {isSavingLocal === true || isSaving === true ? (
                <CircularProgress size="1rem" />
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        }
      />
    </StyledSnackbar >
  );

}

export default SaveBar;
