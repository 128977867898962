import changePassword from "./changePassword";
import disableMFA from "./disableMFA";
import enableMFA from "./enableMFA";
import resendPhoneCode from "./resendPhoneCode";
import setPhone from "./setPhone";
import submitPhoneConfirmation from "./submitPhoneConfirmation";

export interface ICognito {
  changePassword(oldPassword: string, newPassword: string): Promise<any>;
  enableMFA(): Promise<void>;
  setPhone(phone: string): Promise<void>;
  resendPhoneCode(phone: string): Promise<void>;
  submitPhoneConfirmation(code: string): Promise<void>;
  disableMFA(): Promise<void>;
}

const Cognito: ICognito = {
  changePassword,
  enableMFA,
  setPhone,
  resendPhoneCode,
  submitPhoneConfirmation,
  disableMFA
}

export default Cognito;
