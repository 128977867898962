import OverviewEventInbox from '@components/overview/OverviewEventInbox';
import OverviewReports from '@components/overview/OverviewReports/OverviewReports';
import { Box } from '@mui/material';
import { EventsTabs } from '@store/views/overviewView/types';
import React from 'react';

type EventsContentProps = {
  activeTab: EventsTabs;
}

const EventsContent: React.FC<EventsContentProps> = (props) => {
  const { activeTab } = props;

  return (
    <Box width="100%" height="100%">
      {activeTab === EventsTabs.EventInbox && <OverviewEventInbox />}
      {activeTab === EventsTabs.Reports && <OverviewReports />}
    </Box>
  )
}

export default EventsContent;
