import React from 'react';
import styled from 'styled-components';
import { AppTheme } from '@store/views/appView/types';
import Centroid from '@classes/Geometry/Centroid';

const ViewContainer = styled.canvas`
  position: absolute;
  z-index: 3;
  display: block;

  min-height: 1px;
  min-width: 1px;
`;

interface IProps {
  zone: Zone;
  plane: Vector2;
  isUserDrawingZone: boolean;
  isSelected: boolean;
  isHovered: boolean;
  theme?: AppTheme;
  transform: { x: number, y: number; scale: number }
  containerWidth: number;
  containerHeight: number;
}

export interface IDrawable {
  draw: (transform: { x: number, y: number, scale: number }) => void;
}

const ZoneMinimumObjectSize: React.ForwardRefRenderFunction<IDrawable, IProps> = (props, ref) => {
  const { zone, plane, isSelected, isUserDrawingZone, containerHeight, containerWidth } = props;


  React.useImperativeHandle(ref, () => ({
    draw
  }))

  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const currentTransform = React.useRef<{ x: number, y: number, scale: number }>({ x: 0, y: 0, scale: 1 });

  React.useEffect(draw);

  function draw(t?: { x: number, y: number, scale: number }): void {
    try {
      if (canvasRef.current !== null) {
        const transform = t || currentTransform.current;
        if (t) currentTransform.current = t;

        const context = canvasRef.current.getContext('2d');
        if (context) {
          context.clearRect(0, 0, containerWidth, containerHeight);
  
          if (zone.minimumObjectSize && isSelected && isUserDrawingZone && zone.type !== "ignore" && zone.coordinates.length > 2) {
            context.lineWidth = 1;
            context.strokeStyle = "#FF0000";
            context.fillStyle = 'rgba(255, 0, 0, 0.8)';
  
            context.beginPath();
            const centroid = new Centroid().getPolygonCentroid(zone.coordinates);
            const totalArea = plane.x * plane.y;
            const objectArea = totalArea * (zone.minimumObjectSize / 100);
            const l = Math.sqrt(objectArea * transform.scale) / 2;

            const offsetY = ((containerHeight - plane.y) * (transform.scale)) / 2;
            const offsetX = ((containerWidth - plane.x) * (transform.scale)) / 2;
  
            context.lineTo(((plane.x * centroid[0]) * transform.scale) + transform.x + offsetX - l, ((plane.y * centroid[1]) * transform.scale) + transform.y + offsetY - l);
            context.stroke();
  
            context.lineTo(((plane.x * centroid[0]) * transform.scale) + transform.x + offsetX + l, ((plane.y * centroid[1]) * transform.scale) + transform.y + offsetY - l);
            context.stroke();
  
            context.lineTo(((plane.x * centroid[0]) * transform.scale) + transform.x + offsetX + l, ((plane.y * centroid[1]) * transform.scale) + transform.y + offsetY + l);
            context.stroke();
  
            context.lineTo(((plane.x * centroid[0]) * transform.scale) + transform.x + offsetX - l, ((plane.y * centroid[1]) * transform.scale) + transform.y + offsetY + l);
            context.stroke();
  
            context.lineTo(((plane.x * centroid[0]) * transform.scale) + transform.x + offsetX - l, ((plane.y * centroid[1]) * transform.scale) + transform.y + offsetY - l);
            context.stroke();
  
            context.fill();
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <ViewContainer
      width={containerWidth}
      height={containerHeight}
      ref={canvasRef}
      style={{
        width: containerWidth || 0,
        height: containerHeight || 0,
        left: 0,
        top: 0
      }}
      data-cy="zone-minimum-object-canvas"
    />
  )
}

export default React.forwardRef(ZoneMinimumObjectSize);
