function getBoardPosition(
  boardVector: Vector2,
  parentVector: Vector2,
  pointPosition: Vector2,
  offsetVector: Vector2,
  transform?: { x: number, y: number, scale: number }
): Vector2 {
  const widthMargin = ((parentVector.x - boardVector.x) / 2) + offsetVector.x;
  const heightMargin = ((parentVector.y - boardVector.y) / 2) + offsetVector.y;

  if (transform) {
    return {
      x: (pointPosition.x - widthMargin - transform.x) / transform.scale,
      y: (pointPosition.y - heightMargin - transform.y) / transform.scale
    };
  }

  return {
    x: (pointPosition.x - widthMargin),
    y: (pointPosition.y - heightMargin)
  };
}

export default getBoardPosition;