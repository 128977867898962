import { Button } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import React from 'react';

const CustomGridTreeDataGroupingHeader: React.FC<{}> = () => {
  const apiRef = useGridApiContext();

  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <Button
      variant="text"
      color="primary"
      data-cy="expand-collapse-button"
      onClick={() => {
        const allIds = apiRef.current.getAllRowIds();
        for (let id of allIds) {
          const row = apiRef.current.getRow(id)
          if (row.type === "COMPANY" || row.type === "SITE") {
            apiRef.current.setRowChildrenExpansion(id, !expanded)
            setExpanded(!expanded)
          }
        }
      }}
    >
      {expanded ? 'Collapse All' : 'Expand All'}
    </Button>
  )
}

export default CustomGridTreeDataGroupingHeader;