import { ActionTypes, IViewsViewState } from "./types";
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IViewsViewState = {
  isCreatingView: false,
  selectedView: undefined,
  newViewTitle: ""
};

export const viewsViewReducer = Utils.Redux.createReducer<IViewsViewState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_IS_CREATING_VIEW]: (state, action) => ({
      ...state,
      isCreatingView: action.value,
      newViewTitle: action.name ? action.name :  ""
    }),

    [ActionTypes.SET_SELECTED_VIEW]: (state, action) => ({
      ...state,
      selectedView: action.value
    })
  }
);
