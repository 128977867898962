function getTwoDigitsUTCHours(date: Date): string {
  const hours = date.getUTCHours();
  if (hours < 10) {
    return `0${hours}`
  }

  return hours.toString();
}

export default getTwoDigitsUTCHours;
