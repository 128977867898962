import { ICredentials } from "@store/session/types";
import { Endpoint, KinesisVideoArchivedMedia } from "aws-sdk";

async function listStreamImages(
  streamName: string,
  options: KinesisVideoArchivedMedia.ClientConfiguration,
  dataEndpoint: string,
  targetStart: Date,
  targetEnd: Date,
  credentials?: ICredentials,
): Promise<KinesisVideoArchivedMedia.Image[]> {
  return new Promise((resolve, reject) => {

    const kinesisVideoArchivedContent = new KinesisVideoArchivedMedia(options);
    kinesisVideoArchivedContent.endpoint = new Endpoint(dataEndpoint);

    kinesisVideoArchivedContent.getImages({
      EndTimestamp: targetEnd,
      Format: 'JPEG',
      ImageSelectorType: 'PRODUCER_TIMESTAMP',
      SamplingInterval: 3000,
      StartTimestamp: targetStart,
      StreamName: streamName
    }, (err, data) => {
      if (err) {
        reject(err);
      }

      if (data && data.Images) {
        resolve(data.Images);
      }
      
      reject();
    })
  })
}

export default listStreamImages;
