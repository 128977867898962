import { ActionTypes, IWorkersState } from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IWorkersState = {
  subcontractor: undefined,
  foreman: undefined,
  subcontractors: [],
  workers: [],
  selectedWorkers: [],
  filteredWorkers: [],
  filteredSubcontractors: [],
  workerImages: {},
  workerToEditDialogOpen: false,
  subToEditDialogOpen: false,
  subColumns: [],
  workerColumns: []
};

export const workersReducer = Utils.Redux.createReducer<
  IWorkersState,
  Types,
  Actions
>(initialState,
  {
    [ActionTypes.ACTIVE_SUBCONTRACTOR]: (state, action) => ({
      ...state,
      subcontractor: action.subcontractor
    }),
    [ActionTypes.ACTIVE_FOREMAN]: (state, action) => ({
      ...state,
      foreman: action.foreman
    }),
    [ActionTypes.SET_WORKERS]: (state, action) => ({
      ...state,
      workers: action.workers
    }),
    [ActionTypes.SET_SUBCONTRACTORS]: (state, action) => ({
      ...state,
      subcontractors: action.subcontractors
    }),
    [ActionTypes.SET_WORKERS_FILTER_TEXT]: (state, action) => ({
      ...state,
      workersFilterText: action.filterText
    }),
    [ActionTypes.SET_SUBCONTRACTORS_FILTER_TEXT]: (state, action) => ({
      ...state,
      subcontractorsFilterText: action.filterText
    }),
    [ActionTypes.SET_SELECTED_WORKERS]: (state, action) => ({
      ...state,
      selectedWorkers: action.selectedWorkers
    }),
    [ActionTypes.SET_FILTERED_WORKERS]: (state, action) => ({
      ...state,
      filteredWorkers: action.workers
    }),
    [ActionTypes.SET_FILTERED_SUBCONTRACTORS]: (state, action) => ({
      ...state,
      filteredSubcontractors: action.subcontractors
    }),
    [ActionTypes.SET_WORKER_IMAGES]: (state, action) => ({
      ...state,
      workerImages: {
        ...state.workerImages,
        [action.workerId]: action.image
      }
    }),
    [ActionTypes.SET_WORKER_TO_EDIT]: (state, action) => ({
      ...state,
      workerToEditId: action.workerToEditId
    }),
    [ActionTypes.SET_WORKER_TO_EDIT_DIALOG_OPEN]: (state, action) => ({
      ...state,
      workerToEditDialogOpen: action.workerToEditDialogOpen
    }),
    [ActionTypes.SET_SUB_TO_EDIT]: (state, action) => ({
      ...state,
      subToEdit: action.subToEdit
    }),
    [ActionTypes.SET_SUB_TO_EDIT_DIALOG_OPEN]: (state, action) => ({
      ...state,
      subToEditDialogOpen: action.subToEditDialogOpen
    }),
    [ActionTypes.SET_SUBS_COLUMNS]: (state, action) => ({
      ...state,
      subColumns: action.columns
    }),
    [ActionTypes.SET_WORKERS_COLUMNS]: (state, action) => ({
      ...state,
      workerColumns: action.columns
    })
  }
);