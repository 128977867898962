import React from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { Query } from "@cubejs-client/core";
import { CubeContext } from '@cubejs-client/react';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';
import * as uid from 'uuid';
import Utils from '@utils/index';
import moment from 'moment';
import { findIndex, isEmpty } from 'lodash';
import EventsReportConverter, { EventsReportData } from '@classes/cubejs/EventsReportConverter';
import OverviewReportsContent from './OverviewReportsContent';
import { enqueueSnackbar } from 'notistack';

const CUBEJS_PREFIX = "tagged_events_";

type OverviewReportsProps = {
  onRequestWidgetDeletion?: () => void;
  isMovingOrResizing?: boolean;
}

const OverviewReports: React.FC<OverviewReportsProps> = (props) => {
  const { isMovingOrResizing } = props;

  const isSmallScreen = useSmallScreenQuery();
  const { cubejsApi } = React.useContext(CubeContext);
  const dataDateFormatter = (date: Date): string => moment(date).format("YYYY-MM-DD");

  const companies = useSelector((state: IStoreState) => state.companies.companies);
  const streams = useSelector((state: IStoreState) => state.streams?.streams);
  const siteMap = useSelector((state: IStoreState) => state.sites.sites);
  const companyId = useSelector((state: IStoreState) => state.selectedCompanies.selectedCompany?.companyId)

  const [query, setQuery] = React.useState<Query>({});
  const [data, setData] = React.useState<undefined | EventsReportData>(undefined);
  const [tagOptions, setTagOptions] = React.useState<string[]>([]);
  const [streamOptions, setStreamOptions] = React.useState<Array<{
    sectionLabel: string;
    options: Array<{
      id: string;
      label: string;
    }>
  }>>([]);
  const [filteredStreamOptions, setFilteredStreamOptions] = React.useState<Array<{
    sectionLabel: string;
    options: Array<{
      id: string;
      label: string;
    }>
  }>>([]);
  const [siteOptions, setSiteOptions] = React.useState<string[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [selectedStreams, setSelectedStreams] = React.useState<string[]>([]);
  const [selectedSites, setSelectedSites] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [dateRange, setDateRange] = React.useState<{ start: Date, end: Date }>({ start: new Date(Date.now() - (86400000 * 1)), end: new Date() })

  const tablePivotRef = React.useRef<{ [key: string]: string | number | boolean; }[] | undefined>();
  const siteMapRef = React.useRef(siteMap);
  const companiesRef = React.useRef(companies)

  React.useEffect(() => { siteMapRef.current = siteMap }, [siteMap])
  React.useEffect(() => { companiesRef.current = companies }, [companies]);

  const getSiteName = React.useCallback((siteId: string) => {
    return (siteId && companyId) ? siteMapRef.current.get(companyId)?.find(s => s.siteId === siteId)?.name : undefined;
  }, [companyId])

  const getCameraName = React.useCallback((cameraId: string) => {
    return cameraId ? streams.find(s => s.kvsName === cameraId)?.defaultName : undefined;
  }, [streams])

  const orderTags = React.useCallback((tags: string[]) => {
    const ORDERED_TAGS = [
      "true_positive_person",
      "true_positive_vehicle",
      "false_positive_animal",
      "false_positive_light",
      "false_positive_weather",
      "false_positive_unknown",
      "false_positive_other",
      "escalate_contact_police",
      "escalate_contact_manager",
      "escalate_fire",
      "escalate_damage",
      "escalate_open_door_gate",
      "escalate_check_subject",
      "escalate_facility_issue"
    ]

    const ordered: string[] = [];

    ORDERED_TAGS.forEach((tag) => {
      if (tags.includes(tag)) ordered.push(tag);
    })
    return ordered;
  }, [])

  const handleDateChange = React.useCallback((start?: Date, end?: Date) => {
    if (start && end) setDateRange({ start, end })
  }, [])

  React.useEffect(() => {
    if (tablePivotRef.current) {
      try {
        const s = streams.filter(el => selectedStreams.includes(el.kvsName));
        const m = new Map(siteMapRef.current);
        m.forEach((value, key) => {
          m.set(key, value.filter(el => selectedSites.includes(el.siteId)))
        })
  
        const converter = new EventsReportConverter(companiesRef.current, m, s);
        const data = converter.convert(tablePivotRef.current);
        setData(data);
      } catch (error) {
        console.log(error);
        setData([]);
        tablePivotRef.current = undefined;
      }
    }
  }, [selectedSites, selectedStreams, streams])

  React.useEffect(() => {
    setLoading(true);
    setData([])
    if (query.measures?.length)
      cubejsApi.load(query).then((resultSet) => {
        const newTablePivot = resultSet.tablePivot();
        tablePivotRef.current = newTablePivot;
        const converter = new EventsReportConverter(companiesRef.current, siteMapRef.current, streams);
        const data = converter.convert(newTablePivot);

        const allTags: string[] = [];
        const allStreams: Set<{ id: string, siteId: string, cameraName: string, siteName: string }> = new Set();
        const allSites: string[] = [];
        newTablePivot?.forEach((i) => {
          let value = 0;
          let label = "";
          let tag = "";
          let tagLabel = "";
          let kvsName = "";
          let cameraName = "";
          let siteId = "";
          let siteName = "";
          for (const key of Object.keys(i)) {
            if (key.includes('.tag')) {
              const tagKey = i[key] as string;
              allTags.push(tagKey);
              tag = tagKey
              tagLabel = Utils.StreamEvent.getTagText(tagKey);
              label = tagLabel;
            }
            if (key.includes('.source')) {
              const sourceKey = i[key] as string;
              kvsName = sourceKey;
              cameraName = getCameraName(sourceKey) || "";
              siteId = streams.find(s => s.kvsName === sourceKey)?.siteId || "";
              if (!isEmpty(siteId))allSites.push(siteId);
              siteName = getSiteName(siteId) || "";
              label += ", " + getCameraName(sourceKey);
              allStreams.add({
                id: kvsName,
                cameraName: cameraName,
                siteId: siteId,
                siteName: siteName
              })
            }
            if (key.includes('.count')) {
              value = Number(i[key]);
            }
          }
          return { ...i, label, value, tag, kvsName, siteId, tagLabel, cameraName, siteName, id: uid.v4() }
        })

        const availableTags = orderTags(Array.from(new Set(allTags)).filter(tag => !tag.includes("muted")));

        setTagOptions(availableTags);
        setSelectedTags(availableTags);
        const streamIds = Array.from(Array.from(allStreams).map(el => el.id));
        const sites = Array.from(new Set(allSites));
        const options: Array<{
          sectionLabel: string;
          options: Array<{
            id: string;
            label: string;
          }>
        }> = [];

        allStreams.forEach((stream) => {
          const target = options.find(el => el.sectionLabel === stream.siteName);

          if (target) {
            if (findIndex(target.options, { id: stream.id }) === -1) {
              target.options.push({
                id: stream.id,
                label: stream.cameraName
              })
            }
          } else {
            options.push({
              sectionLabel: stream.siteName,
              options: [{ id: stream.id, label: stream.cameraName }]
            })
          }
        })

        options.forEach((el) => {
          el.options = Utils.Array.orderAlphabetically(el.options, 'label');
        })

        if (isEmpty(availableTags)) {
          setStreamOptions([])
          setSelectedStreams([]);
          setSiteOptions([]);
          setSelectedSites([]);
          setData([]);
          setLoading(false)
          return;
        }

        setStreamOptions(Utils.Array.orderAlphabetically(options, 'sectionLabel'));
        setSelectedStreams(streamIds);
        setSiteOptions(Utils.Array.orderAlphabetically(sites));
        setSelectedSites(Utils.Array.orderAlphabetically(sites));
        setData(data);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setData([])
        enqueueSnackbar("Something went wrong while loading the data. Please try again later.", { variant: 'error' })
        tablePivotRef.current = undefined;
      });
  }, [query, cubejsApi, streams, getCameraName, getSiteName, orderTags]);

  React.useEffect(() => {
    const s = siteMapRef.current.get(companyId || "")?.filter(el => selectedSites.includes(el.siteId)) || [];
    const newOptions = Utils.Array.orderAlphabetically(streamOptions.filter(option => s.find(el => el.name === option.sectionLabel)), 'sectionLabel')
    newOptions.forEach((el) => {
      el.options = Utils.Array.orderAlphabetically(el.options, 'label')
    })
    setFilteredStreamOptions(newOptions);
    const streamIds: Set<string> = new Set();
    newOptions.forEach(option => option.options.forEach((s: any) => streamIds.add(s.id)))
    setSelectedStreams(Array.from(streamIds));
  }, [companyId, selectedSites, streamOptions, streams])

  React.useEffect(() => {
    const prefix = CUBEJS_PREFIX + companyId;
    const endDateTemp = new Date(dateRange.end);
    endDateTemp.setDate(endDateTemp.getDate() + 1);
    setQuery(q => {
      return {
        ...q,
        measures: [
          `${prefix}.count`
        ],
        order: {
          [`${prefix}.count`]: "desc"
        },
        dimensions: [
          `${prefix}.tag`,
          `${prefix}.source`,
        ],
        timeDimensions: [
          {
            dimension: `${prefix}.start_timestamp`,
            dateRange: [dataDateFormatter(dateRange.start), dataDateFormatter(endDateTemp) + "T00:00:00.000"]
          }
        ],
      }
    });
  }, [companyId, dateRange])

  React.useEffect(() => {
    return () => {
      setData([]);
      tablePivotRef.current = undefined;
      setQuery({})
    }
  }, [])

  const optionsWidth = React.useMemo(() => {
    return isSmallScreen ? '7rem' : "12rem"
  }, [isSmallScreen])

  const handleSelectedTagsChange = React.useCallback((e: string[]) => {
    setSelectedTags(orderTags(e as string[]))
  }, [orderTags])

  const handleSitesSelectChange = React.useCallback((e: string[]) => {
    const newSites = e as string[];
    newSites.forEach(s => {
      if (!selectedSites.includes(s))
        setSelectedStreams(sL => [...sL, ...streams.filter(st => st.siteId === s).map(str => str.kvsName)])
    })
    setSelectedSites(Utils.Array.orderAlphabetically(newSites));
  }, [selectedSites, streams])

  const handleSelectedStreamsChange = React.useCallback((values: string[]) => {
    setSelectedStreams(values)
  }, [])

  return (
    <OverviewReportsContent
      isMovingOrResizing={isMovingOrResizing}
      handleDateChange={handleDateChange}
      dateRange={dateRange}
      optionsWidth={optionsWidth}
      selectedTags={selectedTags}
      handleSelectedTagsChange={handleSelectedTagsChange}
      selectedSites={selectedSites}
      handleSitesSelectChange={handleSitesSelectChange}
      filteredStreamOptions={filteredStreamOptions}
      selectedStreams={selectedStreams}
      handleSelectedStreamsChange={handleSelectedStreamsChange}
      loading={loading}
      data={data}
      getSiteName={getSiteName}
      tagOptions={tagOptions}
      siteOptions={siteOptions}
      companyId={companyId}
    />
  );
}

export default OverviewReports;