import { Box, Typography } from '@mui/material';
import React from 'react';
import TutorialButton, { TutorialButtonProps } from './TutorialButton';
import { useMobileQuery } from '@hooks/useMobileQuery';

type TutorialHeaderTitleProps = TutorialButtonProps & {
  title: string;
}

/**
 * Component used to give a SearchHeader or Header a title along with a question mark icon to trigger a tutorial.
 */
const TutorialHeaderTitle: React.FC<TutorialHeaderTitleProps> = (props) => {
  const { title, ...tutorialButtonProps } = props;

  const isMobile = useMobileQuery();

  return (
    <Box display="grid" gridTemplateColumns="max-content min-content" alignContent="center">
      <Typography variant="h4" style={{ marginBottom: isMobile ? undefined : "0.5rem" }}>{title}</Typography>
      <Box marginBottom={isMobile ? undefined : "0.5rem"} display="flex" alignItems="center">
        <TutorialButton {...tutorialButtonProps} />
      </Box>
    </Box>
  )
}

export default TutorialHeaderTitle;