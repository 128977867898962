import { API } from "aws-amplify";

async function getStreamParameterValues(): Promise<StreamParameterValues> {
  const path: string = `/parameter-values/streams`;

  try {
    const data = await API.get(
      'api-service',
      path,
      {}
    )

    return (data.data as StreamParameterValues)
  } catch (error: any) {
    throw error;
  }
}

export default getStreamParameterValues;
