import {
  ActionTypes,
  IThumbnail,
  IKinesisStreamInformation
} from './types';

export function setStreamList(streamList: VideoStream[]) {
  return {
    type: ActionTypes.SET_STREAM_LIST,
    streamList
  };
}

export function createStream(stream: VideoStream) {
  return {
    type: ActionTypes.CREATE_STREAM,
    stream
  };
}

export function deleteStream(stream: VideoStream) {
  return {
    type: ActionTypes.DELETE_STREAM,
    stream
  };
}

export function setStreamThumbnail(streamThumbnails: IThumbnail[]) {
  return {
    type: ActionTypes.SET_STREAM_THUMBNAIL,
    streamThumbnails
  }
}

export function setStreamInformationList(streamInformationList: IKinesisStreamInformation[]) {
  return {
    type: ActionTypes.SET_STREAM_LIST_INFORMATION, streamInformationList
  }
}

export function setMutedStreams(kvsNames: KVSName[]) {
  return {
    type: ActionTypes.SET_MUTED_STREAMS,
    kvsNames
  }
}

export function addMutedStream(kvsName: KVSName) {
  return {
    type: ActionTypes.ADD_MUTED_STREAM,
    kvsName
  }
}

export function removeMutedStream(kvsName: KVSName) {
  return {
    type: ActionTypes.REMOVE_MUTED_STREAM,
    kvsName
  }
}
