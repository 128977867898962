import { Box, CircularProgress, Fade, Typography } from '@mui/material';
import React from 'react';

type ExportVideoProps = {
  loading: boolean;
  showVideo: boolean;
  url?: string;
  id?: string;
}

const ExportVideo: React.FC<ExportVideoProps> = (props) => {
  const { loading, showVideo, url, id } = props;

  return (
    <Fade in={loading || showVideo}>
      <Box paddingLeft="4px" data-cy="export-video-latest-container">
        <Typography variant="subtitle2">Latest export</Typography>
        <Box
          style={{ aspectRatio: loading || showVideo ? "16/9" : undefined }}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          width="100%"
          bgcolor="rgba(0, 0, 0, 0.2)"
          borderRadius="12px"
        >
          {loading === true &&
            <CircularProgress />
          }
          {showVideo &&
            <video
              controls={true}
              style={{ width: '100%', height: '100%', borderRadius: '12px' }}
              src={url}
              data-cy={`export-video`}
              id={id}
            />
          }
        </Box>
      </Box>
    </Fade>
  )
}

export default ExportVideo;
