function getTagText(tag: string): string {
    switch (tag) {
        case "escalate_theft": return "Theft"
        case "escalate_contact_police": return "Contact police"
        case "escalate_contact_manager": return "Contact manager"
        case "escalate_fire": return "Fire"
        case "escalate_damage": return "Damage"
        case "escalate_open_door_gate": return "Open door/gate"
        case "escalate_check_subject": return "Check subject"
        case "escalate_facility_issue": return "Facility issue"
        case "true_positive_person": return "Person"
        case "true_positive_vehicle": return "Vehicle"
        case "false_positive_animal": return "Animal"
        case "false_positive_light": return "Light"
        case "false_positive_weather": return "Weather"
        case "false_positive_unknown": return "Unknown"
        case "false_positive_other": return "Other"
        case "muted": return "Muted"
        default: return "None"
    }
}

export default getTagText;
