import { API } from 'aws-amplify';

async function listTrades(asCompany?: string): Promise<string[]> {
  try {
    const path = `/trades` + (asCompany ? `?asCompany=${asCompany}` : "");

    const data: any = await API.get('api-service', path, {});
    return data.data as string[];
  } catch (error: any) {
    throw error;
  }
}

export default listTrades;
