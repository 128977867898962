import * as Actions from './actions';
import Utils from '@utils/index';
import { IGridViewState, ActionTypes } from './types';

type Types = ReduxActionTypes<typeof ActionTypes>;
type ActionsType = ReduxActions<typeof Actions>;

const initialState: IGridViewState = {
  isFetchingGrids: true,
  siteGrids: {}
};

export const gridViewReducer = Utils.Redux.createReducer<IGridViewState, Types, ActionsType>(
  initialState,
  {
    [ActionTypes.SET_SITE_GRIDS]: (state, action) => ({
      ...state,
      siteGrids: { ...state.siteGrids, [action.companyId]: action.siteGrids }
    }),

    [ActionTypes.SET_FETCHING_GRIDS]: (state, action) => ({
      ...state,
      isFetchingGrids: action.isFetchingGrids
    }),

    [ActionTypes.SET_SELECTED_GRID]: (state, action) => ({
      ...state,
      selectedGrid: action.selectedGrid
    })
  }
);
