import { CloudWatch } from "aws-sdk";
import { isArray } from "lodash";

function getStreamMetrics(
  streamName: string | string[],
  options: CloudWatch.ClientConfiguration,
  startDate: Date,
  endDate: Date,
  metricName: string = 'PutMedia.Success',
  period: number = 300,
  stat: string = 'Average'
): Promise<CloudWatch.GetMetricDataOutput> {
  const cloudWatch = new CloudWatch(options);

  return new Promise((resolve, reject) => {
    var params = {
      StartTime: startDate,
      EndTime: endDate,
      MetricDataQueries: [
        {
          Id: 'metric_alias0' /* required */,
          Label: 'Kinesis string put success metrics',
          MetricStat: {
            Metric: {
              Dimensions: isArray(streamName) ? streamName.map(el => (
                {
                  Name: 'StreamName' /* required */,
                  Value: el /* required */
                }
              )) : [{
                Name: 'StreamName' /* required */,
                Value: streamName /* required */
              }],
              MetricName: metricName,
              Namespace: 'AWS/KinesisVideo'
            },
            Period: period /* required */,
            Stat: stat /* required */,
            Unit: 'Count'
          },
          ReturnData: true
        }
      ],

      // MaxDatapoints: 'NUMBER_VALUE',
      // NextToken: 'STRING_VALUE',
      ScanBy: 'TimestampAscending'
    };

    cloudWatch.getMetricData(params, function(err, data) {
      if (err) {
        return reject(err);
      }

      resolve(data);
    });
  });
}

export default getStreamMetrics;
