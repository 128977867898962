import { Box, Paper } from "@mui/material";
import FilterRow from "./FilterRow";
import { Field, Operator } from "@classes/Filter/Field";

interface IFilterRowProps {
  currentFilters: FilterToApply[],
  isMobile: boolean,
  currentActiveFilters: number,
  restartFilters: () => void,
  setCurrentFilters: React.Dispatch<React.SetStateAction<FilterToApply[]>>,
  properties: FilterProperties[],
  newFilterBasedOnType: (type: "string" | "number" | "date" | "boolean" | "select" | "array" | "multi_select" | "duration", name: string, operators: Operator[]) => Field<any, any>,
  createInputField: (filter: FilterToApply, index: number) => JSX.Element,
  noPadding?: boolean,
  oneRowLayout?: boolean,
  addFilterDefaultValues?: Map<string, string | number | boolean | string[]>
}

const FilterList: React.FC<IFilterRowProps> = (props) => {
  const {
    createInputField,
    currentActiveFilters,
    currentFilters,
    isMobile,
    newFilterBasedOnType,
    properties,
    restartFilters,
    setCurrentFilters,
    noPadding,
    oneRowLayout,
    addFilterDefaultValues
  } = props;

  return <Box padding={noPadding ? "0 0 1rem" : "1rem"} maxHeight="50vh" style={{ overflowY: 'auto' }}>
    {currentFilters.map((filter, index) => {
      const commonContent = <FilterRow
        filter={filter}
        isMobile={isMobile}
        index={index}
        currentFilters={currentFilters}
        currentActiveFilters={currentActiveFilters}
        restartFilters={restartFilters}
        setCurrentFilters={setCurrentFilters}
        properties={properties}
        newFilterBasedOnType={newFilterBasedOnType}
        createInputField={createInputField}
        oneRowLayout={oneRowLayout}
        key={index}
        addFilterDefaultValues={addFilterDefaultValues}
      />;

      if (isMobile) {
        return (
          <Paper style={{ padding: '0.5rem', marginTop: index > 0 ? '1rem' : undefined }}>
            {commonContent}
          </Paper>
        );
      } else {
        return commonContent;
      }
    })}
  </Box>
}

export default FilterList;
