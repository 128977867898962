import React, { useCallback } from 'react';
import { Box, Chip, Collapse, Button } from '@mui/material';
import EventsFilter, { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';
import TutorialButton from '@components/common/TutorialButton';
import _ from 'lodash';

type TagFilterProps = {
  zones: Zone[];
  onChipRemove?: (type: string, property: string) => void;
  eventsFilterParams?: EventsFilterParams;
  onApplyFilters?: (filter?: EventsFilter) => void;
  areaSelection?: Rectangle;
  onRemoveAreaSearch?: () => void;
  onFilteringClicked: () => void;
  removeAddButton?: boolean;
  disableEventsTutorial?: boolean;
}

const TagFilter: React.FC<TagFilterProps> = (props) => {
  const {
    eventsFilterParams,
    onChipRemove,
    onApplyFilters,
    areaSelection,
    onRemoveAreaSearch,
    onFilteringClicked,
    removeAddButton,
    disableEventsTutorial,
    zones
  } = props;

  const eventsFilter = React.useMemo(() => eventsFilterParams ? new EventsFilter(eventsFilterParams) : undefined, [eventsFilterParams]);
  const [open, setOpen] = React.useState(false);

  const removeChip = useCallback((params: EventsFilterParams) => {
    if (onApplyFilters) {
      const filter = new EventsFilter(params);
      if (filter.isEverythingDisabled() === true) onApplyFilters(undefined)
      else onApplyFilters(filter);
    }
  }, [onApplyFilters]);


  const getTag = useCallback((filter: FilterToApply, onDelete: () => void, category: string) => {
    const splittedName = filter.filter.getName().split(".");
    let label = `${category}/${_.capitalize(splittedName[splittedName.length - 1])}/`;
    const operatorString = _.capitalize(filter.filter.getCurrentOperator().replaceAll("_", " ")).replace("Is any of", "Is/are");
    switch (filter.filter.getName()) {
      case 'duration':
      case 'personDetectionDuration':
      case 'vehicleDetectionDuration':
      case 'unknownDetectionDuration':
        const time = Number(filter.text) / 1000;
        const formattedTime = (time < 60) ? `${time}s` : `${Math.floor(time / 60)}:${(time % 60) < 10 ? "0" + (time % 60) : (time % 60)}m`
        const operator = filter.filter.getCurrentOperator().includes("greater") ? ">=" : filter.filter.getCurrentOperator().includes("lesser") ? "<=" : "=";
        label = `${category}/${_.capitalize(filter.filter.getName().replace("DetectionDuration", "").replace("unknown", "Motion"))}/${operator}: ${formattedTime}`
        break;
      case 'zoneId':
        label = label.replace("Zoneid", "Zone") + `${operatorString}: ${zones.filter(z => filter.text?.toString().includes(z.id)).map(z => z.name).join(", ")}`
        break;
      case 'labels':
        label = label.replace("Labels", "Object") + `${operatorString}: ${_.capitalize(filter.text?.toString()?.replace('motion', 'unknown').replaceAll(",", ", "))}`;
        break;
      default:
        label += `${operatorString}: ${_.capitalize(filter.text?.toString()?.replaceAll(",", ", ")
          .replaceAll("_", " ").replaceAll("true positive", "").replaceAll("false positive", "").replaceAll("escalate", ""))}`
        break;
    }
    return <Chip
      onDelete={onDelete}
      label={label}
      title={label}
      style={{ maxWidth: 300 }}
      key={label}
      data-cy={`filter-tag-${label.toLocaleLowerCase().replaceAll(/[/ :]+/g, "-")}`}
    />
  }, [zones]);

  const chips = useCallback(() => {
    const filterChips = [];
    if (eventsFilter === undefined || eventsFilter.isEverythingDisabled()) {
      filterChips.push(<Chip
        data-cy="event-tab-all-events-filter-chip"
        label="All events"
      />)
    }
    if (areaSelection) {
      filterChips.push(<Chip
        onDelete={onRemoveAreaSearch}
        label="Area search"
      />)
    }

    if (eventsFilterParams?.motion?.enable) {
      filterChips.push(...eventsFilterParams?.motion?.filters.map((filter) => getTag(filter, () => {
        if (onChipRemove)
          onChipRemove("Motion", filter.filter.getName())
        else
          removeChip({
            ...eventsFilterParams,
            motion: { enable: eventsFilterParams.motion.enable, filters: [...eventsFilterParams.motion.filters.filter(f => f.filter.getName() !== filter.filter.getName())] }
          })
      }, "Motion")))
    }

    if (eventsFilterParams?.people?.enable) {
      filterChips.push(...eventsFilterParams?.people?.filters.map((filter) => getTag(filter, () => {
        if (onChipRemove)
          onChipRemove("People", filter.filter.getName());
        else
          removeChip({
            ...eventsFilterParams,
            people: { enable: eventsFilterParams.people.enable, filters: [...eventsFilterParams.people.filters.filter(f => f.filter.getName() !== filter.filter.getName())] }
          })
      }, "People")))
    }

    if (eventsFilterParams?.vehicles?.enable) {
      filterChips.push(...eventsFilterParams?.vehicles?.filters.map(filter => getTag(filter, () => {
        if (onChipRemove)
          onChipRemove("Vehicle", filter.filter.getName());
        else
          removeChip({
            ...eventsFilterParams,
            vehicles: { enable: eventsFilterParams.vehicles.enable, filters: [...eventsFilterParams.vehicles.filters.filter(f => f.filter.getName() !== filter.filter.getName())] }
          });
      }, "Vehicle")))
    }


    if (eventsFilterParams?.safety?.enable) {
      filterChips.push(...eventsFilterParams?.safety?.filters.map(filter => getTag(filter, () => {
        if (onChipRemove)
          onChipRemove("Safety", filter.filter.getName());
        else
          removeChip({
            ...eventsFilterParams,
            safety: { enable: eventsFilterParams.safety.enable, filters: [...eventsFilterParams.safety.filters.filter(f => f.filter.getName() !== filter.filter.getName())] }
          })
      }, "Safety")))
    }


    if (eventsFilterParams?.zones?.enable) {
      filterChips.push(...eventsFilterParams?.zones?.filters.map(filter => getTag(filter, () => {
        if (onChipRemove)
          onChipRemove("Zones", filter.filter.getName());
        else
          removeChip({
            ...eventsFilterParams,
            zones: { enable: eventsFilterParams.zones.enable, filters: [...eventsFilterParams.zones.filters.filter(f => f.filter.getName() !== filter.filter.getName())] }
          })
      }, "Zones")))
    }

    if (filterChips.length > 1 && !open) {
      return [filterChips[0], <Button size="small" sx={{ height: "2rem" }} variant='text' onClick={() => setOpen(o => !o)}>Show {filterChips.length - 1} more</Button>]
    }

    if (filterChips.length > 1) {
      filterChips.push(<Button size="small" sx={{ height: "2rem" }} variant='text' onClick={() => setOpen(o => !o)}>Show less</Button>)
    }

    return filterChips
  }, [areaSelection, eventsFilter, eventsFilterParams, getTag, onRemoveAreaSearch, removeChip, open, onChipRemove]);

  return (
    <Box display="grid" columnGap="1rem" gridTemplateColumns="auto min-content">
      <Collapse in={open} collapsedSize={"2.5rem"} sx={{ maxHeight: "10rem", overflowY: "scroll" }}>
        {removeAddButton !== true &&
          <Chip
            id="add-filter-description"
            variant="outlined"
            onClick={onFilteringClicked}
            label="+ Add Filter"
          />
        }
        {chips()}
      </Collapse>
      {disableEventsTutorial !== true &&
        <TutorialButton tutorialType="timeline_events" />}
    </Box>
  );
}

export default TagFilter;

