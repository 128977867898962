import React from 'react';
import { fetchAppInitialData } from './App/AppHooks';

/**
 * Load basic data and dispatch to the Redux Store.
 * Responsabilities:
 * - Load Companies and dispatch them to the Redux Store.
 * - Load Sites and dispatch them to the Redux Store.
 * - Load Streams and dispatch them to the Redux Store.
 * @returns boolean - false when loading is done.
 */
function useLoadBasicData(isAuthenticated: boolean | undefined) {
  const [ isLoading, setLoading ] = React.useState<boolean>(true);

  React.useEffect(() => {
    setLoading(isAuthenticated || true);
    async function perform(callback: (value: boolean) => void) {
      if (isAuthenticated === true) {
        await fetchAppInitialData();
        callback(false);
      } else {
        callback(false);
      }
    }

    perform(setLoading);
  }, [isAuthenticated])

  return isLoading;
}

export default useLoadBasicData;
