import { API } from "aws-amplify";

async function cancelTimelapse(kvsName: string, timelapseId: string): Promise<void> {
  try {
    const path: string = `/timelapse/${kvsName}/${timelapseId}/cancel`
    await API.post('api-service', path, {});
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default cancelTimelapse;
