import { first, includes, isArray, isEmpty, isNull, isString, isUndefined } from "lodash";
import { Field } from "./Field";

export class MultiSelectField<T> extends Field<T, Array<string>> {
  public override filter(data: Array<T>, target?: Array<string>): Array<T> {
    return data.filter((el: any) => {
      const value = this._path?.reduce((a, b) => a ? a[b] : undefined, el)
      if (this._currentOperator === "is_empty") {
        if (!value) return true;
        if (value && value === '') return true;
        return false;
      }

      if (this._currentOperator === "is_not_empty") {
        return value && !isEmpty(value);
      }

      if (isEmpty(target) || isNull(target) || isUndefined(target) || first(target) === '') return true;

      if (value) {
        const targetProperty = value;

        if (isString(targetProperty)) {
          if (this._currentOperator === "is_any_of") {
            return includes(target, targetProperty);
          }
        }

        if (isArray(targetProperty)) {
          if (this._currentOperator === "is_any_of") {
            for (let i = 0; i < targetProperty.length; i += 1) {
              if (includes(target, targetProperty[i])) {
                return true;
              }
            }
          }
        }
      }

      return false;
    })
  }
}