import { API } from "aws-amplify";

async function deleteRole(roleId: string, companyId: string): Promise<void> {
  try {
    const path: string = `/roles/${roleId}?companyId=${companyId}`;
    
    await API.del(
      'api-service',
      path,
      {}
    );
    
  } catch (error: any) {
    throw error;
  }
}

export default deleteRole;
