import { PlaybackratePopover } from '@components/common/PlaybackratePopover';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Speed } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

const TextContainer = styled.div`
  .MuiTypography-colorTextSecondary {
    color: rgba(0, 0, 0, 0.26) !important;
  }
`

type PlaybackRateProps = {
  playbackRate: number;
  onPlaybackRateChange: (value: number) => void;
  disabled?: boolean;
  id?: string;
}

export const PlaybackRate: React.FC<PlaybackRateProps> = (props) => {
  const { playbackRate, onPlaybackRateChange, disabled, id } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <>
      <Box id={id} display="flex" flexDirection="row" justifyContent="center" alignItems="flex-end">
        <TextContainer>
          <Typography color={disabled ? 'text.disabled' : 'text.primary'} variant="subtitle1">{`${playbackRate}x`}</Typography>
        </TextContainer>
        <Tooltip title="Playback rate" arrow placement="right">
          <span>
            <IconButton disabled={disabled} onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
              <Speed />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <PlaybackratePopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        playbackRate={playbackRate}
        onPlaybackRateChange={onPlaybackRateChange}
      />
    </>
  )
}