import getTableName from "./getTableName";
import getObjectName from "./getObjectName";
import { DbTable } from "./types";

export interface IName {
  getTableName(table: DbTable): string;
  getObjectName(table: DbTable, id: string): string;  
}

const Name: IName = {
  getTableName: getTableName,
  getObjectName: getObjectName
}

export default Name;