import { DayLabel } from "@declarations/enums";

function sortDays(days: string[]) {
  var sorted = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday", "Sunday"];
  return days.slice().sort((a, b) => sorted.indexOf(a) - sorted.indexOf(b));
}

function getScheduleDisplayText(value: Record<DayLabel, Array<ActiveDayTime>>, fallback: string): string {
  return sortDays(Object.keys(value)).join(", ") || fallback;
}

export default getScheduleDisplayText;