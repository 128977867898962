import * as _ from 'lodash';

function orderAlphabetically(collection: any[], property?: string): any[] {
  try {
    if (property) {
      const ordered = _.orderBy(collection, [item => item[property]], 'asc');
      return ordered.sort((a, b) => a[property]?.localeCompare(b[property], 'en', { numeric: true }));
    }

    return collection.sort().sort((a, b) => a.localeCompare(b, 'en', { numeric: true }));
  } catch (error) {
    console.log(error);
    return collection;
  }
}

export default orderAlphabetically;
