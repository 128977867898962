import React from 'react';
import API from '@API/index';
import { useDispatch } from 'react-redux';
import { setMutedStreams } from '@store/streams/actions';

/**
 * Hook that will perform an update to Redux Store (Muted Streams) when called.
 * This hook is useful when you want to check for updates on backend and update the App State at the same time.
 * @param companyId ID of the Company to perform the update.
 * 
 * @example
 * const Component: React.FC<{}> = () => {
 *  // Updates Muted Streams every time this component mounts.
 *  useUpdateMutedStreams("forsight_company");
 * }
 * 
 * const Component: React.FC<{}> = () => {
 *  const [selectedCompanyId, setSelectedCompanyId] = React.useState<string>("forsight_company");
 *  const [selectedTab, setSelectedTab] = React.useState<number>(0);
 * 
 *  // Update Muted Streams every time the selectedTab state changes.
 *  useUpdateStreams(selectedCompanyId, [selectedTab])
 * }
 */
export function useUpdateMutedStreams(
  companyId?: string,
  deps?: React.DependencyList
) {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(true);

  const perform = React.useCallback(() => {
    if (companyId) {
      setLoading(true);

      API.Streams.getMutedStreams(companyId)
        .then((response) => {
          dispatch(setMutedStreams(response));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        })
    }
  }, [dispatch, companyId])

  React.useEffect(perform, [...(deps || []), perform])
  return loading;
}
