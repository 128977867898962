export class Filter {
  protected _filters: FilterToApply[] = [];

  constructor(filters: FilterToApply[]) {
    this._filters = filters;
  }

  public getFilters(): FilterToApply[] {
    return this._filters;
  }

  public setFilters(filters: FilterToApply[]) {
    this._filters = filters;
  }

  public filter(data: any[]): any[] {
    let filteredData = [...data];
    this._filters?.forEach(filter => {
      let value: number | string | boolean | string[] | undefined = filter.text;
      if (filter.filter.getType() === 'date') value = new Date(value as string).getTime();
      if (filter.filter.getType() === "boolean") {
        if (value === 'true') value = true;
        if (value === 'false') value = false;
      }
      filteredData = filter.filter.filter(filteredData, value);
    })
    return filteredData;
  }
}