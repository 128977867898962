import { API } from "aws-amplify";

async function listStrobes(streamId: string, type?: string): Promise<Strobe[]> {
    const path: string = `/devices/attached/${streamId}`;
    const queryParams = type ? { type } : {};
  
    try {
      const data = await API.get('api-service', path, { queryParams });
      return data.data as Strobe[];
    } catch (error: any) {
      throw error;
    }
  }

  export default listStrobes;