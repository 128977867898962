import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_ACTIVE_STREAM: null,
  ADD_ACTIVE_STREAM_TIMES: null,
  CLEAR_ACTIVE_STREAM: null,
  SET_VIDEO_STATE: null,
  SET_PLAYER_TYPE: null,
  SET_VIDEO_LIVE: null,
  SET_PUBSUB_SUBSCRIPTION_FUNCTION: null,
  SET_PUBSUB_PTZ_SUBSCRIPTION_FUNCTION: null
});

export interface ISelectedStreamState {
  readonly stream?: VideoStream;
  readonly streamChunks: VideoStreamTimeChunk[];
  readonly videoState: VideoState;
  readonly playerType: "KVS" | "PTZ";
  readonly isLive: boolean;
  readonly subscription?: (data: VideoStreamEvent[]) => void;
  readonly ptzSubscription?: (data: PTZEvent | PTZEvent[]) => void;
}
export interface ISiteGrid {
  companyId: string;
  siteId: string;
  id: string;
  name: string;
  layout: IGrid;
  customProperties: Map<string, ICustomProperties>;
  updatedAt: number;
  createdAt: number;
  archived?: IGrid;
}

interface IGrid {
  page1?: IGridPage;
  page2?: IGridPage;
  page3?: IGridPage;
  page4?: IGridPage;
  page5?: IGridPage;
  page6?: IGridPage;
}

type IGridPage = string[];

interface ICustomProperties {
  displayName: string;
}