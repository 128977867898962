import React from 'react';
import styled from 'styled-components';
import Utils from '@utils/index';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const EDGE_POINT_SIZE = 14;

const Container = styled.div<{ width?: string, height?: string }>`
  position: absolute;
  z-index: 32;

  width: ${props => props.width};
  height: ${props => props.height};
`

const Board = styled.canvas<{ width?: string, height?: string, cursor?: string }>`
  position: relative;

  width: ${props => props.width};
  height: ${props => props.height};

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  pointer-events: auto;

  :hover {
    cursor: ${props => props.cursor};
  }

  z-index: 11;
`

const DraggablePoint = styled.div<{ cursor: string }>`
  position: absolute;
  width: ${EDGE_POINT_SIZE}px;
  height: ${EDGE_POINT_SIZE}px;
  border-radius: 50%;
  background-color: transparent;

  :hover {
    cursor: ${props => props.cursor};
  }
`

let ctx: any;
let canvasOffset: ClientRect | DOMRect;
let offsetX: number;
let offsetY: number;
let startX: number;
let startY: number;
let width: number;
let height: number;

let points = {
  x: 0,
  y: 0,
  width: 0,
  height: 0
}

type AreaSelectorProps = {
  active?: boolean;
  onAreaSelectionChange?: (params?: Rectangle) => void;
  areaSelection?: Rectangle
  videoWidth: number;
  videoHeight: number;
  containerWidth: number;
  containerHeight: number;
  videoState: "error" | "buffering" | "loading" | "ready";
}

const AreaSelector: React.FC<AreaSelectorProps> = (props) => {
  const { active, onAreaSelectionChange, areaSelection, videoWidth, videoHeight, containerWidth, containerHeight, videoState } = props;

  const [isMouseDown, setMouseDown] = React.useState<boolean>(false);
  const [isDone, setDone] = React.useState<boolean>(false);
  const [canvasSize, setCanvasSize] = React.useState<Vector2>({ x: 0, y: 0 }); 
  
  const canvasSizeRef = React.useRef(canvasSize);

  React.useEffect(() => { canvasSizeRef.current = canvasSize }, [canvasSize])
  React.useEffect(handleAreaSelectionChange, [areaSelection]);
  React.useEffect(drawRectangle);
  React.useEffect(calculateCanvasSize, [videoWidth, videoHeight, containerWidth, containerHeight]);

  if (!active) return null;

  function calculateCanvasSize() {
    if (videoWidth !== 0 && videoHeight !== 0 && containerWidth !== 0 && containerHeight !== 0) {
      const size = Utils.Geometry.getCanvasSize(
        videoWidth || 0,
        videoHeight || 0,
        containerWidth || 0,
        containerHeight || 0
      );

      setCanvasSize(size);
    }
  }

  function drawRectangle(): void {
    if (ctx) {
      if (isDone === true && (videoState === 'ready' || videoState === 'buffering')) {
        ctx.clearRect(0, 0, canvasSize.x, canvasSize.y);
        ctx.strokeRect(canvasSize.x * points.x, canvasSize.y * points.y, canvasSize.x * points.width, canvasSize.y * points.height);
      } else if (videoState !== 'ready') {
        ctx.clearRect(0, 0, canvasSize.x, canvasSize.y);
      } else if (areaSelection === undefined && isMouseDown === false) {
        ctx.clearRect(0, 0, canvasSize.x, canvasSize.y);
      }
    }
  }

  function setRef(ref: HTMLCanvasElement | null): void {
    if (ref !== null) {
      ctx = ref.getContext('2d');
      canvasOffset = ref.getBoundingClientRect();
      offsetX = canvasOffset.left;
      offsetY = canvasOffset.top;
      canvasSize.x = canvasOffset.width;
      canvasSize.y = canvasOffset.height;
    }

    if (ctx) {
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#FFCA7A';
      ctx.fillStyle = "rgba(255, 255, 255, .2)";
      ctx.fill();
    }
  }

  function handleAreaSelectionChange(): void {
    if (areaSelection === undefined && ctx) {
      ctx.clearRect(0, 0, canvasSizeRef.current.x, canvasSizeRef.current.y);
      setDone(false);
    }
  }

  function handleMouseDown(e: React.MouseEvent<HTMLCanvasElement>): void {
    e.preventDefault();
    e.stopPropagation();
    if (isDone === true) return;

    startX = parseInt(String(e.clientX - offsetX));
    startY = parseInt(String(e.clientY - offsetY));

    setMouseDown(true);
  }

  function handleMouseUp(e: React.MouseEvent<HTMLCanvasElement>): void {
    e.preventDefault();
    e.stopPropagation();
    if (isDone === true) return;

    setMouseDown(false);

    if (points.height === 0 && points.width === 0) return;

    setDone(true);

    if (onAreaSelectionChange) {
      //@ts-ignore
      const xMultiplier = videoWidth / canvasSize.x;
      //@ts-ignore
      const yMultipler = videoHeight / canvasSize.y;

      onAreaSelectionChange({
        x: Math.floor(startX * xMultiplier),
        y: Math.floor(startY * yMultipler),
        width: Math.floor(width * xMultiplier),
        height: Math.floor(height * yMultipler)
      })
    }
  }

  function handleMouseMove(e: React.MouseEvent<HTMLCanvasElement>): void {
    if (isMouseDown === false || isDone === true) return;

    const mouseX = parseInt(String(e.clientX - offsetX));
    const mouseY = parseInt(String(e.clientY - offsetY));
    width = mouseX - startX;
    height = mouseY - startY;

    if (width < 0 && height < 0) {
      points = {
        x: (startX + width) / canvasSize.x,
        y: (startY + height) / canvasSize.y,
        width: width * -1 / canvasSize.x,
        height: height * -1 / canvasSize.y
      }
    } else if (height < 0) {
      points = {
        x: startX / canvasSize.x,
        y: (startY + height) / canvasSize.y,
        width: width / canvasSize.x,
        height: height * -1 / canvasSize.y
      }
    } else if (width < 0) {
      points = {
        x: (startX + width) / canvasSize.x,
        y: startY / canvasSize.y,
        width: width * -1 / canvasSize.x,
        height: height / canvasSize.y
      }
    } else {
      points = {
        x: startX / canvasSize.x,
        y: startY / canvasSize.y,
        width: width / canvasSize.x,
        height: height / canvasSize.y
      }
    }

    ctx.clearRect(0, 0, canvasSize.x, canvasSize.y);
    ctx.strokeRect(canvasSize.x * points.x, canvasSize.y * points.y, canvasSize.x * points.width, canvasSize.y * points.height);
  }

  function clearRectangle(): void {
    ctx.clearRect(0, 0, canvasSize.x, canvasSize.y);
    points = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    }
    setDone(false);
    if (onAreaSelectionChange) onAreaSelectionChange(undefined);
  }

  function getButtonCoordinates(): Vector2 {
    return {
      //@ts-ignore
      y: (canvasSize.y * points.y) + (((canvasSize.y - containerHeight || 0) / 2) * -1) - 30,
      //@ts-ignore
      x: (canvasSize.x * points.x) + (((canvasSize.x - containerWidth || 0) / 2) * -1) + (canvasSize.x * points.width)
    }
  }

  function getDraggablePointPosition(position: "top-left" | "top-right" | "bottom-left" | "bottom-right"): Vector2 {
    const yOffset = (((canvasSize.y - containerHeight || 0) / 2) * -1) - (EDGE_POINT_SIZE / 2);
    const xOffset = (((canvasSize.x - containerWidth || 0) / 2) * -1) - (EDGE_POINT_SIZE / 2);

    switch (position) {
      case "top-left":
        return {
          x: (canvasSize.x * points.x) + xOffset,
          y: (canvasSize.y * points.y) + yOffset
        }
      case "top-right":
        return {
          x: (canvasSize.x * (points.x + points.width)) + xOffset,
          y: (canvasSize.y * points.y) + yOffset
        }
      case "bottom-left":
        return {
          x: (canvasSize.x * points.x) + xOffset,
          y: (canvasSize.y * (points.y + points.height)) + yOffset
        }
      case "bottom-right":
        return {
          x: (canvasSize.x * (points.x + points.width)) + xOffset,
          y: (canvasSize.y * (points.y + points.height)) + yOffset
        }
    }
  }

  function handleResizeMouseDown(position: "top-left" | "top-right" | "bottom-left" | "bottom-right"): void {
    setDone(false);
    setMouseDown(true);
    if (position === "bottom-left") {
      startX = parseInt(String(canvasSize.x * (points.x + points.width)));
      startY = parseInt(String(canvasSize.y * points.y));
    } else if (position === "top-left") {
      startX = parseInt(String(canvasSize.x * (points.x + points.width)));
      startY = parseInt(String(canvasSize.y * (points.y + points.height)));
    } else if (position === "top-right") {
      startX = parseInt(String(canvasSize.x * points.x));
      startY = parseInt(String(canvasSize.y * (points.y + points.height)));
    }
  }

  return (
    <Container
      width={`${containerWidth}px`}
      height={`${containerHeight}px`}
    >
      {isDone === true && (videoState === 'ready' || videoState === 'buffering') &&
        <>
          <IconButton
            onClick={clearRectangle}
            size="small"
            style={{
              position: 'absolute',
              top: getButtonCoordinates().y,
              left: getButtonCoordinates().x,
              zIndex: 20,
              backgroundColor: "#BF260E"
            }}
          >
            <Close htmlColor="white" fontSize="small" />
          </IconButton>
          <DraggablePoint
            style={{
              top: getDraggablePointPosition("top-left").y,
              left: getDraggablePointPosition("top-left").x,
              zIndex: 20,
            }}
            cursor="nwse-resize"
            onMouseDown={() => { handleResizeMouseDown("top-left") }}
          />
          <DraggablePoint
            style={{
              top: getDraggablePointPosition("top-right").y,
              left: getDraggablePointPosition("top-right").x,
              zIndex: 20,
            }}
            cursor="nesw-resize"
            onMouseDown={() => { handleResizeMouseDown("top-right") }}
          />
          <DraggablePoint
            style={{
              top: getDraggablePointPosition("bottom-left").y,
              left: getDraggablePointPosition("bottom-left").x,
              zIndex: 20,
            }}
            cursor="nesw-resize"
            onMouseDown={() => { handleResizeMouseDown("bottom-left") }}
          />
          <DraggablePoint
            style={{
              top: getDraggablePointPosition("bottom-right").y,
              left: getDraggablePointPosition("bottom-right").x,
              zIndex: 20,
            }}
            cursor="nwse-resize"
            onMouseDown={() => { handleResizeMouseDown("bottom-right") }}
          />
        </>
      }
      <Board
        ref={(ref) => setRef(ref)}
        width={`${canvasSize.x}px`}
        height={`${canvasSize.y}px`}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        cursor={isDone ? undefined : 'crosshair'}    
        data-cy="zone-board-canvas"
      />
    </Container>
  )
}

export default AreaSelector;
