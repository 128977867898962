import { ISiteGrid } from "@store/selectedStream/types";
import { API } from "aws-amplify";

async function listCompanyGrids(companyId: string): Promise<ISiteGrid[]> {
  try {
    const path: string = `/grids/${companyId}`;

    const data = await API.get(
      'api-service',
      path,
      {}
    )

    return data.data as ISiteGrid[];
  } catch (error: any) {
    return [];
  }
}

export default listCompanyGrids;
