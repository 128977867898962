import { API } from "aws-amplify";

async function deactivateUsers(userIds: string[]): Promise<void> {
  try {
    const path: string = `/users/accounts/disable`;

    await API.post(
      'api-service',
      path,
      {
        body: {
          userIds
        }
      }
    );

  } catch (error: any) {
    throw error;
  }
}

export default deactivateUsers;
