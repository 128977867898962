import React from 'react';
import styled from 'styled-components';

const Led = styled.div<{ state?: boolean }>`
  margin: 0 auto;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 0.75rem;
  background-color: ${props => props.state ? props.state === true ? '#ABFF00' : 'red' : 'none'};

  border-radius: 50%;
`

interface ILedFeedbackProps {
  active?: boolean;
}

const LedFeedback: React.FC<ILedFeedbackProps> = (props) => {
  const { active } = props;

  return (
    <Led state={active} />
  )
}

export default LedFeedback;
