import { Auth } from "aws-amplify";

async function changePassword(oldPassword: string, newPassword: string): Promise<any> {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(currentUser, oldPassword, newPassword);
    return currentUser;
  } catch (e) {
    throw e;
  }
}

export default changePassword;
