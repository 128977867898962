import React from 'react';
import { IconButton,Menu, MenuItem, Tooltip } from '@mui/material';
import { MoreHoriz, Refresh } from '@mui/icons-material';
import ProcoreCopyConfig from '@components/common/ProcoreCopyConfig';

type VideoOptionsMenuProps = {
  onReload?: () => void;
  onEdit?: () => void;
  onArchive?: () => void;
  onDelete?: () => void;
  onActivate?: () => void;
  details?: boolean;
}

export const VideoOptionsMenu: React.FC<VideoOptionsMenuProps> = (props) => {
  const { onReload, onEdit, onArchive, onDelete, onActivate, details } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  };

  function handleReload() {
    if (onReload) onReload();
    handleClose();
  }

  if (onEdit === undefined && onArchive === undefined && onDelete === undefined && onActivate === undefined) {
    return (
      <Tooltip title="Reload" arrow placement="right">
        <IconButton onClick={onReload} size="small">
          <Refresh color="action" />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <>
      <Tooltip title="More options" arrow placement="right">
        <IconButton onClick={handleClick} size="small">
          <MoreHoriz color="action" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleReload} data-cy={`video-reload-menu-item`} >Reload</MenuItem>
        {onEdit && <MenuItem onClick={onEdit} data-cy={`video-edit-menu-item`} >{details ? "Details" : "Edit"}</MenuItem>}
        {onActivate && <MenuItem onClick={() => { handleClose(); onActivate(); }} data-cy={`video-activate-menu-item`} >Activate</MenuItem>}
        {onArchive && <MenuItem onClick={() => { handleClose(); onArchive(); }} data-cy={`video-archive-menu-item`} >Archive</MenuItem>}
        {onDelete && <MenuItem onClick={() => { handleClose(); onDelete(); }} data-cy={`video-delete-menu-item`} >Delete</MenuItem>}
        <ProcoreCopyConfig onClick={() => { handleClose(); }} />
      </Menu>
    </>
  )
}