import { KinesisVideo } from "aws-sdk";
import API from '@API/index';

async function getLiveReplayStream(
  streamName: string,
  options: KinesisVideo.ClientConfiguration,
  startTimestamp: Date
) {
  try {
    const dataEndpoint = await API.Kinesis.getDataEndpoint(
      streamName,
      options,
      'GET_HLS_STREAMING_SESSION_URL'
    );

    // const result = await getPreviousFragmentTimestamp(streamName, options, dataEndpoint, startTimestamp);

    const playbackMode = 'LIVE_REPLAY'; // 'LIVE' or 'ON_DEMAND or 'LIVE_REPLAY'
    const fragmentSelectorType = 'PRODUCER_TIMESTAMP'; // 'SERVER_TIMESTAMP' or 'PRODUCER_TIMESTAMP'

    const streamUrl = await API.Kinesis.getHLSStreamingSessionURL(
      streamName,
      options,
      dataEndpoint,
      {
        PlaybackMode: playbackMode,
        HLSFragmentSelector: {
          FragmentSelectorType: fragmentSelectorType,
          TimestampRange: {
            StartTimestamp: startTimestamp
          }
        },
        DisplayFragmentTimestamp: 'ALWAYS',
        MaxMediaPlaylistFragmentResults: 10
      }
    );

    return streamUrl;
  } catch (error: any) {
    throw error;
  }
}

export default getLiveReplayStream;
