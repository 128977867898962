import React from 'react';
import { IStoreState } from '@store/index';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import API from '@API/index';

type StreamValidatorProps = {
  onValidationCompleted: (statusCode: 200 | 403 | 404) => void;
}

const StreamValidator: React.FC<StreamValidatorProps> = (props) => {
  const { onValidationCompleted } = props;

  const location = useLocation();

  const permissions = useSelector((store: IStoreState) => store.session.permissions);
  const credentials = useSelector((store: IStoreState) => store.session.credentials);

  const permissionsRef = React.useRef(permissions);
  const credentialsRef = React.useRef(credentials);
  const locationRef = React.useRef(location);
  const onValidationCompletedRef = React.useRef(onValidationCompleted);

  React.useEffect(() => { permissionsRef.current = permissions }, [permissions]);
  React.useEffect(() => { credentialsRef.current = credentials }, [credentials]);
  React.useEffect(() => { locationRef.current = location }, [location])
  React.useEffect(() => { onValidationCompletedRef.current = onValidationCompleted }, [onValidationCompleted]);
  React.useEffect(validateStream, [])

  function validateStream(): void {
    const { search } = locationRef.current;
    const urlSearchParams = new URLSearchParams(search);

    const company = urlSearchParams.get('company');
    const site = urlSearchParams.get('site');
    const stream = urlSearchParams.get('stream');

    const kvsName = `${company}-${site}-${stream}`;

    if (permissionsRef.current) {
      const streamPermissions = permissionsRef.current[kvsName];
      if (streamPermissions) {
        const permissionsToViewStream = streamPermissions.find(el => el === "ALL" || el === "VIEW") !== undefined;
        if (permissionsToViewStream === true) {
          onValidationCompletedRef.current(200);
          return
        }
      }

      onValidationCompletedRef.current(404);
      return;
    }

    if (credentialsRef.current) {
      const options = {
        credentials: credentialsRef.current,
        region: process.env.REACT_APP_KINESIS_VIDEO_STREAMS_REGION
      };

      API.Kinesis.describeStream(kvsName, options)
        .then(() => {
          onValidationCompletedRef.current(403);
        })
        .catch(() => {
          onValidationCompletedRef.current(404);
        })

      return;
    }

    onValidationCompletedRef.current(404)
  }

  return null;
}

export default StreamValidator;
