import { Field, Operator } from '@classes/Filter/Field';
import { FieldFactory } from '@classes/Filter/FieldFactory';
import EventsFilter, { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';
import { setTutorialsEnabled } from '@store/tutorials/actions';
import { setAppBarExpanded, setAppTheme } from '@store/views/appView/actions';
import { StorageKeys } from '@store/views/appView/types';
import { setActiveSidePanel, setInboxFilter, setMuteTimeValue, setMuteTimeValueType, setOverviewOptions } from '@store/views/overviewView/actions';
import { isNumber, isString, toNumber } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

/**
 * Loads the AppTheme from the user's Local Storage.
 * Responsabilities:
 * - Loads AppTheme from the Local Storage and dispatch to Redux Store.
 * - Loads App Bar Expanded boolean from the Local Storage and dispatch to Redux Store.
 * @returns void;
 */
function useLoadUserPreferences() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    try {
      loadThemeFromStorage(dispatch);
      loadAppBarExpandedFromStorage(dispatch);
      loadOverviewOptions(dispatch);
      loadOverviewFiltersFromStorage(dispatch);
      loadOverviewMuteOptions(dispatch);
      loadTutorialsEnabled(dispatch);
    } catch (e) {
      console.log('Error fetching user preferences');
      console.log(e);
    }
  }, [dispatch])
}

export function loadThemeFromStorage(dispatcher: Dispatch<any>): void {
  const themeFromStorage = loadFromStorage(StorageKeys.appTheme)
  if (themeFromStorage) {
    dispatcher(setAppTheme(themeFromStorage as AppTheme));
  }
}

export function loadAppBarExpandedFromStorage(dispatcher: Dispatch<any>): void {
  const appBarExpandedFromStorage = loadFromStorage(StorageKeys.appBarExpanded);
  if (appBarExpandedFromStorage) {
    const bool = appBarExpandedFromStorage === 'true' ? true : false;
    dispatcher(setAppBarExpanded(bool));
  }
}

export function loadOverviewOptions(dispatcher: Dispatch<any>): void {
  const overviewFiltersExpanded = loadFromStorage('eventInboxSidePanel');
  if (overviewFiltersExpanded) {
    dispatcher(setActiveSidePanel(overviewFiltersExpanded as OverviewSidePanel));
  }

  const overViewOptions = loadFromStorage('eventInboxOptions');
  if (overViewOptions) {
    const options = JSON.parse(overViewOptions) as OverviewOptions;
    dispatcher(setOverviewOptions(options));
  }
}

export function loadOverviewMuteOptions(dispatcher: Dispatch<any>): void {
  const value = loadFromStorage("eventInboxMuteTimeValue");
  if (value && isNumber(toNumber(value))) {
    dispatcher(setMuteTimeValue(toNumber(value)))
  }

  const type = loadFromStorage("eventInboxMuteTimeValueType");
  if (type && isString(type) && (type === "hours" || type === "minutes")) {
    dispatcher(setMuteTimeValueType(type));
  }
}

function newFilterBasedOnType(type: "string" | "number" | "date" | "boolean" | "select" | "array" | "multi_select" | "duration", name: string, operators: Operator[]): Field<any, any> {
  if (type === "number") return FieldFactory.createNumberField(name, operators)
  if (type === "date") return FieldFactory.createDateField(name, operators)
  if (type === "boolean") return FieldFactory.createBooleanField(name, operators);
  if (type === "select") return FieldFactory.createSelectField(name, operators);
  if (type === "array") return FieldFactory.createArrayField(name, operators);
  if (type === "multi_select") return FieldFactory.createMultiSelectField(name, operators);
  if (type === "duration") return FieldFactory.createDurationField(name, operators);
  return FieldFactory.createStringField(name, operators)
}

function newFilters(filters: FilterToApply[]) {
  const newFilters: FilterToApply[] = [];
  filters?.forEach(f => {
    const field = newFilterBasedOnType(f.filter._type, f.filter._name, f.filter._operators);
    field.setCurrentOperator(f.filter._currentOperator)
    newFilters.push({
      filter: field,
      text: f.text
    });
  })
  return newFilters;
}

export function loadOverviewFiltersFromStorage(dispatcher: Dispatch<any>): void {
  const filterstr = loadFromStorage('overview_eventsfilter_params');
  if (filterstr) {
    const json = JSON.parse(filterstr) as EventsFilterParams;
    
    const newParams: EventsFilterParams = {
      motion: { enable: json.motion.enable, filters: newFilters(json.motion.filters) },
      people: { enable: json.people.enable, filters: newFilters(json.people.filters) },
      safety: { enable: json.safety.enable, filters: newFilters(json.safety.filters) },
      vehicles: { enable: json.vehicles.enable, filters: newFilters(json.vehicles.filters) },
      zones: { enable: json.zones.enable, filters: newFilters(json.zones.filters) },
      permissions: json.permissions,
      sites: json.sites,
      streams: json.streams
    }

    if (json) {
      const filters = new EventsFilter(newParams);
      dispatcher(setInboxFilter(filters.getCurrentParams()));
    }
  }
}

export function loadTutorialsEnabled(dispatcher: Dispatch<any>): void {
  const tutorialsEnabledFromStorage = loadFromStorage("tutorialsEnabled")
  if (tutorialsEnabledFromStorage) {
    const bool = tutorialsEnabledFromStorage === 'true' ? true : false;
    dispatcher(setTutorialsEnabled(Boolean(bool)));
  }
}

export function loadFromStorage(key: string): string | null {
  const dataFromStorage = localStorage.getItem(key);
  return dataFromStorage;
}

export default useLoadUserPreferences;
