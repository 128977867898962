import { API } from "aws-amplify";

async function listCompanies(): Promise<Company[]> {
  try {
    const path: string = "/companies";
    const data = await API.get(
      "api-service",
      path,
      {}
    )

    if (data.data) {
      // TODO: This is a temporary fix, remove when the backend is fixed.
      if (Array.isArray(data.data)) {
        return data.data as Company[];
      }
      return [data.data] as Company[];
    }
    return [];
  } catch (error: any) {
    throw error;
  }
}

export default listCompanies;
