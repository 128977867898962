class KVSDataEndpoint {
  private static _instance: KVSDataEndpoint;
  private static _database: Map<string, string> = new Map();

  private constructor () { }

  public static get instance() {
    if (!this._instance) {
      KVSDataEndpoint._instance = new KVSDataEndpoint();
    }

    return this._instance;
  }

  public getStreamEndpoint(streamName: string): string | undefined {
    return KVSDataEndpoint._database.get(streamName);
  }

  public setStreamEndPOint(streamName: string, endPoint: string) {
    return KVSDataEndpoint._database.set(streamName, endPoint);
  }
}

export default KVSDataEndpoint;
