import { API } from 'aws-amplify';
import qs from 'qs';

async function getSubcontractors(
  siteId: string, 
  asCompany?: string
): Promise<Subcontractor[]> {
  try {
    const path = `/subcontractors?${qs.stringify({
      siteId: siteId,
      asCompany: asCompany
    })}`;
    const data = await API.get('api-service', path, {});

    return data.data;
  } catch (error: any) {
    throw error;
  }
}

export default getSubcontractors;
