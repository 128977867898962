import { SnackbarKey, useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Utils from '@utils/index';
import { IStoreState } from '@store/index';
import * as uuid from 'uuid';
import { setRequestAsCanceled, setRequestAsDone, setRequestAsError, updateRequestLink } from '@store/exports/actions';
import API from '@API/index';
import { showCustomSnackbar } from './common';

/**
 * Standalone component that manages Queues for Exporting stream footage.
 */
const FootageExporter: React.FC<{}> = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const requests = useSelector((store: IStoreState) => store.exports.requests);

  const exportSnackRef = React.useRef<undefined | SnackbarKey>(undefined);
  const exportKeyRef = React.useRef<undefined | string>(undefined);
  const processingExportIdRef = React.useRef<string | undefined>(undefined);
  const exportRequestsRef = React.useRef<ExportRequest[]>([]);
  const userCanceledExports = React.useRef<string[]>([]);

  React.useEffect(updateRequestsRef, [requests]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(exportFootage, [requests]);

  function exportFootage(): void { startExport(); }
  function updateRequestsRef(): void { exportRequestsRef.current = requests; }

  async function startExport(): Promise<void> {
    if (processingExportIdRef.current !== undefined) return;

    const r = Utils.Exports.nextInQueue<ExportRequest>(exportRequestsRef.current);
    if (processingExportIdRef.current === undefined && r !== undefined) {
      processingExportIdRef.current = r.requestId;
      exportKeyRef.current = uuid.v4();
      try {
        showCustomSnackbar(
          enqueueSnackbar,
          `Generating video for ${r.streamName}.`,
          exportSnackRef,
          exportKeyRef,
          () => {
            const request = Utils.Exports.findById<ExportRequest>(exportRequestsRef.current, processingExportIdRef.current);
            dispatch(setRequestAsCanceled(request?.requestId || ""));
            if (request && request.requestId) userCanceledExports.current.push(request?.requestId);
            closeSnackbar(exportSnackRef.current);
            processingExportIdRef.current = undefined;
          }
        );
        const vd = await requestVideoDownload();
        executeWhenDownloadReady(r.kvsName, vd, r.requestId, downloadFile)
      } catch (error) {
        handleExportError(error);
      }
    }
  }

  async function executeWhenDownloadReady(kvsName: string, videoDownload: {videoDownloadId: string} | VideoDownload, requestId: string, callback: (target: VideoDownload, requestId: string) => void): Promise<void> {
    try {
      const target = await API.Downloads.getDownloadStatus(kvsName, videoDownload.videoDownloadId);
      if (target) {
        if (userCanceledExports.current.find(el => el === requestId)) {
          callback(target as VideoDownload, requestId);
          return;
        }

        if (target.status === "IN_PROGRESS") {
          setTimeout(() => {
            executeWhenDownloadReady(kvsName, videoDownload, requestId, callback);
          }, 2000)
        } else {
          callback(target as VideoDownload, requestId);
        }
      } else {
        throw new Error("Error generating export!");
      }
    } catch (error) {
      handleExportError(error);
    }
  }

  async function requestVideoDownload(): Promise<{ videoDownloadId: string }> {
    const request = Utils.Exports.findById<ExportRequest>(exportRequestsRef.current, processingExportIdRef.current);

    if (request) {
      try {
        const vd = await API.Downloads.postDownloadStart(request.kvsName, request.timerange.start, request.timerange.end, request.options, request.name, request.description);
        if (vd === undefined) throw new Error("Error generating video...")
        return { ...vd };
      } catch (error) {
        throw error;
      }
    }

    throw new Error("Request not found");
  }

  async function downloadFile(target: VideoDownload, requestId: string) {
    if (userCanceledExports.current.find(el => el === requestId)) {
      API.Downloads.cancelVideoDownload(target.streamName, target.videoDownloadId);
      return;
    }
    
    try {

      if (target.status === "FAILURE") {
        handleExportError(new Error(`Export generation finished with status of: ${target.status}.`));
        return;
      }

      const request = Utils.Exports.findById<ExportRequest>(exportRequestsRef.current, processingExportIdRef.current);
      if (request) {
        const link = await API.Downloads.getDownloadVideoLink(target.location);
        dispatch(updateRequestLink(request.requestId, link));
      }

      if (request) {
        dispatch(setRequestAsDone(request.requestId, target.videoDownloadId));
      }
    } catch (error) {
      handleExportError(error);
    } finally {
      processingExportIdRef.current = undefined;
      closeSnackbar(exportSnackRef.current);

      // Get the next item on the request stack if any.
      setTimeout(() => {
        startExport();
      }, 100)
    }
  }

  function handleExportError(error: any): void {
    handleError(error, exportSnackRef);
    const target = Utils.Exports.findById(exportRequestsRef.current, processingExportIdRef.current);
    if (target) dispatch(setRequestAsError(target.requestId));
    processingExportIdRef.current = undefined;
  }

  function handleError(error: any, snackbarRef: React.MutableRefObject<SnackbarKey | undefined>): void {
    closeSnackbar(snackbarRef.current);
    enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: "error" });
  }

  return null;
}

export default FootageExporter;
