import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

const CloseSnackbarAction: React.FC<{ id: string | number }> = ({ id }: any) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton
      style={{ marginTop: "0.1em", color: 'white' }}
      onClick={() => { closeSnackbar(id) }}
      data-cy="snackbar-close-button"
    >
      <Close />
    </IconButton>)
}

export default CloseSnackbarAction;