import findById from './findById';
import { findLeastRequiredFilter } from './findLeastRequiredFilter';
import inProgress from './inProgress';
import isUrlSet from './isUrlSet';
import nextInQueue from './nextInQueue';

export interface IExport {
  /**
   * Returns true if the export is found and met criteria to be "in progress".
   * @param requests List of requests that are being processed or were processed.
   * @param request Selected request to evaluate.
   * @example
   * const requests = useSelector((store: IStoreState) => store.exports.requests);
   * 
   * const isMyRequestInProgress = Utils.ExportRequest.inProgress(requests, mySelectedRequest);
   */
   inProgress(requests: { kvsName: string, progress: number, error: boolean }[], request?: { kvsName: string }): boolean,

  /**
   * Assert if a request has a valid URL set so it can be displayed as video.
   * @param request Request object.
   * @return {boolean} True if the URL is valid.
   * @example
   * const request1 = { ..., url: "https://s3...../video.mp4" };
   * const request2 = { ..., url: undefined };
   * const request3 = { ..., url: "" };
   * 
   * console.log(Utils.ExportRequest.isUrlSet(request1)); // => 'true'
   * console.log(Utils.ExportRequest.isUrlSet(request2)); // => 'false'
   * console.log(Utils.ExportRequest.isUrlSet(request2)); // => 'false'
   */
  isUrlSet(request?: { url: string }): boolean,

  /**
   * Find Request within collection that matches the given ID.
   * @param requests Collection of Requests (BaseRequest).
   * @param id ID to be found.
   * @example
   * const id: string = "forsight";
   * const collection: BaseRequest[] = [ {..., requestId: "es"}, { ..., requestId: "forsight" } ];
   * const target: ExportRequest = Utils.ExportRequest.findById<ExportRequest>(collection, id);
   * console.log(target); // => { ..., requestId: "forsight" };
   */
  findById<T extends BaseRequest>(requests: T[], id: string | undefined): T | undefined,

  /**
   * Finds the next request to be processed.
   * @param requests Collection of Requests (BaseRequest)
   * @example
   * const collection: ExportRequest[] = [{ id: "request1", progress: 100}, { id: "request2", progress: 0 }];
   * const target: ExportRequest | undefined = Utils.ExportRequest.nextInQueue<ExportRequest>(collection);
   * console.log(target); // => { id: "request2", progress: 0 }
   */
  nextInQueue<T extends BaseRequest>(requests: T[]): T | undefined;
  findLeastRequiredFilter(targetTimestamp: number, timezone: string): 'Last 7 days' | 'Last 14 days' | 'Last 30 days' | 'Last 90 days' | 'Custom'
}

const Exports: IExport = {
  inProgress,
  isUrlSet,
  findById,
  nextInQueue,
  findLeastRequiredFilter
}

export default Exports;
