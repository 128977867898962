import { findIndex, sum, toNumber } from "lodash";
import Utils from '@utils/index';

export type EventsReportData = Array<{
  id: string;
  path: Array<string>;
  companyId: string;
  companyName: string;
  siteId: string;
  siteName: string;
  kvsName: string;
  streamName: string;
  escalate_theft: number;
  escalate_contact_police: number;
  escalate_contact_manager: number;
  escalate_fire: number;
  escalate_damage: number;
  escalate_open_door_gate: number;
  escalate_check_subject: number;
  escalate_facility_issue: number;
  true_positive_person: number;
  true_positive_vehicle: number;
  false_positive_animal: number;
  false_positive_light: number;
  false_positive_weather: number;
  false_positive_unknown: number;
  false_positive_other: number;
  muted: number;
  type: "COMPANY" | "SITE" | "CAMERA"
}>

class EventsReportConverter {
  private companies: Company[] | null = null;
  private sites: Map<string, Site[]> | null = null;
  private streams: VideoStream[] | null = null;

  constructor (companies: Company[], sites: Map<string, Site[]>, streams: VideoStream[]) {
    this.companies = companies;
    this.sites = sites;
    this.streams = streams;
  }

  public convert(cubeJsData: any[]): EventsReportData {
    try {
      const data: EventsReportData = [];

      cubeJsData.forEach((row) => {
        let kvsName: string = '';
        let tag: string = '';
        let count: number = 0;

        for (const key of Object.keys(row)) {
          if (key.includes('.source')) {
            kvsName = row[key] as string;
          }

          if (key.includes('.tag')) {
            tag = row[key] as string;
          }

          if (key.includes('.count')) {
            count = toNumber(row[key]);
          }
        }

        const target = data.find(el => el.kvsName === kvsName);
        if (!target) {
          const stream = this.streams?.find(el => el.kvsName === kvsName);
          const companyId = stream?.companyId || "";
          const siteId = stream?.siteId || "";

          const company = this.companies?.find(el => el.companyId === companyId);
          const site = this.sites?.get(companyId || "")?.find(el => el.siteId === siteId);

          if (company && site && stream) {
            data.push({
              id: stream.kvsName,
              path: ["All Selected Sites", site.name, stream.kvsName],
              companyId: company.companyId,
              companyName: company.name,
              siteId: site.siteId,
              siteName: site.name,
              kvsName: stream.kvsName,
              streamName: stream.defaultName || stream.kvsName,
              escalate_theft: 0,
              escalate_contact_police: 0,
              escalate_contact_manager: 0,
              escalate_fire: 0,
              escalate_check_subject: 0,
              escalate_damage: 0,
              escalate_facility_issue: 0,
              escalate_open_door_gate: 0,
              false_positive_animal: 0,
              false_positive_light: 0,
              false_positive_other: 0,
              false_positive_unknown: 0,
              false_positive_weather: 0,
              true_positive_person: 0,
              true_positive_vehicle: 0,
              muted: 0,
              type: "CAMERA"
            })
          }
        }

        const index = findIndex(data, { kvsName });
        if (data[index]) {
          //@ts-ignore
          data[index][tag] = count;
        }

      })

      const siteSet: Set<string> = new Set<string>();
      data.forEach((d) => siteSet.add(d.siteId));
      const companies: Map<Company, Array<Site>> = new Map<Company, Array<Site>>();

      this.companies?.forEach((company) => {
        const siteIdsArray: Array<string> = Array.from(siteSet);
        const companySites: Array<Site> | undefined = this.sites?.get(company.companyId);
        if (companySites) {
          const allSites: Site[] = Utils.Array.orderAlphabetically(companySites.filter(el => siteIdsArray.includes(el.siteId)), 'name');
          companies.set(company, allSites);
        }
      })

      companies.forEach((value, key) => {
        value.forEach((site) => {
          data.push({
            id: site.siteId,
            path: ["All Selected Sites", site.name],
            companyId: key.companyId,
            companyName: key.name,
            siteId: site.siteId,
            siteName: site.name,
            kvsName: "",
            streamName: "",
            escalate_theft: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.escalate_theft)),
            escalate_contact_police: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.escalate_contact_police)),
            escalate_contact_manager: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.escalate_contact_manager)),
            escalate_fire: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.escalate_fire)),
            escalate_check_subject: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.escalate_check_subject)),
            escalate_damage: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.escalate_damage)),
            escalate_facility_issue: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.escalate_facility_issue)),
            escalate_open_door_gate: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.escalate_open_door_gate)),
            false_positive_animal: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.false_positive_animal)),
            false_positive_light: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.false_positive_light)),
            false_positive_other: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.false_positive_other)),
            false_positive_unknown: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.false_positive_unknown)),
            false_positive_weather: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.false_positive_weather)),
            true_positive_person: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.true_positive_person)),
            true_positive_vehicle: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.true_positive_vehicle)),
            muted: sum(data.filter(el => el.type === "CAMERA").filter(el => el.siteId === site.siteId).map(el => el.escalate_theft)),
            type: "SITE"
          });
        })

        if (data.length > 0)
          data.push({
            id: key.companyId,
            path: ["All Selected Sites"],
            companyId: key.companyId,
            companyName: key.name,
            siteId: "",
            siteName: "",
            kvsName: "",
            streamName: "",
            escalate_theft: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.escalate_theft)),
            escalate_contact_police: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.escalate_contact_police)),
            escalate_contact_manager: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.escalate_contact_manager)),
            escalate_fire: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.escalate_fire)),
            escalate_check_subject: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.escalate_check_subject)),
            escalate_damage: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.escalate_damage)),
            escalate_facility_issue: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.escalate_facility_issue)),
            escalate_open_door_gate: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.escalate_open_door_gate)),
            false_positive_animal: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.false_positive_animal)),
            false_positive_light: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.false_positive_light)),
            false_positive_other: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.false_positive_other)),
            false_positive_unknown: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.false_positive_unknown)),
            false_positive_weather: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.false_positive_weather)),
            true_positive_person: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.true_positive_person)),
            true_positive_vehicle: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.true_positive_vehicle)),
            muted: sum(data.filter(el => el.companyId === key.companyId).filter(el => el.type === "SITE").map(el => el.escalate_theft)),
            type: "COMPANY"
          });
      })

      return Utils.Array.orderAlphabetically(data, 'streamName');
    } catch (error) {
      console.log(error);
      return [];
    }
  }
}

export default EventsReportConverter;
