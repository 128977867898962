import { Storage } from 'aws-amplify';

/**
 * Gets image URL from a given key from the S3 Storage, it places the url as a parameter on the target object.
 * @param key s3 bucket path given back by the Storage.list method.
 * @param target Object to spread with the url.
 * @param bucket (Optional) bucket to get the url from.
 * @returns object with url parameter and all other given properties on the target.
 */
async function getImageUrl(key: string, target: object, bucket?: string): Promise<{ url: string }> {
  const url = await Storage.get(key, {
    bucket: bucket || "",
    expires: 86400 //seconds
  }) as string;

  return {
    ...target,
    url
  }
}

export default getImageUrl;
