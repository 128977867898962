export function createVideoStreamFilters(isSuperAdministrator?: boolean): FilterProperties[] {
  return isSuperAdministrator ? [
    { label: 'Name', property: 'defaultName', type: 'string' },
    { label: 'KVS Name', property: 'kvsName', type: 'string' },
    { label: 'Site ID', property: 'siteId', type: 'string' },
    { label: 'Manufacturer', property: 'manufacturer', type: 'string' },
    { label: 'Position', property: 'position', type: 'string' },
    { label: 'RPORT ID', property: 'rportCameraId', type: 'string' },
    { label: 'RTSP URL', property: 'rtspUrl', type: 'string' },
    { label: 'RTSP URL Proc', property: 'rtspUrlProc', type: 'string' },
    { label: 'IoT Thing Name', property: 'iotThingName', type: 'string' },
    { label: 'Device Shadow Name', property: 'deviceShadowName', type: 'string' },
    { label: 'Retention Period in Hours', property: 'retentionPeriodInHours', type: 'number' },
    { label: 'Angle East of North', property: 'angleEastOfNorth', type: 'number' },
    { label: 'FOV Width', property: 'fovWidth', type: 'number' },
    { label: 'FOV Range', property: 'range', type: 'number' },
    { label: 'Latitude', property: 'latitude', type: 'number' },
    { label: 'Longitude', property: 'longitude', type: 'number' },
    { label: 'Created', property: 'createdAt', type: 'date' },
    { label: 'Updated', property: 'updatedAt', type: 'date' },
    { label: 'Perform decoding', property: 'performDecoding', type: 'boolean' },
    { label: 'State', property: 'state', type: 'select', options: ["ACTIVE", "ARCHIVED"] },
    { label: 'Status', property: 'statusOnDevice', type: 'select', options: ["online", "offline", "invalid_configuration", "invalid_rtsp"] },
    { label: 'Tags', property: 'tags', type: 'array' },
    { label: 'Blurry', property: 'isBlurry', type: 'boolean' }
  ] : [
    { label: 'Name', property: 'defaultName', type: 'string' },
    { label: 'KVS Name', property: 'kvsName', type: 'string' },
    { label: 'State', property: 'state', type: 'select', options: ["ACTIVE", "ARCHIVED"] },
    { label: 'Created', property: 'createdAt', type: 'date' },
    { label: 'Updated', property: 'updatedAt', type: 'date' },
  ]
}