import { API } from "aws-amplify";

export async function getBlacklistedWorkers(asCompany?: string): Promise<CrewWorker[]> {
  try {
    const path: string = `/workers/blacklisted` + (asCompany ? `?asCompany=${asCompany}` : "");

    const data = await API.get('api-service', path, {});
    return data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}