import findNextItem from './findNextItem';
import findPreviousItem from './findPreviousItem';
import findClosestTimestamp from './findClosestTimestamp';
import getDifferenceInSeconds from './getDifferenceInSeconds';
import formatToUTCString from './formatToUTCString';
import containsWeekend from './containsWeekend';
import nextTimeRange from './nextTimeRange';
import previousTimeRange from './previousTimeRange';
import edgeTimestampToMiliseconds from './removeLastDigits';
import isBetween from './isBetween';

export interface ITimestamp {
  findNextItem: (items: { timestamp: number }[], timestamp: number, maxRange?: number) => object | undefined;
  findPreviousItem: (items: { timestamp: number }[], timestamp: number, maxRange?: number) => object | undefined;
  findClosestTimestamp: (items: { timestamp: number }[], timestamp: number) => object | undefined;
  getDifferenceInSeconds: (startTime: number, endTime: number) => string | undefined;
  formatToUTCString: (timestamp: number, showHour?: boolean, utcOffset?: number, timezone?: string) => string
  containsWeekend(start: number, end: number, timezone: string): boolean;
  edgeTimestampToMiliseconds(edgeTimestamp: number): number;

  /**
   * Method that returns the next time chunk considering the difference between two timestamps.
   * @param start timestamp
   * @param end timestamp
   */
  nextTimeRange(start: number, end: number): { start: number, end: number };

  /**
   * Method that returns previous time chunk considering the difference between two timestamps.
   * @param start timestamp
   * @param end timestamp
   */
  previousTimeRange(start: number, end: number): { start: number, end: number };

  /**
   * Assert if a timestamp is between a start and range timestamp.
   * @param timestamp target Timestamp.
   * @param range timestamp window to assert.
   */
  isBetween(timestamp: number, range: { start: number, end: number }): boolean ;
}

const Timestamp: ITimestamp = {
  findNextItem,
  findPreviousItem,
  findClosestTimestamp,
  getDifferenceInSeconds,
  formatToUTCString,
  containsWeekend,
  nextTimeRange,
  previousTimeRange,
  edgeTimestampToMiliseconds,
  isBetween
}

export default Timestamp;
