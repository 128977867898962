import { PrometheusQuery } from "./PrometheusQuery";
import {utc} from 'moment';

export class NodeBootTimeSecondsQuery extends PrometheusQuery {
  protected _id: string = "node_boot_time_seconds";

  public override getPossibleValues() {
    return [
      { id: "Boot time", color: "#0048e3" }
    ]
  };
  
  public override getRefinedData() {
    const refinedData = [];

    if (this.isEmpty()) return [];
    
    for (let i = 0; i < this._queryData.data.result[0].values.length; i += 1) {
      refinedData.push({
        date: this.formatDate(this._queryData.data.result[0].values[i][0]),
        "Boot time": this.secondsToHours(this._queryData.data.result[0].values[i][1])
      })
    }

    return refinedData;
  }

  public override getDomain() {
    return undefined;
  }

  public override getValueLabel() {
    return "";
  }

  private secondsToHours(seconds: string | number) {
    const formatted = utc(+seconds*1000).format('HH:mm:ss')
    return formatted;
  }
}
