import React from 'react';
import styled from 'styled-components';
import { Avatar, Box, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip, Typography } from '@mui/material';
import { DraftsOutlined, InfoOutlined, Login, Logout, Share } from '@mui/icons-material';
import StreamEvents, { ColorMap, BackgroundMap, BordersMap } from '@classes/StreamEvents/StreamEvents';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';
import ZoneThumbnail from '@components/common/ZoneThumbnail';
import _ from 'lodash';

const StyledList = styled(List) <{ ongoing?: boolean }>`
  :hover {
    .MuiListItemSecondaryAction-root {
      transition: opacity 0.3s !important;
      opacity: 1 !important;
    }
  }

  .MuiListItemSecondaryAction-root {
    transition: opacity 0.3s !important;
  }
`

const LI = styled(Box) <{ ongoing?: boolean }>`
  background-color: transparent;
  animation-name: ${props => props.ongoing === true ? 'color' : undefined};
  animation-duration: 1s;
  animation-iteration-count: ${props => props.ongoing === true ? 'infinite' : undefined};

  @keyframes color {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: rgba(0, 72, 227, 0.08);
    }
    100% {
      background-color: transparent;
    }
  }
`

const ColoredAvatar = styled(Avatar) <{ color?: string, background?: string, border?: string }>`
  border: ${props => props.border};
  background: ${props => props.background};
  width: 2rem;
  height: 2rem;
  
  svg {
    color: ${props => props.color}
  }
`

type EventProps = {
  event: VideoStreamEvent;
  zones: Zone[];
  rowHeight: number;
  onEventClick?: (event: VideoStreamEvent, animate?: boolean) => void;
  stream?: VideoStream;
  timezone?: string;
  onMouseEnterEvent?: (event: VideoStreamEvent) => void;
  onMouseLeaveEvent?: () => void;
  onMarkAsReadClick?: (data: VideoStreamEvent) => void;
  onInformationClick?: (data: VideoStreamEvent) => void;
}

const EventListItem: React.FC<EventProps> = (props) => {
  const {
    event,
    onEventClick,
    stream,
    timezone,
    onMouseEnterEvent,
    onMouseLeaveEvent,
    zones,
    rowHeight,
    onMarkAsReadClick,
    onInformationClick
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [zone, setZone] = React.useState<Zone | undefined>(undefined);
  const [loadingUrl, setLoadingUrl] = React.useState<boolean>(false);

  const eventRef = React.useRef(event);
  const zoneRef = React.useRef(zone);

  React.useEffect(() => { eventRef.current = event }, [event])
  React.useEffect(() => { zoneRef.current = zone }, [zone])

  React.useEffect(() => {
    if (eventRef.current.eventType === "Geofence triggered" && zoneRef.current === undefined) {
      const zone = zones.find(el => eventRef.current.zoneId?.includes(el.id));
      if (zone) {
        setZone(zone);
      }
    }
  }, [zones])

  function generateEventTimestamp(event: VideoStreamEvent): string {
    return `${Utils.Date.format(new Date(event.startDate), "MMM Do, YYYY h:mm:ss A", timezone)} - ${Utils.Timestamp.getDifferenceInSeconds(event.startDate, event?.endDate || event?.currentEnd || Date.now())}`
  }


  function generateEventTitle(event: VideoStreamEvent): string {
    const { eventType, labels } = event;

    if (eventType === 'Motion') {
      if (labels) {
        const tempLabels: string[] = [];
        if (labels.find(el => el === 'person'))
          tempLabels.push('Person');
        if (labels.find(el => el === 'vehicle'))
          tempLabels.push(tempLabels.length ? 'vehicle' : 'Vehicle');
        if (labels.find(el => el === 'infrared_mode_on'))
          tempLabels.push('Infrared mode ON');
        if (labels.find(el => el === 'infrared_mode_off'))
          tempLabels.push('Infrared mode OFF');
        if (labels.find(el => el === 'light_changed'))
          tempLabels.push(tempLabels.length ? 'lighting changed' : 'Lighting changed');
        if (labels.find(el => el === 'tampering'))
          tempLabels.push(tempLabels.length ? 'tampering' : 'Tampering');
        if (tempLabels.length)
          return tempLabels.join(" and ") + " detected"
      }

      return 'Motion detected';
    }

    if (eventType === "Viewpoint changed") {
      return `Viewpoint changed detected`;
    }

    if (eventType === 'Geofence triggered') {
      const zone = zones.find(el => event.zoneId?.includes(el.id));
      if (zone) {
        return `"${zone.name || 'Unknown'}" triggered`;
      }
      return 'Zone triggered';
    }
    if (eventType === 'Safety infraction') return 'Safety infraction';

    if (eventType === 'Person detected') return 'Person detected';

    if (eventType === 'Vehicle detected') {
      if (event.metadata && event.metadata.plate) {
        return `${event.metadata.plate}`;
      }
      if (event.labels) {
        let title: string = '';
        for (let i = 0; i < event.labels.length; i += 1) {
          title += event.labels[i];
          if (i + 1 < event.labels.length) {
            title += ', ';
          }
        };

        title += " detected";
        return title;
      }
      return 'Vehicle detected';
    };

    // legacy
    if (eventType === 'Social distancing') return 'People too close by at least 5m';
    if (eventType === 'Fire detected') return 'Fire detected';
    if (eventType === 'Vehicle detected') {
      if (event.metadata && event.metadata.plate) {
        return `${event.metadata.plate}`;
      }
      if (event.labels) {
        let title: string = '';
        for (let i = 0; i < event.labels.length; i += 1) {
          title += event.labels[i];
          if (i + 1 < event.labels.length) {
            title += ', ';
          }
        };

        title += " detected";
        return title;
      }
      return 'Vehicle detected';
    };

    return 'Motion detected';
  }

  function generateEventTitleIcon(event: VideoStreamEvent): React.ReactNode {
    const { eventType } = event;

    if (eventType === 'Vehicle detected') {
      if (event.metadata?.orientation) {
        return event.metadata.orientation === "front"
          ? <Login fontSize='inherit' color="success" />
          : event.metadata.orientation === "rear"
            ? <Logout fontSize='inherit' color="error" />
            : null
      }
    }

    return <></>
  }

  function generateEventSubtitle(event: VideoStreamEvent): string {
    const { eventType } = event;

    if (eventType === 'Vehicle detected') {
      if (event.metadata && event.metadata.plate) {
        const { make, model, color, region } = event.metadata;
        const formattedColor = _.capitalize(color) || "";
        const formattedMake = make || "";
        const formattedModel = model ? (" " + model) : "";
        const countryAndRegion = region?.split("-");
        const country = countryAndRegion ? countryAndRegion[0].toUpperCase() : "";
        const formattedRegion = countryAndRegion ? (" " + _.capitalize(countryAndRegion[1])) : "";
        return `${formattedColor}${((make || model) && color) ? " - " : ""}${formattedMake}${formattedModel}${(make || model || color) ? " - " : ""}${country}${formattedRegion}`;
      }
    };

    return "";
  }

  async function handleShareUrl(): Promise<void> {
    setLoadingUrl(true);
    try {
      const url = await Utils.URL.shorten(Utils.Stream.getStreamShareUrl(stream as VideoStream, event.startDate))
      navigator.clipboard.writeText(url);
      enqueueSnackbar('URL copied to clipboard!', { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar('Something went wrong!', { variant: 'error' })
    } finally {
      setLoadingUrl(false);
    }
  }

  const eventColors = React.useMemo(() => {
    const { metadata, eventType } = event;
    if (eventType === "Vehicle detected") {
      const color = metadata?.color;
      return {
        color: color ? (color === "white" ? "black" : "white") : "#ccc",
        background: color || "linear-gradient(45deg, #ccc 10%, transparent 10%, transparent 20%, #ccc 20%, #ccc 30%, transparent 30%, transparent 40%, #ccc 40%, #ccc 50%, transparent 50%, transparent 60%, #ccc 60%, #ccc 70%, transparent 70%, transparent 80%, #ccc 80%, #ccc 90%, transparent 90%, transparent 100%, #fff)",
        border: "1px solid " + (color ? (color === "White" ? "black" : color) : "#ccc")
      }
    }
    return {
      color: ColorMap.get(eventType),
      background: BackgroundMap.get(eventType),
      border: BordersMap.get(eventType)
    }
  }, [event])

  return (
    <StyledList >
      <LI ongoing={event.ongoing}>
        <ListItem
          onMouseEnter={() => { setTimeout(() => { if (onMouseEnterEvent) onMouseEnterEvent(event); }, 5) }}
          onMouseLeave={() => { if (onMouseLeaveEvent) onMouseLeaveEvent() }}
          onClick={() => {
            if (onMarkAsReadClick && event.new === true) onMarkAsReadClick(event);
            if (onEventClick) onEventClick(event, true);
          }}
          selected={event.new}
          style={{ height: `calc(${rowHeight}px - 0.25rem)`, borderRadius: "0.25rem", gap: "0.75rem" }}
          button={true}
        >
          <ListItemAvatar style={{ minWidth: 0 }}>
            {zone === undefined ? (
              <ColoredAvatar {...eventColors}>
                {StreamEvents.getEventIcon(event)}
              </ColoredAvatar>
            ) : (
              <ZoneThumbnail zone={zone} />
            )}
          </ListItemAvatar>
          <ListItemText
            secondary={
              <Typography style={{ fontWeight: event.new || event.ongoing ? 700 : undefined }} variant="caption" color={event.new || event.ongoing ? 'textPrimary' : 'textSecondary'}>
                {generateEventTimestamp(event)}
              </Typography>
            }
          >
            <Box display="grid" width="100%" gridTemplateColumns="auto" paddingRight="2rem">
              <Box display="grid" gridTemplateColumns="min-content auto" alignItems={"center"} gap="0.25rem">
                {generateEventTitleIcon(event)}
                <Typography style={{ fontWeight: event.new || event.ongoing ? 700 : undefined, height: "1rem" }} noWrap={true} variant="body2" color="textPrimary">
                  {generateEventTitle(event)}
                </Typography>
              </Box>
              {generateEventSubtitle(event) &&
                <Typography style={{ fontWeight: event.new || event.ongoing ? 700 : undefined, height: "1rem" }} noWrap={true} variant="caption" color="textPrimary">
                  {generateEventSubtitle(event)}
                </Typography>}
            </Box>
          </ListItemText>
          <ListItemSecondaryAction>
            <Box paddingRight="8px" display="flex" flexDirection="row">
              {event.new && onMarkAsReadClick &&
                <Tooltip title="Mark as read">
                  <IconButton onClick={() => { onMarkAsReadClick(event) }} size="small">
                    <DraftsOutlined />
                  </IconButton>
                </Tooltip>
              }
              {event.metadata && onInformationClick &&
                <Tooltip title="Additional information">
                  <IconButton onClick={() => { onInformationClick(event) }} size="small">
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              }
              {loadingUrl ? (
                <IconButton size="small" disabled={true}>
                  <CircularProgress size="1rem" />
                </IconButton>
              ) : (
                <Tooltip title="Share event URL">
                  <IconButton size="small" onClick={handleShareUrl}>
                    <Share />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
      </LI>
    </StyledList>
  );
}

export default EventListItem;
