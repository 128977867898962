import { ActionTypes, IAppViewState } from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IAppViewState = {
  theme: 'light',
  isLoadingBasicData: true,
  appBarExpanded: false,
  rootFontSize: 16,
  errorLoadingCompanies: undefined,
  errorLoadingUserData: undefined,
  errorLoadingSites: undefined,
  appBarAnimationEndedAt: Date.now()
};

export const appViewReducer = Utils.Redux.createReducer<IAppViewState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_APP_THEME]: (state, action) => ({
      ...state,
      theme: action.theme
    }),

    [ActionTypes.SET_LOADING_BASIC_DATA]: (state, action) => ({
      ...state,
      isLoadingBasicData: action.value
    }),

    [ActionTypes.SET_APP_BAR_EXPANDED]: (state, action) => ({
      ...state,
      appBarExpanded: action.value
    }),

    [ActionTypes.SET_ROOT_FONT_SIZE]: (state, action) => ({
      ...state,
      rootFontSize: action.value
    }),

    [ActionTypes.SET_ERROR_LOADING_COMPANIES]: (state, action) => ({
      ...state,
      errorLoadingCompanies: action.value
    }),

    [ActionTypes.SET_ERROR_LOADING_USER_DATA]: (state, action)=> ({
      ...state,
      errorLoadingUserData: action.value
    }),

    [ActionTypes.SET_ERROR_LOADING_SITES]: (state, action) => ({
      ...state,
      errorLoadingSites: action.value
    }),

    [ActionTypes.SET_APP_BAR_ANIMATION_ENDED]: (state, action) => ({
      ...state,
      appBarAnimationEndedAt: action.value
    })
  }
);
