import { Box } from '@mui/material';
import React from 'react';
import Utils from '@utils/index';
import mapboxgl from 'mapbox-gl';
import API from '@API/index';
import { MapboxStyleSwitcherControl } from '../../include/mapbox-gl-style-switcher/MapBoxGlStyleSwitcherControl';
import { matrix, multiply } from 'mathjs';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';

type StreamSAtelliteViewProps = {
  containerWidth: number;
  containerHeight: number;
}

const FOV_LINES_CANVAS_ID = 'fov-line-source';
const FOV_LINES_LAYER_LINE_ID = 'fov-line-layer';

export interface IStreamSatelliteViewHandles {
  draw: (boxes: EventBoundingBox | undefined) => void;
}

const StreamSatelliteView: React.ForwardRefRenderFunction<IStreamSatelliteViewHandles, StreamSAtelliteViewProps> = (props, ref) => {
  const { containerWidth, containerHeight } = props;

  const selectedStream = useSelector((store: IStoreState) => store.selectedStream.stream);
  const site = useSelector((store: IStoreState) => store.selectedSites.selectedSite);

  const mapContainer = React.useRef<HTMLDivElement | null>(null);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const selectedSteamRef = React.useRef(selectedStream);
  const siteRef = React.useRef(site);

  React.useEffect(() => { siteRef.current = site }, [site])
  React.useEffect(() => { selectedSteamRef.current = selectedStream }, [selectedStream])

  const loadMap = React.useCallback(() => {
    if (mapContainer.current && !map.current) {
      const mapOptions = {
        container: mapContainer.current,
        style: Utils.MapBox.generateStylesUrl('satellite'),
        center: new mapboxgl.LngLat(
          selectedSteamRef.current?.longitude || siteRef.current?.coordinates?.longitude || 15.975834198824849,
          selectedSteamRef.current?.latitude || siteRef.current?.coordinates?.latitude || 45.79974685471517),
        zoom: 18
      }

      map.current = API.MapBox.initializeMap(mapOptions);
      map.current.on('load', () => {
        const styleSwitcher = new MapboxStyleSwitcherControl();
        map.current?.addControl(styleSwitcher, "bottom-right");
        map.current?.addControl(new mapboxgl.NavigationControl(), "bottom-right")
        map.current?.resize();
      });

      map.current.on('style.load', () => {

      })
    }
  }, [])

  React.useEffect(loadMap, [loadMap])

  React.useImperativeHandle(ref, () => ({
    draw
  }))

  const calculateGPSFromCameraPosition = React.useCallback((box: BoundingBox, hMatrix: number[][]) => {
    console.log(hMatrix);
    // Assume bottom center as the point for simplicity
    const [x, y] = [(box.coordinates[0][0] + box.coordinates[1][0]) / 2, box.coordinates[3][1]];

    // Projection step
    const homogenousPoint = [x, y, 1];
    const point = multiply(matrix(hMatrix), homogenousPoint).toArray();

    const a = Number(point[0]) / Number(point[2]);
    const b = Number(point[1]) / Number(point[2]);

    return {
      color: box.color,
      coordinates: {
        lat: a,
        lng: b
      }
    }
  }, [])

  function draw(boxes: EventBoundingBox | undefined) {
    if (boxes) {
      const detections = boxes.boxes.map(el => {
        return calculateGPSFromCameraPosition(el, el.satelliteParameters?.homographyMatrix || [])
      });

      const colorsArray = ['grey', 'limegreen', 'blue']

      colorsArray.forEach((color) => {
        if (map.current) {
          const lineSource = map.current.getSource(`${FOV_LINES_CANVAS_ID}-${color}`);

          if (!lineSource) {
            map.current.addSource(`${FOV_LINES_CANVAS_ID}-${color}`, {
              type: 'geojson',
              data: {
                type: "FeatureCollection",
                features: detections.map(el => ({
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: new mapboxgl.LngLat(el.coordinates.lng, el.coordinates.lat).toArray()
                  }
                }))

              }
            })
          }

          const lineSourceCast = lineSource as mapboxgl.GeoJSONSource;

          if (lineSourceCast) {
            lineSourceCast.setData({
              type: "FeatureCollection",
              features: detections.filter(el => el.color === color).map(el => ({
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: new mapboxgl.LngLat(el.coordinates.lng, el.coordinates.lat).toArray()
                }
              }))
            });
          }

          const lineLayer = map.current.getLayer(`${FOV_LINES_LAYER_LINE_ID}-${color}`);
          if (!lineLayer) {
            map.current.addLayer({
              id: `${FOV_LINES_LAYER_LINE_ID}-${color}`,
              type: "circle",
              source: `${FOV_LINES_CANVAS_ID}-${color}`,
              layout: {

              },
              paint: {
                "circle-color": color as string,
                "circle-radius": 4,
              }
            })
          }
        }
      })
    }
  }

  return (
    <Box
      position="absolute"
      zIndex={0}
      width={containerWidth}
      height={containerHeight}
      style={{ transform: 'translate(-1rem, 0)' }}
      display="flex"
      flexDirection="row"
      alignItems="flex-end"
      justifyContent="flex-end"
      bgcolor="black"
    >
      <div style={{ width: '100%', height: '100%' }} ref={mapContainer} className="map-container"></div>
    </Box>
  )
}


export default React.forwardRef(StreamSatelliteView);
