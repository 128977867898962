import React from 'react';
import API from '@API/index';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';
import { setDevices } from '@store/assets/actions';

function useUpdateEdgeDevices(
  companyId?: string,
  deps?: React.DependencyList
) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState<boolean>(true);

  const perform = React.useCallback(() => {
    if (companyId) {
      API.EdgeDevices.listDevices('All', undefined, companyId)
        .then((devices) => {
          dispatch(setDevices(devices))
        })
        .catch((error) => {
          enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' })
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }, [dispatch, companyId, enqueueSnackbar])

  React.useEffect(perform, [...(deps || []), companyId, perform])
  return loading;
}

export default useUpdateEdgeDevices;
