import { Avatar, Box, Chip, Typography } from '@mui/material';
import { first } from 'lodash';
import React from 'react';

type TagSelectionChipsProps = {
  onTagSelected: (tag: EventTag) => void;
  hasPermissionToEditEvent: boolean;
  event?: VideoStreamEvent;
  events?: VideoStreamEvent[];
}

const TagSelectionChips: React.FC<TagSelectionChipsProps> = (props) => {
  const { onTagSelected, event, events, hasPermissionToEditEvent } = props;

  function getOpacity(tag: EventTag): number {
    if (events) {
      const areAllEventsTheSameTag = events.filter(el => first(el.tags) === first(first(events)?.tags)).length === events.length;
      if (areAllEventsTheSameTag) {
        const currentSelectedTag = first(first(events)?.tags);
        if (currentSelectedTag) {
          if (currentSelectedTag !== tag) return 0.5;
        }
      }
    }

    const currentSelectedTag = first(event?.tags);
    if (currentSelectedTag) {
      if (currentSelectedTag !== tag) return 0.5;
    }

    return 1;
  }

  return (
    <Box>
      <Box width="100%" display="grid" justifyContent="flex-start">
        <Typography variant="h6">Assign a tag from the list:</Typography>
      </Box>
      <Box justifyContent="flex-start" width="100%" display="grid" gridTemplateColumns="repeat(auto-fill, minmax(6rem, min-content))">
        <Chip data-cy="event-person-tag" style={{ opacity: getOpacity("true_positive_person"), minHeight: '1.5rem' }} onClick={() => onTagSelected("true_positive_person")} variant="outlined" size="small" avatar={<Avatar style={{ backgroundColor: 'rgba(82, 169, 130, 0.2)', minHeight: '1rem', minWidth: '1rem' }}>1</Avatar>} color={!hasPermissionToEditEvent ? "default" : "success"} label="Person" disabled={!hasPermissionToEditEvent} />
        <Chip data-cy="event-vehicle-tag" style={{ opacity: getOpacity("true_positive_vehicle"), minHeight: '1.5rem' }} onClick={() => onTagSelected("true_positive_vehicle")} variant="outlined" size="small" avatar={<Avatar style={{ backgroundColor: 'rgba(82, 169, 130, 0.2)', minHeight: '1rem', minWidth: '1rem' }}>2</Avatar>} color={!hasPermissionToEditEvent ? "default" : "success"} label="Vehicle" disabled={!hasPermissionToEditEvent} />
      </Box>
      <Box justifyContent="flex-start" width="100%" display="grid" gridTemplateColumns="repeat(auto-fill, minmax(6rem, min-content))">
        <Chip data-cy="event-animal-tag" style={{ opacity: getOpacity("false_positive_animal"), minHeight: '1.5rem' }} onClick={() => onTagSelected("false_positive_animal")} variant="outlined" size="small" avatar={<Avatar style={{ backgroundColor: 'rgba(217, 112, 96, 0.2)', minHeight: '1rem', minWidth: '1rem' }}>3</Avatar>} color={!hasPermissionToEditEvent ? "default" : "error"} label="Animal" disabled={!hasPermissionToEditEvent} />
        <Chip data-cy="event-light-tag" style={{ opacity: getOpacity("false_positive_light"), minHeight: '1.5rem' }} onClick={() => onTagSelected("false_positive_light")} variant="outlined" size="small" avatar={<Avatar style={{ backgroundColor: 'rgba(217, 112, 96, 0.2)', minHeight: '1rem', minWidth: '1rem' }}>4</Avatar>} color={!hasPermissionToEditEvent ? "default" : "error"} label="Light" disabled={!hasPermissionToEditEvent} />
        <Chip data-cy="event-weather-tag" style={{ opacity: getOpacity("false_positive_weather"), minHeight: '1.5rem' }} onClick={() => onTagSelected("false_positive_weather")} variant="outlined" size="small" avatar={<Avatar style={{ backgroundColor: 'rgba(217, 112, 96, 0.2)', minHeight: '1rem', minWidth: '1rem' }}>5</Avatar>} color={!hasPermissionToEditEvent ? "default" : "error"} label="Weather" disabled={!hasPermissionToEditEvent} />
        <Chip data-cy="event-unknown-tag" style={{ opacity: getOpacity("false_positive_unknown"), minHeight: '1.5rem' }} onClick={() => onTagSelected("false_positive_unknown")} variant="outlined" size="small" avatar={<Avatar style={{ backgroundColor: 'rgba(217, 112, 96, 0.2)', minHeight: '1rem', minWidth: '1rem' }}>6</Avatar>} color={!hasPermissionToEditEvent ? "default" : "error"} label="Unknown" disabled={!hasPermissionToEditEvent} />
        <Chip data-cy="event-other-tag" style={{ opacity: getOpacity("false_positive_other"), minHeight: '1.5rem' }} onClick={() => onTagSelected("false_positive_other")} variant="outlined" size="small" avatar={<Avatar style={{ backgroundColor: 'rgba(217, 112, 96, 0.2)', minHeight: '1rem', minWidth: '1rem' }}>7</Avatar>} color={!hasPermissionToEditEvent ? "default" : "error"} label="Other" disabled={!hasPermissionToEditEvent} />
      </Box>
    </Box>
  )
}

export default TagSelectionChips;
