import { IconButton, Tooltip } from '@mui/material';
import { Pause, PlayArrow } from '@mui/icons-material';
import React from 'react';

type PlayPauseButtonProps = {
  isPaused: boolean;
  onPause: () => void;
  onPlay: () => void;
  id?: string;
}

export const PlayPauseButton: React.FC<PlayPauseButtonProps> = (props) => {
  const { isPaused, onPause, onPlay, id } = props;

  if (isPaused === false) {
    return (
      <Tooltip title="Pause" arrow placement="right">
        <IconButton id={id} onClick={onPause} size="small">
          <Pause color="action" />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Tooltip title="Play" arrow placement="right">
      <IconButton id={id} onClick={onPlay} size="small">
        <PlayArrow color="action" />
      </IconButton>
    </Tooltip>
  )
}
