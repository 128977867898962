import { API } from "aws-amplify";

async function updateStrobe(streamId: string, device: Strobe): Promise<void> {
  const path: string = `/devices/attached/${streamId}`;
  const options = {
    body: device
  };

  try {
    await API.put('api-service', path, options);
  } catch (error: any) {
    throw error;
  }
}

export default updateStrobe;