import { PubSub } from "aws-amplify";

function publishOnTopic(topic: string, message: object | string): void {
  PubSub.publish(topic, message as any)
    .catch(e => {
      console.log('Error: ', e);
    });
}

export default publishOnTopic;
