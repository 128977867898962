function getCanvasSize(videoWidth: number, videoHeight: number, offsetWidth: number, offsetHeight: number): Vector2 {
  const ratioVideo = videoWidth / videoHeight;
  const ratioCanvas = offsetWidth / offsetHeight;
  const ratioVideoHeight = videoHeight / videoWidth;

  if (ratioVideo > ratioCanvas) {
    return ({
      x: offsetWidth,
      y: offsetWidth / ratioVideo
    });
  } else {
    return ({
      x: offsetHeight / ratioVideoHeight,
      y: offsetHeight
    });
  }
}

export default getCanvasSize;
