import Tutorial from "./Tutorial";

class ActionsTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#action-list-container',
        popover: {
          title: 'List of actions',
          description: "Actions are highly customizable notification rules – Design alerts for users when certain events occur on certain streams.",
          position: 'mid-center'
        }
      },
      {
        element: '#action-item-icon-container-0',
        popover: {
          title: 'Edit',
          description: 'Edit current action.',
        }
      },
      {
        element: '#action-item-sentence-container-0',
        popover: {
          title: 'Action description',
          description: 'Description of an action set by the user.',
        }
      },
      {
        element: '#action-item-switch-0',
        popover: {
          title: 'Enable',
          description: 'Enable / disable the action.',
          position: 'left'
        }
      },
      {
        element: '#action-item-more-options-0',
        popover: {
          title: 'Delete',
          description: 'Delete the action.',
          position: 'left'
        }
      }
    ])
  }

}

export default ActionsTutorial;
