import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

type LoadingContentProps = {
  message: string;
  theme?: "light" | "dark";
  value?: number;
}

const LoadingContent: React.FC<LoadingContentProps> = (props) => {
  const { message, theme, value } = props;

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%" justifyContent="center" alignItems="center">
      <Typography style={{ color: theme === 'dark' ? 'white' : undefined }} variant="subtitle2" >{message}</Typography>
      <LinearProgress value={value} style={{ width: 200 }} />
    </Box>
  );
}

export default LoadingContent;
