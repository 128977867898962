import { API } from "aws-amplify";

async function updateCompany(company: Company): Promise<Company> {
  try {
    const path: string = `/companies/${company.companyId}`;
    const tempCompany: any = {
      ...company
    }
    delete tempCompany.companyId
    const options = {
      body: tempCompany
    };

    const data = await API.put(
      'api-service',
      path,
      options
    );

    return data.data as Company;
  } catch (error: any) {
    throw error;
  }
}

export default updateCompany;
