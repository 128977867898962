import { PrometheusQuery } from "./PrometheusQuery";

export class NodeNetworkTransmitBytesTotalQuery extends PrometheusQuery {
  protected _id: string = "node_network_transmit_bytes_total"
  public override getPossibleValues() {
    return [
      { id: "Network - Transit", color: "#FF0000" }
    ]
  };

  public override getRefinedData() {
    const refinedData = [];

    if (this.isEmpty()) return [];

    for (let i = 0; i < this._queryData.data.result[0].values.length; i += 1) {
      refinedData.push({
        date: this.formatDate(this._queryData.data.result[0].values[i][0]),
        "Network - Transit": this.bytesToKb(this._queryData.data.result[0].values[i][1])
      })
    }

    return refinedData;
  }

  public override getValueLabel() {
    return "KB/s";
  }

  public override getDomain() {
    if (this.isEmpty()) return [0, 6]
    return undefined;
  }

  public override getMiddleValue() {
    if (this.isEmpty()) return 3;
    return undefined;
  }
}
