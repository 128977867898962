import { API } from "aws-amplify";

async function deleteScheduleTemplate(siteId: string, scheduleTemplateId: string): Promise<void> {
  try {
    const path: string = `/scheduleTemplate/${siteId}/${scheduleTemplateId}`;
    
    await API.del(
      'api-service',
      path,
      {}
    );
    
  } catch (error: any) {
    throw error;
  }
}

export default deleteScheduleTemplate;
