import Utils from '@utils/index';

async function getUserPic(userId: string, type?: string): Promise<{ url: string }> {
  try {
    if (process.env.REACT_APP_USER_PIC_S3_BUCKET)
      return Utils.S3.getImageUrl(userId + (type ? `.${type}`: ""), {},
        process.env.REACT_APP_USER_PIC_S3_BUCKET) as Promise<{ url: string }>
    else {
      throw Object.assign(
        new Error("User pic source not set"),
        { code: 404 }
      );
    }
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export default getUserPic;
