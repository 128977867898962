import useDebouncedState from '@hooks/Debounce/useDebounceState';
import {
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuProps,
  TextField,
  Typography
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { toNumber } from 'lodash';
import React from 'react';
import { CustomLi, CustomList, CustomUl } from './useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import API from '@API/index';
import { setCompanies } from '@store/companies/actions';
import Utils from '@utils/index';
import { PageView } from '@store/session/types';
import { HoverDetector } from './SiteList';

interface ICompanyListProps extends MenuProps {
  onCompanySelected: (company: Company) => void;
  selectedCompany?: Company;
  onManageCompanies: () => void;
  onCompanyEdit?: (company: Company) => void;
}

const CompanyList: React.FC<ICompanyListProps> = (props) => {
  const { onCompanySelected, selectedCompany, onCompanyEdit, onManageCompanies, ...menuProps } = props;

  const dispatch = useDispatch();
  const activePage = useSelector((store: IStoreState) => store.session.activePage);

  const companies = useSelector((store: IStoreState) => store.companies.companies);
  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);
  const isSuperAdministrator = useSelector((store: IStoreState) => store.session.isSuperAdministrator);

  const [filteredCompanies, setFilteredCompanies] = React.useState<Company[]>([]);
  const [filterText, setFilterText] = useDebouncedState("");
  const [loading, setLoading] = React.useState<boolean>(true);

  const setFilterTextRef = React.useRef(setFilterText);
  const loadingRef = React.useRef(loading);
  const companiesRef = React.useRef(companies);
  const dispatchRef = React.useRef(dispatch)

  React.useEffect(() => { dispatchRef.current = dispatch }, [dispatch])
  React.useEffect(() => { companiesRef.current = companies }, [companies])
  React.useEffect(() => { loadingRef.current = loading }, [loading])
  React.useEffect(() => { setFilterTextRef.current = setFilterText }, [setFilterText])
  React.useEffect(filter, [companies, filterText]);
  React.useEffect(reset, [menuProps.open]);
  React.useEffect(fetchCompanies, [menuProps.open]);

  function reset(): void {
    setFilterTextRef.current("");
  }

  function filter(): void {
    setFilteredCompanies(companies.filter(element => element.name.toLowerCase().includes(filterText.toLowerCase())));
  }

  function fetchCompanies(): void {
    if (menuProps.open === true) {
      if (companiesRef.current.length === 0) setLoading(true);

      API.Company.listCompanies()
        .then((companies) => {
          dispatchRef.current(setCompanies(companies));
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          if (loadingRef.current === true) setLoading(false);
        })
    }
  }

  return (
    <Menu
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      elevation={3}
      {...menuProps}
      style={{ marginLeft: 3 }}
    >
      <CustomList>
        <CustomLi>
          <CustomUl>
            <ListSubheader
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 8,
                paddingBottom: 8
              }}
            >
              <TextField
                style={{ marginTop: 0 }}
                autoFocus={true}
                defaultValue={filterText}
                placeholder="Search"
                variant="standard"
                onChange={(event) => { setFilterText(event.target.value); }}
                size="small"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                data-cy="company-list-search-input"
              />
            </ListSubheader>
          </CustomUl>
        </CustomLi>
      </CustomList>
      <CustomList data-cy="companies-list">
        <CustomLi>
          <CustomUl>
            <Divider />
            {filteredCompanies.length ?
              Utils.Array.orderAlphabetically(filteredCompanies, 'name').map(company => (
                <HoverDetector data-cy={`button-${company.companyId}`} key={filteredCompanies.indexOf(company)}>
                  <ListItem data-cy={`company-list-${company.companyId}-list-item`} selected={Boolean(selectedCompany === company)} button onClick={() => { onCompanySelected(company) }} key={company.companyId} >
                    <ListItemText primary={company.name} />
                    {onCompanyEdit &&
                      <ListItemSecondaryAction>
                        <Button onClick={() => { onCompanyEdit(company) }} variant="text" data-cy={`site-list-${company.name}-edit-button`}>
                          <Typography color={selectedCompany?.companyId === company.companyId ? 'primary' : undefined} variant={selectedCompany === company ? 'subtitle2' : 'body2'} >Edit</Typography>
                        </Button>
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                </HoverDetector>
              )) :
              loading === false ? <Typography style={{ margin: "0.5rem 1rem" }} variant="body2">No results found.</Typography> :
                <CircularProgress style={{ margin: "0.5rem 1rem" }} size={toNumber(rootFontSize)} />
            }
          </CustomUl>
        </CustomLi>
      </CustomList>

      {isSuperAdministrator && <CustomList>
        <CustomLi>
          <CustomUl>
            <Divider />
            <ListItem selected={activePage === PageView.Companies} onClick={onManageCompanies} button data-cy="company-list-create-list-item">
              <ListItemText primary="Manage companies" />
            </ListItem>
          </CustomUl>
        </CustomLi>
      </CustomList>}
    </Menu>
  )
}

export default CompanyList;
