function isInsideTheCanvas(
  position: Vector2,
  parentVector: Vector2
): boolean {
  const insideYBoundaries = position.y > 0 && position.y < parentVector.y;
  const insideXBoundaries = position.x > 0 && position.x < parentVector.x;
  return insideYBoundaries && insideXBoundaries;
}

export default isInsideTheCanvas;
