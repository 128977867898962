import { ISessionUser } from '@store/users/types';
import { ActionTypes, ICredentials, PageView, Components, AssetsPermissions } from './types';

export function authenticateUser(user: ISessionUser, credentials: ICredentials) {
  return {
    type: ActionTypes.AUTHENTICATE_USER,
    user,
    credentials
  };
}

export function setSessionUser(user: ISessionUser) {
  return {
    type: ActionTypes.SET_SESSION_USER,
    user
  }
}

export function clearUser() {
  return {
    type: ActionTypes.CLEAR_USER
  };
}

export function setUserData(userData: User) {
  return {
    type: ActionTypes.SET_USER_DATA,
    userData
  }
}

export function setActivePage(pageView: PageView) {
  return {
    type: ActionTypes.SET_ACTIVE_PAGE,
    pageView
  }
}

export function setActiveComponent(component: Components) {
  return {
    type: ActionTypes.SET_ACTIVE_COMPONENT,
    component
  }
}

export function setSuperAdministrator(value: boolean) {
  return {
    type: ActionTypes.SET_SUPER_ADMINISTRATOR,
    value
  }
}

export function setPermissions(value: AssetsPermissions) {
  return {
    type: ActionTypes.SET_PERMISSIONS,
    value
  }
}
