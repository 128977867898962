import React from 'react';
import { Box, Typography } from '@mui/material';
import { DayLabel } from '@declarations/enums';
import Utils from '@utils/index';

interface IScheduleHoverInfoProps {
  value: Record<DayLabel, Array<ActiveDayTime>>;
}

const ScheduleHoverInfo: React.FC<IScheduleHoverInfoProps> = (props) => {
  const { value } = props;

  if (Object.keys(value).length === 0)
    return null;
  return (
    <Box display="grid" columnGap="0.5rem" gridTemplateColumns={"min-content max-content min-content max-content"} >
      {Object.keys(DayLabel).map(dl =>
        Object.keys(value).includes(dl) ?
          <React.Fragment key={dl}>
            <Typography key={`${dl}1`}>{dl.slice(0, 3)}</Typography>
            <Typography key={`${dl}2`}>{`${Utils.Date.getAmPmFrom24Hour(value[dl as DayLabel][0]?.from)} - ${Utils.Date.getAmPmFrom24Hour(value[dl as DayLabel][0]?.to)}`}</Typography>
            {value[dl as DayLabel].length > 1 ?
              <React.Fragment>
                <Typography key={`${dl}3`}>|</Typography>
                <Typography key={`${dl}4`}>{`${Utils.Date.getAmPmFrom24Hour(value[dl as DayLabel][1]?.from)} - ${Utils.Date.getAmPmFrom24Hour(value[dl as DayLabel][1]?.to)}`}</Typography>
              </React.Fragment> :
              <React.Fragment>
                <div key={`${dl}5`}/>
                <div key={`${dl}6`}/>
              </React.Fragment>}
          </React.Fragment> :
          <React.Fragment></React.Fragment>
      )}
    </Box>
  );
}

export default ScheduleHoverInfo;