import React from 'react';
import { useState, useEffect } from "react";
import {
  isPushNotificationSupported,
  registerServiceWorker,
  getUserSubscription,
  askUserPermission,
  createNotificationSubscription,
} from '@service_workers/pushNotifications';
import API from '@API/index';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import ConfirmationDialog from '../ConfirmationDialog';
import { useUserAgent } from '@oieduardorabelo/use-user-agent';
import { selectIsSuperAdminUser } from '@store/selectors';

const PushNotification: React.FC = () => {
  const pushNotificationSupported = isPushNotificationSupported();
  const details = useUserAgent();

  const [userConsent, setUserConsent] = useState(Notification.permission);
  const [browserWebPushSub, setBrowserWebPushSub] = useState<PushSubscription | null>(null);
  const [isAskingPermission, setIsAskingPermission] = useState(false);

  const serverWebPushSubs = useSelector((state: IStoreState) => state.session.userData?.communication?.webPushSubscriptions)
  const companyId = useSelector((state: IStoreState) => state.session.userData?.companyId)
  const userId = useSelector((state: IStoreState) => state.session.userData?.userId)
  const user = useSelector((state: IStoreState) => state.session.userData)
  const isSuperAdministrator = useSelector(selectIsSuperAdminUser)

  const handleWebPushSubscriptionsUpdateRequest = React.useCallback((webPushSubscriptions: PushSubscription[], onSuccess?: () => void, onError?: () => void): void => {
    if (userId)
      API.Users.updateWebPushSubscriptions(webPushSubscriptions, userId)
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch(() => {
          if (onError) onError();
        })
  }, [userId])

  const updateServerPushSubs = React.useCallback((existingSubscription: PushSubscription | null) => {
    const webPushSubs = serverWebPushSubs ? [...serverWebPushSubs] : [];
    if (existingSubscription) {
      handleWebPushSubscriptionsUpdateRequest(addOrUpdatePushSub(existingSubscription, webPushSubs))
    } else {
      createNotificationSubscription()
        .then(function (subscription) {
          handleWebPushSubscriptionsUpdateRequest(addOrUpdatePushSub(subscription, webPushSubs))
        })
        .catch(err => {
          console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
        });
    }
  }, [handleWebPushSubscriptionsUpdateRequest, serverWebPushSubs])

  const checkNotificationSubscription = React.useCallback(() => {
    if (browserWebPushSub) {
      updateServerPushSubs(browserWebPushSub);
    }
    else
      getUserSubscription()
        .then((existingSubscription) => {
          setBrowserWebPushSub(existingSubscription);
          updateServerPushSubs(existingSubscription);
        })
  }, [browserWebPushSub, updateServerPushSubs])

  const checkNotificationPermission = React.useCallback(() => {
    if (userConsent !== "granted") {
      if (userConsent === "denied")
        return;
      setIsAskingPermission(true);
      askUserPermission().then(consent => {
        if (consent === "granted") {
          setUserConsent(consent);
          checkNotificationSubscription();
        }
        else {
          if (consent === "denied")
            return;
          setIsAskingPermission(true);
        }
      });
    } else {
      checkNotificationSubscription();
    }
  }, [checkNotificationSubscription, userConsent])

  useEffect(setupPushNotifications, [companyId, userId, pushNotificationSupported, isSuperAdministrator, userConsent, serverWebPushSubs, checkNotificationPermission, user?.communication?.webPushInUse])

  function setupPushNotifications() {
    if (companyId && userId && pushNotificationSupported) {
      registerServiceWorker();
      if (isSuperAdministrator)
        checkNotificationPermission();
      else {
        if (user?.communication?.webPushInUse)
          checkNotificationPermission();
      }
    }
  }

  const addOrUpdatePushSub = (pushSub: PushSubscription, pushSubs: PushSubscription[]): PushSubscription[] => {
    const subExists = pushSubs.some((ps: PushSubscription) => pushSub.endpoint === ps.endpoint);
    if (!subExists) {
      return [...pushSubs, pushSub];
    }
    return pushSubs.map((ps: PushSubscription) => pushSub.endpoint === ps.endpoint ? pushSub : ps);
  };

  return (
    <ConfirmationDialog
      open={isAskingPermission}
      title="Enable notifications"
      description={'In your browser, enable notifications to receive real-time alerts for site activity.'}
      loading={false}
      onCancel={() => {
        switch (details?.browser.name) {
          case "Safari":
            window.open("https://support.apple.com/guide/safari/customize-website-notifications-sfri40734/mac", '_blank');
            break;
          case "Firefox":
            window.open("https://support.mozilla.org/en-US/kb/push-notifications-firefox", '_blank');
            break;
          case "Chrome":
          default:
            window.open("https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop", '_blank');
            break;
        }
      }}
      onConfirm={() => setIsAskingPermission(false)}
      confirmLabel='OK'
      cancelLabel={'Learn more'}
    />
  );
}

export default PushNotification
