import { API } from 'aws-amplify';

async function deleteAttendance(id: string, asCompany?: string): Promise<void> {
  try {
    const path = `/workers/attendance/${id}` + (asCompany ? `?asCompany=${asCompany}` : "");

    const { data } = await API.del('api-service', path, {});
    

    return data;
  } catch (error: any) {
    throw error;
  }
}

export default deleteAttendance;
