import { API } from "aws-amplify";

async function partialUpdateSite(companyId: string, siteId: string, name: string, tags?: string[]): Promise<Site> {
  try {
    const path: string = `/sites/${companyId}/${siteId}`;

    const options = {
      body: {
        name,
        tags
      }
    };

    const data = await API.put(
      'api-service',
      path,
      options
    );

    
    return data.data as Site;
  } catch (error: any) {
    throw error;
  }
}

export default partialUpdateSite;
