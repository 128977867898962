import { API } from "aws-amplify";
import { first, isArray } from "lodash";

export default async function getBlacklistDetails(workerId: number, asCompany?: string): Promise<BlacklistDetails | undefined> {
  try {
    const path: string = `/workers/${workerId}/blacklist` + (asCompany ? `?asCompany=${asCompany}` : "");
    const result = await API.get('api-service', path, {});

    return isArray(result.data) ? first(result.data) : result.data;
  } catch (error) {
    throw error;
  }
}
