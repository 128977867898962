import { ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Logos } from '@utils/Styles';
import React from 'react';

type AppLogoProps = {
  open: boolean;
  onClick?: () => void;
  logoOnly?: boolean;
}

const AppLogo: React.FC<AppLogoProps> = (props) => {
  const { logoOnly } = props;

  if (logoOnly) return (
    <ListItem style={{ pointerEvents: 'none' }} button={false}>
      <ListItemIcon>
        <img style={{ maxHeight: "1.5rem" }} src={Logos.FORSIGHT_LOGO} alt="error" />
      </ListItemIcon>
    </ListItem>
  )

  return (
    <ListItem style={{ pointerEvents: 'none' }} button={false}>
      <ListItemIcon>
        <img style={{ maxHeight: "1.5rem" }} src={Logos.FORSIGHT_LOGO} alt="error" />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ style: { display: 'flex' } }}>
        <img style={{ maxHeight: "1.5rem", alignSelf: 'center', justifySelf: 'center' }} src={Logos.FORSIGHT_LOGO_TEXT} alt="error" />
      </ListItemText>
    </ListItem>
  )
}

export default AppLogo;
