import useUpdateStreams from '@hooks/useUpdateStreams';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import SearchHeader from '@components/common/SearchHeader';
import Utils from '@utils/index';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';
import CustomDataGrid from '@components/common/DataGrid/CustomDataGrid';
import { useNavigate } from 'react-router-dom';
import { last } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import NonIdealState from '@components/common/NonIdealState/NonIdealState';

const DEFAULT_VISIBILITY = {
  kvsName: true,
  companyId: false,
  siteId: true,
  iotThingName: true,
  rtspUrl: false,
  rtspUrlProc: false,
  defaultName: true,
  state: true,
  tags: false,
  type: false,
  manufacturer: true,
  position: false,
  deviceShadowName: true,
  retentionPeriodInHours: false,
  latitude: false,
  longitude: false,
  fovWidth: false,
  range: false,
  angleEastOfNorth: false,
  statusOnDevice: true,
  rportCameraId: false,
  createdAt: false,
  updateAt: false
};

const MOBILE_VISIBILITY = {
  kvsName: true,
  companyId: false,
  siteId: true,
  iotThingName: false,
  rtspUrl: false,
  rtspUrlProc: false,
  defaultName: false,
  state: false,
  tags: false,
  type: false,
  manufacturer: false,
  position: false,
  deviceShadowName: false,
  retentionPeriodInHours: false,
  latitude: false,
  longitude: false,
  fovWidth: false,
  range: false,
  angleEastOfNorth: false,
  statusOnDevice: false,
  rportCameraId: false,
  createdAt: false,
  updateAt: false
}

const HealthCameras: React.FC<{}> = () => {
  const navigate = useNavigate();
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const userData = useSelector((store: IStoreState) => store.session.userData)
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const isSuperAdministrator = useSelector((store: IStoreState) => store.session.isSuperAdministrator);
  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);

  const isLoadingStreams = useUpdateStreams(selectedSite?.siteId, selectedCompany?.companyId, userData?.roles, userData?.userId);
  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();

  const [filteredStreams, setFilteredStreams] = React.useState<VideoStream[]>([]);
  const [finalStreams, setFinalStreams] = React.useState<VideoStream[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>(DEFAULT_VISIBILITY)

  React.useEffect(() => {
    if (selectedSite) {
      setFilteredStreams(streams.filter(el => el.siteId === selectedSite.siteId));
    } else {
      setFilteredStreams(streams.filter(el => el.companyId === selectedCompany?.companyId));
    }
  }, [streams, selectedCompany, selectedSite])

  React.useEffect(() => {
    if (isMobile || isSmallScreen) {
      setColumnVisibilityModel(MOBILE_VISIBILITY)
    } else {
      setColumnVisibilityModel(DEFAULT_VISIBILITY)
    }
  }, [isMobile, isSmallScreen])

  const generateColumn = React.useCallback((property: string, label: string, flex: number = 0.25) => {
    return {
      field: property,
      headerName: label,
      flex: flex,
      sortable: true,
      renderCell: (params: any) => (
        <Typography noWrap variant="body2">{params.value}</Typography>
      )
    }
  }, []);

  const textFilterProps = React.useMemo(() => ["siteId", "defaultName", "kvsName", "iotThingName", "state", "statusOnDevice", "manufacturer", "deviceShadowName"], []);

  const streamsToDisplay = React.useMemo(() => {
    if (isLoadingStreams) return [];

    return Utils.Array.orderAlphabetically(finalStreams, 'companyId').map((stream: VideoStream) => ({
      id: stream.kvsName,
      kvsName: stream.kvsName,
      companyId: stream.companyId,
      siteId: stream.siteId,
      iotThingName: stream.iotThingName,
      rtspUrl: stream.rtspUrl,
      rtspUrlProc: stream.rtspUrlProc,
      defaultName: stream.defaultName,
      state: stream.state,
      tags: stream.tags,
      type: stream.type,
      manufacturer: stream.manufacturer,
      position: stream.position,
      deviceShadowName: stream.deviceShadowName,
      retentionPeriodInHours: stream.retentionPeriodInHours,
      latitude: stream.latitude,
      longitude: stream.longitude,
      fovWidth: stream.fovWidth,
      range: stream.range,
      angleEastOfNorth: stream.angleEastOfNorth,
      statusOnDevice: stream.statusOnDevice,
      rportCameraId: stream.rportCameraId,
      createdAt: stream.createdAt,
      updateAt: stream.updatedAt

    }))
  }, [finalStreams, isLoadingStreams])

  if (isSuperAdministrator === false) {
    return (
      <NonIdealState
        title="Not found"
        description="Page was not found, please check your information."
        errorCode={404}
      />
    )
  }

  return (
    <Box display="grid" gridTemplateRows="min-content auto" width="100%" height="100%" padding="1.5rem" gap="2rem">
      <SearchHeader
        title="Cameras"
        textFiltering={true}
        textFilterProps={textFilterProps}
        noPadding
        searchHeaderFilterProps={{
          onFilteredDataChange: setFinalStreams,
          data: filteredStreams,
          properties: Utils.Filters.createVideoStreamFilters(true)
        }}
      />
      <CustomDataGrid
        loading={isLoadingStreams}
        hideHeaders={isLoadingStreams || filteredStreams.length === 0}
        defaultHeight='82vh'
        disableColumnFilter
        disableRowSelectionOnClick
        rows={streamsToDisplay}
        columns={[
          generateColumn('siteId', 'Site ID', 1),
          generateColumn('defaultName', 'Name', 1),
          generateColumn('kvsName', 'KVS name', 1.5),
          generateColumn('iotThingName', 'IOT thing name', 1),
          generateColumn('rtspUrl', 'RTSP url', 1),
          generateColumn('rtspUrlProc', 'RTSP url proc', 1),
          generateColumn('rportCameraId', 'RPORT ID', 1),
          generateColumn('state', 'State', 0.5),
          generateColumn('statusOnDevice', 'Status on device', 0.5),
          generateColumn('tags', 'Tags', 0.5),
          generateColumn('type', 'Type', 0.5),
          generateColumn('manufacturer', 'Manufacturer', 0.5),
          generateColumn('position', 'Position', 0.5),
          generateColumn('deviceShadowName', 'Device shadow name', 0.8),
          generateColumn('latitude', 'Latitude', 0.5),
          generateColumn('longitude', 'Longitude', 0.5),
          generateColumn('fovWidth', 'FOV width', 0.5),
          generateColumn('range', 'Range', 0.5),
          generateColumn('angleEastOfNorth', 'Angle east of north', 0.5),
          generateColumn('createdAt', 'Created', 0.5),
          generateColumn('updateAt', 'Updated', 0.5),
        ]}
        autoPageSize
        onColumnVisibilityModelChange={(model) => setColumnVisibilityModel(model)}
        columnVisibilityModel={columnVisibilityModel}
        dataCy="overview-cameras-table"
        onRowClick={(params) => {
          try {
            const url = Utils.Stream.getStreamShareUrl({ kvsName: params.id } as VideoStream);
            console.log(`/${last(url.split('/'))}`)
            navigate(`/${last(url.split('/'))}`);
          } catch (error) {
            enqueueSnackbar('There was a problem navigating to the stream', { variant: 'error' })
          }
        }}
      />
    </Box>
  )
}

export default HealthCameras;
