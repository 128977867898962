import React from 'react';
import { Button, SnackbarContent, Typography } from "@mui/material";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export function showCustomSnackbar(
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  startText: string,
  snackbarRef: React.MutableRefObject<SnackbarKey | undefined>,
  typographyRef: React.MutableRefObject<string | undefined>,
  onCancel?: () => void
) {
  snackbarRef.current = enqueueSnackbar("", {
    variant: 'default',
    persist: true,
    content: (
      <SnackbarContent
        message={<Typography variant="body2" style={{ color: '#FFFFFF' }} id={typographyRef.current}>{startText}</Typography>}
        style={{ backgroundColor: '#323232' }}
        action={onCancel ? <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={onCancel}
          data-cy="cancel-snackbar-button"
        >Cancel</Button> : undefined}
      />
    )
  });
}
