import React from 'react';
import TimeRange from '../../timeline/CommandPanel/common/TimeRange';
import { FormControl, Popover, TextField } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import DatePickerPopover from '../../timeline/CommandPanel/common/DatePickerPopover';
import Utils from '@utils/index';
import CustomInputLabel from './CustomInputLabel';

export type TimeRangeSelectorProps = {
  timeRange?: { start: Date, end: Date };
  selectedSite?: Site;
  timezone?: string;
  onTimeRangeChange: (start?: Date, end?: Date, timezone?: 'local' | 'utc') => void;
  variant?: string;
  dayRangesOnly?: boolean;
  disableFuture?: boolean;
  enableLiveOption?: boolean;
  disableLongTermOptions?: boolean;
  multiDay?: boolean;
  maxTimeRangeInHours?: number;
  calendarUtcMessage?: string;
  onTimeInputOpen?: () => void;
  onTimeInputClose?: () => void;
}

const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = (props) => {
  const {
    timeRange,
    selectedSite,
    onTimeRangeChange,
    variant,
    dayRangesOnly,
    disableFuture,
    enableLiveOption,
    disableLongTermOptions,
    multiDay,
    maxTimeRangeInHours,
    calendarUtcMessage,
    onTimeInputClose,
    onTimeInputOpen,
    timezone: tz
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [calendarAnchorEl, setCalendarAnchorEl] = React.useState<HTMLElement | null>(null);
  const [timezone, setTimezone] = React.useState<string>(tz || "");
  const [focused, setFocused] = React.useState(false);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const itemsWidth = containerRef.current ? containerRef.current.offsetWidth - 16 : undefined;

  React.useEffect(() => {
    if (selectedSite && selectedSite.timezone) {
      setTimezone(selectedSite.timezone);
    }
  }, [selectedSite])

  function getFromToTimeText(): string {
    if (timeRange) {
      const timezone = Utils.Site.getTimezone(selectedSite);
      return `${Utils.Date.format(timeRange.start, 'MMM Do, YYYY h:mm A', timezone)} - ${Utils.Date.format(timeRange?.end, 'MMM Do, YYYY h:mm A', timezone)}`
    }

    return "As events happen";
  }

  function handleTimeRangeClick(offset: number, chipText: string): void {
    setAnchorEl(null);
    if (onTimeInputClose) onTimeInputClose();

    const now = Date.now();
    const end = new Date(now);
    const start = new Date(now - offset);
    onTimeRangeChange(start, end, 'utc')
  }

  function handleDatePopoverConfirmation(start: Date, end: Date) {
    setCalendarAnchorEl(null);
    if (onTimeInputClose) onTimeInputClose();
    onTimeRangeChange(start, end, 'local');
  }

  function getChipText(): string {
    if (timeRange) {
      const length = timeRange.end.getTime() - timeRange.start.getTime()

      const seconds = Number((length / 1000).toFixed(0));

      if (seconds < 0) return "err"
      if (seconds < 60) return `${seconds}s`;

      const minutes = Number((length / 60000).toFixed(0));
      if (minutes < 60) return `${minutes}m`;

      const hours = Number((length / 3600000).toFixed(0));
      if (hours < 24) return `${hours}h`

      const days = Number((hours / 24).toFixed(0));
      if (days < 7) return `${days}d`;

      const weeks = Number((days / 7).toFixed(0));
      if (weeks < 4) return `${weeks}w`;

      const months = Number((weeks / 4).toFixed(0));
      return `${months}mo`;
    }

    return "Live";
  }

  function renderOptions() {
    return <>
      {!(dayRangesOnly || false) &&
        <>
          {enableLiveOption &&
            <TimeRange
              expandable={false}
              text="As events happen"
              chipText="Live"
              onClick={() => {
                setAnchorEl(null);
                onTimeRangeChange();
                if (onTimeInputClose) onTimeInputClose();
              }}
              width={itemsWidth}
              data-cy={'as-events-happen-time-range'}
            />
          }
          <TimeRange
            expandable={false}
            text="Select from calendar"
            icon={<CalendarToday style={{ marginLeft: "0.675rem" }} fontSize="small" />}
            onClick={() => {
              setAnchorEl(null);
              setCalendarAnchorEl(containerRef.current);
              if (onTimeInputOpen) onTimeInputOpen();
            }}
            width={itemsWidth}
            data-cy={'select-from-calendar-time-range'}
          />
          <TimeRange
            expandable={false}
            text="Last 5 minutes"
            chipText="5m"
            onClick={() => { handleTimeRangeClick(60000 * 5, '5m') }}
            width={itemsWidth}
            data-cy={'last-5-minutes-time-range'}
          />
          <TimeRange
            expandable={false}
            text="Last 15 minutes"
            chipText="15m"
            onClick={() => { handleTimeRangeClick(60000 * 15, '15m') }}
            width={itemsWidth}
            data-cy={'last-15-minutes-time-range'}
          />
          <TimeRange
            expandable={false}
            text="Last 30 minutes"
            chipText="30m"
            onClick={() => { handleTimeRangeClick(60000 * 30, '30m') }}
            width={itemsWidth}
            data-cy={'last-30-minutes-time-range'}
          />
          <TimeRange
            expandable={false}
            text="Last 1 hour"
            chipText="1h"
            onClick={() => { handleTimeRangeClick(3600000 * 1, '1h') }}
            width={itemsWidth}
            data-cy={'last-1-hour-time-range'}
          />
          <TimeRange
            expandable={false}
            text="Last 4 hours"
            chipText="4h"
            onClick={() => { handleTimeRangeClick(3600000 * 4, '4h') }}
            width={itemsWidth}
            data-cy={'last-4-hour-time-range'}
          />
          <TimeRange
            expandable={false}
            text="Last 12 hours"
            chipText="12h"
            onClick={() => { handleTimeRangeClick(3600000 * 12, '12h') }}
            width={itemsWidth}
            data-cy={'last-12-hour-time-range'}
          />
        </>}
      <TimeRange
        expandable={false}
        text="Last 1 day"
        chipText="1d"
        onClick={() => { handleTimeRangeClick(86400000 * 1, '1d') }}
        width={itemsWidth}
        data-cy={'last-1-day-time-range'}
      />
      {(disableLongTermOptions === false || disableLongTermOptions === undefined) &&
        <TimeRange
          expandable={false}
          text="Last 2 days"
          chipText="2d"
          onClick={() => { handleTimeRangeClick(86400000 * 2, '2d') }}
          width={itemsWidth}
          data-cy={'last-2-days-time-range'}
        />
      }

      {(disableLongTermOptions === false || disableLongTermOptions === undefined) &&
        <TimeRange
          expandable={false}
          text="Last 1 week"
          chipText="1w"
          onClick={() => { handleTimeRangeClick(86400000 * 7, '1w') }}
          width={itemsWidth}
          data-cy={'last-1-week-time-range'}
        />
      }
      {(disableLongTermOptions === false || disableLongTermOptions === undefined) &&
        <TimeRange
          expandable={false}
          text="Last 1 month"
          chipText="1mo"
          onClick={() => { handleTimeRangeClick(86400000 * 30, '1mo') }}
          width={itemsWidth}
          data-cy={'last-1-month-time-range'}
        />
      }
    </>
  }

  return (
    <div ref={containerRef} data-cy="time-range-selector">
      {variant === "outlined" ?
        <FormControl id="time-range-description" fullWidth>
          <CustomInputLabel htmlFor="roles" focused={focused}>Time range</CustomInputLabel>
          <TextField
            id="roles"
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            variant={variant}
            inputProps={{
              readOnly: true,
              // disabled: true,
            }}
            value={getFromToTimeText()}
            onClick={(e) => {
              e.preventDefault();
              setAnchorEl(e.currentTarget);
            }
            }
            style={{ width: "20rem" }}
            data-cy="time-range-input"
          />
        </FormControl> :
        <TimeRange
          expandable
          text={getFromToTimeText()}
          chipText={getChipText()}
          onClick={(e) => { setAnchorEl(e.currentTarget); if (onTimeInputOpen) onTimeInputOpen() }}
          padding="1rem"
          variant="outlined"
          focused={Boolean(anchorEl)}
        />}
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          if (onTimeInputClose) onTimeInputClose();
        }}
        style={{ maxHeight: "17.25rem", width: containerRef.current?.offsetWidth }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {renderOptions()}
      </Popover>
      <DatePickerPopover
        anchorEl={calendarAnchorEl}
        open={Boolean(calendarAnchorEl)}
        onClose={() => {
          setCalendarAnchorEl(null);
          if (onTimeInputClose) onTimeInputClose()
        }}
        timezone={timezone}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onCancelClick={() => { setCalendarAnchorEl(null); }}
        onConfirmClick={handleDatePopoverConfirmation}
        disableFuture={disableFuture || false}
        multiDay={multiDay}
        maxTimeRangeInHours={maxTimeRangeInHours}
        calendarUtcMessage={calendarUtcMessage}
      />
    </div>
  )
}

export default TimeRangeSelector;
