import { API } from "aws-amplify";

async function deleteStream(siteId: string, kvsName: string): Promise<void> {
  const path: string = `/streams/${siteId}/${kvsName}`

  try {
    await API.del(
      'api-service',
      path,
      {}
    )
  } catch (error: any) {
    throw error;
  }
}

export default deleteStream;
