import { API } from 'aws-amplify';

async function getViewsFromCompany(companyId: string): Promise<UserView[]> {
  try {
    const path: string = `/grids/${companyId}`;
    const data = await API.get(
      'api-service',
      path,
      {}
    );

    return data.data as UserView[];
  } catch (error: any) {
    throw new Error(error.response?.data.error || error.message);
  }
}

export default getViewsFromCompany;
