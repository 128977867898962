import Tutorial from './Tutorial';

class AnalyticsTutorial extends Tutorial {
  protected setup(): void {
    const wrapper = document.getElementsByClassName("recharts-legend-wrapper");
    const cartesian = document.getElementsByName("recharts-cartesian-grid");

    const steps: Array<any> = [
      {
        element: '#report-event-types-description',
        popover: {
          title: 'Event types',
          description: 'Select event type(s) from your site to be reported on.',
          position: 'bottom'
        }
      },
      {
        element: '#date-range-input',
        popover: {
          title: 'Time range',
          description: 'Select a preferred time range for the events selected to be reported on.',
          position: 'bottom'
        }
      },
      {
        element: '#group-by-description',
        popover: {
          title: 'Group by',
          description: 'Group events in the report by day or hour.',
          position: 'bottom'
        }
      },
      {
        element: '#create-report-description',
        popover: {
          title: 'Create report',
          description: 'Create a bar chart of the selected event type and time range for all the streams present at the site.',
          position: 'left'
        }
      }
    ]

    if (wrapper.length > 0) steps.push({
      element: '.recharts-legend-wrapper',
      popover: {
        title: 'Stream names',
        description: 'List of stream names present on the site.'
      }
    })

    if (cartesian.length > 0) steps.push({
      element: '.recharts-cartesian-grid',
      popover: {
        title: 'Graph',
        description: 'Motion event count of distinct streams presented by a one dimensional bar diagram.',
      }
    })

    super.defineSteps(steps);
  }
}

export default AnalyticsTutorial;