import React from 'react';
import API from '@API/index';
import VideoPoseEstimations from '@classes/VideoPoseEstimations';

type PoseEstimationLoaderProps = {
  poseEstimationsVisible?: boolean;
  isLive: boolean;
  streamUrl?: string;
  videoTimestamp?: number;
  stream?: VideoStream;
  poseEstimations?: VideoPoseEstimations;
  onLoad?: (bbox?: VideoPoseEstimations) => void;
}

export interface IPoseEstimationLoaderHandles {
  setPoseEstimations: (boxes: PoseEstimations | undefined) => void;
}

const PoseEstimationLoader: React.ForwardRefRenderFunction<IPoseEstimationLoaderHandles, PoseEstimationLoaderProps> = (props, ref) => {
  const {
    poseEstimationsVisible,
    isLive,
    streamUrl,
    videoTimestamp,
    stream,
    poseEstimations,
    onLoad
  } = props;

  React.useImperativeHandle(ref, () => ({
    setPoseEstimations
  }))

  const onLoadRef = React.useRef(onLoad)
  const isLoadingDetectionBoxes = React.useRef<boolean>(false);
  const poseEstimationsToDraw = React.useRef<PoseEstimations | undefined>(undefined)
  const lastCallTimestampRef = React.useRef({ start: 0, end: 0 })

  function setPoseEstimations(poses: PoseEstimations | undefined): void {
    poseEstimationsToDraw.current = poses;
  }

  React.useEffect(() => { onLoadRef.current = onLoad }, [onLoad])

  React.useEffect(() => {
    if (poseEstimationsVisible !== true) return;

    if (isLive) return;

    if (isLoadingDetectionBoxes.current === true) return;
    if (poseEstimationsToDraw.current) {
      if (poseEstimations) {
        const lastElement = poseEstimations.getLast();
        if (lastElement) {
          if ((lastElement.timestamp - poseEstimationsToDraw.current.timestamp) > 15000) {
            return;
          }
        }
      }
    }

    if (streamUrl && videoTimestamp && stream) {

      if (lastCallTimestampRef.current.start !== 0 && lastCallTimestampRef.current.end !== 0) {
        if (videoTimestamp > lastCallTimestampRef.current.start) {
          if (videoTimestamp < lastCallTimestampRef.current.end - 15000) {
            return;
          }
        }
      }

      isLoadingDetectionBoxes.current = true;

      const startTs = videoTimestamp;
      const endTs = videoTimestamp + ((1000 * 60) * 1);
      lastCallTimestampRef.current  = { start: startTs, end: endTs };

      API.Events.getPoseEstimations(stream.kvsName, startTs, endTs)
        .then((result) => {
          if (onLoadRef.current) onLoadRef.current(result);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          isLoadingDetectionBoxes.current = false;
        })
    }
  }, [streamUrl, videoTimestamp, isLive, poseEstimationsVisible, stream, poseEstimations])

  return null;
}

export default React.forwardRef(PoseEstimationLoader);
