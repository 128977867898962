import React from 'react';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { Avatar } from '@components/common/Avatar';
import Utils from '@utils/index';
import StreamEvents from '@classes/StreamEvents/StreamEvents';
import styled from 'styled-components';
import EventTagLabel from './EventTagLabel';
import { Report } from '@mui/icons-material';
import { isArray, isEmpty } from 'lodash';

const Card = styled(Paper) <{ selected: boolean; }>`
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: ${props => props.selected ? 'rgba(0, 72, 227, 0.8)' : undefined};

  :hover {
    cursor: pointer;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  }

  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  @keyframes color {
    0% {
      background-color: #fff;
    }
    50% {
      background-color: rgba(0, 72, 227, 0.08);
    }
    100% {
      background-color: #fff;
    }
  }

  @keyframes color_selected {
    0% {
      background-color: rgba(0, 72, 227, 0.4);
    }
    50% {
      background-color: rgba(0, 72, 227, 0.8);
    }
    100% {
      background-color: rgba(0, 72, 227, 0.4);
    }
  }
`;

const PulsingCircle = styled(Box)`
  content: '';
  width: 20px;
  height: 20px;
  outline: 2px solid rgba(0, 72, 227, 0.8);
  border-radius: 50%;
  position: absolute;
  animation: pulsate infinite 1s;

  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(1, 1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(10, 10);
      opacity: 0;
    }
  }
`

const CustomIcon = styled(Report)`
  :hover {
    cursor: auto;
  }
`

const CardText = styled(Typography) <{ selected: boolean }>`
  line-height: 1.59 !important;
  color: ${props => props.selected ? 'white' : undefined};
`;

type EventCardProps = {
  event: VideoStreamEvent;
  selected: boolean;
  onEventClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, event: VideoStreamEvent) => void;
  zoneNames?: string[];
  usersSelectingEvent: Array<string>;
}

const EventCard: React.FC<EventCardProps> = (props) => {
  const { event, selected, onEventClick, zoneNames, usersSelectingEvent } = props;

  const sites = useSelector((store: IStoreState) => store.sites.sites);
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const users = useSelector((store: IStoreState) => store.users.users);

  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const [titleHovered, setTitleHovered] = React.useState<boolean>(false);
  const [descriptionHovered, setDescriptionHovered] = React.useState<boolean>(false);

  const eventRef = React.useRef(event);

  React.useEffect(() => { eventRef.current = event }, [event])
  React.useEffect(() => {
    setTooltipOpen(titleHovered || descriptionHovered);
  }, [titleHovered, descriptionHovered])

  const eventTitle = React.useMemo(() => {
    return StreamEvents.getTitle(event, zoneNames);
  }, [event, zoneNames])

  const description = React.useMemo(() => {
    const stream = streams.find(el => el.kvsName === event.sourceCamera);
    const eventContainsChildren = isArray(event.children) && !isEmpty(event.children) && stream;

    if (eventContainsChildren) {
      const kvsNames = getKvsNames()
      const site = sites.get(stream.companyId)?.find(el => el.siteId === stream.siteId);
      if (kvsNames.length > 1) return `${kvsNames.length} cameras at ${site?.name || 'unknown'}`;
    }

    if (stream) {
      const site = sites.get(stream.companyId)?.find(el => el.siteId === stream.siteId);
      if (site) {
        return `${stream.defaultName || stream.kvsName} at ${site.name}`;
      }

      return `${stream.defaultName || stream.kvsName}`
    }

    return `Error finding the stream and site`;
  }, [streams, event, sites])

  function renderSelectingUsersAvatars(): JSX.Element[] {
    return getUsersSelecting().map((profile) => (
      <Tooltip key={profile.userId} title={Utils.User.getUserName(profile || {})}>
        <Box display="flex">
          <Avatar
            fontSize={12}
            lineHeight={1}
            disableGap
            user={profile}
            height={20}
            width={20}
          />
          <PulsingCircle />
        </Box>
      </Tooltip>
    ))
  }

  function getUsersSelecting(): User[] {
    let usersProfiles: User[] = [];
    usersSelectingEvent.forEach(id => {
      const target = users.find(el => el.userId === id);
      if (target) usersProfiles.push(target);
    })
    return usersProfiles;
  }

  function getTooltipTitle(type: "description" | "title"): JSX.Element[] | string {
    if (zoneNames && zoneNames.length > 1) {
      if (type === "description") return "";
      return zoneNames.map(el => <Typography key={el} variant="body2" >{el}</Typography>);
    }

    if (isArray(event.children) && !isEmpty(event.children)) {
      const kvsNames = getKvsNames()
      return kvsNames.map(kvsName => {
        const stream = streams.find(el => el.kvsName === kvsName)
        return <Typography key={kvsName} variant="body2" >{stream?.defaultName || stream?.kvsName || "Unknown"}</Typography>;
      })
    }

    return ""
  }

  function getKvsNames(): string[] {
    const kvsNamesSet: Set<string> = new Set<string>();
    kvsNamesSet.add(eventRef.current.sourceCamera);
    eventRef.current.children?.forEach((element) => {
      kvsNamesSet.add(element.sourceCamera);
    })
    return Array.from(kvsNamesSet);
  }

  const userData = users.find(el => el.userId === event.owner) as User;

  return (
    <Box
      paddingTop="0.5rem"
      paddingBottom="0.5rem"
      paddingLeft="0.3rem"
      paddingRight="1rem"
    >
      <Card
        selected={selected}
        onClick={(e) => { onEventClick(e, event); }}
        style={{
          outline: getUsersSelecting().length > 0 ? '1px solid rgba(0, 72, 227, 0.8)' : undefined,
          animationName: event.ongoing ? selected ? "color_selected":  "color" : undefined,
          opacity: event.ongoing ? 0.5 : 1,
          animationIterationCount: event.ongoing ? "infinite" : undefined,
          animationDuration: '1s'
        }}
        data-cy={`event-card`}
      >
        <Box padding="0.5rem">
          <Box
            display="grid"
            gridAutoRows="1fr"
          >
            <Box
              width="100%"
              height="100%"
              display="grid"
              gridTemplateColumns="auto max-content"
              gap="0.5rem"
              alignItems="center"
            >
              <Tooltip
                open={tooltipOpen}
                disableInteractive
                title={getTooltipTitle("title")}
              >
                <CardText
                  onMouseEnter={() => setTitleHovered(true)}
                  onMouseLeave={() => setTitleHovered(false)}
                  selected={selected}
                  noWrap={true}
                  variant="subtitle2"
                  data-cy="event-card-title"
                >
                  {eventTitle}
                </CardText>
              </Tooltip>
              <Typography
                style={{ lineHeight: 0, color: selected ? 'white' : undefined }}
                variant="overline"
                data-cy="event-card-time"
              >
                {Utils.Date.getTimeAgo(Date.now() - (event.arrived ? event.arrived : Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate)))}
              </Typography>
            </Box>
            <Box
              width="100%"
              height="100%"
              display="grid"
              gridTemplateColumns="auto max-content"
              gap="0.5rem"
              alignItems="center"
            >
              <CardText
                onMouseEnter={() => setDescriptionHovered(true)}
                onMouseLeave={() => setDescriptionHovered(false)}
                selected={selected}
                noWrap={true}
                variant="body2"
                data-cy="event-card-desc"
              >
                {description}
              </CardText>
              {Boolean(event.tags) && !isEmpty(event.tags) && <EventTagLabel event={event} selected={selected} />}
            </Box>
            <Box
              width="100%"
              height="100%"
              display="grid"
              gridTemplateColumns="min-content auto"
              gap="0.5rem"
              alignItems="center"
            >
              <Tooltip title={Utils.User.getUserName(userData || {})}>
                <Box>
                  {event.owner &&
                    <Avatar
                      fontSize={12}
                      lineHeight={1}
                      disableGap
                      user={userData}
                      height={20}
                      width={20}
                    />
                  }
                </Box>
              </Tooltip>
              <Box width="100%" display="flex" alignItems="flex-end" justifyContent="flex-end">
                {event.tags && event.tags[0] && event.tags[0].toString().includes("escalate") &&
                  <CustomIcon fontSize='small' color="error" />
                }
                {renderSelectingUsersAvatars()}
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default EventCard;
