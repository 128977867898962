import React from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import "../../../include/mapbox-gl-style-switcher/MapBoxGlStyleSwitcherControl.css";
import API from "@API/index";
import Device from "./Device";
import { ICredentials } from '@store/session/types';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import ViewStream from '../view/ViewStream';
import Utils from '@utils/index';
import MapBoxBoundaries from '@classes/MapBoxBoundaries';
import MapboxSiteMarker from './MapboxSiteMarker';
import { Tooltip, Button, Box } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import TutorialButton from '@components/common/TutorialButton';
import { MapboxStyleSwitcherControl } from '../../../include/mapbox-gl-style-switcher/MapBoxGlStyleSwitcherControl';

type MapItem = VideoStream & {
  map: mapboxgl.Map | null;
}
type SiteMapProps = {
  streams: VideoStream[];
  height?: string | number;
  multiplesites?: {
    sites: Site[];
  },
  footageInfo?: {
    timestamp: number,
    live: boolean
  }
}

const SiteMap: React.FC<SiteMapProps> = (props) => {
  const { streams, height, multiplesites, footageInfo } = props;

  const credentials: ICredentials | undefined = useSelector((store: IStoreState) => store.session.credentials);
  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);
  const reduxSelectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);

  const [mapLoaded, setMapLoaded] = React.useState<boolean>(false);
  const [hoveredStream, setHoveredStream] = React.useState<VideoStream | undefined>(undefined);
  const [mapItems, setMapItems] = React.useState<MapItem[]>([]);
  const [selectedSite, setSelectedSite] = React.useState<Site | undefined>(reduxSelectedSite);
  const [canPopoverStreams, setCanPopoverStreams] = React.useState<boolean>(true);

  const siteCoordinates = React.useRef<Map<string, number[]>>(new Map());
  const mapContainer = React.useRef<HTMLDivElement | null>(null);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const mousey = React.useRef<number>(0);
  const mousex = React.useRef<number>(0);

  const streamsRef = React.useRef<VideoStream[]>(streams);
  const mapLoadedRef = React.useRef<boolean>(mapLoaded);
  const multiplesitesRef = React.useRef<{ sites: Site[] } | undefined>(multiplesites)
  const selectedSiteRef = React.useRef<Site | undefined>(selectedSite);
  const reduxSelectedSiteRef = React.useRef<any>(reduxSelectedSite);

  React.useEffect(() => {
    if (reduxSelectedSite) {
      setSelectedSite(reduxSelectedSite);
    } else {
      setSelectedSite(undefined);
    }
  }, [reduxSelectedSite])

  React.useEffect(() => {
    streamsRef.current = streams;
  }, [streams])

  React.useEffect(() => {
    mapLoadedRef.current = mapLoaded
  }, [mapLoaded])

  React.useEffect(() => {
    multiplesitesRef.current = multiplesites;
  }, [multiplesites])

  React.useEffect(() => {
    selectedSiteRef.current = selectedSite;
  }, [selectedSite])

  const updateMapItems = React.useCallback(() => {
    const mapItems = Array.from(streamsRef.current, (item) => {
      const test = { ...item, map: map.current };
      return test;
    });
    setMapItems(mapItems);
  }, [])

  const zoomInToSiteCameras = React.useCallback(() => {
    if (mapLoadedRef.current === false || multiplesitesRef.current) return;

    if (streamsRef.current.length > 0) {
      map.current?.fitBounds(new MapBoxBoundaries(streamsRef.current).generate(0.002) as any, { speed: 6 });
    }
  }, [])

  const zoomInToSelectedSite = React.useCallback(() => {
    if (mapLoadedRef.current === false) return;

    if (selectedSiteRef.current) {
      const coords = siteCoordinates.current.get(selectedSiteRef.current.siteId);
      if (coords) {
        const siteStreams = [{ longitude: coords[0], latitude: coords[1] } as VideoStream];
        map.current?.fitBounds(new MapBoxBoundaries(siteStreams).generate(0.004) as any, { speed: 6 });
      }
    }
  }, [])

  React.useEffect(() => {
    reduxSelectedSiteRef.current = reduxSelectedSite;
  }, [reduxSelectedSite])


  const zoomOutToUSA = React.useCallback(() => {
    if (mapLoadedRef.current === false) return;

    map.current?.fitBounds([-131.74804688, 52.48278022, -68.46679688, 22.59372606] as any, { speed: 6 });
  }, [])

  React.useEffect(load, [mapContainer, map, updateMapItems]);
  React.useEffect(updateMapItems, [streams, updateMapItems]);
  React.useEffect(cleanUp, []);
  React.useEffect(zoomInToSiteCameras, [mapLoaded, zoomInToSiteCameras]);
  React.useEffect(() => {
    if (selectedSite !== undefined) {
      setCanPopoverStreams(false);
      zoomInToSelectedSite();
      if (map.current) {
        map.current.scrollZoom.enable();
      }
      setTimeout(() => {
        setCanPopoverStreams(true);
      }, 1500)
    } else {
      zoomOutToUSA();
      // if (map.current) {
      //   map.current.scrollZoom.disable();
      // }
    }
  }, [selectedSite, zoomInToSiteCameras, zoomInToSelectedSite, zoomOutToUSA])

  /**
   * Handles loading of MapBox logic, set up events listeners and more.
   */
  function load(): void {
    if (mapContainer.current && !map.current) {
      let boundaries = [-131.74804688, 52.48278022, -68.46679688, 22.59372606] as any;

      if (reduxSelectedSiteRef.current && reduxSelectedSiteRef.current.coordinates) {
        try {
          const siteStreams = [{ longitude: reduxSelectedSiteRef.current.coordinates.longitude, latitude: reduxSelectedSiteRef.current.coordinates.latitude } as VideoStream];
          boundaries = new MapBoxBoundaries(siteStreams).generate(0.004) as any; 
        } catch (error) {
          boundaries = [-131.74804688, 52.48278022, -68.46679688, 22.59372606] as any;
        }
      }

      const mapOptions = {
        container: mapContainer.current,
        style: Utils.MapBox.generateStylesUrl('streets'),
        bounds: boundaries
      }

      map.current = API.MapBox.initializeMap(mapOptions);
      map.current.on('load', () => {
        const styleSwitcher = new MapboxStyleSwitcherControl();
        map.current?.addControl(styleSwitcher, "bottom-right");
        map.current?.addControl(new mapboxgl.NavigationControl(), "bottom-right")
        map.current?.resize();
        setMapLoaded(true);
      });

      map.current.on('style.load', () => {
        setMapItems([]);
        updateMapItems();
      })
    }
  }

  /**
   * Clean up associated data with the mounted mapp.
   */
  function cleanUp() {
    return () => {
      if (map.current) map.current.remove();
    }
  }

  /**
   * Set hovered stream state when the user enters with the mouse on top of a Camera Icon.
   */
  function onMouseOverStream(stream: VideoStream, x: number, y: number): void {
    if (Boolean(hoveredStream)) return;
    mousex.current = x;
    mousey.current = y;
    setHoveredStream(stream);
  }

  /**
   * Set the hovered stream state to undefined when the user mouse leaves any camera icon.
   */
  function onMouseLeaveStream(): void {
    setHoveredStream(undefined);
  }

  function getHoverStyles(): React.CSSProperties {
    const { windowWidth, windowHeight } = getStreamWindowSize();
    let top = 0;
    let left = 0;

    top = mousey.current - (windowHeight) - (rootFontSize * 2);
    left = mousex.current - ((windowWidth / 2));

    if (top <= 0) {
      top = mousey.current + 30;
    }

    if (left <= 70) {
      left = mousex.current + 30;
    } else if (left >= (window.innerWidth - windowWidth)) {
      left = mousex.current - windowWidth;
    }

    return {
      width: windowWidth,
      height: windowHeight,
      visibility: !hoveredStream ? 'hidden' : 'visible',
      position: 'absolute',
      top: top,
      left: left,
      zIndex: 9999
    }
  }

  function getStreamWindowSize(): { windowWidth: number, windowHeight: number } {
    try {
      const windowWidth = (window.innerWidth / 2) - (rootFontSize * 5) - 50;
      const windowHeight = (window.innerHeight / 2) - 50;
      return { windowWidth, windowHeight };
    } catch (e) {
      const windowWidth = rootFontSize * 48;
      const windowHeight = rootFontSize * 30;
      return { windowWidth, windowHeight };
    }
  }

  if (Boolean(multiplesites)) {
    return (
      <>
        {/* Cameras on the map */}
        <div style={{ height: height || '80vh' }} ref={mapContainer} className="map-container">
          <Box
            zIndex={1000}
            position='absolute'
            display="grid"
            gridTemplateColumns="auto auto"
            gap="0.5rem"
            marginTop='0.5rem'
            marginLeft='0.5rem'
          >
            {Boolean(multiplesites) && Boolean(selectedSite) &&
              <Tooltip title="Go back to site selection">
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => setSelectedSite(undefined)}
                  style={{ width: rootFontSize * 2.5, height: rootFontSize * 2.5, backgroundColor: 'white' }}
                >
                  <ArrowBack fontSize="small" />
                </Button>
              </Tooltip>
            }
            <TutorialButton
              tutorialType="overview_site_map"
            />
          </Box>
          {mapLoaded && map.current && multiplesites &&
            multiplesites.sites.map(site => (
              <MapboxSiteMarker
                map={map.current}
                site={site}
                onClick={(site: Site) => setSelectedSite(site)}
                onCoordinateAquired={(siteId: string, coordinates: number[]) => siteCoordinates.current.set(siteId, coordinates)}
              />
            ))
          }
          {mapLoaded && map.current &&
            mapItems.filter(el => el.siteId === selectedSite?.siteId).map(item => (
              <React.Fragment key={item.kvsName}>
                <Device
                  key={item.kvsName}
                  map={item.map}
                  stream={item}
                  onMouseOverStream={onMouseOverStream}
                  onMouseLeaveStream={onMouseLeaveStream}
                ></Device>
              </React.Fragment>
            ))
          }
        </div>
        {/* Window that opens when the user hover over a camera, displaying live footage of the stream. */}
        {hoveredStream && canPopoverStreams &&
          <div
            style={getHoverStyles()}
          >
            <ViewStream
              stream={{ kvsName: hoveredStream.kvsName, x: 0, y: 0, w: 0, h: 0 }}
              credentials={credentials as ICredentials}
              paused={false}
              playbackRate={1}
              live={true}
              hideThreeDotsMenu={true}
            />
          </div>
        }
      </>
    )
  }

  return (
    <>
      <TutorialButton
        tutorialType="views_map"
        IconButtonProps={{
          style: { position: 'absolute', marginTop: '0.5rem', marginLeft: '0.5rem', zIndex: 1000 }
        }}
      />
      {/* Cameras on the map */}
      <div style={{ height: height || '80vh' }} ref={mapContainer} className="map-container">
        {mapLoaded && map.current &&
          mapItems.map(item => (
            <React.Fragment key={item.kvsName}>
              <Device
                key={item.kvsName}
                map={item.map}
                stream={item}
                onMouseOverStream={onMouseOverStream}
                onMouseLeaveStream={onMouseLeaveStream}
              ></Device>
            </React.Fragment>
          ))
        }
      </div>
      {/* Window that opens when the user hover over a camera, displaying footage of the stream. */}
      {hoveredStream &&
        <div
          style={getHoverStyles()}
        >
          <ViewStream
            stream={{ kvsName: hoveredStream.kvsName, x: 0, y: 0, w: 0, h: 0 }}
            credentials={credentials as ICredentials}
            paused={false}
            playbackRate={1}
            hideThreeDotsMenu={true}
            live={footageInfo?.live || true}
            footageInfo={footageInfo}
          />
        </div>
      }
    </>
  );
}


export default SiteMap;