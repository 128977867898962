type MinimalUser = {
  name: string;
  isCurrentUser: boolean;
}

abstract class EventChange {
  protected _ts: number;
  public get ts(): number { return this._ts }

  protected _userId: string;
  public get userId(): string { return this._userId }

  protected _type: "TAG_ADDED" | "TAG_REMOVED" | "TAB_CHANGED" | "OWNER_CHANGED";
  public get type(): "TAG_ADDED" | "TAG_REMOVED" | "TAB_CHANGED" | "OWNER_CHANGED" { return this._type; }

  protected _value: string;
  public get value(): string { return this._value; }

  protected _author: MinimalUser | undefined;
  public get author(): MinimalUser | undefined { return this._author; }

  protected _target: MinimalUser | undefined;
  public get target(): MinimalUser | undefined { return this._target; }

  protected _previousEvent: APIEventChanges | undefined;
  public get previousEvent(): APIEventChanges | undefined { return this._previousEvent }

  constructor(apiObject: APIEventChanges, author?: MinimalUser, target?: MinimalUser, previousEvent?: APIEventChanges) {
    this._ts = apiObject.ts;
    this._userId = apiObject.userId;
    this._type = apiObject.type;
    this._value = apiObject.value;
    this._author = author;
    this._target = target;
    this._previousEvent = previousEvent;
  }

  public abstract getIconElement(): JSX.Element;
  public abstract getTextElement(): JSX.Element;
}

export default EventChange;
