import { API } from "aws-amplify";

export interface IPaginationSupport {
  responseData: AuditLog[];
  lastEvaluatedTimestamp?: string;
}

async function getAuditLogs(companyId: string, start: number, end: number, lastEvaluatedTimestamp?: string, siteIds?: string[], userIds?: string[]): Promise<IPaginationSupport> {
  const path: string = `/auditLog/${companyId}`;

  const queryStringParameters: any = {};
  queryStringParameters.startTimestamp = start;
  queryStringParameters.endTimestamp = end;
  if (siteIds)
    queryStringParameters.siteIds = siteIds;
  if (userIds)
    queryStringParameters.userIds = userIds;
  if (lastEvaluatedTimestamp)
    queryStringParameters.lastEvaluatedTimestamp = lastEvaluatedTimestamp;
  try {
    const data = await API.get(
      'api-service',
      path,
      {
        queryStringParameters: queryStringParameters
      }
    )
    return data.data;
  } catch (error) {
    throw error;
  }
}

export default getAuditLogs;
