const database = new Map();

export async function getBase64FromUrl(url: string, width?: number, height?: number): Promise<{ b64: string, width: number, height: number }> {
  try {
    if (database.has(url)) return database.get(url);
    
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = url;
    await img.decode();

    var canvas = document.createElement("canvas");
    canvas.width = width || img.width;
    canvas.height = height || img.height;
    var ctx = canvas.getContext("2d");
    if (width && height) {
      ctx?.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
    } else {
      ctx?.drawImage(img, 0, 0);
    }
    var base64 = canvas.toDataURL("image/png");
    database.set(url, { b64: base64, width: img.width, height: img.height })
    return { b64: base64, width: img.width, height: img.height };
  } catch (error) {
    console.log(error);
    throw error;
  }
}
