import React, { useEffect, useState } from 'react';
import { Box, Checkbox, InputAdornment, Popover, TextField } from '@mui/material';
import styled from 'styled-components';
import { DayLabel } from '@declarations/enums';
import { ExpandMore } from '@mui/icons-material';
import Utils from '@utils/index';

const TimeInput = styled(TextField)`
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }
`
interface IScheduleInputProps {
  day: DayLabel;
  value?: ActiveDayTime;
  onChange: (day: DayLabel, value?: ActiveDayTime, interval?: number) => void;
  interval: number;
  mustStartAfter?: string;
  disabled?: boolean;
}

const DayTimeRangeInput: React.FC<IScheduleInputProps> = (props) => {
  const { day, value, onChange, interval, mustStartAfter, disabled } = props;

  const [timeRangeInputOpen, setTimeRangeInputOpen] = useState(false);
  const [anchorElDateRange, setAnchorElDateRange] = useState<Element | null>(null);

  const onChangeRef = React.useRef(onChange)
  const intervalRef = React.useRef(interval);
  const mustStartAfterRef = React.useRef(mustStartAfter)

  useEffect(() => { mustStartAfterRef.current = mustStartAfter }, [mustStartAfter])
  useEffect(() => { intervalRef.current = interval }, [interval])
  useEffect(() => { onChangeRef.current = onChange }, [onChange])
  useEffect(validate, [value, day])

  const onTimeRangeSelectClick = (e: any) => {
    e.stopPropagation();
    setTimeRangeInputOpen((v) => !v);
    setAnchorElDateRange(e.currentTarget);
  }

  function validate() {
    if (value) {
      const start = Utils.Date.stringToDate(value.from);
      const end = Utils.Date.stringToDate(value.to);
      if (value.from === "23:59") {
        return;
      }
      if (mustStartAfterRef.current) {
        const mustStartAfterTime = Utils.Date.stringToDate(mustStartAfterRef.current);
        if (start.getTime() < mustStartAfterTime.getTime()) {
          onChangeRef.current(day, {
            ...value,
            from: mustStartAfterRef.current
          }, intervalRef.current)
        }
      }
      if (start.getTime() >= end.getTime()) {
        end.setHours(start.getHours());
        end.setMinutes(start.getMinutes() + 1);
        onChangeRef.current(day, {
          ...value,
          to: end.toTimeString().split(":").slice(0, 2).join(":")
        }, intervalRef.current)
      }
    }
  }

  return (
    <Box display="grid" gridTemplateColumns={"min-content 2fr"}>
      <Checkbox
        color="primary"
        checked={!!value}
        disabled={disabled}
        data-cy={`day-time-range-input-${day}-checkbox`}
        onChange={(e, v) =>
          onChange(day, v ? {
            from: mustStartAfter ? mustStartAfter : "00:00",
            to: "23:59"
          } : undefined, interval)}
      />
      <TextField
        type='text'
        variant='outlined'
        disabled={!!!value}
        value={value ? `${Utils.Date.getAmPmFrom24Hour(value.from)} - ${Utils.Date.getAmPmFrom24Hour(value.to)}` : "Select time"}
        onClick={onTimeRangeSelectClick}
        style={{ marginTop: 0 }}
        inputProps={{
          readOnly: true,
          style: { cursor: "pointer" },
        }}
        InputProps={{
          endAdornment:
            <InputAdornment position="end" style={{ cursor: "pointer" }}>
              <ExpandMore />
            </InputAdornment>
        }}
        data-cy="day-time-range-input"
      />
      {
        value &&
        <Popover
          open={timeRangeInputOpen}
          anchorEl={anchorElDateRange}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => setTimeRangeInputOpen(false)}
        >
          <Box display="grid" gap="1.5rem" padding="1.5rem" onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}>
            <TimeInput
              id="time"
              type="time"
              label="Start time"
              variant="outlined"
              autoFocus
              data-cy="time-range-start-time-input"
              onChange={(e) => {
                onChange(day, {
                  ...value,
                  from: e.target.value === "23:59" ? "23:58" : e.target.value
                }, interval)
              }}
              value={value.from}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              />
            <TimeInput
              id="time"
              type="time"
              label="End time"
              variant="outlined"
              data-cy="time-range-end-time-input"
              onChange={(e) => {
                onChange(day, {
                  ...value,
                  to: e.target.value
                }, interval);
              }}
              value={value.to}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Box>
        </Popover>
      }
    </Box >
  );
}

export default DayTimeRangeInput;