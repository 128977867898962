import { ActionTypes } from "./types";

export function postExportRequest(stream: VideoStream, timerange: { start: number, end: number }, name: string, description: string, options?: { channels?: string[] }) {
  return {
    type: ActionTypes.POST_REQUEST,
    stream: stream,
    timerange,
    options,
    name,
    description
  }
}

export function updateRequestLink(requestId: string, url: string) {
  return {
    type: ActionTypes.UPDATE_REQUEST_LINK,
    requestId,
    url
  }
}

export function setRequestAsDone(requestId: string, exportId: string) {
  return {
    type: ActionTypes.SET_REQUEST_AS_DONE,
    requestId,
    exportId
  }
}

export function setRequestAsError(requestId: string) {
  return {
    type: ActionTypes.SET_REQUEST_AS_ERROR,
    requestId
  }
}

export function setRequestAsCanceled(requestId: string) {
  return {
    type: ActionTypes.SET_REQUEST_AS_CANCELED,
    requestId
  }
}

export function postDownloadRequest(exportRequest: ExportRequest) {
  return {
    type: ActionTypes.POST_DOWNLOAD_REQUEST,
    exportRequest
  }
}

export function setDownloadRequestAsDone(requestId: string) {
  return {
    type: ActionTypes.SET_DOWNLOAD_REQUEST_AS_DONE,
    requestId
  }
}

export function setDownloadRequestAsError(requestId: string) {
  return {
    type: ActionTypes.SET_DOWNLOAD_REQUEST_AS_ERROR,
    requestId
  }
}

export function setDownloadRequestAsCanceled(requestId: string) {
  return {
    type: ActionTypes.SET_DOWNLOAD_REQUEST_AS_CANCELED,
    requestId
  }
}

export function setVideoClips(kvsName: string, videoClips: VideoDownload[]) {
  return {
    type: ActionTypes.SET_VIDEO_CLIPS_FOR_CAMERA,
    kvsName, videoClips
  }
}

export function setTimelapses(kvsName: string, timelapses: Timelapse[]) {
  return {
    type: ActionTypes.SET_TIMELAPSES_FOR_CAMERA,
    kvsName, timelapses
  }
}

export function postTimelapseRequest(params: TimelapseCreationParams, kvsName: string) {
  return {
    type: ActionTypes.POST_TIMELAPSE_REQUEST,
    params, kvsName
  }
}

export function updateTimelapseRequestLink(requestId: string, url: string) {
  return {
    type: ActionTypes.UPDATE_TIMELAPSE_REQUEST_LINK,
    requestId,
    url
  }
}

export function setTimelapseRequestAsDone(requestId: string, exportId: string) {
  return {
    type: ActionTypes.SET_TIMELAPSE_REQUEST_AS_DONE,
    requestId,
    exportId
  }
}

export function setTimelapseRequestAsError(requestId: string) {
  return {
    type: ActionTypes.SET_TIMELAPSE_REQUEST_AS_ERROR,
    requestId
  }
}

export function setTimelapseRequestAsCanceled(requestId: string) {
  return {
    type: ActionTypes.SET_TIMELAPSE_REQUEST_AS_CANCELED,
    requestId
  }
}
