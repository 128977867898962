import { API } from "aws-amplify";

async function createAction(siteId: string, action: Action): Promise<Action> {
  try {
    const path: string = `/actions/${siteId}`;

    const options = {
      body: action
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    return data.data as Action;
  } catch (error: any) {
    throw error;
  }
}

export default createAction;

