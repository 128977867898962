import { API } from "aws-amplify";

export async function overrideZoneSchedule(schedule: { from: string, to: string }[]): Promise<void> {
  try {
    const path: string = `/geofence/override`;

    const options = {
      body: schedule
    }

    await API.post('api-service', path, options);
  } catch (error) {
    console.log(error);
    throw error;
  }
}