import SearchHeader from '@components/common/SearchHeader';
import useUpdateEdgeDevices from '@hooks/useUpdateEdgeDevices';
import { Box, IconButton, Typography } from '@mui/material';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import Utils from '@utils/index';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { IEdgeDevice } from '@store/assets/types';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';
import { isEmpty } from 'lodash';
import { ShowChart } from '@mui/icons-material';
import Metrics from '@components/common/Metrics';
import CustomDataGrid from '@components/common/DataGrid/CustomDataGrid';
import NonIdealState from '@components/common/NonIdealState/NonIdealState';

const HealthEdgeDevices: React.FC<{}> = () => {
  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();
  const isSuperAdministrator = useSelector((store: IStoreState) => store.session.isSuperAdministrator);

  const reduxEdgeDevices = useSelector((store: IStoreState) => store.assets.assets);
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);

  const [edgeDevices, setEdgeDevices] = React.useState<IEdgeDevice[]>(selectedSite ? reduxEdgeDevices.filter(el => el.siteId === selectedSite.siteId) : reduxEdgeDevices)
  const [filteredEdgeDevices, setFilteredEdgeDevices] = React.useState<IEdgeDevice[]>(edgeDevices);
  const [devices, setDevices] = React.useState<string[]>([]);

  const isUpdatingEdgeDevices = useUpdateEdgeDevices(selectedCompany?.companyId);

  React.useEffect(() => {
    if (selectedSite) {
      setEdgeDevices(reduxEdgeDevices.filter(el => el.siteId === selectedSite.siteId))
    } else {
      setEdgeDevices(reduxEdgeDevices);
    }
  }, [selectedSite, selectedCompany, reduxEdgeDevices])

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    'companyId': false,
    'siteId': true,
    'ioThingName': true,
    'mac': true
  })

  const changeVisibilityFromMobile = React.useCallback(() => {
    if (isMobile || isSmallScreen) {
      setColumnVisibilityModel({
        'companyId': false,
        'siteId': false,
        'ioThingName': true,
        'mac': true
      })
    } else {
      setColumnVisibilityModel({
        'companyId': false,
        'siteId': true,
        'ioThingName': true,
        'mac': true
      })
    }
  }, [isMobile, isSmallScreen])

  React.useEffect(changeVisibilityFromMobile, [changeVisibilityFromMobile])
  const columns = React.useMemo(() => {
    const base = [
      {
        field: 'companyId',
        headerName: 'Company ID',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Typography noWrap variant="body2">{params.value}</Typography>
        )
      },
      {
        field: 'siteId',
        headerName: 'Site ID',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Typography noWrap variant="body2">{params.value}</Typography>
        )
      },
      {
        field: 'iotThingName',
        headerName: 'IOT thing name',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Typography noWrap variant="body2">{params.value}</Typography>
        )
      },
      {
        field: 'mac',
        headerName: 'Mac address',
        flex: 0.25,
        sortable: true,
        renderCell: (params: any) => (
          <Typography noWrap variant="body2">{params.value}</Typography>
        ),
      }
    ]

    if (!isMobile && !isSmallScreen) {
      base.push({
        field: 'metrics',
        headerName: 'Metrics',
        flex: 0.05,
        sortable: false,
        renderCell: (params: any) => (
          <IconButton
            onClick={() => { setDevices([params.value]) }}
            size="medium"
          >
            <ShowChart />
          </IconButton>
        )
      })
    }

    return base;
  }, [isMobile, isSmallScreen])

  const textFilterProps = React.useMemo(() => ["siteId", "iotThingName", "mac"], []);

  function getDevicesByIds(ids: string[]): IEdgeDevice[] {
    const devices: IEdgeDevice[] = [];

    ids.forEach((id) => {
      const target = edgeDevices.find(el => el.iotThingName === id);
      if (target) devices.push(target);
    })

    return devices;
  }

  const devicesToShow = React.useMemo(() => {
    if (isUpdatingEdgeDevices) return [];

    return Utils.Array.orderAlphabetically(filteredEdgeDevices, 'companyId').map((edgeDevice: IEdgeDevice) => ({
      id: `${edgeDevice.companyId}-${edgeDevice.siteId}-${edgeDevice.iotThingName}-${edgeDevice.mac}`,
      companyId: edgeDevice.companyId,
      siteId: edgeDevice.siteId,
      iotThingName: edgeDevice.iotThingName,
      mac: edgeDevice.mac,
      metrics: edgeDevice.iotThingName

    }))
  }, [filteredEdgeDevices, isUpdatingEdgeDevices])

  if (isSuperAdministrator === false) {
    return (
      <NonIdealState
        title="Not found"
        description="Page was not found, please check your information."
        errorCode={404}
      />
    )
  }

  if (!isEmpty(devices)) {
    return (
      <Metrics
        devices={getDevicesByIds(devices)}
        onClose={() => setDevices([])}
      />
    )
  }

  return (
    <Box display="grid" gridTemplateRows="min-content auto" width="100%" height="100%" padding="1.5rem" gap="2rem">
      <SearchHeader
        title="Edge devices"
        textFilterProps={textFilterProps}
        textFiltering={true}
        noPadding
        searchHeaderFilterProps={{
          onFilteredDataChange: (data: any[]) => { setFilteredEdgeDevices(data) },
          data: edgeDevices,
          properties: [
            {
              label: 'Company ID',
              property: 'companyId',
              type: 'string'
            },
            {
              label: 'Site ID',
              property: 'siteId',
              type: 'string'
            },
            {
              label: 'IOT thing name',
              property: 'iotThingName',
              type: 'string'
            },
            {
              label: 'Mac address',
              property: 'mac',
              type: 'string'
            }
          ]
        }}
      />
      <CustomDataGrid
        hideHeaders={isUpdatingEdgeDevices || filteredEdgeDevices.length === 0}
        loading={isUpdatingEdgeDevices}
        defaultHeight='82vh'
        disableColumnFilter
        disableRowSelectionOnClick
        rows={devicesToShow}
        columns={columns}
        onColumnVisibilityModelChange={(model) => setColumnVisibilityModel(model)}
        columnVisibilityModel={columnVisibilityModel}
        dataCy="overview-edge-devices-table"
      />
    </Box>
  )
}

export default HealthEdgeDevices;
