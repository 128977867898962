import { List, ListItem, ListItemIcon, ListItemText, Menu, MenuProps } from '@mui/material';
import { ExitToApp, Person } from '@mui/icons-material';
import React from 'react';
import { Avatar } from '@components/common/Avatar';

interface IUserMenuProps extends MenuProps {
  userData?: User;
  isSuperAdministrator?: boolean;
  isTemporaryAccessRole?: boolean;
  isTemporarySuperAdmininistrator?: boolean;
  selectedCompany?: Company;
  onCompanyClick: (event: any) => void;
  onSignOutClick: () => Promise<void>;
  onManageAccountClick: () => void;
  onAccessRequestsClick: () => void;
}

const UserMenu: React.FC<IUserMenuProps> = (props) => {
  const {
    userData,
    isSuperAdministrator,
    isTemporaryAccessRole,
    isTemporarySuperAdmininistrator,
    selectedCompany,
    onCompanyClick,
    onSignOutClick,
    onManageAccountClick,
    onAccessRequestsClick,
    ...menuProps
  } = props;

  return (
    <Menu
      {...menuProps}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      style={{ marginLeft: "1rem" }}
      elevation={3}
    >
      <List disablePadding >
        <ListItem style={{ pointerEvents: 'none' }} data-cy="user-menu-user-details-list-item">
          <ListItemIcon style={{ width: '3rem' }}>
            <Avatar
              user={userData}
              width="2.3rem"
              height="2.3rem"
            />
          </ListItemIcon>
          <ListItemText
            style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            primary={`${userData?.givenName} ${userData?.familyName}`}
            secondary={userData?.email}
          />
        </ListItem>

        <ListItem button onClick={onManageAccountClick} data-cy="user-menu-manage-account-list-item">
          <ListItemIcon style={{ width: '3rem' }}>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Manage my account" />
        </ListItem>

        <ListItem button onClick={onSignOutClick} data-cy="user-menu-sign-out-list-item">
          <ListItemIcon style={{ width: '3rem' }}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>

      </List>
    </Menu>
  )
}

export default UserMenu;
