import React from 'react';
import API from '@API/index';

type ZonesLoaderProps = {
  stream: VideoStream;
  onLoadStart?: () => void;
  onError?: (error: any) => void;
  onLoadDone?: (zones: Zone[]) => void;
}

export interface IZonesLoaderHandles {
  refresh: () => Promise<void>;
}

const ZonesLoader: React.ForwardRefRenderFunction<IZonesLoaderHandles, ZonesLoaderProps> = (props, ref) => {
  const { stream, onLoadStart, onError, onLoadDone } = props;

  const streamRef = React.useRef(stream);
  const onLoadStartRef = React.useRef(onLoadStart);
  const onErrorRef = React.useRef(onError);
  const onLoadDoneRef = React.useRef(onLoadDone);

  React.useImperativeHandle(ref, () => ({
    refresh
  }))

  React.useEffect(() => { streamRef.current = stream }, [stream]);
  React.useEffect(() => { onLoadStartRef.current = onLoadStart }, [onLoadStart]);
  React.useEffect(() => { onErrorRef.current = onError }, [onError]);
  React.useEffect(() => { onLoadDoneRef.current = onLoadDone }, [onLoadDone]);
  React.useEffect(() => { fetchZones() }, [stream]);
  

  async function fetchZones(): Promise<void> {
    if (onLoadStartRef.current) onLoadStartRef.current();

    try {
      const data: Zone[] = await API.Geofences.getZones(streamRef.current.kvsName);
      if (onLoadDoneRef.current) onLoadDoneRef.current(data);
    } catch (error) {
      if (onErrorRef.current) onErrorRef.current(error);
    }
  }

  async function refresh(): Promise<void> {
    try {
      const data: Zone[] = await API.Geofences.getZones(stream.kvsName);
      if (onLoadDone) onLoadDone(data);
    } catch (error) {
      if (onError) onError(error);
    }
  }

  return null;
}

export default React.forwardRef(ZonesLoader);
