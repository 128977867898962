import { List } from '@mui/material';
import styled from 'styled-components';

// export const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: "15rem",
//     flexShrink: 0,
//     whiteSpace: 'nowrap',
//   },

//   drawerOpen: {
//     width: "15rem",
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     overflowX: 'hidden',
//   },

//   drawerClose: {
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     overflowX: 'hidden',
//     width: "4.5rem"
//   }
// }))

export const CustomList = styled(List)(({ theme }) => ({
  width: "100%",
  maxWidth: "22.5rem",
  backgroundColor: theme.palette.background.paper,
  position: "relative",
  overflow: "auto",
  maxHeight: "50vh",
  padding: 0,
}));

export const CustomLi = styled("li")(() => ({
  backgroundColor: "inherit",
}));

export const CustomUl = styled("ul")(() => ({
  backgroundColor: "inherit",
  padding: 0,
}));