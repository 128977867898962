import createAction from "./createAction";
import deleteAction from "./deleteAction";
import listActions from "./listActions";
import updateAction from "./updateAction";

export interface IActionsAPI {
  listActions(siteId: string, filterText: string, lastActionId?: string): Promise<Action[]>;
  updateAction(siteId: string, action: Action): Promise<Action>;
  createAction(siteId: string, action: Action): Promise<Action>;
  deleteAction(siteId: string, actionId: string): Promise<Action>;
}

const Actions: IActionsAPI = {
  listActions,
  updateAction,
  createAction,
  deleteAction
}

export default Actions;
