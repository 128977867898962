import { API } from "aws-amplify";

async function restartGreengrass(iotThingName: string): Promise<void> {
  try {
    const path: string = `/restart/streaming?iotThingName=${iotThingName}`;

    await API.post(
      'api-service',
      path,
      {}
    );

  } catch (error: any) {
    throw error;
  }
}

export default restartGreengrass;
