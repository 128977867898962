import { AppBar, Box, CircularProgress, Dialog, IconButton, Slide, Toolbar } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import Utils from "@utils/index";
import { Storage } from 'aws-amplify';
import styled from 'styled-components';
import { Close } from '@mui/icons-material';

const DOCXContainer = styled.div`
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-right: 30vw;
	padding-left: 30vw;
`;

type PrivacyPolicyDialogProps = {
  open: boolean;
  onClose: () => void;
}

const PrivacyPolicyDialog: React.FC<PrivacyPolicyDialogProps> = (props) => {
  const { open, onClose } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [documentLoaded, setDocumentLoaded] = React.useState<boolean>(false);
  const [document, setDocument] = React.useState<string>("");

  const enqueueSnackbarRef = React.useRef(enqueueSnackbar);
  const documentVersion = React.useRef<string>("");

  React.useEffect(() => { enqueueSnackbarRef.current = enqueueSnackbar }, [enqueueSnackbar])
  React.useEffect(() => { downloadTermsOfUse() }, [])

  async function downloadTermsOfUse(): Promise<void> {
    try {
      const data = await Storage.get('privacy.html', {
        bucket: process.env.REACT_APP_TERMS_OF_USE_BUCKET || "",
        download: true
      })

      documentVersion.current = data.VersionId || "";
      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {
        if (e.target && e.target.result) {
          setDocumentLoaded(true);
          setDocument(e.target.result as string)
        }
      });

      reader.readAsText(data.Body as Blob);

    } catch (error) {
      enqueueSnackbarRef.current(Utils.Error.getErrorMessage(error), { variant: 'error' })
      console.log(error);
    }
  }

  return (
    <Dialog
      TransitionComponent={Slide}
      open={open}
      style={{ zIndex: 99999 }}
      fullScreen
      PaperProps={{ style: { borderRadius: 0 } }}
      onClose={onClose}
    >
      <AppBar sx={{ position: 'sticky' }} style={{ backgroundColor: 'white' }}>
        <Toolbar style={{ paddingLeft: '1rem', paddingRight: '1rem', width: '100%' }}>
          <Box sx={{ ml: 2, flex: 1 }} component="div" />
          <IconButton
            onClick={() => onClose()}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      {documentLoaded === false ? (
        <Box width="100%" height="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <DOCXContainer dangerouslySetInnerHTML={{ __html: document }} />
        </Box>
      )
      }
    </Dialog>
  )
}

export default PrivacyPolicyDialog;
