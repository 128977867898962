import { API } from "aws-amplify";

export async function estimateHomographyMatrix(pairPoints: Array<{cameraViewPoints: number[], satelliteViewPoints: number[]}>): Promise<Array<Array<number>>> {
  try {
    const path: string = `/estimate-homography`
    const data = await API.post(
      'api-service',
      path,
      {
        body: pairPoints.map((el) => (
          {
            cameraViewPoints: el.cameraViewPoints,
            satelliteViewPoints: el.satelliteViewPoints
          }
        ))
      }
    )

    console.log(data.data);
    return data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}