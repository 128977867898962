import { API } from "aws-amplify";
import { fromPairs, sortBy, toPairs } from "lodash";

// [[-0.0024592101728401075, 0.024465256627291074, 15.975844244000353], [-0.007051000597967475, 0.07014291177997323, 45.80049460514306],[-0.00015394895810586277, 0.0015315235973902069, 1]]

async function listStreams(siteId?: string, companyId?: string): Promise<VideoStream[]> {
  let path: string = '';
  if (siteId) {
    path = `/streams/${siteId}?limit=500`
  } else if (companyId) {
    path = `/streams?companyId=${companyId}&limit=500`
  } else {
    throw new Error("Either companyId or siteId is required to list streams.");
  }

  try {
    const data: APIResponse<VideoStream[]> = await API.get(
      'api-service',
      path,
      {}
    )

    let lastKey: number | { s: number, src: string } | { siteId: string, kvsName: string } | undefined = data.lastKey;

    if (data.data) {
      while (lastKey !== undefined) {
        const newPath = path + `&lastKey=${encodeURI(JSON.stringify(lastKey))}`;
        const newData: APIResponse<VideoStream[]> = await API.get('api-service', newPath, {});

        if (newData.data) {
          data.data.push(...newData.data);
        }

        lastKey = newData.lastKey;
      }

      return (data.data as VideoStream[]).sort((s1, s2) =>
        (s1?.defaultName || "z").localeCompare((s2?.defaultName || "z"), undefined, { numeric: true })).map((el) => ({...el, cvParameters: fromPairs(sortBy(toPairs(el.cvParameters), [0]))}));
    }
    return []
  } catch (error: any) {
    return [];
  }
}

export default listStreams;
