import { Storage } from "aws-amplify";
import Utils from '@utils/index';

async function getShareImageLink(path: string): Promise<string> {
  try {
    const url = await Storage.get(Utils.S3.getKey(path) || path, {
      bucket: process.env.REACT_APP_DOWNLOAD_IMAGES_S3_BUCKET || "",
      contentDisposition: "inline",
      expires: 86400
    }) as string;
    
    return url;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export default getShareImageLink;
