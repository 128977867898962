import { EventsFilterParams } from '@classes/StreamEvents/EventsFilter';
import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_ACTIVE_TAB: null,
  SET_INBOX_FILTER: null,
  PUSH_EVENT: null,
  CLEAR_EVENTS: null,
  CHANGE_EVENT_STATUS: null,
  CHANGE_EVENT_TAG: null,
  SET_FILTER_BAR_EXPANDED: null,
  UPDATE_EVENTS: null,
  SET_OVERVIEW_OPTIONS: null,
  SPLIT_MERGED_EVENT: null,
  SET_EVENTS: null,
  SET_SELECTED_EVENTS: null,
  PUSH_ZONES: null,
  SET_CURRENT_ZONES: null,
  SET_SELECTED_ZONE: null,
  SET_FILTERED_ZONES: null,
  SET_EVENT_COUNT: null,
  SET_UNLOADED_EVENT_COUNT: null,
  SET_MUTE_TIME_VALUE: null,
  SET_MUTE_TIME_VALUE_TYPE: null,
  SET_HOVERED_ZONE_ID: null
});

export const StorageKeys = keyMirror({

});

export interface IOverviewViewState {
  activeTab: OverviewTabs;
  filters: EventsFilterParams | undefined;
  events: VideoStreamEvent[];
  eventInboxSidePanel: OverviewSidePanel;
  overviewOptions: OverviewOptions;
  selectedEvents: VideoStreamEvent[];
  eventCount: EventInboxCount;
  unloadedEventCount: EventInboxCount;
  zoneDatabase: { [key: string]: Zone[] };
  currentZones: Zone[];
  filteredZones: Zone[];
  selectedZone?: Zone;
  hoveredZoneId?: string[];
  muteTimeValue?: number;
  muteTimeValueType: "hours" | "minutes"
}

export enum OverviewTabs {
  SiteList = "site_list",
  SiteMap = "site_map",
  EventInbox = "event_inbox",
  EdgeDevices = "edge_devices",
  Cameras = "cameras",
  Reports = "reports",
  Satellite = "satellite",
  FlaggedWorkers = "flagged_workers",
  ImageMatch = "image_match"
}


export enum HealthTabs {
  EdgeDevices = "edge_devices",
  Cameras = "cameras",
}

export enum EventsTabs {
  EventInbox = "event_inbox",
  Reports = "reports",
}

export enum MapTabs {
  SiteMap = "site_map",
}

export enum SitesTabs {
  Sites = "site_list"
}

export enum CompaniesTabs {
  Companies = "companies_list"
}