import React from "react";
import mapboxgl from "mapbox-gl";
import LivePortion from "./LivePortion";
import CanvasComponents from "./CanvasesComponents";
import PubSubsManager from "@classes/PubSubsManager";
import StreamMarker from "@classes/MapBox/StreamMarker";

type DeviceProps = {
  map: mapboxgl.Map | null,
  events?: GeoJSON.FeatureCollection,
  stream: VideoStream;
  onMouseOverStream: (stream: VideoStream, x: number, y:  number) => void;
  onMouseLeaveStream: () => void;
}

const Device: React.FC<DeviceProps> = (props) => {
  const { map, events, stream, onMouseOverStream, onMouseLeaveStream } = props;

  const [id, setId] = React.useState<string>("Offline");

  const markerRef = React.useRef<StreamMarker | null>(null);
  const onMouseOverStreamRef = React.useRef(onMouseOverStream);
  const onMouseLeaveStreamRef = React.useRef(onMouseLeaveStream);

  React.useEffect(() => { onMouseOverStreamRef.current = onMouseOverStream }, [onMouseOverStream]);
  React.useEffect(() => { onMouseLeaveStreamRef.current = onMouseLeaveStream }, [onMouseLeaveStream]);
  React.useEffect(connectWebsocket, [stream]);
  React.useEffect(assertOnline, [stream.statusOnDevice]);
  React.useEffect(placeStreamMarker, [map, stream]);

  function placeStreamMarker() {
    if (map) {
      const marker = new StreamMarker()
        .setLngLat(new mapboxgl.LngLat(stream.longitude, stream.latitude))
        .setDraggable(false)
        .withLabel(map as mapboxgl.Map, stream.defaultName || stream.kvsName);

      if (stream.statusOnDevice !== "online") marker.setInteractable(false)

      marker.setAngleEastOfNorth(stream.angleEastOfNorth || 0);
     
      const markerDiv = marker.getElement();
      markerDiv.addEventListener('mouseenter', (e) => { onMouseOverStreamRef.current(stream, e.clientX, e.clientY) });
      markerDiv.addEventListener('mouseleave', (e) => { onMouseLeaveStreamRef.current(); });

      marker.addTo(map);
      markerRef.current = marker;
    }

    return () => {      
      if (markerRef.current && map) {
        markerRef.current.cleanUp(map);
        markerRef.current.remove();
      }
    }
  }

  /**
   * Handles subscription and unsubscribe to AWS PubSub for the stream.
   */
  function connectWebsocket() {
    PubSubsManager.instance.subscribeToStream(stream, onMessage);

    return () => {
      PubSubsManager.instance.unsubscribe(stream);
    }
  }

  /**
   * Display a 1 seconds flashing effect on the canvas when a event is triggered.
   */
  function onMessage(message: any) {
    let delay: number = 1000;

    if (message.e && message.s) {
      const endDate = +message.e;
      const startDate = +`${message.s}`.substring(0, `${message.s}`.length - 2);
      delay = endDate - startDate;
    }

    setId(message.t);

    setTimeout(() => {
      setId('default');
    }, delay)
  }

  function assertOnline() {
    if (stream.statusOnDevice === "online") setId("default");
    else setId("Offline");
  }

  if (!map) return null;

  return (
    <React.Fragment>
      <CanvasComponents
        map={map}
        visibleCanvasId={id}
        stream={stream}
      />
      <LivePortion
        map={map}
        Id={stream.kvsName}
        events={events}
      />
    </React.Fragment>
  )
}


export default Device;