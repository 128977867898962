import Utils from "@utils/index";
import { IActionsState, ActionTypes } from "./types";
import * as actions from './actions';
type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IActionsState = {
  actions: []
}

export const actionsReducer = Utils.Redux.createReducer<
  IActionsState,
  Types,
  Actions
>(initialState, {

  [ActionTypes.SET_ACTIONS]: (state, value) => ({
    ...state,
    actions: value.actions
  })
})