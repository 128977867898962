import { API } from "aws-amplify";

async function getDefaultCVParameters(): Promise<Object> {
  const path: string = `/parameters/cv`;

  try {
    const data = await API.get(
      'api-service',
      path,
      {}
    )
    return data.data;
  } catch (error) {
    throw error;
  }
}

export default getDefaultCVParameters;
