import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import React from 'react';

interface IOpenCloseDrawer {
  open: boolean,
  onClick?: () => void,
}

const OpenCloseDrawer: React.FC<IOpenCloseDrawer> = (props) => {
  const { open, onClick } = props;

  if (open === true) {
    return (
      <ListItem button onClick={onClick} >
        <ListItemText />
        <ListItemIcon style={{ minWidth: 0 }} >
          <ChevronLeft />
        </ListItemIcon>
      </ListItem>
    )
  }

  return (
    <ListItem data-cy="open-drawer" button onClick={onClick} >
      <ListItemIcon>
        <Tooltip arrow placement="right" title="Expand">
          <ChevronRight />
        </Tooltip>
      </ListItemIcon>
      <ListItemText />
    </ListItem>
  )
}

export default OpenCloseDrawer;
