import { Filter } from "@classes/Filter/Filter";
import { EventsFilterParams } from "./EventsFilter";
import IFilterEvents from "./IFilterEvents";

class MotionFilter implements IFilterEvents {
  filter(params: EventsFilterParams, events: VideoStreamEvent[]): VideoStreamEvent[] {
    const { motion } = params;
    
    if(!motion.enable) {
      return [];
    }

    if (motion?.filters?.length) {
      return new Filter(motion.filters).filter(events);
    } else {
      return events;
    }
  }
}

export default MotionFilter;
