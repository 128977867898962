import React from 'react';

function useRenderOnWindowResize() {
  const [windowSize, setWindowSize] = React.useState<{ width: number, height: number }>({ width: window.innerWidth, height: window.innerHeight });

  React.useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const handler = function(this: Window, _ev: UIEvent) {

      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        setWindowSize({ width: this.innerWidth, height: this.innerHeight })
      }, 250)
    }

    window.addEventListener('resize', handler)

    return () => { window.removeEventListener('resize', handler) }
  }, [])

  return windowSize;
}

export default useRenderOnWindowResize;
