import { AssetsPermissions } from "@store/session/types";
import MotionFilter from "./MotionFilter";
import OngoingFilter from "./OngoingFilter";
import SitesFilter from "./SitesFilter";
import StreamsFilter from "./StreamsFilter";
import VehicleFilter from "./VehicleFilter";
import ZoneFilter from "./ZoneFilter";
import SafetyFilter from "./SafetyFilter";
import PeopleFilter from "./PeopleFilter";
import _ from "lodash";

export type TimeFiltering = {
  timeFilterType: ">" | "<" | null,
  timeType: "min" | "sec" | null,
  timeValue: string
}

export type EventTypeFilters = { enable: boolean, filters: FilterToApply[] }
export type EventsFilterParams = {
  motion: EventTypeFilters,
  people: EventTypeFilters,
  vehicles: EventTypeFilters,
  safety: EventTypeFilters,
  zones: EventTypeFilters,
  sites?: {
    sites: string[];
  },

  streams?: {
    streams: string[];
  },

  permissions?: {
    permissions: AssetsPermissions;
  }
}

class EventsFilter {
  public params: EventsFilterParams;

  constructor(params: EventsFilterParams) {
    this.params = params;
  }

  public getFilteredEvents(events: VideoStreamEvent[], hideOngoingEvents: boolean = false) {
    const onGoingEvents = new OngoingFilter().filter(events, hideOngoingEvents);
    const siteFilteredEvents = new SitesFilter().filter(this.params, onGoingEvents);
    const streamsFilteredEvents = new StreamsFilter().filter(this.params, siteFilteredEvents);
    const eventByType: Map<string, VideoStreamEvent[]> = new Map();

    streamsFilteredEvents.forEach(e => {
      if (eventByType.has(e.eventType)) {
        eventByType.get(e.eventType)!.push(e);
      } else {
        eventByType.set(e.eventType, [e]);
      }
    });

    const motionFilteredEvents = new MotionFilter().filter(this.params, eventByType.get("Motion") || []);
    const peopleFilteredEvents = new PeopleFilter().filter(this.params, eventByType.get("Person detected") || []);
    const vehicleFilteredEvents = new VehicleFilter().filter(this.params, eventByType.get("Vehicle detected") || []);
    const safetyFilteredEvents = new SafetyFilter().filter(this.params, eventByType.get("Safety infraction") || [])
    const zonesFilteredEvents = new ZoneFilter().filter(this.params, eventByType.get("Geofence triggered") || []);

    const filteredEvents = [...motionFilteredEvents, ...peopleFilteredEvents, ...vehicleFilteredEvents, ...safetyFilteredEvents, ...zonesFilteredEvents]
    return filteredEvents;
  }

  public getCurrentParams(): EventsFilterParams { return this.params; }

  _extractTag(filters: FilterToApply[]): string[] {
    return (filters?.find(f => f.filter?.getName() === "tags")?.text as string[]) || [];
  }

  public getAllTags(): string[] {
    return _.uniq([
      ...this._extractTag(this.params.motion.filters),
      ...this._extractTag(this.params.people.filters),
      ...this._extractTag(this.params.vehicles.filters),
      ...this._extractTag(this.params.safety.filters),
      ...this._extractTag(this.params.zones.filters)
    ]);
  }

  public isEverythingDisabled(): boolean {
    return this._isAllEventsDisabled();
  }

  public isSitesDisabled(): boolean {
    const { sites } = this.params;
    return sites === undefined;
  }

  _isAllEventsDisabled(): boolean {
    const { motion, people, safety, vehicles, zones, } = this.params;

    return motion.filters.length === 0 && people.filters.length === 0 && safety.filters.length === 0 && vehicles.filters.length === 0 && zones.filters.length === 0;
  }
}

export default EventsFilter;
