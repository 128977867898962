import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { CustomSelect } from '../Schedule/ScheduleInput';
import Utils from '@utils/index';
import DatePickerPopover from '@components/timeline/CommandPanel/common/DatePickerPopover';
import * as moment from 'moment';

type TargetDateInputProps = {
  fullWidth?: boolean;
  disabled?: boolean;
  value?: number;
  onChange?: (timestamp: number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  timezone?: string;
  showTimeEstimate?: boolean;
  startTimeText?: string;
  endTimeText?: string;
  outlined?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  hintEnd?: string;
  error?: boolean;
}

/**
 * Input used to select a target date in the future with date selector and time selector.
 */
const TargetDateInput: React.FC<TargetDateInputProps> = (props) => {
  const {
    fullWidth,
    disabled,
    value,
    onChange,
    timezone,
    showTimeEstimate,
    startTimeText,
    endTimeText,
    outlined,
    disablePast,
    disableFuture,
    hintEnd,
    error,
    onBlur,
    onFocus
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <Box>
      <CustomSelect
        IconComponent={KeyboardArrowDown}
        disableUnderline={true}
        fullWidth={true}
        value={"default"}
        open={false}
        style={fullWidth ? { fontSize: "inherit" } : { fontSize: "inherit", maxWidth: '15vw' }}
        autoFocus={false}
        disabled={disabled}
        variant={outlined === true ? 'outlined' : "standard"}
        onClick={(e) => { if (!disabled) setAnchorEl(e.currentTarget) }}
        error={error}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <MenuItem value={"default"}>
          <Typography
            noWrap={true}
            variant="button"
            color="textPrimary"
          >
            {value ? Utils.Date.format(new Date(value), 'MMM Do, YYYY h:mm A', timezone) : 'Select time'}
          </Typography>
        </MenuItem>
      </CustomSelect>
      <DatePickerPopover
        style={{ zIndex: 9999 }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => { setAnchorEl(null) }}
        onFocus={onFocus}
        onBlur={onBlur}
        timezone={timezone || ''}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onCancelClick={() => { setAnchorEl(null); }}
        onConfirmClick={(_start: Date, end: Date) => {
          setAnchorEl(null);
          if (onChange) onChange(end.getTime());
        }}
        disablePast={disablePast !== undefined ? disablePast : true}
        disableFuture={disableFuture !== undefined ? disableFuture : false}
        hintEnd={hintEnd || moment.default(value).tz(timezone?.split(' ')[0] || "UTC").format('HH:mm')}
        endOnly={true}
        showTimeEstimate={showTimeEstimate}
        endTimeText={endTimeText}
        startTimeText={startTimeText}
        endDayHint={value}
      />
    </Box>
  )
}

export default TargetDateInput;
