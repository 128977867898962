import { API } from "aws-amplify";

export async function unmuteStream(companyId: string, kvsName: string): Promise<void> {
  try {
    await API.post(
      'api-service',
      `/muted/streams/${companyId}/${kvsName}/unmute`,
      {}
    )
  } catch (error) {
    throw error;
  }
}