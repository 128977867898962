import { useCallback, useState } from "react";
import { debounce } from "lodash";

const useDebouncedState = (initialState: any, durationInMs = 300) => {
  const [internalState, setInternalState] = useState(initialState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFunction = useCallback(debounce(setInternalState, durationInMs), [setInternalState, durationInMs]); 
  return [internalState, debouncedFunction];
};

export default useDebouncedState;