import { Card, Popover, Slider, Typography } from '@mui/material';
import React from 'react';

type PlaybackratePopoverProps = {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  onPlaybackRateChange: (value: number) => void;
  playbackRate: number;
}

export const PlaybackratePopover: React.FC<PlaybackratePopoverProps> = (props) => {
  const { anchorEl, setAnchorEl, onPlaybackRateChange, playbackRate } = props;

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => { setAnchorEl(null); }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Card style={{ padding: "1rem", width: '20vw' }}>
        <Typography>Playback speed</Typography>
        <Slider
          min={1}
          max={4}
          value={playbackRate}
          onChange={(e: object, value: any) => { onPlaybackRateChange(value) }}
          step={0.1}
          marks={[
            {
              value: 1,
              label: '1x'
            },
            {
              value: 2,
              label: '2x'
            },
            {
              value: 3,
              label: '3x'
            },
            {
              value: 4,
              label: '4x'
            }
          ]}
        />
      </Card>
    </Popover>
  )
}
