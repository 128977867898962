import deleteSnapshot from "./deleteSnapshot";
import getDownloadImageLink from "./getDownloadImageLink";
import getShareImageLink from "./getShareImageLink";
import getSnapshots from "./getSnapshots";
import getThumbnailImageLink from "./getThumbnailImageLink";
import postSnapshot from "./postSnapshot";

export interface ISnapshotAPI {
  postSnapshot(kvsName: string, base64Image: string, imageContentType: string, timestamp: number): Promise<ImageDownload>;
  getSnapshots(siteId: string): Promise<ImageDownload[]>;
  deleteSnapshot(siteId: string, id: string): Promise<boolean>;
  getShareImageLink(path: string): Promise<string>;
  getDownloadImageLink(path: string): Promise<string>;
  getThumbnailImageLink(path: string): Promise<string>;
}

const Snapshots: ISnapshotAPI = {
  postSnapshot,
  getSnapshots,
  deleteSnapshot,
  getShareImageLink,
  getDownloadImageLink,
  getThumbnailImageLink
}

export default Snapshots;
