import { Box, MenuItem, Select, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';

type ExportTypeSelectProps = {
  onChange: (value: "video_clip" | "timelapse") => void;
}

const ExportTypeSelect: React.FC<ExportTypeSelectProps> = (props) => {
  const { onChange } = props;

  const [exportType, setExportType] = React.useState<"video_clip" | "timelapse">("video_clip");

  React.useEffect(() => {
    onChange(exportType);
  }, [exportType, onChange])

  return (
    <Box id="timeline-save-video-type-selector-container" width="100%">
      <Typography variant="subtitle2">{`Type`}</Typography>
      <Select IconComponent={ExpandMore} fullWidth variant="outlined" value={exportType} data-cy={`export-type-select`}>
        <MenuItem onClick={() => setExportType("video_clip")} value="video_clip" data-cy={`export-type-video-menu-item`} >Video clip</MenuItem>
        <MenuItem onClick={() => setExportType("timelapse")} value="timelapse" data-cy={`export-type-timelapse-menu-item`} >Timelapse</MenuItem>
      </Select>
    </Box>
  )
}

export default ExportTypeSelect;
