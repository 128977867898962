import { Box, Typography } from '@mui/material';
import React from 'react';

type ViewFeedbackProps = {
  timestamp: number;
  uptime: VideoStreamTimeChunk[];
  isLive: boolean;
}

const ViewFeedback: React.FC<ViewFeedbackProps> = (props) => {
  const { timestamp, uptime, isLive } = props;

  const findChunkUnderTimelinePointer = React.useCallback((uptime: VideoStreamTimeChunk[], timestamp: number) => {
    return uptime.find(el => el.color && timestamp > el.start.getTime() && timestamp < el.end.getTime());
  }, [])

  const colorToBorder = React.useMemo(() => {
    return new Map([
      ["#B3E4FF", "1px solid #59717F"],
      ["#FCB3B2", "1px solid #7D5958"],
      ["#FDECB", "1px solid #7E7559"],
      ["#B3B3FF", "1px solid #59597F"]
    ])
  }, [])

  const currentChunk = React.useMemo(() => {
    return findChunkUnderTimelinePointer(uptime, timestamp)
  }, [findChunkUnderTimelinePointer, timestamp, uptime])

  const getBorderColor = React.useMemo(() => {
    if (currentChunk && currentChunk.color) {
      return colorToBorder.get(currentChunk.color)
    }
  }, [colorToBorder, currentChunk])

  if (isLive === true || currentChunk === undefined) return <div />

  return (
    <Box
      marginTop="2px"
      marginBottom="2px"
      minWidth="5rem"
      bgcolor={currentChunk.color}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      borderRadius={1}
      border={getBorderColor}
      style={{ opacity: 0.8 }}
    >
      <Typography style={{ opacity: 1 }} variant="caption">{currentChunk.positionName}</Typography>
    </Box>
  )
}

export default ViewFeedback;
