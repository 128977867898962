const darkTheme = {
  palette: {
    primary: {
      main: "#1976d2",
    },
    background: {
      default: "#30404D",
      paper: "#30404D"
    },
    action: {
      active: "#F1F3F4"
    },
    text: {
      primary: "#fafafa",
      secondary: "#fafafa"
    },
  }
}

export default darkTheme;