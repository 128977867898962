import { API } from 'aws-amplify';

async function deleteTimelapse(kvsName: string, timelapseId: string): Promise<void> {
  try {
    await API.del(
      'api-service',
      `/timelapse/${kvsName}/${timelapseId}`,
      {}
    )
  } catch (error) {
    throw error;
  }
}

export default deleteTimelapse;
