import moment from 'moment-timezone'

function formatToUTCString(timestamp: number, showHour: boolean = true, utcOffset: number = 0, timezone: string = ""): string {
  if (timezone) {
    const parsedTimezone = timezone.split(" ")[0]; 
    const format = showHour ? "MMM DD, YYYY - HH:mm:ss" : "MMM DD, YYYY";
    return moment.tz(timestamp, parsedTimezone).format(format);
  }
  const now = new Date(0);
  now.setUTCMilliseconds(timestamp)
  now.setUTCHours(now.getUTCHours() + utcOffset);
  return now.toUTCString().slice(0, showHour ? -4 : -12);
}

export default formatToUTCString;
