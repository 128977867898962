import Utils from "@utils/index";
import { API } from "aws-amplify";

async function getTimelapse(kvsName: string, timelapseId: string): Promise<Timelapse> {
  try {
    const path: string = `/timelapse/${kvsName}/${timelapseId}`;

    const response: APIResponse<Timelapse> = await API.get(
      'api-service',
      path,
      {}
    );

    return response.data as Timelapse;
  } catch (error) {
    console.log(Utils.Error.getErrorMessage(error));
    console.log(error);
    throw error;
  }
}

export default getTimelapse;
