import EventConverter from "@classes/StreamEvents/WSEventConverter";
import { API } from "aws-amplify";
import Utils from '@utils/index';
import EventsFilter from "@classes/StreamEvents/EventsFilter";
import { buildRequestBody } from "@API/global_event_inbox/listEvents";
import { sortBy } from "lodash";

export function convertResponse(stream: VideoStream, data: APIResponse<DynamoDBEvent[]>): APIResponse<VideoStreamEvent[]> {
  return {
    status: data.status,
    lastKey: data.lastKey,
    data: data.data ? sortBy(data.data.filter(el => (el.e !== undefined && el.s !== undefined) && el.s !== el.e).map(el => (
      new EventConverter(stream).convertFromDynamoDB(el)
    )), ['startDate']).reverse() : []
  }
}

async function fetchEvents(
  stream: VideoStream,
  startDate: Date,
  endDate: Date,
  filters?: EventsFilter,
  areaFilter?: { x: number, y: number, h: number, w: number }
): Promise<APIResponse<VideoStreamEvent[]>> {

  const retentionTimestamp = Utils.Stream.calculateRetentionTimestamp(stream, Date.now());
  let start = startDate;

  if (startDate.getTime() < retentionTimestamp && endDate.getTime() < retentionTimestamp) return { status: true, data: [] };

  if (start.getTime() < retentionTimestamp) start = new Date(retentionTimestamp);

  try {
    const path = `/events/${stream.kvsName}`;
    const body = buildRequestBody(stream.companyId, startDate.getTime(), endDate.getTime(), undefined, undefined, filters?.getCurrentParams(), undefined, areaFilter);
    delete body.companyId;
    const data: APIResponse<DynamoDBEvent[]> = await API.post('api-service', path, { body });

    let lastKey: number | { s: number, src: string } | { siteId: string, kvsName: string } | undefined = data.lastKey;

    if (data.data) {
      while (lastKey !== undefined) {
        const newPath = `/events/${stream.kvsName}`;
        const body = buildRequestBody(stream.companyId, startDate.getTime(), endDate.getTime(), undefined, undefined, filters?.getCurrentParams(), undefined, areaFilter)
        const newData: APIResponse<DynamoDBEvent[]> = await API.post('api-service', newPath, { body });

        if (newData.data) {
          data.data.push(...newData.data);
        }

        lastKey = newData.lastKey;
      }
    }

    return convertResponse(stream, data);
  } catch (e) {
    throw e;
  }
}

export default fetchEvents;
