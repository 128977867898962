import { Storage } from 'aws-amplify';
import Utils from '@utils/index';
import { S3ProviderListOutputItem, S3ProviderListOutput } from '@aws-amplify/storage';
import { store } from '../../index';

async function getMotionHeatmaps(companyId: string, siteId?: string, timezone?: string): Promise<Heatmap[]> {
  try {
    const s3Objects = await listS3Heatmaps(companyId, siteId);

    const streams = store.getState().streams.streams;

    return s3Objects.map((object) => {
      const timestamp = Utils.RegEx.findTimestamp(object.key || "");
      const kvsName: string = object.key?.split('/')[2] || "";
      return {
        url: object.key || "",
        timestamp,
        kvsName: kvsName,
        timestampLabel: Utils.Date.format(new Date(timestamp), 'MMM Do, YYYY h:00 A', timezone),
        streamName: streams.find(el => el.kvsName === kvsName)?.defaultName
      }
    })
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export async function listS3Heatmaps(companyId: string, siteId?: string): Promise<S3ProviderListOutputItem[]> {
  const path: string = siteId ? `${companyId}/${siteId}` : companyId;
  let nextToken: string | undefined = undefined;
  const d = [];
  do {
    const data: S3ProviderListOutput = await Storage.list(path, { pageSize: "ALL", nextToken })
    d.push(...data.results);
    nextToken = data.nextToken;
  } while (nextToken !== undefined)

  return d;
}

export default getMotionHeatmaps;
