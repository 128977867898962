function findTimestamp(url: string): number {
  const match: RegExpMatchArray | null = url.match(/[0-9]{13}/);
  if (match && match[0]) {
    return +match[0];
  }

  return 0;
}

export default findTimestamp;
