import { Auth } from 'aws-amplify';
import disableMFA from './disableMFA';

async function setPhone(phone: string): Promise<void> {
  try {
    await disableMFA();
    const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const response = await Auth.updateUserAttributes(currentUser, {
      'phone_number': phone
    });

    if (response !== "SUCCESS") throw new Error(response);
  } catch (error: any) {
    throw error;
  }
}

export default setPhone;
