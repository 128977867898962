import { ActionTypes, ICompaniesState } from './types';
import * as actions from './actions';
import Utils from '@utils/index';
import update from 'immutability-helper';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: ICompaniesState = {
  companies: [],
};

export const companiesReducer = Utils.Redux.createReducer<ICompaniesState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_COMPANIES]: (state, action) => ({
      ...state,
      companies: action.companies
    }),

    [ActionTypes.PUSH_COMPANY]: (state, action) => ({
      ...update(state, {
        companies: {
          $push: [action.company]
        }       
      })
    })
  }
);
