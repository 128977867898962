import { Duration } from 'moment';

export const getAllFilterTypes = (filterOptions: any[]) => {
  return {
    keywordFilters: filterOptions?.map((filter: any) => filter.keyword?.toLowerCase()).filter((f: any) => !!f),
    tradeFilter: filterOptions?.map((filter: any) => filter.trade).filter((f: any) => !!f),
    expirationFilter: filterOptions?.map((filter: any) => filter.certExpiration).filter((f: any) => !!f),
  }
}

export const matchesExpirationPeriod = (expirationFilter: any[], duration: Duration) => {
  return !expirationFilter.length || (expirationFilter.includes('>6 Months') && Math.floor(duration.asMonths()) >= 6) ||
    (expirationFilter.includes('<6 Months') && Math.floor(duration.asMonths()) < 6)
}

export const extractKeywords = (keywords: string) => {
  const keywordList = [];
  let currentKeyword = '';
  let inPhrase = false;

  for (let i = 0; i < keywords.length; i++) {
    const char = keywords[i];

    if (char === ' ' && !inPhrase) {
      if (currentKeyword.length > 0) {
        keywordList.push(currentKeyword);
      }
      currentKeyword = '';
    } else {
      if (char === "'" || char === '"') {
        inPhrase = !inPhrase;
      }
      currentKeyword += char;
    }
  }

  if (currentKeyword.length > 0) {
    keywordList.push(currentKeyword);
  }

  return keywordList;
}

export const matchesKeywordPeriod = (keywords: string[], object: any, props?: string[]): boolean => {
  let objectValue = '';
  let nonNegatedExists = false;
  let keywordFound = false;

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const value = object[key];

      if (value !== undefined) {
        if ((props && props.includes(key)) || (!props && (!key.toLocaleLowerCase().includes("id") && !key.includes("kvsName")))) {
          objectValue += "   " + String(value).toLowerCase();
        }
      }
    }
  }

  for (const keyword of keywords) {
    if (keyword.startsWith('-')) {
      const negatedKeyword = keyword.substring(1);
      if ((negatedKeyword.startsWith("'") && negatedKeyword.endsWith("'")) || (negatedKeyword.startsWith("\"") && negatedKeyword.endsWith("\""))) {
        // Negated phrase
        const phrase = negatedKeyword.substring(1, negatedKeyword.length - 1).toLowerCase();
        if (objectValue.includes(phrase)) {
          return false; // Negated phrase found in object, not a match
        }
      } else {
        // Negated keyword
        if (objectValue.includes(negatedKeyword.toLowerCase())) {
          return false; // Negated keyword found in object, not a match
        }
      }
    } else {
      nonNegatedExists = true;
      if ((keyword.startsWith("'") && keyword.endsWith("'")) || (keyword.startsWith("\"") && keyword.endsWith("\""))) {
        // Phrase
        const phrase = keyword.substring(1, keyword.length - 1).toLowerCase();
        if (!objectValue.includes(phrase)) {
          return false; // Phrase not found in object, not a match
        } else {
          keywordFound = true;
        }
      } else {
        // Keyword
        if (objectValue.includes(keyword.toLowerCase())) {
          keywordFound = true; // Keyword found in object, it's a match
        }
      }
    }
  }

  return !nonNegatedExists || keywordFound || false; // No match found for any keyword
};

export const matchesTradePeriod = (tradeFilter: any[], row: any) => {
  return !tradeFilter.length || tradeFilter.includes(row.trade)
}
