import { API } from "aws-amplify";

export async function getStreamPTZEvents(kvsName: string, start: number, end: number): Promise<Array<{src: string; eventTime: number; orientationId: string;}>> {
  try {
    const data = await API.get(
      'api-service',
      `/events/control/${kvsName}/${start}/${end}`,
      {}
    );

    return data.data;
  } catch (error) {
    throw error;
  }
}