import { Colors, Icons } from "@utils/Styles";

class EventType {
  // Used events
  static readonly MotionDetected = new EventType(
    "Motion",
    "Motion",
    Colors.EVENT_MOTION,
    Icons.ICON_EVENT_MOTION,
    "M"
  );
  static readonly GeofenceTriggered = new EventType(
    "Geofence triggered",
    "Zones",
    Colors.EVENT_GEOFENCE,
    Icons.ICON_GEOFENCE,
    "GT"
  );  
  static readonly SafetyInfraction = new EventType(
    "Safety infraction",
    "Safety infraction",
    Colors.EVENT_INFRACTION,
    Icons.ICON_EVENT_INFRACTION,
    "SI"
  );
  static readonly PersonDetected = new EventType(
    "Person detected",
    "Person detected",
    Colors.EVENT_DISTANCE,
    Icons.ICON_EVENT_DISTANCE,
    "PD"
  )
  static readonly VehicleDetected = new EventType(
    "Vehicle detected",
    "Vehicle detected",
    Colors.EVENT_LICENCE,
    Icons.ICON_EVENT_LICENCE,
    "VD"
  )

  // Legacy events
  static readonly TimelapseCreated = new EventType(
    "Timelapse created",
    "Timelapse created",
    Colors.EVENT_INFRACTION,
    Icons.ICON_EVENT_INFRACTION,
    "TC"
  );

  static readonly CameraOffline = new EventType(
    "Camera offline",
    "Camera offline",
    Colors.EVENT_INFRACTION,
    Icons.ICON_EVENT_INFRACTION,
    "CO"
  );
  static readonly SocialDistancing = new EventType(
    "Social distancing",
    "Social distance",
    Colors.EVENT_DISTANCE,
    Icons.ICON_EVENT_DISTANCE,
    "SD"
  )
  static readonly FireDetected = new EventType(
    "Fire detected",
    "Fire",
    Colors.EVENT_FIRE,
    Icons.ICON_EVENT_FIRE,
    "FD"
  )
  static readonly LicencePlateDetected = new EventType(
    "License plate detected",
    "Vehicle license plate",
    Colors.EVENT_LICENCE,
    Icons.ICON_EVENT_LICENCE,
    "LPD"
  )

  static readonly CertificationExpired = new EventType(
    "Worker certification expiring",
    "Worker certification expiring",
    Colors.EVENT_INFRACTION,
    Icons.ICON_EVENT_INFRACTION,
    "CE"
  )

  static readonly BlacklistedWorkerDetected = new EventType(
    "Blacklisted worker detected",
    "Blacklisted worker detected",
    Colors.EVENT_INFRACTION,
    Icons.ICON_EVENT_INFRACTION,
    "BWD"
  )
  //------ Static Methods ------//

  static get values(): EventType[] {
    return [
      this.MotionDetected,
      this.GeofenceTriggered,
      this.SafetyInfraction,
      this.PersonDetected,
      this.VehicleDetected,
      // legacy
      this.FireDetected,
      this.LicencePlateDetected,
      this.SocialDistancing,
      this.TimelapseCreated,
      // this.BlacklistedWorkerDetected
      /*this.CameraOffline*/
    ];
  }

  /**
   * Converts a string to its corresponding Enum instance.
   *
   * @param string the string to convert to Enum
   * @throws RangeError, if a string that has no corressonding Enum value was passed.
   * @returns the matching Enum
   */
  static fromString(string: string): EventType {
    // Works assuming the name property of the enum is identical to the variable's name.
    let value = (this as any)[string];
    if (value) {
      return value;
    } else {
      value = this.values.find(v => v.id === string || v.label === string);
      if (value) {
        return value;
      }
    }

    throw new RangeError(
      `Illegal argument passed to fromString(): ${string} does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
      }`
    );
  }

  /**
   * Converts short label to label
   *
   * @param shortLabel the string to convert to Enum label
   * @throws RangeError, if a string that has no corressonding Enum value was passed.
   * @returns the matching Enum
   */
  static getLabel(shortLabel: string): string {
    const label = this.values.find(v => v.shortLabel === shortLabel)?.label;
    if (label) {
      return label;
    }
    throw new RangeError(
      `Illegal argument passed to getLabel(): ${shortLabel} does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
      }`
    );
  }

  private constructor(
    public readonly id: string,
    public readonly label: string,
    public readonly color?: string,
    public readonly icon?: any,
    public readonly shortLabel?: string,
  ) { }
}

export default EventType;