import { API } from "aws-amplify";

async function deleteStrobe(streamId: string, deviceIdentifier: string, deviceType: string): Promise<void> {
  const path: string = `/devices/attached/${streamId}/${deviceIdentifier}/${deviceType}`;

  try {
    await API.del('api-service', path, {});
  } catch (error: any) {
    throw error;
  }
}

export default deleteStrobe;