import React from 'react';
import Utils from '@utils/index';
import { Box, Tooltip } from '@mui/material';

type StreamStatusProps = {
  stream?: VideoStream;
}

export const StreamStatus: React.FC<StreamStatusProps> = (props) => {
  const { stream } = props;

  const [status, setStatus] = React.useState<{ color: string, message: string }>({ color: "#bbbb00", message: 'Unknown' });

  React.useEffect(() => { setStatus(Utils.Stream.getDeviceStatus(stream)); }, [stream])

  return (
    <Tooltip placement="top" arrow title={status.message}>
      <Box
        width="0.8rem"
        height="0.8rem"
        borderRadius="50%"
        bgcolor={status.color}
      />
    </Tooltip>
  )
}