import { API } from 'aws-amplify';

async function getAttendance(
  startTime: Date,
  endTime: Date,
  siteId: string, 
  asCompany?: string
): Promise<{
  entries: (TimeEntry & { startTime: number, endTime: number })[];
  startTime: Date,
  endTime: Date,
  siteId: string,
}> {
  try {
    const path = `/workers/attendance/site/${siteId}?startTime=${startTime.getTime()}&endTime=${endTime.getTime()}` + (asCompany ? `&asCompany=${asCompany}` : "");

    const { data } = await API.get('api-service', path, {});

    return {
      entries: data,
      startTime,
      endTime,
      siteId
    }
  } catch (error: any) {
    throw error;
  }
}

export default getAttendance;
