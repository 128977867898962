import React from 'react';
import styled from 'styled-components';
import { Paper, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { CommandPanelTabs } from './CommandPanel';
import { useSearchParams } from 'react-router-dom';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';

const TabsContainer = styled(Paper)`
  width: 100%;

  // This layout is different from our default MUI styles.
  .MuiTab-root {
    min-height: 0 !important;
  }

  // This layout is different from our default MUI styles.
  .MuiTabs-root {
    min-height: 0 !important;
  }

  border-bottom: 1px solid rgba(0,0,0,0.12);
`

type TabSelectorProps = {
  selectedTab: CommandPanelTabs;
  onTabClick: (tab: CommandPanelTabs) => void;
  features?: StreamFeatures;
}

const TabSelector: React.FC<TabSelectorProps> = (props) => {
  const { selectedTab, onTabClick, features } = props;
  const [searchParams] = useSearchParams();

  const isMobile = useMobileQuery();
  const isSmallScreen = useSmallScreenQuery();

  const searchParamsRef = React.useRef(searchParams);
  const onTabClickRef = React.useRef(onTabClick);

  const shouldRenderEventsTab = React.useCallback((features?: StreamFeatures) => {
    const motion = Boolean(features?.events.motion.active && features?.events.motion.read);
    const zones = Boolean(features?.events.zones.active && features?.events.zones.read);
    const safetyInfraction = Boolean(features?.events.safetyInfraction.active && features?.events.safetyInfraction.read);
    const person = Boolean(features?.events.person.active && features?.events.person.read);
    const vehicle = Boolean(features?.events.vehicle.active && features?.events.vehicle.read);
    // legacy
    const socialDistance = Boolean(features?.events.socialDistance.active && features?.events.socialDistance.read);
    const fire = Boolean(features?.events.fire.active && features?.events.fire.read);
    const vehicleLicensePlate = Boolean(features?.events.vehicleLicensePlate.active && features?.events.vehicleLicensePlate.read);

    return fire || motion || socialDistance || zones || vehicleLicensePlate || safetyInfraction || vehicle || person;
  }, [])

  React.useEffect(() => { onTabClickRef.current = onTabClick }, [onTabClick])
  React.useEffect(() => { searchParamsRef.current = searchParams }, [searchParams])
  React.useEffect(() => {
    if (features) {
      const allowedTabs = [];
      const tab = searchParamsRef.current.get("tab") as CommandPanelTabs;
      if (shouldRenderEventsTab(features)) allowedTabs.push(CommandPanelTabs.Events);
      if (features?.zones.active && features?.zones.read) allowedTabs.push(CommandPanelTabs.Zones);
      if (features?.exports.active && features?.exports.write) allowedTabs.push(CommandPanelTabs.Exports);
      allowedTabs.push(CommandPanelTabs.None);
      if (tab && allowedTabs.includes(tab)) {
        onTabClickRef.current(tab);
      } else if (allowedTabs[0]) {
        onTabClickRef.current(allowedTabs[0]);
      }
    }
  }, [features, shouldRenderEventsTab]);

  if (features === undefined || !selectedTab) {
    return (
      <TabsContainer elevation={0} square>
        <Tabs style={{ minWidth: "26vw" }} variant="fullWidth">
          <Tab label={<Typography variant="button"><Skeleton style={{ borderRadius: 0 }} variant="text" width="3.5rem" /></Typography>} />
          <Tab label={<Typography variant="button"><Skeleton style={{ borderRadius: 0 }} variant="text" width="3.5rem" /></Typography>} />
          <Tab label={<Typography variant="button"><Skeleton style={{ borderRadius: 0 }} variant="text" width="3.5rem" /></Typography>} />
        </Tabs>
      </TabsContainer>
    )
  }

  return (
    <TabsContainer elevation={0} square>
      {selectedTab &&
        <Tabs
          value={selectedTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          style={{ minWidth: "26vw" }}
        >
          {shouldRenderEventsTab(features) &&
            <Tab
              onClick={() => { onTabClick(CommandPanelTabs.Events) }}
              value={CommandPanelTabs.Events}
              label={(
                <Typography id="events-description" variant="button">Events</Typography>
              )}
              data-cy="timeline-events-tab"
            />
          }
          {features?.zones.active && features?.zones.read && !isMobile && !isSmallScreen &&
            <Tab
              onClick={() => { onTabClick(CommandPanelTabs.Zones) }}
              value={CommandPanelTabs.Zones}
              label={(
                <Typography id="zones-description" variant="button">Zones</Typography>
              )}
              data-cy="timeline-zones-tab"
            />
          }
          {features?.exports.active && features?.exports.write &&
            <Tab
              onClick={() => { onTabClick(CommandPanelTabs.Exports) }}
              value={CommandPanelTabs.Exports}
              label={(
                <Typography id="save-video-description" variant="button">Save video</Typography>
              )}
              data-cy="timeline-save-video-tab"
            />
          }
        </Tabs>
      }
    </TabsContainer>
  )
}

export default TabSelector;
