import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, FormControlLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { IStoreState } from '@store/index';
import { postExportRequest, postTimelapseRequest, setTimelapses, setVideoClips } from '@store/exports/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerPopover from '../common/DatePickerPopover';
import Utils from '@utils/index';
import ExportVideo from './ExportVideo';
import Actions from './Actions';
import Title from './Title';
import DateSelect from './DateSelect';
import { ExpandMore, OpenInNew } from '@mui/icons-material';
import DateRangePickerPopover from '../common/DateRangePickerPopover';
import styled from 'styled-components';
import ExportTypeSelect from './ExportTypeSelect';
import { setTargetExport } from '@store/views/savedView/actions';
import API from '@API/index';
import { useSnackbar } from 'notistack';
import CameraPosition from './CameraPosition';
import { useNavigate } from 'react-router-dom';
import { setActivePage } from '@store/session/actions';
import { PageView } from '@store/session/types';
import moment from 'moment';
import { useMobileQuery } from '@hooks/useMobileQuery';
import ExportNotification from './ExportNotification';

export enum ChannelTypes {
  Email = "email",
  Sms = "sms",
  Web = "web"
}

const Container = styled.div`
  max-height: 90vh;
  overflow-y: auto;

  .MuiAccordion-root {
    box-shadow: none !important;

    ::before {
      height: 0 !important;
    }
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 2.5rem !important;
  }

  .MuiAccordionSummary-root {
    min-height: 2.5rem !important;
  }

  .MuiAccordion-root.Mui-disabled {
    background-color: transparent !important;
  }

  .MuiAccordionSummary-expandIcon {
    margin: -6px !important;
  }
`

export type ExportsTabProps = {
  selectedSite?: Site;
  stream?: VideoStream;
  type?: "video_clip" | "timelapse";
  startTimestamp?: number;
  endTimestamp?: number;
  disableMinimumVideoLength?: boolean;
}

const ExportsTab: React.FC<ExportsTabProps> = (props) => {
  const { selectedSite, stream, type, startTimestamp, endTimestamp, disableMinimumVideoLength } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const requests = useSelector((store: IStoreState) => store.exports.requests);
  const downloadRequests = useSelector((store: IStoreState) => store.exports.downloadRequests);
  const timelapseRequests = useSelector((store: IStoreState) => store.exports.timelapseRequests);
  const isMobile = useMobileQuery();

  const [timelapseName, setTimelapseName] = React.useState<string>("");
  const [timelapseDescription, setTimelapseDescription] = React.useState<string>("");
  const [videoName, setVideoName] = React.useState<string>("");
  const [videoDescription, setVideoDescription] = React.useState<string>("");
  const [exportType, setExportType] = React.useState<"video_clip" | "timelapse">(type || "video_clip");
  const [dateFormat, setDateFormat] = React.useState<string>(isMobile ? 'MMM Do, h:mm:ss A' : 'MMM Do, YYYY h:mm:ss A')
  const [tabRequest, setTabRequest] = React.useState<ExportRequest | undefined>(undefined);
  const [videoClipCalendarAnchorEl, setVideoClipCalendarAnchorEl] = React.useState<HTMLElement | null>(null);
  const [videoClipStart, setVideoClipStart] = React.useState<number>(startTimestamp ? startTimestamp : Date.now() - (15 * 60000));
  const [videoClipEnd, setVideoClipEnd] = React.useState<number>(endTimestamp ? endTimestamp : Date.now());
  const [videoClipDateText, setVideoClipDateText] = React.useState<string>(
    `${Utils.Date.format(new Date(videoClipStart), dateFormat, Utils.Site.getTimezone(selectedSite))} - ${Utils.Date.format(new Date(videoClipEnd), dateFormat, Utils.Site.getTimezone(selectedSite))}`
  );

  const [timelapseAnchorEl, setTimelapseAnchorEl] = React.useState<HTMLElement | null>(null);
  const [timelapseStart, setTimelapseStart] = React.useState<number>(Date.now() - (86400000 * 7));
  const [timelapseEnd, setTimelapseEnd] = React.useState<number>(Date.now());
  const [timelapseDateText, setTimelapseDateText] = React.useState<string>(
    `${Utils.Date.format(new Date(timelapseStart), "MMM Do, YYYY", Utils.Site.getTimezone(selectedSite))} - ${Utils.Date.format(new Date(timelapseEnd), "MMM Do, YYYY", Utils.Site.getTimezone(selectedSite))}`
  );
  const [timelapseSpeed, setTimelapseSpeed] = React.useState<"slow" | "medium" | "fast">("medium");
  const [timelapseCameraPosition, setTimelapseCameraPosition] = React.useState<number>(0);
  const [timelapseExcludeNights, setTimelapseExcludeNights] = React.useState<boolean>(false);
  const [timelapseExcludeWeekends, setTimelapseExcludeWeekends] = React.useState<boolean>(false);

  const [tabTimelapseRequest, setTabTimelapseRequest] = React.useState<TimelapseRequest | undefined>(undefined);

  const [loadingShowInSaved, setLoadingSHowInSaved] = React.useState<boolean>(false);

  const [checked, setChecked] = React.useState<boolean>(false);
  const [channel, setChannels] = React.useState<ChannelTypes[]>([ChannelTypes.Email]);

  const streamRef = React.useRef(stream);
  const selectedSiteRef = React.useRef(selectedSite);
  const timelapseExcludeWeekendsRef = React.useRef(timelapseExcludeWeekends);

  const changeChecked = React.useCallback((value: boolean) => {
    setChecked(value);
  }, [])

  const changeChannels = React.useCallback((value: ChannelTypes[]) => {
    setChannels(value)
  }, [])

  const isGenerateVideoDisabled = React.useMemo(() => {
    if (checked) {
      if (channel.length === 0) return true;
    }

    return false;
  }, [checked, channel])

  React.useEffect(() => {
    if (isMobile) setDateFormat('MMM Do, h:mm:ss A')
    else setDateFormat('MMM Do, YYYY h:mm:ss A')
  }, [isMobile])

  React.useEffect(() => { timelapseExcludeWeekendsRef.current = timelapseExcludeWeekends }, [timelapseExcludeWeekends])
  React.useEffect(() => { selectedSiteRef.current = selectedSite }, [selectedSite])
  React.useEffect(() => { streamRef.current = stream }, [stream])
  React.useEffect(updateTabRequest, [requests]);
  React.useEffect(updateTabTimelapseRequest, [timelapseRequests]);
  React.useEffect(setSelectText, [videoClipStart, videoClipEnd, dateFormat])
  React.useEffect(setTimelapseText, [timelapseStart, timelapseEnd]);
  React.useEffect(uncheckExcludeWeekendsIfNecessary, [timelapseStart, timelapseEnd]);

  function uncheckExcludeWeekendsIfNecessary(): void {
    if (timelapseExcludeWeekendsRef.current === true) {
      if (!Utils.Timestamp.containsWeekend(timelapseStart, timelapseEnd, selectedSiteRef.current?.timezone || '')) {
        setTimelapseExcludeWeekends(false);
      }
    }
  }

  function setSelectText(): void {
    setVideoClipDateText(`${Utils.Date.format(new Date(videoClipStart), dateFormat, Utils.Site.getTimezone(selectedSiteRef.current))} - ${Utils.Date.format(new Date(videoClipEnd), dateFormat, Utils.Site.getTimezone(selectedSiteRef.current))}`);
  }

  function setTimelapseText(): void {
    setTimelapseDateText(`${Utils.Date.format(new Date(timelapseStart), "MMM Do, YYYY", Utils.Site.getTimezone(selectedSiteRef.current))} - ${Utils.Date.format(new Date(timelapseEnd), "MMM Do, YYYY", Utils.Site.getTimezone(selectedSiteRef.current))}`)
  }

  function updateTabRequest(): void {
    setTabRequest(requests.find(el => el.kvsName === streamRef.current?.kvsName));
  }

  function updateTabTimelapseRequest(): void {
    setTabTimelapseRequest(timelapseRequests.find(el => el.kvsName === streamRef.current?.kvsName));
  }

  function generateRequest(): void {
    if (stream) {
      try {
        let options = undefined
        if (checked) {
          if (channel.length > 0) {
            options = {
              channels: channel.map((el) => el)
            }
          }
        }
        dispatch(postExportRequest(stream, { start: videoClipStart, end: videoClipEnd }, videoName, videoDescription, options))
      } catch (error) {
        console.log(error);
      }
    }
  }

  function download(): void {
    if (tabRequest) {
      try {
        var tempLink = document.createElement('a');
        tempLink.href = tabRequest.url;
        tempLink.setAttribute('download', `${tabRequest.requestId}.mp4`);
        tempLink.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  function downloadTimelapse(): void {
    if (tabTimelapseRequest) {
      try {
        var tempLink = document.createElement('a');
        tempLink.href = tabTimelapseRequest.url;
        tempLink.setAttribute('download', `${tabTimelapseRequest.requestId}.mp4`);
        tempLink.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  function handleDatePopoverConfirmation(start: Date, end: Date) {
    setVideoClipCalendarAnchorEl(null);
    setVideoClipStart(start.getTime());
    setVideoClipEnd(end.getTime());
  }

  async function handleManageExportsClick(): Promise<void> {
    try {
      setLoadingSHowInSaved(true);
      if (exportType === "timelapse" && tabTimelapseRequest) {
        dispatch(setTargetExport("timelapse", tabTimelapseRequest.exportId || '', tabTimelapseRequest.kvsName));
        const streamExports = await API.Timelapses.listSiteTimelapses(selectedSite?.siteId || "");
        dispatch(setTimelapses(selectedSite?.siteId || "", streamExports));
      }

      if (exportType === "video_clip" && tabRequest) {
        dispatch(setTargetExport("video_clip", tabRequest.exportId || '', tabRequest.kvsName));
        const streamExports = await API.Downloads.listSiteDownloads(selectedSite?.siteId || "");
        dispatch(setVideoClips(selectedSite?.siteId || "", streamExports));
      }

      setLoadingSHowInSaved(false);

      if (selectedSite) {
        navigate(`/exports?company=${selectedSite.companyId}&site=${selectedSite.siteId.split('-')[1]}`);
      } else {
        dispatch(setActivePage(PageView.Exports))
      }
    } catch (error) {
      enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' });
      setLoadingSHowInSaved(false);
    }
  }

  function generateTimelapse(): void {
    let framerate = 5;
    if (timelapseSpeed === "medium") framerate = 15;
    else if (timelapseSpeed === "fast") framerate = 30;
    let channels = undefined
    if (checked) {
      if (channel.length > 0) {
        channels = channel.map((el) => el.toString())
      }
    }

    dispatch(postTimelapseRequest({
      framerate: framerate,
      options: {
        excludeNights: timelapseExcludeNights,
        excludeWeekends: timelapseExcludeWeekends,
        channels
      },
      startTime: timelapseStart,
      endTime: timelapseEnd + 1,
      position: timelapseCameraPosition,
      streamName: stream?.defaultName || "",
      name: timelapseName,
      description: timelapseDescription
    }, stream?.kvsName || ""))
  }

  function getDayHint(timestamp: number) {
    try {
      return moment(timestamp).tz(Utils.Site.getTimezone(selectedSite) || "").format('MM/DD/YYYY')
    } catch (error) {
      return undefined;
    }
  }

  return (
    <Container style={{ height: isMobile ? '40vh' : undefined }} >
      <Box margin="1.5rem" display="grid" gap="0.5rem" gridTemplateRows="repeat(6, min-content)">
        <Title />
        {type === undefined &&
          <ExportTypeSelect
            onChange={setExportType}
          />
        }
        {exportType === 'video_clip' &&
          <>
            <DateSelect
              dateText={videoClipDateText}
              onSelectClick={(e) => { setVideoClipCalendarAnchorEl(e.currentTarget) }}
              type="video_clip"
            />
            <Accordion style={{ paddingLeft: '0.25rem', width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMore />} >More options</AccordionSummary>
              <AccordionDetails style={{ width: '100%' }}>
                <Box gap="0.5rem" display="grid" gridTemplateRows="min-content" width="100%">
                  <TextField onChange={(e) => setVideoName(e.currentTarget.value)} value={videoName} label="Video name (optional)" variant="outlined" placeholder="Optional" />
                  <TextField onChange={(e) => setVideoDescription(e.currentTarget.value)} value={videoDescription} label="Video description (optional)" variant="outlined" placeholder="Optional" />
                  <ExportNotification
                    onChannelsChange={changeChannels}
                    onNotifyChange={changeChecked}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Actions
              disableGenerate={Utils.Exports.inProgress(requests, tabRequest) === true || isGenerateVideoDisabled}
              onGenerateClick={generateRequest}
              type="video_clip"
            />
            <ExportVideo
              showVideo={Utils.Exports.isUrlSet(tabRequest) === true}
              loading={Utils.Exports.inProgress(requests, tabRequest) === true && Utils.Exports.isUrlSet(tabRequest) === false}
              url={tabRequest?.url}
              id={tabRequest?.exportId}
            />
            <Box paddingLeft="0.25rem">
              {Utils.Exports.isUrlSet(tabRequest) === true &&
                <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="1rem" width="100%" paddingTop="1rem">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={Utils.Exports.isUrlSet(tabRequest) === false || downloadRequests.find(el => el.exportRequest.kvsName === tabRequest?.kvsName && el.progress !== 100 && el.error === false) !== undefined}
                    onClick={download}
                    data-cy={`timeline-exports-tab-download-button`}
                  >Download</Button>
                  <Button
                    onClick={handleManageExportsClick}
                    variant="text" endIcon={loadingShowInSaved ? undefined : <OpenInNew />}
                    disabled={loadingShowInSaved}
                    data-cy="timeline-export-show-in-saved-button"
                  >
                    {loadingShowInSaved ? <CircularProgress color='primary' size="1.5rem" /> : 'Show in saved'}
                  </Button>
                </Box>
              }
            </Box>
          </>
        }
        {exportType === 'timelapse' &&
          <>
            <DateSelect
              dateText={timelapseDateText}
              onSelectClick={(e) => { setTimelapseAnchorEl(e.currentTarget) }}
              type="timelapse"
            />
            <Accordion style={{ paddingLeft: '0.25rem', width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMore />} >More options</AccordionSummary>
              <AccordionDetails style={{ width: '100%' }}>
                <Box gap="0.5rem" display="grid" gridTemplateRows="min-content min-content min-content min-content" width="100%">
                <TextField onChange={(e) => setTimelapseName(e.currentTarget.value)} value={timelapseName} label="Timelapse name (optional)" variant="outlined" placeholder="Optional" />
                <TextField onChange={(e) => setTimelapseDescription(e.currentTarget.value)} value={timelapseDescription} label="Timelapse description (optional)" variant="outlined" placeholder="Optional" />
                  <Box width="100%">
                    <Typography variant="subtitle2">{`Timelapse speed`}</Typography>
                    <Select IconComponent={ExpandMore} fullWidth variant="outlined" value={timelapseSpeed}>
                      <MenuItem data-cy={`timelapse-speed-slow-menu-item`} onClick={() => setTimelapseSpeed("slow")} value="slow">Slow (5 FPS)</MenuItem>
                      <MenuItem data-cy={`timelapse-speed-medium-menu-item`} onClick={() => setTimelapseSpeed("medium")} value="medium">Medium (15 FPS)</MenuItem>
                      <MenuItem data-cy={`timelapse-speed-fast-menu-item`} onClick={() => setTimelapseSpeed("fast")} value="fast">Fast (30 FPS)</MenuItem>
                    </Select>
                  </Box>
                  <CameraPosition
                    onCameraPositionChange={(value: number) => setTimelapseCameraPosition(value)}
                  />
                  <FormControlLabel
                    label="Exclude nights (7PM-5AM)"
                    control={
                      <Checkbox
                        color="primary"
                        checked={timelapseExcludeNights}
                        onChange={(_e, checked) => setTimelapseExcludeNights(checked)}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Exclude weekends (Sat, Sun)"
                    control={
                      <Checkbox
                        disabled={!Utils.Timestamp.containsWeekend(timelapseStart, timelapseEnd, selectedSite?.timezone || '')}
                        color="primary"
                        checked={timelapseExcludeWeekends}
                        onChange={(_e, checked) => setTimelapseExcludeWeekends(checked)}
                      />
                    }
                  />
                  <ExportNotification
                    onChannelsChange={changeChannels}
                    onNotifyChange={changeChecked}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Actions
              disableGenerate={Utils.Exports.inProgress(timelapseRequests, tabTimelapseRequest) === true || isGenerateVideoDisabled}
              onGenerateClick={generateTimelapse}
              type="timelapse"
            />
            <ExportVideo
              showVideo={Utils.Exports.isUrlSet(tabTimelapseRequest) === true}
              loading={Utils.Exports.inProgress(timelapseRequests, tabTimelapseRequest) === true && Utils.Exports.isUrlSet(tabTimelapseRequest) === false}
              url={tabTimelapseRequest?.url}
              id={tabTimelapseRequest?.exportId}
            />
            <Box paddingLeft="0.25rem">
              {Utils.Exports.isUrlSet(tabTimelapseRequest) === true &&
                <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="1rem" width="100%" paddingTop="1rem">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={Utils.Exports.isUrlSet(tabTimelapseRequest) === false || timelapseRequests.find(el => el.kvsName === tabTimelapseRequest?.kvsName && el.progress !== 100 && el.error === false) !== undefined}
                    onClick={downloadTimelapse}
                    data-cy="video-download-button" >Download</Button>
                  <Button
                    onClick={handleManageExportsClick}
                    variant="text" endIcon={loadingShowInSaved ? undefined : <OpenInNew />}
                    disabled={loadingShowInSaved}
                    data-cy="timeline-export-show-in-saved-2-button"
                  >
                    {loadingShowInSaved ? <CircularProgress color="primary" size="1.5rem" /> : 'Show in saved'}
                  </Button>
                </Box>
              }
            </Box>
          </>
        }
      </Box>
      <DatePickerPopover
        anchorEl={videoClipCalendarAnchorEl}
        open={Boolean(videoClipCalendarAnchorEl)}
        onClose={() => { setVideoClipCalendarAnchorEl(null) }}
        timezone={selectedSite?.timezone || ""}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onCancelClick={() => { setVideoClipCalendarAnchorEl(null); }}
        onConfirmClick={handleDatePopoverConfirmation}
        hintStart={Utils.Date.format(new Date(videoClipStart), "HH:mm:ss", Utils.Site.getTimezone(selectedSite))}
        hintEnd={Utils.Date.format(new Date(videoClipEnd), "HH:mm:ss", Utils.Site.getTimezone(selectedSite))}
        startDayHint={getDayHint(videoClipStart)}
        endDayHint={getDayHint(videoClipEnd)}
        disableFuture={true}
        customErrorCheck={(start: Date, end: Date) => {
          const offset = end.getTime() - start.getTime();
          if (offset > 60000 * 60) return "Maximum clip length allowed is an hour.";
          if (disableMinimumVideoLength !== true) {
            if (offset < 60000) {
              return "Minimum clip length allowed is 1 minute.";
            }
          }
        }}
      />
      <DateRangePickerPopover
        anchorEl={timelapseAnchorEl}
        open={Boolean(timelapseAnchorEl)}
        onClose={() => setTimelapseAnchorEl(null)}
        onBackdropClick={() => setTimelapseAnchorEl(null)}
        timezone={selectedSite?.timezone || ""}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        start={new Date(timelapseStart)}
        end={new Date(timelapseEnd)}
        onConfirmClick={(start, end) => {
          setTimelapseStart(start);
          setTimelapseEnd(end);
          setTimelapseAnchorEl(null);
        }}
        onCancelClick={() => setTimelapseAnchorEl(null)}
      />
    </Container>
  );
}

export default ExportsTab;
