import React from 'react';
import API from '@API/index';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { PermissionType } from '@store/session/types';

type StreamFeaturesProps = {
  onStreamFeaturesGenerated: (features: StreamFeatures) => void;
  stream: VideoStream;
  onError?: (error: any) => void;
}

const StreamFeaturesValidator: React.FC<StreamFeaturesProps> = (props) => {
  const { stream, onStreamFeaturesGenerated, onError } = props;

  const user = useSelector((store: IStoreState) => store.session.userData);
  const sessionPermissions = useSelector((store: IStoreState) => store.session.permissions);

  const currentStreamRef = React.useRef<VideoStream>(stream)
  const onStreamFeaturesGeneratedRef = React.useRef(onStreamFeaturesGenerated);
  const onErrorRef = React.useRef(onError);

  React.useEffect(() => { onStreamFeaturesGeneratedRef.current = onStreamFeaturesGenerated }, [onStreamFeaturesGenerated])
  React.useEffect(() => { onErrorRef.current = onError }, [onError]);

  const propagatePermissions = React.useCallback((permissions: PermissionType[]) => {
    const { features } = stream;
    const { zones, exports, timelapse, imageAnnotations, ptz, ppeDetection, socialDistancing, fireDetection, licensePlateDetection } = features;

    onStreamFeaturesGeneratedRef.current({
      stream: { view: havePermission(permissions, ["VIEW"]) },

      events: {
        motion: { active: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]), read: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]) },
        zones: { active: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]), read: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]) },
        safetyInfraction: { active: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]), read: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]) },
        person: { active: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]), read: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]) },
        vehicle: { active: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]), read: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]) },
        // legacy
        socialDistance: { active: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]), read: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]) },
        fire: { active: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]), read: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]) },
        vehicleLicensePlate: { active: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]), read: havePermission(permissions, ["EVENTS", "EVENTS_VIEW"]) }
      },
    
      zones: { active: zones, read: havePermission(permissions, ["GEOFENCES", "GEOFENCES_VIEW"]), write: havePermission(permissions, ["GEOFENCES"]) },
      exports: { active: exports, read: havePermission(permissions, ["VIDEO_DOWNLOADS_VIEW"]), write: havePermission(permissions, ["VIDEO_DOWNLOADS"]) },
      timelapse: { active: timelapse, read: havePermission(permissions, ["TIMELAPSES_VIEW"]), write: havePermission(permissions, ["TIMELAPSES"]) },
      imageAnnotations: { active: imageAnnotations, read: havePermission(permissions, ["ANNOTATED_IMAGES_VIEW"]), write: havePermission(permissions, ["ANNOTATED_IMAGES"]) },
      
      ptz: { active: ptz, read: havePermission(permissions, ["PTZ_CONTROL"]), write: havePermission(permissions, ["PTZ_CONTROL"])},
      
      ppeDetection: ppeDetection,

      socialDistancing: socialDistancing,
      fireDetection: fireDetection,
      licensePlateDetection: licensePlateDetection
    })
  }, [stream])

  const assertStreamFeatures = React.useCallback(async () => {
    const target = { ...currentStreamRef.current };
    try {
      const localPermissions = sessionPermissions[stream.kvsName];

      if (localPermissions) {
        propagatePermissions(localPermissions)
      }

      const { permissions } = await API.Permissions.listPermissionsForStream(user?.userId || "", stream.kvsName);

      if (currentStreamRef.current.kvsName === target.kvsName) propagatePermissions(permissions);
    } catch (error) {
      if (onErrorRef.current && currentStreamRef.current.kvsName === target.kvsName) onErrorRef.current(error);
    }
  }, [sessionPermissions, stream.kvsName, user?.userId, propagatePermissions])

  React.useEffect(() => { currentStreamRef.current = stream }, [stream])
  React.useEffect(startRoutine, [stream, assertStreamFeatures]);

  function startRoutine(): void { assertStreamFeatures(); }

  function havePermission(permissions: PermissionType[], lookFor: PermissionType[]): boolean {
    return permissions.some(el => [...lookFor, "ALL"].indexOf(el) >= 0);
  }

  return null;
}

export default StreamFeaturesValidator;
