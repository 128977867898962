import { Box, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import { Launch } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

const Container = styled(Box) <{ highlight: boolean }>`
  box-shadow: ${props => props.highlight ? `rgb(57 113 235) 0 0 0pt 2pt;` : undefined};
  :hover {
    box-shadow: rgb(57 113 235) 0 0 0pt 2pt;
    cursor: pointer;
  }

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
`

type PositionItemProps = {
  position: StreamPosition;
  setSelectedPosition: React.Dispatch<React.SetStateAction<number>>;
  selectedPosition: number;
  onImageDown: (event: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => void;
}

const PositionItem: React.FC<PositionItemProps> = (props) => {
  const { position, setSelectedPosition, selectedPosition, onImageDown } = props;

  const [imageDataLoaded, setImageDataLoaded] = React.useState<boolean>(false);

  return (
    <Container
      onClick={() => { setSelectedPosition(position.index) }}
      onMouseDown={(e) => { onImageDown(e, position.index) }}
      highlight={selectedPosition === position.index}
      position="relative"
      flexDirection="column"
      border="1px solid rgba(0, 0, 0, 0.12)"
      borderRadius="1rem"
    >
      {!imageDataLoaded &&
        <Box
          width="100%"
          height="unset"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.11)', aspectRatio: '16/9', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography variant='caption' style={{ color: 'black', visibility: 'visible' }}>Loading position snapshot...</Typography>
          <LinearProgress style={{ width: '50%', marginTop: 8 }} />
        </Box>
      }
      <img
        alt=""
        style={{ aspectRatio: '16/9', width: '100%', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', display: imageDataLoaded ? 'flex' : 'none' }}
        src={position.snapshotUrl}
        onLoad={() => setImageDataLoaded(true)}
      />
      <Box
        width="100%"
        paddingTop="4px"
        paddingBottom="4px"
        paddingRight="8px"
        paddingLeft="8px"
        display="grid"
        gridTemplateColumns="auto min-content"
        gap="1rem"
        alignItems="center"
      >
        <Typography variant="body2" noWrap={true}>
          {`Position ${position.index + 1}`}
        </Typography>
        <Tooltip title="Open image on a new tab">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(position.snapshotUrl, "_blank");
            }}
            size="small"
          >
            <Launch />
          </IconButton>
        </Tooltip>
      </Box>
    </Container>
  );
}

export default PositionItem;
