import { Box, MenuItem, Select, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React from 'react';

type DateSelectProps = {
  dateText: string;
  onSelectClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  type: "video_clip" | "timelapse";
}

const DateSelect: React.FC<DateSelectProps> = (props) => {
  const { dateText, onSelectClick, type } = props;

  function getTitle(): string {
    if (type === "timelapse") return "Date range";
    return "Date and time range";
  }

  return (
    <Box id="timeline-save-video-date-selector-container" width="100%">
      <Typography variant="subtitle2">{getTitle()}</Typography>
      <Select IconComponent={ExpandMore} fullWidth onClick={onSelectClick} open={false} variant="outlined" value="default" data-cy="date-range-select">
        <MenuItem value="default" data-cy={`date-select-menu-item`} >{dateText}</MenuItem>
      </Select>
    </Box>
  )
}

export default DateSelect;
