import { API } from "aws-amplify";

export async function accept(version: string): Promise<boolean> {
  try {
    const path: string = "/terms/accept";
    const options = {
      body: {
        version
      }
    }

    const data = await API.post('api-service', path, options);
    return data.status;
  } catch (error) {
    console.log(error);
    return false;
  }
}