import { setActivePage } from '@store/session/actions';
import { PageView } from '@store/session/types';
import React from 'react';
import { useDispatch } from 'react-redux';

/**
 * Sets the active page to the redux store when Mounting the component.
 * 
 * This effect will set the active page to the Redux Store upon mounting the component.
 * 
 * @param {PageView} pageView PageView to set active in Redux.
 */
function useSetActivePage(pageView: PageView) {
  const dispatch = useDispatch();

  const view = React.useMemo(() => {
    return pageView;
  }, [pageView])

  const action = React.useCallback(() => {
    dispatch(setActivePage(view));
  }, [dispatch, view])

  React.useEffect(action, [action])
}

export default useSetActivePage;
