import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_ASSETS: null
});

export interface IAssetsState {
  assets: IEdgeDevice[]
}


export interface IEdgeDevice {
  companyId: string,
  siteId: string,
  iotThingName: string,
  mac: string
}
