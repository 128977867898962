import {
  ActionTypes, IHeatmapsState
} from './types';
import * as actions from './actions';
import Utils from '@utils/index';
import { findIndex } from 'lodash';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: IHeatmapsState = {
  heatmaps: []
}

export const heatmapsReducer = Utils.Redux.createReducer<
  IHeatmapsState,
  Types,
  Actions
>(initialState, {

  [ActionTypes.ADD_HEATMAPS]: (state, action) => ({
    ...state,
    heatmaps: mergeHeatmaps(state.heatmaps, action.value)
  }),
});

function mergeHeatmaps(state: Heatmap[], newValues: Heatmap[]): Heatmap[] {
  const newState = [...state];
  newValues.forEach(el => {
    const index = findIndex(newState, { kvsName: el.kvsName, timestamp: el.timestamp });
    if (index === -1) {
      newState.push(el);
    }
  })

  return newState;
}
