function getTimeAgo(length: number): string {
  const seconds = Number((length / 1000).toFixed(0));

  if (seconds < 0) return "Now"

  if (seconds < 60) return `${seconds}s`;

  const minutes = Number((length / 60000).toFixed(0));
  if (minutes < 60) return `${minutes}min`;

  const hours = Number((length / 3600000).toFixed(0));
  if (hours < 24) return `${hours}h`

  const days = Number((hours / 24).toFixed(0));
  if (days < 7) return `${days}d`;

  const weeks = Number((days / 7).toFixed(0));
  if (weeks < 4) return `${weeks}w`;

  const months = Number((weeks / 4).toFixed(0));
  return `${months}mo`;
}

export default getTimeAgo;
