import { isFulfilled } from "./isFulfilled";
import { isRejected } from "./isRejected";

export interface IPromiseUtils {
  isFulfilled: <T>(p: PromiseSettledResult<T>) => p is PromiseFulfilledResult<T>;
  isRejected: <T>(p: PromiseSettledResult<T>) => p is PromiseRejectedResult;
}

export const PromiseUtils: IPromiseUtils = {
  isRejected,
  isFulfilled,
};

export default PromiseUtils;