import moment from 'moment-timezone';

function stringToDate(time: string, timezone?: string, day?: number, month?: number, year?: number): Date {
  const newDate = new Date();

  if (timezone) {
    const pieces = time.split(':');
    const ms = Date.UTC(year || newDate.getFullYear(), month !== undefined ? month : newDate.getMonth(), day || newDate.getDate(), +pieces[0], +pieces[1], +pieces[2] ? +pieces[2] : 0, 0);
    const a = moment.tz([year || newDate.getFullYear(), month !== undefined ? month : newDate.getMonth(), day || newDate.getDate()], timezone.split(' ')[0])
    let offset = a.utcOffset() * 60000;
    return new Date(ms - offset);
  }

  const pieces = time.split(':');
  newDate.setHours(+pieces[0]);
  newDate.setMinutes(+pieces[1]);
  if (+pieces[2]) newDate.setSeconds(+pieces[2]);

  return newDate;
}

export default stringToDate;
