import { isEmpty, isUndefined } from "lodash";
import { Field } from "./Field";

export class StringField<T> extends Field<T, string> {
  public override filter(data: Array<T>, target?: string): Array<T> {
    return data.filter((el: any) => {
      const value = this._path?.reduce((a, b) => a ? a[b] : undefined, el)
      if (this._currentOperator === "is_empty") {
        if (!value) return true;
        if (value && value === '') return true;
        return false;
      }

      if (this._currentOperator === "is_not_empty") {
        return value && !isEmpty(value);
      }

      if (!isEmpty(target) && (isUndefined(value) || isEmpty(value))) return false;

      if (value) {
        const targetProperty = value as string;

        if (this._currentOperator === "starts_with") return isEmpty(target) || (target && targetProperty.toLowerCase().startsWith(target.toLowerCase()))
        if (this._currentOperator === "contains") return isEmpty(target) || (target && targetProperty.toLowerCase().includes(target.toLowerCase()));
        if (this._currentOperator === "ends_with") return isEmpty(target) || (target && targetProperty.toLowerCase().endsWith(target.toLowerCase()));
        if (this._currentOperator === "equals") return isEmpty(target) || (target && targetProperty.toLowerCase() === target.toLowerCase());
      }

      return true;
    })
  }
}