import { API } from "aws-amplify";

async function deleteCertificate(companyId: string, CertificateId: string): Promise<void> {
  try {
    const path: string = `/sites/${companyId}/${CertificateId}`;

    await API.del(
      'api-service',
      path,
      {}
    );

  } catch (error: any) {
    throw error;
  }
}

export default deleteCertificate;
