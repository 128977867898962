import { Tooltip } from '@mui/material';
import { Search } from '@mui/icons-material';
import React from 'react';

const SearchHeaderTooltipIcon: React.FC = () => {
  return <Tooltip title='Use quotes for exact phrase match (e.g. "Camera 1") or dash to exclude word (e.g. -camera)'>
    <Search />
  </Tooltip>
};

export default SearchHeaderTooltipIcon;
