import { API } from "aws-amplify";

async function createStream(stream: VideoStreamBase): Promise<VideoStream> {
  try {
    const path: string = '/streams';
    const tempStream: any = {
      ...stream
    }
    delete tempStream.kvsName
    const options = {
      body: tempStream
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    return data.data as VideoStream;

  } catch (error: any) {
    throw new Error(error.response?.data.error || error.message);
  }
}

export default createStream;
