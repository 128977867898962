import PubSub from "./PubSub";
import API from '@API/index';

class PubSubStrobes extends PubSub {
  protected _endpoint: string;

  constructor(endpoint: string, onMessage: Function, onError?: Function, onComplete?: Function) {
    super(onMessage, onError, onComplete);
    this._endpoint = endpoint;
  }

  public connect(): void {
    this.subscription = API.PubSub.subscribeToTopic(this._endpoint, this.onMessage, this.onError, this.onComplete);

    this.interval = setInterval(() => { this.checkSubscriptionHealth() }, 15000);
  }
}

export default PubSubStrobes;
