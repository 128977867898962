import React from 'react';
import SiteMap from "./SiteMap";

type MapBoxProps = {
  streams: VideoStream[];
  height?: string | number;
  multiplesites?: {
    sites: Site[];
  };
  footageInfo?: {
    timestamp: number,
    live: boolean
  }
}

const MapBox: React.FC<MapBoxProps> = (props) => {
  const { streams, height, multiplesites, footageInfo } = props;

  const filteredStreams = React.useMemo(() => {
    const filtered = streams.filter(el => {
      return el.latitude && el.latitude !== 0
      && el.longitude && el.longitude !== 0
    });

    return filtered;
  }, [streams])

  return (
    <div>
      <SiteMap
        streams={filteredStreams}
        height={height}
        multiplesites={multiplesites}
        footageInfo={footageInfo}
      />
    </div>
  );
}



export default MapBox;