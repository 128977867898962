import React from 'react';
import styled from 'styled-components';
import { Box, IconButton, InputAdornment, MenuItem, Radio, Select, TextField, Tooltip, Typography } from '@mui/material';
import { ArrowBack, Done, Info, KeyboardArrowDown, MoreHoriz } from '@mui/icons-material';
import { ZoneTabProps } from './ZonesTab';
import ScheduleInput from '@components/common/Schedule/ScheduleInput';
import TextArea from '@components/common/TextArea';
import { DayLabel } from '@declarations/enums';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import TargetDateInput from '@components/common/Input/TargetDateInput';
import TextInlineSelect from '@components/common/Dropdown/TextInlineSelect';
import { isArray } from 'lodash';

const ZONE_COLORS = ["#BF260E", "#FF6D1D", "#FFA720", "#093F27", "#0048E3", "#4720F4"]

const RoughBackground = styled(Box)`
  background-color: #ffffff;
  opacity: 1;
  background: repeating-linear-gradient( -35deg, #000000, #000000 1px, #ffffff 1px, #ffffff 1.5px );
`
type ZoneEditProps = ZoneTabProps & {
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const ZoneEdit: React.FC<ZoneEditProps> = (props) => {
  const {
    zones,
    onSelectedZoneChange,
    focusedZone,
    setAnchorEl,
    timezone,
    siteId
  } = props;

  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);

  const [timeType, setTimeType] = React.useState<"schedule" | "active_until">(getInitialType());
  const [error, setError] = React.useState<string>()

  function changeFocusedZoneColor(color: string) {
    //@ts-ignore
    if (focusedZone && onSelectedZoneChange) onSelectedZoneChange({ ...focusedZone, color: color });
  }

  function changeFocusedZoneTexture(texture: "smooth" | "rough") {
    if (focusedZone && onSelectedZoneChange) onSelectedZoneChange({ ...focusedZone, texture: texture });
  }

  function handleActiveDayTimeChange(value: Record<DayLabel, Array<ActiveDayTime>>): void {
    if (focusedZone && onSelectedZoneChange)
      onSelectedZoneChange({ ...focusedZone, schedule: value, activeUntil: undefined });
  }

  function handleActiveUntilChange(value: number): void {
    if (focusedZone && onSelectedZoneChange)
      onSelectedZoneChange({ ...focusedZone, activeUntil: value })
  }

  function getInitialType(): "schedule" | "active_until" {
    if (focusedZone) {
      if (focusedZone.activeUntil) {
        return "active_until";
      }
    }

    return "schedule";
  }

  function handleMinimumObjectSizeChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    if (focusedZone && onSelectedZoneChange) {
      if (+e.currentTarget.value <= 0.03) {
        onSelectedZoneChange({
          ...focusedZone,
          minimumObjectSize: 0.03
        });
        return;
      }

      if (+e.currentTarget.value > 30) {
        onSelectedZoneChange({
          ...focusedZone,
          minimumObjectSize: 30
        });
        return;
      }

      onSelectedZoneChange({
        ...focusedZone,
        minimumObjectSize: +e.currentTarget.value
      })
    }
  }

  if (focusedZone) {
    return (
      <Box padding="1rem" width="100%" display="grid" gridTemplateRows="repeat(6, min-content)" gap="1rem">
        <Box alignItems="center" display="grid" width="100%" gap="0.5rem" gridTemplateColumns="min-content auto min-content">
          <Box height="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <IconButton onClick={() => { if (onSelectedZoneChange) onSelectedZoneChange(undefined); }} size="small" data-cy="zone-edit-back-button">
              <ArrowBack />
            </IconButton>
          </Box>
          <TextField
            variant="outlined"
            onChange={(e) => {
              if (zones.some(z => z.name === e.currentTarget.value)) {
                setError("Zone with same name already exists on this stream.")
              } else if (error) {
                setError(undefined)
              }
              if (onSelectedZoneChange) onSelectedZoneChange({ ...focusedZone, name: e.currentTarget.value })
            }}
            value={focusedZone.name}
            error={Boolean(error)}
            helperText={error}
            style={{ marginTop: 0 }}
            data-cy={"zone-edit-name-input"}
          />
          {zones.find(el => el.id === focusedZone.id) &&
            <Box height="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <IconButton
                onClick={(e: any) => setAnchorEl(e.currentTarget)}
                size="small"
                data-cy={`zone-more-icon-button`}
              >
                <MoreHoriz />
              </IconButton>
            </Box>
          }
        </Box>
        <Box height="2rem" display="grid" width="100%" gridTemplateColumns="8.5rem min-content" alignItems="center">
          <Typography variant="subtitle2">Type</Typography>
          <Select
            IconComponent={KeyboardArrowDown}
            value={focusedZone?.type || "inner"}
            disableUnderline={true}
            fullWidth={true}
            variant="standard"
            onChange={(event: any) => {
              if (onSelectedZoneChange) onSelectedZoneChange({
                ...focusedZone,
                type: event.target.value,
                purpose: "security",
                color: event.target.value === "ignore" ? "" : focusedZone.color !== "" ? focusedZone.color : "#0048E3",
                texture: event.target.value === "ignore" ? "" : focusedZone.texture !== "" ? focusedZone.texture : "smooth",
                minimumObjectSize: event.target.value === "ignore" ? 0 : focusedZone.minimumObjectSize ? focusedZone.minimumObjectSize : 0.03
              })
            }}
          >
            <MenuItem value={"inner"}><Typography variant="button" color="textPrimary" >Enter</Typography></MenuItem>
            <MenuItem value={"outer"} ><Typography variant="button" color="textPrimary" >Exit</Typography></MenuItem>
            <MenuItem value={"ignore"} ><Typography variant="button" color="textPrimary" >Exclude</Typography></MenuItem>
          </Select>
        </Box>
        <Box height="2rem" display="grid" width="100%" gridTemplateColumns="8.5rem min-content min-content">
          <Box display="grid" gridTemplateColumns="min-content max-content" alignItems="center" gap="0.2rem">
            <Radio
              style={{ padding: 0, marginBottom: 2 }}
              checked={timeType === "schedule"}
              data-cy="zone-edit-schedule-radio-button"
              onClick={() => {
                if (focusedZone && onSelectedZoneChange) onSelectedZoneChange({ ...focusedZone, activeUntil: undefined });
                setTimeType("schedule");
              }}
              size="small"
            />
            <Typography style={{ opacity: timeType === "schedule" ? 1 : 0.5 }} variant="subtitle2">Schedule</Typography>
          </Box>
          <ScheduleInput
            anchorOrigin={{
              vertical: 68,
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            value={focusedZone.schedule || {}}
            onChange={handleActiveDayTimeChange}
            disabled={timeType !== "schedule"}
            maxWidth="10vw"
            siteId={siteId}
          />
        </Box>
        <Box height="2rem" display="grid" width="100%" gridTemplateColumns="8.5rem min-content min-content">
          <Box display="grid" gridTemplateColumns="min-content max-content" alignItems="center" gap="0.2rem">
            <Radio
              style={{ padding: 0, marginBottom: 2 }}
              checked={timeType === "active_until"}
              data-cy="zone-edit-active-until-radio-button"
              onClick={() => setTimeType("active_until")}
              size="small"
            />
            <Typography style={{ opacity: timeType === "active_until" ? 1 : 0.5 }} variant="subtitle2">Active until</Typography>
          </Box>
          <TargetDateInput
            timezone={selectedSite?.timezone?.split(' ')[0] || timezone || ""}
            disabled={timeType !== "active_until"}
            onChange={handleActiveUntilChange}
            value={focusedZone.activeUntil}
            showTimeEstimate={true}
            endTimeText={'Active until'}
          />
        </Box>
        <Box height="2rem" display="grid" width="100%" gridTemplateColumns="8.5rem auto" alignItems="center">
          <Typography variant="subtitle2">Color</Typography>
          <Box display="grid" gridTemplateColumns={`repeat(${ZONE_COLORS.length}, min-content)`}>
            {ZONE_COLORS.map(color => (
              <IconButton
                disabled={focusedZone.type === "ignore"}
                key={ZONE_COLORS.indexOf(color)}
                onClick={() => changeFocusedZoneColor(color)}
                size="small"
                data-cy={`zone-color-${color}-icon-button`}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  height="1.5rem"
                  width="1.5rem"
                  borderRadius="50%"
                  bgcolor={color}
                  style={{ opacity: focusedZone.type === "ignore" ? 0.5 : 1 }}
                >
                  {focusedZone.color === color &&
                    <Done fontSize="small" htmlColor="white" />
                  }
                </Box>
              </IconButton>
            ))}
          </Box>
        </Box>
        <Box height="2rem" display="grid" width="100%" gridTemplateColumns="8.5rem auto" alignItems="center">
          <Typography variant="subtitle2">Texture</Typography>
          <Box display="grid" gridTemplateColumns="repeat(2, min-content)">
            <IconButton disabled={focusedZone.type === "ignore"} onClick={() => changeFocusedZoneTexture("smooth")} size="small">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                height="1.5rem"
                width="1.5rem"
                borderRadius="50%"
                border="1px solid black"
                style={{ opacity: focusedZone.type === "ignore" ? 0.5 : 1 }}
              >
                {focusedZone.texture === "smooth" &&
                  <Done fontSize="small" />
                }
              </Box>
            </IconButton>
            <IconButton disabled={focusedZone.type === "ignore"} onClick={() => changeFocusedZoneTexture("rough")} size="small">
              <RoughBackground
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                height="1.5rem"
                width="1.5rem"
                borderRadius="50%"
                border="1px solid black"
                style={{ opacity: focusedZone.type === "ignore" ? 0.5 : 1 }}
              >
                {focusedZone.texture === "rough" &&
                  <Done htmlColor="white" fontSize="small" />
                }
              </RoughBackground>
            </IconButton>
          </Box>
        </Box>
        <Box height="2rem" display="grid" width="100%" gridTemplateColumns="8.5rem min-content" alignItems="center">
          <Box>
            <Typography variant="subtitle2">Objects of interest</Typography>
          </Box>
          <TextInlineSelect
            options={[{ label: 'Motion', value: 'motion' }, { label: 'Person', value: 'person' }, { label: 'Vehicle', value: 'vehicle' }]}
            value={focusedZone.objectsOfInterest || []}
            multiple
            optionDisplayProp="label"
            optionValueProp="value"
            placeholder="Select objects of interest"
            onChange={(value: string | any[] | undefined) => {
              if (onSelectedZoneChange) onSelectedZoneChange({
                ...focusedZone,
                objectsOfInterest: isArray(value) ? value : [value]
              })
            }}
          />
        </Box>
        <Box height="3rem" display="grid" width="100%" gridTemplateColumns="8.5rem min-content" alignItems="center">
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">Ignore objects</Typography>
            <Box display="flex" flexDirection="row">
              <Typography variant="subtitle2">smaller than</Typography>
              <Box height="100%" display="flex">
                <Tooltip title="This option defines the minimum object size that can trigger a zone in relation to the total frame area. The red colored square shown at the center of the drawn zone depicts the minimum object area that will trigger the zone."><Info style={{ height: '1.1rem', width: '1.1rem' }} fontSize="small" /></Tooltip>
              </Box>
            </Box>
          </Box>
          <TextField
            disabled={focusedZone.type === "ignore"}
            value={focusedZone.minimumObjectSize}
            size="small"
            style={{ minWidth: '7rem', marginTop: 0 }}
            inputProps={{
              type: "number",
              min: 0,
              max: 30,
              step: 0.01
            }}
            onChange={handleMinimumObjectSizeChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </Box>
        <TextArea
          title="Description"
          data-cy="zone-description-text-area"
          onChange={(val) => { if (onSelectedZoneChange) onSelectedZoneChange({ ...focusedZone, description: val.currentTarget.value }) }}
          value={focusedZone?.description}
          textAreaProps={{ style: { fontSize: "0.875rem" } }}
        />
      </Box>
    );
  }

  return null;
}

export default ZoneEdit;
