import EventTagLabel from "@components/overview/EventTagLabel";
import { SellOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import EventChange from "./EventChange";

class TagRemoved extends EventChange {
  public getIconElement(): JSX.Element {
    return <SellOutlined fontSize="small" />;
  }

  public getTextElement(): JSX.Element {
    if (this._author?.isCurrentUser) {
      return (
        <Box display="flex">
          <Box display="flex">
            <EventTagLabel noMarginLeft event={{ tags: [this._value as EventTag] }} selected={false} />
          </Box>
          <Box display="flex">
            <Typography variant="body2">
              <b>removed&nbsp;</b>{`by you`}
            </Typography>
          </Box>
        </Box>
      )
    }

    return (
      <Box display="flex">
        <Box display="flex">
          <EventTagLabel noMarginLeft event={{ tags: [this._value as EventTag] }} selected={false} />
        </Box>
        <Box display="flex">
          <Typography variant="body2">
            <b>removed&nbsp;</b>{`by ${this._author?.name || "Unknown"}`}
          </Typography>
        </Box>
      </Box>
    )
  }
}

export default TagRemoved;
