import { Box, CircularProgress, Fade } from '@mui/material';
import React from 'react';

/**
 * Full width/height loading feedback.
 */
const Loading: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <Fade in={true}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={style}
      >
        <CircularProgress />
      </Box>
    </Fade>
  )
}

export default Loading;
