import { createSelector } from "reselect";
import { IStoreState } from ".";
import { UserRole } from "./users/types";

export const selectIsRestrictedUser = createSelector(
    [(state: IStoreState) => state.session.userData?.roles],
    (roles) => {
        if (roles) {
            if (typeof roles === "string") {
                return roles === UserRole.Restricted
            } else {
                return false;
                // return Boolean(roles[UserRole.Readonly]) || Boolean(roles[UserRole.SuperAdmin])
            }
        }
        return false;
    }
)


export const selectIsSuperAdminUser = createSelector(
    [(state: IStoreState) => state.session.userData?.roles],
    (roles) => {
        if (roles) {
            if (typeof roles === "string") {
                return roles === UserRole.SuperAdmin
            } else {
                return Boolean(roles[UserRole.Readonly]) || Boolean(roles[UserRole.SuperAdmin])
            }
        }
        return false;
    }
)

export const selectIsTemporarySuperAdminUser = createSelector(
    [(state: IStoreState) => state.session.userData?.roles],
    (roles) => {
        if (roles) {
            if (typeof roles !== "string") {
                return Boolean(roles[UserRole.Readonly]) || Boolean(roles[UserRole.SuperAdmin])
            }
        }
        return false;
    }
)