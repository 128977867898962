import getWorkersTimeLabelTooltip from "./getWorkersTimeLabelTooltip";

export interface IWorkers {
  getWorkersTimeLabelTooltip: (object: {
    workers?: number;
    hours?: number;
    minutes?: number;
  }) => string;
}

const Workers: IWorkers = {
  getWorkersTimeLabelTooltip,
}

export default Workers;

