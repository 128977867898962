import API from '@API/index';

class UserPictureManager {
  private static _instance: UserPictureManager;
  private _thumbnails: Map<string, { expires: number, url: string }> = new Map();

  public static get instance() {
    if (!UserPictureManager._instance) {
      UserPictureManager._instance = new UserPictureManager();
    }

    return this._instance
  }

  private constructor() { }

  public async getUserPicture(user: User) {
    const target = this._thumbnails.get(user.userId);

    if (!target || target.expires < Date.now()) {

      if (!user.pictureURL || user.pictureURL?.includes('default')) {
        this._thumbnails.set(user.userId, { expires: Date.now() + (86400 * 1000), url: "" })
        return "";
      }

      const data = await API.Users.getUserPic(user.userId);
      const userPictureExists = await this.pictureExists(data.url);
      if (userPictureExists) {
        this._thumbnails.set(user.userId, { expires: Date.now() + (86400 * 1000), url: data.url })
      } else {
        this._thumbnails.set(user.userId, { expires: Date.now() + (86400 * 1000), url: "" })
      }

      return data.url;
    }

    return target.url;
  }

  private async pictureExists(url: string): Promise<boolean> {
    const res = await window.fetch(url);
    return res.ok;
  }
}

export default UserPictureManager;
