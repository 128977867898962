import { API } from "aws-amplify";

async function reinviteUsers(userIds: string[]): Promise<void> {
  try {
    const path: string = `/invitation/resend`;

    await API.post(
      'api-service',
      path,
      {
        body: {
          userIds
        }
      }
    );

  } catch (error: any) {
    throw new Error(error.response?.data.error || error.message);
  }
}

export default reinviteUsers;
