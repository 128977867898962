import EventChange from '@classes/GEI/EventChange';
import { Box } from '@mui/material';
import React from 'react';

type EventContentHistoryItemProps = {
  history: EventChange;
}

const EventContentHistoryItem: React.FC<EventContentHistoryItemProps> = (props) => {
  const { history } = props;

  return (
    <Box display="grid" gap="0.5rem" gridTemplateColumns="min-content max-content" alignItems="center" paddingRight="0.5rem">
      {history.getIconElement()}
      {history.getTextElement()}
    </Box>
  )
}

export default EventContentHistoryItem;
