import { PrometheusQuery } from "./PrometheusQuery";

export class RootDiskUtilizationQuery extends PrometheusQuery {
  protected _id: string = "calculated:filesystem_utilization_root";

  public override getPossibleValues() {
    return [
      { id: "Used disk (root)", color: "#008000" }
    ]
  };
  
  public override getRefinedData() {
    const refinedData = [];

    if (this.isEmpty()) return [];
    
    for (let i = 0; i < this._queryData.data.result[0].values.length; i += 1) {
      refinedData.push({
        date: this.formatDate(this._queryData.data.result[0].values[i][0]),
        "Used disk (root)": Math.round((this._queryData.data.result[0]?.values[i][1] || 0 * 100 + Number.EPSILON) * 100) / 100
      })
    }

    return refinedData;
  }

  public override getDomain() {
    if (this.isEmpty()) return [0, 100]
    return undefined;
  }

  public override getMiddleValue() {
    if (this.isEmpty()) return 50;
    return undefined;
  }

  public override getValueLabel() {
    return "%";
  }
}
