import { API } from "aws-amplify";

async function activateUsers(userIds: string[], newPassword: string): Promise<void> {
  try {
    const path: string = `/users/accounts/enable`;

    await API.post(
      'api-service',
      path,
      {
        body: {
          userIds,
          password: newPassword
        }
      }
    );

  } catch (error: any) {
    throw new Error(error.response?.data.error || error.message);
  }
}

export default activateUsers;
