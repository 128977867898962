import { Box, IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import { isString } from 'lodash';
import { useMobileQuery } from '@hooks/useMobileQuery';
import { useVerySmallScreenQuery } from '@hooks/useVerySmallScreenQuery';


const CustomBox = styled(Box)`
  .MuiFormControl-root{
    margin-top: 0;
  }
`

type FormHeaderProps = {
  title: string | JSX.Element;
  onClose?: () => void;
  large?: boolean;
  children?: React.ReactNode;
}

/**
 * Header component for Views within the Dashboard.
 * 
 * See also TutorialHeaderTitle.jsx (Used to inject tutorial icon on page name).
 */
const Header: React.FC<FormHeaderProps> = (props) => {
  const { title, onClose, children, large } = props;

  const isMobile = useMobileQuery();
  const isVerySmallScreen = useVerySmallScreenQuery();

  const templateRows = React.useMemo(() => {
    const template = ["auto"]
    if (onClose)
      template.unshift("max-content")
    if (children)
      template.push("max-content")
    return template.join(" ")
  }, [onClose, children])


  if (isMobile || isVerySmallScreen) {
    return (
      <CustomBox
        width="100%"
        display="grid"
        gridTemplateRows={"auto auto max-content"}
        gap="1rem"
      >
        {onClose &&
          <IconButton
            onClick={onClose}
            style={{ height: "2.625rem", width: "2.625rem" }}
            size="large"
            data-cy="back-button">
            <ArrowBack />
          </IconButton>}
        {isString(title) ? <Typography variant="h4" style={{ marginBottom: "0.5rem" }}>{title}</Typography> : { ...title }}
        <Box display="grid" gap="2rem" gridAutoRows="max-content" gridAutoFlow="row" paddingTop={large ? "0.5rem" : ""}>
          {children}
        </Box>
      </CustomBox>
    )
  }

  return (
    <CustomBox
      width="100%"
      display="grid"
      gridTemplateColumns={templateRows || "auto auto max-content"}
      gap="1rem"
    >
      {onClose &&
        <IconButton
          onClick={onClose}
          style={{ height: "2.625rem", width: "2.625rem" }}
          size="large"
          data-cy="back-button">
          <ArrowBack />
        </IconButton>}
      {isString(title) ? <Typography variant="h4" style={{ marginBottom: "0.5rem" }}>{title}</Typography> : { ...title }}
      <Box id="header-children" marginLeft="0.5rem" display="grid" gap="1.5rem" gridAutoColumns="max-content" gridAutoFlow="column" paddingTop={large ? "0.5rem" : ""}>
        {children}
      </Box>
    </CustomBox>
  );
}

export default Header;