import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Logos } from '@utils/Styles';
import { useSnackbar } from 'notistack';
import React from 'react';
import Utils from '@utils/index';
import { useLocation } from 'react-router-dom';

type ProcoreCopyConfigProps = {
  onClick?: () => void;
  forcePattern?: {
    path: string,
    company: string,
    site: string
  }
}

const ProcoreCopyConfig: React.FC<ProcoreCopyConfigProps> = (props) => {
  const { onClick, forcePattern } = props;

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  function handleClick(): void {
    try {
      let config: string = '';
      if (forcePattern !== undefined) {
        config += `URL Path: ${forcePattern.path}\n`;
        config += `Your Company ID: ${forcePattern.company}\n`;
        config += `Your Site ID: ${forcePattern.site}`;

      } else {
        if (location.pathname) config += `URL Path: ${location.pathname.substring(1)}\n`
  
        const arrayOfParameters: string[] = location.search.substring(1).split('&');
        arrayOfParameters.forEach((param) => {
          const keys: string[] = param.split('=');
          if (keys[0] && keys[1]) {
            if (keys[0] === 'company') config += `Your Company ID: ${keys[1]}\n`;
            if (keys[0] === 'site') config += `Your Site ID: ${keys[1]}\n`;
            if (keys[0] === 'stream') config += `Your Stream ID: ${keys[1]}\n`;
            if (keys[0] === 'view') config += `Your View ID: ${keys[1]}\n`;
          }
        })
      }

      navigator.clipboard.writeText(config);
      enqueueSnackbar('Configuration copied to clipboard', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(Utils.Error.getErrorMessage(e), { variant: 'error' })
    }

    if (onClick) onClick();
  }
  
  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <img
          alt=""
          style={{ borderRadius: '50%', height: '1.5rem', width: '1.5rem' }}
          src={Logos.PROCORE_LOGO}
        />
      </ListItemIcon>
      <ListItemText>Copy Procore app configuration</ListItemText>
    </MenuItem>
  )
}

export default ProcoreCopyConfig;
