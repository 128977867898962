import React from 'react';
import { Box, Button, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { List, ListRowProps } from 'react-virtualized';
import EventCard from './EventCard';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import EventContent, { IEventContentHandles } from './EventContent/EventContent';
import NonIdealState from '@components/common/NonIdealState/NonIdealState';
import { InboxOutlined, MailOutline } from '@mui/icons-material';
import MultiEventContent from './EventContent/MultiEventContent';
import TagMenu from './TagMenu';
import { changeEventState, changeEventTag, setEventCount, splitMergedEvents } from '@store/views/overviewView/actions';
import UserList from './UserList';
import API from '@API/index';
import { first, indexOf, isArray, isEmpty, isEqual, last } from 'lodash';
import ListBulkSelection from '@classes/ListBulkSelection';
import EventChangeFactory from '@classes/GEI/EventChangeFactory';
import { useKeyPress } from '@hooks/KeyPress/KeyPressHooks';
import ExportEventDialog from './ExportEventDialog';
import usePrevious from '@hooks/usePrevious';
import Utils from '@utils/index';
import MuteStream from './MuteStream';
import PubSubSelectedEvents from '@classes/PubSubSelectedEvents';
import { setSelectedEvents as updateEventSelection } from '@store/views/overviewView/actions';

type EventsInboxProps = {
  events: VideoStreamEvent[];
  siteListOpen: boolean;
  timeInputOpen: boolean;
  zoneNames: Map<string, string>;
  inputFocused?: boolean;
  otherUsersEventSelection: Array<{ userId: string, eventId: string }>;
  loading: boolean;
  onScrollEnd: (tab: EventState, lastKey: number) => void;
}

const EventsInbox: React.FC<EventsInboxProps> = (props) => {
  const { events, siteListOpen, zoneNames, inputFocused, otherUsersEventSelection, timeInputOpen, onScrollEnd } = props;

  const previousEvents = usePrevious<VideoStreamEvent[]>(events);
  const selectedZone = useSelector((store: IStoreState) => store.overviewView.selectedZone);

  const dispatch = useDispatch();

  const eventCount = useSelector((store: IStoreState) => store.overviewView.eventCount);
  const companies = useSelector((store: IStoreState) => store.companies.companies);
  const sites = useSelector((store: IStoreState) => store.sites.sites);
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const fontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);
  const userData = useSelector((store: IStoreState) => store.session.userData);
  const hideOnGoingEvent = useSelector((store: IStoreState) => store.overviewView.overviewOptions.hideOngoingEvents);
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const permissions = useSelector((store: IStoreState) => store.session.permissions);
  const credentials = useSelector((store: IStoreState) => store.session.credentials);

  const [selectedTab, setSelectedTab] = React.useState<EventState>("unassigned");
  const [selectedEvents, setSelectedEvents] = React.useState<VideoStreamEvent[]>([]);
  const previousSelection = usePrevious<VideoStreamEvent[]>(selectedEvents);

  const [tagMenuAnchor, setTagMenuAnchor] = React.useState<HTMLElement | null>(null);
  const [tagMenuAnchorPosition, setTagMenuAnchorPosition] = React.useState<{ top: number, left: number } | undefined>(undefined);
  const [customMuteDialogOpen, setCustomMuteDialogOpen] = React.useState<boolean>(false);

  const [userListAnchor, setUserListAnchor] = React.useState<HTMLElement | null>(null);
  const [userListAnchorPosition, setUserListAnchorPosition] = React.useState<{ top: number, left: number } | undefined>(undefined);

  const [eventToExport, setEventToExport] = React.useState<VideoStreamEvent | undefined>(undefined);
  const [videoState, setVideoState] = React.useState<"error" | "buffering" | "loading" | "ready">("loading");
  const [scrollLoading, setScrollLoading] = React.useState<number[]>([]);

  const listContainerBoxRef = React.useRef<HTMLDivElement>(null);
  const heightRef = React.useRef<number>(0);
  const widthRef = React.useRef<number>(0);
  const eventContentRef = React.useRef<IEventContentHandles | null>(null);
  const mousePositionRef = React.useRef<{ x: number, y: number }>({ x: 0, y: 0 });
  const isModalOpen = React.useRef<boolean>(false);
  const scrollTopRef = React.useRef<number>(0);
  const listRef = React.useRef<List | null>(null);
  const tabChanged = React.useRef<boolean>(false);
  const autoplayOnHold = React.useRef<boolean>(false);
  const eventsRef = React.useRef<VideoStreamEvent[]>([]);
  const selectedTabRef = React.useRef<EventState>("unassigned");
  const selectedEventsRef = React.useRef(selectedEvents);
  const fontSizeRef = React.useRef(fontSize);
  const hideOnGoingEventRef = React.useRef(hideOnGoingEvent)
  const previousEventsRef = React.useRef(previousEvents)
  const previousSelectionRef = React.useRef(previousSelection);
  const selectedCompanyRef = React.useRef(selectedCompany)
  const userDataRef = React.useRef(userData);
  const credentialsRef = React.useRef(credentials)

  const targetKvsNames: string[] = React.useMemo(() => {
    const set: Set<string> = new Set<string>();
    selectedEvents.forEach((el) => set.add(el.sourceCamera));
    return Array.from(set);
  }, [selectedEvents])

  const hasPermissionToEditEvent = React.useMemo(() => {
    return !selectedEvents.some(e => !Utils.Permissions.userHasPermission(permissions, "EVENTS", e.siteId, e.sourceCamera))
  }, [selectedEvents, permissions])

  const selectFirstAvailableEvent = React.useCallback(() => {
    try {
      const isThereEventsCurrentlySelected: boolean = selectedEventsRef.current && selectedEventsRef.current.length > 0;

      if (isThereEventsCurrentlySelected) {
        const lastElement = last(selectedEventsRef.current);
        const firstElement = first(selectedEventsRef.current);

        if (lastElement && firstElement) {
          const tabEvents = eventsRef.current.filter(el => el.tab && el.tab === selectedTabRef.current);
          let toBeSelected: VideoStreamEvent | undefined = getEventToBeSelected(tabEvents, firstElement)

          if (toBeSelected) {
            setSelectedEvents([toBeSelected]);
            scrollListDownByOneCard();
            return;
          }

          let toBeSelectedPast: VideoStreamEvent | undefined = getEventToBeSelected(tabEvents, lastElement)

          if (toBeSelectedPast) {
            setSelectedEvents([toBeSelectedPast]);
            scrollListUpByOneCard();
            return;
          }
        }
      }

      selectFirstElementOfTheCurrentTab();
    } catch (error) {
      console.log(error);
      selectFirstElementOfTheCurrentTab();
    }
  }, [])

  React.useEffect(() => { credentialsRef.current = credentials }, [credentials])

  React.useEffect(() => {
    if (!isEqual(selectedEvents, previousSelection))
      dispatch(updateEventSelection(selectedEvents))
  }, [dispatch, selectedEvents, previousSelection])

  /**
   * Intecept ArrowUp / ArrowDown keystrokes and avoid default list scrolling.
   */
  React.useEffect(() => {
    function handleKeyDown(e: any) {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  React.useEffect(() => { userDataRef.current = userData }, [userData]);
  React.useEffect(() => { selectedCompanyRef.current = selectedCompany }, [selectedCompany])
  React.useEffect(() => { previousSelectionRef.current = previousSelection }, [previousSelection])
  React.useEffect(() => { previousEventsRef.current = previousEvents }, [previousEvents])
  React.useEffect(() => { hideOnGoingEventRef.current = hideOnGoingEvent }, [hideOnGoingEvent])
  React.useEffect(() => { fontSizeRef.current = fontSize }, [fontSize])
  React.useEffect(() => { selectedEventsRef.current = selectedEvents }, [selectedEvents])
  React.useEffect(() => {
    if (selectedEventsRef.current.length > 0) {
      const ids: string[] = [];
      selectedEventsRef.current.map(el => ids.push(el.id));
      setSelectedEvents(events.filter(el => ids.includes(el.id)));
    }
  }, [events])

  React.useEffect(() => { eventsRef.current = events; }, [events])
  React.useEffect(() => { selectedTabRef.current = selectedTab }, [selectedTab]);
  React.useEffect(resetScrollbarPosition, [selectedTab])

  React.useEffect(() => {
    if (
      tagMenuAnchor !== null ||
      selectedZone !== undefined ||
      userListAnchor !== null ||
      timeInputOpen === true ||
      siteListOpen === true ||
      inputFocused === true ||
      customMuteDialogOpen === true
    ) isModalOpen.current = true;
    else isModalOpen.current = false;
  }, [tagMenuAnchor, userListAnchor, siteListOpen, inputFocused, customMuteDialogOpen, timeInputOpen, selectedZone]);

  React.useEffect(onMount, []);
  React.useEffect(() => {
    if (listContainerBoxRef.current) {
      heightRef.current = listContainerBoxRef.current.clientHeight - (fontSize);
      widthRef.current = listContainerBoxRef.current.clientWidth;
    }
  }, [listContainerBoxRef, fontSize]);
  React.useEffect(updateUserSelection, [events, selectFirstAvailableEvent])
  React.useEffect(removeEventSelection, [selectedTab, selectFirstAvailableEvent]);
  React.useEffect(updateSelectedEvent, [events]);
  React.useEffect(() => {
    if (autoplayOnHold.current === true && previousEventsRef.current && selectedEventsRef.current.length === 1 && selectedEventsRef.current[0]) {
      const tabEvents = events.filter(el => el.tab && el.tab === selectedTabRef.current);
      const previousTabEvets = previousEventsRef.current.filter(el => el.tab && el.tab === selectedTabRef.current);

      if (tabEvents.length > previousTabEvets.length) {
        const currentIndex = indexOf(tabEvents, selectedEventsRef.current[0])
        if (tabEvents[currentIndex - 1]) {
          setSelectedEvents([tabEvents[currentIndex - 1]]);
          autoplayOnHold.current = false;
        }
      }
    }
  }, [events])

  React.useEffect(() => {
    if (selectedCompanyRef.current && selectedEvents.length > 0 && !isEqual(previousSelectionRef.current, selectedEvents)) {
      PubSubSelectedEvents.publish({
        userId: userDataRef.current?.userId || '',
        data: selectedEvents.map(el => el.id)
      });
    }
  }, [selectedEvents])

  useKeyPress('ArrowUp', () => {
    if (isModalOpen.current === true) return;
    handleNextEvent();
  }, [])

  useKeyPress('ArrowDown', () => {
    if (isModalOpen.current === true) return;
    handlePreviousEvent();
  }, [])

  useKeyPress('r', () => {
    if (!hasPermissionToEditEvent) return;
    if (isModalOpen.current === true) return;
    if (selectedEvents.length > 0 && (selectedTab === "trash" || selectedTab === "archived")) {
      handleActivateEvents();
    }
  }, []);

  useKeyPress('i', () => {
    if (!hasPermissionToEditEvent) return;
    if (isModalOpen.current === true) return;
    if (selectedEvents.length > 0 && selectedTab !== "trash") {
      handleTrashEvents();
    }
  }, []);

  useKeyPress('s', () => {
    if (!hasPermissionToEditEvent) return;
    if (isModalOpen.current === true) return;
    if (selectedEvents.length > 0 && selectedTab !== "archived") {
      handleArchiveEvents();
    }
  }, []);

  useKeyPress('j', () => {
    if (isModalOpen.current === true) return;
    const tabEvents = events.filter(el => el.tab && el.tab === selectedTab);

    if (selectedEvents.length > 0) {
      if (selectedEvents.length === 0) {
        setSelectedEvents([tabEvents[0]]);
        return;
      }

      const firstElement = first(selectedEvents);
      if (firstElement) {
        const currentIndex = tabEvents.indexOf(firstElement);
        if (currentIndex > 0) {
          setSelectedEvents([tabEvents[currentIndex - 1]]);
          return;
        }
      }
    }
  }, []);

  useKeyPress('k', () => {
    if (isModalOpen.current === true) return;
    const tabEvents = events.filter(el => el.tab && el.tab === selectedTab);

    if (selectedEvents.length > 0) {
      if (selectedEvents.length === 0) {
        setSelectedEvents([tabEvents[0]]);
        return;
      }

      const lastElement = last(selectedEvents);
      if (lastElement) {
        const currentIndex = tabEvents.indexOf(lastElement);
        if ((currentIndex + 1) < tabEvents.length) {
          setSelectedEvents([tabEvents[currentIndex + 1]]);
          return;
        }
      }
    }
  }, []);

  useKeyPress('f', () => {
    if (isModalOpen.current === true) return;
    if (selectedEvents.length > 0) {
      setTagMenuAnchorPosition(undefined);
      setUserListAnchorPosition(undefined);
      setTagMenuAnchor(null);
      setUserListAnchor(null)

      setUserListAnchorPosition({ top: mousePositionRef.current.y, left: mousePositionRef.current.x });
    }
  }, [])

  useKeyPress('e', () => {
    if (isModalOpen.current === true) return;
    if (selectedEvents.length > 0) {
      setTagMenuAnchorPosition(undefined);
      setUserListAnchorPosition(undefined);
      setTagMenuAnchor(null);
      setUserListAnchor(null)

      setTagMenuAnchorPosition({ top: mousePositionRef.current.y, left: mousePositionRef.current.x });
    }
  }, [])

  useKeyPress('1', () => { handleNumericalKeypress('1') }, []);
  useKeyPress('2', () => { handleNumericalKeypress('2') }, []);
  useKeyPress('3', () => { handleNumericalKeypress('3') }, []);
  useKeyPress('4', () => { handleNumericalKeypress('4') }, []);
  useKeyPress('5', () => { handleNumericalKeypress('5') }, []);
  useKeyPress('6', () => { handleNumericalKeypress('6') }, []);
  useKeyPress('7', () => { handleNumericalKeypress('7') }, []);

  function handleNumericalKeypress(key: '1' | '2' | '3' | '4' | '5' | '6' | '7'): void {
    if (!hasPermissionToEditEvent) return;
    if (isModalOpen.current === true) return;

    if (selectedEvents.length > 0) {
      const map: Map<string, EventTag> = new Map([
        ['1', "true_positive_person"],
        ['2', "true_positive_vehicle"],
        ['3', "false_positive_animal"],
        ['4', "false_positive_light"],
        ['5', "false_positive_weather"],
        ['6', "false_positive_unknown"],
        ['7', "false_positive_other"],
      ]);

      const tag: EventTag | undefined = map.get(key);

      if (tag) {
        handleTagSelection(tag);
      }
    }
  }

  function onMount() {
    window.addEventListener('mousemove', handleMouseMovement);

    return () => {
      window.removeEventListener('mousemove', handleMouseMovement);
    }
  }

  function handleMouseMovement(this: Window, ev: MouseEvent): void {
    mousePositionRef.current = { x: ev.clientX, y: ev.clientY };
  }

  function updateSelectedEvent() {
    if (selectedEventsRef.current.length === 1 && selectedEventsRef.current[0].ongoing === true) {
      // We have a ongoing selection, we might need to update the content.
      const target = events.find(el => el.id === selectedEventsRef.current[0].id);
      if (target) {
        setSelectedEvents([target]);
        return;
      }
    }
  }

  function removeEventSelection() {
    const database = eventsRef.current.filter(el => el.ongoing === false).filter(el => el.tab && el.tab === selectedTab);
    if (database[0]) {
      setSelectedEvents([database[0]]);
    } else {
      selectFirstAvailableEvent();
    }
  }

  function handleEventClicked(clickEvent: React.MouseEvent<HTMLDivElement, MouseEvent>, event: VideoStreamEvent): void {
    autoplayOnHold.current = false;

    clickEvent.stopPropagation();
    clickEvent.preventDefault();

    const newSelection: VideoStreamEvent[] = new ListBulkSelection(events.filter(el => el.tab === selectedTab))
      .withSelection(selectedEvents)
      .getNewSelection(clickEvent, event);

    setSelectedEvents(newSelection);
  }

  function updateUserSelection(): void {
    if (isEmpty(selectedEventsRef.current)) {
      selectFirstAvailableEvent();
      return;
    }

    if (selectedEventsRef.current.length === 1 && eventsRef.current.find(el => el.id === selectedEventsRef.current[0].id)) {
      return;
    }

    if (selectedEventsRef.current.length === 1) {
      const selectedEvent = first(selectedEventsRef.current);
      const eventsWithChildren = eventsRef.current.filter(el => isArray(el.children));
      let parent = undefined;

      for (let i = 0; i < eventsWithChildren.length; i += 1) {
        const target = eventsWithChildren[i].children?.find(el => el.id === selectedEvent?.id);
        if (target) {
          parent = eventsWithChildren[i];
          break;
        }
      }
      if (parent) {
        setSelectedEvents([parent]);
        return;
      }
    }

    const eventsToRemove: VideoStreamEvent[] = [];
    selectedEventsRef.current.forEach((selectedEvent) => {
      const target = eventsRef.current.find(el => el.id === selectedEvent.id);
      if (!target) eventsToRemove.push(selectedEvent);
    })

    if (eventsToRemove.length > 0) {
      const newSelection = selectedEventsRef.current.filter((e) => {
        const target = eventsToRemove.find(el => el.id === e.id);
        if (target) return false;
        return true;
      });

      if (newSelection.length === 0) selectFirstAvailableEvent()
      else setSelectedEvents(newSelection);
    }
  }

  function getZoneNames(event?: VideoStreamEvent): string[] {
    if (!event) return [];

    let zNames: string[] = [];
    if (event.zoneId) {
      event.zoneId.forEach((id) => {
        const name = zoneNames.get(id);
        if (name) {
          zNames.push(name)
        }
      })
    }

    return zNames;
  }

  function rowRenderer(props: ListRowProps, database: VideoStreamEvent[]): JSX.Element | null {
    return (
      <div
        key={props.key}
        style={props.style}
      >
        <EventCard
          event={database[props.index]}
          selected={Boolean(selectedEvents.find(el => el.id === database[props.index].id))}
          onEventClick={handleEventClicked}
          zoneNames={getZoneNames(database[props.index])}
          usersSelectingEvent={otherUsersEventSelection.filter(el => el.eventId === database[props.index].id).map(el => el.userId)}
        />
      </div>
    )
  }

  function handleTagSelection(tag: EventTag): void {
    // To update the current selected content history.
    const prevSelection = selectedEvents.map(el => ({ ...el }));

    selectedEvents.forEach((event) => {
      event.tags = [tag];
      event.escalations = [tag];
      event.children?.forEach((child) => {
        child.tags = [tag];
        child.escalations = [tag];
      })
    })

    // This is the case where the user is assinging the same tag which is currently active on the event;
    const isAllTagsTheSame = prevSelection.filter(el => el.tags && first(el.tags) === first(first(prevSelection)?.tags)).length === prevSelection.length;
    if (isAllTagsTheSame && first(first(prevSelection)?.tags) === tag) {
      handleTagRemoval();
      return;
    }

    if (prevSelection.length === 1) {
      if (prevSelection && prevSelection[0] && prevSelection[0].tags && prevSelection[0].tags[0]) {
        pushHistoryToContent({
          type: "TAG_ADDED",
          value: tag,
          userId: userData?.userId || "",
          ts: Date.now()
        }, {
          type: "TAG_ADDED",
          value: prevSelection[0].tags[0],
          userId: userData?.userId || "",
          ts: Date.now() - 100
        })
      } else {
        pushHistoryToContent({
          type: "TAG_ADDED",
          value: tag,
          userId: userData?.userId || "",
          ts: Date.now()
        })
      }
    }

    dispatch(changeEventTag(selectedEvents, [tag]))

    selectedEvents.forEach((event) => {
      API.GlobalEventInbox.updateEvent(
        event.sourceCamera,
        event.startDate,
        {
          ...event,
          owner: event.owner,
          tab: event.tab === "unassigned" ? undefined : event.tab,
          tags: [tag]
        }
      )

      event.children?.forEach(child => {
        API.GlobalEventInbox.updateEvent(
          child.sourceCamera,
          child.startDate,
          {
            ...child,
            owner: child.owner,
            tab: child.tab === "unassigned" ? undefined : child.tab,
            tags: [tag]
          }
        )
      })
    })
  }

  function handleTagRemoval(): void {
    if (selectedEvents.length === 1) {
      if (selectedEvents && selectedEvents[0] && selectedEvents[0].tags && selectedEvents[0].tags[0]) {
        pushHistoryToContent({
          type: "TAG_REMOVED",
          value: selectedEvents[0].tags[0],
          userId: userData?.userId || "",
          ts: Date.now()
        })
      }
    }

    dispatch(changeEventTag(selectedEvents, undefined))

    selectedEvents.forEach((event) => {
      API.GlobalEventInbox.updateEvent(
        event.sourceCamera,
        event.startDate,
        {
          ...event,
          owner: event.owner,
          tab: event.tab === "unassigned" ? undefined : event.tab,
          tags: []
        }
      )

      event.children?.forEach(child => {
        API.GlobalEventInbox.updateEvent(
          child.sourceCamera,
          child.startDate,
          {
            ...child,
            owner: child.owner,
            tab: child.tab === "unassigned" ? undefined : child.tab,
            tags: []
          }
        )
      })
    })
  }

  function handleTrashEvents() {
    dispatch(changeEventState(selectedEvents, "trash"));
    selectedEvents.forEach((event) => {
      API.GlobalEventInbox.updateEvent(
        event.sourceCamera,
        event.startDate,
        { ...event, tab: 'trash', tags: event.tags, owner: event.owner }
      )

      event.children?.forEach(child => {
        API.GlobalEventInbox.updateEvent(
          child.sourceCamera,
          child.startDate,
          { ...child, tab: 'trash', tags: child.tags, owner: child.owner }
        )

      })
    })
    updateCountOnRedux(selectedEvents, selectedTab, "trash")
    selectFirstAvailableEvent();
  }

  function handleActivateEvents() {
    const unassigned = selectedEvents.filter(el => el.owner === undefined);
    const assigned = selectedEvents.filter(el => el.owner !== undefined);

    unassigned.forEach((event) => {
      API.GlobalEventInbox.updateEvent(
        event.sourceCamera,
        event.startDate,
        { ...event, tab: undefined, owner: undefined, tags: event.tags }
      )

      event.children?.forEach(child => {
        API.GlobalEventInbox.updateEvent(
          child.sourceCamera,
          child.startDate,
          { ...child, tab: undefined, owner: undefined, tags: child.tags }
        )
      })
    });

    assigned.forEach((event) => {
      API.GlobalEventInbox.updateEvent(
        event.sourceCamera,
        event.startDate,
        { ...event, tab: 'assigned', owner: event.owner, tags: event.tags }
      )

      event.children?.forEach(child => {
        API.GlobalEventInbox.updateEvent(
          child.sourceCamera,
          child.startDate,
          { ...child, tab: 'assigned', owner: child.owner, tags: child.tags }
        )
      })
    });

    if (unassigned.length > 0) {
      updateCountOnRedux(unassigned, selectedTab, "unassigned")
      dispatch(changeEventState(unassigned, "unassigned"))
    }

    if (assigned.length > 0) {
      updateCountOnRedux(assigned, selectedTab, "assigned")
      dispatch(changeEventState(assigned, "assigned"))
    }

    selectFirstAvailableEvent();
  }

  function updateBackendAssigment(event: VideoStreamEvent, id?: string) {
    event.owner = id
    if (id) {
      API.GlobalEventInbox.updateEvent(
        event.sourceCamera,
        event.startDate,
        { ...event, owner: id, tab: (event.tab === undefined || event.tab === "unassigned") ? 'assigned' : event.tab, tags: event.tags }
      );
    } else {
      let tab: string | undefined = undefined;
      if (event.tab) {
        if (event.tab === "archived") tab = "archived";
        if (event.tab === "trash") tab = "trash";
      }

      API.GlobalEventInbox.updateEvent(
        event.sourceCamera,
        event.startDate,
        { ...event, tags: event.tags, tab: tab as EventState | undefined, owner: undefined }
      );
    }
  }

  function updateCountOnRedux(targets: VideoStreamEvent[], from: EventState, to: EventState) {
    let increment = 0;
    targets.forEach((parent) => {
      increment += 1;
      if (parent.children && isArray(parent.children)) {
        parent.children.forEach((child) => {
          increment += 1;
        })
      }
    })

    if (increment < 0) increment = 0;

    dispatch(setEventCount({
      unassigned: from === "unassigned" ? eventCount.unassigned - increment : to === "unassigned" ? eventCount.unassigned + increment : eventCount.unassigned,
      assigned: from === "assigned" ? eventCount.assigned - increment : to === "assigned" ? eventCount.assigned + increment : eventCount.assigned,
      archived: from === "archived" ? eventCount.archived - increment : to === "archived" ? eventCount.archived + increment : eventCount.archived,
      muted: from === "muted" ? eventCount.muted - increment : to === "muted" ? eventCount.muted + increment : eventCount.muted,
      trash: from === "trash" ? eventCount.trash - increment : to === "trash" ? eventCount.trash + increment : eventCount.trash,
    }))
  }

  function handleUserAssignment(id?: string) {
    const newEvents = [...selectedEvents];

    for (let i = 0; i < newEvents.length; i += 1) {
      updateBackendAssigment(newEvents[i], id);
      newEvents[i].children?.forEach(child => {
        updateBackendAssigment(child, id);
      })
    };

    let newTab = "assigned";

    if (id && selectedTab === "unassigned") newTab = "assigned";
    else if (!id && selectedTab === "assigned") newTab = "unassigned";
    else newTab = selectedTab;

    dispatch(changeEventState(selectedEvents, newTab as any))
    updateCountOnRedux(newEvents, selectedTab, "assigned")
    if (newTab !== selectedTab) selectFirstAvailableEvent();

    // To update the current content history being displayed.
    if (newTab === selectedTab && selectedEvents.length === 1) {
      pushHistoryToContent({
        type: "OWNER_CHANGED",
        userId: userData?.userId || "",
        value: id || "",
        ts: Date.now()
      })
    }
  }

  function handleArchiveEvents() {
    dispatch(changeEventState(selectedEvents, "archived"));
    selectedEvents.forEach((event) => {
      API.GlobalEventInbox.updateEvent(
        event.sourceCamera,
        event.startDate,
        { ...event, tab: 'archived', tags: event.tags, owner: event.owner }
      )
      event.children?.forEach(child => {
        API.GlobalEventInbox.updateEvent(
          child.sourceCamera,
          child.startDate,
          { ...child, tab: 'archived', tags: child.tags, owner: child.owner }
        )
      })
    })
    updateCountOnRedux(selectedEvents, selectedTab, "archived")
    selectFirstAvailableEvent();
  }

  function getEventToBeSelected(events: VideoStreamEvent[], target: VideoStreamEvent): VideoStreamEvent | undefined {
    let toBeSelected: VideoStreamEvent | undefined = undefined;

    if (hideOnGoingEventRef.current) toBeSelected = events.reverse().find(el => el.endDate >= target.endDate)
    else toBeSelected = events.reverse().find(el => el.startDate >= target.startDate)

    return toBeSelected;
  }

  function selectFirstElementOfTheCurrentTab(): void {
    try {
      if (selectedTabRef.current === "unassigned") {
        const target = eventsRef.current.find(el => el.tab === undefined || el.tab === "unassigned");
        if (target) setSelectedEvents([target]);
        else setSelectedEvents([]);
      } else if (selectedTabRef.current === "archived") {
        const target = eventsRef.current.find(el => el.tab === "archived");
        if (target) setSelectedEvents([target]);
        else setSelectedEvents([]);
      } else if (selectedTabRef.current === "assigned") {
        const target = eventsRef.current.find(el => el.tab === "assigned");
        if (target) setSelectedEvents([target]);
        else setSelectedEvents([]);
      } else if (selectedTabRef.current === "trash") {
        const target = eventsRef.current.find(el => el.tab === "trash");
        if (target) setSelectedEvents([target]);
        else setSelectedEvents([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function scrollListUpByOneCard(): void {
    if (listRef.current) {
      listRef.current.scrollToPosition(scrollTopRef.current + (fontSizeRef.current * 6))
    }
  }

  function scrollListDownByOneCard(): void {
    if (listRef.current) {
      listRef.current.scrollToPosition(scrollTopRef.current - (fontSizeRef.current * 6))
    }
  }

  function pushHistoryToContent(history: APIEventChanges, previous?: APIEventChanges): void {
    if (eventContentRef.current) {
      const change = EventChangeFactory.createEventChange(history, previous);
      eventContentRef.current.pushHistory(change);
    }
  }

  function handleExportRequest(): void {
    if (selectedEvents.length === 1) {
      const event = first(selectedEvents);
      if (event) {
        setEventToExport(event);
      }
    }
  }

  function getExportTooltip(): string {
    if (videoState === "error") {
      return "Video footage unavailable"
    }

    if (selectedEvents.length > 1) {
      return "Cannot export multiple events";
    }

    if (selectedEvents.find(el => el.ongoing === true) !== undefined) {
      return "Cannot export ongoing events";
    }

    if (isMultipleSourceCamerasSelected()) {
      return "Cannot export multiple cameras footage";
    }

    return "Export selected event as video clip"
  }

  function getTopOffset(): number | undefined {
    if (tabChanged.current === true) {
      return undefined;
    }

    if (previousEvents) {
      const current = events.filter(el => el.tab && el.tab === selectedTab);
      const previous = previousEvents.filter(el => el.tab && el.tab === selectedTab);

      if (listRef.current && listRef.current.Grid && (current.length !== previous.length)) {
        //@ts-ignore
        const currentStartIndex = listRef.current.Grid._renderedRowStartIndex as number;
        //@ts-ignore
        const currentStopIndex = listRef.current.Grid._renderedRowStopIndex as number;

        if (previous[currentStartIndex] && previous[currentStopIndex]) {
          let focusId = '';
          const focusElement = first(selectedEvents)
          if (focusElement) focusId = focusElement.id;
          else focusId = previous[currentStartIndex].id;

          const diff = Utils.EventInbox.getListMovement(previous, current, focusId)

          if (current.length * (fontSize * 6) > listRef.current.Grid.props.height) {
            const curr = scrollTopRef.current;
            scrollTopRef.current = curr + (diff * (fontSize * 6));
            return curr + (diff * (fontSize * 6))
          }
        }
      }
    }

    return undefined;
  }

  function resetScrollbarPosition(): void {
    tabChanged.current = true;

    setTimeout(() => {
      if (listRef.current) {
        listRef.current.scrollToPosition(0);
      }
    }, 10)

    setTimeout(() => {
      tabChanged.current = false;
    }, 250)
  }

  function handleNextEvent() {
    if (selectedEvents.length === 1) {
      const tabEvents = events.filter((el) => el.tab && el.tab === selectedTab);
      const currentIndex = indexOf(tabEvents, selectedEvents[0]);

      if (tabEvents[currentIndex - 1]) {
        setSelectedEvents([tabEvents[currentIndex - 1]]);
        scrollToSelectedEvent(currentIndex - 1, 'up');
      }
    }
  }

  function handlePreviousEvent() {
    if (selectedEvents.length === 1) {
      const tabEvents = events.filter((el) => el.tab && el.tab === selectedTab);
      const currentIndex = indexOf(tabEvents, selectedEvents[0]);

      if (tabEvents[currentIndex + 1]) {
        setSelectedEvents([tabEvents[currentIndex + 1]]);
        scrollToSelectedEvent(currentIndex + 1, 'down');
      }
    }
  }

  function scrollToSelectedEvent(index: number, direction: "up" | "down") {
    if (listRef.current) {
      const rowHeight = fontSize * 6;
      const scrollTop = scrollTopRef.current;

      if (direction === 'up') {
        if (index * rowHeight < scrollTop) {
          listRef.current.scrollToPosition((index - 1) * rowHeight);
        }
      } else if (direction === 'down') {
        const listHeight = listRef.current.props.height;
        if ((index + 1) * rowHeight >= scrollTop + listHeight) {
          listRef.current.scrollToPosition((index + 1) * rowHeight - listHeight);
        }
      }
    }
  }

  function handleTimelineEventClicked(event: VideoStreamEvent) {
    setSelectedEvents([event]);
    if (listRef.current) {
      const tabEvents = eventsRef.current.filter(el => el.tab && el.tab === selectedTabRef.current);
      listRef.current.scrollToRow(indexOf(tabEvents, event));
    }
  }

  function handleSplitMergedEvents() {
    dispatch(splitMergedEvents(selectedEvents));
  }

  function isExportDisabled(): boolean {
    if (selectedEvents.length > 1) return true;
    if (!hasPermissionToEditEvent) return true;
    if (Boolean(selectedEvents.find(el => el.ongoing === true) !== undefined)) return true;
    if (videoState === "error") return true;
    if (isMultipleSourceCamerasSelected()) return true;
    return false;
  }

  function isMultipleSourceCamerasSelected(): boolean {
    if (selectedEvents.length === 1) {
      if (selectedEvents[0].children) {
        const set = new Set();
        set.add(selectedEvents[0].sourceCamera);
        selectedEvents[0].children.forEach(el => set.add(el.sourceCamera));
        if (set.size > 1) return true;
      }
    }

    return false;
  }

  return (
    <Box display="grid" gridTemplateRows="min-content auto" height="100%">
      <Box display="grid" gridTemplateColumns="auto auto" borderBottom="1px solid rgba(0, 0, 0, 0.12)">
        <Box paddingLeft="0.5rem">
          <Tabs id="overview-event-inbox-tabs" indicatorColor="primary" color="primary" value={selectedTab} variant="scrollable" >
            <Tab
              value="unassigned"
              onClick={() => setSelectedTab("unassigned")}
              data-cy="event-inbox-unassigned-tab"
              label={
                <Typography
                  style={{ color: selectedTab === "unassigned" ? 'blue' : undefined }}
                  variant="body2"
                >
                  {`Unassigned (${eventCount.unassigned})`}
                </Typography>}
            />
            <Tab
              value="assigned"
              onClick={() => setSelectedTab("assigned")}
              data-cy="event-inbox-assigned-tab"
              label={
                <Typography
                  style={{ color: selectedTab === "assigned" ? 'blue' : undefined }}
                  variant="body2"
                >
                  {`Assigned (${eventCount.assigned})`}
                </Typography>}
            />
            <Tab
              value="archived"
              onClick={() => setSelectedTab("archived")}
              data-cy="event-inbox-archived-tab"
              label={
                <Typography
                  style={{ color: selectedTab === "archived" ? 'blue' : undefined }}
                  variant="body2"
                >
                  {`Archived (${eventCount.archived})`}
                </Typography>}
            />
            <Tab
              value="trash"
              onClick={() => setSelectedTab("trash")}
              data-cy="event-inbox-trash-tab"
              label={
                <Typography
                  style={{ color: selectedTab === "trash" ? 'blue' : undefined }}
                  variant="body2"
                >
                  {`Trash (${eventCount.trash})`}
                </Typography>}
            />
            <Tab
              value="muted"
              onClick={() => setSelectedTab("muted")}
              data-cy="event-inbox-muted-tab"
              label={
                <Typography
                  style={{ color: selectedTab === "muted" ? 'blue' : undefined }}
                  variant="body2"
                >
                  {`Muted (${eventCount.muted})`}
                </Typography>}
            />
          </Tabs>
        </Box>
        <Box display="grid">
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" width="100%">
            {selectedEvents.length === 1 && isArray(first(selectedEvents)?.children) && !isEmpty(first(selectedEvents)?.children) &&
              <Tooltip
                title='Split this merged events back into individuals'
              >
                <Button
                  style={{ height: '1.5rem', marginRight: '0.5rem' }}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleSplitMergedEvents}
                  data-cy="events-inbox-split-button"
                  disabled={!hasPermissionToEditEvent}
                >
                  Split
                </Button>
              </Tooltip>
            }
            {selectedEvents.length > 0 &&
              <Tooltip
                title='Tag selected events ("E")'
              >
                <Button
                  style={{ height: '1.5rem', marginRight: '0.5rem' }}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={(e) => setTagMenuAnchor(e.currentTarget)}
                  disabled={!hasPermissionToEditEvent}
                  data-cy="events-inbox-escalate-button"
                >
                  Escalate
                </Button>
              </Tooltip>
            }
            {selectedEvents.length > 0 &&
              <Tooltip
                title='Assign to a team member ("F")'
              >
                <Button
                  style={{ height: '1.5rem', marginRight: '0.5rem' }}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={(e) => setUserListAnchor(e.currentTarget)}
                  disabled={!hasPermissionToEditEvent}
                  data-cy="events-inbox-assign-button"
                >
                  Assign
                </Button>
              </Tooltip>
            }
            {(selectedEvents.length > 0 && (selectedEvents[0].tab === "trash" || selectedEvents[0].tab === "archived")) &&
              <Tooltip
                title='Reopen selected events ("R")'
              >
                <Button
                  style={{ height: '1.5rem', marginRight: '0.5rem' }}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleActivateEvents}
                  disabled={!hasPermissionToEditEvent}
                  data-cy="events-inbox-reopen-button"
                >
                  Reopen
                </Button>
              </Tooltip>
            }
            {(selectedEvents.length > 0 && selectedEvents[0].tab !== "archived") &&
              <Tooltip
                title='Archive selected events ("S")'
              >
                <Button
                  style={{ height: '1.5rem', marginRight: '0.5rem' }}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleArchiveEvents}
                  disabled={!hasPermissionToEditEvent}
                  data-cy="events-inbox-archive-button"
                >
                  Archive
                </Button>
              </Tooltip>
            }
            {selectedEvents.length > 0 && selectedEvents[0].tab !== "trash" &&
              <Tooltip
                title='Trash selected events ("I")'
              >
                <Button
                  style={{ height: '1.5rem', marginRight: '0.5rem' }}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleTrashEvents}
                  disabled={!hasPermissionToEditEvent}
                  data-cy="events-inbox-trash-button"
                >
                  Trash
                </Button>
              </Tooltip>
            }
            {selectedEvents.length > 0 &&
              <Tooltip
                title={getExportTooltip()}
              >
                <span>
                  <Button
                    style={{ height: '1.5rem', marginRight: '0.5rem' }}
                    color="secondary"
                    variant="outlined"
                    size="small"
                    onClick={handleExportRequest}
                    disabled={isExportDisabled()}
                    data-cy="events-inbox-export-button"
                  >
                    Export
                  </Button>
                </span>
              </Tooltip>
            }
            {selectedEvents.length > 0 &&
              <MuteStream
                selectedEvents={selectedEvents}
                onDialogOpen={() => setCustomMuteDialogOpen(true)}
                onDialogClose={() => setCustomMuteDialogOpen(false)}
                hasPermissionToEditEvent={hasPermissionToEditEvent}
              />
            }
          </Box>
        </Box>
      </Box>
      {events.filter(el => el.tab && el.tab === selectedTab).length === 0 &&
        <NonIdealState
          icon={<InboxOutlined />}
          title="No events"
          description="No events match the currently selected filters. Please try to adjust the filters!"
        />
      }
      {events.filter(el => el.tab && el.tab === selectedTab).length > 0 &&
        <Box width="100%" height="100%" display="grid" gridTemplateColumns="22rem auto">
          <div ref={listContainerBoxRef}>
            <Box id="overview-event-inbox-event-list" width="100%" height="100%">
              {/* @ts-ignore */}
              <List
                ref={listRef}
                style={{ outline: 'none' }}
                height={heightRef?.current || window.innerHeight * 0.88}
                width={widthRef?.current || fontSize * 22}
                rowCount={events.filter(event => event.tab && event.tab === selectedTab).length}
                rowHeight={fontSize * 6}
                rowRenderer={(props: ListRowProps) => rowRenderer(props, events.filter(el => el.tab && el.tab === selectedTab))}
                overscanRowCount={10}
                onScroll={(e) => {
                  if (Math.floor((e.scrollTop + e.clientHeight) - e.scrollHeight) > -100) {
                    const lastKey = events.filter(el => el.tab && el.tab === selectedTab).reduce((prev, current) => Utils.Timestamp.edgeTimestampToMiliseconds(prev.startDate) < Utils.Timestamp.edgeTimestampToMiliseconds(current.startDate) ? prev : current).startDate
                    if (lastKey && !scrollLoading.includes(lastKey)) {
                      setScrollLoading([...scrollLoading, lastKey]);
                      onScrollEnd(selectedTab, lastKey);
                    }
                  }
                  scrollTopRef.current = e.scrollTop;
                }}
                scroll
                scrollTop={getTopOffset()}
                scrollToAlignment="center"
              />
            </Box>
          </div>
          <Box
            id="overview-event-inbox-event-content-container"
            paddingRight="1rem"
            paddingLeft="1rem"
            display="flex"
            width="100%"
            height="100%"
          >
            {selectedEvents.length === 0 &&
              <NonIdealState
                title='No selection'
                description={
                  <Box display="grid" gridTemplateRows="min-content min-content" gap="1rem">
                    <Typography variant="body2">Select events on the left panel to see footage and more information.</Typography>
                    <Typography variant="body2">Shift + left-click to select multiple events.</Typography>
                  </Box>
                }
                icon={<MailOutline />}
              />
            }
            {selectedEvents.length === 1 &&
              <EventContent
                ref={eventContentRef}
                event={selectedEvents[0]}
                companies={companies}
                sites={sites}
                streams={streams}
                onTagSelected={handleTagSelection}
                zoneNames={getZoneNames(first(selectedEvents))}
                onVideoStateChange={(param) => setVideoState(param)}
                onNextEventClick={handleNextEvent}
                onPreviousEventClick={handlePreviousEvent}
                events={events}
                onEventClick={(event) => { handleTimelineEventClicked(event) }}
                hasPermissionToEditEvent={hasPermissionToEditEvent}
              />
            }
            {selectedEvents.length > 1 &&
              <MultiEventContent
                events={selectedEvents}
                onTagSelected={handleTagSelection}
                zoneNames={zoneNames}
                hasPermissionToEditEvent={hasPermissionToEditEvent}
              />
            }
          </Box>
        </Box>
      }
      <TagMenu
        anchor={tagMenuAnchor}
        onClose={() => { setTagMenuAnchor(null); setTagMenuAnchorPosition(undefined); }}
        onTagSelected={handleTagSelection}
        anchorPosition={tagMenuAnchorPosition}
        selectedEvents={selectedEvents}
      />
      <UserList
        anchor={userListAnchor}
        anchorPosition={userListAnchorPosition}
        onClose={() => { setUserListAnchor(null); setUserListAnchorPosition(undefined) }}
        onUserSelected={handleUserAssignment}
        activeUsersOnly
        targetKvsNames={targetKvsNames}
      />
      <ExportEventDialog
        open={eventToExport !== undefined}
        eventToExport={eventToExport}
        onClose={() => setEventToExport(undefined)}
      />
    </Box>
  )
}

export default EventsInbox;
