function getSubstream(mjpgUrl: string): number { 
  const string = mjpgUrl.split('/').find((str) => {
    const index: number = +str;
    if (!isNaN(index) && index > 0) {
      return true
    }
    return false
  });

  if (string) {
    return +string;
  }

  if (mjpgUrl.includes('?camera=')) {
    const res = mjpgUrl.split('?camera=')[1].charAt(0);
    const index = +res;
    if(!isNaN(index) && index > 0) return index;
  }

  if (mjpgUrl.includes('&camera=')) {
    const res = mjpgUrl.split('&camera=')[1].charAt(0);
    const index = +res;
    if(!isNaN(index) && index > 0) return index;
  }

  return 1;
}

export default getSubstream;
