import { API } from 'aws-amplify';

async function updateView(view: UserView): Promise<void> {
  try {
    const path: string = `/grids/${view.id}`;


    await API.put(
      'api-service',
      path,
      {
        body: {
          "layout": view.layout,
          "name": view.name,
          "siteId": view.siteId,
          "streams": view.streams
        }
      }
    )
  } catch (error: any) {
    throw error;
  }
}

export default updateView;