function getGroupMetricTimestamps(
  timestamps: Date[],
  period: number = 300
): Date[] {
  const groupedTimestamps: Date[] = [];

  if (timestamps.length < 2) {
    return timestamps;
  }

  groupedTimestamps.push(timestamps[0]);

  for (let i = 1; i < timestamps.length; i++) {
    let time1 = timestamps[i].getTime();
    let time2 = timestamps[i - 1].getTime();
    if (time1 - time2 !== period * 1000) {
      groupedTimestamps.push(timestamps[i - 1]);
      groupedTimestamps.push(timestamps[i]);
    }
  }

  groupedTimestamps.push(timestamps[timestamps.length - 1]);

  return groupedTimestamps;
}

export default getGroupMetricTimestamps;
