import { store } from '../../index';
import * as SessionActions from '@store/session/actions';
import { Auth } from 'aws-amplify';
import { setUserDefaultCompany } from '../Grids/Hooks';
import { fetchAllCompaniesAndSites } from '../Management/ManagementHooks';
import { setFetchingGrids } from '@store/views/gridView/actions';
import { setLoadingBasicData } from '@store/views/appView/actions';

export const fetchAppInitialData = async () => {
  store.dispatch(setFetchingGrids(true));
  
  await fetchAllCompaniesAndSites();
  
  if (store.getState().selectedCompanies.selectedCompany === undefined) setUserDefaultCompany();

  store.dispatch(setFetchingGrids(false));
  store.dispatch(setLoadingBasicData(false));
}

export async function refreshSession() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const currentSession = await Auth.currentSession();
    await cognitoUser.refreshSession(currentSession.getRefreshToken(), async () => {
      const auth = Auth.essentialCredentials(await Auth.currentCredentials());
      store.dispatch(SessionActions.authenticateUser(cognitoUser, auth));
    });
  } catch (e) {
    console.log(e);
  }
}
