import { IEdgeDevice } from "@store/assets/types";
import { API } from "aws-amplify";

async function listDevices(filter: "All" | "Available", siteId?: string, companyId?: string): Promise<IEdgeDevice[]> {
  try {
    let path: string = `/devices?filter=${filter}&${siteId ? `siteId=${siteId}` : `companyId=${companyId}`}`;

    const data = await API.get(
      'api-service',
      path,
      {}
    );

    const devices = (data.data as IEdgeDevice[]).sort((d1, d2) =>
      (d1?.iotThingName || "z").localeCompare((d2?.iotThingName || "z")));

    return devices;
  } catch (error: any) {
    throw error;
  }
}

export default listDevices;
