export async function shorten(url: string): Promise<string> {
  try {
    const response = await fetch("https://api.linkbird.io/api/v1/links", {
      method: 'POST',
      signal: AbortSignal.timeout(3000),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer key_vsd9mz1un298nw67`
      },
      body: JSON.stringify({
        "longUrl": url
      })
    })

    const result = await response.json() as { shortUrl: string };
    return result.shortUrl;
  } catch (error) {
    console.log(error);
    return url;
  }
}