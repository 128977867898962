import React from 'react';
import { Box, Typography } from '@mui/material';
import Utils from '@utils/index';
import styled from 'styled-components';
import { useMobileQuery } from '@hooks/useMobileQuery';

const LightH5 = styled(Typography)`
  font-weight: 300;
`

type VideoDateProps = {
  timestamp: number;
  timezone?: string;
}

export const VideoDate: React.FC<VideoDateProps> = (props) => {
  const { timestamp, timezone } = props;

  const isMobile = useMobileQuery();

  function timestampToString(timestamp: number, timezone?: string): string {
    const date = new Date(timestamp);
    const tz = Utils.Site.getTimezone({ timezone } as Site);
    if (isMobile) return `${Utils.Date.format(date, 'h:mm:ss A', tz)}`
    return `${Utils.Date.format(date, 'MMM Do, YYYY h:mm:ss A', tz)}`
  }

  const date = timestampToString(timestamp, timezone);

  return (
    <Box display="grid" gridTemplateColumns="max-content max-content auto" columnGap="0.5rem">
      <LightH5 variant="h5">{date}</LightH5>
    </Box>
  );
}