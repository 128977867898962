import { IVideoTimelineHandles, TimelineVisibilityHint } from '@components/timeline/timeline/VideoTimeline';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import Utils from '@utils/index';
import { STREAM_FEATURES } from '../OverviewEventInbox';
import MultiStreamTimeline from '@components/timeline/timeline/MultiStreamTimeline';

type EventContentTimelineProps = {
  events: VideoStreamEvent[];
  selectedEvent: VideoStreamEvent;
  onEventClick?: (event: VideoStreamEvent) => void;
  onTimelineClick?: (date: Date) => void;
  kvsNames: string[];
}

export interface IEventContentTimelineHandles {
  updateTime: (timestamp: number, id?: string) => void;
}

const EventContentTimeline: React.ForwardRefRenderFunction<IEventContentTimelineHandles, EventContentTimelineProps> = (props, ref) => {
  const { events, selectedEvent, onEventClick, onTimelineClick, kvsNames } = props;

  React.useImperativeHandle(ref, () => ({
    updateTime
  }))

  const sites = useSelector((store: IStoreState) => store.sites.sites);
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const filters = useSelector((store: IStoreState) => store.overviewView.filters);

  const [timezone, setTimezone] = React.useState<string | undefined>(undefined);
  const [timeEvents] = React.useState<VideoStreamTimeChunk[]>([]);
  const [retentionPeriod, setRetentionPeriod] = React.useState<number>(Date.now());
  const [features] = React.useState<StreamFeatures | undefined>(undefined);

  const timelineRef = React.useRef<IVideoTimelineHandles | null>(null);
  const eventsRef = React.useRef<VideoStreamEvent[]>([]);
  const selectedEventRef = React.useRef(selectedEvent)
  const sitesRef = React.useRef(sites)
  const kvsNamesRef = React.useRef(kvsNames)
  const streamsRef = React.useRef(streams)

  const [streamEvents, setStreamEvents] = React.useState<VideoStreamEvent[]>(() => {
    if (kvsNames.length === 1) {
      const streamEvents = events.filter(el => kvsNamesRef.current.includes(el.sourceCamera));
      return streamEvents;
    } else {
      const streamEvents = events.filter(el => kvsNamesRef.current.includes(el.sourceCamera) && el.eventType === selectedEvent.eventType);
      return streamEvents;
    }
  });

  React.useEffect(() => { streamsRef.current = streams }, [streams])
  React.useEffect(() => { kvsNamesRef.current = kvsNames }, [kvsNames])
  React.useEffect(() => { sitesRef.current = sites }, [sites])
  React.useEffect(() => { selectedEventRef.current = selectedEvent }, [selectedEvent])
  React.useEffect(() => {
    eventsRef.current = events;
  }, [events])

  const [timelineVisibilityHint, setTimelineVisibilityHint] = React.useState<TimelineVisibilityHint>(generateHints())

  React.useEffect(() => {
    setTimelineVisibilityHint(generateHints());
  }, [selectedEvent, filters])


  React.useEffect(() => {
    const { companyId, siteId } = selectedEventRef.current;
    const timezone = sitesRef.current.get(companyId)?.find(el => el.siteId === siteId)?.timezone;
    setTimezone(timezone);
  }, [selectedEvent.id])

  React.useEffect(() => {
    if (kvsNames.length === 1) {
      const streamEvents = events.filter(el => kvsNamesRef.current.includes(el.sourceCamera));
      setStreamEvents(streamEvents);
    } else {
      const streamEvents = events.filter(el => kvsNamesRef.current.includes(el.sourceCamera) && el.eventType === selectedEvent.eventType);
      setStreamEvents(streamEvents);
    }
  }, [selectedEvent, events, kvsNames.length])

  React.useEffect(() => {
    const { sourceCamera } = selectedEventRef.current;

    const stream = streamsRef.current.find(el => el.kvsName === sourceCamera);
    if (stream) {
      setRetentionPeriod(Utils.Stream.calculateRetentionTimestamp(stream, Date.now()));
    }
  }, [selectedEvent.id])

  React.useEffect(() => {
    if (timelineRef.current) timelineRef.current.updateTime(Utils.Timestamp.edgeTimestampToMiliseconds(selectedEventRef.current.startDate))
  }, [selectedEvent.id])

  React.useEffect(() => {
    setTimeout(() => {
      const { startDate, endDate } = selectedEventRef.current;
      if (timelineRef.current) {
        timelineRef.current.moveTo(new Date(Utils.Timestamp.edgeTimestampToMiliseconds(startDate)), new Date(endDate));
      }
    }, 200)
  }, [selectedEvent.id])

  function updateTime(timestamp: number, id?: string): void {
    if (timelineRef.current) timelineRef.current.updateTime(timestamp, id)
  }

  function handleTimelineClick(date: Date) {
    if (onTimelineClick) onTimelineClick(date);
  }

  function handleTimeRangeChange(start: Date, end: Date) {

  }

  function handleEventClicked(id: string) {
    if (onEventClick) {
      const target = eventsRef.current.find(el => el.id === id);
      if (target) {
        onEventClick(target)
      }
    }
  }

  function generateHints(): TimelineVisibilityHint {
    return {
      motion: Boolean(eventsRef.current.filter(el => el.sourceCamera === selectedEventRef.current.sourceCamera).find(el => el.eventType === "Motion")),
      zones: Boolean(eventsRef.current.filter(el => el.sourceCamera === selectedEventRef.current.sourceCamera).find(el => el.eventType === "Geofence triggered")),
      safetyInfraction: Boolean(eventsRef.current.filter(el => el.sourceCamera === selectedEventRef.current.sourceCamera).find(el => el.eventType === "Safety infraction")),
      personDetected: Boolean(eventsRef.current.filter(el => el.sourceCamera === selectedEventRef.current.sourceCamera).find(el => el.eventType === "Person detected")),
      vehicleDetected: Boolean(eventsRef.current.filter(el => el.sourceCamera === selectedEventRef.current.sourceCamera).find(el => el.eventType === "Vehicle detected")),
      // legacy
      fire: Boolean(eventsRef.current.filter(el => el.sourceCamera === selectedEventRef.current.sourceCamera).find(el => el.eventType === "Fire detected")),
      socialDistance: Boolean(eventsRef.current.filter(el => el.sourceCamera === selectedEventRef.current.sourceCamera).find(el => el.eventType === "Social distancing")),
      vehicleLicensePlate: Boolean(eventsRef.current.filter(el => el.sourceCamera === selectedEventRef.current.sourceCamera).find(el => el.eventType === "License plate detected")),
    }
  }

  return (
    <MultiStreamTimeline
      streams={streams.filter(stream => kvsNames.includes(stream.kvsName))}
      ref={timelineRef}
      timezone={timezone}
      onClick={handleTimelineClick}
      timeEvents={timeEvents}
      events={streamEvents}
      onTimeRangeChange={handleTimeRangeChange}
      timerangeBackground={false}
      features={features || STREAM_FEATURES}
      min={retentionPeriod}
      highlightEvents={[selectedEvent]}
      mouseMarker={false}
      onEventClick={(id) => handleEventClicked(id)}
      noPadding
      renderEventsTags
      hint={timelineVisibilityHint}
    />
  )
}

export default React.forwardRef(EventContentTimeline);
