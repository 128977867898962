export function createActionsFilters(isSuperAdministrator: boolean): FilterProperties[] {
  if (isSuperAdministrator === true) {
    return [
      { label: 'Active', property: 'active', type: 'boolean' },
      { label: 'Created at', property: 'createdAt', type: 'date' },
      { label: 'Hash', property: 'has', type: 'string' },
      { label: 'ID', property: 'id', type: 'string' },
    ];
  }

  return [
    { label: 'Active', property: 'active', type: 'boolean' },
    { label: 'Created at', property: 'createdAt', type: 'date' },
  ];
}
