import { ArrayField } from "./ArrayField";
import { BooleanField } from "./BooleanField";
import { DateField } from "./DateField";
import { DurationField } from "./DurationField";
import { Operator } from "./Field";
import { MultiSelectField } from "./MultiSelectField";
import { NumberField } from "./NumberField";
import { SelectField } from "./SelectField";
import { StringField } from "./StringField";

export class FieldFactory {
  public static createStringField<T>(name: string, operators?: Operator[]): StringField<T> {
    return new StringField<T>()
      .withName(name)
      .withType("string")
      .withOperators(operators || ["contains", "ends_with", "equals", "is_empty", "is_not_empty", "starts_with"]);
  }

  public static createNumberField<T>(name: string, operators?: Operator[]): NumberField<T> {
    return new NumberField<T>()
      .withName(name)
      .withType("number")
      .withOperators(operators || ["equals", "is_not", "greater", "greater_or_equal", "lesser", "lesser_or_equal", "is_empty", "is_not_empty"]);
  }

  public static createDateField<T>(name: string, operators?: Operator[]): DateField<T> {
    return new DateField<T>()
      .withName(name)
      .withType("date")
      .withOperators(operators || [
        "is",
        "is_not",
        "is_after",
        "is_on_or_after",
        "is_before",
        "is_on_or_before",
        "is_empty",
        "is_not_empty"
      ]);
  }

  public static createBooleanField<T>(name: string, operators?: Operator[]): BooleanField<T> {
    return new BooleanField<T>()
      .withName(name)
      .withType("boolean")
      .withOperators(operators || [
        "is",
        "is_not",
        "is_empty",
        "is_not_empty"
      ])
  }

  public static createSelectField<T>(name: string, operators?: Operator[]): SelectField<T> {
    return new SelectField<T>()
      .withName(name)
      .withType("select")
      .withOperators(operators || [
        "is",
        "is_not",
        "is_empty",
        "is_not_empty"
      ])
  }

  public static createArrayField<T>(name: string, operators?: Operator[]): ArrayField<T> {
    return new ArrayField<T>()
      .withName(name)
      .withType('array')
      .withOperators(operators || [
        "includes",
        "does_not_include",
        "is_empty",
        "is_not_empty"
      ])
  }

  public static createMultiSelectField<T>(name: string, operators?: Operator[]): MultiSelectField<T> {
    return new MultiSelectField<T>()
      .withName(name)
      .withType('multi_select')
      .withOperators(operators || [
        "is_any_of",
        "is_empty",
        "is_not_empty"
      ])
  }

  public static createDurationField<T>(name: string, operators?: Operator[]): DurationField<T> {
    return new DurationField<T>()
      .withName(name)
      .withType('duration')
      .withOperators(operators || ["equals", "is_not", "greater", "greater_or_equal", "lesser", "lesser_or_equal", "is_empty", "is_not_empty"])
  }
}