import { includes, isArray, isEmpty } from "lodash";
import { Field } from "./Field";

export class ArrayField<T> extends Field<T, string> {
  public override filter(data: Array<T>, target?: string): Array<T> {
    return data.filter((el: any) => {      
      const value = this._path?.reduce((a, b) => a ? a[b] : undefined, el)
      if (this._currentOperator === "is_empty") {
        if (!value) return true;
        if (value && isEmpty(value)) return true;
        return false;
      }

      if (this._currentOperator === "is_not_empty") {
        return value && !isEmpty(value);
      }

      const targetProperty = isArray(value) ? value.map((el: string) => el.toLowerCase()) : value as Array<string>;

      if (this._currentOperator === "includes") return isEmpty(target) || (target && includes(targetProperty, target.toLowerCase()));
      if (this._currentOperator === "does_not_include") return isEmpty(target) || (target && !includes(targetProperty, target.toLowerCase()));

      return false;
    })
  }
}