import React from 'react';
import { BugReport, DirectionsWalk, DriveEta, SwitchVideo } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';
import { capitalize, SvgIcon } from '@mui/material';
import Utils from '@utils/index';
import { isArray, isEmpty } from 'lodash';

export enum TimelineEventGroupID {
  Motion = "Motion",
  Zones = "Zones",
  SafetyInfraction = "Safety infraction",
  Person = "Person",
  Vehicle = "Vehicle",
  // legacy
  SocialDistance = "Social distance",
  Fire = "Fire",
  VehicleLicensePlate = "Vehicle license plate"
}

enum TimelineEventGroupColors {
  Motion = "#2D076B",
  Zones = "#393939",
  SafetyInfraction = "#8F5D13",
  Person = "#093F27",
  Vehicle = "#07596B",
  // legacy
  SocialDistance = "#093F27",
  Fire = "#6B1507",
  VehicleLicensePlate = "#07596B"
}

enum TimelineEventsBackgroundColors {
  Motion = "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #520EBF",
  Zones = "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #676767",
  SafetyInfraction = "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FFA720",
  Person = "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #093F27",
  Vehicle = "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0E9FBF",
  // legacy
  SocialDistance = "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #093F27",
  Fire = "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #BF260E",
  VehicleLicensePlate = "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0E9FBF"
}

enum TimelineEventsBorderColors {
  Motion = "1px solid rgba(82, 14, 191, 0.5)",
  Zones = "1px solid rgba(57, 57, 57, 0.5)",
  SafetyInfraction = "1px solid rgba(255, 167, 32, 0.5)",
  Person = "1px solid rgba(16, 113, 69, 0.5)",
  Vehicle = "1px solid rgba(14, 159, 191, 0.5)",
  // legacy
  SocialDistance = "1px solid rgba(16, 113, 69, 0.5)",
  Fire = "1px solid rgba(191, 38, 14, 0.5)",
  VehicleLicensePlate = "1px solid rgba(14, 159, 191, 0.5)"
}

export const TimelineEventMap = new Map([
  ["Motion", TimelineEventGroupID.Motion],
  ["Geofence triggered", TimelineEventGroupID.Zones],
  ["Safety infraction", TimelineEventGroupID.SafetyInfraction],
  ["Person detected", TimelineEventGroupID.Person],
  ["Vehicle detected", TimelineEventGroupID.Vehicle],
  // legacy
  ["Social distancing", TimelineEventGroupID.SocialDistance],
  ["Fire detected", TimelineEventGroupID.Fire],
  ["License plate detected", TimelineEventGroupID.VehicleLicensePlate]
])

export const TimelineToBackendMap = new Map([
  [TimelineEventGroupID.Motion, "Motion"],
  [TimelineEventGroupID.Zones, "Geofence triggered"],
  [TimelineEventGroupID.SafetyInfraction, "Safety infraction"],
  [TimelineEventGroupID.Person, "Person detected"],
  [TimelineEventGroupID.Vehicle, "Vehicle detected"],
  // legacy
  [TimelineEventGroupID.SocialDistance, "Social distancing"],
  [TimelineEventGroupID.Fire, "Fire detected"],
  [TimelineEventGroupID.VehicleLicensePlate, "License plate detected"]
])

export const ColorMap = new Map([
  ["Motion", TimelineEventGroupColors.Motion],
  ["Viewpoint changed", TimelineEventGroupColors.Motion],
  ["Viewpoint changed", TimelineEventGroupColors.Motion],
  ["Geofence triggered", TimelineEventGroupColors.Zones],
  ["Safety infraction", TimelineEventGroupColors.SafetyInfraction],
  ["Person detected", TimelineEventGroupColors.Person],
  ["Vehicle detected", TimelineEventGroupColors.Vehicle],
  // legacy
  ["Social distancing", TimelineEventGroupColors.SocialDistance],
  ["Fire detected", TimelineEventGroupColors.Fire],
  ["License plate detected", TimelineEventGroupColors.VehicleLicensePlate]
])

export const BackgroundMap = new Map([
  ["Motion", TimelineEventsBackgroundColors.Motion],
  ["Viewpoint changed", TimelineEventsBackgroundColors.Motion],
  ["Geofence triggered", TimelineEventsBackgroundColors.Zones],
  ["Safety infraction", TimelineEventsBackgroundColors.SafetyInfraction],
  ["Person detected", TimelineEventsBackgroundColors.Person],
  ["Vehicle detected", TimelineEventsBackgroundColors.Vehicle],
  // legacy
  ["Social distancing", TimelineEventsBackgroundColors.SocialDistance],
  ["Fire detected", TimelineEventsBackgroundColors.Fire],
  ["License plate detected", TimelineEventsBackgroundColors.VehicleLicensePlate]
])

export const BordersMap = new Map([
  ["Motion", TimelineEventsBorderColors.Motion],
  ["Viewpoint changed", TimelineEventsBorderColors.Motion],
  ["Geofence triggered", TimelineEventsBorderColors.Zones],
  ["Safety infraction", TimelineEventsBorderColors.SafetyInfraction],
  ["Person detected", TimelineEventsBorderColors.Person],
  ["Vehicle detected", TimelineEventsBorderColors.Vehicle],
  // legacy
  ["Social distancing", TimelineEventsBorderColors.SocialDistance],
  ["Fire detected", TimelineEventsBorderColors.Fire],
  ["License plate detected", TimelineEventsBorderColors.VehicleLicensePlate]
])

class StreamEvents {
  public static extractEvents(events: VideoStreamEvent[], timeRange: { start: Date, end: Date }, highlightedEvents?: VideoStreamEvent[], renderEventsTags?: boolean) {
    const e: VideoStreamEvent[] = [];
    e.push(...StreamEvents.mergeEvents(events.filter(event => event.eventType === TimelineToBackendMap.get(TimelineEventGroupID.Motion)), timeRange))
    e.push(...StreamEvents.mergeEvents(events.filter(event => event.eventType === TimelineToBackendMap.get(TimelineEventGroupID.Zones)), timeRange))
    e.push(...StreamEvents.mergeEvents(events.filter(event => event.eventType === TimelineToBackendMap.get(TimelineEventGroupID.SafetyInfraction)), timeRange))
    e.push(...StreamEvents.mergeEvents(events.filter(event => event.eventType === TimelineToBackendMap.get(TimelineEventGroupID.Person)), timeRange))
    e.push(...StreamEvents.mergeEvents(events.filter(event => event.eventType === TimelineToBackendMap.get(TimelineEventGroupID.Vehicle)), timeRange))
    // legacy
    e.push(...StreamEvents.mergeEvents(events.filter(event => event.eventType === TimelineToBackendMap.get(TimelineEventGroupID.Fire)), timeRange))
    e.push(...StreamEvents.mergeEvents(events.filter(event => event.eventType === TimelineToBackendMap.get(TimelineEventGroupID.SocialDistance)), timeRange))
    e.push(...StreamEvents.mergeEvents(events.filter(event => event.eventType === TimelineToBackendMap.get(TimelineEventGroupID.VehicleLicensePlate)), timeRange))

    return e.map((event) => {
      if (highlightedEvents) {
        const isSelected = highlightedEvents.find(el =>
          el.sourceCamera === event.sourceCamera &&
          el.startDate === event.startDate &&
          el.endDate === event.endDate
        ) !== undefined;

        return StreamEvents.generateEvent(event, isSelected, renderEventsTags)
      }

      return StreamEvents.generateEvent(event, undefined, renderEventsTags)
    });
  }

  public static mergeEvents(events: VideoStreamEvent[], timeRange: { start: Date, end: Date }): VideoStreamEvent[] {
    const miliseconds = StreamEvents.getDaysDiference(timeRange.start, timeRange.end);
    if (miliseconds === 0) return events;

    const chunks: VideoStreamEvent[] = [];

    for (let i = 0; i < events.length; i += 1) {
      let start = events[i].startDate;
      let end = events[i].endDate;

      for (let j = i + 1; j < events.length; j += 1) {
        if (Utils.Timestamp.edgeTimestampToMiliseconds(start) - Utils.Timestamp.edgeTimestampToMiliseconds(events[j].startDate) < miliseconds) {
          start = events[j].startDate;
          i += 1;
        } else {
          break;
        }
      }

      chunks.push({
        ...events[i],
        startDate: start,
        endDate: end
      });
    }

    return chunks;
  }

  public static getDaysDiference(start: Date, end: Date): number {
    const result = end.getTime() - start.getTime();
    const days = result / 86400000;
    if (days < 0.02) {
      return 0;
    }

    const totalMillis = 86400000 * days;
    const m = totalMillis / (window.outerWidth / 10);
    return m;
  }

  public static generateEvent(event: VideoStreamEvent, isSelected: boolean = false, renderEventsTags?: boolean) {
    const start = Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate);
    const end = event.mergedEndDate || event.endDate;

    return {
      id: `${event.sourceCamera}-${event.startDate}#${uuidv4()}`,
      start: new Date(start),
      end: new Date(end),
      style: `background: ${ColorMap.get(event.eventType)};
              outline: ${isSelected ? '2px ridge rgba(0, 72, 227, 0.8)' : 'none'};
              border: none;
              height: 1rem;
              z-index: ${isSelected ? '9999' : '1'}`,
      group: TimelineEventMap.get(event.eventType),
      title: renderEventsTags && isArray(event.tags) && !isEmpty(event.tags) ? this.getTagText(event) : undefined,
      content: renderEventsTags && isArray(event.tags) && !isEmpty(event.tags) ? this.getTagText(event) : undefined
    }
  }

  public static getEventIcon(event: VideoStreamEvent, evaluateMotion: boolean = true): JSX.Element {
    const { eventType, labels } = event;
    if (eventType === 'Viewpoint changed') {
      return (<SwitchVideo />)
    }

    if (eventType === 'Motion') {
      if (evaluateMotion === false) return (<DirectionsWalk key={uuidv4()} />);

      if (labels) {
        const icons: JSX.Element[] = [];
        if (labels.find(el => el === 'person'))
          icons.push(<DirectionsWalk key={uuidv4()} />);

        if (labels.find(el => el === 'vehicle'))
          icons.push(<SvgIcon key={uuidv4()}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
              <g><g><g><path d="M563,494.3c30.1-15,147.3-16.2,182.5-16.2c45,0,89.7,1.6,129.3,4.7l0.3,0c27.9,2.4,42.6,15.5,46.5,19.5l0.9,1l45.4,51.2V203.6H333.3v78.8c-2.3,0-4.4,0-6.7,0v304.4v17.1l145.7-37.3C493.4,547.4,541.1,505.3,563,494.3z" /><path d="M318.3,589V281.8c0,0-136.1,0-179.4,0L26.8,426.6v91L10,535.2V589l65.5,0h23c-0.7,2.9-1.2,5.8-1.6,8.8H23l0.8,32.1H99c9.4,35.2,41.6,61.1,79.6,61.1c38.1,0,70.2-26,79.7-61.1h26.3h13.5l7.4-20.6l12.2-3.1v-8.4H302h-17.8h-23.8c-0.4-3-0.9-5.9-1.6-8.8h23.5H318.3L318.3,589z M178.7,648c-21.8,0-39.5-17.7-39.5-39.5c0-21.8,17.7-39.5,39.5-39.5s39.5,17.7,39.5,39.5C218.2,630.3,200.5,648,178.7,648z M78.9,421.1l75.2-104.9h130.6v104.9H78.9z" /><path d="M990,736.5l0-73.1l-16.7-52.3l-78-88c0,0-8.4-8.6-27-10.2c-124.3-9.6-273.3-2.8-295.6,8.4c-22.3,11.2-89.4,72.6-89.4,72.6l-158.4,40.6l-14.8,41.3L296.3,688l9.5,50.2H317V755h56.7c9.1,24.2,32.4,41.4,59.8,41.4c33.4,0,60.8-25.6,63.6-58.3l325.3,0c2.8,32.6,30.2,58.3,63.6,58.3c27.4,0,50.7-17.2,59.8-41.4H980v-16.8h10c0-0.5,0-0.9,0-1.4L990,736.5z M433.4,756.1c-13,0-23.6-10.6-23.6-23.6c0-13,10.6-23.5,23.6-23.5c13,0,23.6,10.6,23.6,23.5C457,745.5,446.4,756.1,433.4,756.1z M708.2,597.6H551.2l44.5-55.7c0,0,72.3-6.1,112.4-6.7L708.2,597.6L708.2,597.6z M748.5,597.6v-62.3c63.2-1.4,109.9,4.7,109.9,4.7l47,57.7L748.5,597.6L748.5,597.6z M885.9,756.1c-13,0-23.6-10.6-23.6-23.6c0-13,10.6-23.5,23.6-23.5c13,0,23.6,10.6,23.6,23.5C909.5,745.5,899,756.1,885.9,756.1z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g>
            </svg>
          </SvgIcon>);

        if (labels.find(el => el === 'tampering'))
          icons.push(<SvgIcon key={uuidv4()}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="125 80 450 450" enableBackground="new 125 80 450 450">
              <path xmlns="http://www.w3.org/2000/svg" d="m350 84c-12.426 0-23.812 6.4336-30.102 17.148l-189.18 322.88c-6.2891 10.746-6.3047 23.914 0 34.648 6.8867 11.727 19.223 17.355 31.676 17.324h375.2c12.457 0.054688 24.789-5.5977 31.676-17.324 6.3086-10.738 6.293-23.902 0-34.648l-189.18-322.88c-6.2891-10.719-17.676-17.148-30.102-17.148zm0 22.398c4.0938 0 8.8672 2.7461 10.852 6.125l189.18 322.88c1.9844 3.3867 1.9883 8.6914 0 12.074-1.4062 2.3984-8.332 6.1367-12.426 6.125h-375.2c-4.0977 0-11.02-3.7305-12.426-6.125-1.9883-3.3828-1.9844-8.6875 0-12.074l189.18-322.88c1.9844-3.3789 6.7578-6.125 10.852-6.125zm0 95.199c-12.371 0-22.398 10.027-22.398 22.398l5.6016 117.6c0 9.2773 7.5234 16.801 16.801 16.801s16.801-7.5234 16.801-16.801l5.6016-117.6c0-12.371-10.027-22.398-22.398-22.398zm0 168c-15.465 0-28 12.535-28 28s12.535 28 28 28 28-12.535 28-28-12.535-28-28-28z" />
            </svg>
          </SvgIcon>);

        if (labels.find(el => el === 'light_changed'))
          icons.push(<SvgIcon key={uuidv4()}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="50 250 600 60" enableBackground="new 50 250 600 60">
              <g xmlns="http://www.w3.org/2000/svg">
                <path d="m349.98 100.78c-87.133 0-158.04 70.926-158.04 158.08 0 33.215 10.324 65.066 29.996 92.348 1.5391 2.1016 34.738 47.266 40.688 87.816-16.52 3.7266-28.91 18.445-28.91 36.066 0 20.406 16.59 36.977 36.977 36.977h20.789v22.227c0 14.016 11.359 25.375 25.359 25.375h66.344c14 0 25.375-11.359 25.375-25.375v-22.227h20.738c20.422 0 37.031-16.574 37.031-36.977 0-17.621-12.391-32.34-28.891-36.066 5.9336-40.547 39.148-85.75 40.688-87.852l4.7422-6.3164-0.17578-0.35156c16.625-25.621 25.375-55.074 25.375-85.664-0.007812-87.129-70.898-158.06-158.08-158.06zm33.195 433.49h-66.344v-22.227h66.344zm57.75-59.199c0 6.3867-5.2344 11.602-11.656 11.602h-158.6c-6.3867 0-11.621-5.2148-11.621-11.602 0-6.4062 5.2344-11.637 11.621-11.637h158.6c6.4258-0.003906 11.656 5.2305 11.656 11.637zm17.012-139.23c-1.8008 2.3281-40.18 53.359-46.305 102.22h-123.25c-5.9336-47.023-41.738-96.129-46.078-101.92-16.379-22.699-25.008-49.438-25.008-77.281 0-73.184 59.516-132.7 132.67-132.7 73.184 0 132.7 59.516 132.7 132.7 0.015625 27.789-8.6289 54.531-24.727 76.984z" />
                <path d="m337.3 0.35156h25.375v72.957h-25.375z" />
                <path d="m176.19 67.078 51.594 51.609-17.945 17.941-51.594-51.609z" />
                <path d="m91.523 246.16h72.957v25.375h-72.957z" />
                <path d="m523.82 67.082 17.941 17.941-51.613 51.613-17.941-17.941z" />
                <path d="m535.5 246.16h72.977v25.375h-72.977z" />
              </g>
            </svg>
          </SvgIcon>);

        if (labels.find(el => el === 'infrared_mode_on'))
          icons.push(<SvgIcon key={uuidv4()}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="175 120 350 350" enableBackground="new 175 120 350 350">
              <g xmlns="http://www.w3.org/2000/svg">
                <path d="m401.16 256.09c2.0078 1.8281 4.5273 2.7305 7.0508 2.7305 2.8359 0 5.668-1.1523 7.7305-3.4141 3.8945-4.2695 3.5859-10.887-0.67578-14.781l-0.73047-0.66016c-4.3203-3.832-10.934-3.4258-14.762 0.89844-3.832 4.3242-3.4336 10.934 0.89844 14.762z" />
                <path d="m418.39 280.05c1.6875 3.9883 5.5625 6.3828 9.6367 6.3828 1.3594 0 2.7383-0.26562 4.0742-0.83203 5.3164-2.2461 7.8086-8.3867 5.5508-13.719l-0.4375-0.98438c-2.3867-5.2695-8.457-7.3477-13.723-4.9609-5.2617 2.3906-7.4766 8.8477-5.1016 14.113z" />
                <path d="m280.68 269.35c1.8516 1.3281 3.9805 1.9766 6.1055 1.9766 3.2422 0 6.457-1.5156 8.5-4.3516l0.38672-0.52734c3.457-4.6289 2.5039-11.18-2.1289-14.641-4.6094-3.4531-11.176-2.5117-14.641 2.125l-0.60156 0.82422c-3.3711 4.6797-2.3047 11.223 2.3789 14.594z" />
                <path d="m294.99 350.15c-3.3789-4.6953-9.9141-5.7461-14.602-2.375s-5.7539 9.9062-2.3828 14.598l0.48438 0.67578c2.043 2.8438 5.2539 4.3516 8.5 4.3516 2.125 0 4.2539-0.64062 6.1055-1.9766 4.6875-3.3711 5.7539-9.9062 2.3828-14.598z" />
                <path xmlns="http://www.w3.org/2000/svg" d="m374.93 240.86 0.66406 0.21484c1.0938 0.37109 2.2109 0.54297 3.3047 0.54297 4.3828 0 8.4609-2.7734 9.9219-7.1562 1.8281-5.4844-1.1328-11.41-6.6172-13.23l-0.92969-0.30859c-5.5508-1.7539-11.391 1.293-13.145 6.793-1.7461 5.5078 1.293 11.391 6.8008 13.145z" />
                <path d="m283.2 323.05c-1.1328-5.6602-6.6328-9.2695-12.301-8.168-5.668 1.1367-9.3359 6.6758-8.2031 12.352l0.19531 0.93359c1.0664 4.832 5.2695 7.9219 10.008 7.9219 0.78906 0 1.5859-0.085938 2.3945-0.26562 5.6328-1.2344 9.1406-7.1211 7.9062-12.773z" />
                <path d="m271.07 301.91c0.64063 0.12891 1.293 0.19141 1.9258 0.19141 4.9531 0 9.4258-3.6914 10.418-8.7148 1.2539-5.6445-2.3008-11.23-7.9375-12.48-5.6484-1.2266-11.23 2.2969-12.48 7.9453l-0.22656 1.0586c-1.1133 5.6602 2.6289 10.879 8.3008 12z" />
                <path d="m312 248.5c1.7656 0 3.5586-0.44922 5.207-1.3984l0.71875-0.41406c5.0039-2.8789 6.7305-9.2734 3.8516-14.281-2.8789-5.0078-9.2695-6.7461-14.281-3.8516l-0.71875 0.41406c-5.0039 2.8789-6.7305 9.2734-3.8516 14.281 1.9297 3.375 5.4492 5.25 9.0742 5.25z" />
                <path d="m344.27 237.92c0.38281 0 0.75781-0.019531 1.1445-0.0625l0.71484-0.074219c5.7539-0.55859 9.957-5.6797 9.3984-11.426-0.56641-5.7539-5.7656-9.9375-11.426-9.3906l-0.94141 0.097656c-5.7461 0.62891-9.8906 5.7852-9.2734 11.531 0.58203 5.3477 5.1172 9.3242 10.383 9.3242z" />
                <path d="m317.5 370.6-0.64062-0.375c-4.9922-2.9297-11.402-1.2539-14.32 3.7188-2.9297 4.9844-1.2617 11.398 3.7188 14.32l0.80469 0.46875c1.6484 0.94141 3.4414 1.3984 5.207 1.3984 3.625 0 7.1445-1.8828 9.082-5.25 2.8789-5.0078 1.1523-11.406-3.8516-14.281z" />
                <path d="m432.09 332.47c-5.2695-2.3828-11.457 0-13.816 5.2734l-0.32422 0.71875c-2.3828 5.2617-0.042968 11.465 5.2188 13.848 1.4023 0.63281 2.8672 0.92188 4.3086 0.92188 3.9805 0 7.7891-2.2812 9.5391-6.1484l0.36328-0.80469c2.3555-5.2578-0.015625-11.449-5.2891-13.809z" />
                <path d="m400.33 362.18c-4.3516 3.7969-4.8047 10.406-1.0078 14.758 2.0664 2.375 4.9727 3.5938 7.8906 3.5938 2.4375 0 4.8867-0.85547 6.8672-2.5781l0.80078-0.71484c4.2656-3.8945 4.3516-10.309 0.45703-14.578-3.9062-4.2656-10.73-4.3633-15.008-0.48047z" />
                <path d="m434.86 297.97c-5.7734 0-10.461 4.6875-10.461 10.461v0.94141c-0.12891 5.7773 4.457 10.566 10.227 10.688 0.078125 0 0.15234 0.007812 0.23438 0.007812 5.6758 0 10.332-4.543 10.449-10.238l0.011719-1.4023c0-5.7656-4.6836-10.457-10.461-10.457z" />
                <path d="m345.03 379.64c-5.8086-0.68359-10.949 3.4141-11.629 9.1445-0.6875 5.7422 3.4102 10.949 9.1367 11.637l1.0586 0.10938c0.34375 0.035156 0.67578 0.054687 1.0156 0.054687 5.293 0 9.582-4.0664 10.109-9.4766 0.56641-5.75-3.9375-10.902-9.6914-11.469z" />
                <path d="m375.14 376.61-0.6875 0.22656c-5.5078 1.7461-8.5469 7.6289-6.793 13.137 1.4141 4.457 5.5312 7.2969 9.9688 7.2969 1.0469 0 2.1172-0.16016 3.1758-0.49219l0.89844-0.28906c5.4883-1.8164 8.4688-7.7305 6.6602-13.211-1.8125-5.4805-7.7188-8.4727-13.223-6.668z" />
                <path d="m374.93 240.86 0.66406 0s.21484c1.0938 0.37109 2.2109 0.54297 3.3047 0.54297 4.3828 0 8.4609-2.7734 9.9219-7.1562 1.8281-5.4844-1.1328-11.41-6.6172-13.23l-0.92969-0.30859c-5.5508-1.7539-11.391 1.293-13.145 6.793-1.7461 5.5078 1.293 11.391 6.8008 13.145z" />
                <path d="m353.08 186.44c-67.762 0-122.89 55.125-122.89 122.89 0 67.762 55.125 122.88 122.89 122.88s122.89-55.121 122.89-122.88-55.129-122.89-122.89-122.89zm0 224.85c-56.223 0-101.96-45.734-101.96-101.95 0-56.227 45.742-101.97 101.96-101.97s101.96 45.742 101.96 101.97c0 56.215-45.738 101.95-101.96 101.95z" />
                <path d="m353.08 167.01c78.48 0 142.32 63.848 142.32 142.32h20.922c0-90.012-73.23-163.25-163.25-163.25-90.012 0-163.24 73.23-163.24 163.25h20.922c0.003906-78.473 63.848-142.32 142.32-142.32z" />
                <path d="m353.08 248.41c-33.281 0-60.363 27.078-60.363 60.363 0 33.27 27.078 60.352 60.363 60.352 33.273 0 60.352-27.078 60.352-60.352-0.003907-33.289-27.078-60.363-60.352-60.363zm0 99.781c-21.742 0-39.434-17.688-39.434-39.422 0-21.746 17.691-39.441 39.434-39.441 21.734 0 39.422 17.691 39.422 39.441 0 21.738-17.688 39.422-39.422 39.422z" />
              </g>
            </svg>
          </SvgIcon>);

        if (labels.find(el => el === 'infrared_mode_off'))
          icons.push(<SvgIcon key={uuidv4()}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="175 120 350 350" enableBackground="new 175 120 350 350">
              <g xmlns="http://www.w3.org/2000/svg">
                <path d="m353.08 186.44c-67.762 0-122.89 55.125-122.89 122.89 0 67.762 55.125 122.88 122.89 122.88s122.89-55.121 122.89-122.88-55.129-122.89-122.89-122.89zm0 224.85c-56.223 0-101.96-45.734-101.96-101.95 0-56.227 45.742-101.97 101.96-101.97s101.96 45.742 101.96 101.97c0 56.215-45.738 101.95-101.96 101.95z" />
                <path d="m353.08 167.01c78.48 0 142.32 63.848 142.32 142.32h20.922c0-90.012-73.23-163.25-163.25-163.25-90.012 0-163.24 73.23-163.24 163.25h20.922c0.003906-78.473 63.848-142.32 142.32-142.32z" />
                <path d="m353.08 248.41c-33.281 0-60.363 27.078-60.363 60.363 0 33.27 27.078 60.352 60.363 60.352 33.273 0 60.352-27.078 60.352-60.352-0.003907-33.289-27.078-60.363-60.352-60.363zm0 99.781c-21.742 0-39.434-17.688-39.434-39.422 0-21.746 17.691-39.441 39.434-39.441 21.734 0 39.422 17.691 39.422 39.441 0 21.738-17.688 39.422-39.422 39.422z" />
              </g>
            </svg>
          </SvgIcon>);

        if (icons.length)
          return <>{icons}</>;
      }

      return (<BugReport />);
    }

    if (eventType === "Safety infraction") {
      return (
        <SvgIcon>
          <svg width="24" height="24" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3623 2.85749C18.8378 4.12241 19.7785 6.23362 20.3366 8.59241C20.4784 9.20012 20.4491 9.85509 20.6359 10.447C20.996 11.5972 20.4671 11.9303 19.4387 12.2206C13.7533 13.8466 7.71781 13.7841 2.06735 12.0406C1.72303 11.937 1.22118 11.9438 1.29319 11.3158C1.54525 9.10333 1.87156 6.91335 3.16558 5.01371C3.76708 4.06503 4.64759 3.32593 5.68609 2.898C5.68609 3.81856 5.68609 4.64909 5.68609 5.47737C5.68609 5.82173 5.68609 6.22462 6.13619 6.22912C6.58628 6.23362 6.58628 5.83524 6.58628 5.49087C6.58628 4.86066 6.58628 4.23045 6.58628 3.60024C6.5705 3.3432 6.625 3.08671 6.74394 2.85831C6.86289 2.62991 7.04178 2.43821 7.26142 2.3038C7.54948 2.11924 7.89155 1.95494 8.20211 2.10124C8.51267 2.24753 8.34389 2.63016 8.34614 2.898C8.36189 4.75037 8.34614 6.60275 8.34614 8.45512C8.34614 8.81749 8.27187 9.32616 8.75347 9.34191C9.33634 9.35767 9.23057 8.80623 9.23057 8.4191C9.23057 6.12108 9.25758 3.82531 9.23057 1.52954C9.21257 0.750783 9.50062 0.482943 10.268 0.498699C13.2634 0.557218 12.766 0.165587 12.7998 2.90701C12.8201 4.61083 12.784 6.3169 12.8156 8.01847C12.8156 8.48438 12.5342 9.31265 13.2184 9.3104C14.0263 9.3104 13.6685 8.44611 13.6685 7.95995C13.7135 6.25613 13.6685 4.55231 13.6932 2.84849C13.6932 2.57614 13.5199 2.17326 13.8305 2.05622C14.0093 2.00467 14.198 1.99825 14.3798 2.03753C14.5617 2.0768 14.7309 2.16056 14.8725 2.2813C15.0667 2.42448 15.2226 2.61343 15.3262 2.83135C15.4299 3.04927 15.4781 3.28942 15.4666 3.53047C15.4666 4.20569 15.4666 4.86291 15.4666 5.53139C15.4666 5.8825 15.5116 6.26063 15.9527 6.23362C16.2993 6.21111 16.36 5.87125 16.3555 5.5584C16.3578 4.70986 16.3623 3.86358 16.3623 2.85749Z" fill="#8F5D13" />
            <path d="M10.9652 18.225C9.00277 18.225 7.03812 18.1867 5.07572 18.2408C4.65272 18.2647 4.23004 18.1887 3.84183 18.019C3.45362 17.8493 3.11078 17.5907 2.84101 17.2639C2.02409 16.3479 1.21168 15.4273 0.865106 14.2232C0.493781 12.9357 0.914616 12.5058 2.17037 12.8727C8.01874 14.6156 14.2517 14.5914 20.0863 12.8029C21.0562 12.5126 21.3263 12.7579 21.2498 13.7888C21.0945 15.891 18.6055 18.225 16.5238 18.225H10.9652Z" fill="#8F5D13" />
          </svg>

        </SvgIcon>
      )
    }

    if (eventType === "Fire detected") {
      return (
        <SvgIcon>
          <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0301 13.8184H14.0301V11.8184H12.0301C12.6792 10.9529 13.0301 9.90021 13.0301 8.81836C13.0411 8.14432 12.8762 7.47906 12.5518 6.88813C12.2274 6.29719 11.7547 5.80097 11.1801 5.44836C8.23012 3.44836 9.03012 0.818359 9.03012 0.818359C2.30012 4.38836 3.03012 8.28836 3.03012 8.81836C3.11993 9.92152 3.54967 10.9697 4.26012 11.8184H2.03012V13.8184H0.0301208V15.8184H16.0301V13.8184ZM7.89012 10.8184C8.25375 10.6834 8.58115 10.4659 8.84661 10.1831C9.11207 9.90029 9.30838 9.5598 9.42012 9.18836C9.46806 8.64687 9.39987 8.10139 9.22012 7.58836C9.13069 7.1623 9.15833 6.71997 9.30012 6.30836C9.84012 7.51836 11.4501 7.94836 11.3001 9.48836C11.2814 9.87875 11.1488 10.2551 10.9186 10.5709C10.6883 10.8868 10.3706 11.1282 10.0047 11.2654C9.6387 11.4027 9.24056 11.4297 8.85943 11.3431C8.4783 11.2565 8.13089 11.0602 7.86012 10.7784L7.89012 10.8184Z" fill="#BF260E" />
            <path d="M16.0301 13.8184H14.0301V11.8184H12.0301C12.6792 10.9529 13.0301 9.90021 13.0301 8.81836C13.0411 8.14432 12.8762 7.47906 12.5518 6.88813C12.2274 6.29719 11.7547 5.80097 11.1801 5.44836C8.23012 3.44836 9.03012 0.818359 9.03012 0.818359C2.30012 4.38836 3.03012 8.28836 3.03012 8.81836C3.11993 9.92152 3.54967 10.9697 4.26012 11.8184H2.03012V13.8184H0.0301208V15.8184H16.0301V13.8184ZM7.89012 10.8184C8.25375 10.6834 8.58115 10.4659 8.84661 10.1831C9.11207 9.90029 9.30838 9.5598 9.42012 9.18836C9.46806 8.64687 9.39987 8.10139 9.22012 7.58836C9.13069 7.1623 9.15833 6.71997 9.30012 6.30836C9.84012 7.51836 11.4501 7.94836 11.3001 9.48836C11.2814 9.87875 11.1488 10.2551 10.9186 10.5709C10.6883 10.8868 10.3706 11.1282 10.0047 11.2654C9.6387 11.4027 9.24056 11.4297 8.85943 11.3431C8.4783 11.2565 8.13089 11.0602 7.86012 10.7784L7.89012 10.8184Z" fill="black" fillOpacity="0.6" />
          </svg>
        </SvgIcon>
      )
    }

    if (eventType === "Social distancing") {
      return (
        <SvgIcon>
          <svg width="24" height="24" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.99883 2C1.99883 0.9 2.89883 0 3.99883 0C5.09883 0 5.99883 0.9 5.99883 2C5.99883 3.1 5.09883 4 3.99883 4C2.89883 4 1.99883 3.1 1.99883 2ZM6.77906 5.58C5.92917 5.21 4.98929 5 3.99942 5C3.00955 5 2.06967 5.21 1.21978 5.58C0.479876 5.9 -6.10352e-05 6.62 -6.10352e-05 7.43V8H7.9989V7.43C7.9989 6.62 7.51896 5.9 6.77906 5.58ZM15.9974 4C17.0974 4 17.9974 3.1 17.9974 2C17.9974 0.9 17.0974 0 15.9974 0C14.8974 0 13.9974 0.9 13.9974 2C13.9974 3.1 14.8974 4 15.9974 4ZM18.7768 5.58C17.9269 5.21 16.987 5 15.9972 5C15.0073 5 14.0674 5.21 13.2175 5.58C12.4776 5.9 11.9977 6.62 11.9977 7.43V8H19.9966V7.43C19.9966 6.62 19.5167 5.9 18.7768 5.58ZM19.9973 14L15.9979 10V13H3.99942V10L-6.10352e-05 14L3.99942 18V15H15.9979V18L19.9973 14Z" fill="#093F27" />
          </svg>

        </SvgIcon>
      )
    }

    if (eventType === "Geofence triggered") {
      return (
        <SvgIcon>
          <svg width="24" height="24" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.26136 9.1533L0.970736 8.19311L15.3447 0.721002C15.8732 0.907194 16.7222 1.21541 17.48 1.49221C17.9111 1.64969 18.3113 1.79647 18.6038 1.90388L18.952 2.03187L19.0474 2.06699L19.0722 2.07616L19.0786 2.07849L19.0802 2.07908L19.0806 2.07923L19.0807 2.07926L19.2536 1.61011L19.0807 2.07928L19.0824 2.0799L19.0825 2.07993L19.0827 2.07999L19.0837 2.08037L19.09 2.08272L19.1179 2.09322C19.1429 2.10276 19.1803 2.11729 19.228 2.13648C19.3234 2.17491 19.4592 2.23173 19.6176 2.30429C19.9377 2.45095 20.3357 2.65524 20.6792 2.89456C21.0398 3.14572 21.2644 3.38398 21.3413 3.57452C21.3736 3.65452 21.3749 3.71345 21.36 3.76743C21.3445 3.82378 21.2993 3.91691 21.1678 4.04025L10.5461 11.3224H8.0751L2.26136 9.1533Z" stroke="#0048E3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

        </SvgIcon>
      )
    }


    if (eventType === "Vehicle detected") {
      return (
        <DriveEta />
      )
    }

    if (eventType === "License plate detected") {
      return (
        <DriveEta />
      )
    }

    if (eventType === "Person detected") {
      return (
        <DirectionsWalk />
      )
    }

    return (
      <DirectionsWalk />
    )
  }

  public static getCompanyAndSite(event: VideoStreamEvent, companies: Company[], sites: Map<string, Site[]>): { company: Company | null, site: Site | null } {
    const targetCompany = companies.find(company => company.companyId === event.companyId);
    if (targetCompany) {
      const companySites = sites.get(targetCompany.companyId);
      if (companySites) {
        const targetSite = companySites.find(el => el.siteId === event.siteId);
        if (targetSite) {
          return { company: targetCompany, site: targetSite };
        }
      }
    }
    return { company: null, site: null };
  }

  public static getStreamName(event: VideoStreamEvent, streams: VideoStream[]): string {
    const stream = streams.find(el => el.kvsName === event.sourceCamera);
    return stream?.defaultName || stream?.kvsName || "unknown"
  }

  public static getSiteName(event: VideoStreamEvent, companies: Company[], sites: Map<string, Site[]>): string {
    const { site } = StreamEvents.getCompanyAndSite(event, companies, sites);
    if (site) {
      return site.name;
    }

    return "Unknown";
  }

  public static getTitle(event: VideoStreamEvent, zoneNames?: string[]): string {
    if ((event.eventType === "Geofence triggered" || event.eventType === "Zone triggered") && zoneNames) {
      if (zoneNames.length > 1) {
        return `${zoneNames.length} zones triggered`;
      }

      return `${zoneNames} triggered`;
    }

    const TYPE_TO_TITLE = new Map([
      ["Motion", "Motion detected"],
      ["Safety infraction", "Safety infraction detected"],
      ["Geofence triggered", "Zone triggered"],
      ["Zone triggered", "Zone triggered"],
      ["Person detected", "Person detected"],
      ["Vehicle detected", "Vehicle detected"],
      // legacy
      ["Social distancing", "Social distancing detected"],
      ["Fire detected", "Fire detected"],
      ["License plate detected", "License plate detected"],
      ["Licence plate detected", "License plate detected"]
    ]);

    if (isArray(event.children) && !isEmpty(event.children)) {
      return `${event.children.length + 1} ${TYPE_TO_TITLE.get(event.eventType) || "Unknown"}`
    }
    return TYPE_TO_TITLE.get(event.eventType) || "Unknown";
  }

  public static getDescription(event: VideoStreamEvent, companies: Company[], sites: Map<string, Site[]>, streams: VideoStream[]): string {
    const { company, site } = StreamEvents.getCompanyAndSite(event, companies, sites);
    const stream = streams.find(el => el.kvsName === event.sourceCamera);
    return `${StreamEvents.getEventType(event)} on ${stream?.defaultName || stream?.kvsName || "unknown"} camera at ${company?.name || "unknown"} ${site?.name || 'unknown'}.`
  }

  public static getEventType(event: VideoStreamEvent): string {
    if (event.eventType === "Geofence triggered") {
      return 'Zone triggered';
    }

    return event.eventType;
  }

  /**
   * Return the labels of event as a human formatted string.
   * @param event VideoStreamEvent
   * @returns string
   * @example
   * const event = { labels: ['helmet', 'vehicle'] };
   * const result = StreamEvents.getLabels(event);
   * console.log(result); // => "Helmet, vehicle."
   */
  public static getLabels(event: Partial<VideoStreamEvent>): string {
    if (event.labels) {
      const { labels } = event;

      const capitalized = labels.map((label: string, index: number) => {
        if (index === 0) {
          return capitalize(label.replace('_', ' '));
        }
        return label.replace('_', ' ');
      })

      return `${capitalized.join(', ')}.`;
    }

    return "None.";
  }

  public static getTagText(event: { tags?: EventTag[] }): string {
    if (event.tags && event.tags[0]) {
      return Utils.StreamEvent.getTagText(event.tags[0])
    }

    return "None"
  }
}

export default StreamEvents;
