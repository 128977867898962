import { AssetsPermissions, PermissionType } from "@store/session/types";
import isUserAbleToInvite from "./isUserAbleToInvite";
import userHasPermission from "./userHasPermission";

export interface IPermissions {
  isUserAbleToInvite(user?: User): boolean;
  userHasPermission(assetPermissions: AssetsPermissions, permission: PermissionType, siteId?: string, cameraId?: string): boolean;
}

const Permissions: IPermissions = {
  isUserAbleToInvite,
  userHasPermission
}

export default Permissions;
