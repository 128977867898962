function addCameraIcon(map : mapboxgl.Map, id : string): void{
  if(!map.getLayer(id)){
      map.addLayer({id : id,
          type : "symbol",
          source : id,
          layout: {
              "icon-image": 'camera',
              "icon-size": 1
          }
      });
  }
}

export default addCameraIcon;