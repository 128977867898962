import { IGetStreamMetrics } from "@API/cloudwatch";
import { IGetStreamPTZEvents, IGetStreamUptime } from "@API/streams";
import { ICredentials } from "@store/session/types";
import Utils from "@utils/index";
import { CloudWatch } from "aws-sdk";
import { first, isArray, isEmpty, last } from "lodash";

/**
 * This class represents all logic of the thin black line on the Timeline View over the dashboard.
 * It currently builds the uptime window, as a black line.
 * As well as the PTZ colored feedback.
 */
export class StreamUptime {
  private _stream: VideoStream | VideoStream[];
  private _credentials: ICredentials;
  private _streamMetricsRepository: IGetStreamMetrics;
  private _streamRepository: IGetStreamPTZEvents & IGetStreamUptime;

  constructor (
    stream: VideoStream | VideoStream[],
    credentials: ICredentials,
    streamMetricsRepository: IGetStreamMetrics,
    streamRepository: IGetStreamPTZEvents & IGetStreamUptime
  ) {
    this._stream = stream;
    this._credentials = credentials;
    this._streamMetricsRepository = streamMetricsRepository;
    this._streamRepository = streamRepository;
  }

  /**
   * Main entry method, build the data necessary to represent the uptime over the Timeline.
   */
  public async build(): Promise<Array<VideoStreamTimeChunk>> {
    try {
      const start: Date = new Date(Utils.Stream.calculateRetentionTimestamp(this._stream, Date.now()));
      const end: Date = new Date();

      const timeChunks: VideoStreamTimeChunk[] = await this.getStreamUptime();
      const ptzTimeChunks = await this.injectPtzUptime(start, end, timeChunks)

      return ptzTimeChunks;
    } catch (error) {
      throw error;
    }
  }

  /**
   * This method first tries to load the uptime from our own AWS Backend, if it fails or is empty.
   * This method will then use CloudWatch as a fallback option to get the metrics.
   */
  private async getStreamUptime(): Promise<VideoStreamTimeChunk[]> {
    try {
      const stream = isArray(this._stream) ? first(this._stream) : this._stream;

      if (stream) {
        const uptime: UptimeEvent[] = await this._streamRepository.getStreamUptime(stream);
        const chunks = this.buildUptimeFromUptimeEvents(uptime)
        if (!isEmpty(chunks)) return chunks;
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const { streamMetrics } = await this.getStreamMetrics();
      return await this.getTimechunks(streamMetrics);
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  /**
   * Turns the data from our AWS Backend into a timeline's readable structure.
   */
  private buildUptimeFromUptimeEvents(uptime: UptimeEvent[]): VideoStreamTimeChunk[] {
    try {
      const chunks: VideoStreamTimeChunk[] = [];

      for (let i = 0; i < uptime.length; i += 1) {
        if (uptime[i].status === "offline" && i === 0) {
          const start: Date = new Date(Utils.Stream.calculateRetentionTimestamp(this._stream, Date.now()));
          const chunk: VideoStreamTimeChunk = new TimeChunkBuilder()
            .withKvsName(uptime[i].src)
            .withTimeRange(start, new Date(uptime[i].timestamp || Date.now()))
            .build()
          chunks.push(chunk)
        }

        if (uptime[i].status === "online") {

          const offline = uptime.find((el, index) => {
            if (index <= i) return false;
            if (el.status === "offline") {
              return true;
            }

            return false;
          })

          const chunk: VideoStreamTimeChunk = new TimeChunkBuilder()
            .withKvsName(uptime[i].src)
            .withTimeRange(new Date(uptime[i].timestamp), new Date(offline?.timestamp || Date.now()))
            .build()

          chunks.push(chunk)
        }
      }

      return chunks;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Inject into the uptime data, the same representation but as colored feedback for each different view.
   */
  private async injectPtzUptime(start: Date, end: Date, timeChunks: VideoStreamTimeChunk[]) {
    try {
      if (this.isSingleStream(this._stream)) {
        const data = await this._streamRepository.getStreamPTZEvents(
          isArray(this._stream) ? first(this._stream)?.kvsName || "" : this._stream.kvsName,
          start.getTime(),
          end.getTime()
        )

        return new PTZUptimeConcatenator(data).concatWith(timeChunks);
      }

      return timeChunks;
    } catch (error) {
      console.log(error)
      return timeChunks;
    }
  }

  /**
   * Turns CloudWatch's data structure into a readable data structure for the Timeline.
   */
  private async getTimechunks(streamMetrics: CloudWatch.GetMetricDataOutput): Promise<VideoStreamTimeChunk[]> {
    const streamTimestamps =
      (streamMetrics.MetricDataResults &&
        streamMetrics.MetricDataResults[0].Timestamps) ||
      [];

    return Utils.CloudWatch.getStreamTimeChunks(isArray(this._stream) ? this._stream[0] : this._stream, streamTimestamps);
  }

  /**
   * Fetches the metrics from CloudWatch's API for Kinesis Video Streams.
   */
  private async getStreamMetrics(): Promise<{ start: Date, end: Date, streamMetrics: CloudWatch.GetMetricDataOutput }> {
    const options = {
      credentials: this._credentials,
      region: process.env.REACT_APP_KINESIS_VIDEO_STREAMS_REGION
    };


    try {
      const start: Date = new Date(Utils.Stream.calculateRetentionTimestamp(this._stream, Date.now()));
      const end: Date = new Date();

      const streamMetrics = await this._streamMetricsRepository.getStreamMetrics(
        isArray(this._stream) ? this._stream.map(el => el.kvsName) : this._stream.kvsName,
        options,
        start,
        end
      );

      return { start, end, streamMetrics }
    } catch (error) {
      throw error;
    }
  }

  /**
   * Assert if the stream property of the class is a single or array.
   * This might be necessary because for compatibility reasons, we accept
   * an array in the constructor.
   * @param stream 
   * @returns 
   */
  private isSingleStream(stream: VideoStream | VideoStream[]): boolean {
    return !isArray(stream) || (isArray(stream) && stream.length === 1)
  }
}

/**
 * Concatenate PTZ uptime data into regular uptime data.
 */
export class PTZUptimeConcatenator {
  private _ptzEvents: PTZEvent[] = [];

  private _possibleColors: Array<string> = [
    '#B3E4FF',
    '#FCB3B2',
    '#FDECB3',
    '#B3B3FF'
  ]

  constructor (ptzEvents: PTZEvent[]) {
    this._ptzEvents = ptzEvents
  }

  /**
   * Concat the PTZ data with the regular uptime.
   */
  public concatWith(currentUptime: VideoStreamTimeChunk[]): Array<VideoStreamTimeChunk> {
    try {
      if (this._ptzEvents.length === 0) return currentUptime;

      const chunks: VideoStreamTimeChunk[] = [...currentUptime];

      const uniqueOrientationIdsSet = new Set();
      this._ptzEvents.forEach((el) => uniqueOrientationIdsSet.add(el.orientationId));
      const uniqueOrientationIds = Array.from(uniqueOrientationIdsSet);

      const colors = new Map();

      uniqueOrientationIds.forEach((el, index) => {
        colors.set(el, this._possibleColors[index]);
      })

      this._ptzEvents.forEach((event, index) => {
        const { src, orientationId } = event;
        const start = new Date(event.eventTime)
        const end = this._ptzEvents[index + 1] ? new Date(this._ptzEvents[index + 1].eventTime + 100) : last(currentUptime)?.end || new Date(Date.now());

        const chunk = new TimeChunkBuilder()
          .withKvsName(src)
          .withTimeRange(start, end)
          .withPtz(colors.get(orientationId), orientationId)
          .build()
        chunks.push(chunk);
      })

      return chunks;
    } catch (error) {
      console.log(error);
      return currentUptime;
    }
  }

  public pushPtzEvent(currentUptime: VideoStreamTimeChunk[], ptzEvent: PTZEvent): Array<VideoStreamTimeChunk> {
    try {
      const chunks: VideoStreamTimeChunk[] = [...currentUptime];

      const { src, orientationId } = ptzEvent;
      const start = new Date(ptzEvent.eventTime)
      const end = new Date(ptzEvent.eventTime + 1000);

      const chunk = new TimeChunkBuilder()
        .withKvsName(src)
        .withTimeRange(start, end)
        .withPtz(this._possibleColors[Number(orientationId)], orientationId)
        .build()
      const lastEvent = last(chunks);
      if (lastEvent) lastEvent.end = start;

      chunks.push(chunk);
      return chunks;
    } catch (error) {
      console.log(error);
      return currentUptime;
    }
  }
}

/**
 * Builder class to allow for a more readable aporach to creating the timeline's data objects.
 */
class TimeChunkBuilder {
  private _period: number = 300;
  private _kvsName: string = "";
  private _start: Date = new Date();
  private _end: Date = new Date();
  private _color: string | undefined;
  private _positionName: string | undefined;

  public withKvsName(kvsName: string): TimeChunkBuilder {
    this._kvsName = kvsName;
    return this;
  }

  public withTimeRange(start: Date, end: Date): TimeChunkBuilder {
    this._start = start;
    this._end = end;
    return this;
  }

  public withPtz(color: string, positionName: string): TimeChunkBuilder {
    this._color = color;
    this._positionName = positionName;
    return this;
  }

  public build(): VideoStreamTimeChunk {
    return {
      period: this._period,
      id: `${this._kvsName}_${this._positionName || ''}_${this._start.getTime()}`,
      kvsName: this._kvsName,
      start: this._start,
      end: this._end,
      color: this._color,
      positionName: this._positionName
    }
  }
}