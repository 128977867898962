function getDifferenceInSeconds(startTime: number, endTime: number): string | undefined {
  if (startTime && endTime) {
    let durationSec = Math.floor((endTime - startTime) / 1000);
    const durationMin = Math.floor(durationSec / 60);
    durationSec = durationSec - (60 * durationMin);
    if (durationMin)
      return durationSec ? `${durationMin}m ${durationSec}s` : `${durationMin}m`;
    else
      return `${durationSec}s`
  }
}

export default getDifferenceInSeconds;
