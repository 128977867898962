import React from 'react';
import useSetActivePage from '@hooks/useSetActivePage';
import { PageView } from '@store/session/types';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { NavBar } from '@components/common/NavBar/NavBar';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { MapTabs, OverviewTabs } from '@store/views/overviewView/types';
import { useSearchParams } from 'react-router-dom';
import useUpdateSites from '@hooks/useUpdateSites';
import Loading from '@components/common/Loading';
import { fetchAllSitesStreams } from '@hooks/Streams/ReduxStreamHooks';
import useRenderOnWindowResize from '@hooks/useRenderOnWindowResize';
import MapContent from '@components/map/MapContent';

const TAB_PARAM = "tab";

const MapView: React.FC<{}> = () => {
  useSetActivePage(PageView.Map);
  useRenderOnWindowResize();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabAsserted, setTabAsserted] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [activeTab, setActiveTab] = React.useState<"site_map">("site_map")

  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const loadingSites = useUpdateSites(selectedCompany?.companyId);

  const changeSearchParams = React.useCallback((key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleNavbarOptionSelection = React.useCallback((tab: string) => {
    if (searchParams.get(TAB_PARAM) !== tab) {
      changeSearchParams(TAB_PARAM, tab)
    }
  }, [changeSearchParams, searchParams])

  const isValidTab = React.useCallback((tab: string) => {
    return tab === "site_map"
  }, [])

  const processActiveTab = React.useCallback(() => {
    const tab = searchParams.get(TAB_PARAM);
    if (tab && isValidTab(tab)) {
      setActiveTab(tab as MapTabs);
    }

    setTabAsserted(true);
  }, [searchParams, isValidTab])

  const tabs = React.useMemo(() => {
    return [
      {
        value: OverviewTabs.SiteMap.toString(),
        onClick: () => { handleNavbarOptionSelection("site_map") },
        label: "Site map",
        dataCy: "overview-site-map-tab"
      }
    ]
  }, [handleNavbarOptionSelection])

  React.useEffect(() => {
    if (loadingSites === false) {
      fetchAllSitesStreams()
        .then(() => {
          setLoading(false);
        })
    }
  }, [loadingSites])

  React.useEffect(processActiveTab, [processActiveTab])

  if (!tabAsserted) return null;

  return (
    <Box width="100%" height="100%" display="grid" gridTemplateRows="min-content auto">
      <Helmet title="Forsight | Map" />
      <NavBar
        title="Map"
        selectedValue={activeTab.toString()}
        tabs={tabs}
      />
      {loading === true &&
        <Loading />
      }
      {loading === false &&
        <MapContent activeTab={activeTab as MapTabs} />
      }
    </Box>
  )
}


export default MapView;
