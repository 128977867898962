import { ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface AppBarButtonProps {
  open: boolean,
  onClick?: (event?: any) => void,
  icon: JSX.Element,
  text?: string,
  selected?: boolean,
  disabled?: boolean,
  dataCy?: string
}

const Wraper = styled.div<{ selected?: boolean }>`
  svg {
    color: ${props => props.selected ? props.theme.palette.primary.main : 'black'};
    fill:${props => props.selected ? props.theme.palette.primary.main : 'black'};
  }

  .MuiTypography-root {
    color: ${props => props.selected ? props.theme.palette.primary.main : 'black'}
  }
`

const AppBarButton: React.FC<AppBarButtonProps> = (props) => {
  const { open, onClick, icon, text, selected, disabled, dataCy } = props;

  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const [hovered, setHovered] = React.useState<boolean>(false);

  const hoveredRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    hoveredRef.current = hovered;
  }, [hovered]);

  function handleMouseEnter() {
    setHovered(true);

    setTimeout(() => {
      if (hoveredRef.current === true) {
        setTooltipOpen(true);
      }
    }, 200)
  }

  function handleMouseLeave() {
    setHovered(false);
    setTooltipOpen(false);
  }

  if (open === true) return (
    <Wraper selected={selected}>
      <ListItem button onClick={onClick} disabled={disabled}>
        <ListItemIcon data-cy={dataCy}>
            {icon}
        </ListItemIcon>
        <ListItemText>
          <Typography>{text}</Typography>
        </ListItemText>
      </ListItem>
    </Wraper >
  )

  return (
    <Wraper selected={selected}>
      <ListItem button onClick={onClick} disabled={disabled}>
        <ListItemIcon data-cy={dataCy} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Tooltip open={tooltipOpen} arrow title={text || ""} placement="right">
              {icon}
          </Tooltip>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ variant: "body1" }} primary={text} style={{ paddingLeft: ""}} />
      </ListItem>
    </Wraper>
  )
}

export default AppBarButton;
