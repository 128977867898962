import { PrometheusQuery } from "./PrometheusQuery";

export class InferenceRequestSuccess extends PrometheusQuery {
  protected _id: string = "calculated:edge_inference_request_success_total";

  public override getPossibleValues() {
    return [
      { id: "face_detection_s", color: "#0000FF" },
      { id: "helmet_classification_s", color: "#FF0000" },
      { id: "licence_plate_detection_s", color: "#7F00FF" },
      { id: "person_detection_s", color: "#000080" },
      { id: "person_reid_s", color: "#FFA500" },
      { id: "plate_recognizer_detection_s", color: "#008000" },
      { id: "pose_estimation_s", color: "#000000" },
      { id: "vehicle_detection_s", color: "#808080" }
    ]
  };

  public override getRefinedData() {
    const refinedData = [];

    if (this.isEmpty()) return [];

    for (let i = 0; i < this._queryData.data.result[0].values.length; i += 1) {
      const values: number[] = [];

      for (let j = 0; j < 8; j += 1) {
        try {
          values.push(Math.floor(+this._queryData.data.result[j]?.values[i][1]))
        } catch (e) {
          values.push(0)
        }
      }

      refinedData.push({
        date: this.formatDate(this._queryData.data.result[0].values[i][0]),
        "face_detection_s": values[0],
        "helmet_classification_s": values[1],
        "licence_plate_detection_s": values[2],
        "person_detection_s": values[3],
        "person_reid_s": values[4],
        "plate_recognizer_detection_s": values[5],
        "pose_estimation_s": values[6],
        "vehicle_detection_s": values[7],
      })
    }

    return refinedData;
  }

  public override getDomain() {
    if (this.isEmpty()) return [0, 100]
    return undefined;
  }

  public override getMiddleValue() {
    if (this.isEmpty()) return 50;
    return undefined;
  }

  public override getValueLabel() {
    return "";
  }
}