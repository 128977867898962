import Utils from '@utils/index';

function findPreviousItem(items: { timestamp: number }[], timestamp: number, maxRange?: number): object | undefined {
  const lesserEvents = items.filter(element => {
    if (maxRange) return element.timestamp < timestamp - 10 && element.timestamp > timestamp - maxRange
    else return element.timestamp < timestamp - 10
  });

  return Utils.Timestamp.findClosestTimestamp(lesserEvents, timestamp);
}

export default findPreviousItem;
