import { VolumeDown, VolumeMute, VolumeOff, VolumeUp } from '@mui/icons-material';
import { Box, IconButton, Slider, Tooltip } from '@mui/material';
import React from 'react';

type PlayerVolumeProps = {
  volume: number;
  onVolumeChange: (value: number) => void;
  disabled: boolean;
}

const PlayerVolume: React.FC<PlayerVolumeProps> = (props) => {
  const { volume, onVolumeChange, disabled } = props;

  const [previousBeforeMute, setPreviousBeforeMute] = React.useState<number>(100);

  const onIconClick = React.useCallback(() => {
    if (volume === 0) onVolumeChange(previousBeforeMute)
    else {
      setPreviousBeforeMute(volume)
      onVolumeChange(0)
    }
  }, [onVolumeChange, previousBeforeMute, volume])

  const currentIcon = React.useMemo(() => {
    if (volume === 0) return (<VolumeOff />)
    if (volume >= 1 && volume <= 30) return (<VolumeMute />)
    if (volume > 30 && volume < 60) return (<VolumeDown />)
    return (<VolumeUp />)
  }, [volume])

  return (
    <Box
      display="grid"
      gap="1rem"
      marginRight="1rem"
      gridTemplateColumns="min-content min-content"
      justifyContent="center"
      alignItems="center"
    >
      <IconButton
        disabled={disabled}
        size='small'
        onClick={onIconClick}
      >
        {currentIcon}
      </IconButton>
      <Tooltip disableInteractive arrow placement="right" title="Volume">
        <Slider
          disabled={disabled}
          onChange={(_e: any, value: any) => onVolumeChange(value)}
          step={1}
          color="secondary"
          value={volume}
          max={100}
          min={0}
          style={{ width: '4rem' }}
        />
      </Tooltip>
    </Box>
  )
}

export default PlayerVolume;
