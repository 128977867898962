import { API } from "aws-amplify";
import getWorker from "./getWorker";
import createAction from '../actions/createAction';
import { first } from "lodash";

export const MOCK_BLACKLIST_REPOSITORY: Map<string, BlacklistDetails> = new Map();
export const MOCK_BLACKLIST_ACCESS_ATTEMPTS_REPOSITORY: Map<number, Array<BlacklistedAccessAttempt>> = new Map();

/**
 * Parameters for notifications when a blacklisted worker attempt to enter a site.
 * @channels Array containing keywords SMS or EMAIL, in order to notify by these channels, both options can be present.
 * @users Array containing userIds to be notified by the channels of this notification.
 * @roles Array containing roleIds to be notified by the channels of this notification.
 */
export type NotificationsParameters = {
  channels: Array<"sms" | "email">;
  users?: Array<string>;
  roles?: Array<string>;
  streams?: Array<string>;
}

export default async function blacklistWorker(
  details: BlacklistDetails,
  notifications: NotificationsParameters,
  asCompany?: string
): Promise<CrewWorker> {
  try {
    const { workerId } = details;
    const path: string = `/workers/${workerId}/blacklist` + (asCompany ? `?asCompany=${asCompany}` : "");

    console.log('Creating actions....');

    await createAction(details.siteId, {
      id: "",
      filterText: "",
      active: true,
      notifyWhenOnline: false,
      sentence: [
        {
          value: notifications.users,
          key: "User",
          label: ""
        },
        {
          value: first(notifications.roles) || "",
          key: "Role",
          label: ""
        },
        {
          value: {
            "Monday": [
              {
                from: "00:00",
                to: "23:59"
              }
            ],
            "Tuesday": [
              {
                from: "00:00",
                to: "23:59"
              }
            ],
            "Wednesday": [
              {
                from: "00:00",
                to: "23:59"
              }
            ],
            "Thursday": [
              {
                from: "00:00",
                to: "23:59"
              }
            ],
            "Friday": [
              {
                from: "00:00",
                to: "23:59"
              }
            ],
            "Saturday": [
              {
                from: "00:00",
                to: "23:59"
              }
            ],
            "Sunday": [
              {
                from: "00:00",
                to: "23:59"
              }
            ]
          },
          key: "TimeRange",
          label: ""
        },
        {
          key: "EventType",
          label: "",
          value: [
            {
              "type": "Person detected",
              filters: {
                workerId: {
                  operator: 'includes',
                  value: details.workerId
                }
              },
            }
          ]
        },
        {
          value: notifications.streams || [],
          key: "Asset",
          label: ""
        },
        {
          value: notifications.channels.map((el) => ({ type: el, destionations: [] })) || [],
          key: "NotificationChannel",
          label: ""
        }
      ]
    })

    console.log('Blacklisting worker...');

    await API.post('api-service', path, {
      body: {
        timestamp: details.timestamp,
        reason: details.reason,
        siteId: details.siteId
      }
    })

    const worker = await getWorker(workerId, asCompany);
    worker.blacklisted = 'blacklisted';

    return worker;
  } catch (error) {
    throw error;
  }
}