function includeFeature(streams: { features: any }[], feature: string) {
  const target = streams.find(el => {
    if (el.features) {
      return el.features[feature] === true;
    }

    return false;
  });
  return target !== undefined;
}

export default includeFeature;
