import React from 'react';
import useSetActivePage from '@hooks/useSetActivePage';
import { PageView } from '@store/session/types';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { NavBar } from '@components/common/NavBar/NavBar';
import { CompaniesTabs } from '@store/views/overviewView/types';
import { useSearchParams } from 'react-router-dom';
import Loading from '@components/common/Loading';
import useRenderOnWindowResize from '@hooks/useRenderOnWindowResize';
import CompaniesContent from '@components/companies/CompaniesContent';
import API from '@API/index';
import { setCompanies } from '@store/companies/actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';

const TAB_PARAM = "tab";

const CompaniesView: React.FC<{}> = () => {
  useSetActivePage(PageView.Companies);
  useRenderOnWindowResize();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabAsserted, setTabAsserted] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [activeTab, setActiveTab] = React.useState<CompaniesTabs>("companies_list" as CompaniesTabs)

  const changeSearchParams = React.useCallback((key: string, value: string) => {
    searchParams.set(key, value)
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleNavbarOptionSelection = React.useCallback((tab: string) => {
    if (searchParams.get(TAB_PARAM) !== tab) {
      changeSearchParams(TAB_PARAM, tab)
    }
  }, [changeSearchParams, searchParams])

  const isValidTab = React.useCallback((tab: string) => {
    return tab === "companies_list"
  }, [])

  const processActiveTab = React.useCallback(() => {
    const tab = searchParams.get(TAB_PARAM);
    if (tab && isValidTab(tab)) {
      setActiveTab(tab as CompaniesTabs);
    }

    setTabAsserted(true);
  }, [searchParams, isValidTab, setActiveTab])

  const tabs = React.useMemo(() => {
    return [
      {
        value: CompaniesTabs.Companies.toString(),
        onClick: () => { handleNavbarOptionSelection("companies_list") },
        label: "List",
        dataCy: "companies-list-tab"
      }
    ]
  }, [handleNavbarOptionSelection])

  React.useEffect(() => {
    API.Company.listCompanies()
      .then((companies) => {
        dispatch(setCompanies(companies));
      })
      .catch((error) => {
        enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' })
      })
      .finally(() => {
        setLoading(false);
      })
  }, [dispatch, enqueueSnackbar])

  React.useEffect(processActiveTab, [processActiveTab])

  if (!tabAsserted) return null;

  return (
    <Box width="100%" height="100%" display="grid" gridTemplateRows="min-content auto">
      <Helmet title="Forsight | Companies" />
      <NavBar
        title="Companies"
        selectedValue={activeTab.toString()}
        tabs={tabs}
      />
      {loading === true &&
        <Loading />
      }
      {loading === false &&
        <CompaniesContent activeTab={activeTab} />
      }
    </Box>
  )
}


export default CompaniesView;
