import { first, isEmpty } from "lodash";

class HttpSynchronize {
  public requests: Array<() => Promise<void>> = [];
  public isProcessing: boolean = false;

  public add(request: () => Promise<void>) {
    this.requests.push(request);
    if (this.isProcessing === false) this.next();
  }

  public async next() {
    if (this.isProcessing === true) return;

    this.isProcessing = true;

    const request = first(this.requests);

    if (request) {
      try {
        await request()
      } catch (e) { }
    }

    this.requests.splice(0, 1);
    this.isProcessing = false;
    if (!isEmpty(this.requests)) this.next();
  }
}

export default HttpSynchronize;