import { API } from "aws-amplify";

async function createTrade(form: AddTradeForm, asCompany?: string): Promise<Trade> {
  try {
    const path: string = '/trades' + (asCompany ? `?asCompany=${asCompany}` : "");

    const options = {
      body: {
        ...form
      }
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    return data.data as Trade;
  } catch (error: any) {
    throw error;
  }
}

export default createTrade;
