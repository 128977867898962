import LoadingContent from '@components/overview/LoadingContent';
import { Box } from '@mui/material';
import React from 'react';

const GridLoadingOverlay: React.FC<{}> = () => {
  return (
    <Box width="100%" height="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center" style={{ background: "white" }}>
      <LoadingContent message='Loading' />
    </Box>
  )
}

export default GridLoadingOverlay;
