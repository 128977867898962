import deleteSite from "./deleteSite";
import listSites from "./listSites";
import postNewSite from "./postNewSite";
import updateSite from "./updateSite";
import partialUpdateSite from "./updateSiteName";

export interface ISiteAPI {
  postNewSite(site: Site): Promise<Site>;
  updateSite(site: Site): Promise<Site>;
  listSites(companyId: string): Promise<Site[]>;
  partialUpdateSite(companyId: string, siteId: string, name: string, tags?: string[]): Promise<Site>;
  deleteSite(companyId: string, siteId: string): Promise<void>;
}

const Site: ISiteAPI = {
  postNewSite,
  updateSite,
  listSites,
  partialUpdateSite,
  deleteSite
}

export default Site;
