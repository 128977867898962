import PubSub from "./PubSub";
import API from '@API/index';

class PubSubMutedStreams extends PubSub {
  public connect(): void {
    const topicName: string = `${process.env.REACT_APP_STACK}/streams/state/changes`;
    this.subscription = API.PubSub.subscribeToTopic(topicName, this.onMessage, this.onError, this.onComplete);

    this.interval = setInterval(() => { this.checkSubscriptionHealth() }, 15000);
  }
}

export default PubSubMutedStreams;
