import API from '@API/index';
import Utils from '@utils/index';
import { MapboxStyleSwitcherControl } from '../include/mapbox-gl-style-switcher/MapBoxGlStyleSwitcherControl';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import VideoBoundingBoxes from '@classes/VideoBoundingBoxes';
import { useSnackbar } from 'notistack';
import { matrix, multiply } from 'mathjs';
import useSetActivePage from '@hooks/useSetActivePage';
import { PageView } from '@store/session/types';

type SatelliteViewProps = {

}

const FOV_LINES_CANVAS_ID = 'fov-line-source';
const FOV_LINES_LAYER_LINE_ID = 'fov-line-layer';

const SatelliteView: React.FC<SatelliteViewProps> = () => {
  const { enqueueSnackbar } = useSnackbar();

  useSetActivePage(PageView.Satellite);

  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const selectedSite = useSelector((store: IStoreState) => store.selectedSites.selectedSite);

  const [boundingBoxes, setBoundingBoxes] = React.useState<VideoBoundingBoxes[] | undefined>(undefined);

  const mapContainer = React.useRef<HTMLDivElement | null>(null);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
  const boundingBoxesRef = React.useRef<VideoBoundingBoxes[] | undefined>(boundingBoxes)
  const currentTimestampRef = React.useRef<number>(Date.now() - (60000 * 5));
  const endTimetampRef = React.useRef<number>(Date.now() - (60000 * 4));
  const streamsSatelliteProperitesRef = React.useRef<Map<string, VideoStreamSatelliteParameters>>(new Map());
  const selectedSiteRef = React.useRef(selectedSite);

  React.useEffect(() => { selectedSiteRef.current = selectedSite }, [selectedSite]);

  const loadMap = React.useCallback(() => {
    if (mapContainer.current && !map.current) {
      const mapOptions = {
        container: mapContainer.current,
        style: Utils.MapBox.generateStylesUrl('satellite'),
        center: new mapboxgl.LngLat(selectedSiteRef.current?.coordinates?.longitude || 15.975834198824849, selectedSiteRef.current?.coordinates?.latitude || 45.79974685471517),
        zoom: 18
      }

      map.current = API.MapBox.initializeMap(mapOptions);
      map.current.on('load', () => {
        const styleSwitcher = new MapboxStyleSwitcherControl();
        map.current?.addControl(styleSwitcher, "bottom-right");
        map.current?.addControl(new mapboxgl.NavigationControl(), "bottom-right")
        map.current?.resize();
      });

      map.current.on('style.load', () => {

      })
    }
  }, [])

  const loadBoundingBoxes = React.useCallback(() => {
    console.log('Loading bounding boxes for timestamp: ', new Date(currentTimestampRef.current))
    endTimetampRef.current = currentTimestampRef.current + 60000;
    const selectedSiteStreams = streams.filter(el => el.siteId === selectedSite?.siteId)

    selectedSiteStreams.forEach((stream) => {
      if (stream.satelliteParameters)
        streamsSatelliteProperitesRef.current.set(stream.kvsName, stream.satelliteParameters);
    })
    
    const promises = selectedSiteStreams.map(stream => API.Events.getBoundingBoxes(stream.kvsName, currentTimestampRef.current, endTimetampRef.current))
    Promise.all(promises)
      .then((bboxes) => {
        setBoundingBoxes(bboxes)
        console.log('Done loading bounding boxes');
      })
      .catch((error) => {
        enqueueSnackbar({ message: Utils.Error.getErrorMessage(error), variant: 'error' })
      })
  }, [streams, selectedSite, enqueueSnackbar])

  const calculateGPSFromCameraPosition = React.useCallback((box: BoundingBox, hMatrix: number[][]) => {
    // Assume bottom center as the point for simplicity
    const [x, y] = [(box.coordinates[0][0] + box.coordinates[1][0]) / 2, box.coordinates[3][1]];

    // Projection step
    const homogenousPoint = [x, y, 1];
    const point = multiply(matrix(hMatrix), homogenousPoint).toArray();

    const a = Number(point[0]) / Number(point[2]);
    const b = Number(point[1]) / Number(point[2]);

    return {
      color: box.color,
      coordinates: {
        lat: b,
        lng: a
      }
    }
  }, [])

  const frameRoutine = React.useCallback(() => {
    intervalRef.current = setInterval(() => {
      const diff = endTimetampRef.current - currentTimestampRef.current;

      if (diff < 10000) {
        loadBoundingBoxes();
      }

      if (boundingBoxesRef.current !== undefined) {
        const bboxes: BoundingBox[] = [];

        boundingBoxesRef.current.forEach((box) => {
          box.setCurrentTimestamp(currentTimestampRef.current);
          const target = box.getCurrentBoundingBox();
          const parameters = streamsSatelliteProperitesRef.current.get(box.getKvsName());
          if (parameters && target) {
            bboxes.push(...target.boxes.map((el) => ({...el, satelliteParameters: parameters})));
          }
        })

        const detections = bboxes.map(el => {
          return calculateGPSFromCameraPosition(el, el.satelliteParameters?.homographyMatrix || [])
        });

        currentTimestampRef.current += 100;

        const colorsArray = ['grey', 'limegreen', 'blue']

        colorsArray.forEach((color) => {
          if (map.current) {
            const lineSource = map.current.getSource(`${FOV_LINES_CANVAS_ID}-${color}`);
  
            if (!lineSource) {
              map.current.addSource(`${FOV_LINES_CANVAS_ID}-${color}`, {
                type: 'geojson',
                data: {
                  type: "FeatureCollection",
                  features: detections.map(el => ({
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "Point",
                      coordinates: new mapboxgl.LngLat(el.coordinates.lng, el.coordinates.lat).toArray()
                    }
                  }))
  
                }
              })
            }
  
            const lineSourceCast = lineSource as mapboxgl.GeoJSONSource;
  
            if (lineSourceCast) {
              lineSourceCast.setData({
                type: "FeatureCollection",
                features: detections.filter(el => el.color === color).map(el => ({
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: new mapboxgl.LngLat(el.coordinates.lng, el.coordinates.lat).toArray()
                  }
                }))
              });
            }
  
            const lineLayer = map.current.getLayer(`${FOV_LINES_LAYER_LINE_ID}-${color}`);
            if (!lineLayer) {
              map.current.addLayer({
                id: `${FOV_LINES_LAYER_LINE_ID}-${color}`,
                type: "circle",
                source: `${FOV_LINES_CANVAS_ID}-${color}`,
                layout: {
  
                },
                paint: {
                  "circle-color": color as string,
                  "circle-radius": 4,
                }
              })
            }
          }  
        })

      }
    }, 100);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  }, [calculateGPSFromCameraPosition, loadBoundingBoxes])

  React.useEffect(() => { boundingBoxesRef.current = boundingBoxes }, [boundingBoxes])
  React.useEffect(loadMap, [loadMap]);
  React.useEffect(loadBoundingBoxes, [loadBoundingBoxes])
  React.useEffect(frameRoutine, [frameRoutine])

  return (
    <div style={{ width: '100%', height: '100%' }} ref={mapContainer} className="map-container"></div>
  )
}

export default SatelliteView;
