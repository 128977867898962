export class ZoneEntity { 
  public static isZoneDirty(zones: Zone[], focusedZone?: Zone): boolean {
    if (!focusedZone) return false;

    if (ZoneEntity.isCreatingNewZone(focusedZone) && ZoneEntity.isZoneValidToSave(focusedZone)) return true;
    const target = ZoneEntity.findZoneById(focusedZone, zones);

    if (target) return ZoneEntity.areZoneDifferent(target, focusedZone);

    return false;
  }

  public static isCreatingNewZone(zone: Zone): boolean {
    return Boolean(zone.id === "");
  }

  public static isZoneValidToSave(zone: Zone): boolean {
    return Boolean(
      zone.coordinates.length > 2 &&
      (zone.schedule || zone.activeUntil)
    );
  }

  public static findZoneById(zone: Zone, zones: Zone[]): Zone | undefined {
    return zones.find(el => el.id === zone.id);
  }

  public static areZoneDifferent(zoneA: Zone, zoneB: Zone): boolean {
    const targetString = JSON.stringify(zoneA);
    const focusString = JSON.stringify(zoneB);

    return targetString !== focusString;
  }
}