import { API } from "aws-amplify";

async function registerUser(userId: string, user: Role): Promise<void> {
  try {
    const path: string = `/users/register/${userId}`

    const options = {
      body: user
    }

    await API.post(
      'api-service',
      path,
      options
    )

  } catch (error: any) {
    throw new Error(error.response?.data.error || error.message);
  }
}

export default registerUser;
