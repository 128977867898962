import React from 'react';
import styled from 'styled-components';
import EventsGroup from './EventsGroup';
import { TimelineEventGroupID, TimelineToBackendMap } from '@classes/StreamEvents/StreamEvents';
import EventsFilter from '@classes/StreamEvents/EventsFilter';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { useMobileQuery } from '@hooks/useMobileQuery';

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-right: 0.8rem;
  padding-left: 1rem;
  overflow: hidden;
  display: flex;
`

const Content = styled(Box)`
  .MuiAccordion-root {
    box-shadow: none !important;

    ::before {
      height: 0 !important;
    }
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 3.5rem !important;
  }

  .MuiAccordionSummary-root {
    min-height: 3.5rem !important;
  }

  .MuiAccordion-root.Mui-disabled {
    background-color: transparent !important;
  }
  
  .MuiIconButton-sizeSmall.MuiIconButton-edgeEnd {
    margin-right: 0 !important;
  }

  .MuiAccordionDetails-root {
    padding: 0 !important;
  }

  .MuiAccordionSummary-root {
    padding: 0 0 !important;
  }

  .MuiListItem-gutters {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .MuiListItemSecondaryAction-root {
    right: 0 !important;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

type EventsGroupListProps = {
  events: VideoStreamEvent[];
  onEventClick?: (event: VideoStreamEvent, animateTimeline?: boolean) => void;
  eventsFilter?: EventsFilter;
  isLoadingEvents: boolean;
  stream?: VideoStream;
  features: StreamFeatures;
  timezone?: string;
  onMouseEnterEvent?: (event: VideoStreamEvent) => void;
  onMouseLeaveEvent?: () => void;
  zones: Zone[];
  onMarkAsReadClick?: (data: VideoStreamEvent) => void;
  onInformationClick?: (data: VideoStreamEvent) => void;
}

const EventsGroupList: React.FC<EventsGroupListProps> = (props) => {
  const {
    events,
    onEventClick,
    isLoadingEvents,
    stream,
    features,
    timezone,
    onMouseEnterEvent,
    onMouseLeaveEvent,
    zones,
    onMarkAsReadClick,
    onInformationClick
  } = props;

  const rootFontSize = useSelector((state: IStoreState) => state.appView.rootFontSize);
  const isMobile = useMobileQuery();

  const [panel, setPanels] = React.useState<Map<TimelineEventGroupID, boolean>>(setExistingEventGroups())
  const [maxHeight, setMaxHeight] = React.useState(0);

  const panelRef = React.useRef(panel);

  React.useEffect(() => { panelRef.current = panel }, [panel]);

  React.useEffect(() => {
    const map = new Map<TimelineEventGroupID, boolean>();
    panelRef.current.forEach((value, key) => {
      if (value === true) {
        if (events.find(el => el.eventType === TimelineToBackendMap.get(key as TimelineEventGroupID)) !== undefined) {
          map.set(key, value);
        } else {
          map.set(key, false);
        }
      } else {
        map.set(key, value)
      }
    });

    setPanels(map);
  }, [events]);

  React.useEffect(() => {
    const calculateMaxHeightPx = () => {
      if (containerRef.current) {
        const panels = Array.from(panelRef.current.values());
        const openPanels = panels.filter(el => el === true).length;
        const rowHeight = 56 * rootFontSize / 16;
        if (openPanels === 0) {
          return (containerRef.current.offsetHeight - rootFontSize - (rowHeight * panels.length));
        }
        return (containerRef.current.offsetHeight - rootFontSize - (rowHeight * panels.length)) / openPanels;
      }

      return 200;
    }

    setMaxHeight(calculateMaxHeightPx());
  }, [rootFontSize]);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  function setExistingEventGroups(): Map<TimelineEventGroupID, boolean> {
    const { events } = features;

    const map: Map<TimelineEventGroupID, boolean> = new Map();

    if (events.motion.active && events.motion.read) map.set(TimelineEventGroupID.Motion, false);
    if (events.zones.active && events.zones.read) map.set(TimelineEventGroupID.Zones, false);
    if (events.safetyInfraction.active && events.safetyInfraction.read) map.set(TimelineEventGroupID.SafetyInfraction, false);
    if (events.person.active && events.person.read) map.set(TimelineEventGroupID.Person, false);
    if (events.vehicle.active && events.vehicle.read) map.set(TimelineEventGroupID.Vehicle, false);
    // legacy
    // if (events.socialDistance.active && events.socialDistance.read && features.socialDistancing) map.set(TimelineEventGroupID.SocialDistance, false);
    // if (events.fire.active && events.fire.read && features.fireDetection) map.set(TimelineEventGroupID.Fire, false);
    // if (events.vehicleLicensePlate.active && events.vehicleLicensePlate.read && features.licensePlateDetection) map.set(TimelineEventGroupID.VehicleLicensePlate, false);

    return map;
  }

  function handleOpenPanel(type: TimelineEventGroupID): void {
    const map = new Map<TimelineEventGroupID, boolean>();
    panel.forEach((value, key) => map.set(key, false));
    map.set(type, true);
    setPanels(map);
  }

  function handleCLosePanel(type: TimelineEventGroupID): void {
    const map = new Map<TimelineEventGroupID, boolean>();
    panel.forEach((value, key) => map.set(key, value));
    map.set(type, false);
    setPanels(map);
  }

  const getRowHeight = React.useCallback((type: TimelineEventGroupID): number => {
    switch (type) {
      case TimelineEventGroupID.VehicleLicensePlate:
        return rootFontSize * 4.5;
      default:
        return rootFontSize * 3.5;
    }
  }, [rootFontSize])

  return (
    <Container ref={containerRef}>
      {isLoadingEvents === true && containerRef.current && (
        <Box
          zIndex={5}
          bgcolor="rgba(255, 255, 255, 0.7)"
          width={containerRef.current.scrollWidth - 32}
          height={containerRef.current.scrollHeight - 32}
          display="flex" flexDirection="row"
          justifyContent="center"
          alignItems="center"
          position="absolute"
        >
          <CircularProgress />
        </Box>
      )}
      <Content
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        maxHeight={() => {
          if (containerRef.current) {
            if (isMobile) {
              return containerRef.current.offsetHeight - 16;
            }

            return containerRef.current.offsetHeight;
          }

          return '73vh'
        }}
      >
        {Array.from(panel.keys()).map(el => (
          <EventsGroup
            disabled={events.filter(event => event.eventType === TimelineToBackendMap.get(el as TimelineEventGroupID)).length === 0}
            key={el}
            type={el}
            events={events.filter(event => {
              if (event.eventType === "Viewpoint changed") {
                if (el === "Motion") {
                  return true;
                }
              }

              return event.eventType === TimelineToBackendMap.get(el as TimelineEventGroupID)
            })}
            onEventClick={onEventClick}
            maxHeightPx={maxHeight}
            rowHeight={getRowHeight(el)}
            onOpen={handleOpenPanel}
            onClose={handleCLosePanel}
            stream={stream}
            expanded={panel.get(el) || false}
            timezone={timezone}
            onMouseEnterEvent={onMouseEnterEvent}
            onMouseLeaveEvent={onMouseLeaveEvent}
            zones={zones}
            onMarkAsReadClick={onMarkAsReadClick}
            onInformationClick={onInformationClick}
          />
        ))}
      </Content>
    </Container>
  )
}

export default EventsGroupList;
