import { Box, Button, Checkbox, CircularProgress, Dialog, FormControlLabel, MenuItem, MenuList, Popover, Select, TextField, Tooltip, Typography } from '@mui/material';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import API from '@API/index';
import { first, isArray, isEmpty, isNumber, isString } from 'lodash';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setMuteTimeValue, setMuteTimeValueType } from '@store/views/overviewView/actions';

type Minutes = number;

const HOUR = 3600;

type MuteStreamProps = {
  selectedEvents: VideoStreamEvent[];
  onDialogOpen: () => void;
  onDialogClose: () => void;
  hasPermissionToEditEvent: boolean;
}

const MuteStream: React.FC<MuteStreamProps> = (props) => {
  const { selectedEvents, onDialogOpen, onDialogClose, hasPermissionToEditEvent } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const mutedStreams = useSelector((store: IStoreState) => store.streams.mutedStreams);
  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const streams = useSelector((store: IStoreState) => store.streams.streams);
  const customTimeValue = useSelector((store: IStoreState) => store.overviewView.muteTimeValue);
  const customTimeValueType = useSelector((store: IStoreState) => store.overviewView.muteTimeValueType);

  const [kvsNames, setKvsNames] = React.useState<KVSName | KVSName[]>([]);
  const [isStreamMuted, setStreamMuted] = React.useState<boolean>(false);
  const [isSiteMuted, setSiteMuted] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [unmuteSiteAnchorEl, setUnmuteSiteAnchorEl] = React.useState<HTMLElement | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [customTimeDialogOpen, setCustomTimeDialogOpen] = React.useState<boolean>(false);

  const [valueErrorText, setValueErrorText] = React.useState<string>("");
  const [muteSite, setMuteSite] = React.useState<boolean>(false);

  const onDialogOpenRef = React.useRef(onDialogOpen);
  const onDialogCloseRef = React.useRef(onDialogClose);
  const kvsNamesRef = React.useRef(kvsNames);

  React.useEffect(() => { kvsNamesRef.current = kvsNames }, [kvsNames]);
  React.useEffect(() => { onDialogOpenRef.current = onDialogOpen }, [onDialogOpen]);
  React.useEffect(() => { onDialogCloseRef.current = onDialogClose }, [onDialogClose])

  React.useEffect(() => {
    if (customTimeValue) {
      if (customTimeValueType === "hours") {
        if (customTimeValue < 0.5 || customTimeValue >= 4.01) setValueErrorText("Value must be between 0.5 and 4.")
        else setValueErrorText("");
      } else {
        if (customTimeValue < 30 || customTimeValue >= 240.01) setValueErrorText("Value must be between 30 and 240.")
        else setValueErrorText("")
      }
    } else {
      setValueErrorText("Value must be provided.")
    }
  }, [customTimeValue, customTimeValueType])

  React.useEffect(() => {
    if (customTimeDialogOpen === true) onDialogOpenRef.current();
    else onDialogCloseRef.current();
  }, [customTimeDialogOpen])

  React.useEffect(() => {
    const kvs: Set<string> = new Set();
    selectedEvents.forEach((event) => {
      kvs.add(event.sourceCamera);
      event.children?.forEach(el => kvs.add(el.sourceCamera))
    })

    if (kvs.size === 1) {
      const stream = first(Array.from(kvs))
      if (stream) {
        setKvsNames(stream)
      }
    } else {
      setKvsNames(Array.from(kvsNamesRef.current))
    }
  }, [selectedEvents])

  React.useEffect(() => {
    if (isString(kvsNames)) {
      setStreamMuted(mutedStreams.find(el => el === kvsNames) !== undefined);
    }
  }, [kvsNames, mutedStreams])

  React.useEffect(() => {
    if (isStreamMuted && mutedStreams) {
      const mutedKvsName = mutedStreams.find(el => el === kvsNames)
      if (mutedKvsName) {
        const stream = streams.find(el => el.kvsName === mutedKvsName);
        if (stream) {
          const siteStreams = streams.filter(el => el.siteId === stream.siteId);
          const count = siteStreams.filter(el => mutedStreams.includes(el.kvsName)).length;
          if (count > 1) {
            setSiteMuted(true);
          }
        }
      }
    } else {
      setSiteMuted(false);
    }
  }, [isStreamMuted, kvsNames, mutedStreams, streams])

  function reset() {
    setValueErrorText("");
    setMuteSite(false);
    setStreamMuted(false);
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (isStreamMuted === false) {
      setAnchorEl(event.currentTarget);
    } else if (isSiteMuted === true) {
      setUnmuteSiteAnchorEl(event.currentTarget);
    } else {
      unmuteStream();
    }
  }

  function muteStream(time: Minutes) {
    setAnchorEl(null);
    setLoading(true);

    if (selectedCompany && !isEmpty(kvsNames) && isString(kvsNames)) {
      API.Streams.muteStream(selectedCompany.companyId, kvsNames, time)
        .then(() => { })
        .catch((error) => {
          enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        })
    } else {
      if (!selectedCompany) enqueueSnackbar("Company not found.", { variant: 'error' })
      if (isEmpty(kvsNames)) enqueueSnackbar("KVSName not found.");
    }
  }

  function muteSiteStreams(siteId: string, time: Minutes) {
    setAnchorEl(null);
    setLoading(true);

    if (selectedCompany && siteId) {
      const muteStreamPromises = streams.filter(el => el.siteId === siteId).map((siteStream) => API.Streams.muteStream(selectedCompany.companyId, siteStream.kvsName, time))

      Promise.allSettled(muteStreamPromises)
        .then(() => { })
        .catch((error) => {
          enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' })
        })
        .finally(() => { setLoading(false); })
    } else {
      if (!selectedCompany) enqueueSnackbar("Company not found.", { variant: 'error' })
      if (isEmpty(kvsNames)) enqueueSnackbar("KVSName not found.");
    }
  }

  function unmuteStream() {
    setLoading(true);

    if (selectedCompany && !isEmpty(kvsNames) && isString(kvsNames)) {
      API.Streams.unmuteStream(selectedCompany.companyId, kvsNames)
        .then(() => { })
        .catch((error) => {
          enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        })
    } else {
      if (!selectedCompany) enqueueSnackbar("Company not found.", { variant: 'error' })
      if (isEmpty(kvsNames)) enqueueSnackbar("KVSName not found.");
    }
  }

  function unmuteSite() {
    setLoading(true);

    if (selectedCompany && !isEmpty(kvsNames) && isString(kvsNames)) {
      const mutedStream = streams.find(el => el.kvsName === kvsNames);
      if (mutedStream) {
        const siteStreams = streams.filter(el => el.siteId === mutedStream.siteId);
        if (!isEmpty(siteStreams)) {
          const mutedSiteStreams = siteStreams.filter(el => mutedStreams.includes(el.kvsName))

          const promises = mutedSiteStreams.map(el => API.Streams.unmuteStream(selectedCompany.companyId, el.kvsName));

          Promise.allSettled(promises)
            .then(() => { })
            .catch((error) => {
              enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' });
            })
            .finally(() => { setLoading(false) })
        } else {
          setLoading(false);
        }
      }
    } else {
      if (!selectedCompany) enqueueSnackbar("Company not found.", { variant: 'error' })
      if (isEmpty(kvsNames)) enqueueSnackbar("KVSName not found.");
    }
  }

  function onClosePopover() {
    setAnchorEl(null);
  }

  function getTooltip(): string {
    if (isArray(kvsNames)) {
      return "Cannot mute multiple streams"
    }

    const target = streams.find(el => el.kvsName === kvsNames);

    if (isStreamMuted) {
      if (target && target.defaultName) return `Unmute ${target.defaultName}`
      return "Unmute stream"
    }

    if (target && target.defaultName) return `Mute ${target.defaultName}`
    return "Mute stream";
  }

  function handleMuteClicked(): void {
    setCustomTimeDialogOpen(false);

    const siteId = streams.find(el => el.kvsName === kvsNames)?.siteId;

    if (customTimeValue) {
      if (muteSite && siteId) {
        if (customTimeValueType === "hours") muteSiteStreams(siteId, customTimeValue * HOUR);
        else muteSiteStreams(siteId, customTimeValue * 60);
      } else {
        if (customTimeValueType === "hours") muteStream(customTimeValue * HOUR);
        else muteStream(customTimeValue * 60);
      }
    }

    setTimeout(() => {
      reset();
    }, 200)
  }

  return (
    <>
      <Tooltip
        title={getTooltip()}
      >
        <span>
          <Button
            style={{ height: '1.5rem', marginRight: '0.5rem', width: '4.5rem' }}
            color="secondary"
            variant="outlined"
            size="small"
            onClick={(event) => { handleClick(event) }}
            disabled={loading || !isString(kvsNames) || !hasPermissionToEditEvent}
          >
            {loading ? <CircularProgress size={16} /> : isStreamMuted ? "Unmute" : "Mute"}
          </Button>
        </span>
      </Tooltip>
      <Popover
        anchorEl={unmuteSiteAnchorEl}
        open={Boolean(unmuteSiteAnchorEl)}
        onClose={() => { setUnmuteSiteAnchorEl(null) }}
        anchorOrigin={unmuteSiteAnchorEl ? {
          vertical: 'bottom',
          horizontal: 'center',
        } : undefined}
        transformOrigin={unmuteSiteAnchorEl ? {
          vertical: 'top',
          horizontal: 'center',
        } : undefined}
      >
        <MenuItem onClick={() => { setUnmuteSiteAnchorEl(null); unmuteStream(); }}>Unmute stream</MenuItem>
        <MenuItem onClick={() => { setUnmuteSiteAnchorEl(null); unmuteSite(); }} >Unmute site</MenuItem>
      </Popover>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClosePopover}
        anchorOrigin={anchorEl ? {
          vertical: 'bottom',
          horizontal: 'center',
        } : undefined}
        transformOrigin={anchorEl ? {
          vertical: 'top',
          horizontal: 'center',
        } : undefined}
      >
        <MenuList>
          <MenuItem onClick={() => { muteStream(HOUR / 2) }} >30 minutes</MenuItem>
          <MenuItem onClick={() => { muteStream(HOUR) }} >1 hour</MenuItem>
          <MenuItem onClick={() => { muteStream(HOUR * 2) }} >2 hours</MenuItem>
          <MenuItem onClick={() => { muteStream(HOUR * 3) }} >3 hours</MenuItem>
          <MenuItem onClick={() => { muteStream(HOUR * 4) }} >4 hours</MenuItem>
          {/* <MenuItem onClick={() => { muteStream(HOUR * 8) }} >8 hours</MenuItem>
          <MenuItem onClick={() => { muteStream(HOUR * 10) }} >10 hours</MenuItem>
          <MenuItem onClick={() => { muteStream(HOUR * 12) }} >12 hours</MenuItem>
          <MenuItem onClick={() => { muteStream(HOUR * 16) }} >16 hours</MenuItem>
          <MenuItem onClick={() => { muteStream(HOUR * 24) }} >24 hours</MenuItem> */}
          <MenuItem onClick={() => { setAnchorEl(null); setCustomTimeDialogOpen(true); }} >Custom</MenuItem>
        </MenuList>
      </Popover>
      <Dialog open={customTimeDialogOpen} >
        <Box padding="1.5rem">
          <Box height="4rem" display="grid" gap="1rem" gridTemplateColumns="auto auto">
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              style={{ marginTop: 0, width: '20rem' }}
              value={customTimeValue}
              onChange={(e) => {
                if (isNumber(+e.target.value) && +e.target.value !== 0) {
                  dispatch(setMuteTimeValue(+e.target.value))
                } else {
                  dispatch(setMuteTimeValue(undefined))
                }
              }}
              error={Boolean(valueErrorText)}
              helperText={valueErrorText}
              placeholder={customTimeValueType === "hours" ? "Hours" : "Minutes"}
            />
            <Select
              variant="outlined"
              style={{ width: '8rem' }}
              value={customTimeValueType}
              IconComponent={KeyboardArrowDown}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
              }}
            >
              <MenuItem onClick={() => dispatch(setMuteTimeValueType("hours"))} value="hours"><Typography variant="button">Hour(s)</Typography></MenuItem>
              <MenuItem onClick={() => dispatch(setMuteTimeValueType("minutes"))} value="minutes"><Typography variant="button">Minute(s)</Typography></MenuItem>
            </Select>
          </Box>
          <Box paddingLeft="0.15rem">
            <FormControlLabel
              control={
                <Checkbox size="small" checked={muteSite} onChange={() => setMuteSite(!muteSite)} name="autoplay" />
              }
              label="Mute all cameras on the site for specified time period"
            />
          </Box>
          <Box paddingTop="1rem" display="flex" flexDirection="row" justifyContent="space-between">
            <Button onClick={() => { setCustomTimeDialogOpen(false); setTimeout(() => { reset(); }, 300) }} variant="outlined" >Cancel</Button>
            <Button onClick={() => handleMuteClicked()} disabled={!isEmpty(valueErrorText)} variant="contained" >Mute</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default MuteStream;
