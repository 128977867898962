import { API } from 'aws-amplify';

async function postView(view: UserView): Promise<void> {
  try {
    const path: string = `/grids`;

    await API.post(
      'api-service',
      path,
      {
        body: {
          "layout": view.layout,
          "name": view.name,
          "siteId": view.siteId,
          "streams": view.streams
        }
      }
    );
  } catch (error: any) {
    throw new Error(error.response?.data.error || error.message);
  }
}

export default postView;
