import { ActionTypes } from './types';

export function setCompanies(value: Company[]) {
  return {
    type: ActionTypes.SET_COMPANIES,
    companies: value
  }
}

export function pushCompany(company: Company) {
  return {
    type: ActionTypes.PUSH_COMPANY,
    company
  }
}
