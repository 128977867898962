import React from 'react';
import styled from 'styled-components';

const ViewContainer = styled.canvas`
  position: absolute;
  z-index: 3;
  display: block;

  min-height: 1px;
  min-width: 1px;
`;

interface IProps {
  zone: Zone;
  plane: Vector2;
  isUserDrawingZone: boolean;
  isSelected: boolean;
  isHovered: boolean;
  onErrorDrawingZone?: () => void;
  containerWidth: number;
  containerHeight: number;
}

export interface IDrawable {
  draw: (transform: { x: number, y: number, scale: number }) => void;
}

const ZoneShape: React.ForwardRefRenderFunction<IDrawable, IProps> = (props, ref) => {
  const { containerWidth, containerHeight } = props;

  React.useImperativeHandle(ref, () => ({
    draw
  }))

  const currentTransform = React.useRef<{ x: number, y: number, scale: number }>({ x: 0, y: 0, scale: 1 });
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(draw);

  function getFillOpacity() {
    const { isUserDrawingZone, isSelected, isHovered } = props;
    if (isHovered) {
      return '0.2';
    }

    if (!isUserDrawingZone) {
      return '0.9';
    }

    if (isUserDrawingZone && isSelected) {
      return '1';
    }

    if (isUserDrawingZone && !isSelected) {
      return '0.5'
    }

    return '0.9';
  }

  function getLineOpacity() {
    const { isUserDrawingZone, isSelected, isHovered } = props;
    if (isHovered) {
      return '1';
    }

    if (!isUserDrawingZone) {
      return '1';
    }

    if (isUserDrawingZone && isSelected) {
      return '1';
    }

    if (isUserDrawingZone && !isSelected) {
      return '0.4'
    }

    return '0.8';
  }

  function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  function draw(t?: { x: number, y: number, scale: number }) {
    const { zone, plane, onErrorDrawingZone, containerHeight, containerWidth } = props;
    const transform = t || currentTransform.current;

    if (t) currentTransform.current = t;

    if (canvasRef.current !== null) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        try {
          context.fillStyle = 'transparent';
          if (zone.coordinates.length < 2) {
            context.clearRect(0, 0, containerWidth, containerHeight);
            return;
          }
  
          const rgb = hexToRgb(zone.color);
  
          context.clearRect(0, 0, containerWidth, containerHeight);
          context.lineWidth = 1;
          context.strokeStyle = zone.color;
  
          if (zone.texture === "smooth") {
            context.fillStyle = `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${getFillOpacity()})`;
          }
  
          if (zone.texture === "rough") {
            const patternCanvas = document.createElement('canvas');
            patternCanvas.setAttribute("data-cy", "zone-pattern-canvas")
            const patternContext = patternCanvas.getContext('2d');
  
  
            if (patternContext) {
              const color1 = "transparent";
              const color2 = `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${getFillOpacity()})`;;
              const numberOfStripes = 100;
              for (let i = 0; i < numberOfStripes * 2; i++) {
                const thickness = 300 / numberOfStripes;
                patternContext.beginPath();
                patternContext.strokeStyle = i % 2 ? color1 : color2;
                patternContext.lineWidth = thickness;
                patternContext.lineCap = 'round';
  
                patternContext.moveTo(i * thickness + thickness / 2 - 300, 0);
                patternContext.lineTo(0 + i * thickness + thickness / 2, 300);
                patternContext.stroke();
              }
            }
  
            const pattern = context.createPattern(patternCanvas, 'repeat');
            if (pattern) {
              context.fillStyle = pattern;
            }
          }
  
          // Exclude Geofence coloring.
          if (zone.type === "ignore") {
            context.fillStyle = 'rgba(0, 0, 0, 0.3)';
            context.strokeStyle = `rgba(255, 99, 67, ${getLineOpacity()})`;
          }

          const offsetY = ((containerHeight - plane.y) * (transform.scale)) / 2;
          const offsetX = ((containerWidth - plane.x) * (transform.scale)) / 2;
  
          context.beginPath();

          const scaledCoordinates = zone.coordinates.map((coordinate: any) => ({
            x: plane.x * coordinate[0] * transform.scale + transform.x + offsetX,
            y: plane.y * coordinate[1] * transform.scale + transform.y + offsetY,
          }));
          
          context.moveTo(scaledCoordinates[0].x, scaledCoordinates[0].y);
          
          for (let i = 0; i < scaledCoordinates.length; i += 1) {
            context.lineTo(scaledCoordinates[i].x, scaledCoordinates[i].y);
            context.stroke();
          }
          
          context.lineTo(scaledCoordinates[0].x, scaledCoordinates[0].y);
          context.stroke();
          
          context.fill();
        } catch (e) {
          console.log(e);
          if (onErrorDrawingZone) onErrorDrawingZone();
        }
      }
    }
  }

  return (
    <ViewContainer
      width={containerWidth}
      height={containerHeight}
      style={{ width: containerWidth, height: containerHeight, top: 0, left: 0 }}
      data-cy="zone-shape-canvas"
      ref={canvasRef}
    />
  )
}

export default React.forwardRef(ZoneShape);
