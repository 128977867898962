import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Checkbox, Divider, InputAdornment, ListItem, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, MenuList, TextField, Typography } from '@mui/material';
import { first, isEmpty, isObject, isUndefined } from 'lodash';
import React from 'react';
import { CustomSelect } from '../Schedule/ScheduleInput';
import SearchHeaderTooltipIcon from '../SearchHeaderTooltipIcon';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import _ from 'lodash';

type MultiSelectInputProps = {
  options: Array<string | { value: string, label: string }>;
  rootFontSize?: number;
  onChange?: (values: string[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disableWidth?: boolean;
  allSelected?: boolean;
  initialSelection?: Array<string>;
  selectAllOption?: {
    label: string
  };
  disabled?: boolean;
  value?: string[];
  searchable?: boolean;
  sections?: Map<string, string[]>;
  emptyLabel?: string;
  menuWidth?: string;
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = (props) => {
  const { options, menuWidth, emptyLabel, onChange, disableWidth, allSelected, initialSelection, selectAllOption, disabled, value, searchable, sections, onBlur, onFocus, rootFontSize } = props;

  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  const [selectedOptions, setSelectedOptions] = React.useState<Array<string>>(allSelected ? options.map(el => isObject(el) ? el.value : el) : initialSelection ? initialSelection : []);
  const [filterText, setFilterText] = React.useState<string>("")

  const onChangeRef = React.useRef(onChange)
  const searchFieldRef = React.useRef<HTMLDivElement | null>(null);

  const getFiltered = React.useCallback((filterText: string, options: Array<string | { value: string, label: string }>) => {
    return options.filter((option) => {
      if (isObject(option)) {
        return option.label.toLowerCase().includes(filterText.toLowerCase())
      }

      return option.toLowerCase().includes(filterText.toLowerCase())
    })
  }, [])

  const filtered = React.useMemo(() => getFiltered(filterText, options), [filterText, options, getFiltered])

  React.useEffect(() => {
    if (!isUndefined(value)) {
      setSelectedOptions(value);
    }
  }, [value])

  React.useEffect(() => { onChangeRef.current = onChange }, [onChange])

  React.useEffect(() => {
    if (onChangeRef.current && isUndefined(value)) onChangeRef.current(selectedOptions);
  }, [selectedOptions, value])

  React.useEffect(() => {
    if (anchorEl) {
      searchFieldRef?.current?.focus();
    }
  }, [anchorEl])

  function getText(): string {
    const f = first(selectedOptions);

    if (f) {
      const v = options.find((el) => {
        if (isObject(el)) return el.value === f;
        return el === f;
      })

      if (v) {
        if (selectedOptions.length === 1) return getLabel(v) || "";
        return `${getLabel(v)}, +${selectedOptions.length - 1}`;
      }
    }

    return emptyLabel || "Select a value";
  }

  function getValue(option: string | { value: string, label: string }): string {
    if (isObject(option)) return option.value;
    return option;
  }

  function getLabel(option: string | { value: string, label: string }): string {
    if (isObject(option)) return _.capitalize(option.label);
    return _.capitalize(option);
  }

  function includes(options: Array<string>, option: string | { value: string, label: string }): boolean {
    if (isObject(option)) {
      return options.includes(option.value)
    }

    return options.includes(option);
  }

  function setOptions(options: string[]) {
    if (!isUndefined(value) && onChange) {
      onChange(options);
    } else {
      setSelectedOptions(options);
    }
  }

  function buildSectionedList(options: Array<string | { value: string, label: string }>, sections: Map<string, string[]>): JSX.Element {
    const elements: JSX.Element[] = [];

    sections.forEach((value, key) => {
      const opt = options.filter((el) => {
        if (isObject(el)) return value.includes(el.value)
        return value.includes(el as string)
      })

      if (!isEmpty(opt)) {
        elements.push((
          <Typography key={key} style={{ paddingLeft: 28, paddingTop: 12, paddingBottom: 12 }} >{key}</Typography>
        ))

        opt.forEach((option) => {
          elements.push(
            <MenuItem
              dense
              style={{ paddingTop: 0, paddingBottom: 0 }}
              key={getValue(option)}
              onClick={() => {
                if (includes(selectedOptions, option)) setSelectedOptions(selectedOptions.filter(el => {
                  if (isObject(option)) return el !== option.value;
                  return el !== option
                }))
                else setSelectedOptions([...selectedOptions, isObject(option) ? option.value : option])
              }}
            >
              <ListItemIcon
                style={{ minWidth: "2.25rem" }}
              >
                <Checkbox
                  color="primary"
                  size="small"
                  checked={includes(selectedOptions, option)}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography noWrap={true} style={{ width: '11rem' }}>{getLabel(option)}</Typography>
              </ListItemText>
            </MenuItem>
          )
        })
      }
    })

    return (
      <MenuList disablePadding style={{ maxHeight: '40vh', overflowY: 'auto' }}>
        {elements}
      </MenuList>
    );
  }

  const virtualizedList = () => {
    return <div style={{ width: '100%' }}>
      {/* @ts-ignore */}
      <AutoSizer disableHeight>
        {({ width }) => (
          //@ts-ignore
          <List
            height={((rootFontSize || 14) * 3) * 5}
            rowCount={filtered.length}
            rowHeight={(rootFontSize || 14) * 3}
            width={width}
            rowRenderer={(props: ListRowProps) => {
              const option = filtered[props.index];
              return (
                <div
                  key={props.index}
                  style={props.style}
                >
                  <ListItem
                    data-cy={isObject(option) ? option.value : option}
                    dense
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    key={getValue(option)}
                    onClick={() => {
                      if (includes(selectedOptions, option)) setOptions(selectedOptions.filter(el => {
                        if (isObject(option)) return el !== option.value;
                        return el !== option
                      }))
                      else setOptions([...selectedOptions, isObject(option) ? option.value : option])
                    }}
                  >
                    <ListItemIcon
                      style={{ minWidth: "2.25rem" }}
                    >
                      <Checkbox
                        color="primary"
                        size="small"
                        checked={includes(selectedOptions, option)}
                      />
                    </ListItemIcon>
                    <ListItemText style={{ width: '11rem' }}>
                      {getLabel(option)}
                    </ListItemText>
                  </ListItem>
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
    </div>
  }

  return (
    <Box>
      <CustomSelect
        disabled={disabled}
        IconComponent={KeyboardArrowDown}
        fullWidth={true}
        value={"default"}
        open={false}
        style={{ width: !disableWidth ? '11rem' : undefined, height: '2.5rem' }}
        autoFocus={false}
        variant={"outlined"}
        onClick={(e) => { !Boolean(disabled) && setAnchorEl(e.currentTarget) }}
        data-cy="multi-select-input"
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <MenuItem value="default">
          <Box display="grid" gridTemplateColumns="auto auto">
            <Typography style={{ width: !disableWidth ? '11rem' : undefined, textOverflow: 'ellipsis' }} noWrap={true} variant="button">{getText()}</Typography>
            {options &&
              <Box display="flex" width='100%' justifyContent="flex-end" alignItems="flex-end">
                <Typography style={{ width: !disableWidth ? '11rem' : undefined, marginRight: '0.5rem' }} noWrap={true} variant="button">{`${value?.length || 0}/${options.length}`}</Typography>
              </Box>
            }
          </Box>
        </MenuItem>
      </CustomSelect>
      <Menu
        keepMounted={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ zIndex: 9999 }}
        data-cy="multi-select-input-menu"
      >
        {searchable &&
          <MenuList data-cy="multi-select-input-list" disablePadding style={{ width: menuWidth || undefined }} onKeyDown={(e) => e.stopPropagation()}>
            <ListSubheader style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem"
            }} >
              <TextField
                style={{ marginTop: 0 }}
                autoFocus={true}
                placeholder="Search"
                variant="standard"
                defaultValue={filterText}
                onChange={(event) => { setFilterText(event.target.value); }}
                onFocus={onFocus}
                onBlur={onBlur}
                size="medium"
                inputRef={searchFieldRef}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchHeaderTooltipIcon />
                    </InputAdornment>
                  )
                }}
                data-cy="site-list-search-input"
              />
            </ListSubheader>
            <Divider style={{ marginTop: '0.3rem', marginBottom: '0.3rem' }} />
          </MenuList>
        }
        {selectAllOption &&
          <MenuList disablePadding>
            <ListSubheader style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 0
            }} >
              <MenuList disablePadding>
                <MenuItem
                  dense
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  key="all"
                  onClick={() => {
                    if (options.length === selectedOptions.length) setOptions([])
                    else setOptions(options.map(el => isObject(el) ? el.value : el));
                  }}
                >
                  <ListItemIcon
                    style={{ minWidth: "2.25rem" }}
                  >
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={options.length === selectedOptions.length}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography noWrap={true} style={{ width: '11rem' }}>{selectAllOption.label}</Typography>
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </ListSubheader>
            <Divider style={{ marginTop: '0.3rem', marginBottom: '0.3rem' }} />
          </MenuList>
        }
        {sections ? (
          buildSectionedList(filtered, sections)
        ) : (
          virtualizedList()
        )}
      </Menu>
    </Box >
  )
}

export default MultiSelectInput;
