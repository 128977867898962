import React from 'react';
import Utils from '@utils/index';
import { Box, Button, Typography } from '@mui/material';

type ZoneInfoProps = {
  focusedZone?: Zone;
  onClose?: () => void;
  selectedSite?: Site;
}

const ZoneInfo: React.FC<ZoneInfoProps> = (props) => {
  const { focusedZone, onClose, selectedSite } = props;

  return (
    <Box width="30vw" padding="1rem" display="grid" gridTemplateRows="repeat(2, min-content)" gap="1rem">
      <Box width="100%" display="grid" gridTemplateColumns="max-content auto" gap="1rem">
        <Typography color="textPrimary" variant="body2"><b>Name:</b></Typography>
        <Typography color="textSecondary" variant="body2" >{focusedZone?.name}</Typography>
        {focusedZone?.description &&
          <Typography color="textPrimary" variant="body2"><b>Description:</b></Typography>
        }
        {focusedZone?.description &&
          <Typography color="textSecondary" variant="body2" >{focusedZone?.description}</Typography>
        }
        {focusedZone?.createdBy &&
          <Typography color="textPrimary" variant="body2"><b>Created by:</b></Typography>
        }
        {focusedZone?.createdBy &&
          <Typography color="textSecondary" variant="body2" >{focusedZone?.createdBy}</Typography>
        }
        <Typography color="textPrimary" variant="body2"><b>Created:</b></Typography>
        <Typography color="textSecondary" variant="body2" >{Utils.Date.format(new Date(focusedZone?.createdAt || 0), 'MMM Do, YYYY h:mm:ss A', selectedSite && selectedSite.timezone ? selectedSite.timezone.split(" ")[0] : "")}</Typography>
        {focusedZone?.modifiedBy &&
          <Typography color="textPrimary" variant="body2"><b>Modified by:</b></Typography>
        }
        {focusedZone?.modifiedBy &&
          <Typography color="textSecondary" variant="body2" >{focusedZone?.modifiedBy}</Typography>
        }
        {focusedZone && focusedZone.modified &&
          <Typography color="textPrimary" variant="body2"><b>Modified:</b></Typography>
        }
        {focusedZone && focusedZone.modified &&
          <Typography color="textSecondary" variant="body2">{Utils.Date.format(new Date(focusedZone?.modified || 0), 'MMM Do, YYYY h:mm:ss A', selectedSite && selectedSite.timezone ? selectedSite.timezone.split(" ")[0] : "")}</Typography>
        }
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%">
        <Button onClick={onClose} variant="text" data-cy="zone-info-close-button" >Close</Button>
      </Box>
    </Box>
  );
}

export default ZoneInfo;
