

export enum ActionParameterKey {
  EventType = "EventType",
  Asset = "Asset",
  User = "User",
  Role = "Role",
  TimeRange = "TimeRange",
  NotificationChannel = "NotificationChannel"
}

export enum DayLabel {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday"
}