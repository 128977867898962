import { API } from "aws-amplify";

export async function delOverrideZoneSchedule(): Promise<void> {
  try {
    const path: string = `/geofence/override`;
    await API.del('api-service', path, {});
  } catch (error) {
    console.log(error);
    throw error;
  }
}