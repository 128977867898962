import { API } from "aws-amplify";

async function deleteCompany(companyId: string): Promise<void> {
  try {
    const path: string = `/companies/${companyId}`;

    await API.del(
      'api-service',
      path,
      {}
    );

  } catch (error: any) {
    throw error;
  }
}

export default deleteCompany;
