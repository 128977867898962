import { API } from "aws-amplify";

export async function listUsersAndStreamsMapping(companyId: string): Promise<Map<string, string[]>> {
  try {
    const path: string = `/global/inbox/permissions?companyId=${companyId}`;
    const service: string = 'api-service';

    const response = await API.get(
      service,
      path,
      {}
    );

    const { data } = response;
    const { userIds, mappings } = data;


    const map: Map<string, string[]> = new Map<string, string[]>([]);

    const keys = Object.keys(mappings) as string[];
    const values = Object.values(mappings) as number[][];

    for (let i = 0; i < keys.length; i += 1) {
      const ids: string[] = values[i].map((el) => userIds[el]);
      map.set(keys[i], ids)
    }

    return map;
  } catch (error) {
    console.log(error);
    throw error;
  }
}