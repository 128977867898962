import VideoBoundingBoxes from '@classes/VideoBoundingBoxes';
import { API } from 'aws-amplify';

async function getBoundingBoxes(kvsName: string, start: number, end: number): Promise<VideoBoundingBoxes> {
  try {
    const path: string = `/events/boundingboxes/${kvsName}/${start}/${end}`;
    const data: APIResponse<EventBoundingBox[]> = await API.get('api-service', path, {});

    return new VideoBoundingBoxes(kvsName, data.data);
  } catch (error: any) {
    throw error;
  }
}

export default getBoundingBoxes;