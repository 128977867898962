import { Chip } from '@mui/material';
import Utils from '@utils/index';
import React from 'react';

function getTagText(event: { tags?: EventTag[] }): string {
  if (event.tags && event.tags[0]) {
    return Utils.StreamEvent.getTagText(event.tags[0])
  }

  return "None"
}

function getColor(event: { tags?: EventTag[] }): "success" | "warning" | "error" {
  if (event.tags && event.tags[0]) {
    if (event.tags[0].includes('escalate')) {
      return "warning"
    }

    if (event.tags[0].includes('false_positive')) {
      return "error";
    }

    if (event.tags[0].includes('muted')) {
      return "error";
    }

    return "success";
  }

  return "success";
}

function getBackgroundColor(value: "success" | "warning" | "error", selected: boolean = false): string {
  if (selected === true) return 'rgba(255, 255, 255, 1)';
  switch (value) {
    case "success": return "rgba(82, 169, 130, 0.2)";
    case "warning": return "rgba(255, 183, 77, 0.2)";
    case "error": return "rgba(217, 112, 96, 0.2)";
    default: return "rgba(82, 169, 130, 0.2)";
  }
}

const EventTagLabel: React.FC<{ event: { tags?: EventTag[] }, selected: boolean, noMarginLeft?: boolean }> = (props) => {
  const { event, selected, noMarginLeft } = props;

  const tagText = React.useMemo(() => getTagText(event), [event]);

  return (
    <Chip
    data-cy={`assigned-event-tag-${tagText}`}
      color={getColor(event)}
      style={{
        height: '1rem',
        paddingTop: 1,
        backgroundColor: getBackgroundColor(getColor(event), selected),
        visibility: Boolean(event.tags) ? 'visible' : 'hidden',
        marginLeft: noMarginLeft ? '0rem' : undefined
      }}
      size="small"
      label={tagText}
      variant="outlined"
    />
  )
}

export default EventTagLabel;
