import Utils from '@utils/index';

function findNextItem(items: { timestamp: number }[], timestamp: number, maxRange?: number): object | undefined {
  const greaterEvents = items.filter(element => {
    if (maxRange) return element.timestamp > timestamp + 10 && element.timestamp < timestamp + maxRange;
    else return element.timestamp > timestamp + 10
  });

  if (maxRange) greaterEvents.filter(element => element.timestamp < timestamp + maxRange)

  return Utils.Timestamp.findClosestTimestamp(greaterEvents, timestamp)
}

export default findNextItem;
