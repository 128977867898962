import { API } from "aws-amplify";

async function updateAccessRequest(id: string, status: DashboardAccessRequestStatus): Promise<AccessRequest> {
  try {
    const path: string = `/dashboard-access/requests/${id}`;

    const options = {
      body: {
        status
      }
    };

    const data = await API.patch(
      'api-service',
      path,
      options
    );

    return data.data as AccessRequest;
  } catch (error: any) {
    throw error;
  }
}

export default updateAccessRequest;

