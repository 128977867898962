import Tutorial from "./Tutorial";

class HeatmapsOverviewTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#heatmaps',
        popover: {
          title: 'Heatmaps',
          description: "Heatmaps are a resource for visualizing the busiest areas on site within a specified timeframe. Gain insights at a glance and optimize operations with data-driven decision-making."
        }
      },
      {
        element: '#heatmaps-filter',
        popover: {
          title: 'Filtering',
          description: 'Select your timeframe and cameras for the heatmaps you would like to view.',
        }
      },
      {
        element: '#heatmaps-main-content',
        popover: {
          title: 'List',
          description: 'Click into the specific heatmap you would like to view.',
          position: 'mid-center'
        }
      }
    ])
  }

}

export default HeatmapsOverviewTutorial;
