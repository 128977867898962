import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_IS_CREATING_VIEW: null,
  SET_SELECTED_VIEW: null
})

export interface IViewsViewState {
  readonly isCreatingView: boolean;
  readonly selectedView: UserView | undefined;
  readonly newViewTitle: string;
}
