import { API } from "aws-amplify";

async function deleteVideoDownload(download: VideoDownload, kvsName: string): Promise<void> {
  try {
    const path: string = `/download/video/delete/${kvsName}/${download.videoDownloadId}`

    await API.del(
      'api-service',
      path,
      {}
    );

  } catch (error: any) {
    throw error;
  }
}

export default deleteVideoDownload;
