import { useMediaQuery } from '@mui/material';
import React from 'react';

export function useVerySmallScreenQuery() {
  const isTabletOrMobile = useMediaQuery('(max-width: 599px)');
  
  const [state, setState] = React.useState<boolean>(isTabletOrMobile)

  React.useEffect(() => {
    setState(isTabletOrMobile)
  }, [isTabletOrMobile])

  return state;
}