import { API } from "aws-amplify";

async function updateRole(role: Role): Promise<Role> {
  try {
    const path: string = `/roles/${role.id}`;
    
    const tempRole: any = { ...role };
    delete tempRole.companyId;
    delete tempRole.id;
    const options = {
      body: tempRole,
      queryStringParameters: {  // OPTIONAL
        companyId: role.companyId,
      },
    };

    const data = await API.put(
      'api-service',
      path,
      options
    );

    
    return data.data as Role;
  } catch (error: any) {
    throw error;
  }
}

export default updateRole;
