import { ActionTypes, ICalendarState } from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: ICalendarState = {
  activeUser: null,
  viewType: 'timeline',
  calendarStep: 'day',
  timeEntries: [],
  timeEntriesDateRange: undefined,
  activities: {},
  groupBySub: false,
  openedSubs: []
};

export const calendarReducer = Utils.Redux.createReducer<ICalendarState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_ACTIVE_USERS]: (state, action) => ({
      ...state,
      activeUser: action.value
    }),
    [ActionTypes.SET_VIEW_TYPE]: (state, action) => ({
      ...state,
      viewType: action.value
    }),
    [ActionTypes.SET_CALENDAR_VIEW]: (state, action) => ({
      ...state,
      calendarStep: action.value
    }),
    [ActionTypes.SET_TIME_ENTRIES]: (state, action) => ({
      ...state,
      timeEntries: action.value
    }),
    [ActionTypes.SET_ACTIVITIES]: (state, action) => ({
      ...state,
      activities: {
        ...state.activities,
        [action.workerId]: action.activities
      }
    }),
    [ActionTypes.SET_TIME_ENTRIES_DATE_RANGE]: (state, action) => ({
      ...state,
      timeEntriesDateRange: action.value
    }),
    [ActionTypes.SET_TIME_ENTRIES_SITE_ID]: (state, action) => ({
      ...state,
      timeEntriesSiteId: action.value
    }),
    [ActionTypes.SET_GROUP_BY_SUB]: (state, action) => ({
      ...state,
      groupBySub: action.value
    }),
    [ActionTypes.SET_OPENED_SUBS]: (state, action) => ({
      ...state,
      openedSubs: action.value
    })
  }
);
