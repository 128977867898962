function getTagPositivity(label: string): boolean | undefined {
    switch (label) {
        case "Person":
        case "Vehicle":
            return true;
        case "Animal":
        case "Light":
        case "Weather":
        case "Unknown":
        case "Other":
            return false;
        default:
            return undefined;
    }
}

export default getTagPositivity;
