import React from 'react';
import { Box, Chip, SvgIcon, Typography } from '@mui/material';
import { BusinessOutlined, LocationOn, Videocam } from '@mui/icons-material';
import StreamEvents from '@classes/StreamEvents/StreamEvents';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import Utils from '@utils/index';
import TagSelectionChips from './TagSelectionChips';

type MultiEventContentProps = {
  events: VideoStreamEvent[];
  onTagSelected: (tag: EventTag) => void;
  zoneNames: Map<string, string>;
  hasPermissionToEditEvent: boolean;
}

const MultiEventContent: React.FC<MultiEventContentProps> = (props) => {
  const { events, onTagSelected, zoneNames, hasPermissionToEditEvent } = props;

  const companies = useSelector((store: IStoreState) => store.companies.companies);
  const sites = useSelector((store: IStoreState) => store.sites.sites);
  const streams = useSelector((store: IStoreState) => store.streams.streams);

  const [siteChips, setSiteChips] = React.useState<JSX.Element[]>([]);
  const [cameraChips, setCameraChips] = React.useState<JSX.Element[]>([]);
  const [eventTypeChips, setEventTypeChips] = React.useState<JSX.Element[]>([]);

  React.useEffect(getSitesChips, [companies, events, sites]);
  React.useEffect(getCamerasChips, [events, streams]);
  React.useEffect(getEventTypeChips, [events]);

  function getTitle(events: VideoStreamEvent[]): string {
    return `${events.length} Events selected`;
  }

  function getSitesChips(): void {
    const elements: JSX.Element[] = [];
    const possibleSites: Map<string, Site> = new Map<string, Site>();

    events.forEach((event) => {
      const { siteId } = event;
      const company = companies.find(el => el.companyId === event.companyId);
      if (company) {
        const site = sites.get(company.companyId)?.find(el => el.siteId === event.siteId);
        if (site) {
          possibleSites.set(siteId, site);
        }
      }
    })

    possibleSites.forEach((_value, _key) => {
      elements.push(
        <Chip
          key={_key}
          style={{ marginLeft: 0 }}
          variant="outlined"
          icon={<LocationOn />}
          label={_value.name}
        />
      )
    })

    setSiteChips(elements);
  }

  function getCamerasChips(): void {
    const elements: JSX.Element[] = [];
    const possibleCameras: Map<string, VideoStream> = new Map<string, VideoStream>();

    events.forEach((event) => {
      const { sourceCamera } = event;
      const stream = streams.find(el => el.kvsName === sourceCamera);
      if (stream) {
        possibleCameras.set(sourceCamera, stream);
      }
    })

    possibleCameras.forEach((_value, _key) => {
      elements.push(
        <Chip
          key={_key}
          style={{ marginLeft: 0 }}
          variant="outlined"
          icon={<Videocam />}
          label={_value.defaultName || _value.kvsName}
        />
      )
    })

    setCameraChips(elements);
  }

  function getEventTypeChips(): void {
    const elements: JSX.Element[] = [];
    const possibleEventTypes: Map<string, VideoStreamEvent> = new Map<string, VideoStreamEvent>();
    events.forEach((event) => {
      possibleEventTypes.set(event.eventType, event);
    })

    possibleEventTypes.forEach((_value, _key) => {
      elements.push(
        <Chip
          key={_key}
          style={{ marginLeft: 0 }}
          variant="outlined"
          icon={<SvgIcon fontSize="small">{StreamEvents.getEventIcon(_value, false)}</SvgIcon>}
          label={StreamEvents.getEventType(_value)}
        />
      )
    })

    setEventTypeChips(elements);
  }

  function getDescription(event: VideoStreamEvent): string {
    const stream = streams.find(el => el.kvsName === event.sourceCamera);
    if (stream) {
      const site = sites.get(stream.companyId)?.find(el => el.siteId === stream.siteId);
      if (site) {
        return `${stream.defaultName || stream.kvsName} at ${site.name}`;
      }

      return `${stream.defaultName || stream.kvsName}`
    }

    return `Error finding the stream and site`;
  }

  function getZoneNames(event?: VideoStreamEvent): string[] {
    if (!event) return [];

    let zNames: string[] = [];
    if (event.zoneId) {
      event.zoneId.forEach((id) => {
        const name = zoneNames.get(id);
        if (name) {
          zNames.push(name)
        }
      })
    }

    return zNames;
  }

  return (
    <Box paddingBottom="1rem" paddingTop="0.5rem" width="100%" height="100%" display="grid" gridTemplateRows="min-content auto" gap="1rem">
      <Box width="100%" display="grid" gridTemplateColumns="1fr 1fr">
        <Box display="grid" height="min-content" gridTemplateRows="min-content" gap="0.5rem">
          <Typography variant="h6">{getTitle(events)}</Typography>
          <Box width="100%" display="grid" gridTemplateColumns="min-content min-content min-content" alignItems="center">
            {events[0] &&
              <Chip
                style={{ marginLeft: 0 }}
                variant="outlined"
                icon={<BusinessOutlined />}
                label={StreamEvents.getCompanyAndSite(events[0], companies, sites).company?.name || 'unknown'}
              />
            }
          </Box>
          <Box width="100%" display="grid" gridTemplateColumns="min-content min-content min-content" alignItems="center">
            {siteChips}
          </Box>
          <Box width="100%" display="grid" gridTemplateColumns="min-content min-content min-content" alignItems="center">
            {cameraChips}
          </Box>
          <Box width="100%" display="grid" gridTemplateColumns="min-content min-content min-content" alignItems="center">
            {eventTypeChips}
          </Box>
          <Box>
            {events.find(el => el.ongoing === true) === undefined &&
              <TagSelectionChips onTagSelected={onTagSelected} events={events} hasPermissionToEditEvent={hasPermissionToEditEvent} />
            }
          </Box>
        </Box>
        <Timeline style={{ maxHeight: '80vh', overflowY: 'auto' }} position="left" title={""}>
          {events.sort((a, b) => a.startDate - b.startDate).map((event) => (
            <TimelineItem key={event.id}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="caption"
                color="text.secondary"
              >
                {Utils.Date.format(new Date(Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate)), "MMM Do, YYYY h:mm:ss A", StreamEvents.getCompanyAndSite(event, companies, sites).site?.timezone?.split(' ')[0])}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  {<SvgIcon fontSize="small">{StreamEvents.getEventIcon(event, false)}</SvgIcon>}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 1 }}>
                <Typography variant="body2" component="span">
                  {StreamEvents.getTitle(event, getZoneNames(event))}
                </Typography>
                <Typography variant="body2">{getDescription(event)}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </Box>
  )
}

export default MultiEventContent;
