import PubSub from "./PubSub";
import API from '@API/index';

class PubSubSelectedEvents extends PubSub {
  public connect(): void {
    const topicName: string = `${process.env.REACT_APP_STACK}/dashboard/updates`;
    this.subscription = API.PubSub.subscribeToTopic(topicName, this.onMessage, this.onError, this.onComplete);

    this.interval = setInterval(() => { this.checkSubscriptionHealth() }, 15000);
  }

  public static publish(message: any): void {
    const topicName: string = `${process.env.REACT_APP_STACK}/dashboard/updates`;
    API.PubSub.publishOnTopic(topicName, message);
  }
}

export default PubSubSelectedEvents;
