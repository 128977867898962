import Tutorial from "./Tutorial";

class OverivewSiteListTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#overview-site-list-container',
        popover: {
          title: 'Site list',
          description: 'A list that contains all sites present on the selected company with their respective location and camera count.',
          position: 'mid-center'
        }
      },
      {
        element: '#search-header-common-field',
        popover: {
          title: 'Filter',
          description: 'You can search the sites by keyword.',
          position: 'auto'
        }
      },
      {
        element: '#overview-site-list-status-dropdown',
        popover: {
          title: 'All / active / coming soon / maintenance',
          description: 'You can filter sites by their current status.',
          position: 'left'
        }
      },
    ])
  }
}

export default OverivewSiteListTutorial;
