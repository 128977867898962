import { Filter } from "@classes/Filter/Filter";
import { EventsFilterParams } from "./EventsFilter";
import IFilterEvents from "./IFilterEvents";

class SafetyFilter implements IFilterEvents {
  filter(params: EventsFilterParams, events: VideoStreamEvent[]): VideoStreamEvent[] {
    const { safety } = params;

    if(!safety.enable) {
      return [];
    }

    if (safety?.filters?.length) {
      return new Filter(safety.filters).filter(events);
    } else {
      return events;
    }
  }
}

export default SafetyFilter;
