import EventTagLabel from "@components/overview/EventTagLabel";
import { Report, SellOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import EventChange from "./EventChange";

class TagAdded extends EventChange {
  public getIconElement(): JSX.Element {
    if (this._value.toString().includes('escalate')) {
      return <Report fontSize="small" />;
    }

    return <SellOutlined fontSize="small" />;
  }

  public getTextElement(): JSX.Element {
    if (this._value.toString().includes('escalate')) {
      return this.getEscalationRepresentation();
    } else {
      return this.getLabelRepresentation();
    }
  }

  private getEscalationRepresentation(): JSX.Element {
    if (this._author?.isCurrentUser) {
      return (
        <Box display="flex">
          <Box display="flex">
            <Typography variant="body2">
              Event <b>escalated</b> with label&nbsp;
            </Typography>
          </Box>
          <Box display="flex">
            <EventTagLabel noMarginLeft event={{ tags: [this._value as EventTag] }} selected={false} />
          </Box>
          <Box display="flex">
            <Typography variant="body2">
              by you
            </Typography>
          </Box>
        </Box>
      )
    }

    return (
      <Box display="flex">
        <Box display="flex">
          <Typography variant="body2">
            Event <b>escalated</b> with label&nbsp;
          </Typography>
        </Box>
        <Box display="flex">
          <EventTagLabel noMarginLeft event={{ tags: [this._value as EventTag] }} selected={false} />
        </Box>
        <Box display="flex">
          <Typography variant="body2">
            {`by ${this._author?.name || "Unknown"}`}
          </Typography>
        </Box>
      </Box>
    )
  }

  private getLabelRepresentation(): JSX.Element {
    if (this._author?.isCurrentUser) {
      if (this._previousEvent) {
        return (
          <Box display="flex">
            <Box display="flex">
              <Typography variant="body2">
                You<b>&nbsp;changed&nbsp;</b>
              </Typography>
            </Box>
            <Box display="flex">
              <EventTagLabel noMarginLeft event={{ tags: [this._previousEvent.value as EventTag] }} selected={false} />
            </Box>
            <Box display="flex">
              <Typography variant="body2">
                label to&nbsp;
              </Typography>
            </Box>
            <Box display="flex">
              <EventTagLabel noMarginLeft event={{ tags: [this._value as EventTag] }} selected={false} />
            </Box>
          </Box>
        )
      }

      return (
        <Box display="flex">
          <Box display="flex">
            <EventTagLabel noMarginLeft event={{ tags: [this._value as EventTag] }} selected={false} />
          </Box>
          <Box display="flex">
            <Typography variant="body2">
              <b>added&nbsp;</b>{`by you`}
            </Typography>
          </Box>
        </Box>
      )
    }

    if (this._previousEvent) {
      return (
        <Box display="flex">
          <Box display="flex">
            <Typography variant="body2">
              {`${this._author?.name || "Unknown"}`}<b>&nbsp;changed&nbsp;</b>
            </Typography>
          </Box>
          <Box display="flex">
            <EventTagLabel noMarginLeft event={{ tags: [this._previousEvent.value as EventTag] }} selected={false} />
          </Box>
          <Box display="flex">
            <Typography variant="body2">
              label to&nbsp;
            </Typography>
          </Box>
          <Box display="flex">
            <EventTagLabel noMarginLeft event={{ tags: [this._value as EventTag] }} selected={false} />
          </Box>
        </Box>
      )
    }

    return (
      <Box display="flex">
        <Box display="flex">
          <EventTagLabel noMarginLeft event={{ tags: [this._value as EventTag] }} selected={false} />
        </Box>
        <Box display="flex">
          <Typography variant="body2">
            <b>added&nbsp;</b>{`by ${this._author?.name || "Unknown"}`}
          </Typography>
        </Box>
      </Box>
    )
  }
}

export default TagAdded;
