import { SnackbarKey, useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Utils from '@utils/index';
import { IStoreState } from '@store/index';
import * as uuid from 'uuid';
import { setTimelapseRequestAsCanceled, setTimelapseRequestAsDone, setTimelapseRequestAsError, updateTimelapseRequestLink } from '@store/exports/actions';
import API from '@API/index';
import { showCustomSnackbar } from './common';

/**
 * Standalone component that manages Queues for Exporting stream footage.
 */
const TimelapseExporter: React.FC<{}> = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const requests = useSelector((store: IStoreState) => store.exports.timelapseRequests);

  const userCanceledExportsIds = React.useRef<string[]>([]);
  const exportSnackRef = React.useRef<undefined | SnackbarKey>(undefined);
  const exportKeyRef = React.useRef<undefined | string>(undefined);
  const processingExportIdRef = React.useRef<string | undefined>(undefined);
  const exportRequestsRef = React.useRef<TimelapseRequest[]>([]);

  React.useEffect(updateRequestsRef, [requests]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(exportFootage, [requests]);

  function exportFootage(): void { startExport(); }
  function updateRequestsRef(): void { exportRequestsRef.current = requests; }

  async function startExport(): Promise<void> {
    if (processingExportIdRef.current !== undefined) return;

    const r = Utils.Exports.nextInQueue<TimelapseRequest>(exportRequestsRef.current);
    if (processingExportIdRef.current === undefined && r !== undefined) {
      processingExportIdRef.current = r.requestId;
      exportKeyRef.current = uuid.v4();
      try {
        showCustomSnackbar(
          enqueueSnackbar,
          `Generating timelapse for ${r.params.streamName}.`,
          exportSnackRef,
          exportKeyRef,
          () => {
            const request = Utils.Exports.findById<TimelapseRequest>(exportRequestsRef.current, processingExportIdRef.current);
            dispatch(setTimelapseRequestAsCanceled(request?.requestId || ""));
            if (request && request.requestId) userCanceledExportsIds.current.push(request.requestId);
            closeSnackbar(exportSnackRef.current);
            processingExportIdRef.current = undefined;
          }
        );
        await API.Timelapses.createTimelapse({ ...r.params, streamName: r.kvsName, name: r.name, description: r.description }, r.requestId, handleSuccess, handleExportError);
      } catch (error) {
        handleExportError(error, r.requestId);
      }
    }
  }

  async function handleSuccess(_timelapse: Timelapse, requestId: string): Promise<void> {
    if (userCanceledExportsIds.current.find(el => el === requestId)) {
      API.Timelapses.cancelTimelapse(_timelapse.streamName, _timelapse.timelapseId);
      return;
    }
    
    try {
      const request = Utils.Exports.findById<TimelapseRequest>(exportRequestsRef.current, processingExportIdRef.current);
      if (request) {
        const link = await API.Timelapses.getTimelapseVideoLink(_timelapse.location);
        dispatch(updateTimelapseRequestLink(request.requestId, link));
        dispatch(setTimelapseRequestAsDone(request.requestId, _timelapse.timelapseId));
      }
    } catch (error) {
      handleExportError(error, requestId);
    } finally {
      processingExportIdRef.current = undefined;
      closeSnackbar(exportSnackRef.current);

      // Get the next item on the request stack if any.
      setTimeout(() => { startExport() }, 100);
    }
  }

  function handleExportError(error: any, _requestId: string): void {
    if (userCanceledExportsIds.current.find(el => el === _requestId)) return;
    handleError(error, exportSnackRef);
    const target = Utils.Exports.findById(exportRequestsRef.current, processingExportIdRef.current);
    if (target) dispatch(setTimelapseRequestAsError(target.requestId));
    processingExportIdRef.current = undefined;
  }

  function handleError(error: any, snackbarRef: React.MutableRefObject<SnackbarKey | undefined>): void {
    closeSnackbar(snackbarRef.current);
    enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: "error" });
  }

  return null;
}

export default TimelapseExporter;
