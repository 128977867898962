import { useMediaQuery } from '@mui/material';
import React from 'react';

export function useMobileQuery() {
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isTabletOrMobile = useMediaQuery('(max-width: 1000px)');
  
  const [state, setState] = React.useState<boolean>(isPortrait && isTabletOrMobile)

  React.useEffect(() => {
    setState(isPortrait && isTabletOrMobile)
  }, [isPortrait, isTabletOrMobile])

  return state;
}