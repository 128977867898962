import getTwoDigitsUTCHours from './getTwoDigitsUTCHours';
import getTwoDigitsUTCMonth from './getTwoDigitsUTCMonth';
import getTwoDigitsUTCDate from './getTwoDigitsUTCDate';
import stringToDate from './stringToDate';
import getAmPmHours from './getAmPmUTCHours';
import format from './format';
import getAmPmFrom24Hour from './getAmPmFrom24Hour';
import getTimeAgo from './getTimeAgo';
import datesAreOnSameDay from './datesAreOnSameDay';

export interface IDate {
  getTwoDigitsUTCHours: (date: Date) => string;
  getTwoDigitsUTCMonth: (date: Date) => string;
  getTwoDigitsUTCDate: (date: Date) => string;
  stringToDate: (time: string, timezone?: string, day?: number, month?: number, year?: number) => Date;
  getAmPmHours: (date: Date, timezone?: string) => string;
  format(date: Date, format: string, timezone?: string): string;
  getAmPmFrom24Hour(value: string): string;
  getTimeAgo(length: number): string;
  datesAreOnSameDay: (first: Date, second: Date) => boolean;
}

const Date: IDate = {
  getTwoDigitsUTCDate,
  getTwoDigitsUTCHours,
  getTwoDigitsUTCMonth,
  stringToDate,
  getAmPmHours,
  getAmPmFrom24Hour,
  format,
  getTimeAgo,
  datesAreOnSameDay
}

export default Date;
