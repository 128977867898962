import { API } from 'aws-amplify';

async function fetchFilterSuggestions(): Promise<{ colors: string[], states: string[], vehicles: Record<string, string[]> }> {
  try {
    const path: string = `/events/filters/suggestions`;
    const data = await API.get(
      'api-service',
      path,
      {}
    );

    return data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default fetchFilterSuggestions;