import { API } from "aws-amplify";

async function listSites(companyId: string): Promise<Site[]> {
  try {
    const path: string = `/sites/${companyId}`;

    const data = await API.get(
      'api-service',
      path,
      {}
    )

    if (data.data) {
      return data.data as Site[];
    }
    
    return [];
  } catch (error) {
    throw error;
  }
}

export default listSites;
