import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_API_KEY}`

function initializeMap(options : mapboxgl.MapboxOptions ) : mapboxgl.Map{
    const map = new mapboxgl.Map(options);
    return map;
}

export default initializeMap;