import Tutorial from "./Tutorial";

class SavedViewTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#video-clips-saved-described',
        popover: {
          title: 'Video clips',
          description: 'Check out video clips organized by stream generated from selected date and duration.',
          position: 'mid-center'
        }
      },
      {
        element: '#time-interval-saved-description',
        popover: {
          title: 'Time interval',
          description: 'Filter video clips by time period',
          position: 'left'
        }
      },
      {
        element: '#saved-video-clips-description',
        popover: {
          title: 'Select stream',
          description: 'Filter video clips by stream.',
          position: 'left'
        }
      },
      {
        element: '#sort-video-clips-description',
        popover: {
          title: 'Sort by',
          description: 'Sort clips from newest to oldest or vice versa.',
          position: 'left'
        }
      }

    ])
  }
}

export default SavedViewTutorial;
