import { IEdgeDevice } from "@store/assets/types";
import listDevices from "./listDevices";
import restartGreengrass from "./restartGreengrass";

export interface IDevice {
  listDevices(filter: "All" | "Available", siteId?: string, companyId?: string ): Promise<IEdgeDevice[]>;
  restartGreengrass(iotThingName: string): Promise<void>;
}

const EdgeDevices: IDevice = {
  listDevices,
  restartGreengrass
}

export default EdgeDevices;
