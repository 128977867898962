import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import API from '@API/index';

type CompanyUsersLoaderProps = {
  onLoad: (users: User[]) => void;
  onError?: (error: any) => void;
  onRoutineDone?: () => void;
}

const CompanyUsersLoader: React.FC<CompanyUsersLoaderProps> = (props) => {
  const { onLoad, onError, onRoutineDone } = props;

  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);

  const onErrorRef = React.useRef(onError);
  const onLoadRef = React.useRef(onLoad);
  const onRoutineDoneRef = React.useRef(onRoutineDone)

  React.useEffect(fetchUsers, [selectedCompany]);

  function fetchUsers(): void {
    if (selectedCompany) {
      API.Users.getUsers(selectedCompany.companyId)
        .then((users) => {
          onLoadRef.current(users);
        })
        .catch((error) => { if (onErrorRef.current) onErrorRef.current(error) })
        .finally(() => { if (onRoutineDoneRef.current) onRoutineDoneRef.current() });
    }
  }

  return null;
}

export default CompanyUsersLoader;
