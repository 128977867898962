import initializeMap from './initializeMap';
import addCameraIcon from './addCameraIcon';
import addGeoJSONSource from './addGeoJSONSource';

export interface IMapBoxAPI {
  initializeMap(options : mapboxgl.MapboxOptions ) : mapboxgl.Map,
  addGeoJSONSource(map : mapboxgl.Map, id : string, data : GeoJSON.FeatureCollection): void,
  addCameraIcon(map : mapboxgl.Map, id : string): void
}

const MapBox = {
  initializeMap,
  addGeoJSONSource,
  addCameraIcon
}

export default MapBox;
