import getSiteVehicles from "./getSiteVehicles";

export interface IVehiclesAPI {
  getSiteVehicles(siteId: string, companyId?: string): Promise<Array<VehicleDetection>>;
}

const Vehicles: IVehiclesAPI = {
  getSiteVehicles
}

export default Vehicles;
