import deleteCertificate from "./deleteCertificate";
import listCertificates from "./listCertificates";
import createCertificate from "./createCertificate";
import updateCertificate from "./updateCertificate";

export interface ICertificateAPI {
  createCertificate(certificate: Certificate, asCompany?: string): Promise<Certificate>;
  updateCertificate(certificate: Partial<Certificate>, asCompany?: string): Promise<Certificate>;
  listCertificates(workerId: number, asCompany?: string): Promise<Certificate[]>;
  deleteCertificate(workerId: number, certificateId: string, asCompany?: string): Promise<void>;
}

const Certificate: ICertificateAPI = {
  createCertificate,
  updateCertificate,
  listCertificates,
  deleteCertificate
}

export default Certificate;
