import { API } from 'aws-amplify';

async function getWorkerImage(
  workerId: number,
  imageContent: string,
  asCompany?: string
): Promise<string> {
  try {
    const body: any = { imageContent };
    const path = `/workers/${workerId}/images` + (asCompany ? `?asCompany=${asCompany}` : "");
    const options = {
      body
    }

    const result = await API.post(
      'api-service',
      path,
      options
    );
    return result.data as string;
  } catch (error: any) {
    throw error;
  }
}

export default getWorkerImage;
