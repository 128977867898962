import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Games } from '@mui/icons-material';

function isPtzDisabled(stream?: VideoStream): boolean {
  if (stream === undefined) return true;
  if (Boolean(stream.rportCameraId)) return false;
  return !Boolean(stream.features.ptz);
}

type PTZControlButtonProps = {
  onPtzClick: () => void;
  stream?: VideoStream;
  enabled: boolean;
}

export const PTZControlButton: React.FC<PTZControlButtonProps> = (props) => {
  const { stream, onPtzClick, enabled } = props;

  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [tooltipTitle, setTooltipTitle] = React.useState<string>("PTZ");

  React.useEffect(() => {
    const disabled = isPtzDisabled(stream);
    setDisabled(disabled);
  }, [stream])

  React.useEffect(() => {
    const ptzDisabled = isPtzDisabled(stream);
    if (ptzDisabled === false) {
      if (enabled === true) setTooltipTitle("Disconnect");
      else setTooltipTitle("PTZ");
    } else {
      setTooltipTitle("PTZ not set for this camera");
    }
  }, [stream, enabled])

  return (
    <Tooltip title={tooltipTitle} arrow placement="right">
      <span>
        <IconButton
          id="ptz-button-description"
          onClick={onPtzClick}
          disabled={disabled}
          size="small"
        >
          <Games />
        </IconButton>
      </span>
    </Tooltip>
  )
}