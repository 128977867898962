import { getEndOfCurrentDay } from './getEndOfCurrentDay';
import { getStartOfCurrentDay } from './getStartOfCurrentDay';
import getTimeZoneOptions from './getTimeZoneOptions';

export interface ITimezone {
  getTimeZoneOptions(): string[]
  getEndOfCurrentDay(timezone: string): number;
  getStartOfCurrentDay(timezone: string): number;
}

const Timezone: ITimezone = {
  getTimeZoneOptions,
  getEndOfCurrentDay,
  getStartOfCurrentDay
}

export default Timezone;
