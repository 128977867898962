import Actions, { IActionsAPI } from "./actions";
import CloudWatch, { ICloudWatch } from "./cloudwatch";
import Cognito, { ICognito } from "./cognito";
import Company, { ICompanyAPI } from "./company";
import EdgeDevices, { IDevice } from "./devices";
import Downloads, { IDownloadsAPI } from "./downloads";
import Events, { IEvents } from "./events";
import Grids, { IGrids } from "./grids";
import Heatmaps, { IHeatmaps } from "./heatmaps";
import Kinesis, { IKinesis } from "./kinesis";
import Notifications, { INotifications } from "./notifications";
import Permissions, { IPermissionsAPI } from "./permissions";
import Roles, { IRoleAPI } from "./roles";
import ScheduleTemplates, { IScheduleTemplatesAPI } from "./scheduleTemplate";
import Site, { ISiteAPI } from "./site";
import Streams, { IStreamsAPI } from "./streams";
import Timelapses, { ITimelapsesAPI } from "./timelapses";
import Users, { IUsers } from "./users";
import Views, { IView } from "./views";
import Geofences, { IZonesAPI } from "./zones";
import PubSub, { IPubSubAPI } from './pubsub';
import MapBox, { IMapBoxAPI } from "./mapbox";
import Parameters, { IParametersAPI } from "./parameters";
import GlobalEventInbox, { IGlobalEventInbox } from './global_event_inbox';
import AuditLog, { IAuditLogAPI } from './audit_log';
import Attendance, { IAttendanceAPI } from "./attendance";
import Subcontractors, { ISubcontractorsAPI } from "./subcontractor";
import Workers, { IBlacklistedWorkersAPI, IWorkersAPI } from "./worker";
import Integrations, { IIntegrations } from './integrations';
import Snapshots, { ISnapshotAPI } from "./snapshot";
import Terms, { ITerms } from './terms';
import Prometheus, { IPrometheusAPI } from './prometheus';
import AccessRequests, { IAccessRequestsAPI } from "./access_requests";
import Certificates, { ICertificateAPI } from "./certificates"
import Trades, { ITrades } from "./trades";
import Strobes, { IStrobes } from "./strobe";
import Detections, { IDetectionAPI } from "./detection";
import Vehicles, { IVehiclesAPI } from './vehicles';

interface IAPI {
  Kinesis: IKinesis;
  CloudWatch: ICloudWatch;
  Events: IEvents;
  Heatmaps: IHeatmaps;
  Cognito: ICognito;
  EdgeDevices: IDevice;
  Notifications: INotifications;
  Users: IUsers;
  Grids: IGrids;
  Company: ICompanyAPI;
  Site: ISiteAPI;
  Permissions: IPermissionsAPI;
  Streams: IStreamsAPI;
  Geofences: IZonesAPI;
  Timelapses: ITimelapsesAPI;
  Downloads: IDownloadsAPI;
  Views: IView;
  Actions: IActionsAPI;
  ScheduleTemplates: IScheduleTemplatesAPI;
  Roles: IRoleAPI;
  PubSub: IPubSubAPI;
  MapBox: IMapBoxAPI;
  Parameters: IParametersAPI;
  GlobalEventInbox: IGlobalEventInbox;
  AuditLog: IAuditLogAPI;
  Attendance: IAttendanceAPI;
  Subcontractors: ISubcontractorsAPI;
  Workers: IWorkersAPI & IBlacklistedWorkersAPI;
  Integrations: IIntegrations;
  Snapshots: ISnapshotAPI;
  Terms: ITerms;
  Prometheus: IPrometheusAPI;
  AccessRequests: IAccessRequestsAPI;
  Certificates: ICertificateAPI;
  Trades: ITrades;
  Strobes: IStrobes;
  Detections: IDetectionAPI;
  Vehicles: IVehiclesAPI;
}

const API: IAPI = {
  Kinesis,
  Events,
  Heatmaps,
  CloudWatch,
  EdgeDevices,
  Notifications,
  Users,
  Grids,
  Company,
  Site,
  Permissions,
  Streams,
  Geofences,
  Timelapses,
  Downloads,
  Views,
  Actions,
  ScheduleTemplates,
  Roles,
  Cognito,
  PubSub,
  MapBox,
  Parameters,
  GlobalEventInbox,
  AuditLog,
  Attendance,
  Subcontractors,
  Workers,
  Integrations,
  Snapshots,
  Terms,
  Prometheus,
  AccessRequests,
  Certificates,
  Trades,
  Strobes,
  Detections,
  Vehicles
}

export default API;
