import VideoBoundingBoxes from "@classes/VideoBoundingBoxes";
import fetchEvents from "./fetchEvents";
import getBoundingBoxes from "./getBoundingBoxes";
import getEventFootage from "./getEventFootage";
import VideoPoseEstimations from "@classes/VideoPoseEstimations";
import getPoseEstimations from './getPoseEstimations';
import EventsFilter from "@classes/StreamEvents/EventsFilter";
import fetchFilterSuggestions from "./fetchFilterSuggestions";

export interface IEvents {
  getBoundingBoxes: (kvsName: string, start: number, end: number) => Promise<VideoBoundingBoxes>;
  getPoseEstimations: (kvsName: string, start: number, end: number) => Promise<VideoPoseEstimations>;
  getEventFootage: (stream: VideoStream, start: number, end: number) => Promise<{ url: string, timestamp: number }[]>;
  fetchEvents(
    stream: VideoStream,
    startDate: Date,
    endDate: Date,
    filters?: EventsFilter,
    areaFilter?: { x: number, y: number, h: number, w: number }
  ): Promise<APIResponse<VideoStreamEvent[]>>;
  fetchFilterSuggestions(): Promise<{ colors: string[], states: string[], vehicles: Record<string, string[]> }>;
}

const Events: IEvents = {
  getBoundingBoxes,
  getEventFootage,
  fetchEvents,
  getPoseEstimations,
  fetchFilterSuggestions,
}

export default Events;
