import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Mode } from '@mui/icons-material';
import { SnapshotDialog } from './SnapshotDialog';
import { useSnackbar } from 'notistack';
import { Snapshot } from 'bitmovin-player';
import API from '@API/index';
import { KinesisVideo, KinesisVideoArchivedMedia } from 'aws-sdk';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import Utils from '@utils/index';


type SnapshotDialogButtonProps = {
  disabled: boolean;
  kvsName: string;
  getImage: () => Snapshot | undefined;
  timestamp: number;
}

const isSafari = Utils.Browser.isSafari();

export const SnapshotDialogButton: React.FC<SnapshotDialogButtonProps> = (props) => {
  const { kvsName, disabled, getImage, timestamp } = props;

  const credentials = useSelector((store: IStoreState) => store.session.credentials);
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [src, setSrc] = React.useState("");

  async function getSrc() {
    if (isSafari) {
      try {
        const dataEndpoint: string = await API.Kinesis.getDataEndpoint(
          kvsName,
          { videoQuality: "high", } as unknown as KinesisVideo.ClientConfiguration,
          'GET_HLS_STREAMING_SESSION_URL'
        )
        const images: KinesisVideoArchivedMedia.Images = await API.Kinesis.listStreamImages(
          kvsName,
          {},
          dataEndpoint,
          new Date(Math.round(timestamp - 3000)),
          new Date(Math.round(timestamp)),
          credentials
        )
        const alternativeSrc = 'data:image/jpeg;base64,' + images.find(i => i.Error === null)?.ImageContent;
        setSrc(alternativeSrc)
        setOpen(true);
      } catch (error) {
        enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: "error" });
      }
    } else {
      const src = getImage();
      if (src) {
        setSrc(src.data as string);
        setOpen(true);
      } else {
        enqueueSnackbar("Something went wrong...", { variant: "error" });
      }
    }
  }


  return (
    <>
      <Tooltip title={"Snapshot and annotate"} arrow placement="right">
        <span>
          <IconButton
            id="snapshot-button-description"
            onClick={() => getSrc()}
            size="small"
            disabled={disabled}
            data-cy="snapshot-button"
          >
            <Mode />
          </IconButton>
        </span>
      </Tooltip>
      <SnapshotDialog
        src={src}
        open={open}
        kvsName={kvsName}
        onClose={() => setOpen(false)}
        timestamp={timestamp}
        isSafari={isSafari}
      />
    </>
  )
}