import subscribeToTopic from './subscribeToTopic';
import { ZenObservable } from '../../../node_modules/zen-observable-ts'
import publishOnTopic from './publishOnTopic';

export interface IPubSubAPI {
  subscribeToTopic: (topic: string | string[], onMessage: Function, onError?: Function, onComplete?: Function) => ZenObservable.Subscription;
  publishOnTopic(topic: string, message: object | string): void;
}

const PubSub: IPubSubAPI = {
  subscribeToTopic,
  publishOnTopic
}

export default PubSub;
