import { API } from "aws-amplify";

async function postDetection(siteId: string, form: CreateTimeEntryForm, asCompany?: string): Promise<TimeEntry> {
  try {
    const path: string = `/attendance/${siteId}` + (asCompany ? `?asCompany=${asCompany}` : "");
    if(!Boolean(form.streamId?.length)) {
      delete form.streamId;
    }
    const options = {
      body: form
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    return data.data as TimeEntry;
  } catch (error: any) {
    throw error;
  }
}

export default postDetection;
