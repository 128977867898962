import { Board } from '@components/common/KinesisPlayer/ZonesCanvas';
import { Box } from '@mui/material';
import React from 'react';
import Utils from '@utils/index';
import styled from 'styled-components';

const Canvas = styled.canvas`
  position: absolute;
  z-index: 3;
  display: block;

  min-height: 1px;
  min-width: 1px;
`;

export interface IEventContentVideoDetectionBoxesHandles {
  draw: (boxes: EventBoundingBox | undefined, transform: { x: number, y: number, scale: number } | undefined) => void;
}

type EventContentVideoDetectionBoxesProps = {
  videoWidth: number;
  videoHeight: number;
  containerWidth: number;
  containerHeight: number;
  videoWindowOffset: Vector2;
  videoState: "error" | "buffering" | "loading" | "ready";
}

const EventContentVideoDetectionBoxes: React.ForwardRefRenderFunction<IEventContentVideoDetectionBoxesHandles, EventContentVideoDetectionBoxesProps> = (props, ref) => {
  const { containerWidth, containerHeight, videoWidth, videoHeight, videoState } = props;

  React.useImperativeHandle(ref, () => ({
    draw
  }))

  const [canvasSize, setCanvasSize] = React.useState<Vector2>({ x: 0, y: 0 });

  React.useEffect(calculateCanvasSize, [videoWidth, videoHeight, containerWidth, containerHeight]);

  const canvasRef = React.useRef<HTMLCanvasElement | null>(null)

  function draw(boxes: EventBoundingBox | undefined, transform: { x: number, y: number, scale: number } | undefined): void {
    drawBoundingBoxes(canvasRef.current, boxes, transform);
  }

  function calculateCanvasSize() {
    if (videoWidth !== 0 && videoHeight !== 0 && containerWidth !== 0 && containerHeight !== 0) {
      const size = Utils.Geometry.getCanvasSize(
        videoWidth || 0,
        videoHeight || 0,
        containerWidth || 0,
        containerHeight || 0
      );

      setCanvasSize(size);
    }
  }

  function drawBoundingBoxes(
  ref: HTMLCanvasElement | null,
  boundingBox: EventBoundingBox | undefined,
  transform: { x: number, y: number, scale: number } | undefined
): void {
  try {
    if (ref !== null && boundingBox && transform) {
      const context = ref.getContext('2d');
      if (context) {
        context.clearRect(0, 0, canvasSize.x, canvasSize.y);

        context.lineWidth = 1;
        context.fillStyle = "transparent";

        boundingBox.boxes.forEach((box) => {
          context.strokeStyle = box.color;
          context.beginPath();

          const positions: { x: number, y: number }[] = box.coordinates.map((coordinate) => {
            const x = coordinate[0];
            const y = coordinate[1];

            const offsetY = ((containerHeight - canvasSize.y) * (transform.scale - 1)) / 2;
            const offsetX = ((containerWidth - canvasSize.x) * (transform.scale - 1)) / 2;

            // Apply zoom and pan transformations
            const transformedX = (x * (canvasSize.x / videoWidth) * transform.scale) + transform.x + offsetX;
            const transformedY = (y * (canvasSize.y / videoHeight) * transform.scale) + transform.y + offsetY;

            return { x: transformedX, y: transformedY };
          });
          
          if (positions.length === 4) {
            context.beginPath();
          
            context.moveTo(positions[0].x, positions[0].y);
            context.lineTo(positions[1].x, positions[1].y);
            context.stroke();
          
            context.moveTo(positions[1].x, positions[1].y);
            context.lineTo(positions[3].x, positions[3].y);
            context.stroke();
          
            context.moveTo(positions[3].x, positions[3].y);
            context.lineTo(positions[2].x, positions[2].y);
            context.stroke();
          
            context.moveTo(positions[2].x, positions[2].y);
            context.lineTo(positions[0].x, positions[0].y);
            context.stroke();
          }

          context.fill();
        });
      }
    }
  } catch (e) {
    console.error(e);
  }
}

  if (videoState === "error" || videoState === "loading") return null;

  return (
    <Box style={{ pointerEvents: 'none' }} position="absolute" zIndex={32} width={containerWidth} height={containerHeight}>
      <Board
        width={`${canvasSize.x}px`}
        height={`${canvasSize.y}px`}
      >
        <Canvas
          width={canvasSize.x}
          height={canvasSize.y}
          ref={canvasRef}
          style={{
            width: canvasSize.x || 0,
            height: canvasSize.y || 0,
            left: 0,
            top: 0
          }}
          data-cy="event-content-detection-box-canvas"
        />
      </Board>
    </Box>
  )
}

export default React.forwardRef(EventContentVideoDetectionBoxes);
