import { findIndex } from "lodash";

class EventUpdateTracker {
  public static registered: VideoStreamEvent[] = [];

  public static register(event: VideoStreamEvent): void {
    EventUpdateTracker.registered.push(event);
  }

  public static unregister(event: VideoStreamEvent): void {
    const index = findIndex(EventUpdateTracker.registered, { id: event.id });
    if (index > -1) {
      EventUpdateTracker.registered.splice(index, 1);
    }
  }

  public static has(event: VideoStreamEvent): boolean {
    const index = findIndex(EventUpdateTracker.registered, { id: event.id });
    return index !== -1;
  }
}

export default EventUpdateTracker;
