import {
  ActionTypes, ITutorialsState,
} from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: ITutorialsState = {
  tutorialsEnabled: false,
  activeTutorial: undefined
}

export const tutorialsReducer = Utils.Redux.createReducer<
ITutorialsState,
  Types,
  Actions
>(initialState, {
  [ActionTypes.SET_ACTIVE_TUTORIAL]: (state, action) => ({
    ...state,
    activeTutorial: action.value
  }),
  
  [ActionTypes.SET_TUTORIALS_ENABLED]: (state, action) => ({
    ...state,
    tutorialsEnabled: action.value
  })
});
