import { KinesisVideo } from "aws-sdk";

function describeStream(
  streamName: string,
  options: KinesisVideo.ClientConfiguration,
): Promise<KinesisVideo.DescribeStreamOutput> {
  return new Promise((resolve, reject) => {
    const kinesisVideo = new KinesisVideo(options);
    kinesisVideo.describeStream({ StreamName: streamName }, function (err, data) {
      if (err) reject(err);
      resolve(data)
    })
  })
}

export default describeStream;