import { Storage } from 'aws-amplify';
import Utils from '@utils/index';

async function getDownloadImageLink(path: string): Promise<string> {
  try {
    const url = await Storage.get(Utils.S3.getKey(path), {
      bucket: process.env.REACT_APP_DOWNLOAD_IMAGES_S3_BUCKET || "",
      contentDisposition: "attachment",
      expires: 86400
    }) as string;
    
    return url;
  } catch (error) {
    throw error;
  }
}

export default getDownloadImageLink;