class MapBoxBoundaries {
  private _streams: VideoStream[] = [];

  constructor(streams: VideoStream[]) {
    this._streams = streams;
  }

  public generate(margin: number) {
    let lowerLat = 0;
    let lowerLng = 0;
    let highestLat = 0;
    let highestLng = 0;

    for (let i = 0; i < this._streams.length; i += 1) {
      const { latitude, longitude } = this._streams[i];
      
      if (i === 0) {
        lowerLat = latitude;
        lowerLng = longitude;
        highestLat = latitude;
        highestLng = longitude;
      }
      else
      {
        if (latitude < lowerLat) lowerLat = latitude;
        if (longitude < lowerLng) lowerLng = longitude;
        if (latitude > highestLat) highestLat = latitude;
        if (longitude > highestLng) highestLng = longitude;
      }
    }

    return [lowerLng - margin, lowerLat - margin, highestLng + margin, highestLat + margin];
  }
}

export default MapBoxBoundaries;
