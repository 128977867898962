import { API } from "aws-amplify";

async function deleteAction(siteId: string, actionId: string): Promise<Action> {
  try {
    const path: string = `/actions/${siteId}/${actionId}`;

    const data = await API.del(
      'api-service',
      path,
      {}
    );

    return data.data as Action;
  } catch (error: any) {
    throw error;
  }
}

export default deleteAction;

