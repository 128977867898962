export function createVideoDownloadsFilters(isSuperAdministrator?: boolean): FilterProperties[] {
  return [
    { label: "Author", property: 'author', type: 'string' },
    { label: "Name", property: 'name', type: 'string' },
    { label: "Description", property: 'description', type: 'string' },
    { label: "KVS Name", property: 'streamName', type: "string" },
    { label: "Created at", property: 'createdAt', type: "date" },
    { label: "Start time", property: 'startTimestamp', type: "date" },
    { label: "End time", property: 'endTimestamp', type: "date" },
    { label: "Status", property: 'status', type: "select", options: ["SUCCESS", "FAILURE", "IN_PROGRESS", "PARTIAL"] },
  ];
}