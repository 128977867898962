import Utils from '@utils/index';

async function getCompanyLogo(companyId: string, type?: string): Promise<{ url: string }> {
  try {
    if (process.env.REACT_APP_COMPANY_LOGO_S3_BUCKET)
      return Utils.S3.getImageUrl(companyId + (type ? `.${type}`: ""), {},
        process.env.REACT_APP_COMPANY_LOGO_S3_BUCKET) as Promise<{ url: string }>
    else {
      throw Object.assign(
        new Error("Company logo source not set"),
        { code: 404 }
      );
    }
  } catch (error: any) {
    console.log(error);
    throw error;
  }
}

export default getCompanyLogo;
