import ActionsTutorial from './ActionsTutorial';
import AnalyticsTutorial from './AnalyticsTutorial';
import ViewsCameraSearchTutorial from './ViewsCameraSearchTutorial';
import CreateActionTutorial from './CreateActionTutorial';
import CreateViewTutorial from './CreateViewTutorial';
import GetStartedTutorial from './GetStartedTutorial';
import SavedViewTutorial from './SavedViewTutorial';
import TimelineEventsTutorial from './TimelineEventsTutorial';
import TimelineViewTutorial from './TimelineViewTutorial';
import TimelineZonesTutorial from './TimelineZonesTutorial';
import Tutorial from './Tutorial';
import ViewsMapTutorial from './ViewsMapTutorial';
import EventsFilterTutorial from './EventsFilterTutorial';
import ViewsSelectedViewTutorial from './ViewsSelectedViewTutorial';
import OverviewSiteListTutorial from './OverviewSiteListTutorial';
import OverviewSiteMapTutorial from './OverviewSiteMapTutorial';
import OverviewEventInboxTutorial from './OverviewEventInboxTutorial';
import SettingsGeneralTutorial from './SettingsGeneralTutorial';
import SettingsUsersTutorial from './SettingsUsersTutorial';
import SettingsRolesTutorial from './SettingsRolesTutorial';
import SettingsAuditLogsTutorial from './SettingsAuditLogsTutorial';
import TimelineSaveVideoTutorial from './TimelineSaveVideoTutorial';
import HeatmapsOverviewTutorial from './HeatmapsOverviewTutorial';
import HeatmapsDialogTutorial from './HeatmapsDialogTutorial';
import EventsReportsTutorial from './EventsReportsTutorial';

export type TutorialType =
  "analytics" |
  "timeline_events" |
  "create_action" |
  "timeline_zones" |
  "timeline_view" |
  "timeline_save_video" |
  "create_view" |
  "saved_view" |
  "get_started" |
  "actions" |
  "views_camera_search" |
  "views_map" |
  "events_filter" |
  "views_selected_view" |
  "overview_site_list" |
  "overview_site_map" |
  "overview_event_inbox" |
  "settings_general" |
  "settings_users" |
  "settings_roles" |
  "settings_audit_logs" |
  "heatmaps_1" |
  "heatmaps_2" |
  "events_reports";

class TutorialFactory {
  public static createTutorialFor(type: TutorialType): Tutorial {
    if (type === "analytics") return new AnalyticsTutorial();
    if (type === "timeline_events") return new TimelineEventsTutorial();
    if (type === "create_action") return new CreateActionTutorial();
    if (type === "timeline_zones") return new TimelineZonesTutorial();
    if (type === "timeline_view") return new TimelineViewTutorial();
    if (type === "create_view") return new CreateViewTutorial();
    if (type === "saved_view") return new SavedViewTutorial();
    if (type === "get_started") return new GetStartedTutorial();
    if (type === "actions") return new ActionsTutorial();
    if (type === "views_camera_search") return new ViewsCameraSearchTutorial();
    if (type === "views_map") return new ViewsMapTutorial();
    if (type === "events_filter") return new EventsFilterTutorial();
    if (type === "views_selected_view") return new ViewsSelectedViewTutorial();
    if (type === "overview_site_list") return new OverviewSiteListTutorial();
    if (type === "overview_site_map") return new OverviewSiteMapTutorial();
    if (type === "overview_event_inbox") return new OverviewEventInboxTutorial();
    if (type === "settings_general") return new SettingsGeneralTutorial();
    if (type === "settings_users") return new SettingsUsersTutorial();
    if (type === "settings_roles") return new SettingsRolesTutorial();
    if (type === "settings_audit_logs") return new SettingsAuditLogsTutorial();
    if (type === "timeline_save_video") return new TimelineSaveVideoTutorial();
    if (type === "heatmaps_1") return new HeatmapsOverviewTutorial();
    if (type === "heatmaps_2") return new HeatmapsDialogTutorial();
    if (type === "events_reports") return new EventsReportsTutorial();

    throw new Error(`Tutorial not found for keyword: ${type}`)
  }
}

export default TutorialFactory;
