import createWorker from "./createWorker";
import deleteWorker from "./deleteWorker";
import getWorker from "./getWorker";
import getWorkerImages from "./getWorkerImages";
import getWorkerLogs from "./getWorkerLogs";
import getWorkers from "./getWorkers";
import mergeWorkers from "./mergeWorkers";
import updateWorker from "./updateWorker";
import blacklistWorker, { NotificationsParameters } from "./blacklistWorker";
import getBlacklistDetails from "./getBlacklistDetails";
import getBlacklistedAccessAttempts from "./getBlacklistedAccessAttempts";
import unblacklistWorker from "./unblacklistWorker";
import getSimilarWorkers from "./getSimilarWorkers";
import getWorkerImage from "./getWorkerImage";
import { getBlacklistedWorkers } from "./getBlacklistedWorkers";
import { listImagesForReview } from "./listImagesForReview";
import { removeDetectionReview } from "./removeDetectionReview";

/**
 * API interface defining operations related to workers.
 */
export interface IWorkersAPI {
  /**
   * Creates a new worker.
   * @param worker Details of the worker to be created.
   * @returns A promise that resolves to the created worker.
   */
  createWorker(worker: BaseWorker, asCompany?: string): Promise<CrewWorker>;

  /**
   * Deletes a worker based on the provided worker ID.
   * @param workerId ID of the worker to be deleted.
   * @returns A promise that resolves when the worker is successfully deleted.
   */
  deleteWorker(workerId: number, asCompany?: string): Promise<void>;

  /**
   * Retrieves details of a worker based on the provided worker ID.
   * @param workerId ID of the worker to retrieve.
   * @returns A promise that resolves to the worker details.
   */
  getWorker(workerId: number, asCompany?: string): Promise<CrewWorker>;

  /**
   * Retrieves a list of workers based on optional site and subcontractor IDs.
   * @param siteId Optional ID of the site to filter workers.
   * @param subcontractorId Optional ID of the subcontractor to filter workers.
   * @returns A promise that resolves to an array of workers.
   */
  getWorkers(siteId?: string, subcontractorId?: string, asCompany?: string): Promise<CrewWorker[]>;

  /**
   * Retrieves worker images within a specified range.
   * @param workerId ID of the worker for whom images are requested.
   * @param start Start timestamp of the image range.
   * @param end End timestamp of the image range.
   * @returns A promise that resolves to the requested worker images.
   */
  getWorkerImages(workerId: number, start: number, end: number, asCompany?: string): Promise<any>;

  /**
   * Retrieves audit logs for a worker.
   * @param workerId ID of the worker for whom audit logs are requested.
   * @returns A promise that resolves to an array of worker audit logs.
   */
  getWorkerLogs(workerId: number, asCompany?: string): Promise<WorkerAuditLog[]>;

  /**
   * Updates details of an existing worker.
   * @param worker Details of the worker to be updated.
   * @returns A promise that resolves when the worker is successfully updated.
   */
  updateWorker(worker: BaseWorker, asCompany?: string): Promise<any>;

  /**
   * Merges two workers, combining their information.
   * @param fromId ID of the worker to merge from.
   * @param intoId ID of the worker to merge into.
   * @returns A promise that resolves when the workers are successfully merged.
   */
  mergeWorkers(fromId: number, intoId: number, asCompany?: string): Promise<any>;
  removeDetectionReview(pictureUrl: string, asCompany?: string): Promise<void>;
  listImagesForReview(companyId: string): Promise<ImageMatchReview[]>

  /**
   * Retrieves a list of workers that are similar to the specified worker.
   * @param workerId ID of the worker to find similar workers.
   * @param maxMatches Maximum number of similar workers to retrieve.
   * @returns A promise that resolves to an array of similar workers.
   */
  getSimilarWorkers(workerId: number, maxMatches?: number, asCompany?: string): Promise<CrewWorker[]>;/**

  * Retrieves a s3 image for a worker.
  * @param workerId ID of the worker for whom the image is requested.
  * @param imageContent Content of the image to be retrieved.
  * @param companyId Current company worker is managed for.
  * @returns A promise that resolves to the URL or content of the requested image.
  */
  getWorkerImage(workerId: number, imageContent: string, companyId: string): Promise<string>;
}

/**
 * API that handles all blacklist operation for workers and sites.
 */
export interface IBlacklistedWorkersAPI {
  /**
   * Adds the worker to a blacklist on a given site.
   * @param details Parameters for the creation of the backend data to be placed.
   * @param notifications Parameters to identify which system actions are required when a blacklisted worker tries to access a given site.
   */
  blacklistWorker(details: BlacklistDetails, notifications: NotificationsParameters, asCompany?: string): Promise<CrewWorker>;

  /**
   * Removes the worker from the blacklist.
   * @param workerId ID of the worker entity.
   * @param siteId  ID of the site the worker is currently attached to.
   */
  unblacklistWorker(workerId: number, siteId: string, asCompany?: string): Promise<CrewWorker>;

  /**
   * List the details for the blacklist operation on a given site and worker. The details contain all information necessary about the
   * blacklisting of the worker, including author, time and reason.
   * @param workerId ID of the worker entity.
   */
  getBlacklistDetails(workerId: number, asCompany?: string): Promise<BlacklistDetails | undefined>;

  /**
   * List all the access attempts made by a blacklisted worker on a given site. These access attempts have information such as
   * the time of the access try and the stream kvsName that captured it.
   * @param workerId ID of the worker entity.
   */
  getBlacklistedAccessAttempts(workerId: number, asCompany?: string): Promise<Array<BlacklistedAccessAttempt>>;

  /**
   * List all blacklisted workers on a company level.
   * @param companyId ID of the company.
   */
  getBlacklistedWorkers(companyId: string): Promise<CrewWorker[]>;
}

const Workers: IWorkersAPI & IBlacklistedWorkersAPI = {
  createWorker,
  deleteWorker,
  getWorker,
  getWorkers,
  getWorkerImages,
  updateWorker,
  mergeWorkers,
  getWorkerLogs,
  blacklistWorker,
  unblacklistWorker,
  getBlacklistDetails,
  getBlacklistedAccessAttempts,
  getSimilarWorkers,
  getWorkerImage,
  getBlacklistedWorkers,
  listImagesForReview,
  removeDetectionReview
}

export default Workers;
