import { Box } from '@mui/material';
import { MapTabs } from '@store/views/overviewView/types';
import React from 'react';
import SitesMap from './SitesMap';

type OverviewContentProps = {
  activeTab: MapTabs;
}

const MapContent: React.FC<OverviewContentProps> = (props) => {
  const { activeTab } = props;

  return (
    <Box width="100%" height="100%">
      {activeTab === MapTabs.SiteMap && <SitesMap />}
    </Box>
  )
}

export default MapContent;
