import { Field, Operator } from '@classes/Filter/Field';
import React from 'react'
import { Box, IconButton, MenuItem, Select, Typography, capitalize } from '@mui/material';
import { Delete, KeyboardArrowDown } from '@mui/icons-material';

interface IFilterRowProps {
  filter: FilterToApply,
  isMobile: boolean,
  index: number,
  currentFilters: FilterToApply[],
  currentActiveFilters: number,
  restartFilters: () => void,
  setCurrentFilters: React.Dispatch<React.SetStateAction<FilterToApply[]>>,
  properties: FilterProperties[],
  newFilterBasedOnType: (type: "string" | "number" | "date" | "boolean" | "select" | "array" | "multi_select" | "duration", name: string, operators: Operator[]) => Field<any, any>,
  createInputField: (filter: FilterToApply, index: number) => JSX.Element,
  oneRowLayout?: boolean;
  addFilterDefaultValues?: Map<string, string | number | boolean | string[]>;
}

const FilterRow: React.FC<IFilterRowProps> = (props) => {
  const {
    createInputField,
    currentFilters,
    filter,
    index,
    isMobile,
    newFilterBasedOnType,
    properties,
    restartFilters,
    setCurrentFilters,
    oneRowLayout,
    addFilterDefaultValues
  } = props;

  const getOperatorLabel = (operator: Operator, type?: "string" | "number" | "date" | "boolean" | "select" | "array" | "multi_select" | "duration") => {
    if (type === "duration" && operator === "greater_or_equal")
      return "Is longer than"
    if (type === "multi_select" && operator === "is_any_of")
      return "Is/Are"
    return capitalize(operator.split('_').join(' '));
  }

  const operators = React.useMemo(() => filter.filter.getOperators(), [filter.filter]);
  const type = React.useMemo(() => filter.filter.getType(), [filter.filter]);

  return <Box display={"grid"} gap={'0.5rem'} gridTemplateColumns={"auto min-content"}>
    <Box display={oneRowLayout ? "flex" : "grid"} gap={(isMobile || oneRowLayout) ? '0.5rem' : "1rem"} >
      <Box
        key={filter.filter.getName()}
        paddingTop={index > 0 ? '1rem' : undefined}
        minWidth={"10rem"}
      >
        <Select
          label="Property"
          variant="outlined"
          value={filter.filter.getName()}
          IconComponent={KeyboardArrowDown}
          fullWidth
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            style: { zIndex: 9999 }
          }}
          onChange={(e) => {
            const targetProperty = properties.find(el => el.property === e.target.value);

            const newArray = [...currentFilters];
            const shouldPreserveValue = targetProperty?.type !== "multi_select" && newArray[index].filter.getType() === targetProperty?.type 
            newArray[index] = { text: shouldPreserveValue ? newArray[index].text : (addFilterDefaultValues?.get(e.target.value) || ''), filter: newFilterBasedOnType(targetProperty?.type || "string", e.target.value, targetProperty?.operators) };

            setCurrentFilters(newArray);
          }}
          data-cy="filter-property-select"
        >
          {properties.map((el, index) => (
            <MenuItem key={el.property} value={el.property} data-cy={`filter-property-menu-item`} disabled={el.preventSelect}>
              <Typography variant="button">{el.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={isMobile ? 'auto' : (operators.length === 1 ? 'minmax(max-content, auto) max-content' : '1fr 1fr')}
        width={"inherit"}
        gap={(isMobile || oneRowLayout) ? '0.5rem' : "1rem"}
        paddingTop={(index > 0 && oneRowLayout) ? '1rem' : undefined}
      >
        {operators.length === 1
          ? <Typography sx={{ marginY: 'auto' }}>{getOperatorLabel(operators[0], type)}</Typography>
          : <Select
            label="Operators"
            variant="outlined"
            style={{ width: isMobile ? '100%' : 'auto', minWidth: isMobile ? "100%" : "6rem" }}
            value={filter.filter.getCurrentOperator()}
            IconComponent={KeyboardArrowDown}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              style: { zIndex: 9999 }
            }}
            onChange={(e) => {
              const newArray = [...currentFilters];
              const existingFilter = newArray[index].filter;
              const existingText = newArray[index].text
              newArray[index] = { text: existingText, filter: newFilterBasedOnType(existingFilter.getType() || "string", existingFilter.getName(), existingFilter.getOperators()) };
              newArray[index].filter.setCurrentOperator(e.target.value as Operator);
              setCurrentFilters(newArray);
            }}
            data-cy="filter-operator-select"
          >
            {operators.map((el: Operator, index: number) => (
              <MenuItem key={el} value={el} data-cy={`filter-operator-menu-item`}>
                <Typography variant="button">{getOperatorLabel(el, type)}</Typography>
              </MenuItem>
            ))}
          </Select>}
        <Box width={"11rem"}>
          {createInputField(filter, index)}
        </Box>
      </Box>
    </Box>
    <Box paddingTop={index > 0 ? '1rem' : undefined}>
      <IconButton
        // disabled={index === 0 && currentFilters.length === 1 && currentActiveFilters === 0}
        style={{ width: isMobile ? 'min-content' : '100%' }}
        onClick={() => {
          if (currentFilters.length === 1) {
            restartFilters();
          } else {
            const newArray = currentFilters.filter(el => currentFilters.indexOf(el) !== index);
            setCurrentFilters(newArray);
          }
        }}
        data-cy="filter-delete-filter-button"
      >
        <Delete />
      </IconButton>
    </Box>
  </Box>;
}


export default FilterRow