import { API } from 'aws-amplify';

async function createWorker(
  worker: CrewWorker, asCompany?: string
): Promise<CrewWorker> {
  try {
    const body: any = { ...worker };
    delete body.id;
    delete body.footages;
    delete body.notes;
    delete body.userId;
    const path = `/workers` + (asCompany ? `?asCompany=${asCompany}` : "");
    const options = {
      body
    }

    const result = await API.post(
      'api-service',
      path,
      options
    );
    return result.data as CrewWorker;
  } catch (error: any) {
    throw error;
  }
}

export default createWorker;
