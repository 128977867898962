import Tutorial from './Tutorial';

class SettingsRolesTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#settings-roles-main-container',
        popover: {
          title: 'Roles',
          description: 'List of predefined and custom roles assigned by the company to each user of the dashboard. Each user has limited access to the dashboard’s features depending on his/her role in the company.',
          position: 'mid-center'
        }
      }
    ])
  }
}

export default SettingsRolesTutorial;
