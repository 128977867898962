function updateViews(
  grids: { [key: string]: UserView[] },
  selectedSite: Site,
  companyViews: UserView[]
): { [key: string]: UserView[] } {
  const map: { [key: string]: UserView[] } = {};

  Object.entries(grids).forEach(([key, val]) => {
    if (key !== selectedSite.companyId) map[key] = val;
    else map[selectedSite.companyId] = companyViews
  })

  return map;
}

export default updateViews;
