import deleteDetection from "./deleteDetection";
import getDetections from "./getDetections";
import postDetection from "./postDetection";
import updateDetection from "./updateDetection";

export interface IDetectionAPI {
  getDetections(
    startTime: Date,
    endTime: Date,
    siteId: string, 
    asCompany?: string,
    workerId?: number
  ): Promise<{
    entries: Detection[];
    startTime: Date,
    endTime: Date,
    siteId: string,
    workerId?: number
  }>;
  postDetection(siteId: string, form: CreateTimeEntryForm, asCompany?: string): Promise<TimeEntry>;
  updateDetection(siteId: string, id: string, form: CreateTimeEntryForm, asCompany?: string): Promise<TimeEntry>;
  deleteDetection(siteId: string, id: string, asCompany?: string): Promise<void>;
}

const Detection: IDetectionAPI = {
  getDetections,
  postDetection,
  updateDetection,
  deleteDetection
}

export default Detection;
