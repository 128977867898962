import createRole from "./createRole";
import deleteRole from "./deleteRole";
import getRoles from "./getRoles";
import updateRole from "./updateRole";

export interface IRoleAPI {
  getRoles(companyId: string): Promise<Role[]>;
  createRole(role: Role): Promise<Role>;
  updateRole(role: Role): Promise<Role>;
  deleteRole(roleId: string, companyId: string): Promise<void>;
}

const Roles: IRoleAPI = {
  getRoles,
  createRole,
  updateRole,
  deleteRole
}

export default Roles;
