import { IStoreState } from '@store/index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorLoadingCompanies, setErrorLoadingSites, setErrorLoadingUserData } from '@store/views/appView/actions';
import ErrorHandler from './ErrorHandler';

/**
 * Gathers a collection of errors possible from Redux Store and renders one ErrorHandler for each of them.
 * Responsabilities:
 * - Read possible error variables from Redux Store.
 * - Render a ErrorHandler for each possible Redux Store error variables.
 */
const ErrorFeedback: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const errorLoadingCompanies: any | undefined = useSelector((store: IStoreState) => store.appView.errorLoadingCompanies);
  const errorLoadingUserData: any | undefined = useSelector((store: IStoreState) => store.appView.errorLoadingUserData);
  const errorLoadingSites: any | undefined = useSelector((store: IStoreState) => store.appView.errorLoadingSites);

  return (
    <>
      <ErrorHandler
        error={errorLoadingCompanies}
        reset={() => { dispatch(setErrorLoadingCompanies(undefined)) }}
        message="There was a problem loading company data"
      />
      <ErrorHandler
        error={errorLoadingUserData}
        reset={() => { dispatch(setErrorLoadingUserData(undefined)) }}
        message="There was a problem loading your user data"
      />
      <ErrorHandler
        error={errorLoadingSites}
        reset={() => { dispatch(setErrorLoadingSites(undefined)) }}
        message="There was a problem loading your company sites"
      />
    </>
  );
}

export default ErrorFeedback;
