import React from 'react';
import { refreshSession } from './App/AppHooks';

/**
 * Periodically refresh user session using AWS SDK.
 * Responsabilities:
 * - Every 10 minutes, refresh user session using AWS SDK.
 * - Dispatch credentials to the Redux Store.
 */
function usePeriodicallyRefreshSession() {
  React.useEffect(() => {
    const interval = setInterval(() => {
      refreshSession();
    }, 3600000 * 0.50);

    return () => {
      clearInterval(interval);
    }
  }, [])
}

export default usePeriodicallyRefreshSession;