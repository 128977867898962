import React from 'react';
import { DefinedRange } from '../Input/DateRangePicker/types';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';

const dateFns = require('date-fns');
const {
	addDays,
	startOfWeek,
	endOfWeek,
	addWeeks,
	startOfMonth,
	endOfMonth,
	addMonths,
  startOfDay,
  endOfDay
} = dateFns;

export const getDefaultRanges = (date: Date): { label: string, getValue: () => [Date, Date] }[] => [
	{
		label: "Today",
    getValue: () => {
      return [startOfDay(date),  date]
    }
	},
	{
		label: "Yesterday",
    getValue: () => {
      return [startOfDay(addDays(date, -1)), endOfDay(addDays(date, -1))]
    }
	},
	{
		label: "This Week",
    getValue: () => {
      return [startOfWeek(date, {weekStartsOn: 1}), endOfWeek(date, {weekStartsOn: 1})]
    }
	},
	{
		label: "Last Week",
    getValue: () => {
      return [startOfWeek(addWeeks(date, -1), {weekStartsOn: 1}), endOfWeek(addWeeks(date, -1), {weekStartsOn: 1})]
    }
	},
	{
		label: "Last 7 Days",
    getValue: () => {
      return [addWeeks(date, -1), date]
    }
	},
	{
		label: "This Month",
    getValue: () => {
      return [startOfMonth(date), endOfMonth(date)]
    }
	},
	{
		label: "Last Month",
    getValue: () => {
      return [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))]
    }
	}
];

interface IProps {
  startDate: Date,
  endDate: Date
  onDateChange: (start?: Date, end?: Date) => void;
  style?: React.CSSProperties | undefined;
  inlineLayout?: boolean;
  definedRanges?: DefinedRange[];
  error?: string;
  disabled?: boolean;
  disableMargin?: boolean;
}

const DateRangeInput: React.FC<IProps> = (props) => {
  const { startDate, endDate, onDateChange, error, disabled, disableMargin } = props;

  const isSmallScreen = useSmallScreenQuery();

  return (
    <DateRangePicker
      slots={{ field: SingleInputDateRangeField }}
      disabled={disabled}
      defaultValue={[startDate, endDate]}
      onAccept={(value) => {
        if (value[0] && value[1])
        onDateChange(value[0], value[1])
      }}
      slotProps={{
        shortcuts: {
          items: getDefaultRanges(new Date(Date.now())),
          dense: true,
          disablePadding: true
        },
        textField: {
          style: { width: isSmallScreen ? "12rem" : "20rem", marginTop: disableMargin === true ? '0px' : "1.5rem" },
          label: "Time range",
          error: Boolean(error),
          helperText: error,
          // @ts-ignore
          "data-cy": "date-range-input"
        },
        field: {
          format: "iii, dd MMM yyyy"
        }
      }}
    />
  )
}

export default DateRangeInput;
