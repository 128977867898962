import { first, last, sortBy } from "lodash";

class VideoPoseEstimations {
  private _poseEstimations: PoseEstimations[] | undefined = undefined;
  private _currentTimestamp: number = 0;

  constructor (apiData?: PoseEstimations[]) {
    if (apiData) {
      this._poseEstimations = sortBy(apiData, 'timestamp');
    }
  }

  public setCurrentTimestamp(timestamp: number): void {
    this._currentTimestamp = timestamp;
  }

  public getCurrentTimestamp(): number {
    return this._currentTimestamp;
  }

  public getCurrentPoseEstimations(): PoseEstimations | undefined {
    const closest = this.findClosest(this._currentTimestamp);
    return closest;
  }

  public isEmpty(): boolean {
    return this._poseEstimations === undefined || this._poseEstimations.length === 0;
  }

  public getLast(): PoseEstimations | undefined {
    if (this._poseEstimations) {
      return last(this._poseEstimations);
    }
  }

  public getFirst(): PoseEstimations | undefined {
    if (this._poseEstimations) {
      return first(this._poseEstimations);
    }
  }

  private findClosest(target: number): PoseEstimations | undefined {
    if (this._poseEstimations && !this.isEmpty()) {
      const box = this._poseEstimations.reduce(function (prev, current) {
        return (Math.abs(target - current.timestamp) < Math.abs(target - prev.timestamp) ? current : prev)
      })
      if (box) {
        if ((box.timestamp - target) >= 1000 || (target - box.timestamp >= 1000)) return undefined;
        else return box;
      }
    }
  }
}

export default VideoPoseEstimations;
