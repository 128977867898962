import { ISiteGrid } from '@store/selectedStream/types';
import * as Types from './types';

export function setSiteGrids(companyId: string, siteGrids: ISiteGrid[]) {
  return {
    type: Types.ActionTypes.SET_SITE_GRIDS,
    companyId,
    siteGrids
  }
}

export function setFetchingGrids(isFetchingGrids: boolean) {
  return {
    type: Types.ActionTypes.SET_FETCHING_GRIDS,
    isFetchingGrids
  }
}

export function setSelectedSiteGrid(selectedGrid?: ISiteGrid) {
  return {
    type: Types.ActionTypes.SET_SELECTED_GRID,
    selectedGrid
  }
}
