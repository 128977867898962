import EventChange from '@classes/GEI/EventChange';
import EventChangeFactory from '@classes/GEI/EventChangeFactory';
import { API } from 'aws-amplify';

async function listEventChanges(kvsName: string, startTimestamp: number): Promise<EventChange[]> {
  try {
    const path: string = `/global/event/${kvsName}/${startTimestamp}`;

    const data: APIResponse<APIEventChanges[]> = await API.get(
      'api-service',
      path,
      {}
    )

    if (data.data) {
      return EventChangeFactory.createEventChangeList(data.data);
    }

    return [];
  } catch (e) {
    throw e;
  }
}

export default listEventChanges;
