import { ActionTypes, ISitesState } from './types';
import * as actions from './actions';
import Utils from '@utils/index';

type Types = ReduxActionTypes<typeof ActionTypes>;
type Actions = ReduxActions<typeof actions>;

const initialState: ISitesState = {
  sites: new Map<string, Site[]>()
};

export const sitesReducer = Utils.Redux.createReducer<ISitesState, Types, Actions>(
  initialState,
  {
    [ActionTypes.SET_SITES]: (state, action) => ({
      ...state,
      sites: addSite(state.sites, action.companyId, action.sites)
    })
  }
);

function addSite(sites: Map<string, Site[]>, companyId: string, newSites: Site[]): Map<string, Site[]> {
  const newMap = new Map<string, Site[]>(sites);

  newMap.set(companyId, newSites);

  return newMap;
}