import { API } from "aws-amplify";

export async function muteStream(companyId: string, kvsName: string, duration: number): Promise<void> {
  try {
    await API.post(
      'api-service',
      `/muted/streams/${companyId}/${kvsName}/mute`,
      {
        body: {
          duration: duration
        }
      }
    )
  } catch (error) {
    throw error;
  }
}