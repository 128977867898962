import { toNumber } from "lodash";

function edgeTimestampToMiliseconds(edgeTimestamp: number): number {
  const valid = edgeTimestamp.toString().length === Date.now().toString().length;

  if (valid) return edgeTimestamp;
  
  return toNumber(`${edgeTimestamp}`.substring(0, `${edgeTimestamp}`.length - 2));
}

export default edgeTimestampToMiliseconds;
