import Tutorial from './Tutorial';

class SettingsUsersTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#users-table-container',
        popover: {
          title: 'Users table',
          description: 'List of all the users with their email address, account status, and company role information.',
          position: 'mid-center'
        }
      }
    ])
  }
}

export default SettingsUsersTutorial;
