import React from 'react';
import { IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import ZoneThumbnail from '@components/common/ZoneThumbnail';
import { MoreVert } from '@mui/icons-material';

type ZoneListItemProps = {
  zone: Zone,
  index: number,
  onClick?: () => void;
  onCreateNewFrom?: (zone: Zone) => void;
  onZoneMouseEnter?: (zoneId: string) => void;
  onZoneMouseLeave?: (zoneId: string) => void;
}

const ZoneListItem: React.FC<ZoneListItemProps> = (props) => {
  const { zone, index, onClick, onCreateNewFrom, onZoneMouseEnter, onZoneMouseLeave } = props;

  const [anchor, setAnchor] = React.useState<any>(null);

  const handleMenuClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchor(event.target);
  }, [])

  const handleCreateNewFromClick = React.useCallback(() => {
    setAnchor(null);
    if (onCreateNewFrom) onCreateNewFrom(zone)
  }, [onCreateNewFrom, zone])

  return (
    <>
      <ListItem
        onMouseEnter={() => { if (onZoneMouseEnter) onZoneMouseEnter(zone.id) }}
        onMouseLeave={() => { if (onZoneMouseLeave) onZoneMouseLeave(zone.id) }}
        onClick={onClick}
        disableGutters={true}
        button
      >
        <ListItemAvatar>
          <ZoneThumbnail zone={zone} />
        </ListItemAvatar>
        <ListItemText
          primary={zone.name || `Zone ${index}`}
          secondary={
            <React.Fragment>
              <Typography style={{ display: 'inline' }} color="textPrimary" component="span" variant="body2">{`${capitalize(zone.state)}`}</Typography>
              {zone.description !== undefined && zone.description !== "" &&
                ` — ${zone.description}`
              }
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <IconButton size="small" onClick={handleMenuClick} style={{ marginLeft: '0.5rem' }}>
            <MoreVert />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      >
        <MenuItem onClick={handleCreateNewFromClick} >Create new zone from template</MenuItem>
      </Menu>
    </>
  )
}

export default ZoneListItem;
