import { API } from "aws-amplify";

async function postNewCertificate(certificate: Certificate, asCompany?: string): Promise<Certificate> {
  try {
    const path: string = `/certificates/${certificate.workerId}` + (asCompany ? `?asCompany=${asCompany}` : "");

    const tempCertificate: any = {
      ...certificate
    }
    delete tempCertificate.workerId
    delete tempCertificate.id

    const options = {
      body: tempCertificate
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    
    return data.data as Certificate;
  } catch (error: any) {
    throw error;
  }
}

export default postNewCertificate;
