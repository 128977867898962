import { PrometheusQuery } from "./PrometheusQuery";

export class JetsonUsageRAMKbQuery extends PrometheusQuery {
  protected _id: string = "jetson_usage_ram_kb";

  public override getPossibleValues() {
    return [
      { id: "Jetson - GPU RAM", color: "#0048e3" }
    ]
  };

  public override getRefinedData() {
    const refinedData = [];

    if (this.isEmpty()) return [];

    for (let i = 0; i < this._queryData.data.result[0].values.length; i += 1) {
      refinedData.push({
        date: this.formatDate(this._queryData.data.result[0].values[i][0]),
        "Jetson - GPU RAM": this.kbToGigabytes(this._queryData.data.result[0].values[i][1])
      })
    }

    return refinedData;
  }

  public override getDomain() {
    if (this.isEmpty()) return [0, 32]
    return undefined
  }

  public override getMiddleValue() {
    if (this.isEmpty()) return 16;
    return undefined;
  }

  public override getValueLabel() {
    return "GB";
  }
}