import API from '@API/index';
import { store } from '../../index';
import * as GridsActions from '@store/views/gridView/actions';
import * as SelectedCompaniesActions from '@store/selectedCompanies/actions';
import * as SelectedSitesActions from '@store/selectedSites/actions';
import { ISiteGrid } from '@store/selectedStream/types';

export const getAllGrids = async () => {
  const companies = store.getState().companies.companies;
  const promises: Promise<void>[] = [];

  companies.forEach(company => {
    promises.push(getGrids(company.companyId));
  });

  await Promise.all(promises);
}

const getGrids = async (companyId: string) => {
  try {
    const data = await API.Grids.listCompanyGrids(companyId);
    store.dispatch(GridsActions.setSiteGrids(companyId, data));
  } catch (error: any) {

  }
}

const setSelectedCompany = (company?: Company) => {
  store.dispatch(SelectedCompaniesActions.setSelectedCompany(company));
}

const setSelectedSite = (site?: Site) => {
  const { siteGrids } = store.getState().gridView;
  const { selectedCompany } = store.getState().selectedCompanies;
  store.dispatch(SelectedSitesActions.setSelectedSite(site));

  if (selectedCompany && site) {
    const grids = siteGrids[selectedCompany.companyId];
    if (grids) {
      const selectedGrid = grids.find(element => element.siteId === site.siteId);
      setSelectedGrid(selectedGrid);
    }
  } else {
    setSelectedGrid(undefined);
  }
}

const setSelectedGrid = (grid?: ISiteGrid) => {
  store.dispatch(GridsActions.setSelectedSiteGrid(grid));
}

export const setUserDefaultCompany = () => {
  const { userData } = store.getState().session;
  if (userData && userData.roles !== 'superadmin') {
    const { companies } = store.getState().companies;
    const userCompany = companies.find(element => element?.companyId === userData?.companyId);
    if (userCompany)
      setSelectedCompany(userCompany);
  }
}

export const setUserDefaultSite = () => {
  const { userData } = store.getState().session;
  const { companies } = store.getState().companies;
  const { sites } = store.getState().sites;
  if (userData) {
    const userCompany = companies.find(element => element.companyId === userData.companyId);
    if (userCompany) {
      const userSites = sites.get(userCompany.companyId);
      if (userSites) {
        setSelectedSite(userSites[0]);
      }
    }
  }
}

export const updateSelectedGrid = () => {
  const { siteGrids } = store.getState().gridView;
  const { selectedSite } = store.getState().selectedSites;
  const { selectedCompany } = store.getState().selectedCompanies;
  if (selectedSite && selectedCompany) {
    const grids = siteGrids[selectedCompany.companyId];
    if (grids) {
      const targetGrid = grids.find(element => element.siteId === selectedSite.siteId);
      if (targetGrid) {
        setSelectedGrid(targetGrid);
      }
    }
  }
}
