import { API } from 'aws-amplify';
import Utils from '@utils/index';
import getTimelapse from './getTimelapse';

async function createTimelapse(
  params: TimelapseCreationParams,
  requestId: string,
  onSuccess: (timelapse: Timelapse, requestId: string) => void,
  onError: (error: any, requestId: string) => void
): Promise<void> {
  try {
    const path: string = `/timelapse`;
    const data: APIResponse<{ timelapseId: string }> = await API.post(
      'api-service',
      path,
      {
        body: params
      }
    );

    const timelapseId = data.data?.timelapseId;

    if (timelapseId) await notifyWhenTimelapseIsDone(params.streamName, timelapseId, requestId, onSuccess, onError);
    else onError(new Error('Failed to create timelapse.'), requestId);
  } catch (error) {
    console.log(Utils.Error.getErrorMessage(error));
    console.log(error);
    throw onError(error, requestId);
  }
}

async function notifyWhenTimelapseIsDone(
  kvsName: string,
  timelapseId: string,
  requestId: string,
  onSuccess: (timelapse: Timelapse, requestId: string) => void,
  onError: (error: any, requestId: string) => void
): Promise<void> {
  try {
    const response: Timelapse = await getTimelapse(kvsName, timelapseId);

    if (!response) onError({ error: { message: 'Unexpected response on Timelapse creation..' } }, requestId)

    switch (response?.status) {
      case "IN_PROGRESS":
        setTimeout(() => {
          notifyWhenTimelapseIsDone(kvsName, timelapseId, requestId, onSuccess, onError);
        }, 3000)
        break;
      case "FAILURE":
        onError({ error: { message: 'Timelapse creation finished with status of: FAILURE.' } }, requestId)
        break;
      case "MISSING_FRAMES":
        onError({error: { message: 'No data found for the specified period, Timelapse creation failed. Please check your request or contact Forsight support!' }}, requestId)
        break;
      case "PARTIAL":
        onSuccess(response, requestId);
        break;
      case "READY":
        onSuccess(response, requestId);
        break;
      //@ts-ignore
      case "SUCCESS":
        onSuccess(response, requestId);
        break;
      default:
        onError({ error: { message: 'Timelapse creation finished with status of: FAILURE.' } }, requestId)
    }
  } catch (error) {
    console.log(error);
    console.log(Utils.Error.getErrorMessage(error));
    onError(error, requestId);
  }
}

export default createTimelapse;