import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuProps,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { useRef } from 'react';
import { CustomLi, CustomList, CustomUl } from './useStyles';
import styled from 'styled-components';
import useDebouncedState from '@hooks/Debounce/useDebounceState';
import { isEmpty, toNumber } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import API from '@API/index';
import { setSites } from '@store/sites/actions';
import Utils from '@utils/index';
import SearchHeaderFilter from '@components/common/SearchHeaderFilter';
import ProcoreCopyConfig from '@components/common/ProcoreCopyConfig';
import { extractKeywords, matchesKeywordPeriod } from '@utils/filtering/filter-utils';
import SearchHeaderTooltipIcon from '@components/common/SearchHeaderTooltipIcon';
import { selectIsSuperAdminUser } from '@store/selectors';

export const HoverDetector = styled.div`
  transition: opacity 0.3s;
  
  .MuiListItemSecondaryAction-root {
    opacity: 0;
    transition: opacity 0.3s;
  }

  :hover {
    .MuiListItemSecondaryAction-root {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
`

interface ISiteListProps extends MenuProps {
  onSiteSelected: (site?: Site) => void;
  selectedSite?: Site;
  onSiteEdit?: (site: Site) => void;
  onOverviewClick?: () => void;
  isOverviewSelected?: boolean;
  siteScrollTop?: number;
  setSiteScrollTop?: (value: number) => void;
  sites: Site[];
  onManageSites?: () => void;
}

const SiteList: React.FC<ISiteListProps> = (props) => {
  const {
    onSiteSelected,
    selectedSite,
    onSiteEdit,
    open,
    onOverviewClick,
    isOverviewSelected,
    siteScrollTop,
    setSiteScrollTop,
    sites,
    onManageSites,
    ...menuProps
  } = props;

  const dispatch = useDispatch();

  const selectedCompany = useSelector((store: IStoreState) => store.selectedCompanies.selectedCompany);
  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);
  const isSuperAdministrator = useSelector(selectIsSuperAdminUser);

  const [filterText, setFilterText] = useDebouncedState("");
  const [loading, setLoading] = React.useState<boolean>(true);

  const [allSites, setAllSites] = React.useState<Site[]>([]);
  const [filteredSites, setFilteredSites] = React.useState<Site[]>([]);
  const [textFilteredSites, setTextFilteredSites] = React.useState<Site[]>([]);

  const [siteMenuAnchor, setSiteMenuAnchor] = React.useState<HTMLElement | null>(null);
  const [focusedSite, setFocusedSite] = React.useState<Site | undefined>(undefined);

  const siteRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLUListElement>(null)
  const dispatchRef = React.useRef(dispatch);
  const filterTextRef = React.useRef(filterText)
  const setFilterTextRef = React.useRef(setFilterText)
  const siteScrollTopRef = React.useRef(siteScrollTop)
  const selectedCompanyRef = React.useRef(selectedCompany);
  const sitesRef = React.useRef(sites)

  React.useEffect(() => { sitesRef.current = sites }, [sites])
  React.useEffect(() => { selectedCompanyRef.current = selectedCompany }, [selectedCompany])
  React.useEffect(() => { siteScrollTopRef.current = siteScrollTop }, [siteScrollTop])
  React.useEffect(() => { setFilterTextRef.current = setFilterText }, [setFilterText])
  React.useEffect(() => { filterTextRef.current = filterText }, [filterText])
  React.useEffect(() => { dispatchRef.current = dispatch }, [dispatch])
  React.useEffect(() => { setAllSites(sites) }, [sites])
  React.useEffect(() => { if (siteMenuAnchor === null) setFocusedSite(undefined) }, [siteMenuAnchor])

  React.useEffect(fetchCompanySites, [open]);

  React.useEffect(() => {
    if (tableRef?.current && siteScrollTopRef.current && open)
      tableRef.current.scrollTop = siteScrollTopRef.current;
  }, [open])

  React.useEffect(filter, [filteredSites, filterText]);
  React.useEffect(clearSearch, [open]);

  function fetchCompanySites(): void {
    if (selectedCompanyRef.current && open === true) {
      if (sitesRef.current.length === 0) setLoading(true);

      API.Site.listSites(selectedCompanyRef.current.companyId)
        .then((sites) => {
          dispatchRef.current(setSites(selectedCompanyRef.current?.companyId || "", sites));
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 40)
        })
    }
  }

  function clearSearch(): void {
    if (open === false && !isEmpty(filterTextRef.current)) setFilterTextRef.current("");
  }

  /**
   * Filter elements on the Site array using the Filter Text.
   */
  function filter(): void {
    try {
      const keywords = extractKeywords(filterText);
      setTextFilteredSites(filteredSites.filter(element => matchesKeywordPeriod(keywords, element, ["name", "tags"])))
    } catch (e) {
      setTextFilteredSites([]);
    }
  }

  function getCompany(): string {
    if (focusedSite) {
      return focusedSite.companyId;
    }

    return "Error";
  }

  function getSite(): string {
    if (focusedSite) {
      return focusedSite.siteId.split('-')[1];
    }
    return "Error";
  }

  return (
    <Menu
      open={open}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      elevation={3}
      {...menuProps}
      style={{ zIndex: 1301 }}
    >
      <div style={{ zIndex: 999 }} id="appbar-site-list">
        <CustomList>
          <CustomLi>
            <CustomUl>
              <ListSubheader style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem"
              }} >
                <TextField
                  style={{ marginTop: 0 }}
                  autoFocus={true}
                  placeholder="Search"
                  variant="standard"
                  defaultValue={filterText}
                  onChange={(event) => { setFilterText(event.target.value); }}
                  size="medium"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchHeaderTooltipIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchHeaderFilter
                          data={allSites}
                          onFilteredDataChange={(data: any[]) => {
                            setFilteredSites(data);
                          }}
                          properties={Utils.Filters.createSitesFilters(isSuperAdministrator || false, allSites)}
                        />
                      </InputAdornment>
                    )
                  }}
                  data-cy="site-list-search-input"
                />
              </ListSubheader>
            </CustomUl>
          </CustomLi>
        </CustomList>
        <Divider />
        <CustomList data-cy="site-list-site-list" ref={tableRef} onScroll={(e) => setSiteScrollTop && setSiteScrollTop(e.currentTarget.scrollTop)}>
          <CustomLi>
            <CustomUl>
              <HoverDetector data-cy={`button-all-sites`} key={-1}>
                <ListItemButton selected={selectedSite === undefined} onClick={() => { onSiteSelected(undefined) }} key={-1} >
                  <ListItemText>
                    <Tooltip title="All Sites" enterDelay={200}>
                      <Typography variant={selectedSite === undefined ? "subtitle2" : "body2"}>
                        {`All Sites`}
                      </Typography>
                    </Tooltip>
                  </ListItemText>
                  {onManageSites &&
                    <ListItemSecondaryAction>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSiteMenuAnchor(null);
                          onManageSites()
                        }}
                        variant="text"
                        data-cy={`site-list-all-sites-edit-button`}
                      >
                        <Typography color={selectedSite === undefined ? 'primary' : undefined} variant={selectedSite === undefined ? 'subtitle2' : 'body2'} >Manage</Typography>
                      </Button>
                    </ListItemSecondaryAction>
                  }
                </ListItemButton>
              </HoverDetector>
              {textFilteredSites.length ?
                Utils.Array.orderAlphabetically(textFilteredSites, 'name').map(site => (
                  <HoverDetector data-cy={`button-${site.siteId}`} key={textFilteredSites.indexOf(site)}>
                    <ListItemButton ref={selectedSite === site ? siteRef : null} selected={selectedSite?.siteId === site.siteId} onClick={() => { onSiteSelected(site) }} key={sites.indexOf(site)} >
                      <ListItemText>
                        <Tooltip title={site.name.length > 15 ? site.name : ""} enterDelay={200}>
                          <Typography variant={selectedSite?.siteId === site.siteId ? "subtitle2" : "body2"}>
                            {`${site.name.slice(0, 25)}${site.name.length > 25 ? "..." : ""}`}
                          </Typography>
                        </Tooltip>
                      </ListItemText>
                      {onSiteEdit ? (
                        <ListItemSecondaryAction>
                          <Button onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onSiteEdit(site)
                          }}
                            variant="text"
                            data-cy={`site-list-${site.name}-edit-button`}
                          >
                            <Typography color={selectedSite?.siteId === site.siteId ? 'primary' : undefined} variant={selectedSite === site ? 'subtitle2' : 'body2'} >Edit</Typography>
                          </Button>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setFocusedSite(site)
                              setSiteMenuAnchor(e.currentTarget);
                            }}
                          >
                            <MoreVert fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      ) : (
                        <ListItemSecondaryAction>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSiteMenuAnchor(e.currentTarget);
                            }}
                          >
                            <MoreVert fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  </HoverDetector>
                )) :
                loading === false ? <Typography style={{ margin: "0.5rem 1rem" }} variant="body2">No results found.</Typography> :
                  <CircularProgress style={{ margin: "0.5rem 1rem" }} size={toNumber(rootFontSize)} data-cy="sites-circular-progress" />}
              <Divider />
            </CustomUl>
          </CustomLi>
        </CustomList>
      </div>
      <Menu style={{ zIndex: 9999 }} open={Boolean(siteMenuAnchor)} anchorEl={siteMenuAnchor}>
        <ProcoreCopyConfig
          onClick={() => setSiteMenuAnchor(null)}
          forcePattern={{
            path: 'cameras',
            company: getCompany(),
            site: getSite()
          }}
        />
      </Menu>
    </Menu>
  )
}

export default SiteList;
