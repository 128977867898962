import React from 'react';
import API from '@API/index';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { setSites } from '@store/sites/actions';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';

/**
 * Hook that will perform an update to Redux Store (Sites) when called.
 * This hook is useful when you want to check for updates on backend and update the App State at the same time.
 * @param companyId ID of the company to perform the update.
 * @param deps Dependencies to trigger the update logic.
 * 
 * @example
 * const Component: React.FC<{}> = () => {
 *  // Updates Sites every time this component mounts.
 *  useUpdateSites("forsight_company");
 * }
 * 
 * const Component: React.FC<{}> = () => {
 *  const [selectedCompanyId, setSelectedCompanyId] = React.useState<string>("forsight_company");
 *  const [selectedTab, setSelectedTab] = React.useState<number>(0);
 * 
 *  // Update Sites every time the selectedTab state changes.
 *  useUpdateSites(selectedCompanyId, [selectedTab])
 * }
 */
function useUpdateSites(companyId?: string, deps?: React.DependencyList) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const sites = useSelector((store: IStoreState) => store.sites.sites);
  
  const sitesRef = React.useRef(sites);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => { sitesRef.current = sites }, [sites]);

  const perform = React.useCallback(() => {
    if (companyId) {
      API.Site.listSites(companyId)
        .then((result: Site[]) => {
          dispatch(setSites(companyId, result));
        })
        .catch((e) => {
          enqueueSnackbar(Utils.Error.getErrorMessage(e), { variant: 'error' })
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }, [companyId, dispatch, enqueueSnackbar])

  React.useEffect(perform, [...(deps || []), companyId, perform])

  return loading;
}

export default useUpdateSites;