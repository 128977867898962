import { API } from "aws-amplify";

async function postNewSite(site: Site): Promise<Site> {
  try {
    const path: string = `/sites/${site.companyId}`;

    const tempSite: any = {
      ...site
    }
    delete tempSite.companyId
    delete tempSite.siteId

    const options = {
      body: tempSite
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    
    return data.data as Site;
  } catch (error: any) {
    throw error;
  }
}

export default postNewSite;
