import React from 'react'
import sector from '@turf/sector';
import mapboxgl from 'mapbox-gl';

type CameraBackgroundProps = {
  map: mapboxgl.Map,
  visibleCanvasId?: string;
  stream: VideoStream;
}

const CanvasComponents: React.FC<CameraBackgroundProps> = (props) => {
  const { map, stream, visibleCanvasId } = props;

  const kvsName = React.useRef(stream.kvsName);
  const mapRef = React.useRef(map);
  const streamRef = React.useRef(stream);
  const visibleCanvasIdRef = React.useRef(visibleCanvasId);

  React.useEffect(() => { kvsName.current = stream.kvsName }, [stream.kvsName]);
  React.useEffect(() => { mapRef.current = map }, [map]);
  React.useEffect(() => {  streamRef.current = stream }, [stream]);
  React.useEffect(() => { visibleCanvasIdRef.current = visibleCanvasId }, [visibleCanvasId]);

  const addGeometry = React.useCallback(() => {
    if (streamRef.current.range && streamRef.current.angleEastOfNorth && streamRef.current.fovWidth && streamRef.current.latitude && streamRef.current.longitude) {
      const FOV_LINES_CANVAS_ID = `${streamRef.current.kvsName}-canvas`;
      const b = sector(
        [streamRef.current.longitude, streamRef.current.latitude],
        streamRef.current.range,
        streamRef.current.angleEastOfNorth - (streamRef.current.fovWidth / 2),
        streamRef.current.angleEastOfNorth + (streamRef.current.fovWidth / 2),
        { units: 'feet', steps: 512 }
      );
  
      const lineSource = mapRef.current.getSource(FOV_LINES_CANVAS_ID);
      if (!lineSource) {
        mapRef.current.addSource(FOV_LINES_CANVAS_ID, {
          type: 'geojson',
          data: b
        })
      }
  
      const lineSourceCast = lineSource as mapboxgl.GeoJSONSource;
      if (lineSourceCast) {
        lineSourceCast.setData(b);
      }
    }
  }, [])

  const addLineLayer = React.useCallback(() => {
    const LINE_OPACITY = getLineOpacity();
    const COLOR = getColor();
    const FOV_LINES_CANVAS_ID = `${streamRef.current.kvsName}-canvas`;
    const FOV_LINES_LAYER_LINE_ID = `${streamRef.current.kvsName}-layer`;

    const lineLayer = mapRef.current.getLayer(FOV_LINES_LAYER_LINE_ID);
    if (lineLayer) {
      mapRef.current.removeLayer(FOV_LINES_LAYER_LINE_ID);
    }

    try {
      mapRef.current.addLayer({
        id: FOV_LINES_LAYER_LINE_ID,
        type: 'line',
        source: FOV_LINES_CANVAS_ID,
        layout: {

        },
        paint: {
          "line-color": COLOR,
          "line-width": 2,
          "line-opacity": LINE_OPACITY
        }
      })
    } catch (e) { }
  }, [])

  const addFillLayer = React.useCallback(() => {
    const FILL_OPACITY = getFillOpacity();
    const COLOR = getColor();
    const FOV_LINES_CANVAS_ID = `${streamRef.current.kvsName}-canvas`;
    const FOV_LINES_LAYER_FILL_ID = `${streamRef.current.kvsName}-fill`
    const fillLayer = mapRef.current.getLayer(FOV_LINES_LAYER_FILL_ID);

    if (fillLayer) {
      mapRef.current.removeLayer(FOV_LINES_LAYER_FILL_ID)
    }

    try {
      mapRef.current.addLayer({
        id: FOV_LINES_LAYER_FILL_ID,
        type: 'fill',
        source: FOV_LINES_CANVAS_ID,
        layout: {

        },
        paint: {
          "fill-color": COLOR,
          "fill-opacity": FILL_OPACITY,
        }
      })
    } catch (e) { }
  }, [])

  const addSourceAndLayer = React.useCallback(() => {
    addGeometry();
    addLineLayer();
    addFillLayer();
  }, [addGeometry, addLineLayer, addFillLayer])

  React.useEffect(addSourceAndLayer, [visibleCanvasId, addSourceAndLayer]);
  React.useEffect(cleanUp, []);

  function cleanUp() {
    return () => {
      if (mapRef.current) {
        const FOV_LINES_LAYER_LINE_ID = `${kvsName.current}-layer`;
        const FOV_LINES_LAYER_FILL_ID = `${kvsName.current}-fill`

        try { mapRef.current.removeLayer(FOV_LINES_LAYER_LINE_ID); } catch (e) {}
        try { mapRef.current.removeLayer(FOV_LINES_LAYER_FILL_ID); } catch (e) {}
      }
    }
  }

  /**
   * Get the line opacity to be used, offline streams
   * have lower opacity lines than online or events.
   */
  function getLineOpacity(): number {
    return visibleCanvasIdRef.current === "Offline" ? 0.1 : 1;
  }

  /**
   * Get the fill opacity for the geometry, offline streams
   * have lower opacity than online streams or events.
   */
  function getFillOpacity(): number {
    return visibleCanvasIdRef.current === "Offline" ? 0.1 : 0.5;
  }

  /**
   * Get the color based on the event that is currently active.
   */
  function getColor(): string {
    if (visibleCanvasIdRef.current === "Offline") return '#000'
    else if (visibleCanvasIdRef.current === "default") return '#000'
    else if (visibleCanvasIdRef.current === "Motion") return '#2D076B'
    else if (visibleCanvasIdRef.current === "Geofence triggered") return '#393939'
    else if (visibleCanvasIdRef.current === "Safety infraction") return '#8F5D13'
    else if (visibleCanvasIdRef.current === "Person distancing") return '#093F27'
    else if (visibleCanvasIdRef.current === "Vehicle detected") return '#07596B'
    // legacy
    else if (visibleCanvasIdRef.current === "Fire detected") return '#6B1507'
    else if (visibleCanvasIdRef.current === "Social distancing") return '#093F27'
    else if (visibleCanvasIdRef.current === "License plate detected") return '#07596B'
    else return "#000"
  }

  return null;
}


export default CanvasComponents;