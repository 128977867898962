import React from 'react';
import {
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import { TimelineView } from '@pages/TimelineView';
import SatelliteView from '@pages/SatelliteView';
import EventsView from '@pages/EventsView';
import MapView from '@pages/MapView';
import HealthView from '@pages/HealthView';
import SitesView from '@pages/SitesView';
import CompaniesView from '@pages/CompaniesView';

const PolicyView = React.lazy(() => import('@pages/PolicyView'));
const GetStartedView = React.lazy(() => import('@pages/GetStartedView'));
const MyAccountView = React.lazy(() => import('@pages/MyAccountView'));
const SiteView = React.lazy(() => import('@pages/SiteView'));
const CameraView = React.lazy(() => import('@pages/CameraView'));
const OrganizationView = React.lazy(() => import('@pages/OrganizationView'));
const LoginView = React.lazy(() => import('@pages/LoginView'));
const NotFoundView = React.lazy(() => import('@pages/NotFoundView'));
const PasswordResetView = React.lazy(() => import('@pages/PasswordResetView'));
const RegisterView = React.lazy(() => import('@pages/RegisterView'));
const ViewsView = React.lazy(() => import('@pages/ViewsView'));
const ActionsView = React.lazy(() => import('@pages/ActionsView'));
const AnalyticsView = React.lazy(() => import('@pages/AnalyticsView'));
const HomeView = React.lazy(() => import('@pages/HomeView'));
const ReactivateUserView = React.lazy(() => import('@pages/ReactivateUserView'));
const ExportsView = React.lazy(() => import('@pages/ExportsView'));
const ManagementView = React.lazy(() => import('@pages/ManagementView'));
const WorkersView = React.lazy(() => import('@pages/WorkersView'));
const VehiclesView = React.lazy(() => import('@pages/VehiclesView'))

type MainSwitchProps = {
  isAuthenticated: boolean;
  isSuperAdministrator: boolean;
  isAdministrator: boolean;
  isTemporaryAccessRole: boolean;
}

/**
 * Component responsible for rendering the main switch of the app,
 * depending on the current browser route, it renders a different component.
 * Responsabilities:
 * - Renders the current selected View Component based on the browser location.
 */
const MainSwitch: React.FC<MainSwitchProps> = (props) => {
  const { isAuthenticated, isSuperAdministrator, isAdministrator } = props;

  const requestAuth = (node: React.ReactNode, additionalCond = true) => isAuthenticated && additionalCond ? node : <Navigate to={"/login"} replace />

  return (
    <main>
      <Routes>
        <Route
          path="/cameras"
          element={requestAuth(<ViewsView />)}
        />

        <Route
          path="/site"
          element={requestAuth(<SiteView />, isAdministrator)}
        />

        <Route
          path="/camera"
          element={requestAuth(<CameraView />)}
        />

        <Route
          path="/organization"
          element={requestAuth(<OrganizationView />, isSuperAdministrator)}
        />

        <Route
          path="/my-account"
          element={requestAuth(<MyAccountView />)}
        />

        <Route
          path="/timeline"
          element={requestAuth(<TimelineView />)}
        />

        <Route
          path="/vehicles"
          element={requestAuth(<VehiclesView />)}
        />

        <Route
          path="/actions"
          element={requestAuth(<ActionsView />)}
        />

        <Route
          path="/get-started"
          element={requestAuth(<GetStartedView />)}
        />

        <Route
          path="/exports"
          element={requestAuth(<ExportsView />)}
        />

        <Route
          path="/analytics"
          element={requestAuth(<AnalyticsView />)}
        />

        <Route
          path="/events"
          element={requestAuth(<EventsView />)}
        />

        <Route
          path="/map"
          element={requestAuth(<MapView />)}
        />

        <Route
          path="/health"
          element={requestAuth(<HealthView />)}
        />

        <Route
          path="/companies"
          element={requestAuth(<CompaniesView />, isSuperAdministrator)}
        />

        <Route
          path="/satellite"
          element={requestAuth(<SatelliteView />)}
        />

        <Route
          path="/sites"
          element={requestAuth(<SitesView />)}
        />

        <Route
          path="/management"
          element={requestAuth(<ManagementView />)}
        />

        <Route
          path="/workers/:userId"
          element={requestAuth(<WorkersView />, isSuperAdministrator)}
        />
        <Route
          path="/add-worker"
          element={requestAuth(<WorkersView />, isSuperAdministrator)}
        />
        <Route
          path="/workers"
          element={requestAuth(<WorkersView />, isSuperAdministrator)}
        />
        <Route
          path="/workers/attendance"
          element={requestAuth(<WorkersView />, isSuperAdministrator)}
        />
        <Route
          path="/workers/subcontractors"
          element={requestAuth(<WorkersView />, isSuperAdministrator)}
        />

        <Route
          path="/"
          element={requestAuth(<HomeView />)}
        />

        <Route
          path="/policy"
          element={<PolicyView />}
        />

        <Route
          path="/register/:userId"
          element={<RegisterView />}
        />

        <Route
          path="/login"
          element={<LoginView />}
        />

        <Route
          path="/login/reset"
          element={<PasswordResetView />}
        />
        <Route
          path="/login/reactivate/:userId"
          element={<ReactivateUserView />}
        />
        <Route
          path="/login/reactivate"
          element={<ReactivateUserView />}
        />
        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </main>
  )
}

export default MainSwitch;
