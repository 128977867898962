import FilterPanel, { FilterPanelProps } from '@components/timeline/CommandPanel/EventsTab/FilterPanel';
import { Box } from '@mui/material';
import { IStoreState } from '@store/index';
import React from 'react';
import { useSelector } from 'react-redux';
import EventInboxOptions from './EventInboxOptions';
import EventInboxZonesPanel from './EventInboxZonesPanel';

type EventInboxRightPanelProps = {
  filterPanelProps: FilterPanelProps;
}

const EventInboxRightPanel: React.FC<EventInboxRightPanelProps> = (props) => {
  const { filterPanelProps } = props;
  const eventInboxSidePanel = useSelector((store: IStoreState) => store.overviewView.eventInboxSidePanel);

  if (eventInboxSidePanel === "options_panel") return (
    <EventInboxOptions />
  )

  if (eventInboxSidePanel === "zones") return (
    <EventInboxZonesPanel />
  )

  if (eventInboxSidePanel === "filter_panel") return (
    <Box
      overflow="hidden"
      display="grid"
      width="100%"
      gap="0.5rem"
      height="100%"
      paddingTop="0.5rem"
      paddingRight="0.5rem"
      paddingLeft="0.5rem"
      borderLeft="1px solid rgba(0, 0, 0, 0.12)"
      id="overview-event-inbox-events-filter-container"
    >
      <FilterPanel {...filterPanelProps} />
    </Box>
  )

  return null;
}

export default EventInboxRightPanel;
