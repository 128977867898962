import { API } from "aws-amplify";

export async function getMutedStreams(companyId: string): Promise<string[]> {
  try {
    const data = await API.get(
      'api-service',
      `/muted/streams/${companyId}`,
      {}
    )

    return data.data as string[];
  } catch (error) {
    throw error;
  }
}