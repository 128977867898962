import EventChange from "./EventChange";
import OwnerChanged from "./OwnerChanged";
import TabChanged from "./TabChanged";
import TagAdded from "./TagAdded";
import UnknownChange from "./UnknownChange";
import { store } from '../../index';
import Utils from '@utils/index';
import TagRemoved from "./TagRemoved";
import { findIndex } from "lodash";

class EventChangeFactory {
  public static createEventChangeList(apiObjects: APIEventChanges[]): EventChange[] {
    const filtered = apiObjects.filter(el => {
      if (el.type === "TAG_REMOVED") {
        const index = findIndex(apiObjects, el);
        if (index > 0 && apiObjects[index - 1].type === "TAG_ADDED" && apiObjects[index - 1].value !== apiObjects[index].value) return false;
        for (let i = index; i < apiObjects.length; i += 1) {
          if (apiObjects[i].type === "TAG_ADDED") return false;
        }

        return true;
      }

      return true;
    });

    return filtered.map((object) => {
      let previousEvent: APIEventChanges | undefined = undefined;

      if (object.type === "TAG_ADDED") {
        const added = apiObjects.filter(el => el.type === "TAG_ADDED");
        const elementIndex = added.indexOf(object);
        const previousIndex = elementIndex - 1;
        if (previousIndex > -1) {
          previousEvent = {...added[previousIndex]};
        }
      }

      return this.createEventChange(object, previousEvent);
    }).reverse();
  }

  public static createEventChange(apiObject: APIEventChanges, previousEvent?: APIEventChanges): EventChange {
    const users = store.getState().users.users;

    const currentUser = store.getState().session.userData;

    const authorUser = currentUser?.userId === apiObject.userId ? currentUser : users.find(el => el.userId === apiObject.userId);
    const targetUser = currentUser?.userId === apiObject.value ? currentUser : users.find(el => el.userId === apiObject.value);

    let author = undefined;
    let target = undefined;

    if (authorUser) {
      author = {
        isCurrentUser: authorUser.userId === currentUser?.userId,
        name: Utils.User.getUserName(authorUser)
      }
    }

    if (targetUser) {
      target = {
        isCurrentUser: targetUser.userId === currentUser?.userId,
        name: Utils.User.getUserName(targetUser)
      }
    }

    if (apiObject.type === "OWNER_CHANGED") {
      return new OwnerChanged(apiObject, author, target)
    }

    if (apiObject.type === "TAB_CHANGED") {
      return new TabChanged(apiObject, author, target);
    }

    if (apiObject.type === "TAG_ADDED") {
      return new TagAdded(apiObject, author, target, previousEvent);
    }

    if (apiObject.type === "TAG_REMOVED") {
      return new TagRemoved(apiObject, author, target);
    }

    return new UnknownChange(apiObject);
  }
}

export default EventChangeFactory;
