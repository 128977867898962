import React from 'react';
import { Avatar as MuiAvatar, Badge, Box, Skeleton, Typography, Tooltip } from '@mui/material';
import { Settings } from '@mui/icons-material';
import UserPictureManager from '@classes/UserPictureManager';
import { Variant } from '@mui/material/styles/createTypography';
import Utils from '@utils/index';

function generateUserInitials(name: string): string {
  const parts = name.split(' ');
  if (parts.length === 2) return `${parts[0].charAt(0)}${parts[1].charAt(0)}`;
  else return `${parts[0].charAt(0)}`;
}

function extractUserName(givenName?: string, familyName?: string): string {
  if (!givenName) return 'User'
  if (!familyName) return givenName;
  return `${givenName} ${familyName}`;
}

type AvatarProps = {
  id?: string;
  user?: User;
  userId?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  width: string | number;
  height: string | number;
  fontSize?: number | string;
  fontVariant?: Variant;
  nameGap?: string;
  color?: string;
  withName?: boolean;
  badge?: boolean;
  notRegistered?: boolean;
  deactivated?: boolean;
  margin?: string;
  disableGap?: boolean;
  lineHeight?: number;
  group?: boolean;
  tooltip?: string;
  src?: string;
  fontColor?: string;
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    user,
    userId,
    givenName,
    familyName,
    email,
    width,
    height,
    fontSize,
    fontVariant,
    color,
    withName,
    badge,
    notRegistered,
    deactivated,
    margin,
    disableGap,
    lineHeight,
    id,
    group,
    nameGap,
    tooltip,
    src,
    fontColor
  } = props;

  const [avatarSrc, setAvatarSrc] = React.useState<string | undefined>(undefined);
  const [avatarBgColor, setAvatarBgColor] = React.useState<string | undefined>(undefined);
  const [avatarInitials, setAvatarInitials] = React.useState<string | undefined>(undefined);
  const [srcStatus, setSrcStatus] = React.useState<"loading" | "error" | "success">("loading");

  React.useEffect(() => {
    if (src) {
      setAvatarSrc(src);
    } else {
      if (user) {
        UserPictureManager.instance.getUserPicture(user)
          .then((url) => {
            if (url === "") {
              setSrcStatus("error");
            } else {
              setAvatarSrc(url);
            }
          })
      } else {
        setSrcStatus("error");
      }
    }
  }, [user, userId, src]);

  React.useEffect(() => {
    const initials = generateUserInitials(extractUserName(user?.givenName || givenName, user?.familyName || familyName));
    setAvatarInitials(initials.replace(/[^A-Za-z0-9 ]+/g, ''));
  }, [user, givenName, familyName]);

  React.useEffect(() => {
    if (notRegistered)
      return;
    if (color === undefined) {
      const bgColor = Utils.User.generateBackgroundFromColorRange(user?.givenName ||
        givenName ||
        user?.familyName ||
        familyName);
      setAvatarBgColor(bgColor);
    } else {
      setAvatarBgColor(color);
    }
  }, [user, givenName, familyName, color, notRegistered]);

  const MAvatar = () =>
    <MuiAvatar
      id={id}
      style={{
        backgroundColor: srcStatus === "success" || srcStatus === "loading" ? 'transparent' : avatarBgColor,
        color: 'white',
        opacity: deactivated ? 0.26 : 1,
        alignSelf: "center",
      }}
      key={id}
      sx={{ width, height }}
      src={srcStatus === "success" ? avatarSrc : undefined}
    >
      {notRegistered ?
        <></>
        : srcStatus === "loading" ? <Skeleton variant="circular" style={{ width: width, height: height }} />
          : <Typography variant="body2" style={{ fontSize: fontSize || "1rem", fontWeight: 700, lineHeight: lineHeight }}>{avatarInitials}</Typography>
      }
      <img
        style={{ display: 'none' }}
        src={avatarSrc}
        onLoad={() => { setSrcStatus("success"); }}
        onError={() => { setSrcStatus("error"); }}
        alt=""
      />
    </MuiAvatar>

  const displayText = () => {
    if (notRegistered)
      return "Registration pending"
    if ((user?.givenName || givenName) && (!user?.familyName && !familyName))
      return `${user?.givenName || givenName}`
    if (user?.givenName || givenName)
      return `${user?.givenName || givenName} ${user?.familyName || familyName}`
    return user?.email || email;
  }

  if (group) {
    return MAvatar();
  }

  return (
    <Tooltip title={tooltip || ""} arrow>
      <Box
        display="grid"
        gap={Boolean(disableGap) === true ? undefined : (nameGap || "1.5rem")}
        gridTemplateColumns="min-content auto"
        margin={margin}
        alignItems={"center"}
      >
        {badge ?
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<Settings fontSize="small" style={{ background: "rgba(255, 255, 255, 0.9)", borderRadius: "50%" }} />}
          >
            {MAvatar()}
          </Badge> :
          MAvatar()}
        {withName && <Typography
          variant={fontVariant || "body1"}
          style={{ lineHeight: "2rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", verticalAlign: "center", color: fontColor, fontSize: fontSize }}
        >{displayText()}</Typography>}
      </Box>
    </Tooltip>
  );
}