import Tutorial from "./Tutorial";

class CreateViewTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#title-description',
        popover: {
          title: 'Name of the view',
          description: 'Customize a name for your view so that the title best describes the camera location and subject matter.',
          nextBtnText: "Next",
          position: 'left'
        }
      },
      {
        element: '#layout-description',
        popover: {
          title: 'Layout shapes',
          description: 'Select a preferable layout that suits your view. The predefined layouts make it easy to customize and organize your view.',
          position: 'left'
        }
      },
      {
        element: '#available-cameras-search-description',
        popover: {
          title: 'Available cameras',
          description: 'The search bar enables you to find the stream names that you want to add to your layout. The filter button is a drop-down to choose between currently available active streams or archived streams.',
          position: 'left'
        }
      },
      {
        element: '#cameras-on-present-construction-yard-description',
        popover: {
          title: 'Select your streams',
          description: 'With your mouse, drag and drop your desired stream(s) into your preferred spot within the layout.',
          position: 'left'
        }
      },
      {
        element: '#expanded-view-description',
        popover: {
          title: 'Expanded view',
          description: 'Expands the selected layout and enables the user to drag and drop the streams to the preferred place in the grid. After customizing the layout, click the ‘save’ button on the top right corner of the page.',
          position: 'mid-center'
        }
      },
    ])
  }
}

export default CreateViewTutorial;
