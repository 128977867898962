import KVSDataEndpoint from "@classes/KVSDataEndpoint";
import { KinesisVideo } from "aws-sdk";

function getDataEndpoint(
  streamName: string,
  options: KinesisVideo.ClientConfiguration,
  APIName: KinesisVideo.APIName,
  nocache: boolean = false
): Promise<string> {
  const kinesisVideo = new KinesisVideo(options);

  return new Promise((resolve, reject) => {
    const preDefinedEndpoint = KVSDataEndpoint.instance.getStreamEndpoint(streamName);
    if (preDefinedEndpoint && nocache === false) {
      resolve(preDefinedEndpoint);
    }

    kinesisVideo.getDataEndpoint(
      {
        StreamName: streamName,
        APIName
      },
      function (err, response) {
        if (err) {
          return reject(err);
        }

        if (nocache === false) KVSDataEndpoint.instance.setStreamEndPOint(streamName, response.DataEndpoint || "");
        resolve(response.DataEndpoint || "");
      }
    );
  });
}

export default getDataEndpoint;
