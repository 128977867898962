import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { ExpandMore, Language, LocationOn } from '@mui/icons-material';
import React from 'react';

interface ISiteSelectionProps {
  selectedSite?: Site;
  open: boolean;
  onSiteClick: (event: any) => void;
  selectedCompany?: Company;
}

const SiteSelection: React.FC<ISiteSelectionProps> = (props) => {
  const { selectedSite, open, onSiteClick, selectedCompany } = props;

  const expandMoreRef = React.useRef(null);

  const [tooltipOpen, setTooltipOpen] = React.useState<boolean>(false);
  const [hovered, setHovered] = React.useState<boolean>(false);

  const hoveredRef = React.useRef<boolean>(false);

  const icon = React.useMemo(() => {
    if (selectedCompany !== undefined && selectedSite === undefined) {
      return <Language id="site-selection-icon" />
    }

    return <LocationOn id="site-selection-icon" />
  }, [selectedCompany, selectedSite])

  React.useEffect(() => {
    hoveredRef.current = hovered;
  }, [hovered]);

  function handleMouseEnter() {
    if (open === false) {
      setHovered(true);

      setTimeout(() => {
        if (hoveredRef.current === true) {
          setTooltipOpen(true);
        }
      }, 200)
    }
  }

  function handleMouseLeave() {
    if (open === false) {
      setHovered(false);
      setTooltipOpen(false);
    }
  }

  function handleClick(event: any): void {
    if (open === false) {
      onSiteClick({ currentTarget: expandMoreRef.current })
    } else {
      onSiteClick(event);
    }
  }

  return (
    <ListItem disabled={selectedCompany === undefined} button onClick={handleClick} data-cy="site-selection-dropdown-button">
      <ListItemIcon data-cy="button-sites" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Tooltip open={tooltipOpen} arrow title={selectedSite?.name ? selectedSite.name : selectedCompany ? 'All Sites' : "Select a Site"} placement="right">
          <span>
            {icon}
          </span>
        </Tooltip>
        {open === false ? (<ExpandMore ref={expandMoreRef} />) : null}
      </ListItemIcon>
      <Tooltip enterDelay={500} title={selectedSite?.name ? selectedSite.name : selectedCompany ? 'All Sites' : "Select a Site"} >
        <ListItemText primaryTypographyProps={{ variant: 'body1', noWrap: true }} primary={selectedSite?.name ? selectedSite.name : selectedCompany ? 'All Sites' : "Select a Site"} />
      </Tooltip>
      <ExpandMore />
    </ListItem>
  )
}

export default SiteSelection;
