import CompanyPubSubUpdates from './CompanyPubSubUpdates';
import PubSub from './PubSub';
import PubSubCompany from './PubSubCompany';
import PubSubGeofence from './PubSubGeofence';
import PubSubMotion from './PubSubMotion';
import PubSubMutedStreams from './PubSubMutedStreams';
import PubSubPerson from './PubSubPerson';
import PubSubSafetyInfraction from './PubSubSafetyInfraction';
import PubSubSelectedEvents from './PubSubSelectedEvents';
import PubSubStream from './PubSubStream';
import PubSubStrobes from './PubSubStrobes';
import PubSubVehicle from './PubSubVehicle';

const MUTED_TOPIC_NAME = "muted-status-changes";
const EVENT_SELECTION_CHANGE = "selection-changes"

class PubSubsManager {
  private static _instance: PubSubsManager;
  private _subscriptions: Map<string, PubSub> = new Map();

  /**
   * Returns the instance of the Singleton.
   */
  public static get instance() {
    if (!PubSubsManager._instance) {
      PubSubsManager._instance = new PubSubsManager();
    }

    return this._instance
  }

  private constructor () { }

  public async subscribeToStream(stream: VideoStream, onMessage: Function, onError?: Function, onComplete?: Function) {
    try {
      if (Boolean(this._subscriptions.get(stream.kvsName))) return;

      const pubSub: PubSub = new PubSubStream(stream, onMessage, onError, onComplete);
      pubSub.connect();

      this._subscriptions.set(stream.kvsName, pubSub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async subscribeToCompany(company: Company, onMessage: Function, onError?: Function, onComplete?: Function) {
    try {
      if (Boolean(this._subscriptions.get(company.companyId))) return;

      const pubSub: PubSub = new PubSubCompany(company, onMessage, onError, onComplete);
      pubSub.connect();

      this._subscriptions.set(company.companyId, pubSub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async subscribeToCompanyByEventType(company: Company, eventType: "motion" | "person-detected" | "vehicle-detected" | "safety-infraction" | "geofence-triggered", onMessage: Function, onError?: Function, onComplete?: Function) {
    try {
      if (Boolean(this._subscriptions.get(`${company.companyId}-${eventType}`))) return;

      let pubSub: PubSub | null = null;

      switch (eventType) {
        case "geofence-triggered":
          pubSub = new PubSubGeofence(company, onMessage, onError, onComplete);
          pubSub.connect();
          break;
        case "motion":
          pubSub = new PubSubMotion(company, onMessage, onError, onComplete);
          pubSub.connect();
          break;
        case "person-detected":
          pubSub = new PubSubPerson(company, onMessage, onError, onComplete);
          pubSub.connect();
          break;
        case "safety-infraction":
          pubSub = new PubSubSafetyInfraction(company, onMessage, onError, onComplete);
          pubSub.connect();
          break;
        case "vehicle-detected":
          pubSub = new PubSubVehicle(company, onMessage, onError, onComplete);
          pubSub.connect();
          break
        default:
          return
      }

      if (pubSub)
        this._subscriptions.set(`${company.companyId}-${eventType}`, pubSub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async subscribeToCompanyUpdates(company: Company, onMessage: Function, onError?: Function, onComplete?: Function) {
    try {
      if (Boolean(this._subscriptions.get(`updates-${company.companyId}`))) return;

      const pubSub: PubSub = new CompanyPubSubUpdates(company, onMessage, onError, onComplete);
      pubSub.connect();

      this._subscriptions.set(`updates-${company.companyId}`, pubSub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async subscribeToMutedStreamsUpdates(onMessage: Function, onError?: Function, onComplete?: Function) {
    try {
      if (Boolean(this._subscriptions.get(MUTED_TOPIC_NAME))) return;

      const pubSub: PubSub = new PubSubMutedStreams(onMessage, onError, onComplete);
      pubSub.connect();

      this._subscriptions.set(MUTED_TOPIC_NAME, pubSub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async subscribeToEventSelectionUpdates(onMessage: Function, onError?: Function, onComplete?: Function) {
    try {
      if (Boolean(this._subscriptions.get(EVENT_SELECTION_CHANGE))) return;

      const pubSub: PubSub = new PubSubSelectedEvents(onMessage, onError, onComplete);
      pubSub.connect();

      this._subscriptions.set(EVENT_SELECTION_CHANGE, pubSub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async subscribeToStrobe(endpoint: string, onMessage: Function, onError?: Function, onComplete?: Function) {
    try {
      if (Boolean(this._subscriptions.get(endpoint))) return;

      const pubSub: PubSub = new PubSubStrobes(endpoint, onMessage, onError, onComplete);
      pubSub.connect();

      this._subscriptions.set(endpoint, pubSub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async unsubscribe(subscription: { kvsName?: string, companyId: string }) {
    try {
      const sub = subscription.kvsName || subscription.companyId;

      if (!Boolean(this._subscriptions.get(sub))) return;
      this._subscriptions.get(sub)?.disconnect();
      this._subscriptions.delete(sub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async unsubscribeToCompanyEvents(company: Company) {
    try {
      if (this._subscriptions.has(`${company.companyId}-motion`)) {
        this._subscriptions.get(`${company.companyId}-motion`)?.disconnect();
        this._subscriptions.delete(`${company.companyId}-motion`)
      }

      if (this._subscriptions.has(`${company.companyId}-person-detected`)) {
        this._subscriptions.get(`${company.companyId}-person-detected`)?.disconnect();
        this._subscriptions.delete(`${company.companyId}-person-detected`)
      }

      if (this._subscriptions.has(`${company.companyId}-vehicle-detected`)) {
        this._subscriptions.get(`${company.companyId}-vehicle-detected`)?.disconnect();
        this._subscriptions.delete(`${company.companyId}-vehicle-detected`)
      }

      if (this._subscriptions.has(`${company.companyId}-safety-infraction`)) {
        this._subscriptions.get(`${company.companyId}-safety-infraction`)?.disconnect();
        this._subscriptions.delete(`${company.companyId}-safety-infraction`)
      }

      if (this._subscriptions.has(`${company.companyId}-geofence-triggered`)) {
        this._subscriptions.get(`${company.companyId}-geofence-triggered`)?.disconnect();
        this._subscriptions.delete(`${company.companyId}-geofence-triggered`)
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async unsubscribeToCompanyEventsByType(company: Company, eventType: "motion" | "person-detected" | "vehicle-detected" | "safety-infraction" | "geofence-triggered") {
    try {
      switch (eventType) {
        case "geofence-triggered":
          if (this._subscriptions.has(`${company.companyId}-geofence-triggered`)) {
            this._subscriptions.get(`${company.companyId}-geofence-triggered`)?.disconnect();
            this._subscriptions.delete(`${company.companyId}-geofence-triggered`)
          }
          break;
        case "motion":
          if (this._subscriptions.has(`${company.companyId}-motion`)) {
            this._subscriptions.get(`${company.companyId}-motion`)?.disconnect();
            this._subscriptions.delete(`${company.companyId}-motion`)
          }
          break;
        case "person-detected":
          if (this._subscriptions.has(`${company.companyId}-person-detected`)) {
            this._subscriptions.get(`${company.companyId}-person-detected`)?.disconnect();
            this._subscriptions.delete(`${company.companyId}-person-detected`)
          }
          break;
        case "safety-infraction":
          if (this._subscriptions.has(`${company.companyId}-safety-infraction`)) {
            this._subscriptions.get(`${company.companyId}-safety-infraction`)?.disconnect();
            this._subscriptions.delete(`${company.companyId}-safety-infraction`)
          }

          break;
        case "vehicle-detected":
          if (this._subscriptions.has(`${company.companyId}-vehicle-detected`)) {
            this._subscriptions.get(`${company.companyId}-vehicle-detected`)?.disconnect();
            this._subscriptions.delete(`${company.companyId}-vehicle-detected`)
          }
          break
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async unsubscribeToCompanyUpdates(subscription: { companyId: string }) {
    try {
      const sub = `updates-${subscription.companyId}`;

      if (!Boolean(this._subscriptions.get(sub))) return;
      this._subscriptions.get(sub)?.disconnect();
      this._subscriptions.delete(sub);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async unsubscribeToMutedUpdates() {
    try {
      if (!Boolean(this._subscriptions.get(MUTED_TOPIC_NAME))) return;
      this._subscriptions.get(MUTED_TOPIC_NAME)?.disconnect();
      this._subscriptions.delete(MUTED_TOPIC_NAME);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async unsubscribeToEventSelectionUpdates() {
    try {
      if (!Boolean(this._subscriptions.get(EVENT_SELECTION_CHANGE))) return;
      this._subscriptions.get(EVENT_SELECTION_CHANGE)?.disconnect();
      this._subscriptions.delete(EVENT_SELECTION_CHANGE);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async unsubscribeToStrobes(endpoint: string) {
    try {
      if (!Boolean(this._subscriptions.get(endpoint))) return;
      this._subscriptions.get(endpoint)?.disconnect();
      this._subscriptions.delete(endpoint);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public isSubscribedTo(topicName: string): boolean {
    return this._subscriptions.get(topicName) !== undefined;
  }

  public getNumberOfSubscriptions(): number {
    return Array.from(this._subscriptions.keys()).length;
  }

  public getNumberOfOpenSockets(): number {
    return Array.from(this._subscriptions.values()).filter(el => el.isClosed() === false).length;
  }

  public getHealthPercentage(): number {
    const total: number = this.getNumberOfSubscriptions();
    const current: number = this.getNumberOfOpenSockets();

    if (total > 0) {
      return current / total;
    }

    return 0;
  }
}
export default PubSubsManager;
