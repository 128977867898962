import { Box } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { EventsTabProps } from './EventsTab/EventsTab';
import { ExportsTabProps } from './ExportsTab/ExportsTab';
import TabRenderer from './TabRenderer';
import TabSelector from './TabSelector';
import { ZoneTabProps } from './ZonesTab/ZonesTab';


export enum CommandPanelTabs {
  Events = "events",
  Zones = "zones",
  Exports = "exports",
  None = ""
}

export type CommandPanelProps = {
  onTabChange?: (tab: CommandPanelTabs) => void;
  zoneTabProps: ZoneTabProps;
  eventsTabProps: EventsTabProps;
  exportsTabProps: ExportsTabProps;
  features?: StreamFeatures;
}

const CommandPanel: React.FC<CommandPanelProps> = (props) => {
  const { onTabChange, zoneTabProps, eventsTabProps, exportsTabProps, features } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = React.useState<CommandPanelTabs>(CommandPanelTabs.Events);

  const onTabChangeRef = React.useRef(onTabChange);

  React.useEffect(() => { onTabChangeRef.current = onTabChange }, [onTabChange])
  React.useEffect(() => {
    if (onTabChangeRef.current) onTabChangeRef.current(activeTab);
  }, [activeTab])

  function handleTabClick(tab: CommandPanelTabs): void {
    if (tab !== activeTab || searchParams.get("tab") !== activeTab) {
      setActiveTab(tab);
      searchParams.set("tab", tab)
      setSearchParams(searchParams, { replace: true })
    }
  }

  return (
    <Box display="grid" gridTemplateRows="min-content auto" height="100%">
      <TabSelector
        selectedTab={activeTab}
        onTabClick={handleTabClick}
        features={features}
      />
      <TabRenderer
        selectedTab={activeTab}
        eventsTabProps={eventsTabProps}
        zoneTabProps={zoneTabProps}
        exportsTabProps={exportsTabProps}
        features={features}
      />
    </Box>
  )
}

export default CommandPanel;
