import { ISiteGrid } from '@store/selectedStream/types';
import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_SITE_GRIDS: null,
  SET_SELECTED_GRID: null,
  SET_FETCHING_GRIDS: null
});

export interface IGridViewState {
  isFetchingGrids: boolean;
  siteGrids: { [key: string]: ISiteGrid[] };
  selectedGrid?: ISiteGrid;
}