import getUserName from './getUserName';
import findUsersByIds from './findUsersByIds';
import bulkActionToPerform from './bulkActionToPerform';
import havePhoneNumber from './havePhoneNumber';
import generateBackgroundFromColorRange from './generateBackgroundFromColorRange';

export interface IUser {
  getUserName(user: { givenName: string, familyName?: string }): string;

  /**
   * Find users objects within a list based on the IDs.
   * @param ids IDs to be found.
   * @param users User collection to iterate.
   */
  findUsersByIds(ids: string[], users: User[]): User[];

  /**
   * Assert which actions can be performed based on a user selection.
   * @param users Collection of users to evaluate.
   */
  bulkActionToPerform(users: User[]): Array<"ACTIVATE" | "REINVITE" | "DEACTIVATE">;

  havePhoneNumber(user?: Partial<User>): boolean;
  generateBackgroundFromColorRange(value?: string): string | undefined;
}

const User: IUser = {
  getUserName,
  findUsersByIds,
  bulkActionToPerform,
  havePhoneNumber,
  generateBackgroundFromColorRange
}

export default User;
