import React from 'react';
import {
  Box,
  ButtonProps,
  IconButton,
  Typography,
} from '@mui/material';
import {
  useGridApiContext,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';

const CustomGridTreeDataGroupingCell: React.FC<GridRenderCellParams> = (props) => {
  const { id, field, rowNode, value, row } = props;
  const { streamName, kvsName } = row;

  const apiRef = useGridApiContext();

  //@ts-ignore
  const isExpanded = rowNode.childrenExpanded ? rowNode.childrenExpanded : false;

  const handleClick: ButtonProps['onClick'] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !isExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  if (row.type === "CAMERA") {
    return (
      <Box style={{ marginLeft: '6rem' }}>
        <Typography>{streamName || kvsName}</Typography>
      </Box>
    )
  }

  if (row.type === "SITE") {
    return (
      <Box style={{ marginLeft: '2rem' }} alignItems="center" display="grid" gridTemplateColumns="min-content auto" gap="1rem">
        <IconButton size="small" onClick={handleClick}>
          {isExpanded ? <KeyboardArrowDown fontSize="large" /> : <KeyboardArrowRight fontSize="large" />}
        </IconButton>
        <Typography>{value}</Typography>
      </Box>
    )
  }

  // COMPANY
  return (
    <Box id="reports-company" width="100%" alignItems="center" display="grid" gridTemplateColumns="min-content min-content auto" gap="1rem">
      <IconButton size="small" onClick={handleClick}>
        {isExpanded ? <KeyboardArrowDown fontSize="large" /> : <KeyboardArrowRight fontSize="large" />}
      </IconButton>
      <Typography>{value}</Typography>
    </Box>
  )
}

export default CustomGridTreeDataGroupingCell;
