import TutorialButton from '@components/common/TutorialButton';
import { FilterList, PentagonOutlined, Settings } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { IStoreState } from '@store/index';
import { setActiveSidePanel } from '@store/views/overviewView/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConnectivityStatus from './ConnectivityStatus';
import ToolbarIcon from './ToolbarIcon';

type ToolbarProps = {
  loadingTab: boolean;
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { loadingTab } = props;

  const dispatch = useDispatch();

  const eventInboxSidePanel = useSelector((store: IStoreState) => store.overviewView.eventInboxSidePanel);
  const tutorialsEnabled = useSelector((store: IStoreState) => store.tutorials.tutorialsEnabled);

  function handleFiltersClick(): void {
    if (eventInboxSidePanel === "none" || eventInboxSidePanel === "options_panel" || eventInboxSidePanel === "zones") dispatch(setActiveSidePanel("filter_panel"))
    else dispatch(setActiveSidePanel("none"));
  }

  function handleOptionsClick(): void {
    if (eventInboxSidePanel === "none" || eventInboxSidePanel === "filter_panel" || eventInboxSidePanel === "zones") dispatch(setActiveSidePanel("options_panel"))
    else dispatch(setActiveSidePanel("none"));
  }

  function handleZonesClick(): void {
    if (eventInboxSidePanel === "none" || eventInboxSidePanel === "filter_panel" || eventInboxSidePanel === "options_panel") dispatch(setActiveSidePanel("zones"))
    else dispatch(setActiveSidePanel("none"))
  }

  return (
    <Box
      height="100%"
      borderLeft="1px solid rgba(0, 0, 0, 0.12)"
      padding="0.2rem"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {tutorialsEnabled &&
        <TutorialButton
          tutorialType="overview_event_inbox"
          IconButtonProps={{ style: { padding: 8 } }}
        />
      }
      <ToolbarIcon
        tooltipTitle='Filters'
        icon={<FilterList />}
        onClick={handleFiltersClick}
        color={eventInboxSidePanel === "filter_panel" ? 'primary' : undefined}
        dataCy="overview-toolbar-filter-icon-button"
      />
      <ToolbarIcon
        tooltipTitle='Zones'
        icon={<PentagonOutlined />}
        onClick={handleZonesClick}
        color={eventInboxSidePanel === "zones" ? 'primary' : undefined}
        dataCy="overview-toolbar-zones-icon-button"
      />
      <ToolbarIcon
        tooltipTitle='Options'
        icon={<Settings />}
        onClick={handleOptionsClick}
        color={eventInboxSidePanel === "options_panel" ? 'primary' : undefined}
        dataCy="overview-toolbar-options-icon-button"
      />
      <ConnectivityStatus />
      {loadingTab === true &&
        <ToolbarIcon
          tooltipTitle='Events are being scanned from the database...'
          icon={<CircularProgress size="1.5rem" data-cy="toolbar-circular-progress" />}
          disableRipple
        />
      }
    </Box>
  )
}

export default Toolbar;
