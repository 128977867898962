import React from 'react';
import { Box, Button, Checkbox, CircularProgress, Dialog, FormControlLabel, Typography } from '@mui/material';
import styled from 'styled-components';
import { Auth, Storage } from 'aws-amplify';
import { clearUser, setUserData } from '@store/session/actions';
import { useDispatch, useSelector } from 'react-redux';
import API from '@API/index';
import { IStoreState } from '@store/index';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';
import PrivacyPolicyDialog from './PrivacyPolicyDialog';

const DOCXContainer = styled.div`
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-right: 30vw;
	padding-left: 30vw;
`;

type TermsOfUseDialogProps = {
	open: boolean;
	onUserCancel?: () => void;
	onUserAgreed?: () => void;
}

const TermsOfUseDialog: React.FC<TermsOfUseDialogProps> = (props) => {
	const { open, onUserCancel, onUserAgreed } = props;

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const userData = useSelector((store: IStoreState) => store.session.userData);

	const [userReadTerms, setUserReadTerms] = React.useState<boolean>(false);
	const [documentLoaded, setDocumentLoaded] = React.useState<boolean>(false);
	const [document, setDocument] = React.useState<string>("");
	const [loadingAccept, setLoadingAccept] = React.useState<boolean>(false);
	const [loadingCancel, setLoadingCancel] = React.useState<boolean>(false);
	const [privacyDialogOpen, setPrivacyDialogOpen] = React.useState<boolean>(false);

	const enqueueSnackbarRef = React.useRef(enqueueSnackbar);

	const documentVersion = React.useRef<string>("");

	React.useEffect(() => { enqueueSnackbarRef.current = enqueueSnackbar }, [enqueueSnackbar])

	React.useEffect(() => { downloadTermsOfUse() }, [])
	React.useEffect(() => {
		if (open === false) {
			setUserReadTerms(false);
		}
	}, [open])

	async function downloadTermsOfUse() {
		try {
			const data = await Storage.get('terms.html', {
				bucket: process.env.REACT_APP_TERMS_OF_USE_BUCKET || "",
				download: true
			})

			documentVersion.current = data.VersionId || "";
			const reader = new FileReader();

			reader.addEventListener('loadend', (e) => {
				if (e.target && e.target.result) {
					setDocumentLoaded(true);
					setDocument(e.target.result as string)
				}
			});

			reader.readAsText(data.Body as Blob);

		} catch (error) {
			enqueueSnackbarRef.current(Utils.Error.getErrorMessage(error), { variant: 'error' })
			console.log(error);
		}
	}

	async function handleUserContinue(): Promise<void> {
		setLoadingAccept(true);
		try {
			await API.Terms.accept(documentVersion.current);
			const data = await API.Users.getUserData(userData?.userId || "");
			dispatch(setUserData(data))
		} catch (error) {
			enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' })
			console.log(error);
			throw error;
		} finally {
			setLoadingAccept(false);
			if (onUserAgreed) onUserAgreed();
		}
	}

	async function handleUserCancel(): Promise<void> {
		setLoadingCancel(true);
		try {
			await Auth.signOut();
			setLoadingCancel(false);
			dispatch(clearUser());
		} catch (error) {
			enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' })
			console.log(error);
			dispatch(clearUser());
		} finally {
			if (onUserCancel) onUserCancel();
			window.location.reload();
		}
	}

	return (
		<Dialog style={{ zIndex: 9999 }} open={open} fullScreen PaperProps={{ style: { borderRadius: 0 } }} >
			{documentLoaded === false ? (
				<Box width="100%" height="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
					<CircularProgress />
				</Box>
			) : (
				<Box>
					<DOCXContainer dangerouslySetInnerHTML={{ __html: document }} />
					<Box margin="1rem" display="grid" justifyContent="center" alignItems="center">
						<FormControlLabel
							style={{ pointerEvents: 'none' }}
							control={<Checkbox style={{ pointerEvents: 'all' }} size="small" onChange={() => setUserReadTerms(!userReadTerms)} checked={userReadTerms} data-cy='terms-of-use-understood-checkbox'/>}
							label={<Typography style={{ paddingTop: '2px' }} variant="caption" >By clicking here, I state that I have read and understood the terms and conditions and agree to our <Typography variant="caption" style={{ color: 'blue', pointerEvents: 'all' }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setPrivacyDialogOpen(true); }}>Privacy Policy</Typography></Typography>}
						/>
					</Box>
					<Box display="grid" gridTemplateColumns="min-content min-content" justifyContent="space-between" marginTop="1rem" marginRight="30vw" marginLeft="30vw" marginBottom="1rem">
						<Button
							onClick={() => { handleUserCancel() }}
							variant="outlined"
							color="error"
							disabled={loadingAccept === true || loadingCancel === true}
							style={{ minWidth: '7rem' }}
              data-cy='terms-of-use-cancel-button'
						>
							{loadingCancel ? <CircularProgress size="1.5rem" /> : 'Cancel'}
						</Button>
						<Button
							onClick={() => { handleUserContinue() }}
							disabled={!userReadTerms || loadingAccept === true || loadingCancel === true}
							variant="contained"
							color="primary"
							style={{ minWidth: '7rem' }}
              data-cy='terms-of-use-accept-button'
						>
							{loadingAccept ? <CircularProgress size="1.5rem" /> : 'Accept'}
						</Button>
					</Box>
				</Box>
			)
			}
			<PrivacyPolicyDialog open={privacyDialogOpen} onClose={() => setPrivacyDialogOpen(false)} />
		</Dialog >
	)
}

export default TermsOfUseDialog;
