import { TimelineEventGroupID } from '@classes/StreamEvents/StreamEvents';
import { Box, Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Slider, Tooltip, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import React from 'react';

type EventsVisibilityProps = {
  onZoomChange: (zoom: number) => void;
  onGroupVisibilityChange: (map: Map<string, boolean>) => void;
  zoomValue: number;
  features?: StreamFeatures;
  style?: React.CSSProperties,
  disableAllEvents?: boolean;
  disableZoomSlider?: boolean;
  disableVisibility?: boolean;
  hint?: {
    motion?: boolean;
    zones?: boolean;
    safetyInfraction?: boolean;
    person?: boolean;
    vehicle?: boolean;
    // legacy
    socialDistance?: boolean;
    fire?: boolean;
    vehicleLicensePlate?: boolean;
  }
}

const EventsVisibility: React.FC<EventsVisibilityProps> = (props) => {
  const {
    onZoomChange,
    onGroupVisibilityChange,
    zoomValue,
    features,
    hint,
    style,
    disableAllEvents,
    disableVisibility,
    disableZoomSlider
  } = props;

  const eventTypes = React.useMemo(() => {
    const map: Map<string, boolean> = new Map();
    if (disableAllEvents) {
      map.set(TimelineEventGroupID.Motion, false)
      map.set(TimelineEventGroupID.Fire, false)
      map.set(TimelineEventGroupID.SafetyInfraction, false)
      map.set(TimelineEventGroupID.SocialDistance, false)
      map.set(TimelineEventGroupID.VehicleLicensePlate, false)
      map.set(TimelineEventGroupID.Zones, false)
    } else if (features) {
      const { events } = features;

      if (events.motion.active && events.motion.read) map.set(TimelineEventGroupID.Motion, true);
      if (events.zones.active && events.zones.read) map.set(TimelineEventGroupID.Zones, true);
      if (events.safetyInfraction.active && events.safetyInfraction.read) map.set(TimelineEventGroupID.SafetyInfraction, true);
      if (events.person.active && events.person.read) map.set(TimelineEventGroupID.Person, true);
      if (events.vehicle.active && events.vehicle.read) map.set(TimelineEventGroupID.Vehicle, true);

      // legacy
      if (events.fire.active && events.fire.read && features.fireDetection) map.set(TimelineEventGroupID.Fire, true);
      if (events.socialDistance.active && events.socialDistance.read && features.socialDistancing) map.set(TimelineEventGroupID.SocialDistance, true);
      if (events.vehicleLicensePlate.active && events.vehicleLicensePlate.read && features.licensePlateDetection) map.set(TimelineEventGroupID.VehicleLicensePlate, true);

      if (hint && hint.motion === false) map.set(TimelineEventGroupID.Motion, false);
      if (hint && hint.zones === false) map.set(TimelineEventGroupID.Zones, false);
      if (hint && hint.safetyInfraction === false) map.set(TimelineEventGroupID.SafetyInfraction, false);
      if (hint && hint.person === false) map.set(TimelineEventGroupID.Person, false);
      if (hint && hint.vehicle === false) map.set(TimelineEventGroupID.Vehicle, false);
      // legacy
      if (hint && hint.socialDistance === false) map.set(TimelineEventGroupID.SocialDistance, false);
      if (hint && hint.fire === false) map.set(TimelineEventGroupID.Fire, false);
      if (hint && hint.vehicleLicensePlate === false) map.set(TimelineEventGroupID.VehicleLicensePlate, false);
    } else {
      map.set(TimelineEventGroupID.Motion, true)
      map.set(TimelineEventGroupID.Zones, true)
      map.set(TimelineEventGroupID.SafetyInfraction, true)
      map.set(TimelineEventGroupID.Person, true)
      map.set(TimelineEventGroupID.Vehicle, true)
      // legacy
      map.set(TimelineEventGroupID.SocialDistance, true)
      map.set(TimelineEventGroupID.Fire, true)
      map.set(TimelineEventGroupID.VehicleLicensePlate, true)
    }

    return map;
  }, [features, hint, disableAllEvents])

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [eventsEnabled, setEventsEnabled] = React.useState<Map<string, boolean>>(eventTypes)

  const onGroupVisibilityChangeRef = React.useRef(onGroupVisibilityChange);

  React.useEffect(() => { onGroupVisibilityChangeRef.current = onGroupVisibilityChange }, [onGroupVisibilityChange]);
  React.useEffect(() => { onGroupVisibilityChangeRef.current(eventsEnabled); }, [eventsEnabled])
  React.useEffect(() => {
    if (features) setEventsEnabled(eventTypes)
  }, [features, eventTypes])

  function handleClose() {
    setAnchorEl(null);
  }

  function handleCheckboxChange(id: string, checked: boolean) {
    const newMap = new Map(eventsEnabled);
    newMap.set(id, checked);
    setEventsEnabled(newMap);
  }

  return (
    <Box style={style} position="absolute" zIndex="99">
      <Box id="timeline-zoom-description" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Tooltip style={{ display: disableVisibility === true ? 'none' : undefined }} title="Events visibility" arrow placement="right">
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
            <Visibility fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
        <Slider
          color="secondary"
          style={{ width: "5rem", marginLeft: "1rem", display: disableZoomSlider === true ? 'none' : undefined }}
          value={zoomValue}
          min={0}
          max={11.2}
          step={0.1}
          onChange={(event: Event, value: number | number[]) => {
            const currentVal = value as number;
            const offset = currentVal - zoomValue;
            if (offset >= 0.4) onZoomChange(zoomValue + 0.4);
            else if (offset <= -0.4) onZoomChange(zoomValue - 0.4)
            else onZoomChange(value as number)
          }}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={3}
        >
          {Array.from(eventsEnabled.keys()).map((event) => (
            <MenuItem key={event}>
              <FormControlLabel
                value={event}
                control={<Checkbox size="small" color="primary" />}
                label={<Typography variant="body2" >{event}</Typography>}
                labelPlacement="end"
                checked={eventsEnabled.get(event)}
                onChange={(_event: React.ChangeEvent<{}>, checked: boolean) => {
                  handleCheckboxChange(event, checked)
                }}
              />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  )
}

export default EventsVisibility;
