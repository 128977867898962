import * as moment from 'moment-timezone';

function getTimeZoneOptions(): string[] {
  const timeZones = moment.tz.names();
  const offsetTmz = [];

  for (const i in timeZones) {
    const tz = timeZones[i];
    const tzOffset = moment.tz(tz).format('Z');
    const timeZoneOption= `${tz} (GMT${tzOffset})`;
    offsetTmz.push(timeZoneOption);
  }

  return offsetTmz;
};

export default getTimeZoneOptions;
