import { API } from 'aws-amplify';

async function deleteDetection(siteId: string, id: string, asCompany?: string): Promise<void> {
  try {
    const path = `/attendance/${siteId}/${id}` + (asCompany ? `?asCompany=${asCompany}` : "");

    const { data } = await API.del('api-service', path, {});
    

    return data;
  } catch (error: any) {
    throw error;
  }
}

export default deleteDetection;
