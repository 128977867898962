import getIdsFromStreamName from './getIdsFromStreamName';
import getStreamShareUrl from './getStreamShareUrl';
import getStreamName from './getStreamName';
import getStreamThumbnail from './getStreamThumbnail';
import filterStreams from './filterStreams';
import getDeviceStatus from './getDeviceStatus';
import includeFeature  from './includeFeature';
import calculateRetentionTimestamp from './calculateRetentionTimestamp';

export interface IStream {
  getIdsFromStreamName: (name: string) => { streamId: string, companyId: string, siteId: string };
  getStreamShareUrl(stream: VideoStream, timestamp?: number): string;
  getStreamName(stream: VideoStream): string;
  getStreamThumbnail(stream: VideoStream): Promise<string>;

  /**
  * @param streams Collection of Streams.
  * @param filterText Text for defaultName or kvsName (if default name is set, kvsName will not be included in the search)
  * @param streamStatus ACTIVE | ARCHIVED
  * @param selectedSite If wanted, site can be provided to further filter streams.
  * @returns Collection of Streams that match search query.
  */
  filterStreams(streams: VideoStream[], streamStatus: "ALL" | "ACTIVE" | "ARCHIVED", selectedSite?: Site): VideoStream[];

  /**
   * Get the statusOnDevice of the provided Stream.
   * @param stream VideoStream to check for status.
   * @returns {object} Object with color and message properties.
   */
   getDeviceStatus(stream?: VideoStream): { color: string, message: string };
   includeFeature(streams: { features: any }[], feature: string): boolean;
   calculateRetentionTimestamp(stream: { retentionPeriodInHours?: number } | { retentionPeriodInHours?: number }[], now: number): number;
}

const Stream: IStream = {
  getIdsFromStreamName,
  getStreamName,
  getStreamShareUrl,
  getStreamThumbnail,
  filterStreams,
  getDeviceStatus,
  includeFeature,
  calculateRetentionTimestamp
}

export default Stream;
