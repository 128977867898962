import { Inventory2Outlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import EventChange from "./EventChange";

class TabChanged extends EventChange {
  public getIconElement(): JSX.Element {
    return <Inventory2Outlined fontSize="small" />
  }
  public getTextElement(): JSX.Element {
    if (this._author?.isCurrentUser) {
      return <Typography variant="body2">Moved to <b>{`${this._value}`}</b> by you</Typography>
    }

    return <Typography variant="body2">Moved to <b>{`${this._value}`}</b> by {`${this._author?.name || "Unknown"}`}</Typography>
  }
  
}

export default TabChanged;
