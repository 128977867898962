import { AssignmentIndOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import EventChange from "./EventChange";

class OwnerChanged extends EventChange {
  public getIconElement(): JSX.Element {
    return <AssignmentIndOutlined fontSize="small" />;
  }
  public getTextElement(): JSX.Element {
    if (this._author && !this._target) {
      if (this._author.isCurrentUser) {
        return <Typography variant="body2"><b>Unassigned</b> by you</Typography>; 
      }

      return <Typography variant="body2"><b>Unassigned</b> by {`${this._author.name}`}</Typography>;
    }

    if (this._author?.isCurrentUser === true && this._target?.isCurrentUser === true) {
      return <Typography variant="body2"><b>Assigned</b> to yourself</Typography>;
    }

    if (this.author?.isCurrentUser === false && this.target?.isCurrentUser === true) {
      return <Typography variant="body2">
        {`${this._author?.name} `}<b>assigned</b> to you
      </Typography>;
    }

    if (this.author?.isCurrentUser === true && this._target?.isCurrentUser === false) {
      return <Typography variant="body2">
        You <b>assigned</b> to {`${this._target.name}`}
      </Typography>;
    }

    return <Typography variant="body2">
      {`${this.author?.name || "Unknown"} `} <b>assigned</b> to {`${this._target?.name || "Unknown"}`}
    </Typography>
  }
}

export default OwnerChanged;
