import { isArray, isObject, isString } from "lodash";

function getDisplayText(value: unknown, multiple: boolean | undefined, optionDisplayProp: string | undefined, optionValueProp: string | undefined, options: any[] | undefined, placeholder: string | undefined, noExtension = false): string {
  try {
    let displayValue = value as string;
    if (multiple) {
      if (isObject(value) && !isArray(value)) {
        const val = value as { type: string };
        if (isString(val.type)) {
          return val.type;
        }
      }
      let textForRest = "";
      let valueArray = [...value as any[]];
      if (valueArray.length) {
        let numberOfRest = 0;
        if (optionDisplayProp && optionValueProp && valueArray.length && options?.length)
          valueArray = valueArray.map(v => {
            const tempOption = options?.find(o => o[optionValueProp] === v);
            return tempOption ? tempOption[optionDisplayProp] : tempOption;
          }).filter(value => value);
        if (!noExtension) {
          while (valueArray.join().length > 60) {
            valueArray.pop();
            numberOfRest += 1;
          }
        }
        if (numberOfRest) {
          textForRest = ` and ${numberOfRest} more`;
          displayValue = valueArray?.join(", ") + textForRest;
        } else {
          if (valueArray.length > 1)
            if (noExtension)
              displayValue = valueArray.join(", ")
            else
              displayValue = `${valueArray?.slice(0, valueArray.length - 1).join(", ")} and ${valueArray.slice(valueArray.length - 1)}`;
          else
            displayValue = valueArray[0];
        }
      }
    } else if (optionDisplayProp && optionValueProp) {
      const target = options?.find(o => o[optionValueProp] === displayValue);
      if (target && target[optionDisplayProp]) {
        return target[optionDisplayProp]
      }
    }
    return (displayValue && displayValue.length > 0) ? displayValue : placeholder || "";
  } catch (error) {
    return "Unknown";
  }
}
export default getDisplayText;
