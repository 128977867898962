import { UserRole } from "@store/users/types";

function isUserAbleToInvite(user?: User): boolean {
  if (user && user.roles === UserRole.Admin) return true;
  if (user && user.roles === UserRole.SuperAdmin) return true;
  if (user && user.roles && Object.keys(user.roles).includes(UserRole.SuperAdmin)) return true;
  if (user && user.roles && Object.keys(user.roles).includes(UserRole.SiteAdmin)) return true;
  return false;
}

export default isUserAbleToInvite;
