import { PrometheusQuery } from '@classes/prometheus/PrometheusQuery';
import usePrevious from '@hooks/usePrevious';
import { Box } from '@mui/material';
import { IStoreState } from '@store/index';
import Utils from '@utils/index';
import { first, flattenDeep, isEmpty, toNumber } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { CartesianGrid, Legend, Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';

const Container = styled(Box)`
  .recharts-legend-wrapper .recharts-default-legend .recharts-legend-item {
    cursor: pointer;
  }
`

type MetricsLineChartProps = {
  data: PrometheusQuery[];
  hidden: Map<string, boolean>;
  setHidden: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
}

const MetricsLineChart: React.FC<MetricsLineChartProps> = (props) => {
  const { data, setHidden, hidden } = props;

  const [zoomStart, setZoomStart] = React.useState<string | undefined>(undefined);
  const [zoomEnd, setZoomEnd] = React.useState<string | undefined>(undefined);
  const [zoomCurrent, setZoomCurrent] = React.useState<string | undefined>(undefined);
  const prevZoomStart = usePrevious(zoomStart);
  const prevZoomEnd = usePrevious(zoomEnd);
  const [finalZoom, setFinalZoom] = React.useState<{ start: string, end: string } | undefined>(undefined)

  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);

  React.useEffect(() => {
    if (zoomStart && zoomEnd && prevZoomEnd !== zoomEnd) {
      setFinalZoom({ start: zoomStart, end: zoomEnd })
    }
  }, [zoomStart, zoomEnd, prevZoomStart, prevZoomEnd])

  const refinedData = React.useMemo(() => {
    if (finalZoom) {
      const refData = PrometheusQuery.merge(data).filter((el: any) => el.date > finalZoom.start && el.date < finalZoom.end);
      return refData;
    }

    const refData = PrometheusQuery.merge(data)
    return refData;
  }, [data, finalZoom]);

  const possibleValues = React.useMemo(() => {
    return flattenDeep(data.map(d => d.getPossibleValues()))
  }, [data])

  const label = React.useMemo(() => {
    return first(data)?.getValueLabel()
  }, [data]);

  const domain = React.useMemo(() => {
    return first(data)?.getDomain();
  }, [data])

  const middle = React.useMemo(() => {
    return first(data)?.getMiddleValue();
  }, [data])

  return (
    <Container key={first(data)?.getId()} width="100%" height="100%">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={refinedData}
          margin={{ top: toNumber(rootFontSize), right: toNumber(rootFontSize), left: toNumber(rootFontSize * 2), bottom: toNumber(rootFontSize) }}          
          onMouseDown={(e, a) => {
            a.stopPropagation();
            a.preventDefault();

            if (e.activeLabel === zoomStart) {
              setZoomCurrent(() => {
                setZoomStart(undefined);
                setZoomEnd(undefined);
                setFinalZoom(undefined)
                return undefined;
              })
            } else {
              setZoomStart(e.activeLabel)
            }
          }}
          onMouseMove={(e) => {if (zoomStart) setZoomCurrent(e.activeLabel)}}
          onMouseUp={(e, a) => {
            a.stopPropagation();
            a.preventDefault();
            setZoomCurrent(undefined);

            if (e.activeLabel && zoomStart && +e.activeLabel < +zoomStart) {
              setZoomEnd(zoomStart);
              setZoomStart(e.activeLabel);
              return;
            }

            if (e.activeLabel !== zoomStart)
              setZoomEnd(e.activeLabel)
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{ fill: "#000", fontSize: '0.875rem' }}
            dataKey="date"
            tickFormatter={(e) => { return Utils.Date.format(new Date(+e), "h:mm:ss A") }}
            minTickGap={30}
            axisLine={false}
          />
          <YAxis tick={{ fill: "#000", fontSize: '0.875rem' }} unit={label} domain={domain} />
          <Tooltip
            wrapperStyle={{ zIndex: 9999 }}
            formatter={(value: any, _name: any, _props: any) => {
              return `${value}${label}`
            }}
            itemStyle={{ fontWeight: 400 }}
            contentStyle={{ fontSize: '0.875rem', fontWeight: 700 }}
            labelFormatter={(value: any) => {
              return Utils.Date.format(new Date(+value), "h:mm:ss A")
            }}
          />
          <ReferenceArea x1={zoomStart || undefined} x2={zoomCurrent || zoomStart} strokeOpacity={0.3} />
          <Legend
            align="right"
            verticalAlign="top"
            onClick={(e) => {
              if (e.dataKey && e.payload) {
                const newMap = new Map(hidden);
                newMap.set(e.dataKey, !e.payload.hide);
                setHidden(newMap);
              }
            }}
          />
          {possibleValues.map((value) => (
            <Line
              key={value.id}
              type="monotoneX"
              dataKey={value.id}
              stroke={value.color}
              dot={false}
              hide={hidden.get(value.id) || false}
            />
          ))}
          {isEmpty(refinedData) && <ReferenceLine y={middle} label={{ value: "No data", position: 'top' }} stroke="red" strokeDasharray="3 3" />}
        </LineChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default MetricsLineChart;
