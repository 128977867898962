import { API } from "aws-amplify";
import { isArray, isObject } from "lodash";

async function listActions(siteId: string, filterText: string, lastActionId?: string): Promise<Action[]> {
  try {
    const path: string = `/actions/${siteId}`;
    
    const queryStringParameters: any = {};
    if (filterText)
      queryStringParameters.filterText = filterText;
    if (lastActionId)
      queryStringParameters.lastActionId = lastActionId;
      
    const data = await API.get(
      'api-service',
      path,
      {
        queryStringParameters: queryStringParameters
      }
    )

    if (data.data) {
      const result = data.data.map((el: Action) => {
        const target = el.sentence.find(el => isObject(el) && el.key === "EventType" && isObject(el.value) && !isArray(el.value)) as ActionParameter;
        if (target) {
          target.value = [target.value.type]
        }

        return {
          ...el
        }
      }) as Action[]
      
      return result;
    }

    return [];
  } catch (e) {
    return [];
  }
}

export default listActions;
