import { API } from 'aws-amplify';

async function connect(): Promise<string> {
  try {
    const path: string = `/procore/connect`;

    const result: APIResponse<string> = await API.get(
      'api-service',
      path,
      {}
    )

    return result?.data || "";
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export default connect;
