import Tutorial from "./Tutorial";

class TimelineViewTutorial extends Tutorial {
  protected setup(): void {
    super.defineSteps([
      {
        element: '#zoom-bar-description',
        popover: {
          title: 'Digital zoom',
          description: 'You can also use your mouse/track pad to operate digital zoom.',
          position: 'bottom'
        }
      },
      {
        element: '#ptz-button-description',
        popover: {
          title: 'PTZ button',
          description: 'Expand to Pan-Tilt-Zoom view.',
          position: 'bottom'
        }
      },
      {
        element: '#ptz-control-description',
        popover: {
          title: 'Pan-tilt-zoom controls',
          description: 'Use the control button on the video stream to control the camera. PTZ cameras can be controlled up-down and left-right. Fisheye cameras can be controlled only up-down or left-right depending on the position of the camera.',
          position: 'bottom'
        }
      },
      {
        element: '#zoom-control-description',
        popover: {
          title: 'Zoom in, zoom out',
          description: 'Using the Zoom In and Zoom Out buttons you can magnify or reduce the surroundings. PTZ cameras have an optical zoom up to 32x.',
          position: 'bottom'
        }
      },
      {
        element: '#ptz-control-speed-description',
        popover: {
          title: 'PTZ control description',
          description: 'You can set the speed of movement of the PTZ camera between 0.5x, 1x or 2x.',
          position: 'bottom'
        }
      },
      {
        element: '#zoom-minimap-description',
        popover: {
          title: 'Zoom mini-map',
          description: 'Show a zoom mini-map in the left corner of the stream.',
          position: 'bottom'
        }
      },
      {
        element: '#fullscreen-description',
        popover: {
          title: 'Full screen',
          description: 'Switch to full screen view.',
          position: 'bottom'
        }
      },
      {
        element: '#more-options-description',
        popover: {
          title: 'Reload / edit / archive',
          description: 'Reload, edit, or archive your stream.',
          position: 'bottom'
        }
      },
      {
        element: '#timeline-container-description',
        popover: {
          title: 'Events on a timeline',
          description: 'A timeline of all the events that are triggered in the stream. The user can narrow or widen using mouse or slider the timeline to have a more detailed view of the event occurrences.',
          position: 'top'
        }
      },
      {
        element: '#timeline-zoom-description',
        popover: {
          title: 'Timeline zoom',
          description: 'Zoom in and out of the timeline to explore events that have occurred in a more narrow time interval.',
          position: 'top'
        }
      },
      {
        element: '#events-description',
        popover: {
          title: 'Events',
          description: 'All the event types that are triggered on the stream.',
          position: 'left'
        }
      },
      {
        element: '#zones-description',
        popover: {
          title: 'Zones',
          description: 'Highlight and create rules for specific areas of your video to provide more customized notifications and insights.',
          position: 'left'
        }
      },
      {
        element: '#save-video-description',
        popover: {
          title: 'Save video',
          description: 'Save specific video clips or a timelapse. Saved video clips can be shared via URL or downloaded.',
          position: 'left'
        }
      }
    ])
  }
}

export default TimelineViewTutorial;
