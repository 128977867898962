function getKey(location: string): string {
  try {
    if (location.includes('amazonaws.com/')) return location.split('amazonaws.com/')[1];
    if (location.charAt(0) === '/') return location.slice(1, location.length);
    return location;
  } catch (error) {
    throw error;
  }
}

export default getKey;
