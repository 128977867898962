import { API } from 'aws-amplify';

async function getWorker(
  workerId: number, asCompany?: string
): Promise<CrewWorker> {
  try {
    const path = `/workers/${workerId}` + (asCompany ? `?asCompany=${asCompany}` : "");

    const { data } = await API.get('api-service', path, {});
    return data as CrewWorker;
  } catch (error: any) {
    throw error;
  }
}

export default getWorker;
