import { ActionTypes, ISelectedCompaniesState} from './types';
import * as Actions from './actions';
import Utils from '@utils/index';

type TypesType = ReduxActionTypes<typeof ActionTypes>;
type ActionsType = ReduxActions<typeof Actions>;

const initialState: ISelectedCompaniesState = {
};

export const selectedCompaniesReducer = Utils.Redux.createReducer<ISelectedCompaniesState, TypesType, ActionsType>(
  initialState,
  {
    [ActionTypes.SET_SELECTED_COMPANY]: (state, action) => ({
      ...state,
      selectedCompany: action.selectedCompany
    })
  }
);
