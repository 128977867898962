import { INotificationChannels } from "@store/session/types";
import { API } from "aws-amplify";

async function getNotificationChannelStatus(): Promise<any> {
  try {
    const path: string = `/notifications/channels`;
  
    const data = await API.get(
      'api-service',
      path,
      {}
    )
  
    return data.data as INotificationChannels;
  } catch (e) {
    throw e;
  }
}

export default getNotificationChannelStatus;
