import { first, last, sortBy } from 'lodash';
import React from 'react';

interface Item {
  id: string;
}

class ListBulkSelection<T extends Item> {
  private items: Array<T> = [];
  private selection: Array<T> = [];

  constructor (items: Array<T>) {
    this.items = items;
  }

  public withSelection(items: Array<T>): ListBulkSelection<T> {
    this.selection = items;
    return this;
  }

  public getNewSelection(clickEvent: React.MouseEvent<HTMLDivElement, MouseEvent>, item: T): Array<T> {
    const target = this.selection.find(el => el.id === item.id);
    if (clickEvent.shiftKey === true) {
      if (this.selection.length === 0) return [item]

      const lastSelected = this.isSelectionSequential() ? last(this.selection) : first(this.selection);

      if (lastSelected) {
        const startIndex = this.items.indexOf(lastSelected);
        const endIndex = this.items.indexOf(item);

        if (startIndex < endIndex) {
          return this.items.filter(el => this.items.indexOf(el) >= startIndex && this.items.indexOf(el) <= endIndex);
        } else {
          return this.items.filter(el => this.items.indexOf(el) <= startIndex && this.items.indexOf(el) >= endIndex);
        }
      }

      return [];
    } else if (clickEvent.ctrlKey === true) {
      if (target) {
        if (this.selection.length === 1) {
          return [target];
        } else {
          return this.selection.filter(el => el.id !== target.id);
        }
      }
      else return [...this.selection, item];
    } else {
      if (target && this.selection.length > 1) { return [item] }
      else if (target) { return [target] }
      else { return [item] }
    }
  }

  private isSelectionSequential(): boolean {
    let sequential: boolean = true;
    const indexes: number[] = sortBy(this.selection.map(el => this.items.indexOf(el)));

    while (indexes.length > 1) {
      if (indexes[0] - indexes[1] !== -1) {
        sequential = false;
        break;
      }

      indexes.shift();
    }

    return sequential;
  }
}

export default ListBulkSelection;
