import React from 'react';
import BaseViewContainer from '@components/common/ViewContainer';
import { CircularProgress } from '@mui/material';

interface IProps {
  message?: string;
}

const LoadingView: React.FunctionComponent<IProps> = props => {
  return (
    <BaseViewContainer title="Loading" flex center column>
      <CircularProgress />
      <h3 style={{ marginTop: 10 }}>{props.message}...</h3>
    </BaseViewContainer>
  );
};

LoadingView.defaultProps = {
  message: 'Loading'
};

export default LoadingView;
