import { Action } from 'redux';

export type Handlers<State, Types extends string, Actions extends Action<Types>> = {
  readonly [Type in Types]: (
    state: State,
    action: Extract<Actions, Action<Type>>
  ) => State
};

const createReducer = <
  State,
  Types extends string,
  Actions extends Action<Types>
>(
  initialState: State,
  handlers: Handlers<State, Types, Actions>
) => (state = initialState, action: Actions) => {
  const type = action.type;

  if (handlers.hasOwnProperty(type)) {
    return handlers[type](state, action as Extract<
      Actions,
      Action<typeof type>
    >);
  }

  return state;
};

export default createReducer;
