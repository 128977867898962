import { TutorialType } from '@classes/Tutorials/TutorialFactory';
import { Help } from '@mui/icons-material';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import { IStoreState } from '@store/index';
import { setActiveTutorial } from '@store/tutorials/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type TutorialButtonProps = {
  tutorialType: TutorialType;
  style?: React.CSSProperties;
  IconButtonProps?: IconButtonProps;
}

const TutorialButton: React.FC<TutorialButtonProps> = (props) => {
  const { tutorialType, style, IconButtonProps } = props;

  const tutorialEnabled: boolean = useSelector((store: IStoreState) => store.tutorials.tutorialsEnabled);

  const dispatch = useDispatch();

  return (
    <Box style={style} visibility={tutorialEnabled === false ? 'hidden' : 'visible'}>
      <IconButton
        onClick={(e) => { e.stopPropagation(); e.preventDefault(); dispatch(setActiveTutorial(tutorialType)); }}
        size="small"
        {...IconButtonProps}
      >
        <Help />
      </IconButton>
    </Box>
  )
}

export default TutorialButton;
