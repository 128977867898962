function bulkActionToPerform(users: User[]): Array<"ACTIVATE" | "REINVITE" | "DEACTIVATE"> {
  if (users.length === 0) return [];

  if (users.filter(user => user.status === "Active").length === users.length) {
    return ["DEACTIVATE"];
  }

  if (users.filter(user => user.status === "Invited").length === users.length) {
    return ["REINVITE"];
  }

  if (users.filter(user => user.status === "Deactivated").length === users.length) {
    return ["ACTIVATE"];
  }

  return [];
}

export default bulkActionToPerform;
