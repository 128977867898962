import { Filter } from "@classes/Filter/Filter";
import { EventsFilterParams } from "./EventsFilter";
import IFilterEvents from "./IFilterEvents";
import TimeFilter from "./TimeFilter";
import { FieldFactory } from "@classes/Filter/FieldFactory";

class ZoneFilter extends TimeFilter implements IFilterEvents {
  filter(params: EventsFilterParams, events: VideoStreamEvent[]): VideoStreamEvent[] {
    const { zones } = params;

    if (!zones.enable) {
      return []
    }

    if (zones?.filters?.length) {
      const durationFilters = [];
      const nonDurationFilters = [];

      for (const f of zones?.filters) {
        if (f.filter.getName().toLowerCase().includes("duration")) {
          durationFilters.push(f);
        } else {
          nonDurationFilters.push(f);
        }
      }

      const filteredEvents = new Filter(nonDurationFilters).filter(events);

      if (durationFilters.length === 0) {
        return filteredEvents;
      }

      // logic for separate duration filtering considering object that was detected
      const unknownEvents: VideoStreamEvent[] = [];
      const personEvents: VideoStreamEvent[] = [];
      const vehicleEvents: VideoStreamEvent[] = [];

      for (const e of filteredEvents) {
        if (e.labels?.includes("person")) {
          personEvents.push(e);
        } else if (e.labels?.includes("vehicle")) {
          vehicleEvents.push(e);
        } else {
          unknownEvents.push(e);
        }
      }

      const personDurationFilter = durationFilters.find(f => f.filter.getName() === "personDetectionDuration");
      const vehilceDurationFilter = durationFilters.find(f => f.filter.getName() === "vehicleDetectionDuration");
      const unknownDurationFilter = durationFilters.find(f => f.filter.getName() === "unknownDetectionDuration");

      const filteredPersonEvents = () => {
        if (personDurationFilter) {
          const propertyAdjustedPersonDurationFilter = FieldFactory.createDurationField("duration");
          propertyAdjustedPersonDurationFilter.setCurrentOperator(personDurationFilter?.filter.getCurrentOperator())
          return new Filter([{ text: personDurationFilter?.text, filter: propertyAdjustedPersonDurationFilter }]).filter(personEvents)
        }
        return personEvents;
      }

      const filteredVehicleEvents = () => {
        if (vehilceDurationFilter) {
          const propertyAdjustedVehicleDurationFilter = FieldFactory.createDurationField("duration");
          propertyAdjustedVehicleDurationFilter.setCurrentOperator(vehilceDurationFilter?.filter.getCurrentOperator())
          return new Filter([{ text: vehilceDurationFilter?.text, filter: propertyAdjustedVehicleDurationFilter }]).filter(vehicleEvents)
        }
        return vehicleEvents;
      }

      const filteredUnknownEvents = () => {
        if (unknownDurationFilter) {
          const propertyAdjustedUnknownDurationFilter = FieldFactory.createDurationField("duration");
          propertyAdjustedUnknownDurationFilter.setCurrentOperator(unknownDurationFilter?.filter.getCurrentOperator())
          return new Filter([{ text: unknownDurationFilter?.text, filter: propertyAdjustedUnknownDurationFilter }]).filter(unknownEvents)
        }
        return unknownEvents;
      }

      return [...filteredPersonEvents(), ...filteredVehicleEvents(), ...filteredUnknownEvents()]
    } else {
      return events;
    }
  }
}

export default ZoneFilter;
