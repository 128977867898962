import orderAlphabetically from './orderAlphabetically';

export interface IArray {
	orderAlphabetically(collection: any[], property?: string): any[]
}

const Array: IArray = {
	orderAlphabetically
};

export default Array;
