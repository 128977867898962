import { DefinedRange } from "./types";

const dateFns = require('date-fns');
const {
	addDays,
	startOfWeek,
	endOfWeek,
	addWeeks,
	startOfMonth,
	endOfMonth,
	addMonths,
  startOfDay,
  endOfDay
} = dateFns;

export const getDefaultRanges = (date: Date): DefinedRange[] => [
	{
		label: "Today",
		getValue: () => {
			return [startOfDay(date), date]
		},
	},
	{
		label: "Yesterday",
		getValue: () => {
			return [startOfDay(addDays(date, -1)), endOfDay(addDays(date, -1))]
		}
	},
	{
		label: "This Week",
		getValue: () => {
			return [startOfWeek(date, {weekStartsOn: 1}), endOfWeek(date, {weekStartsOn: 1})]
		}
	},
	{
		label: "Last Week",
		getValue: () => {
			return [startOfWeek(addWeeks(date, -1), {weekStartsOn: 1}), endOfWeek(addWeeks(date, -1), {weekStartsOn: 1})]
		}
	},
	{
		label: "Last 7 Days",
		getValue: () => {
			return [addWeeks(date, -1), date]
		}
	},
	{
		label: "This Month",
		getValue: () => {
			return [startOfMonth(date), endOfMonth(date)]
		}
	},
	{
		label: "Last Month",
		getValue: () => {
			return [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))]
		}
	}
];

export const defaultRanges = getDefaultRanges(new Date());
