class KinesisCache {
  private static _instance: KinesisCache | null = null;
  private _database: Map<string, object> = new Map<string, object>();
  private _eventsCached: Set<string> = new Set();

  private constructor() {}

  public static getInstance(): KinesisCache {
    if (!this._instance) this._instance = new KinesisCache();
    return this._instance;
  }

  public set(key: string, value: object): void {
    this._database.set(key, value);

    if (this._database.size > 400) {
      Array.from(this._database.keys()).slice(0, 100).forEach(key => this._database.delete(key));
    }
  }

  public get(key: string): object | undefined {
    return this._database.get(key)
  }

  public has(key: string): boolean {
    return this._database.has(key);
  }

  public setEventCached(id: string): void {
    this._eventsCached.add(id);

    if (this._eventsCached.size > 20) {
      Array.from(this._eventsCached.keys()).slice(0, 10).forEach(key => this._eventsCached.delete(key));
    }
  }

  public isEventCached(id: string): boolean {
    return this._eventsCached.has(id)
  }
}

export default KinesisCache;
