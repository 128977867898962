import { TimeFiltering } from "./EventsFilter";
import Utils from '@utils/index';

class TimeFilter {
  public isWithinTimeWindow(event: VideoStreamEvent, params?: TimeFiltering): boolean {
    if (params && params.timeValue) {
      let miliseconds: number = 0;

      const value: number = +params.timeValue;
      if (params.timeType === "min") {
        miliseconds = value * 60000;
      } else {
        miliseconds = value * 1000;
      }

      if (params.timeFilterType === ">") {
        if (event.startDate === undefined || event.endDate === undefined) return false;
        const start = event.startDate > event.endDate ? Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate) : event.startDate;
        const end = event.endDate;
        const length = end - start;
        return length > miliseconds;
      } else {
        if (event.startDate === undefined || event.endDate === undefined) return false;
        const start = event.startDate > event.endDate ? Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate) : event.startDate;
        const end = event.endDate;
        const length = end - start;
        return length < miliseconds;
      }
    }

    return true;
  }
}

export default TimeFilter;