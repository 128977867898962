import KeyMirror from "keymirror";

export const ActionTypes = KeyMirror({
  SET_GRIDS: null,
  ADD_GRIDS: null
});

export interface IGridsState {
  grids: { [key: string]: UserView[] };
}
