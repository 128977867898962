import { Box } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridPrintGetRowsToExportParams,
  GridRowCount,
  GridRowId,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid-pro';
import { IStoreState } from '@store/index';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GridNoRowsOverlayProps } from './GridNoRowsOverlay';
import GridLoadingOverlay from './GridLoadingOverlay';
import { useSmallScreenQuery } from '@hooks/useSmallScreenQuery';

const StyledDataGridPro = styled(DataGridPro) <{ disableBoxShadow: boolean, hideHeaders: boolean, disabled: boolean }>`
  .MuiDataGrid-treeDataGroupingCellToggle {
    margin-right: 1rem !important;
    align-self: center;
  }

  .MuiDataGrid-pinnedColumns {
    background-color: white !important;
  }

  .MuiDataGrid-pinnedColumnHeaders {
    background-color: white !important;
  }

  .MuiDataGrid-columnHeaders {
    display: ${props => props.hideHeaders ? 'none' : ''}
  }

  .MuiDataGrid-toolbarContainer {
    display: ${props => props.hideHeaders ? 'none' : ''}
  }

  ${props => props.disabled ?
    `opacity: 0.5;
    pointerEvents: 'none';`
    : ""
  }
`

type CustomDataGridProps = DataGridProProps & {
  defaultHeight: string;
  hideHeaders: boolean;
  dataCy?: string;
  disabled?: boolean;
  noRowsOverlayProps?: GridNoRowsOverlayProps;
  rowCountComponent?: JSX.Element;
  onCSVExport?: () => void;
  disableFooter?: boolean;
  minimalHeight?: boolean;
}

const CustomDataGrid: React.FC<CustomDataGridProps> = (props) => {
  const { defaultHeight, hideHeaders, dataCy, disabled, noRowsOverlayProps, rowCountComponent, onCSVExport, disableFooter, minimalHeight, ...dataGridProps } = props;

  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const rootFontSize = useSelector((store: IStoreState) => store.appView.rootFontSize);
  const [appBarWidth, setAppBarWidth] = React.useState(0);

  const isSmallScreen = useSmallScreenQuery();

  const appMenuElement = document.getElementById("app-menu-container");

  useEffect(() => {
    if (!appMenuElement) return;
    const resizeObserver = new ResizeObserver(() => {
      setAppBarWidth(isSmallScreen ? 0 : appMenuElement.offsetWidth);
    });
    resizeObserver.observe(appMenuElement);
    return () => resizeObserver.disconnect(); // clean up 
  }, [isSmallScreen, appMenuElement]);

  const getRowsToExport = React.useCallback(({
    apiRef,
  }: GridPrintGetRowsToExportParams): GridRowId[] => {
    // add aditional CSV download function being called next to default DataGrid export
    onCSVExport && onCSVExport();
    return gridFilteredSortedRowIdsSelector(apiRef);
  }, [onCSVExport]);

  function CustomToolbar(rowCount: number) {
    if (disableFooter === true) return null;

    return (
      <GridToolbarContainer sx={{ display: 'grid', gridTemplateColumns: 'min-content min-content auto' }}>
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ getRowsToExport: getRowsToExport }} />
        <Box sx={{ justifySelf: 'flex-end', marginRight: '0.5rem' }}>
          {rowCountComponent ? rowCountComponent : <GridRowCount rowCount={rowCount} visibleRowCount={rowCount} />}
        </Box>
      </GridToolbarContainer>
    );
  }

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <Box height={minimalHeight === true ? "inherit" : defaultHeight ? defaultHeight : containerRef.current?.scrollHeight || defaultHeight} width="100%" maxWidth={`calc(100vw - 3rem - ${appBarWidth}px)`}>
        <StyledDataGridPro
          disabled={disabled || false}
          disableBoxShadow
          hideHeaders={hideHeaders}
          localeText={{
            noRowsLabel: noRowsOverlayProps?.description || "No data found for your current search criteria. Please adjust your filters or broaden your search to find results."
          }}
          slots={{
            // noRowsOverlay: () => (<GridNoRowsOverlay
            //   description={noRowsOverlayProps?.description}
            //   title={noRowsOverlayProps?.title}
            //   icon={noRowsOverlayProps?.icon}
            //   padding={noRowsOverlayProps?.padding || isMobile ? "10vh 2rem" : "20vh 2rem"}
            // />),
            loadingOverlay: GridLoadingOverlay,
            footer: () => CustomToolbar(dataGridProps.rows.filter(r => r.path ? r.path.length === 1 : true).length)
          }}
          disableColumnResize={true}
          {...dataGridProps}
          rowHeight={3.25 * rootFontSize}
          columnHeaderHeight={3.5 * rootFontSize}
          disableVirtualization={false}
          data-cy={dataCy}
        />
      </Box>
    </div>
  )
}

export default CustomDataGrid;
