import { Filter } from "@classes/Filter/Filter";
import { EventsFilterParams } from "./EventsFilter";
import IFilterEvents from "./IFilterEvents";

class VehicleFilter implements IFilterEvents {
  filter(params: EventsFilterParams, events: VideoStreamEvent[]): VideoStreamEvent[] {
    const { vehicles } = params;
    
    if(!vehicles.enable) {
      return [];
    }

    if (vehicles?.filters?.length) {
      return new Filter(vehicles.filters).filter(events);
    } else {
      return events;
    }
  }
}

export default VehicleFilter;
