import createSubcontractor from "./createSubcontractor";
import deleteSubcontractor from "./deleteSubcontractor";
import getSubcontractors from "./getSubcontractors";
import updateSubcontractor from "./updateSubcontractor";

export interface ISubcontractorsAPI {
  createSubcontractor(subcontractor: AddSubForm, asCompany?: string): Promise<Subcontractor>;
  deleteSubcontractor(subcontractorId: string, asCompany?: string): Promise<boolean>;
  getSubcontractors(siteId: string, asCompany?: string): Promise<Subcontractor[]>;
  updateSubcontractor(subcontractorId: string, subcontractor: AddSubForm, asCompany?: string): Promise<Subcontractor>
}

const Subcontractors: ISubcontractorsAPI = {
  createSubcontractor,
  deleteSubcontractor,
  getSubcontractors,
  updateSubcontractor
}

export default Subcontractors;
