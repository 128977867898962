import React, { useRef } from 'react';
import { Box, Button, CircularProgress, Dialog, IconButton, Tooltip, Typography } from '@mui/material';
import './tui-image-editor.css';
import './tui-color-picker.css';
import ReactImageEditor from '@toast-ui/react-image-editor';
import ImageEditor from 'tui-image-editor';
import './image-editor.css'
import API from '@API/index';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';
import { ArrowBack, InfoRounded } from '@mui/icons-material';

type SnapshotDialogProps = {
  src: string;
  open: boolean;
  kvsName: string;
  onClose: () => void;
  timestamp: number;
  isSafari: boolean;
}

const PRIMARY_COLOR = "#0048e3";

export const EDITOR_THEME = {
  "common.bi.image": "undefined",
  "common.bisize.width": "0",
  "common.bisize.height": "0",
  "common.backgroundImage": "none",
  "common.backgroundColor": "#fff",
  "common.border": "0px",

  // header
  'header.backgroundColor': '#fff',

  // submenu primary color
  'submenu.backgroundColor': '#fff',
  'submenu.partition.color': '#000',

  // submenu labels
  'submenu.normalLabel.color': '#000',
  'submenu.normalLabel.fontWeight': 'lighter',
  'submenu.activeLabel.color': PRIMARY_COLOR,
  'submenu.activeLabel.fontWeight': 'lighter',

  // checkbox style
  'checkbox.border': '0px',
  'checkbox.backgroundColor': '#fff',

  // range style
  'range.pointer.color': '#000',
  'range.bar.color': '#000',
  'range.subbar.color': '#000',

  'range.value.color': "#000",
  'range.value.fontWeight': 'lighter',
  'range.value.fontSize': '0.8rem',
  'range.value.border': '1px solid rgba(0, 0, 0, 0.12)',
  'range.value.backgroundColor': '#fff',
  'range.title.color': '#000',
  'range.title.fontWeight': 'lighter',
}

export const SnapshotDialog: React.FC<SnapshotDialogProps> = (props) => {
  const { src, open, kvsName, onClose, timestamp, isSafari } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const editorRef = useRef<any>(null);

  function onSave() {
    if (editorRef.current) {
      setLoading(true);
      const src = (editorRef.current.getInstance() as ImageEditor).toDataURL().split(',')[1];
      API.Snapshots.postSnapshot(kvsName, src, "png", timestamp).then((image) => {
        API.Snapshots.getShareImageLink(image.location)
          .then((url: string) => {
            navigator.clipboard.writeText(url);
            enqueueSnackbar("Snapshot saved successfully.", { variant: 'success' });
            enqueueSnackbar('Image link copied to board. Find image in Saved page.', { variant: 'success' });
          })
        onClose();
        setLoading(false);
      }).catch((e) => {
        enqueueSnackbar(Utils.Error.getErrorMessage(e), { variant: 'error' });
        setLoading(false);
      })
    }
  }

  function onDownload() {
    if (editorRef.current) {
      const instance = (editorRef.current.getInstance() as ImageEditor);
      const tempLink = document.createElement('a');
      tempLink.href = instance.toDataURL();;
      tempLink.setAttribute('download', `${instance.getImageName()}-${new Date(Math.floor(timestamp * 1000)).toLocaleString()}.png`);
      tempLink.click();
    }
  }

  return (
    <Dialog open={open} onClose={onClose} disableEnforceFocus>
      <Box
        display="grid"
        gridTemplateColumns={`max-content${isSafari ? " min-content" : ""} auto max-content max-content`}
        padding="0.5rem"
        gap="0.5rem"
        borderBottom="1px solid rgba(0, 0, 0, 0.12)"
      >
        <IconButton
          onClick={onClose}
          style={{
            width: "min-content",
            padding: "0.5rem",
          }}
        ><ArrowBack /></IconButton>
        {isSafari &&
          <Tooltip title={"Safari browser is not guaranteeing exact frame snapshot. Use alternative browser to have exact frame to annotate."}>
            <Box display='flex' gap='0.5rem' alignItems='center' color='chocolate' marginLeft='1rem' marginTop='0.25rem'>
              <Typography variant='caption'>Warning</Typography>
              <InfoRounded />
            </Box>
          </Tooltip>}
        <div />
        <Button
          onClick={onSave}
          style={{
            width: "min-content",
            padding: "0.5rem",
          }}
          disabled={loading}
          data-cy="snapshot-save-button"
        >Save</Button>
        <Button
          onClick={onDownload}
          color="primary"
          variant="contained"
          style={{
            width: "7rem",
            padding: "0.5rem",
          }}
          disabled={loading}
          data-cy="snapshot-download-button"
        >Download</Button>
      </Box>
      {loading &&
        <CircularProgress size="3rem" style={{ position: "absolute", zIndex: 9999, left: "43vw", top: "40vh" }} />}
      <ReactImageEditor
        ref={editorRef}
        includeUI={{
          loadImage: {
            path: src,
            name: "Snapshot"
          },
          menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask'],
          uiSize: {
            width: '90vw',
            height: '90vh',
          },
          menuBarPosition: 'left',
          theme: EDITOR_THEME
        }}
        cssMaxHeight={Math.round(window.innerHeight * 0.83)}
        cssMaxWidth={99999}
        selectionStyle={{
          cornerSize: 10,
          rotatingPointOffset: 70,
        }}
        usageStatistics={false}
      />
    </Dialog>
  )
}