import User, { IUser } from './user';
import Views, { IViews } from './views';
import URL, { IURL } from './url'
import Timezone, { ITimezone } from './timezone';
import Timestamp, { ITimestamp } from './timestamp';
import Timeline, { ITimeline } from './timeline';
import Text, { IText } from './text'
import Stream, { IStream } from './stream';
import Site, { ISite } from './site'
import S3, { IS3 } from './s3';
import RegEx, { IRegEx } from './regex';
import Date, { IDate } from './date';
import Geometry, { IGeometry } from './geometry';
import Redux, { IRedux } from './reducer';
import CloudWatch, { ICloudWatch } from './cloudwatch';
import PTZ, { IPTZ } from './ptz';
import Permissions, { IPermissions } from './permissions';
import Error, { IError } from './error';
import Exports, { IExport } from './export';
import Array, { IArray } from './array';
import MapBox, { IMapBox } from './mapbox';
import Name, { IName } from './name';
import EventInbox, { IEventInbox } from './events_inbox';
import Filters, { IFilter } from './filter';
import Browser, { IBrowser } from './browser';
import Window, { IWindow } from './window';
import PromiseUtils, { IPromiseUtils } from './promise';
import StreamEvent, { IStreamEvent } from './stream_event';
import Workers, { IWorkers } from './workers';
import CSV, { ICSV } from './CSV';
interface IUtils {
  Timestamp: ITimestamp;
  RegEx: IRegEx;
  Date: IDate;
  S3: IS3;
  Geometry: IGeometry;
  User: IUser;
  Text: IText;
  Stream: IStream;
  CloudWatch: ICloudWatch;
  Redux: IRedux;
  Timeline: ITimeline;
  Timezone: ITimezone;
  URL: IURL;
  PTZ: IPTZ;
  Permissions: IPermissions;
  Views: IViews;
  Error: IError;
  Site: ISite;
  Exports: IExport;
  Array: IArray;
  MapBox: IMapBox;
  Name: IName;
  EventInbox: IEventInbox;
  Filters: IFilter;
  Browser: IBrowser;
  Window: IWindow;
  Promise: IPromiseUtils;
  StreamEvent: IStreamEvent;
  Workers: IWorkers;
  CSV: ICSV;
}

const Utils: IUtils = {
  Timestamp,
  RegEx,
  Date,
  S3,
  Geometry,
  User,
  Text,
  Stream,
  CloudWatch,
  Redux,
  Timeline,
  Timezone,
  URL,
  PTZ,
  Permissions,
  Views,
  Error,
  Site,
  Exports,
  Array,
  MapBox,
  Name,
  EventInbox,
  Filters,
  Browser,
  Window,
  Promise: PromiseUtils,
  StreamEvent,
  Workers,
  CSV
}

export default Utils;
