import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { IStoreState } from '@store/index';
import { ICredentials } from '@store/session/types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Utils from '@utils/index';
import { MoreHoriz, Gamepad } from '@mui/icons-material';
import { FlexContainer, StreamContainer, StreamItemHeader } from '../common';
import KinesisPlayer, { IKinesisPlayerHandles } from '@components/common/KinesisPlayer/KinesisPlayer';
import { StreamStatus } from '@components/common/StreamStatus';

const Container = styled(StreamContainer) <{ x: number, y: number, h: number, w: number }>`
  height: 100%;
  width: 100%;
  justify-self: center;

  grid-column: ${props => props.x} / ${props => (props.x + props.w)};
  grid-row: ${props => props.y} / ${props => (props.y + props.h)};

  video {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`

const VideoContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  flex-direction: center;
  justify-content: center;
  background-color: black;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
`

type ViewStreamProps = {
  stream: UserViewStream;
  credentials: ICredentials;
  moveTo?: number;
  hideIfEmpty?: boolean;
  onStreamClick?: (stream: VideoStream) => void;
  paused: boolean;
  playbackRate: number;
  live: boolean;
  onStreamEdit?: (stream: VideoStream) => void;
  onStreamDetails?: (stream: VideoStream) => void;
  onPtzClick?: (stream: VideoStream) => void;
  onSourceLoaded?: (kvsName: string, player: any) => void;
  hideThreeDotsMenu?: boolean;
  onTimeUpdate?: (time: number) => void;
  footageInfo?: {
    timestamp: number,
    live: boolean
  }
}

const ViewStream: React.FC<ViewStreamProps> = (props) => {
  const {
    credentials,
    stream,
    moveTo,
    hideIfEmpty,
    onStreamClick,
    paused,
    playbackRate,
    live,
    onStreamEdit,
    onStreamDetails,
    onPtzClick,
    onSourceLoaded,
    hideThreeDotsMenu,
    onTimeUpdate,
    footageInfo
  } = props;

  const kvsPlayerRef = React.useRef<IKinesisPlayerHandles | null>(null);

  const videoStreams: VideoStream[] = useSelector((store: IStoreState) => store.streams.streams);

  const [videoStream, setVideoStream] = React.useState<VideoStream | undefined>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [videoState, setVideoState] = React.useState<"error" | "buffering" | "loading" | "ready">("loading");

  React.useEffect(() => {
    const target = videoStreams.find(element => element.kvsName === stream.kvsName);
    setVideoStream(target);
    setInitialized(true);
  }, [stream, videoStreams, setVideoStream]);

  React.useEffect(moveVideo, [moveTo, live]);
  React.useEffect(goLive, [live]);
  React.useEffect(pauseVideo, [paused]);

  function pauseVideo() {
      if (paused === true) kvsPlayerRef.current?.pause();
      else kvsPlayerRef.current?.play();
  }

  function moveVideo() {
    if (live === false && moveTo !== undefined) {
      kvsPlayerRef.current?.pause();
      kvsPlayerRef.current?.goToTime(moveTo);
    }
  }

  function goLive() {
    if (live === true) {
      kvsPlayerRef.current?.pause();
      kvsPlayerRef.current?.goLive();
    }
  }

  // Return nothing if there is no kvsName attached to this cell.
  if (hideIfEmpty === true && stream.kvsName === undefined) return null

  function reload() {
    if (kvsPlayerRef.current) {
      kvsPlayerRef.current.reload();
    }
  }

  function fullscreen() {
    if (kvsPlayerRef.current) {
      kvsPlayerRef.current.fullscreen();
    }
  }

  function isVideoOffline(): boolean {
    return videoState !== "ready"
  }

  return (
    <Container
      elevation={0}
      x={stream.x}
      y={stream.y}
      h={stream.h}
      w={stream.w}
      variant="outlined"
    >
      <StreamItemHeader>
        <div onClick={() => {
          if (kvsPlayerRef.current && kvsPlayerRef.current.isFullscreen() === false && onStreamClick && videoStream) {
            onStreamClick(videoStream);
          }
        }} >
          <StreamStatus stream={videoStream} />
        </div>
        <Typography
          style={{ visibility: initialized === true ? 'visible' : 'hidden' }}
          variant="subtitle1"
          noWrap={true}
        >
          {videoStream ? Utils.Stream.getStreamName(videoStream) : "Not found"}
        </Typography>
        {videoStream && videoStream.features?.ptz && videoStream.rportCameraId && onPtzClick &&
          <Tooltip title="PTZ" arrow placement="top">
            <IconButton
              size="small"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                onPtzClick(videoStream);
              }}
            >
              <Gamepad />
            </IconButton>
          </Tooltip>
        }
        <div style={{ visibility: hideThreeDotsMenu === true ? 'hidden' : 'visible' }} onClick={(e) => { setAnchorEl(e.currentTarget); }} >
          <IconButton style={{ padding: 0 }} size="small" >
            <MoreHoriz />
          </IconButton>
        </div>
        <Menu
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
        >
          {onStreamDetails && videoStream &&
            <MenuItem onClick={() => { setAnchorEl(null); onStreamDetails(videoStream) }}>
              Details
            </MenuItem>
          }
          {onStreamEdit && videoStream &&
            <MenuItem onClick={() => { setAnchorEl(null); onStreamEdit(videoStream); }} >
              Edit
            </MenuItem>
          }
          <MenuItem
            disabled={isVideoOffline()}
            onClick={() => { fullscreen(); setAnchorEl(null); }}
          >
            Fullscreen
          </MenuItem>
          <MenuItem
            onClick={() => { reload(); setAnchorEl(null); }}
          >
            Reload
          </MenuItem>
        </Menu>
      </StreamItemHeader>
      <VideoContainer
        onClick={() => {
          if (kvsPlayerRef.current && kvsPlayerRef.current.isFullscreen() === false && onStreamClick && videoStream) {
            onStreamClick(videoStream);
          }
        }}
      >
        {videoStream ? (
          <KinesisPlayer
            key={videoStream.kvsName}
            ref={kvsPlayerRef}
            stream={videoStream}
            credentials={credentials}
            playbackRate={playbackRate}
            paused={paused}
            wraperStyles={{ width: '100%' }}
            roundedBottom={true}
            onVideoStateChange={(state) => setVideoState(state)}
            onSourceLoaded={onSourceLoaded}
            playbackMode="LIVE_REPLAY"
            onTimeUpdate={onTimeUpdate}
            initialTimestamp={footageInfo && footageInfo.live === false ? footageInfo.timestamp : undefined}
          />
        ) : initialized === true ? (
          <div style={{ alignSelf: 'center', textAlign: 'center' }}>
            <div style={{ color: 'white' }}>You do not have permissions to access this stream.</div>
          </div>
        ) : null}
      </VideoContainer>
    </Container>
  )
}

export default ViewStream;
