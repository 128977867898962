import { API } from 'aws-amplify';

async function getWorkerLogs(
  workerId: number, asCompany?: string
): Promise<any> {
  try {
    const path = `/workers/${workerId}/logs` + (asCompany ? `?asCompany=${asCompany}` : "");

    const { data } = await API.get('api-service', path, {}
    // {
    //   queryStringParameters: {
    //     startTime,
    //     endTime
    //   }
    // }
    );
    return data as any;
  } catch (error: any) {
    throw error;
  }
}

export default getWorkerLogs;
