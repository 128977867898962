function issueCommand(url: string, callback?: (error?: any) => void): void {
  window.fetch(url, {
    credentials: 'include',
    mode: 'no-cors'
  })
    .then(() => {
      if (callback) callback();
    })
    .catch((error) => {
      if (callback) callback(error)
    });
}

export default issueCommand;
