import getAuditLogs, { IPaginationSupport } from "./getAuditLogs";

export interface IAuditLogAPI {
  getAuditLogs(companyId: string, start: number, end: number, lastEvaluated?: string, siteIds?: string[], userIds?: string[]): Promise<IPaginationSupport>;
}

const AuditLog: IAuditLogAPI = {
  getAuditLogs: getAuditLogs
}

export default AuditLog;
