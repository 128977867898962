import { API } from "aws-amplify";

async function postAttendance(siteId: string, form: CreateTimeEntryForm, asCompany?: string): Promise<TimeEntry> {
  try {
    const path: string = `/workers/attendance` + (asCompany ? `?asCompany=${asCompany}` : "");
    if(!Boolean(form.streamId?.length)) {
      delete form.streamId;
    }
    const options = {
      body: {
        workerId: form.workerId,
        startTime: form.from,
        endTime: form.to,
        siteId
      }
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    return data.data as TimeEntry;
  } catch (error: any) {
    throw error;
  }
}

export default postAttendance;
