import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  SET_APP_THEME: null,
  SET_LOADING_BASIC_DATA: null,
  SET_APP_BAR_EXPANDED: null,
  SET_ROOT_FONT_SIZE: null,
  SET_ERROR_LOADING_COMPANIES: null,
  SET_ERROR_LOADING_USER_DATA: null,
  SET_ERROR_LOADING_SITES: null,
  SET_APP_BAR_ANIMATION_ENDED: null
});

export const StorageKeys = keyMirror({
  appTheme: null,
  appBarExpanded: null
});

export type AppTheme = 'light' | 'dark';

export interface IAppViewState {
  readonly theme: AppTheme;
  readonly isLoadingBasicData: boolean;
  readonly appBarExpanded: boolean;
  readonly rootFontSize: number;
  readonly errorLoadingCompanies: any | undefined;
  readonly errorLoadingUserData: any | undefined;
  readonly errorLoadingSites: any | undefined;
  readonly appBarAnimationEndedAt: number;
}
