import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

type ToolbarIconProps = {
  tooltipTitle: string;
  onClick?: () => void;
  color?: "primary";
  icon: JSX.Element;
  disableRipple?: boolean;
  dataCy?: string;
}

const ToolbarIcon: React.FC<ToolbarIconProps> = (props) => {
  const { tooltipTitle, onClick, color, icon, disableRipple, dataCy} = props;

  return (
    <Tooltip disableInteractive title={tooltipTitle}>
      <IconButton disableRipple={disableRipple} onClick={onClick} color={color} data-cy={dataCy}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}

export default ToolbarIcon;
