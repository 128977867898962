export interface IS3Image {
  url: string,
  timestamp: number
}

async function decodeUrl(item: IS3Image): Promise<IS3Image> {
  const img = new Image();
  img.src = item.url;
  await img.decode();
  return {
    url: img.src,
    timestamp: item.timestamp
  }
}

export default decodeUrl;