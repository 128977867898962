function extractUptimeFromTimeChunks(streamChunks: VideoStreamTimeChunk[]): VideoStreamTimeChunk[] {
  const chunks = [
    ...streamChunks.map(chunk => ({
      ...chunk, style: `
    background: ${chunk.color ? chunk.color : '#000000'};
    height: 0.25rem;
    border: none;
    opacity: 1;
    top: 0px !important;
    z-index: ${chunk.color ? 9999 : 1 }
    `, group: 'uptime',
    }))
  ]

  return chunks;
}

export default extractUptimeFromTimeChunks;
