import { accept } from "./accept"

export interface ITerms {
  accept(version: string): Promise<boolean>
}

const Terms: ITerms = {
  accept
}

export default Terms;
