import React from 'react';
import API from '@API/index';
import VideoBoundingBoxes from '@classes/VideoBoundingBoxes';

type DetectionBoxesLoeaderProps = {
  detectionBoxesVisible?: boolean;
  isLive: boolean;
  streamUrl?: string;
  videoTimestamp?: number;
  stream?: VideoStream;
  videoBoundingBoxes?: VideoBoundingBoxes;
  onLoad?: (bbox?: VideoBoundingBoxes) => void;
}

export interface IDetectionBoxesLoaderHandles {
  setDetectionBoxes: (boxes: EventBoundingBox | undefined) => void;
}

const DetectionBoxesLoader: React.ForwardRefRenderFunction<IDetectionBoxesLoaderHandles, DetectionBoxesLoeaderProps> = (props, ref) => {
  const {
    detectionBoxesVisible,
    isLive,
    streamUrl,
    videoTimestamp,
    stream,
    videoBoundingBoxes,
    onLoad
  } = props;

  React.useImperativeHandle(ref, () => ({
    setDetectionBoxes
  }))

  const onLoadRef = React.useRef(onLoad)
  const isLoadingDetectionBoxes = React.useRef<boolean>(false);
  const boundingBoxesToDraw = React.useRef<EventBoundingBox | undefined>(undefined)
  const lastCallTimestampRef = React.useRef({ start: 0, end: 0 })

  function setDetectionBoxes(boxes: EventBoundingBox | undefined): void {
    boundingBoxesToDraw.current = boxes;
  }

  React.useEffect(() => { onLoadRef.current = onLoad }, [onLoad])

  React.useEffect(() => {
    if (detectionBoxesVisible !== true) return;

    if (isLive) return;

    if (isLoadingDetectionBoxes.current === true) return;
    if (boundingBoxesToDraw.current) {
      if (videoBoundingBoxes) {
        const lastElement = videoBoundingBoxes.getLast();
        if (lastElement) {
          if ((lastElement.timestamp - boundingBoxesToDraw.current.timestamp) > 15000) {
            return;
          }
        }
      }
    }

    if (streamUrl && videoTimestamp && stream) {
      if (lastCallTimestampRef.current.start !== 0 && lastCallTimestampRef.current.end !== 0) {
        if (videoTimestamp > lastCallTimestampRef.current.start) {
          if (videoTimestamp < lastCallTimestampRef.current.end - 15000) {
            return;
          }
        }
      }

      isLoadingDetectionBoxes.current = true;

      const startTs = videoTimestamp;
      const endTs = videoTimestamp + ((1000 * 60) * 1);
      lastCallTimestampRef.current  = { start: startTs, end: endTs };

      isLoadingDetectionBoxes.current = true;

      API.Events.getBoundingBoxes(stream.kvsName, startTs, endTs)
        .then((result) => {
          if (onLoadRef.current) onLoadRef.current(result);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          isLoadingDetectionBoxes.current = false;
        })
    }
  }, [streamUrl, videoTimestamp, isLive, detectionBoxesVisible, stream, videoBoundingBoxes])

  return null;
}

export default React.forwardRef(DetectionBoxesLoader);
