import { Box, Button } from '@mui/material';
import React from 'react';

type ActionsProps = {
  disableGenerate: boolean;
  onGenerateClick: () => void;
  type: "video_clip" | "timelapse"
}

const Actions: React.FC<ActionsProps> = (props) => {
  const {
    disableGenerate,
    onGenerateClick,
    type
  } = props;

  return (
    <Box display="grid" gridTemplateColumns="1fr" columnGap="1rem" width="100%">
      <Button
        id="timeline-save-video-generate-button"
        disabled={disableGenerate}
        onClick={onGenerateClick}
        variant="outlined"
        data-cy="timeline-export-generate-button"
      >
        {`${type === "video_clip" ? `Generate video` : `Generate timelapse`}`}
      </Button>
    </Box>
  );
}

export default Actions;
