import { API } from "aws-amplify";

async function getRoles(companyId: string): Promise<Role[]> {
  try {
    const path: string = `/roles?companyId=${companyId}`;

    const data = await API.get(
      'api-service',
      path,
      {}
    )

    if (data.data) {
      return (data.data as Role[]).sort((r1, r2) => {
        if (!r1.editable)
          return -1;
        if (!r2.editable)
          return 1;
        return (r1?.name || "z").localeCompare((r2?.name || "z"))
      });
    }
    return [];
  } catch (e) {
    throw(e);
  }
}

export default getRoles;
