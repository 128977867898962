function getErrorMessage(error: any): string {
  try {
    if (error?.response?.data) {
      return checkIsForbidden(error.response.data.error);
    }
    return checkIsForbidden(error.error ? error.error.message : error.message);
  } catch (error) {
    return "An error occurred."
  }
}

function checkIsForbidden(message: string) {
  if (message.toLocaleLowerCase().includes("forbid"))
    return message.concat(" Reach out to the Company Admin to enable this feature.");
  return message;
}

export default getErrorMessage;
