import { API } from "aws-amplify";

async function getUserData(userId: string): Promise<User> {
  try {
    const userData = await API.get(
      'api-service',
      `/users/${userId}`,
      {}
    );

    const user = userData.data as User;

    return user;
  } catch (error: any) {
    throw error;
  }
}

export default getUserData;
