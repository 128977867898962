import { Paper, Tabs, Tab, Typography, Box } from '@mui/material';
import React from 'react';

type TabProps = {
  value: string;
  onClick: (value: string) => void;
  label: string;
  disabled?: boolean;
  dataCy?: string;
  onAuxClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => void;
}
type NavBarProps = {
  title?: string;
  selectedValue?: string | boolean;
  tabs?: TabProps[];
  variant?: "scrollable" | "standard" | "fullWidth";
}

export const NavBar: React.FC<NavBarProps> = (props) => {
  const { title, selectedValue, tabs, variant } = props;

  return (
    <Paper id="active-nav-bar" elevation={0} style={{ borderRadius: 0 }}>
      <Box display="grid" gridTemplateColumns="min-content auto" borderBottom={tabs ? "1px solid rgba(0, 0, 0, 0.12)" : undefined}>
        {title &&
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" paddingRight="1.5rem" paddingLeft="1.5rem">
            <Typography variant="h6">{title}</Typography>
          </Box>}
        <Tabs
          value={selectedValue}
          indicatorColor="primary"
          textColor="primary"
          variant={variant ? variant : "scrollable"}
          data-cy="navbar-tabs"
        >
          {tabs?.map(t => (
            <Tab
              id={t.value}
              key={tabs.indexOf(t)}
              label={<Typography style={{ maxWidth: '10rem' }} noWrap={true} variant="body2" >{t.label}</Typography>}
              value={t.value}
              onClick={() => { t.onClick(t.value); }}
              onAuxClick={(e) => { if (t.onAuxClick) t.onAuxClick(e, t.value) }}
              disabled={t.disabled}
              data-cy={`nav-bar-${t.dataCy}-tab`}
            />
          ))}
        </Tabs>
      </Box>
    </Paper>
  )
}
