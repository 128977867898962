import { PermissionType } from "@store/session/types";
import { API } from "aws-amplify";

async function listPermissionsForStream(userId: string, kvsName: string): Promise<{ kvsName: string, permissions: PermissionType[]}> {
  try {
    const path: string = `/permissions/${userId}/${kvsName}`;
    const service: string = `api-service`;
    
    const data = await API.get(
      service,
      path,
      {}
    );

    return {
      kvsName,
      permissions: data.data as PermissionType[]
    }
  } catch (error: any) {
    throw error;
  }
}

export default listPermissionsForStream;
