import { Box, CircularProgress, Dialog, IconButton, MenuItem, Select, Tooltip, Typography, Zoom } from '@mui/material';
import { Close, Launch } from '@mui/icons-material';
import API from '@API/index';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from '@store/index';
import { useSnackbar } from 'notistack';
import Utils from '@utils/index';
import { isEmpty } from 'lodash';
import PositionItem from './PositionItem';

type CameraPositionProps = {
  onCameraPositionChange: (value: number) => void;
}

const CameraPosition: React.FC<CameraPositionProps> = (props) => {
  const { onCameraPositionChange } = props;

  const { enqueueSnackbar } = useSnackbar();

  const selectedCamera = useSelector((store: IStoreState) => store.selectedStream.stream)

  const [selectedPosition, setSelectedPosition] = React.useState<number>(0);
  const [isSelectingPosition, setSelectingPosition] = React.useState<boolean>(false);
  const [positions, setPositions] = React.useState<StreamPosition[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onCameraPositionChangeRef = React.useRef(onCameraPositionChange);
  const kvsName = React.useRef(selectedCamera?.kvsName);

  React.useEffect(() => { kvsName.current = selectedCamera?.kvsName }, [selectedCamera?.kvsName]);
  
  React.useEffect(() => { onCameraPositionChangeRef.current = onCameraPositionChange }, [onCameraPositionChange])

  React.useEffect(() => {
    setLoading(true);
    API.Streams.getCameraPosition(kvsName.current || "")
      .then((res: StreamPosition[]) => {
        setPositions(res);
      })
      .catch((error) => {
        enqueueSnackbar(Utils.Error.getErrorMessage(error), { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [enqueueSnackbar]);

  React.useEffect(() => {
    onCameraPositionChangeRef.current(selectedPosition);
  }, [selectedPosition])

  function handleImageMouseDown(event: React.MouseEvent<HTMLElement, MouseEvent>, index: number): void {
    const { button } = event;
    if (button === 1) {
      window.open(positions[index].snapshotUrl, "_blank")
    }
  }

  function getGridLayout(): string {
    try {
      if (positions.length === 1) return "1fr";
      if (positions.length % 3 === 0) return "1fr 1fr 1fr";
      if (positions.length % 2 === 0) return "1fr 1fr";
      return "1fr 1fr 1fr";
    } catch (e) {
      return "1fr 1fr"
    }
  }

  return <>
    <Box width="100%">
      <Typography variant="subtitle2">{`Camera position`}</Typography>
      <Box display="grid" gridTemplateColumns="auto min-content" gap="0.5rem" alignItems="center">
        <Select
          disabled={loading || positions.length === 0}
          onChange={(e) => setSelectedPosition(e.target.value as number)}
          fullWidth
          variant="outlined"
          value={selectedPosition}
        >
          {!isEmpty(positions) &&
            positions.map(el => (
              <MenuItem value={el.index}>{el.index + 1}</MenuItem>
            ))
          }
          {isEmpty(positions) &&
            <MenuItem value={0} >1</MenuItem>
          }
        </Select>
        <Box>
          <Tooltip title={loading ? 'Loading positions...' : loading === false && positions.length === 0 ? 'No positions available' : 'Explore positions'}>
            <span>
              <IconButton
                disabled={loading || positions.length === 0}
                onClick={() => setSelectingPosition(true)}
                size="large">
                {loading ? <CircularProgress size={24} /> : <Launch />}
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Box>
    <Dialog
      onBackdropClick={() => setSelectingPosition(false)}
      onClose={() => setSelectingPosition(false)}
      maxWidth="sm"
      open={isSelectingPosition}
      TransitionComponent={Zoom}
    >
      <Box overflow="hidden" padding="1rem" display="grid" gridTemplateRows="min-content auto">
        <Box width="100%" display="grid" gridTemplateColumns="auto min-content" paddingBottom="1rem">
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Typography style={{ fontWeight: 600 }} variant="body1" >Position explorer</Typography>
          </Box>
          <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
            <IconButton onClick={() => setSelectingPosition(false)} size="small">
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box padding="0.3rem" overflow="auto" width="70vw" height="fit-content" justifyContent="center" gap="1rem" display="grid" gridTemplateColumns={getGridLayout()}>
          {positions.map((el) => (
            <PositionItem
              position={el}
              setSelectedPosition={setSelectedPosition}
              selectedPosition={selectedPosition}
              onImageDown={handleImageMouseDown}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  </>;
}

export default CameraPosition;
