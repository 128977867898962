import { EventsFilterParams } from "./EventsFilter";
import IFilterEvents from "./IFilterEvents";

class StreamsFilter implements IFilterEvents {
  filter(params: EventsFilterParams, events: VideoStreamEvent[]): VideoStreamEvent[] {
    const { permissions } = params;

    return events.filter((event) => {
      try {
        const { sourceCamera } = event;

        if (params.streams?.streams.includes(sourceCamera)) {
          const streamPermissions = permissions?.permissions[sourceCamera];
          if (streamPermissions?.includes('ALL')) {
            return true;
          }

          switch (event.eventType) {
            case "Motion":
            case "Geofence triggered":
            case "Safety infraction":
            case "Person detected":
            case "Vehicle detected": // legacy
            case "Social distancing":
            case "Fire detected":
            case "License plate detected":
              return streamPermissions?.includes("EVENTS") || streamPermissions?.includes("EVENTS_VIEW");
          }
        }

        return false;
      } catch (error) {
        return false;
      }
    })
  }
}

export default StreamsFilter;
