import { API } from "aws-amplify";

async function postNewCompany(company: Company): Promise<Company> {
  try {
    const path: string = '/companies';
    const tempCompany: any = {
      ...company
    }
    delete tempCompany.companyId
    const options = {
      body: tempCompany
    };

    const data = await API.post(
      'api-service',
      path,
      options
    );

    return data.data as Company;
  } catch (error: any) {
    throw error;
  }
}

export default postNewCompany;
