import { setRootFontSize } from '@store/views/appView/actions';
import React from 'react';
import { useDispatch } from 'react-redux';

const RootFontSizeListener: React.FC = () => {
  const dispatch = useDispatch();

  const dispatchRef = React.useRef(dispatch)
  
  React.useEffect(() => { dispatchRef.current = dispatch }, [dispatch])
  React.useEffect(setResizeListener, []);

  function setHeights() {
    const rootFontSize = (window.getComputedStyle(document.body, null).fontSize.split("px")[0] || 16) as number;
    dispatchRef.current(setRootFontSize(rootFontSize));
  }

  function setResizeListener() {
    window.addEventListener("resize", setHeights);
    setHeights();
    return () => window.removeEventListener("resize", setHeights);
  }
  return null;
}

export default RootFontSizeListener;
