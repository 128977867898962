import StreamEvents from '@classes/StreamEvents/StreamEvents';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { capitalize, indexOf, isArray, isEmpty, isString, replace } from 'lodash';
import React from 'react';
import Utils from '@utils/index';
import { BusinessOutlined, LocationOn, OpenInNewOutlined, SkipNext, SkipPrevious } from '@mui/icons-material';
import EventContentVideo, { IEventContentVideoHandles } from './EventContentVideo';
import API from '@API/index';
import EventContentHistory from './EventContentHistory';
import EventChange from '@classes/GEI/EventChange';
import EventTagLabel from '../EventTagLabel';
import VideoBoundingBoxes from '@classes/VideoBoundingBoxes';
import { useSnackbar } from 'notistack';
import TagSelectionChips from './TagSelectionChips';
import EventContentTimeline, { IEventContentTimelineHandles } from './EventContentTimeline';
import { PlayPauseButton } from '@components/timeline/player/PlayPauseButton';

type EventContentProps = {
  events: VideoStreamEvent[];
  event: VideoStreamEvent;
  streams: VideoStream[];
  companies: Company[];
  sites: Map<string, Site[]>;
  onTagSelected: (tag: EventTag) => void;
  hasPermissionToEditEvent: boolean;
  zoneNames?: string[];
  onVideoStateChange?: (videoState: "error" | "buffering" | "loading" | "ready") => void;
  onNextEventClick?: () => void;
  onPreviousEventClick?: () => void;
  onEventClick?: (event: VideoStreamEvent) => void;
}

export interface IEventContentHandles {
  pushHistory: (history: EventChange) => void;
}

const EventContent: React.ForwardRefRenderFunction<IEventContentHandles, EventContentProps> = (props, ref) => {
  const {
    event,
    streams,
    companies,
    sites,
    onTagSelected,
    zoneNames,
    onVideoStateChange,
    onNextEventClick,
    onPreviousEventClick,
    events,
    onEventClick,
    hasPermissionToEditEvent
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [eventHistory, setEventHistory] = React.useState<EventChange[] | undefined>([]);
  const [historyExpanded, setHistoryExpanded] = React.useState<boolean>(false);
  const [videoBoundingBoxes, setVideoBoundingBoxes] = React.useState<Map<string, VideoBoundingBoxes> | undefined>(undefined);
  const [paused, setPaused] = React.useState<boolean>(false);

  const eventContentVideoRef = React.useRef<IEventContentVideoHandles[]>([]);
  const streamStatesRef = React.useRef<Map<string, "error" | "buffering" | "loading" | "ready">>(new Map())
  const eventContentTimelineRef = React.useRef<IEventContentTimelineHandles | null>(null);
  const eventsRef = React.useRef(events);

  React.useEffect(() => { eventsRef.current = events }, [events])

  const getKvsNamesForEvent = React.useCallback((event?: VideoStreamEvent) => {
    if (event) {
      const kvsNames: Set<string> = new Set();

      kvsNames.add(event?.sourceCamera);
      if (event?.children && isArray(event?.children) && !isEmpty(event?.children)) {
        event?.children.forEach((child) => {
          kvsNames.add(child.sourceCamera);
        })
      }

      return Array.from(kvsNames);
    }

    return []
  }, [])

  const getEventByIndexOffset = React.useCallback((index: number, event: VideoStreamEvent) => {
    if (eventsRef.current[indexOf(eventsRef.current, event) + index]) {
      return eventsRef.current[indexOf(eventsRef.current, event) + index];
    }
  }, [])

  const previousEvent = React.useMemo(() => {
    return getEventByIndexOffset(-1, event)
  }, [event, getEventByIndexOffset])

  const previousPreviousEvent = React.useMemo(() => {
    return getEventByIndexOffset(-2, event)
  }, [event, getEventByIndexOffset])

  const previousStreamNames = React.useMemo(() => {
    return getKvsNamesForEvent(previousEvent)
  }, [getKvsNamesForEvent, previousEvent])

  const previousPreviousStreamNames = React.useMemo(() => {
    return getKvsNamesForEvent(previousPreviousEvent)
  }, [getKvsNamesForEvent, previousPreviousEvent])

  const nextEvent = React.useMemo(() => {
    return getEventByIndexOffset(1, event)
  }, [event, getEventByIndexOffset])

  const nextNextEvent = React.useMemo(() => {
    return getEventByIndexOffset(2, event)
  }, [event, getEventByIndexOffset])

  const nextStreamNames = React.useMemo(() => {
    return getKvsNamesForEvent(nextEvent)
  }, [getKvsNamesForEvent, nextEvent])

  const nextNextStreamNames = React.useMemo(() => {
    return getKvsNamesForEvent(nextNextEvent)
  }, [getKvsNamesForEvent, nextNextEvent])

  const streamNames = React.useMemo(() => {
    const kvsNames: Set<string> = new Set();

    kvsNames.add(event.sourceCamera);
    if (event.children && isArray(event.children) && !isEmpty(event.children)) {
      event.children.forEach((child) => {
        kvsNames.add(child.sourceCamera);
      })
    }

    return Array.from(kvsNames);
  }, [event])

  React.useEffect(getEventHistory, [event.id, event.sourceCamera, event.startDate]);
  React.useEffect(() => {
    setVideoBoundingBoxes(undefined);
    eventContentVideoRef.current = [];
    streamStatesRef.current.clear();
  }, [event.id]);

  React.useImperativeHandle(ref, () => ({
    pushHistory
  }))

  function pushHistory(history: EventChange): void {
    if (eventHistory) {
      setEventHistory([history, ...eventHistory]);
    } else {
      setEventHistory([history]);
    }
  }

  function getEventHistory(): void {
    setEventHistory(undefined);

    API.GlobalEventInbox.listEventChanges(event.sourceCamera, event.startDate)
      .then((history) => {
        if (history.length > 0) setEventHistory(history);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getVideoDetection(params: { start: number, end: number }): void {
    setVideoBoundingBoxes(undefined);

    const promises = streamNames.map(kvsName => {
      return API.Events.getBoundingBoxes(kvsName, params.start, params.end);
    })

    Promise.all(promises)
      .then((res) => {
        const map = new Map();
        res.forEach((boxes) => {
          map.set(streamNames[res.indexOf(boxes)], boxes);
        })
        setVideoBoundingBoxes(map);
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(`Error fetching video bounding boxes: ${Utils.Error.getErrorMessage(e)}`, { variant: 'error' });
      })
  }

  function getEventTimelineLink(): string {
    const timestamp: number = Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate);
    const url: string = Utils.Stream.getStreamShareUrl({ kvsName: event.sourceCamera } as VideoStream, timestamp);
    return url;
  }

  function getTemplateRows(): string {
    const size = streamNames.length;

    if (size === 1) return "1fr";
    if (size === 2) return "1fr";
    return "1fr 1fr";
  }

  function getTemplateColumns(): string {
    const size = streamNames.length;

    if (size === 1) return "1fr";
    if (size === 2) return "1fr 1fr";
    if (size === 3) return "1fr 1fr";
    if (size === 4) return "1fr 1fr";
    if (size === 5) return "1fr 1fr 1fr";
    return "1fr 1fr 1fr";
  }

  function handleTimelineClick(date: Date) {
    eventContentVideoRef.current.forEach((player) => player.goToTime(date));
  }

  function handleVideoStateChange(videoState: "error" | "buffering" | "loading" | "ready", kvsName: string): void {
    if (onVideoStateChange) onVideoStateChange(videoState);
    streamStatesRef.current.set(kvsName, videoState);
    evaluateVideoStatesForPlayAction();
  }

  function evaluateVideoStatesForPlayAction(): void {
    if (streamNames.length !== streamStatesRef.current.size) return;

    let shouldPlay: boolean = true;
    for (let i = 0; i < streamStatesRef.current.size; i += 1) {
      const value = Array.from(streamStatesRef.current.values())[i];
      if (value === "buffering" || value === "loading") {
        shouldPlay = false;
        break;
      }
    }

    if (shouldPlay === false) return;

    eventContentVideoRef.current.forEach(ref => ref.play());
  }

  function getEventLabels(event: VideoStreamEvent): JSX.Element | JSX.Element[] | null {
    try {
      if (event.metadata) {
        const elements: JSX.Element[] = [];

        if (event.metadata.plate) {
          elements.push(<Chip style={{ marginLeft: 0 }} variant="filled" label={event.metadata.plate} data-cy="event-inbox-object-detected-plate-label"/>)
        }

        if (event.metadata.color && event.metadata.model) {
          elements.push(<Chip style={{ marginLeft: 0 }} variant="filled" label={`${event.metadata.color} ${event.metadata.model}`} data-cy="event-inbox-object-detected-model-label"/>)
        }

        if (event.metadata.make) {
          elements.push(<Chip style={{ marginLeft: 0 }} variant="filled" label={`${event.metadata.make}`} data-cy="event-inbox-object-detected-make-label" />)
        }

        if (event.metadata.region) {
          elements.push(<Chip style={{ marginLeft: 0 }} variant="filled" label={`${event.metadata.region}`} data-cy="event-inbox-object-detected-region-label" />)
        }

        return elements;
      }

      if (event.labels && event.labels.map) {
        return event.labels.map(l => l === 'motion' ? 'unknown' : l).map((label, index) => (
          <Chip key={label} style={{ marginLeft: index === 0 ? 0 : undefined }} variant="filled" label={capitalize(replace(label, '_', ' '))} data-cy={`event-inbox-object-detected-${label}-label`} />
        ))
      }

      if (isString(event.labels)) {
        return (<Chip style={{ marginLeft: 0 }} variant="filled" label={event.labels.replaceAll('motion', 'unknown')} data-cy={`event-inbox-object-detected-${event.labels}-label`}/>)
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  return (
    <Box
      paddingBottom="1rem"
      paddingTop="0.5rem"
      width="100%"
      height="100%"
      display="grid"
      gridTemplateRows="min-content auto min-content min-content min-content"
      gap="1rem"
    >
      <Box display="grid" gridTemplateRows="min-content" gap="0.5rem">
        <Box width="100%" display="grid" gridTemplateColumns="0.8fr 1fr">
          <Box height="min-content" display="grid" width="100%" gap="0.5rem">
            <Box display="grid" gridTemplateColumns="max-content max-content max-content" gap="0.5rem" alignItems="center">
              <Tooltip title="Check the event in the camera timeline">
                <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                  <a style={{ height: '1.3rem' }} rel="noreferrer" target="_blank" href={getEventTimelineLink()}><OpenInNewOutlined fontSize="small" style={{ color: 'black', textDecoration: 'none' }} /></a>
                </Box>
              </Tooltip>
              <Tooltip
                disableInteractive
                title={zoneNames && zoneNames.length > 1 ? zoneNames.map(el => <Typography key={el} variant="body2" >{el}</Typography>) : ""}
              >
                <Typography variant="h6" data-cy="event-content-title">{StreamEvents.getTitle(event, zoneNames)}</Typography>
              </Tooltip>
              {Boolean(event.tags) && !isEmpty(event.tags) && <EventTagLabel event={event} selected={false} />}
            </Box>
            <Box width="100%" display="grid" gridTemplateColumns={window.innerWidth < 1800 ? "min-content min-content" : "min-content min-content min-content min-content"} alignItems="center">
              <Chip style={{ marginLeft: 0 }} variant="outlined" icon={<BusinessOutlined />} label={StreamEvents.getCompanyAndSite(event, companies, sites).company?.name || 'unknown'} />
              <Chip variant="outlined" icon={<LocationOn />} label={StreamEvents.getCompanyAndSite(event, companies, sites).site?.name || 'unknown'} data-cy="event-content-location-chip" />
            </Box>
            <Box>
              <Typography variant="subtitle2">Time range</Typography>
              <Box display="grid" gridTemplateColumns="min-content auto" gap="0.5rem">
                <Box paddingTop="0.1rem" paddingBottom="0.1rem" alignItems="center" height="100%" display="grid" gridTemplateRows="min-content auto min-content">
                  <Box width="0.6rem" height="0.6rem" borderRadius="50%" bgcolor="#525267" />
                  <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                    <Box height="100%" border="1px dashed #525267" width="2px" />
                  </Box>
                  <Box width="0.6rem" height="0.6rem" borderRadius="50%" bgcolor="#525267" />
                </Box>
                <Box display="grid" gridTemplateColumns="min-content max-content" gap="0.2rem">
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" ><b>From:</b></Typography>
                    <Typography variant="body2" ><b>To:</b></Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body2" >{`${Utils.Date.format(new Date(Utils.Timestamp.edgeTimestampToMiliseconds(event.startDate)), "MMM Do, YYYY h:mm:ss A", StreamEvents.getCompanyAndSite(event, companies, sites).site?.timezone?.split(' ')[0])}`}</Typography>
                    {Boolean(event.endDate) && <Typography variant="body2" >{`${Utils.Date.format(new Date(event.endDate), "MMM Do, YYYY h:mm:ss A", StreamEvents.getCompanyAndSite(event, companies, sites).site?.timezone?.split(' ')[0])}`}</Typography>}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="grid" width="100%">
            <TagSelectionChips event={event} onTagSelected={onTagSelected} hasPermissionToEditEvent={hasPermissionToEditEvent} />
            {event.labels && !isEmpty(event.labels) &&
              <Box>
                <Typography variant="subtitle2" data-cy="event-inbox-object-detected">Object detected</Typography>
                <Box display="grid" columnGap="1rem" gridTemplateColumns="1fr">
                  <Box data-cy="event-inbox-object-detected-labels">
                    {getEventLabels(event)}
                  </Box>
                </Box>
              </Box>
            }
          </Box>
        </Box>
      </Box>
      <Box
        display="none"
      >
        {nextNextEvent && nextNextStreamNames.map(kvsName => (
          <EventContentVideo
            key={kvsName}
            event={nextNextEvent}
            streams={streams}
            kvsName={kvsName}
            autoPlay={false}
            paused={true}
            cacheOnly={true}
          />
        ))}
      </Box>
      <Box
        display="none"
      >
        {nextEvent && nextStreamNames.map(kvsName => (
          <EventContentVideo
            key={kvsName}
            event={nextEvent}
            streams={streams}
            kvsName={kvsName}
            autoPlay={false}
            paused={true}
            cacheOnly={true}
          />
        ))}
      </Box>
      <Box
        display="grid"
        width="100%"
        height="100%"
        gridTemplateRows={getTemplateRows()}
        gridTemplateColumns={getTemplateColumns()}
        gap="0.5rem"
      >
        {streamNames.map(kvsName => (
          <EventContentVideo
            key={kvsName}
            ref={(ref) => { if (ref) eventContentVideoRef.current.push(ref) }}
            event={event}
            videoBoundingBoxes={videoBoundingBoxes?.get(kvsName)}
            onVideoStartRangeAquired={getVideoDetection}
            onVideoStateChange={(data) => handleVideoStateChange(data, kvsName)}
            // onVideoEnd={onVideoEnd}
            streams={streams}
            onVideoTimestampUpdate={(timestamp) => {
              if (kvsName === event.sourceCamera)
                eventContentTimelineRef.current?.updateTime(timestamp);
            }}
            kvsName={kvsName}
            autoPlay={false}
            paused={paused}
            cacheOnly={false}
          />
        ))}
      </Box>
      <Box
        display="none"
      >
        {previousEvent && previousStreamNames.map(kvsName => (
          <EventContentVideo
            key={kvsName}
            event={previousEvent}
            streams={streams}
            kvsName={kvsName}
            autoPlay={false}
            paused={true}
            cacheOnly={true}
          />
        ))}
      </Box>
      <Box
        display="none"
      >
        {previousPreviousEvent && previousPreviousStreamNames.map(kvsName => (
          <EventContentVideo
            key={kvsName}
            event={previousPreviousEvent}
            streams={streams}
            kvsName={kvsName}
            autoPlay={false}
            paused={true}
            cacheOnly={true}
          />
        ))}
      </Box>
      <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <Tooltip title="Previous event" arrow placement="right">
          <IconButton onClick={onPreviousEventClick} size="small">
            <SkipPrevious color="action" />
          </IconButton>
        </Tooltip>
        <PlayPauseButton
          isPaused={paused}
          onPlay={() => setPaused(false)}
          onPause={() => setPaused(true)}
        />
        <Tooltip title="Next event" arrow placement="right">
          <IconButton onClick={onNextEventClick} size="small">
            <SkipNext color="action" />
          </IconButton>
        </Tooltip>
      </Box>
      <EventContentTimeline
        ref={eventContentTimelineRef}
        events={events}
        selectedEvent={event}
        onEventClick={onEventClick}
        onTimelineClick={handleTimelineClick}
        kvsNames={streamNames}
      />
      <EventContentHistory
        history={eventHistory}
        expanded={historyExpanded}
        onExpandClick={() => setHistoryExpanded(!historyExpanded)}
      />
    </Box>
  )
}

export default React.forwardRef(EventContent);
